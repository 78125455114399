import React, { useState, useEffect } from 'react';
import { getAllBusiness } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Row, Col, Dropdown, ButtonGroup, Card, Modal, Form, Container } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import { CgAddR } from 'react-icons/cg' //
import galleryImg from "../../../assets/images/gallery.webp"; //
import galleryLikeImg from "../../../assets/images/like-gallery.png" //
import { getAllBusinessGallery } from "../../../redux/business/actions"
import { findAllGallery, deleteGallery, createGallery } from "../../../redux/dashboard/actions"
import dislikeImg from "../../../assets/images/dislike.png";//
import ImageUploder from '../../../helpers/imageUploder';
import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import { galleryUploadValidation } from '../../../validations/yub_schemas/galleryUploadValidation';
import nullImage from "../../../assets/images/no-image-found.svg"



function GalleryList(props) {


    const [galleryUploadImg, setGalleryImage] = useState("")
    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");


    const [show, setShow] = useState(false);//

    const handleClose = () => setShow(false); //
    const handleShow = () => setShow(true);  //


    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });

    const dispatch = useDispatch();

    const { vendor } = useSelector((state) => state.business);

    const { id } = props; //

    useEffect(() => {
        let data = {
            id: vendor?._id
        }
        dispatch(findAllGallery(data))
            .then((res) => {
                const { results, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: res,
                    total: res.length
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }, [id]);


    const onFileChange = (name, value) => {
        formik.setFieldValue(name, value);
    }


    const formik = useFormik({
        initialValues: {
            title: "",
            gallery_image_url: "",
            image_category: "",

        },
        validationSchema: galleryUploadValidation,
        onSubmit: (values) => {
            let data = {
                title: values.title,
                image_URL: values.gallery_image_url,
                serviceprovider: vendor?._id,
                image_category: values.image_category
            }

            dispatch(createGallery(data))
                .then((res) => {
                    formik.resetForm()
                    handleClose();
                    let data = {
                        id: vendor?._id
                    }
                    dispatch(findAllGallery(data))
                        .then((res) => {
                            const { results, totalcount, resultsPerPage } = res
                            setState((prev) => ({
                                ...prev,
                                data: res,
                                total: res.length
                            }))
                        })
                        .catch((err) => {
                            console.log("Err => ", err)
                        })

                })
                .catch((err) => {
                    console.log(err)
                })
        }
    })

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        const data = {
            id: vendor._id,
            search: e.target.value
        };

        dispatch(findAllGallery(data))
            .then((res) => {
                
                const { results, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: res,
                    total: res.length
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    };

    
    const getAllGallriesFun = () => {
        const data = {
            id: vendor._id,
        };

        dispatch(findAllGallery(data))
            .then((res) => {
                
                console.log("filter Res =>", res)
                const { results, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: res,
                    total: res.length
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }
    const onChangeFilter = async (e) => {
        await setFilterBy(e.target.value);
        const data = {
          id: vendor._id,
          image_category: e.target.value,
          
        };
    
        dispatch(findAllGallery(data))
          .then((res) => {
            setState((prev) => ({
              ...prev,
              data: res,
              total: res.length
            }))
          })
        // onServiceDataChange();
      }

    // console.log("Gallery => ",galleryUploadImg);
    

   const [imgDelete, setImgDelete] = useState(false)
   const [confirmDelete, setConfirmDelete] = useState(false)
   const [imgSelected, setImgSelected] = useState()

   const imagedelete = (item) => {
       setImgSelected(item)
    setImgDelete(true)
   }

   const conformImgDelete = async () => {
    const data = {
        id: imgSelected?._id
    }
   await dispatch((deleteGallery(data)))
    .then((res)=>{
        console.log(res)
        
    })
    getAllGallriesFun()
    setConfirmDelete(false)
   }

   const imagedeleteconformation = () => {
    setConfirmDelete(true)
    setImgDelete(false)
   }
    
    return (
        <div className='container-xxl'>
            <div className="gallery-list mt-3">
            <div className="p-2">
                <h3 className='marketing-header'>Gallery</h3>

            </div>
            <div className="">
                <div className="mt-2">

                    <Row className='gallery-search-filter'>
                        <Col md={9} sm={12} xs={12}>
                            <input type="text" class="form-control gallery-search-filter" id="" placeholder="Search by name" name="search" onInput={onChangeSearch} />
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Row className='add-select'>
                                <Col md={6} xs={6} sm={6} className="">
                                    <Button className='m-0 btn add-service-btn' onClick={handleShow}><span><CgAddR style={{ fontSize: 19 }}/></span> Add</Button>
                                </Col>
                                <Col md={6} xs={6} sm={6}>
                                <select class="form-select"
                                    aria-label="Default select example"
                                    name="filterService"
                                    onChange={onChangeFilter}
                                    value={filterBy}
                                    >
                                    <option value="" selected>Filter by</option>  
                                    <option value="Hair">Hair</option>
                                    <option value="Spa">Spa</option>
                                    <option value="Nails">Nails</option>
                                    <option value="Makeup">Makeup</option>
                                    <option value="Mehendi">Mehendi</option>
                                    <option value="Skin">Skin</option>
                                    </select>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className='Image_uploader'>
                        <Modal className='mb-5' show={show} onHide={handleClose}>
                            <Modal.Header closeButton >
                            </Modal.Header>
                            <Modal.Body className='image-uploader-body' >
                                <Form onSubmit={formik.handleSubmit}>
                                    <FormikProvider value={formik}>
                                        <h3 className="text-center">Add New Image</h3>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            type="text"
                                            name="title"
                                            placeholder="Image Title" />
                                            <Form.Label className="required mt-4">Choose Category</Form.Label>
                                        <Form.Select 
                                        aria-label="Default select example"
                                         name="image_category"
                                         placeholder="image_category"
                                         onChange={formik.handleChange}
                                         value={formik.values.image_category}
                                         isInvalid={!!formik.image_category && !!formik.errors.image_category}
                                        >    <option>Open this select menu</option>
                                             <option value="Hair">Hair</option>
                                            <option value="Nails">Nails</option>
                                            <option value="Spa">Spa</option>
                                            <option value="Skin">Skin</option>
                                            <option value="Mehendi">Mehendi</option>
                                            <option value="Makeup">Makeup</option>
                                      </Form.Select>
                                      <ErrorMessage name="image_category" render={msg => <div className="text-danger mt-2 mb-2">{msg}</div>} />
                                        
                                        <Form.Label className="required my-3">Image</Form.Label>
                                        <ImageUploder
                                            name="gallery_image_url"
                                            onChange={onFileChange}
                                            value={formik.values.gallery_image_url}
                                            filePath={"/business/gallery"}
                                        ></ImageUploder>
                                        <ErrorMessage name="gallery_image_url" render={msg => <div className="text-danger mt-2 mb-2">{msg}</div>} />
                                        <Button className='mb-3' type="submit" style={{ backgroundColor: "#ECB390", color: "#602F10", width: "100%", border: "0" }}>
                                            Post
                                        </Button>
                                    </FormikProvider>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className="businessUpload-gallery-page">
                <Row className='mt-5'>
                    <Col md={12}>
                        <div class="galleryUpload-list-inner">
                           <Row>
                                {
                                    state.data && state.data.length > 0 ? (
                                        state.data.map((item, index) => {
                                            return (
                                                <Col md={3} key={index} className="image-container">
                                                    <img onClick={()=>imagedelete(item)} src={item?.image_URL} alt="gallery"
                                                        className='img-fluid gallery-image-user cur-pointer gallery-image-hover'
                                                    />
                                                     <div class="overlay" onClick={()=>imagedelete(item)}>
                                                        <div class="hover-text">Click the image to view or delete</div>
                                                    </div>
                                                    <h6 className='text-secondary p-2 text-center'>{item?.title}</h6>
                                                   
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <img className='no-image-found' src={nullImage} alt="no results Found" />
                                        )
                                }
                                <Modal  centered size="md" show={imgDelete} onHide={()=>setImgDelete(false)}>
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='p-3 text-center'>
                                    <img src={imgSelected?.image_URL} alt='image' className='img-fluid previewImage mt-3' />
                                    
                                    </Modal.Title>
                                    <Modal.Body className='p-3 mb-3'>
                                    
                                          <Row>
                                          <p className="delete-image-title">{imgSelected?.title}</p>
                                            <Col md={6}>
                                             <Button className='image-delete-cancel mb-2' onClick={()=>{setImgDelete(false)}}>Cancel</Button>
                                            </Col>
                                            <Col md={6}>
                                            <Button onClick={imagedeleteconformation} className='image-delete-delete'>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

                                </Modal>
                                <Modal  centered size="md" show={confirmDelete} onHide={()=>setConfirmDelete(false)}>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    <h2>Delete Image</h2>
                                    <p>Are you sure you want to Delete?</p>
                                    </Modal.Title>
                                    <Modal.Body>
                                          <Row className='p-3'>
                                            <Col>
                                             <Button className='image-delete-cancel' onClick={()=>{setConfirmDelete(false)}}>Cancel</Button>
                                            </Col>
                                            <Col>
                                            <Button onClick={conformImgDelete} className='image-delete-delete'>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

                                </Modal>

                                
                            </Row>
                            {/* <div class="load-more-outer text-center pb-5">
                                <button class="btn load-more">Load more</button>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        </div>
    )
}

export default GalleryList