import * as Yup from 'yup';



export const businessInformationValidation = Yup.object({
        business_name: Yup.string('Enter your business name')
                .matches(/^[a-zA-Z0-9 .!?/'_-]+$/, "Please enter a valid name. We allow only a-z A-Z 0-9 .!?/'_-")
                .required('Business name is required'),
        business_address: Yup.string('Enter your business address').required('Business address is required'),
        area: Yup.string('Enter your area').required('Area is required'),
        city: Yup.string('Enter your city').required('City is required'),
        // state: Yup.string('Enter your state').required('State is required'),
        zipcode: Yup.string('Enter your zipcode').matches(/^\d+$/, "Zipcode should be digits only").required('Zipcode is required'),
        country: Yup.string('Enter your country').required('Country is required'),
        business_email_id: Yup.string('Enter your business email')
                .email('Enter a valid email')
                .required('Email is required'),
        business_address_url: Yup.string().url("Please enter valid location url"),
        secondary_mobile_no: Yup.string('Enter your phone no').matches(/^\d+$/, "Should be digits only")
        .min(8, 'Enter a valid phone number').max(8, 'Enter valid phone number'),
        landline_no:Yup.string('Enter your landline no').matches(/^\+?[0-9-]+$/, "landline should be digits only")
        .min(8, 'Enter a valid landline number').max(8, "Enter a valid landline number"),
        stdcode:Yup.string('Enter your STD code').matches(/^\+?[0-9-]+$/, "Should be digits only")
        .min(8, 'Enter a valid phone number'),

});
