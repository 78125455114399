import * as Yup from 'yup';



export const commissionValidation = Yup.object({
    commission_percentage: Yup.string('please choose your commission percentage')
    .required('commission percentage is required')
    .matches(/^(0*(?:[0-9][0-9]?|100))(\.\d+)?$/, "Please enter a valid commission percentage")
    .min(1,'Should be of minimum 1 characters length')
    .max(4,'Should be of maximum 4 characters length'),
});