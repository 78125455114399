import React, { useState, useEffect } from 'react';
import { getAllOrders, deleteOrder } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical, BsFillCalendar3WeekFill} from "react-icons/bs";
import { Pagination, Dropdown, Badge } from "react-bootstrap";
import OrderView from './OrderView';
import {Row, Col, Modal, Button, Form} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from "moment";
import { useNavigate } from "react-router-dom";
function OrderList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [viewOrder, setViewOrder] = useState({});
  const [page, setPage] = useState(0);
  const [filterBy, setFilterBy] = useState("");
  const [selectSort, setSelectSort] = useState("desc")

  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
    total: 0
  });

  const dispatch = useDispatch();

  const { orders } = useSelector((state) => state.dashboard);
  const { vendor } = useSelector((state) => state.business);

  const [show, setShow] = useState(false);
  const [stateDate, SetStateDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


  useEffect(() => {
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      sortType:selectSort,
      order_status:filterBy,
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    };

    if (search) data.search = search
    dispatch(getAllOrders(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }, [selectSort, filterBy]);

  

  const handleOrderDataChange = () => {
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    };
    dispatch(getAllOrders(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }


  const handlePageChange = (pageNumber) => {
    setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
    setPage(pageNumber.selected);
    const data = {
      serviceProvider: vendor._id,
      page: pageNumber?.selected +1,
      sortType:selectSort,
      order_status:filterBy,
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    };
    if (search) data.search = search
    dispatch(getAllOrders(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })

  };

  const onSelectOrder = (id) => {
    // const orderDetails = state.data.filter(item => item._id == id)
    // setViewOrder(orderDetails[0]);
    // setViewOpen(true);
    navigate(`/dashboard/order/view/${id}`)
  }

  const handleDeleteChange = (id) => {
    const deleteData = {
      id: id,
      serviceProvider: vendor._id,
    };

    dispatch(deleteOrder(deleteData))
      .then((res) => {
        handleOrderDataChange()
      })
      .catch((err) => {
        console.log("Err => ", err)
      })


  }



  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
    const data = {
      serviceProvider: vendor._id,
      search: e.target.value,
      page: 1,
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    };

    dispatch(getAllOrders(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  };

  const onChangeSort = async () => {
    if(selectSort == "asc"){
      setSelectSort("desc")
     } else {
      setSelectSort("asc")
     }
    }

  const resetFilters = () =>{
    setFilterBy("");
        const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      search:"" ,
      request_status: filterBy,
      sortType: "desc",
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    };
    dispatch(getAllOrders(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }
  
  const dateRange = () => {

    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      sortType:selectSort,
      order_status:filterBy,
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    }
    dispatch(getAllOrders(data))
    .then((res) => {
      const { results, total_count, resultsPerPage } = res
      setState((prev) => ({
        ...prev,
        data: results,
        total: total_count / resultsPerPage
      }))
    })
    
  }
 

  return (
  <div className='container-xxl'>
    <div className="order-list mt-3">
      <OrderView
        show={viewOpen}
        onHide={() => setViewOpen(false)}
        order={viewOrder}
        handleOrderDataChange={handleOrderDataChange}
      />
      <div className="service-list-header-option mt-4">
      <Row>
        <Col md={6} sm={5} xs={5}>
            <h5>Your Orders</h5>
        </Col>
        <Col md={6} sm={7} xs={7}>
        <Col md={12}>
        <h5 class="order-calendar text-primary">
        <span className='cur-pointer order-cal-date' onClick={()=> setShow(true)}>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <>
                   <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                   <span className='mx-2'>to</span>
                   <span className='text-primary'>{moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD")}</span>
                  </>
                ) : (
                  <>
                  <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                  </>
                )
              }
            </span>
            <span className="text-primary mx-3">
              <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
            </span>

            <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <Modal.Body>
            <div className='calendar-view'>
            <DateRange
                editableDateInputs={true}
                onChange={item => SetStateDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>
              </Modal.Body>
            </Modal>
          </h5>
        </Col>
        </Col>
        
        {/* <Col md={6}>
        <h5 class="order-calendar text-primary">
        <span className='cur-pointer' onClick={()=> setShow(true)}>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <>
                   <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                   <span className='mx-2'>to</span>
                   <span className='text-primary'>{moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD")}</span>
                  </>
                ) : (
                  <>
                  <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                  </>
                )
              }
            </span>
            <span className="text-primary mx-3">
              <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
            </span>

            <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <Modal.Body>
            <div className='calendar-view'>
            <DateRange
                editableDateInputs={true}
                onChange={item => SetStateDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>
              </Modal.Body>
            </Modal>
          </h5>
        </Col> */}
      </Row>
      <hr />
      {/* <Row>
        <Col md={2} className="mt-2">
            <h5>
            Your Orders
            </h5>
        </Col>
        <Col md={4}>
        <input type="text" class="form-control order-search-bar" id="" placeholder="Search" name="search" onInput={onChangeSearch} />
        </Col>
        <Col md={6}>
        <h5 class="order-calendar text-primary">
        <span className='cur-pointer' onClick={()=> setShow(true)}>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <>
                   <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                   <span className='mx-2'>to</span>
                   <span className='text-primary'>{moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD")}</span>
                  </>
                ) : (
                  <>
                  <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                  </>
                )
              }
            </span>
            <span className="text-primary mx-3">
              <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
            </span>

            <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <Modal.Body>
            <div className='calendar-view'>
            <DateRange
                editableDateInputs={true}
                onChange={item => SetStateDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>
              </Modal.Body>
            </Modal>
          </h5>
        </Col>
      </Row> */}
      <Row>
        <Col md={6}>
             <Row>
             <Col md={9}>
              <input type="text" class="form-control search-orderlist" id="" value={search} placeholder="Search Orders..." name="search" onInput={ onChangeSearch} />
              </Col>
              <Col md={3}>
              {/* <Form.Select aria-label="Default select example" className='inventory-selection-list'
                 value={serviceCategory}
                 onChange={onServiceTypeSelect}
                >
                    <option value="basic">Basic</option>
                      <option value="bridal">Bridal</option>
            </Form.Select> */}
              </Col>
             
             </Row>
        </Col>
        <Col md={3}></Col>
        <Col md={3}>
            <Row>
            <div className='fiters-section-inventory'>
            <Col md={4}>
                          <p className='mt-2'
                           onClick={resetFilters}
                           >
                            Reset Filters
                           </p>
                          </Col>
                          <Col md={4}>
                          <select class="form-select mx-2"
              aria-label="Default select example"
              name="filterService"
              onChange={(e)=>setFilterBy(e.target.value)}
              value={filterBy}
            >
              <option value="">Filter</option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              {/* <option value="deny">No Show</option> */}
              <option value="cancelled">Cancelled</option>
            </select>

                          </Col>
                          <Col md={4}>
                          <Button className='fiter-btn-coupon ms-4' 
                          onClick={onChangeSort}
                          >
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
          </div>
            </Row>
        </Col>
      </Row>
      <hr />
       
      <Row>
        <Col md={12}>
        <div class="table-responsive slaylewks-table mt-4">
            <table class="table table-borderless">
            <thead className='admin-coupon-list-head'>
                      <tr>
                        
                        <th className='admin-tabel-heading'>
                        Order Id                       </th>
                        <th className='admin-tabel-heading'>
                        Name
                        </th>
                        <th className='admin-tabel-heading'>
                        Type
                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Services                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Status                      </th>
                        {/* <th className='admin-tabel-heading text-center'>
                        Payment
                     </th> */}
                        
                        <th>

                        </th>
                      </tr>
                      </thead>
             
              <tbody className='admin-tabel-data'>

                {
                  state.data && state.data.length > 0 ?
                    (
                      state.data.map((item, index) => {
                        return (
                          <tr className='order-list-tabel' key={index}>
                            {/* <th scope="row"><input class="form-check-input" type="checkbox" /></th> */}
                            <td class="sss" onClick={() => onSelectOrder(item._id)}><p>{item?.order_id}</p></td>
                            <td><p className='text-justify'>{item?.customer_details?.username}</p></td>
                            <td><p className='text-justify'>{item?.service_type}</p></td>
                            <td class="text-center"><p>{item?.services?.length}</p></td>
                             {
                                                          item?.order_status == "pending" && (
                                                            <td className='text-center'>
                                                              <Badge className='pending-badge' bg="pending-badge">Pending</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {
                                                          item?.order_status == "completed" && (
                                                            <td className='text-center'>
                                                              <Badge className='accepted-badge' bg="accepted-badge">Completed</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {/* {
                                                          item?.order_status == "noshow" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg="deny-badge">No Show</Badge>
                                                            </td>
                                                          )
                                                        } */}
                                                        {
                                                          item?.order_status == "cancelled" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg='deny-badge'>Cancelled</Badge>
                                                            </td>
                                                          )
                                                        }
                              {/* {
                                                          item?.order_status == "pending" && (
                                                            <td className='text-center'>
                                                              <Badge className='pending-badge' bg="pending-badge">Pending</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {
                                                          item?.order_status == "completed" && (
                                                            <td className='text-center'>
                                                              <Badge className='accepted-badge' bg="accepted-badge">Completed</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {
                                                          item?.order_status == "noshow" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg="deny-badge">No Show</Badge>
                                                            </td>
                                                          )
                                                        }
                                                        {
                                                          item?.order_status == "cancelled" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg='deny-badge'>Cancelled</Badge>
                                                            </td>
                                                          )
                                                        } */}
                            <td className='text-center'>
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <BsThreeDotsVertical />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => onSelectOrder(item._id)}>Edit</Dropdown.Item>
                                  {/* <Dropdown.Item onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item> */}

                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })

                    )
                    :

                    (
                      // <div className="mt-5 justify-content-center">
                      //   No Orders Found
                      // </div>
                      <tr>
                        <td>
                          No Orders Found
                        </td>
                      </tr>
                    )
                }
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      </div>
      <div>
     {
      state?.total > 1 && (
        <ReactPaginate 
        
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={state.total}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={'pagination justify-content-center pagination-box'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={page}
           />
      )
     }
            
         
       
      </div>
    </div >
    </div>
  )
}

export default OrderList