import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { getAllBusinessCouponCodeAction, deleteBusinessCouponCodeAction, findOneBusinessCouponCodeAction, deleteAllBussinessCouponCodeAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Table, Modal, Row, Col } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import CouponAddEdit from "./CouponAddEdit"
import { useNavigate } from "react-router-dom";
import CouponEdit from './CouponEdit';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



function CouponCodeList() {

    const navigate = useNavigate();

    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [code_filter, setCode_filter] = useState("coupon");
    const [viewOpen, setViewOpen] = useState(false);
    const [viewOpenEdit, setViewOpenEdit] = useState(false);
    const [selectCoupon, setSelectCoupon] = useState({});
    const [page, setPage] = useState(0);
    const [isChecked, setIsChecked] = useState();
    const [DeleteConform, setDeleteConform] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectallCheck, setSelectallCheck] = useState(false)
    const [selectSort, setSelectSort] = useState("Z-A")


    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 0
    });

    const dispatch = useDispatch();

    const { orders } = useSelector((state) => state.dashboard);
    const { vendor } = useSelector((state) => state.business);

   

    

    useEffect(() => {
        const data = {
            page: state.activePage,
            id: vendor._id,
        };
        dispatch(getAllBusinessCouponCodeAction(data))
            .then((res) => {
                let { couponMain, total_count, resultsPerPage } = res
                couponMain.map((itemCheck)=>{
                    itemCheck.isChecked = false;
                })
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })
    }, []);

    const getAllCouponList = (props) => {
        const data = {
            id: vendor._id,
            page: state.activePage,
        };
        dispatch(getAllBusinessCouponCodeAction(data))
            .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                couponMain.map((itemCheck)=>{
                    itemCheck.isChecked = false;
                })                
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })
    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber.selected);

        const data = {
            id: vendor._id,
            page: pageNumber?.selected +1
        };
        dispatch(getAllBusinessCouponCodeAction(data))
            .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                couponMain.map((itemCheck)=>{
                    itemCheck.isChecked = false;
                })  
                setSelectedItems([]);
                setSelectallCheck(false);              
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }));
            })

    };

    const getAllBusinessCouponCodes = () => {
        const data = {
            page: state.activePage,
            id: vendor._id,
        };

        dispatch(getAllBusinessCouponCodeAction(data))
            .then((res) => {
                const { results, total_count, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count / resultsPerPage
                }))
            })
    }

    const generateCode = () => {
        setViewOpen(true);
    }

    const handleEditChange = (_id) => {
    const data = {
        id: _id,
    }
    dispatch(findOneBusinessCouponCodeAction(data)).then((res) => {
        setSelectCoupon(res?.data);
        setViewOpenEdit(true);
    })
    }

      const handleDeleteChange = (_id) => {
        const data = {
            id: _id,
          }
          dispatch(deleteBusinessCouponCodeAction(data))
          getAllCouponList();
    }

    const onCheckboxBtnClick = (item, e) => {
        item.isChecked = e.target.checked;
      
        if (item.isChecked) {
          selectedItems.push(item);
        } else {
          selectedItems.splice(item, 1);
        }
        let isAllSelect = state.data.every((item)=>{
          return item.isChecked == true;
        })
        setSelectedItems([...selectedItems]);
        if (isAllSelect){
          setSelectallCheck(true);
        } else{
          setSelectallCheck(false);
        }
      };

const multiDelete =()=>{
        let selectedItemId = selectedItems.map((service) => {
          return service._id;
        })
        const data = {
           id: selectedItemId,
        }
        dispatch(deleteAllBussinessCouponCodeAction(data))
        setDeleteConform(false);
        setSelectedItems([]);
        setSelectallCheck(false);
        getAllCouponList();
      }
  
   
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
    const data = {
      search: e.target.value,
      page: 1,
      sortType: selectSort,
      id: vendor._id,
    };

    dispatch(getAllBusinessCouponCodeAction(data))
      .then((res) => {
        const { couponMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: couponMain,
          total: total_count / resultsPerPage
        }))
      })
  };

const selectAllCheckBox =(event)=>{
    if (event.target.checked) {
      state.data.map((item)=>{
        selectedItems.push(item);
        return item.isChecked = true;
      });
  
      setSelectedItems([...selectedItems]);
      setSelectallCheck(true);
    }
     else {
      state.data.map((item)=>{
        selectedItems.splice(item, 1);
        return item.isChecked = false;
      });
      setSelectedItems([]);
      setSelectallCheck(false);
  
    }
    
  }


  const onChangeSort = async () => {
    if(selectSort == "A-Z"){
      setSelectSort("Z-A")
     } else {
      setSelectSort("A-Z")
     }
    const data = {
      page: state.activePage,
      sortType: selectSort,
      id: vendor._id,
    };

    await dispatch(getAllBusinessCouponCodeAction(data))
      .then((res) => {
        const { couponMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: couponMain,
          total: total_count / resultsPerPage
        }))
      })
  }

    return (
        <div className='container-xxl'>
            <div className="gallery-list mt-3">
            <CouponAddEdit
                show={viewOpen}
                onHide={() => setViewOpen(false)}
                getCouponCodes={getAllCouponList}
            />
            <CouponEdit 
              show={viewOpenEdit}
              onHide={() => setViewOpenEdit(false)}
              formData={selectCoupon}
              getCouponCodes={getAllCouponList}
            />
             <div class="row mb-2">
                <div class="col-md-6">
                <div className="p-2">
                <h3 className='marketing-header'>
                    Marketing
                </h3>
            </div>
                </div>
                <div class="col-md-6">
               <div class="d-flex float-end">
               <div className="mt-1 mx-2">
                    <Button onClick={generateCode} variant="" className="generate-btn-admin"> Generate <span class="ionic-icon-btn"><ion-icon name="duplicate-outline"></ion-icon></span></Button>{' '}
                </div>
                <div className="mt-1 mx-2">
                    <Button className="delete-btn-admin"  onClick={()=>setDeleteConform(true)} > Delete <span class="ionic-icon-btn"><ion-icon name="trash-outline"></ion-icon></span></Button>{' '}
                </div>
               </div>
                </div>
            </div>
            <hr  className='mt-0'/>

            <Modal  centered size="md" show={DeleteConform} onHide={()=>setDeleteConform(false)} className='text-center'>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    Delete Coupons?
                                  </Modal.Title>
                                    <Modal.Body className='mb-5 px-2'>
                                    <p className='mt-2'>Are you sure you want to Delete?</p>

                                          <Row className='mt-5'>
                                            <Col>
                                             <Button className='image-delete-cancel' onClick={()=>{setDeleteConform(false)}}>Cancel</Button>
                                            </Col>
                                            <Col>
                                            <Button className='image-delete-delete' onClick={multiDelete}>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

             </Modal>
            {/* <section>
                <div class="container mt-5 px-2">
                    <div class="table-responsive slaylewks-table">
                        <table class="table table-responsive table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" width="2%"><input class="form-check-input" type="checkbox" /></th>

                                    <th scope="col" width="10%">
                                        <h4>Code</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                        <h4>Service</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                        <h4>Price</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                        <h4>Discount</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                        <h4>Date</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                        <h4>Status</h4>
                                    </th>
                                    <th scope="col" width="5%" class="table-head">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    state && state.data.length > 0 ?
                                        (
                                            state.data.map((item, index) => {
                                                console.log(item)
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row"><input class="form-check-input" type="checkbox" /></th>
                                                        <td class="sss">{item.coupon_name}</td> 
                                                        <td>{item.coupon_type}</td>
                                                        <td>{item.per_user_limit_type}</td>
                                                        <td>{item.time_limit_type}</td>
                                                        <td>{item.usage_limit}</td>

                                                        
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                <Dropdown.Item onClick={() => handleEditChange(item._id)}>Edit</Dropdown.Item>

                                                                    <Dropdown.Item onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                            <div className="mt-5 justify-content-center">
                                                No Orders Found
                                            </div>
                                        )

                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </section> */}

{/* <div className='coupon-headers'>
                <div id='desktop-view'>
                <Row>
                    <Col md={8}>
                    <input type="text" class="form-control searchbar-copon" id="" placeholder="Search Service,..." name="search" value={search} onInput={onChangeSearch}  />

                    </Col>
                    <Col md={4}>
                         <Row className='reset-filters-coupons' >
                         <Col md={8}>
                          <p>Reset Filters</p>
                          </Col>
                          <Col md={2}>
                          <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                          </Col>
                          <Col md={2}>
                          <Button className='fiter-btn-coupon' onClick={sortTypeChange}>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
                          </Row>
                         
                    </Col>
                    
                </Row>
                </div>

                <div className='coupon-headers-inner' id='mobile-view'>
                    <Row>
                    <input type="text" class="form-control searchbar-copon" id="" placeholder="Search Service,..." name="search" value={search} onInput={onChangeSearch}  />
                    </Row>
                    <div class='row mt-2 p-0'>
                        <div class="col-6">
                        <p>Reset Filters</p>
                        </div>
                       <div class="col-3 px-3">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                       </div>
                       <div class="col-3 px-3">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                       </div>
                    </div>
                </div>

                </div> */}
                <div className='pt-0 mt-0'>
                <div className='' id='desktop-view'>
                <Row>
                    <Col md={8} sm={12}>
                    <input type="text" class="form-control searchbar-copon" id="" placeholder="Search Service,..." name="search" value={search} onInput={onChangeSearch}  />

                    </Col>
                    <Col md={4}>
                         <Row >
                         <Col md={8} sm={10} xs={10}>
                          <p className='coupon-headers-reset'>Reset Filters</p>
                          </Col>
                          <Col md={2} sm={2} xs={2}>
                          <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                          </Col>
                          <Col md={2} sm={2} xs={2}>
                          <Button className='fiter-btn-coupon' onClick={onChangeSort}>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
                          </Row>
                         
                    </Col>
                    
                </Row>
                </div>

                <div className='coupon-headers-inner' id='mobile-view'>
                    <Row className='px-2'>
                    <input type="text" class="form-control searchbar-copon w-100" id="" placeholder="Search Service,..." name="search" value={search} onInput={onChangeSearch}  />
                    </Row>
                    <div class='row mt-2'>
                        <div class="col-8">
                        <p>Reset Filters</p>
                        </div>
                       <div class="col-2">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                       </div>
                       <div class="col-2">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                       </div>
                    </div>
                </div>

                </div>

            <div className='coupon-headers'>
               

                <hr className='mt-0 pt-0'/>
        
                    <div>
                      <Table responsive>
                      <thead className='admin-coupon-list-head'>
                      <tr>
                        <th className='admin-tabel-heading'>
                        <input class="form-check-input" type="checkbox" checked={selectallCheck} onClick={(e)=>selectAllCheckBox(e)}  />  
                        </th>
                        <th className='admin-tabel-heading'>
                        Code
                        </th>
                        <th className='admin-tabel-heading'>
                        Name
                        </th>
                        <th className='admin-tabel-heading'>
                        Type
                        </th>
                        <th className='admin-tabel-heading'>
                        Servicecategory
                        </th>
                        <th className='admin-tabel-heading'>
                        Usage limit
                        </th>
                        <th className='admin-tabel-heading'>
                        Status
                        </th>
                        <th>

                        </th>
                      </tr>
                      </thead>
                      <tbody className='admin-tabel-data'>
                      {
                                    state && state.data.length > 0 ?
                                        (
                                            state.data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td scope="row"><input class="form-check-input" type="checkbox" value={item?._id} checked={item.isChecked} onChange={(e)=>onCheckboxBtnClick(item, e)}/></td>
                                                        <td class="sss">{item?.coupon_code_business}</td> 
                                                        <td class="sss">{item.coupon_name}</td> 
                                                        <td>{item.coupon_type}</td>
                                                        <td>{item?.servicecategory[0]?.servicecategory_name}</td>
                                                        <td>{item.time_limit_type}</td>
                                                        {
                                                            item?.is_active == true ? (
                                                                <td className='status-active'>Active</td>
                                                                ) :
                                                                (
                                                                    <td>In active</td>
                                                                )
                                                        }

                                                        
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                <Dropdown.Item onClick={() => handleEditChange(item._id)}>Edit</Dropdown.Item>
                                                                <hr className='m-0 p-0' />
                                                                    <Dropdown.Item onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                           null
                                        )

                                }


                      
                      </tbody>

                      </Table>
                      
                    </div>
                    {
                        state?.data?.length < 1 && (
                            <div className="mt-5">
                            <h6 className='text-center'>No coupons Found</h6>
                        </div>
                        )
                      }
                    
{
    state?.total > 1 && (
        <div className='mt-5'>
        <ReactPaginate 
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={state?.total}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={'pagination justify-content-center pagination-box'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={page}
           />
               </div>
    ) 
}
              
             
            </div>

       
        </div>
        </div>
    )
}

export default CouponCodeList