import React, { useState, useEffect } from 'react';
import { getAllUsers, deleteUser, getAllUsedCoupons, deleteAdminCouponCodeAction, getReferralCodeList } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Modal, Row, Col, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';

function ReferralCodeList(props) {

    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [page, setPage] = useState(0);


    const [Delete, setDelete] = useState(false);
    const [userId, setUserId] = useState();
    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });


    const deleteOpen = (id) => {
        setDelete(true)
        setUserId(id)
        //   handleDeleteChange(id)
    }

    const dispatch = useDispatch();

    const { users } = useSelector((state) => state.dashboard);


    useEffect(() => {

        // const fetchData = async () => {
            const data = {
              page: 1,
              search: props.search,
            };
            dispatch(getReferralCodeList(data))
              .then((res) => {
                  const { orderCountPerPage, resultsPerPage, results, totalcount } = res.data
                  setState((prev) => ({
                      ...prev,
                      data: results,
                      total: Math.ceil(totalcount / resultsPerPage)
                  }))
              })
        //   };
        
         
    },  [props.search]);


    useEffect(() => {

        // const fetchData = async () => {
            const data = {
              page: state.activePage,
              search: "",
            };
            dispatch(getReferralCodeList(data))
              .then((res) => {
                  const { orderCountPerPage, resultsPerPage, results, totalcount } = res.data
                  setState((prev) => ({
                      ...prev,
                      data: results,
                      total: Math.ceil(totalcount / resultsPerPage)
                  }))
              })
        //   };
        
         
    },  []);



    const getAllCouponList = (props) => {
        const data = {
            page: state.activePage,
        };

        dispatch(getReferralCodeList(data))
            .then((res) => {
                const { orderCountPerPage, resultsPerPage, results, totalcount } = res.data
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: Math.ceil(totalcount / resultsPerPage)
                }))
            })
    }

    const getAllData = () => {
        const data = {
            page: state.activePage,
        };
        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })
    }

    const handleDeleteChange = (_id) => {
        const data = {
            id: _id,
        }
        dispatch(deleteAdminCouponCodeAction(data))
        getAllCouponList();
    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber.selected);

        const data = {
            page: pageNumber?.selected + 1,
            search: props.search,

        };
        dispatch(getReferralCodeList(data))
            .then((res) => {
                const { orderCountPerPage, resultsPerPage, results, totalcount } = res.data
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: Math.ceil(totalcount / resultsPerPage)
                }))
            })

    };

    const handleDeleteChange_user = () => {
        const data = {
            id: userId
        }
        dispatch(deleteUser(data))
            .then((res) => {
                const data = {
                    page: state.activePage,
                };
                dispatch(getAllUsers(data))
                    .then((res) => {
                        const { userMain, totalcount, resultsPerPage } = res
                        setState((prev) => ({
                            ...prev,
                            data: userMain,
                            total: totalcount / resultsPerPage
                        }))
                        getAllData();
                        setDelete(false)
                    })


            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        // console.log(" on active page ==> ", state.activePage, e.target.value)
        let data;
        if (e.target.value != '') {
            data = {
                page: 1,
                search: e.target.value
            };
        } else {
            data = {
                page: state.activePage,
                search: e.target.value
            };
        }


        dispatch(getReferralCodeList(data))
            .then((res) => {
                // const { orderCountPerPage, results } = res
                // setState((prev) => ({
                //     ...prev,
                //     data: results,
                //     total: orderCountPerPage
                // }))
                const { orderCountPerPage, resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: Math.ceil(totalcount / resultsPerPage)
                }))
            })
    };

    return (
        <div >
            <div className="order-list mt-3">
                {/* <h3 className='marketing-header'>Referral Code List</h3>
                <hr /> */}
                <div className="service-list-header-option mt-4">

                    {/* <div className="business-search w-50">
                    <input type="text" class="form-control" id="" placeholder="Search Coupon Code" name="search" onInput={onChangeSearch} />
                </div> */}

                </div>
                {/* <hr className='p-0 mb-0' /> */}

                <section class="pt-0">
                    <div class=" mt-4">
                        <div class="table-responsive slaylewks-table">
                            <Table responsive>
                                <thead className='admin-coupon-list-head'>
                                    <tr className='tabel-data-content'>
                                        {/* <th scope="col" width="4%"><input class="form-check-input" type="checkbox" /></th> */}
                                        <th className='admin-tabel-heading'>
                                           Referrer Name
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Referral Code
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Referred Person List
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Total Referrals
                                        </th>
                                        
                                        <th className='admin-tabel-heading'>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state?.data?.map((item, index) => {
                                            
                                            // console.log(item, "referral code")
                                            return (
                                                <tr className='tabel-data-content' key={index}>
                                                    <td class="sss">{item?.userName}</td>
                                                    <td>{item?.referralCode}</td>
                                                    <td>
                                                        {item?.usersUsed.length > 0 ? (
                                                            <select defaultValue={item?.usersUsed[0]}>
                                                                {item?.usersUsed.map((user, idx) => (
                                                                    <option key={idx} value={user}>{user}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            "NA"
                                                        )}
                                                    </td>
                                                    <td>{item?.usedCount}</td>
                                                    <td>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }

                                    {
                                        state?.total < 1 && (
                                            <p className='mt-5 mx-2'>No results found</p>

                                        )
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </section>
                <div>

                    {
                        state?.total > 1 && (

                            <ReactPaginate

                                previousLabel="<<"
                                nextLabel=">>"
                                breakLabel="..."
                                pageCount={state.total}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination justify-content-center pagination-box'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                                forcePage={page}

                            />
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default ReferralCodeList;