import * as Yup from 'yup';

export const signUpValidation = Yup.object({
    username: Yup.string('Enter your username')
    .matches(/^[a-zA-Z]{1}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name")
        // .strict()
        .min(3, 'Username must have minimum 3 characters')
        .max(30, 'Username cannot be more than 15 characters' )
        .required('Username is required'),
    phone_no: Yup
    .string()
    .matches(/^(968)?\d{8}$/, 'Invalid Omani phone number')
    .required('Phone number is required'),
    email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .max(15, 'Password should be of maximum 15 characters length')
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
        // password: Yup.string('Enter your password').min(8, 'Password should be of minimum 8 characters length').max(15, 'Password should be of maximum 15 characters length')
        // .required('Password is required'),

    confirmPassword: Yup.string('Enter password confirmation')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    termsAndPrivacy: Yup.bool().required().oneOf([true], "Terms & Conditions must be accepted"),
});
