import React from 'react'
import Footer from '../../components/elements/footer/Footer'
import Header from '../../components/elements/header/Header'
import BusinessSupport from './BusinessSupport'

function BusinessSupportMain() {
  return (
    <div>
      <Header />
      <BusinessSupport />
      <Footer />
    </div>
  )
}

export default BusinessSupportMain
