import React, { useState, useEffect } from 'react';
import BusinessImg from "../../assets/images/home/Business.webp"
import FreelancerImg from "../../assets/images/home/Freelancer.webp"

import onlinebooking from "../../assets/images/online-booking.webp"
import notificationmail from "../../assets/images/notification-mail.svg"
import clientadmin from "../../assets/images/client-admin.svg"

import acceptpayment from "../../assets/images/accept-payment.svg"
import integration from "../../assets/images/integration.webp"
import customerfeatures from "../../assets/images/customer-features.webp"

import sallonspa from "../../assets/images/sallon-spa.svg"
import onlineappointment from "../../assets/images/online-appointment.svg"
import compareprice from "../../assets/images/compare-price.svg"
import waitingtime from "../../assets/images/waiting-time.svg"
import usicon from "../../assets/images/us-icon.svg"
import bookappointment from "../../assets/images/book-appointment.webp";

import NaturalsImg from "../../assets/images/home/naturals.webp"
import GreenTrendsImg from "../../assets/images/home/greentrends.webp"
import StrandsImg from "../../assets/images/home/strands.webp"
import ZaraImg from "../../assets/images/home/zara.webp";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeVendorType } from "../../redux/business/actions";

import Login from "../../components/elements/auth/Login";
import RegisterMsgModal from "../../components/shared/modals/RegisterMsgModal";
import Signup from "../../components/elements/auth/Signup";
import VerifyYourEmailCode from "../../components/elements/auth/VerifyYourEmailCode";
import ForgotPassword from "../../components/elements/auth/ForgotPassword";
import ResetPassword from "../../components/elements/auth/ResetPassword";
import {Row, Col, Card} from "react-bootstrap";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Footer from '../../components/elements/footer/Footer';

// import "../../assets/css/businessHome.css"

function NewBusinessHome() {

    const registerChange = () => {
        if (registerModal) {
            setRegisterModal(false);
        }
        else {
            setRegisterModal(true);
        }
    }

    const [businessType, setBusinessType] = useState("business");
    const iconStyle = { color: "#ECB390", fontSize: "2.5em" };

    const [rsgMsgModal, setRsgMsgModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailCode] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);

    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);


    const dispatch = useDispatch();

    const navigate = useNavigate();

    const changeBusinessType = (type) => {
        setBusinessType(type);
        dispatch(changeVendorType(type));

        if (isLoggedIn && role?.role_name && (role?.role_name === "business" || role?.role_name === "freelancer")) {
            // dispatch(changeVendorType(type));
            navigate('/business/steps');
        }
        else {
            // dispatch(changeVendorType(type));
            // setRsgMsgModal(true);
            navigate('/business#register');
            // navigate('/business/steps');
        }
    }

    const typeBusiness = () => {
        changeBusinessType('business')
    }

    const typeFreelancer = () => {
        changeBusinessType('freelancer')
    }

    const typeUser = () => {
        changeBusinessType('user')
    }

    return (
        <div className="business-home-new">

            <Signup
                show={registerModal}
                onHide={() => setRegisterModal(false)}
                setRegisterModal={setRegisterModal}
                loginShow={loginModal}
                onLoginHide={() => setLoginModal(true)}
                verifyEmailModal={verifyEmailModal}
                setverifyemailcode={() => setVerifyEmailCode(true)}
            />
            <Login
                show={loginModal}
                onHide={() => setLoginModal(false)}
                registerShow={registerModal}
                onRegisterHide={() => setRegisterModal(true)}
                forgotPasswordModal={forgotPasswordModal}
                setforgotpasswordmodal={() => setForgotPasswordModal(true)}
            />

            <VerifyYourEmailCode
                show={verifyEmailModal}
                onHide={() => setVerifyEmailCode(false)}
                resetPasswordModal={resetPasswordModal}
                setResetPasswordModal={() => setResetPasswordModal(true)}
                loginModal={loginModal}
                setloginmodal={() => setLoginModal(true)}
            />

            <ForgotPassword
                show={forgotPasswordModal}
                onHide={() => setForgotPasswordModal(false)}
                verifyEmailModal={verifyEmailModal}
                setverifyemailcode={() => setVerifyEmailCode(true)}
            />

            <ResetPassword
                show={resetPasswordModal}
                onHide={() => setResetPasswordModal(false)}
                loginModal={loginModal}
                setloginmodal={() => setLoginModal(true)}
            />

            <RegisterMsgModal
                show={rsgMsgModal}
                onHide={() => setRsgMsgModal(false)}
            />
            <section class="business-home-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="business-home-text">
                                <p class="banner-text">Setup your Account in <span class="beauty-text">Just 3 Steps</span></p>
                                <p>for free and get more customers!</p>
                                <Row className='mt-5'>
                                    <Col>
                                    <div class="business-right" onClick={() => { registerChange(); typeBusiness() }}>
                                            <img src={BusinessImg} alt="businessimage" class="img-fluid" />
                                            <h4 className='mt-3'>Business</h4>
                                        </div>
                                    </Col>
                                    <Col>
                                    <div class="freelancer-left" onClick={() => { registerChange(); typeFreelancer() }}>
                                            <img src={FreelancerImg} alt="freelancer" class="img-fluid" />
                                            <h4 className='mt-3'>Freelancer</h4>
                                        </div>
                                    </Col>
                                    <p class="create-account">Create a Slaylewks account today and receive orders Online</p>
                                </Row>
                                {/* <ul>
                                    <li>
                                        <div class="business-home-freelancer col-lg-6 col-md-12 col-sm-12" onClick={() => { registerChange(); typeBusiness() }}>
                                            <img src={BusinessImg} alt="businessimage" class="img-fluid" />
                                            <span>Business</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="business-home-freelancer col-lg-6 col-md-12 col-sm-12" onClick={() => { registerChange(); typeFreelancer() }}>
                                            <img src={FreelancerImg} alt="freelancer" class="img-fluid" />
                                            <span>Freelancer</span>
                                        </div>
                                    </li>
                                </ul>
                                <p class="create-account">Create a Slaylewks account today and receive orders Online</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class='p-0'>
                {/* <div class="container">
                    <div class="row">
                        <div class="col-md-12 title-view">
                            <h3 class="user-common-title business-client">Our Business Clients</h3>
                            <div class="explore-service-list">
                                <ul>
                                    <li>
                                        <div class="explore-list">
                                            <img src={NaturalsImg} alt="naturals" class="img-fluid" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="explore-list">
                                            <img src={GreenTrendsImg} alt="greentrends" class="img-fluid" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="explore-list">
                                            <img src={StrandsImg} alt="strands" class="img-fluid" />

                                        </div>
                                    </li>
                                    <li>
                                        <div class="explore-list">
                                            <img src={ZaraImg} alt="zara" class="img-fluid" />

                                        </div>
                                    </li>
                                    <li>
                                        <div class="explore-list">
                                            <img src={GreenTrendsImg} alt="greentrends" class="img-fluid" />

                                        </div>
                                    </li>
                                    <li>
                                        <div class="explore-list">
                                            <img src={StrandsImg} alt="strands" class="img-fluid" />

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <section class="business-partner-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="business-partner-inner">
                                <p>Why should you partner with Slaylewks?</p>
                                <span>Slaylewks brings to you more customers , more bookings and a platform to brand your business. Get latest insights on the beauty services industry and tips on how you can boost your sales.</span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div class="container our-features-header">
            <h3 class="user-common-title">Our<span> Features</span></h3>
            </div>
            <section class="our-features-outer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            {/* <h3 class="user-common-title">Our<span> Features</span></h3> */}
                            {/* <div class="client-main">
                                <ul>
                                    <li><a href="JavaScript:void(0);">Main</a></li>
                                    <li><a href="JavaScript:void(0);">For Business</a></li>
                                </ul>
                            </div> */}
                             <div>
                                <Row className='text-center mb-5'>
                                      <Row>
                                            <Col md={4} sm={4} xs={4}>
                                            <div class="our-features-images text-center">
                                                <img src={onlinebooking} alt="online-booking" class="img-fluid" />
                                                <p>Accept online bookings</p>
                                            </div>
                                            </Col>
                                            <Col md={4} sm={4} xs={4}>
                                            <div class="our-features-images text-center">
                                                <img src={notificationmail} alt="notification-mail" class="img-fluid" />
                                                <p>Notifications via SMS/Email</p>
                                            </div>
                                            </Col>
                                            <Col md={4} sm={4} xs={4}>
                                            <div class="our-features-images text-center">
                                                <img src={clientadmin} alt="client-admin" class="img-fluid" />
                                                <p>Client & Admin App</p>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md={4} sm={4} xs={4}>
                                        <div class="our-features-images text-center">
                                                <img src={acceptpayment} alt="accept-payment" class="img-fluid" />
                                                <p>Accept Payments</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={4} xs={4}>
                                        <div class="our-features-images text-center">
                                                <img src={integration} alt="integration" class="img-fluid" />
                                                <p>Integration & API</p>
                                            </div>
                                        </Col>
                                        <Col md={4} sm={4} xs={4}>
                                        <div class="our-features-images text-center">
                                                <img src={customerfeatures} alt="customer-features" class="img-fluid" />
                                                <p>Custom features</p>
                                            </div>
                                        </Col>

                                        </Row>
                                    </Row>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="how-works-outer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="user-common-title">How it<span> Works</span></h3>
                            <div class="how-works-list business-work-list">
                                {/* <ul>
                                    <li>
                                        <div class="card">
                                            <img src={sallonspa} alt="sallon-spa" class="img-fluid works-img" />
                                            <div class="card-body">
                                                <h5 class="card-title">Create your page on Slaylewks</h5>
                                                <p class="card-text">Discover the widest selection of salons, parlours and spas in and around your area</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card">
                                            <img src={onlineappointment} alt="online-appointment" class="img-fluid works-img" />
                                            <div class="card-body">
                                                <h5 class="card-title">Register for online ordering</h5>
                                                <p class="card-text">Select your preferred time slot and book appointments online with just a tap or two.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="card">
                                            <img src={compareprice} alt="compare-price" class="img-fluid works-img" />
                                            <div class="card-body">
                                                <h5 class="card-title">Start receiving orders online</h5>
                                                <p class="card-text">Evaluate different outlets, their services and prices and pick the one that fits your needs</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul> */}
                                <Row className='howitworks-card'>
                                <Col md={4} sm={4} xs={4}>
                                <div class="card">
                                            <img src={sallonspa} alt="sallon-spa" class="img-fluid works-img" />
                                            <div class="card-body px-0">
                                                <h5 class="card-title">Create your page on Slaylewks</h5>
                                                <p class="card-text">Discover the widest selection of salons, parlours and spas in and around your area.</p>
                                            </div>
                                        </div>
                                </Col>
                                <Col md={4} sm={4} xs={4}>
                                <div class="card">
                                            <img src={compareprice} alt="online-appointment" class="img-fluid works-img" />
                                            <div class="card-body px-0">
                                                <h5 class="card-title">Register for online ordering</h5>
                                                <p class="card-text">Select your preferred time slot and book appointments online with just a tap or two.</p>
                                            </div>
                                            </div>
                                </Col>
                                <Col md={4} sm={4} xs={4}>
                                <div class="card">
                                            <img src= {onlineappointment} alt="compare-price" class="img-fluid works-img" />
                                            <div class="card-body px-0">
                                                <h5 class="card-title">Start receiving orders online</h5>
                                                <p class="card-text">Evaluate different outlets, their services and prices and pick the one that fits your needs.</p>
                                            </div>
                                        </div>
                                </Col>
                            </Row>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class='p-0'>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="user-common-title m-0">Our happy <span> Partners</span></h3>
                            <Carousel>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div> 
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section> */}
            <div>
            <Footer />
            </div>
        </div>
    )
}

export default NewBusinessHome;