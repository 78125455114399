import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col
} from "react-bootstrap";
import ImageUploder from "../../../helpers/imageUploder";
import FileUploder from "../../../helpers/FileUploder"

function MultipleServiceUpload(props) {
    const [value, setValue] = useState("");
    const [filePath, setFilePath] = useState("service/")

    const closeUploadModal = () => {
        props.onServiceDataChange();
        props.onHide();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-3">
                    <h4>Multiple service upload</h4>
                </div>

                <div className="upload-body-content">
                    <FileUploder
                        name="multiple_service_upload"
                        onChange={setValue}
                        value={value}
                        filePath={filePath}
                        serviceprovider={props.serviceprovider}
                        closeModal={() => closeUploadModal()}
                    ></FileUploder>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MultipleServiceUpload;