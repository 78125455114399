import * as TYPES from './types.js';

const initialState = {
    serviceProviderCart: {},
    servicesCart: [],
    slotDetails: {},
};


const cartReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.CHANGE_SERVICE_PROVIDER_DETAILS:
            return {
                ...state,
                serviceProviderCart: payload,
            };
        case TYPES.CLEAR_SERVICE_PROVIDER_DETAILS:
            return {
                ...state,
                serviceProviderCart: {},
            };
        case TYPES.ADD_SERVICE_TO_CART:
            return {
                ...state,
                servicesCart: [...state.servicesCart, payload],
            };
        case TYPES.CREATE_SERVICE_TO_CART_SUCCESS:
                return {
                    ...state,
                    servicesCart: [...state.servicesCart, payload],
                };
        case TYPES.CREATE_SERVICE_TO_CART_FAIL:
                    return {
                        ...state,
                        
                    };
        case TYPES.READ_CART_SERVICE_SUCCESS:
                        return {
                            ...state,
                            servicesCart: payload,
                        };
         case TYPES.READ_CART_SERVICE_FAIL:
                            return {
                                ...state,
                                
                            };
        case TYPES.ADD_MANY_SERVICES_TO_CART:
            return {
                ...state,
                servicesCart: payload,
            };
        case TYPES.REMOVE_SERVICE_FROM_CART:
            let tempData = state.servicesCart.filter(item => item._id !== payload._id)
            return {
                ...state,
                servicesCart: tempData,
            };
            case TYPES.DELETE_CART_ONE_SUCCESS:
                let cartData = state.servicesCart.filter(item => item._id !== payload._id)
                return {
                    ...state,
                    servicesCart: cartData,
                };
                case TYPES.DELETE_CART_ONE_FAIL:
                    
                    return {
                        ...state,
                    };
        case TYPES.ADD_SERVICE_QTY_TO_CART:
            let addItem = []
            state.servicesCart.map(item => {
                if (item._id === payload._id) {
                    item.service_qty += 1
                }
                return addItem.push(item)
            })
            return {
                ...state,
                servicesCart: addItem,
            };
        case TYPES.REMOVE_SERVICE_QTY_FROM_CART:
            let removeItem = []
            state.servicesCart.map(item => {
                if (item._id === payload._id) {
                    item.product_qty -= 1
                }
                return removeItem.push(item)
            })
            return {
                ...state,
                servicesCart: removeItem,
            };
        case TYPES.CLEAR_CART_DETAILS:
            return {
                ...state,
                servicesCart: [],
            };
        case TYPES.GET_ALL_SLOT_DETAILS:
            return {
                ...state,
                slotDetails: payload,
            };
        case TYPES.CLEAR_SLOT_DETAILS:
            return {
                ...state,
                slotDetails: {},
            };
        case TYPES.CLEAR_ALL_CART_DETAILS:
            return {
                ...state,
                serviceProviderCart: {},
                servicesCart: [],
                slotDetails: {},
            };
        case TYPES.DELETE_CART_MANY_SUCCESS:
                return {
                    ...state,
                    serviceProviderCart: {},
                    servicesCart: [],
                    slotDetails: {},
                };
        case TYPES.DELETE_CART_MANY_FAIL:
            return {
                ...state,
            }

        default:
            return state;
    }
};

export default cartReducer;
