import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone'

import { BiCloudUpload, BiUpload } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { Button } from "react-bootstrap";
import { fileUploadLocalHost } from '../redux/business/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import uploadImage from "../assets/images/upload-list-image-card.svg"

const FileUploder = (props) => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null); // state for storing actual image
    const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage

    const dispatch = useDispatch();
    const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
    const dropRef = useRef(); // React ref for managing the hover state of droppable area

    const iconStyle = { color: "#ECB390", fontSize: "7.5em" };
    const uploadIconStyle = { color: "#00FF00", fontSize: "1em" };
    const deleteIconStyle = { color: "#FF0000", fontSize: "1em" };

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setPreviewSrc('')
        if(acceptedFiles.length===0) {
        toast.warn('Please upload a valid file');
        }
        setLoading(true);
        const [uploadedFile] = acceptedFiles;
        setFile(uploadedFile);

        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewSrc(fileReader.result);
        };
        fileReader.readAsDataURL(uploadedFile);
        setIsPreviewAvailable(uploadedFile.name.match(/\.(csv|xlsx|xls)$/));
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
        minSize: 0,
        maxSize: 1048576
    })
    const onDrop1 = (files) => {
        const [uploadedFile] = files;
        setFile(uploadedFile);

        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewSrc(fileReader.result);
        };
        fileReader.readAsDataURL(uploadedFile);
        setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png|xlsx|ods)$/));
        dropRef.current.style.border = '2px dashed #e9ebeb';
    };
    const updateBorder = (dragState) => {
        if (dragState === 'over') {
            dropRef.current.style.border = '2px solid #000';
        } else if (dragState === 'leave') {
            dropRef.current.style.border = '2px dashed #e9ebeb';
        }
    };
    const uploadFile = () => {
        if (file) {
            // file["name"] = props.filePath + file["name"];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('path', props.filePath);

            if (props.serviceprovider) {
                formData.append('serviceprovider', props.serviceprovider);
            }


            dispatch(fileUploadLocalHost(formData))
                .then((res) => {
                    props.closeModal()
                    props.onChange(props.name, res.data.location);
                }).catch((err) => {
                    console.log("Error => ", err);
                })
        } else {
            console.log('Please select a file to add.');
        }
    }

    const deleteFile = () => {
        setFile(null);
        setPreviewSrc("")
        setIsPreviewAvailable(false)

    }

    return (
        <>
            <div className="image-uploader" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="uploader-icon">
                    {/* <BiCloudUpload style={iconStyle} /> */}
                    <img src={uploadImage} />
                </div>
                <br />
                <div>
                    <h6>
                        Drag & Drop file here, or click to select file
                    </h6>
                    <span className="text-muted">
                        Support File Types: XLS, XLSX, CSV.<br/>
                        Maximum file size 1MB.
                    </span>
                </div>

            </div>
            {/* <div className="upload-section mt-5">
        {previewSrc && (
          isPreviewAvailable ? (
            <div className="image-preview">
              <img className="preview-image" src={previewSrc} alt="Preview" />
            </div>

          ) : (
            <div className="preview-message">
              <p>No preview available for this file</p>
            </div>
          )
        )}
      </div> */}
            {
                file && file !== "" && (
                    <div className="my-5">
                        <strong>Selected file:</strong> {file.name}
                        <span className="ms-3">
                            <Button variant="outline-success" className="me-3" size="sm" onClick={uploadFile}>
                                <BiUpload style={uploadIconStyle} />
                            </Button>
                            <Button variant="outline-danger" size="sm" onClick={deleteFile}>
                                <TiDeleteOutline style={deleteIconStyle} />
                            </Button>
                        </span>
                    </div>
                )
            }

        </>
    )
}

export default FileUploder