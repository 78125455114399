import React from 'react'
import DashboardHeader from '../DashboardHeader';
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import {Row, Col} from "react-bootstrap";
import OwnerLists from './ownerLists';
import OwnerListDetails from './ownerListDetail';

function DetailBranches() {
  return (
    <div className="dashboard-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="">
          <Row>
            <Col className='p-0' md={2} sm={12}>
            <div className="sidebar-content">
            <AdminSidebarPage />
          </div>
            </Col>
            <Col md={10} sm={12}>
            <div className="main-content">
                <OwnerListDetails />
          </div>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

export default DetailBranches;