import React from "react";
import { Modal, Button} from "react-bootstrap";
import successImage from "../../../assets/images/vendor_icons/Success.svg";
import { useNavigate } from 'react-router-dom';

const VendorRegisterSucessModal = (props) => {

    const navigate = useNavigate();

    const handlePageRedirect = () => {
        navigate("/dashboard");
    }

    return (
        <div className="vendor-register-success-modal">
            <Modal
                {...props}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="">
                    <div className="text-center">
                        <img className="success-logo"
                            src={successImage}
                            alt="Success logo"
                        />

                        <div className="step-four-title text-center">
                        <h4 className="mt-4">Business listing details submitted</h4>
                            <p>
                            Our team will verify the details and update once your page is live on Slaylewks!
                            </p>
                        </div>
                        <div className="text-center mt-5 mb-5">
                            <Button variant="" className="next-btn-businessSteps w-50" type="submit" onClick={handlePageRedirect}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VendorRegisterSucessModal;