const apiUrl = process.env.REACT_APP_API_URL;

export const VENDOR_CONFIG = {
    vendorRegister: `${apiUrl}/serviceprovider/create`,
    vendorUpdate: `${apiUrl}/serviceprovider/update`,
    getVendorApi: `${apiUrl}/serviceprovider/findone`,

    exploreApi: `${apiUrl}/explore`,

    businessDetailsApi: `${apiUrl}/detailedservices`,
    businessDetailsByGenderApi: `${apiUrl}/genderservice`,

    getAllBusinessReviewsApi: `${apiUrl}/reviews/all`,
    createBusinessReviewApi: `${apiUrl}/reviews/create`,

    getAllBusinessGalleryApi: `${apiUrl}/gallery/getall`,

    getSomeSalonsApi: `${apiUrl}/salons`,
    getSalonsLocation: `${apiUrl}/locationDetails`,


    getAllReviewsApi: `${apiUrl}/reviews/all`,
    getAvgReviewsApi: `${apiUrl}/ratingview`,
    createReviewsApi: `${apiUrl}/reviews/create`,



    getAllGalleryImgs: `${apiUrl}/gallery/getall`,


};
