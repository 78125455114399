import React, { useEffect, useState } from "react";
import DashboardHeader from '../DashboardHeader';
import { useFormik, FormikProvider } from 'formik';
import { Form,Row, Col, Button } from "react-bootstrap";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router";
import { getProposalDetails, updateProposalDetailsActions } from "../../../redux/dashboard/actions"
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import { businessInfoValidation } from "../../../validations/yub_schemas/businessInfoValidation";


const BusinessDetailsEdit = () => {
    const { id } = useParams();
    const [editResponse, setEditResponse] = useState()

    useEffect(() => {
        const data = {
            id: id
        };
        dispatch(getProposalDetails(data))
            .then((res) => {
                console.log(res, "editrespe");
                setEditResponse(res?.serviceprovider)
                console.log(res?.serviceprovider?.serviceTypes[0].servicetype_name)

            })
    }, [id]);

    const GetAll = () =>{
        const data = {
            id: id
        };
        dispatch(getProposalDetails(data))
            .then((res) => {
                setEditResponse(res?.serviceprovider)
            })
    }

    const iconStyle = { color: "#DC3545", fontSize: "1.5em", cursor: "pointer" };
    const iconBtnStyle = { color: "#FFF", fontSize: "1em", cursor: "pointer" };
    const [errorMessage, setErrorMessage] = useState(false);

    const [openAt, setOpenAt] = useState("");
    const [closeAt, setCloseAt] = useState("");
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    const [timeSlot, setTimeSlot] = useState(editResponse?.business_hours ||
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );

    const addTimeSlot = () => {

        setTimeSlot([...timeSlot, { open_at: "", open_at: "" }])
    }
    const remoteTimeSlot = (index) => {
        let newFormValues = [...timeSlot];
        newFormValues.splice(index, 1);
        setTimeSlot(newFormValues)
        formik.setFieldValue("business_basic_hours", newFormValues)
    };
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: editResponse?._id,
            business_name: editResponse?.business_name || "",
            business_address: editResponse?.location_details?.address || "",
            area: editResponse?.location_details?.area || "",
            city: editResponse?.location_details?.city || "",
            country: editResponse?.location_details?.country || "",
            state: editResponse?.location_details?.state || "",
            zipcode: editResponse?.location_details?.zipcode || "",
            business_email_id: editResponse?.business_email || "",
            business_address_url: editResponse?.location_url || "",
            mobile_no: editResponse?.business_contactNo || "",
            business_type: editResponse?.serviceTypes[0].servicetype_name || "",
            age_category: editResponse?.gender?.map(item => item.gender_name) || [],
            service_category: editResponse?.service_category?.map(item => item.servicecategory_name) || [],
            business_open_at: "",
            business_close_at: "",
            business_basic_hours: editResponse?.business_hours || [{
                'open_at': "",
                "close_at": ""
            }],
            business_open_days: editResponse?.slot_details ? Object.keys(editResponse?.slot_details) : [],
            business_days_hours: editResponse?.slot_details || [],
            advance_percentage: editResponse?.advance_percentage || "",
        },
        validationSchema: businessInfoValidation,
        onSubmit: (values) => {
             const data = {
                ...values,
                business_name: values?.business_name,
                locationdetails: {
                    address: values?.business_address,
                    area: values?.area,
                    city: values?.city,
                    zipcode: values?.zipcode,
                    state: values?.state,
                    country: values?.country
                },
                // address: values?.business_address,
                // area: values?.area,
                // city: values?.city,
                // country: values?.country,
                // state: values?.state,
                // zipcode: values?.zipcode,
                business_email_id: values?.business_email_id,
                location_url: values?.business_address_url,
                business_mobile_no: values?.mobile_no,
                business_type: values?.business_type,
                gender: values?.age_category,
                service_category: values?.service_category,
                // business_open_at: "",
                // business_close_at: "",
                // business_basic_hours: '',
                // business_open_days: "",
                // business_days_hours: '',
                // advance_percentage: '',
             }

             dispatch(updateProposalDetailsActions(data))
             .then((res) => {
                navigate('/dashboard/vendors')
             })
             .catch((err) => {
                 console.log(err)
             })

            // values["business_days_hours"] = {}
            // if (vendorType === "business") {
            //     values.business_basic_hours.forEach(item => {
            //         if (item.open_at > item.close_at || item.open_at == "" || item.closeAt == "") {
            //             setErrorMessage(true)
            //         }
            //         else {
            //             values["business_open_days"].forEach((item) => {
            //                 values["business_days_hours"][item] = { ...values['business_basic_hours'].map(item => item) }
            //             })
            //             values["serviceTypes"] = values["business_type"];
            //             values["gender"] = values["age_category"];


            //             let data = {
            //                 ...values,

            //                 business_type: values.business_type,
            //                 age_category: values.age_category,
            //                 business_open_days: values.business_open_days,
            //                 service_category: values.service_category,
            //                 business_basic_hours: values.business_basic_hours,
            //                 business_open_days: values.business_open_days,
            //                 business_days_hours: values.business_days_hours

            //             }
            //             console.log(data, "test")

            //             dispatch(updateProposalDetailsActions(data))
            //                 .then((res) => {
            //                 })
            //                 .catch((err) => {
            //                     console.log(err)
            //                 })

            //         }
            //     })
            // } else {
            //     values["serviceTypes"] = values["business_type"];
            //     values["gender"] = values["age_category"];


            //     let data = {
            //         ...values,

            //         business_type: values.business_type,
            //         age_category: values.age_category,
            //         business_open_days: values.business_open_days,
            //         service_category: values.service_category,
            //         business_basic_hours: values.business_basic_hours,
            //         business_open_days: values.business_open_days,
            //         business_days_hours: values.business_days_hours,
            //         advance_percentage: values.advance_percentage
            //     }

            //     dispatch(updateProposalDetailsActions(data))
            //         .then((res) => {
            //         })
            //         .catch((err) => {
            //             console.log(err)
            //         })
            //     console.log(data, "test2")


            // }

        },
    });



    const onBack = () => {
        navigate("/dashboard/vendors")
    }

    const handleCheckboxChange = (e) => {
        const { checked, value, name } = e.target;
        if (checked) {
            formik.setFieldValue(name, [...formik.values[name], value]);
        } else {
            formik.setFieldValue(
                name,
                formik.values[name].filter((v) => v !== value)
            );
        }
    };


    const handleTimeChange = async (e, index, type) => {
        const { value, name } = e.target;
        let currObject = formik.values.business_basic_hours[index];
        formik.setFieldTouched(name, true);

        formik.values.business_basic_hours[index] = { ...currObject }
        formik.setFieldValue(name, value);

        await Promise.resolve()

        if (type == "open_at") {

            setOpenAt(value)
        }
        if (type == "close_at") {

            setCloseAt(value)
        }
        formik.setFieldTouched(name, true);
        validateTimeSettings()
    };

    const validateTimeSettings = () => {
    }

    return (

        <>
            <div className="dashboard-page">
                <DashboardHeader />
                <div className="dashboard-body">
                    <div className="">
                        <Row>
                            <Col className='p-0' md={2} sm={12}>
                                <div className="sidebar-content">
                                    <AdminSidebarPage />
                                </div>
                            </Col>
                            <Col md={10} sm={12}>
                                <div className="main-content">
                                    <div className="businessStep2 mt-3">

                                        <Row>
                                            <Col md={12} sm={12}>
                                                <Form noValidate className="business-edit-form text-start" onSubmit={formik.handleSubmit}>
                                                    <FormikProvider value={formik}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-3 p-2" controlId="businessName">
                                                                    <Form.Label>Business Name *</Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Business name"
                                                                        name="business_name"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.business_name}
                                                                    isInvalid={!!formik.touched.business_name && !!formik.errors.business_name}

                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.errors.business_name}
                                                                    </Form.Control.Feedback>

                                                                </Form.Group>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="state">
                                                                            <Form.Label>Address line *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Business Address"
                                                                                name="business_address"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.business_address}
                                                                            isInvalid={!!formik.touched.business_address && !!formik.errors.business_address}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.business_address}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="city">
                                                                            <Form.Label>Area *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Area"
                                                                                name="area"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.area}
                                                                            isInvalid={!!formik.touched.area && !!formik.errors.area}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.area}
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="city">
                                                                            <Form.Label>City *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="City"
                                                                                name="city"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.city}
                                                                            isInvalid={!!formik.touched.city && !!formik.errors.city}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.city}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="state">
                                                                            <Form.Label>State *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="State"
                                                                                name="state"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.state}
                                                                            isInvalid={!!formik.touched.state && !!formik.errors.state}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.state}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="zipcode">
                                                                            <Form.Label>Zipcode *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Zipcode"
                                                                                name="zipcode"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.zipcode}
                                                                            isInvalid={!!formik.touched.zipcode && !!formik.errors.zipcode}

                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.zipcode}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group className="mb-3 p-2" controlId="country">
                                                                            <Form.Label>Country *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                type="text"
                                                                                placeholder="Country"
                                                                                name="country"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.country}
                                                                            isInvalid={!!formik.touched.country && !!formik.errors.country}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.country}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Form.Group className="mb-3 p-2" controlId="state">
                                                                    <Form.Label >Business Address URL </Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Ex: https://www.slaylewks.com"
                                                                        name="business_address_url"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.business_address_url}
                                                                    isInvalid={!!formik.touched.business_address_url && !!formik.errors.business_address_url}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.errors.business_address_url}
                                                                    </Form.Control.Feedback>
                                                                    <Form.Text className="text-muted">
                                                                        This will help your customers to locate your store
                                                                    </Form.Text>

                                                                </Form.Group>

                                                                <Row>
                                                                    <Col md={8}>
                                                                        <Form.Group className="mb-3 p-2" controlId="emailId">
                                                                            <Form.Label>Email Id *</Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                disabled={true}
                                                                                type="email"
                                                                                placeholder="Email Id"
                                                                                name="business_email_id"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.business_email_id}
                                                                            isInvalid={!!formik.touched.business_email_id && !!formik.errors.business_email_id}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {formik.errors.business_email_id}
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </Col>
                              
                                                                </Row>

                                                                <Row>
                                                                    <Col md={8}>
                                                                        <Form.Group className="mb-3 p-2" controlId="mobileNo">
                                                                            <Form.Label>Mobile Number at Business </Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Mobile No"
                                                                                name="mobile_no"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.mobile_no}

                                                                            />
                                                     

                                                                        </Form.Group>
                                                                    </Col>
                                         
                                                                </Row>
  
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mt-3">
                                                                    <Form.Label>Establishment type *</Form.Label>

                                                                    <p className="text-muted">Select most relevant category for your Business type</p>
                                                                </div>
                                                                <Form.Group className="mb-3 p-2" controlId="businessType">
                                        {
                                            editResponse?.vendor_type && editResponse?.vendor_type === "business" && (
                                                <>
                                                    <Form.Check
                                                        className="mb-2"
                                                        type='radio'
                                                        id='shop_only'
                                                        name="business_type"
                                                        label="Shop only"
                                                        value="Shop only"
                                                        checked={formik.values.business_type === "Shop only"}
                                                        onChange={() => formik.setFieldValue("business_type", "Shop only")}
                                                        isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}

                                                    />
                                                    <Form.Check
                                                        type='radio'
                                                        id='shop_and_home'
                                                        name="business_type"
                                                        label="Both Shop and Home service"
                                                        value="Both Shop and Home service"
                                                        checked={formik.values.business_type === "Both Shop and Home service"}
                                                        onChange={() => formik.setFieldValue("business_type", "Both Shop and Home service")}
                                                        isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                    />
                                                </>
                                            )
                                        }

                                        {
                                             editResponse?.vendor_type && editResponse?.vendor_type === "freelancer" && (
                                                <Form.Check
                                                    className="mb-2"
                                                    type='radio'
                                                    id='home_service'
                                                    name="business_type"
                                                    label="Home service"
                                                    value="Home service"
                                                    checked={formik.values.business_type === "Home service"}
                                                    onChange={() => formik.setFieldValue("business_type", "Home service")}
                                                    isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                />
                                            )
                                        }
                                    </Form.Group>
                                                                
                                                                <div className="mt-2">
                                                                    <Form.Label>Category *</Form.Label>

                                                                    <p className="text-muted">Select most relevant category for your Business type</p>
                                                                </div>
                                                                <Form.Group className="mb-3 p-2" controlId="ageCategory">
                                                                    <Form.Check
                                                                        className="mb-2"
                                                                        type='checkbox'
                                                                        id='Men'
                                                                        name="age_category"
                                                                        label="Men"
                                                                        value="Men"
                                                                        checked={formik.values.age_category.includes("Men")}
                                                                        onChange={handleCheckboxChange}
                                                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                                                    />
                                                                    <Form.Check
                                                                        className="mb-2"
                                                                        type='checkbox'
                                                                        id='Women'
                                                                        name="age_category"
                                                                        label="Women"
                                                                        value="Women"
                                                                        checked={formik.values.age_category.includes("Women")}
                                                                        onChange={handleCheckboxChange}
                                                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                                                    />
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        id='Kids'
                                                                        name="age_category"
                                                                        label="Kids"
                                                                        value="Kids"
                                                                        checked={formik.values.age_category.includes("Kids")}
                                                                        onChange={handleCheckboxChange}
                                                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.errors.age_category}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                <div className="mt-2">
                                                                    <Form.Label>Select options which best describe your Business Services *</Form.Label>

                                                                    <div className="">
                                                                        <span className="text-muted px-2">
                                                                            Selected:
                                                                        </span>

                                                                        {
                                                                            formik.values.service_category.map((item, index) => {
                                                                                return (
                                                                                    <span key={index} className="badge service-cat-badge me-2 px-2">
                                                                                        {item}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <Form.Group className="mb-3 p-2" controlId="serviceCategory">
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Form.Check
                                                                                className="mb-3"
                                                                                type='checkbox'
                                                                                id='Hair'
                                                                                name="service_category"
                                                                                label="Hair"
                                                                                value="Hair"
                                                                                checked={formik.values.service_category.includes("Hair")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                            <Form.Check
                                                                                className="mb-3"
                                                                                type='checkbox'
                                                                                id='Nails'
                                                                                name="service_category"
                                                                                label="Nails"
                                                                                value="Nails"
                                                                                checked={formik.values.service_category.includes("Nails")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                            <Form.Check
                                                                                type='checkbox'
                                                                                id='Spa'
                                                                                name="service_category"
                                                                                label="Spa"
                                                                                value="Spa"
                                                                                checked={formik.values.service_category.includes("Spa")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Form.Check
                                                                                className="mb-3"
                                                                                type='checkbox'
                                                                                id='Skin'
                                                                                name="service_category"
                                                                                label="Skin"
                                                                                value="Skin"
                                                                                checked={formik.values.service_category.includes("Skin")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                            <Form.Check
                                                                                className="mb-3"
                                                                                type='checkbox'
                                                                                id='Makeup'
                                                                                name="service_category"
                                                                                label="Makeup"
                                                                                value="Makeup"
                                                                                checked={formik.values.service_category.includes("Makeup")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                            <Form.Check
                                                                                type='checkbox'
                                                                                id='Mehendi'
                                                                                name="service_category"
                                                                                label="Mehendi"
                                                                                value="Mehendi"
                                                                                checked={formik.values.service_category.includes("Mehendi")}
                                                                                onChange={handleCheckboxChange}
                                                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.errors.service_category}
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                                {/* {
                                                                    editResponse?.vendor_type && editResponse?.vendor_type === "business" ? (
                                                                        <>
                                                                            <div className="mt-2">

                                                                                <Form.Label>Business operational hours *</Form.Label>

                                                                                <p className="text-muted">Mark Business opening and closing hours</p>
                                                                            </div>
                                                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningHours">
                                                                                <Row className="mb-2">
                                                                                    <Col md={3}>
                                                                                        <h5 className="text-muted">
                                                                                            Opens At
                                                                                        </h5>
                                                                                    </Col>
                                                                                    <Col md={2}>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <h5 className="text-muted">
                                                                                            Closes At
                                                                                        </h5>
                                                                                    </Col>
                                                                                </Row>
                                                                                {
                                                                                    timeSlot.map((slot, index) => {
                                                                                        const fieldName = `business_basic_hours[${index}]`;
                                                                                        return (
                                                                                            <div key={index}>
                                                                                                <Row className="mb-4">
                                                                                                    <Col md={3}>
                                                                                                        <Form.Control
                                                                                                            type="time"
                                                                                                            required step="3600"
                                                                                                            format="HH:MM a"
                                                                                                            name={`${fieldName}.open_at`}
                                                                                                            onChange={(e) => {
                                                                                                                setOpenAt(e.target.value)
                                                                                                                formik.setFieldValue("business_open_at", e.target.value);
                                                                                                                handleTimeChange(e, index, "open_at");
                                                                                                            }}
                                                                                                            value={formik.values.business_basic_hours[index]?.open_at}
                                                                                                            isInvalid={!!formik.errors.business_basic_hours}

                                                                                                        />
                                                                                                    </Col>

                                                                                                    <Col md={2}>
                                                                                                        <h6 className="text-muted mt-2 ms-4">
                                                                                                            to
                                                                                                        </h6>
                                                                                                    </Col>
                                                                                                    <Col md={3}>
                                                                                                        <Form.Control
                                                                                                            required
                                                                                                            type="time"

                                                                                                            format="hh:mm"
                                                                                                            name={`${fieldName}.close_at`}
                                                                                                            onChange={(e) => {
                                                                                                                setCloseAt(e.target.value)
                                                                                                                formik.setFieldValue("business_close_at", e.target.value);
                                                                                                                handleTimeChange(e, index, "close_at")
                                                                                                            }}
                                                                                                            value={formik.values.business_basic_hours[index]?.close_at}
                                                                                                            isInvalid={!!formik.errors.business_basic_hours}
                                                                                                        />
                                                                                                    </Col>
                                                                                                    {
                                                                                                        index != 0 &&
                                                                                                        <Col md={1}>
                                                                                                            <BsTrash style={iconStyle} onClick={() => remoteTimeSlot(index)} />
                                                                                                        </Col>
                                                                                                    }

                                                                                                </Row>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                                {
                                                                                    <p className={errorMessage ? "text-danger" : "text-warning"}>Note! The Closing time shouldn't be less than the opening time.</p>
                                                                                }
                                                                            </Form.Group>
                                                                            <div className="mx-3">
                                                                                <p className="text-start text-primary add-time-slot" >
                                                                                    <BsPlusLg /><span className="ms-2" onClick={() => addTimeSlot()}>Add Time Slot</span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="my-5">
                                                                                <h4 className="required">Mark open days</h4>
                                                                                <small className="text-muted">Don’t forget to uncheck your off-day</small>
                                                                            </div>
                                                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningDays">
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Monday'
                                                                                    name="business_open_days"
                                                                                    label="Monday"
                                                                                    value="Monday"
                                                                                    checked={formik.values.business_open_days.includes("Monday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Tuesday'
                                                                                    name="business_open_days"
                                                                                    label="Tuesday"
                                                                                    value="Tuesday"
                                                                                    checked={formik.values.business_open_days.includes("Tuesday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Wednesday'
                                                                                    name="business_open_days"
                                                                                    label="Wednesday"
                                                                                    value="Wednesday"
                                                                                    checked={formik.values.business_open_days.includes("Wednesday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Thursday'
                                                                                    name="business_open_days"
                                                                                    label="Thursday"
                                                                                    value="Thursday"
                                                                                    checked={formik.values.business_open_days.includes("Thursday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Friday'
                                                                                    name="business_open_days"
                                                                                    label="Friday"
                                                                                    value="Friday"
                                                                                    checked={formik.values.business_open_days.includes("Friday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Saturday'
                                                                                    name="business_open_days"
                                                                                    label="Saturday"
                                                                                    value="Saturday"
                                                                                    checked={formik.values.business_open_days.includes("Saturday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />
                                                                                <Form.Check
                                                                                    className="mb-3"
                                                                                    type='checkbox'
                                                                                    id='Sunday'
                                                                                    name="business_open_days"
                                                                                    label="Sunday"
                                                                                    value="Sunday"
                                                                                    checked={formik.values.business_open_days.includes("Sunday")}
                                                                                    onChange={handleCheckboxChange}
                                                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                                                />

                                                                            </Form.Group>

                                                                        </>
                                                                    ) : (
                                                                        <>



                                                                        </>

                                                                    )
                                                                } */}

                                                                <div className="text-center my-5">
                                                                    <Row>
                                                                        <Col md={6} className="d-grid mb-2">
                                                                            <Button variant="" className="business-back-btn" onClick={onBack}>
                                                                                <BsFillCaretLeftFill style={iconBtnStyle} /> Back
                                                                            </Button>
                                                                        </Col>
                                                                        <Col md={6} className="d-grid mb-2">
                                                                            <Button variant="" type="submit" className="business-update-btn">
                                                                                Update
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </FormikProvider>
                                                </Form>
                                            </Col>

                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </>
    );
};

export default BusinessDetailsEdit;