import React, { useState } from "react";
import {
    Form,
    Modal,
    Button
} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { forgotPassword } from '../../../redux/auth/actions';
import { forgotPasswordValidation } from "../../../validations/yub_schemas/forgotPasswordValidation";


const ForgotPassword = (props) => {

    const [, setLoading] = useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email_id: '',
        },
        validationSchema: forgotPasswordValidation,
        onSubmit: (values) => {
            const data = {
                email_id: values.email_id,
            };

            dispatch(forgotPassword(data))
                .then(() => {
                    formik.resetForm();
                    props.onHide(true);
                    props.setverifyemailcode();
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        },
    });

    
    

    return (
        <div className="forgot-password">
            <Modal
                // {...props}
                show={props.show}
                onHide={props.onHide}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="">
                    <div className="text-center">
                        {/* <img className="logo"
                            src={logo}
                            width="182.46px"
                            height="40px"
                            alt="Slaylewks logo" /> */}

                        <h4 className="register-header">Forgot Password</h4>
                        <div className="m-1">
                            <h6><small className="text-muted">Please enter your Email Id.</small></h6>
                        </div>
                    </div>

                    <div>
                        <Form className="register-form" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    {/* <Form.Label className="required">Email Id</Form.Label> */}
                                    <Form.Control
                                        type="email"
                                        placeholder="Email Id"
                                        name="email_id"
                                        onChange={formik.handleChange}
                                        value={formik.values.email_id}
                                        isInvalid={!!formik.touched.email_id && !!formik.errors.email_id}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email_id}
                                    </Form.Control.Feedback>
                                    {/* {formik.errors.email_id ? <div className="m-2 text-danger">{formik.errors.email_id}</div> : null} */}
                                </Form.Group>

                                <div className="text-center mt-4 d-grid gap-2">
                                    <Button  className="register-button"  type="submit" size="lg">
                                    Verify
                                    </Button>
                                </div>
                            </FormikProvider>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ForgotPassword;