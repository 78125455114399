export const ADD_USER_LOCATION = 'ADD_USER_LOCATION';
export const UPDATE_USER_LOCATION = 'EDIT_USER_LOCATION';
export const GET_USER_LOCATION = 'GET_USER_LOCATION';

export const GET_ALL_SLOT_DETAILS = 'GET_ALL_SLOT_DETAILS';
export const GET_ALL_SLOT_DETAILS_FAIL = 'GET_ALL_SLOT_DETAILS_FAIL';
export const CLEAR_SLOT_DETAILS = 'CLEAR_SLOT_DETAILS';


export const ADD_APPOINTMENT_DETAILS = 'ADD_APPOINTMENT_DETAILS';
export const UPDATE_APPOINTMENT_DETAILS = 'UPDATE_APPOINTMENT_DETAILS';
export const GET_APPOINTMENT_DETAILS = 'GET_APPOINTMENT_DETAILS';
export const CLEAR_APPOINTMENT_DETAILS = 'CLEAR_APPOINTMENT_DETAILS';


export const ADD_ORDER_DETAILS = 'ADD_ORDER_DETAILS';
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS';

export const ADD_USER_LOCATION_DETAILS = 'ADD_USER_LOCATION_DETAILS';

export const CLEAR_ALL_USER_ORDER_DETAILS = 'CLEAR_ALL_USER_ORDER_DETAILS';
