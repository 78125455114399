import axios from 'axios';
import { PAYMENT_CONFIG } from '../../configs/paymentConfig';

export function authHeader() {
    let token = localStorage.getItem('access');

    if (token) {
        return { 'x-access-token': `${token}` };
    } else {
        return {};
    }
}

const createRPOrder = (data) => axios.post(PAYMENT_CONFIG.createRPOrder, data, { headers: authHeader() });

const verifyRazorpayPayment = (data) => axios.post(PAYMENT_CONFIG.verifyRazorpayPayment, data, { headers: authHeader() });

const raiseRefund = (data) => axios.post(PAYMENT_CONFIG.raiseRefund, data, { headers: authHeader() });

const paymentFailed = (data) => axios.post(PAYMENT_CONFIG.paymentFailed, data, { headers: authHeader() });

const data = {
    createRPOrder,
    verifyRazorpayPayment,
    paymentFailed,
    raiseRefund
};

export default data;