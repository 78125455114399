import React, { useEffect, useState, useContext } from "react";
import Header from "../../../components/elements/header/Header";
import DashboardHeader from '../DashboardHeader';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Container,
    Row,
    Col
} from "react-bootstrap";
import { businessInformationValidation } from "../../../validations/yub_schemas/businessInformationValidation";
import BusinessFormImg from "../../../assets/images/vendor_icons/Form.svg";
import { BsPlusLg, BsTrash, BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
// import MultiStepFormContext from "./MultiStepFormContext";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { getVendorDetailsAction, updateBusinessInformationAction } from "../../../redux/dashboard/actions"




const BusinessInformationSettings = () => {

    // const { step1Details, setStep1Details, next, prev } = useContext(MultiStepFormContext);
    const { user, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const navigate = useNavigate();


    const { vendor } = useSelector(state => state.business);
    const dispatch = useDispatch()



    const iconStyle = { color: "#DC3545", fontSize: "1.5em", cursor: "pointer" };
    const iconBtnStyle = { color: "#FFF", fontSize: "1em", cursor: "pointer" ,marginBottom:"5px"};

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: vendor?._id || "",
            business_name: vendor?.business_name || "",
            business_address: vendor?.location_details?.address || "",
            area: vendor?.location_details?.area || "",
            city: vendor?.location_details?.city || "",
            state: vendor?.location_details?.state || "",
            zipcode: vendor?.location_details?.zipcode || "",
            country: vendor?.location_details?.country || "",
            business_address_url: vendor?.location_url || "",
            business_email_id: user?.email_id || "",
            email_verification_code: "",
            business_mobile_no: user?.mobile_no || "",
            secondary_mobile_no: "",
            mobile_verification_code: "",
            stdcode: "",
            landline_no: "",
        },
        
        validationSchema: businessInformationValidation,
        onSubmit: (values) => {
            let data = {
                ...values,
                business_email: values.business_email_id,
                business_contactNo: values.business_mobile_no || "",
                locationdetails: {
                    address: values.business_address,
                    area: values.area,
                    city: values.city,
                    zipcode: values.zipcode,
                    state: values.state,
                    country: values.country
                },
                location_url: values.business_address_url
            }
            dispatch(updateBusinessInformationAction(data))
                .then((res) => {
                    navigate("/dashboard/settings")
                })
                .catch((err) => {
                    console.log(err)
                })
            // setStep1Details(data);
            // next();
        },
    });

    const onBack = () => {
        navigate("/dashboard/settings")
    }


    return (
        <>
            <DashboardHeader />
            <div className="businessStep1 mt-5">

                <Container>
                    <Row>
                        <Col md={12} sm={12}>
                            <Form noValidate className="business-register-form text-start" onSubmit={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <div className="">
                                        <h4>Business details</h4>
                                        <small className="text-muted">Name, address and location!</small>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="businessName">
                                        <Form.Label>Business Name *</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Business name"
                                            name="business_name"
                                            onChange={formik.handleChange}
                                            value={formik.values.business_name}
                                            isInvalid={!!formik.touched.business_name && !!formik.errors.business_name}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.business_name}
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                    {/* <Form.Group className="mb-3 p-2" controlId="businessAddress">
                                        <Form.Label className="required">Business Address </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Business Address"
                                            name="business_address"
                                            onChange={formik.handleChange}
                                            value={formik.values.business_address}
                                            isInvalid={!!formik.touched.business_address && !!formik.errors.business_address}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.business_address}
                                        </Form.Control.Feedback>

                                    </Form.Group> */}
                                    <Row>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="state">
                                            <Form.Label>Address line *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Business Address"
                                                name="business_address"
                                                onChange={formik.handleChange}
                                                value={formik.values.business_address}
                                                isInvalid={!!formik.touched.business_address && !!formik.errors.business_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.business_address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="city">
                                            <Form.Label>Area *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Area"
                                                name="area"
                                                onChange={formik.handleChange}
                                                value={formik.values.area}
                                                isInvalid={!!formik.touched.area && !!formik.errors.area}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.area}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                   
                                </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 p-2" controlId="city">
                                                <Form.Label>City *</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.city}
                                                    isInvalid={!!formik.touched.city && !!formik.errors.city}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.city}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3 p-2" controlId="state">
                                                <Form.Label>State *</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="State"
                                                    name="state"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.state}
                                                    isInvalid={!!formik.touched.state && !!formik.errors.state}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.state}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 p-2" controlId="zipcode">
                                                <Form.Label>Zipcode *</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Zipcode"
                                                    name="zipcode"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.zipcode}
                                                    isInvalid={!!formik.touched.zipcode && !!formik.errors.zipcode}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.zipcode}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3 p-2" controlId="country">
                                                <Form.Label>Country *</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Country"
                                                    name="country"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.country}
                                                    isInvalid={!!formik.touched.country && !!formik.errors.country}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.country}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="mb-3 p-2" controlId="state">
                                        <Form.Label >Business Address URL </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Ex: https://www.slaylewks.com"
                                            name="business_address_url"
                                            onChange={formik.handleChange}
                                            value={formik.values.business_address_url}
                                            isInvalid={!!formik.touched.business_address_url && !!formik.errors.business_address_url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.business_address_url}
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            This will help your customers to locate your store
                                        </Form.Text>

                                    </Form.Group>


                                    <div className="mt-5 mb-3">
                                        <h4>Contact number at Business</h4>
                                        <small className="text-muted">Your customers will call on this number for general enquiries</small>
                                    </div>


                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="emailId">
                                                <Form.Label>Email Id *</Form.Label>
                                                <Form.Control
                                                    required
                                                    disabled={true}
                                                    type="email"
                                                    placeholder="Email Id"
                                                    name="business_email_id"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.business_email_id}
                                                    isInvalid={!!formik.touched.business_email_id && !!formik.errors.business_email_id}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.business_email_id}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={4}>
                                <Button className="mt-5 px-5 py-2">
                                    Verify
                                </Button>
                            </Col> */}
                                    </Row>

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="mobileNo">
                                                <Form.Label className="business-label-form">Mobile Number at Business </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mobile No"
                                                    name="mobile_no"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.business_mobile_no}
                                                    disabled={true}

                                                />
                                                {/* <Form.Text className="text-muted mt-2">
                                                or want to share landline number
                                            </Form.Text> */}

                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Button disabled={true} className="mt-5 px-5 py-2">
                                                Verify
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="mobileNo">
                                                <Form.Label>Secondary Mobile No </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    name="secondary_mobile_no"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.secondary_mobile_no}
                                                />
                                                <Form.Text className="text-muted mt-2">
                                                    or want to share landline number
                                                </Form.Text>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 p-2" controlId="stdCode">
                                                <Form.Label>STD Code </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="STD Code"
                                                    name="stdcode"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.stdcode}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="landlineNo">
                                                <Form.Label>Landline Number at Business  </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Landline No"
                                                    name="landline_no"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.landline_no}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    {/* <div className="text-center my-5 d-grid">
                                        <Button variant="primary" type="submit" size="lg">
                                            Save
                                        </Button>
                                    </div> */}

                                    <div className="text-center my-5">
                                        <Row>
                                            <Col md={6} className="d-grid mb-2">
                                                <Button variant="primary" size="lg" onClick={onBack}>
                                                    <BsFillCaretLeftFill style={iconBtnStyle} /> Back
                                                </Button>
                                            </Col>
                                            <Col md={6} className="d-grid mb-2">
                                                {/* <Button variant="primary" type="submit" size="lg">
                                                    Submit <BsFillCaretRightFill style={iconBtnStyle} />
                                                </Button> */}
                                                <Button variant="primary" type="submit" size="lg">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>

                                    </div>

                                </FormikProvider>

                            </Form>
                        </Col>
                       
                    </Row>



                </Container>
            </div>
        </>
    );
};

export default BusinessInformationSettings;