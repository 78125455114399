import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Link, Navigate, Outlet, } from "react-router-dom";
import Home from "../pages/home";
import BusinessRegisterPage from "../pages/business";
import ExplorePage from "../pages/explore/ExplorePage";
import BusinessStep1 from "../pages/business/businessStep1";
import BusinessStep2 from "../pages/business/businessStep2";
import BusinessStep3 from "../pages/business/businessStep3";
import BusinessStep4 from "../pages/business/businessStep4";
import BusinessMultiStepForm from "../pages/business/businessMultiStep";
import Dashboard from "../pages/dashboard";
import Appointment from "../pages/dashboard/Appointment";
import Inventory from "../pages/dashboard/Inventory";
import Order from "../pages/dashboard/Order";
import Staff from "../pages/dashboard/Staff";
import StaffAddEdit from "../pages/dashboard/Staff/StaffAddEdit";
import ServiceAddEdit from "../pages/dashboard/Inventory/ServiceAddEdit";
import GalleryPage from "../pages/dashboard/Gallery";
import Settings from "../pages/dashboard/Settings";
import Proposals from "../pages/dashboard/Proposals";
import Users from "../pages/dashboard/Users";
import Business from "../pages/dashboard/Business";
import Marketing from "../pages/dashboard/Marketing";
import ProposalView from "../pages/dashboard/Proposals/ProposalView";
import StaffCalendarView from "../pages/dashboard/Staff/StaffCalendarView";
import Term from "../pages/policy/Term";
import Privacy from "../pages/policy/Privacy";
import Reschedule from "../pages/policy/Reschedule";
import Cookie from "../pages/policy/Cookie";
import SingleBusinessPage from "../pages/business/SinglePage";
import BusinessMarketing from "../pages/dashboard/BusinessMarketing";
import CartMainPage from "../pages/checkout";
import PaymentFailed from "../pages/checkout/paymentStatus/PaymentFailed";
import PaymentSuccess from "../pages/checkout/paymentStatus/PaymentSuccess";
import PaymentCancelled from "../pages/checkout/paymentStatus/PaymentCancelled";
import MyOrdersPage from "../pages/order";
import CartCheckout from "../pages/checkout/CartCheckout";
import UserProfilePage from "../pages/profile";
import BusinessProfilePage from "../pages/profile/business";
import BusinessInformationSettings from "../pages/dashboard/Settings/BusinessInformationSettings";
import BusinessTimingSettings from "../pages/dashboard/Settings/BusinessTimingSettings"
import BankInformationSettings from "../pages/dashboard/Settings/BankInformationSettings"
import Support from "../pages/support/Support";
import BusinessSupport from "../pages/support/BusinessSupport";
import Login from "../components/elements/auth/Login";
import AboutUs from "../pages/about/aboutUs";
import UserSupport from "../pages/support/UserSupport";
import ReschduleSuccess from "../pages/checkout/paymentStatus/ReschduleSuccess";
import ReschduleFailed from "../pages/checkout/paymentStatus/ReschduleFailed";
import CancellationFailed from "../pages/checkout/paymentStatus/CancellationFailed";
import BusinessSupportMain from "../pages/support/BusinessSupportMain";
import CartCheckoutBridal from "../pages/checkout/CartCheckoutBridal";
import RequestSuccess from "../pages/checkout/paymentStatus/RequestSucess";
import OrderRequest from "../pages/dashboard/Order/OrderRequest";
import RequestView from "../pages/dashboard/Order/RequestView";
import RequestMain from "../pages/dashboard/Order/RequestMain";
import OrderDetails from "../pages/dashboard/Order/OrderDetails";
import RequestSingleView from "../pages/checkout/paymentStatus/RequestSingleView";
import RequestCancellation from "../pages/checkout/paymentStatus/RequestCancellation";
import RequestCancelSuccess from "../pages/checkout/paymentStatus/RequestCancelled";
import PaymentSuccessBridal from "../pages/checkout/paymentStatus/PaymentSuccessBridal";
import UsedCoupons from "../pages/dashboard/UsedCoupons";
import TransactionDetails from "../pages/dashboard/Transaction/transactionDetails";
import TransactionMain from "../pages/dashboard/Transaction";
import CommissionMain from "../pages/dashboard/Commission";
import DetailedCommistions from "../pages/dashboard/Commission";
import BusinessDetailsEdit from "../pages/dashboard/Business/BusinessInfoEdit";
import Branches from "../pages/dashboard/Branches";
import OwnerListDetails from "../pages/dashboard/Branches/ownerListDetail";
import DetailBranches from "../pages/dashboard/Branches/ownerMain";
import GroupingMain from "../pages/dashboard/GroupingUsers";
import GroupingSalonMain from "../pages/dashboard/GroupingSalon";
import ReferralPage from "../pages/dashboard/Referral";
import Signup from "../components/elements/auth/Signup";
import { Register } from "../components/elements/auth/Register";




const RoutesPage = () => {

    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    const PrivateRoute = ({ children }) => {
        if (isLoggedIn) {
            return children
        }
        return <Navigate to="/" />
    }

    const AdminRoute = ({ children }) => {
        if (isLoggedIn && role?.role_name === "admin") {
            return children
        }
        return <Navigate to="/" />
    }

    const DashboardRoute = ({ children }) => {
        console.log("Role", role?.role_name)
        if (isLoggedIn && (role?.role_name === "businesss" || role?.role_name === "freelancer" || role?.role_name === "admin")) {
            return children
        }
        console.log("condition => ", ["business,freelancer,admin"].includes(role?.role_name))
        return <Navigate to="/" />
    }

    

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/business" element={<BusinessRegisterPage />} />
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/about" element={<AboutUs />} />
            {/* <Route path="/signup" element={<Signup />} />  */}
            <Route path="/register" element={<Register />} /> 

            <Route path="/business/steps" element={<BusinessMultiStepForm />} />
            <Route path="/business/:id" element={<SingleBusinessPage />} />
            {/* <Route path="/business/step1" element={<PrivateRoute><BusinessStep1 /></PrivateRoute>} />
            <Route path="/business/step2" element={<PrivateRoute><BusinessStep2 /></PrivateRoute>} />
            <Route path="/business/step3" element={<PrivateRoute><BusinessStep3 /></PrivateRoute>} />
            <Route path="/business/step4" element={<PrivateRoute><BusinessStep4 /></PrivateRoute>} /> */}

            {/* Business Dashboard Routes */}
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/dashboard/appointments" element={<PrivateRoute><Appointment /></PrivateRoute>} />
            <Route path="/dashboard/inventory" element={<PrivateRoute><Inventory /></PrivateRoute>} />
            <Route path="/dashboard/orders" element={<PrivateRoute><Order /></PrivateRoute>} />
            <Route path="/dashboard/request" element={<PrivateRoute><OrderRequest /></PrivateRoute>} />
            <Route path="/dashboard/staff" element={<PrivateRoute><Staff /></PrivateRoute>} />
            <Route path="/dashboard/staff/calendar" element={<PrivateRoute><StaffCalendarView /></PrivateRoute>} />
            <Route path="/dashboard/staff/create" element={<PrivateRoute><StaffAddEdit /></PrivateRoute>} />
            <Route path="/dashboard/inventory/create" element={<PrivateRoute><ServiceAddEdit /></PrivateRoute>} />
            <Route path="/dashboard/service/:id" element={<PrivateRoute><ServiceAddEdit /></PrivateRoute>} />
            <Route path="/dashboard/gallery" element={<PrivateRoute><GalleryPage /></PrivateRoute>} />
            <Route path="/dashboard/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/settings/businessInformation" element={<PrivateRoute><BusinessInformationSettings /></PrivateRoute>} />
            <Route path="/settings/businessTiming" element={<PrivateRoute><BusinessTimingSettings /></PrivateRoute>} />
            <Route path="/settings/bankInformation" element={<PrivateRoute><BankInformationSettings /></PrivateRoute>} />
            <Route path="/dashboard/marketing/vendors" element={<PrivateRoute><BusinessMarketing /></PrivateRoute>} />
            <Route path="/dashboard/marketing/vendors/:id" element={<PrivateRoute><BusinessMarketing /></PrivateRoute>} />
            <Route path="/dashboard/order/view/:id" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />


            {/* Admin Dashboard Routes */}
            <Route path="/dashboard/proposal" element={<AdminRoute><Proposals /></AdminRoute>} />
            <Route path="/dashboard/user" element={<AdminRoute><Users /></AdminRoute>} />
            <Route path="/dashboard/vendors" element={<AdminRoute><Business /></AdminRoute>} />
            <Route path="/dashboard/vendors/:id" element={<AdminRoute><BusinessDetailsEdit /></AdminRoute>} />
            {/* <Route path="/dashboard/marketing" element={<AdminRoute><Marketing /></AdminRoute>} /> */}
            <Route path="/dashboard/admin" element={<AdminRoute><Marketing /></AdminRoute>} />

            <Route path="/dashboard/coupons" element={<AdminRoute><UsedCoupons /></AdminRoute>} />
            <Route path="/dashboard/transactions" element={<AdminRoute><TransactionMain /> </AdminRoute>} />
            <Route path="/dashboard/commissions" element={<AdminRoute><DetailedCommistions /> </AdminRoute>} />
            <Route path="/dashboard/marketing/:id" element={<AdminRoute><Marketing /></AdminRoute>} />

            <Route path="/dashboard/proposal/:id" element={<AdminRoute><ProposalView /></AdminRoute>} />
            <Route path="/dashboard/branches" element={<AdminRoute><Branches /> </AdminRoute>} />
            <Route path="/dashboard/grouping" element={<AdminRoute><GroupingMain /> </AdminRoute>} />
            <Route path="/dashboard/salon" element={<AdminRoute><GroupingSalonMain /> </AdminRoute>} />
            {/* <Route path="/dashboard/referrallist" element={<AdminRoute><ReferralPage /> </AdminRoute>}/> */}
            <Route path="/dashboard/branches/:id" element={<AdminRoute><DetailBranches /> </AdminRoute>} />


            <Route path="/term-of-use" element={<Term />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/reschedule-cancel-policy" element={<Reschedule />} />
            <Route path="/cookie-policy" element={<Cookie />} />
            <Route path="/support" element={<UserSupport />} />
            <Route path="/businessSupport" element={<BusinessSupport />} />
            <Route path="/businessSupportMain" element={<BusinessSupportMain />} />


            <Route path="/business/cart" element={<PrivateRoute><CartMainPage /></PrivateRoute>} />
            <Route path="/payment/checkout" element={<PrivateRoute><CartCheckout /></PrivateRoute>} />
            <Route path="/payment/checkout/bridal" element={<PrivateRoute><CartCheckoutBridal /></PrivateRoute>} />
            <Route path="/business/payment/failed" element={<PrivateRoute><PaymentFailed /></PrivateRoute>} />
            <Route path="/business/payment/cancel/failed/:id" element={<PrivateRoute><CancellationFailed /></PrivateRoute>} />
            <Route path="/business/payment/:id" element={<PrivateRoute><PaymentSuccess /></PrivateRoute>} />
            <Route path="/business/bridal/request" element={<PrivateRoute><RequestSuccess /></PrivateRoute>} />
            <Route path="/business/reschdule/success/:id" element={<PrivateRoute><ReschduleSuccess /></PrivateRoute>} />
            <Route path="/business/reschdule/failed/:id" element={<PrivateRoute><ReschduleFailed /></PrivateRoute>} />
            <Route path="/business/payment/cancel" element={<PrivateRoute><PaymentCancelled /></PrivateRoute>} />
            <Route path="/orders" element={<PrivateRoute><MyOrdersPage /></PrivateRoute>} />
            <Route path="/orders/request/:id" element={<PrivateRoute><RequestSingleView /></PrivateRoute>} />
            <Route path="/profile/:id" element={<PrivateRoute><UserProfilePage /></PrivateRoute>} />
            <Route path="/business/profile/:id" element={<PrivateRoute><BusinessProfilePage /></PrivateRoute>} />
            <Route path="/open/request/:id" element={<PrivateRoute><RequestMain /></PrivateRoute>} />
            <Route path="/request/cancel/:id" element={<PrivateRoute><RequestCancellation /></PrivateRoute>} />
            <Route path="/request/successfully/cancelled/:id" element={<PrivateRoute><RequestCancelSuccess /></PrivateRoute>} />
            <Route path="/yourpayment/success" element={<PaymentSuccessBridal />} />



        </Routes>
    );
};

export default RoutesPage;