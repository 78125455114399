import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { Navigate } from "react-router-dom";


function RescheduleConfirm(props) {
    const { show, onHide, selectedDate, selectedTime, handleReschdule } = props;

    const reScheduleBooking = async () => {
        handleReschdule();
        onHide();
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

                <div className="reschedule-confirm-model text-center">
                    <h6>Do you want to confirm the appointment on <span className="text-primary">{selectedDate + " " + selectedTime} </span> ? </h6>
                    <Button variant="outline-danger" className="me-2" onClick={onHide}>
                        cancel
                    </Button>
                    <Button variant="outline-success" onClick={reScheduleBooking}>
                        confirm
                    </Button>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default RescheduleConfirm