import React, { useEffect, useState } from "react";
import { Provider } from "./MultiStepFormContext";
import Header from "../../components/elements/header/Header";
// import StepProgressBar from "react-step-progress";
import BusinessStep1 from "./businessStep1";
import BusinessStep2 from "./businessStep2";
import BusinessStep3 from "./businessStep3";
import BusinessStep4 from "./businessStep4";
import { useNavigate } from 'react-router-dom';
import Stepper from "../../helpers/Stepper";
import { useDispatch, useSelector } from 'react-redux';
import { vendorRegister } from '../../redux/business/actions';
import VendorRegisterSucessModal from "../../components/shared/modals/VendorRegisterSuccessModal";
import UseIsMounted from "../../hooks/UseIsMounted"


const step1InitialState = {

};

const step2InitialState = {

};

const step3InitialState = {

};

const step4InitialState = {

};

const BusinessMultiStepForm = () => {

  const [step1Details, setStep1Details] = useState(step1InitialState);
  const [step2Details, setStep2Details] = useState(step2InitialState);
  const [step3Details, setStep3Details] = useState(step3InitialState);
  const [step4Details, setStep4Details] = useState(step4InitialState);

  const [currentStep, setCurrentStep] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMounted = UseIsMounted();


  const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
  const { user, isLoggedIn, needToLogin } = useSelector((state) => state.auth);

  const renderStep = (currentStep) => {
    switch (currentStep) {
      case 0:
        return <BusinessStep1 />;
      case 1:
        return <BusinessStep2 />;
      case 2:
        return <BusinessStep3 />;
      case 3:
        return <BusinessStep4 />;
      default:
        return null;
    }
  };


  const next = () => {
    // if (currentStep === 3) {
    //   // setCurrentStep(0);
    //   // setStep1Details(step1InitialState);
    //   // setStep2Details(step2InitialState);
    //   // setStep3Details(step3InitialState);
    //   // setStep4Details(step4InitialState);
    //   // return;
    //   // onFormSubmit();
    //   // return;
    // }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);

  function onFormSubmit(value) {
    setLoading(true)
    setStep4Details(value);
    dispatch(vendorRegister({ ...step1Details, ...step2Details, ...step3Details, ...value, userid: user?._id, vendor_type: vendorType }))
      .then(() => {
        if (isMounted.current) {
          setLoading(false);
          setSuccessModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const stepsData = [
    {
      label: "Business Information",
      name: "Business Information"
    },
    {
      label: "Business Type & Timings",
      name: "Business Type & Timings"
    },
    {
      label: "Document",
      name: "Document"
    },
    {
      label: "Bank Account",
      name: "Bank Account"
    }
  ];

  return (
    <Provider value={{ step1Details, setStep1Details, step2Details, setStep2Details, step3Details, setStep3Details, step4Details, setStep4Details, next, prev, onFormSubmit, loading, setLoading }}>
      <div>
        <VendorRegisterSucessModal
          show={successModal}
          onHide={() => setSuccessModal(false)}
        />
        <Header />
        <div className="mt-5">
          <Stepper
            steps={stepsData}
            currentStep={currentStep}
          />
          <main>{renderStep(currentStep)}</main>
        </div>
      </div>
    </Provider>
  );
};

export default BusinessMultiStepForm;