import React, { useState, useEffect } from 'react';
import { getAllUsers, deleteUser, getAllUsedCoupons, deleteAdminCouponCodeAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Modal, Row, Col, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';

function UsedCouponList(props) {

    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");

    const [Delete, setDelete] = useState(false);
    const [userId, setUserId] = useState();
    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });


    const deleteOpen = (id) => {
        setDelete(true)
      console.log("delete id",id)
      setUserId(id)
    //   handleDeleteChange(id)
    }

    const dispatch = useDispatch();

    const { users } = useSelector((state) => state.dashboard);


    useEffect(() => {

            const data = {
              page: 1,
              search: props.search,
            };
            dispatch(getAllUsedCoupons(data))
                .then((res) => {
                    const { orderCountPerPage, resultsPerPage, results, totalcount } = res
                    setState((prev) => ({
                        ...prev,
                        data: results,
                        total: Math.ceil(totalcount / resultsPerPage) 
                    }))
                })        
         
    },  [props.search]);



    useEffect(() => {
        const data = {
            page: state.activePage,
            search:"",
        };
        dispatch(getAllUsedCoupons(data))
            .then((res) => {
                const { orderCountPerPage, resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: Math.ceil(totalcount / resultsPerPage) 
                }))
            })
    }, []);


    const getAllCouponList = (props) => {
        const data = {
            page: state.activePage,
        };

        dispatch(getAllUsedCoupons(data))
        .then((res) => {
            const { orderCountPerPage, resultsPerPage, results, totalcount } = res
            setState((prev) => ({
                ...prev,
                data: results,
                total: Math.ceil(totalcount / resultsPerPage) 
            }))
        })
    }

    const getAllData = () => {
        const data = {
            page: state.activePage,
        };
        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })
    }

    const handleDeleteChange = (_id) => {
        const data = {
            id: _id,
          }
          dispatch(deleteAdminCouponCodeAction(data))
          getAllCouponList();
    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1  }));
        const data = {
            page: pageNumber?.selected + 1 
        };
        dispatch(getAllUsedCoupons(data))
        .then((res) => {
            // const { orderCountPerPage, results } = res
            // setState((prev) => ({
            //     ...prev,
            //     data: results,
            //     total: orderCountPerPage
            // }))
            console.log(" prev state : ", state, " current res ==> ", res)
            const { orderCountPerPage, resultsPerPage, results, totalcount } = res
            setState((prev) => ({
                ...prev,
                data: results,
                total: Math.ceil(totalcount / resultsPerPage) 
            }))
        })

    };

    const handleDeleteChange_user = () => {
        const data = {
            id: userId
        }
        dispatch(deleteUser(data))
            .then((res) => {
                const data = {
                    page: state.activePage,
                };
                dispatch(getAllUsers(data))
                    .then((res) => {
                        const { userMain, totalcount, resultsPerPage } = res
                        setState((prev) => ({
                            ...prev,
                            data: userMain,
                            total: totalcount / resultsPerPage
                        }))
                        getAllData();
                        setDelete(false)
                    })


            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        console.log(" on active page ==> ", state.activePage, e.target.value)
        let data;
        if (e.target.value != ''){
             data = {
                page: 1,
                search: e.target.value
            };
        }else{
             data = {
                page: state.activePage,
                search: e.target.value
            };
        }


        dispatch(getAllUsedCoupons(data))
        .then((res) => {
            // const { orderCountPerPage, results } = res
            // setState((prev) => ({
            //     ...prev,
            //     data: results,
            //     total: orderCountPerPage
            // }))
            const { orderCountPerPage, resultsPerPage, results, totalcount } = res
            setState((prev) => ({
                ...prev,
                data: results,
                total: Math.ceil(totalcount / resultsPerPage) 
            }))
        })
    };

    return (
        <div >
        <div className="order-list mt-3">
                {/* <h3 className='marketing-header'>Redeemed Coupon List</h3>
                <hr /> */}
            <div className="service-list-header-option mt-4">

                {/* <div className="business-search w-50">
                    <input type="text" class="form-control" id="" placeholder="Search Coupon Code" name="search" onInput={onChangeSearch} />
                </div> */}

            </div>
            {/* <hr className='p-0 mb-0' /> */}

            <section class="pt-0">
                <div class=" mt-4">
                    <div class="table-responsive slaylewks-table">
                            <Table responsive>
                        <thead className='admin-coupon-list-head'>
                                <tr className='tabel-data-content'>
                                    {/* <th scope="col" width="4%"><input class="form-check-input" type="checkbox" /></th> */}
                                    <th className='admin-tabel-heading'>
                                        Coupon Code

                                    </th>
                                    <th className='admin-tabel-heading'>
                                    Coupon Name
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Used Count
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Status
                                    </th>
                                    <th className='admin-tabel-heading'>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                              
                                 {
                                    state?.data?.map((item, index)=>{
                                        console.log(item, "coupons")
                                    return(
                                        <tr className='tabel-data-content' key={index}>
                                                        <td class="sss">{item?.couponCode}</td>
                                                        <td>{item?.couponName}</td>
                                                        <td>{item?.couponCount}</td>
                                                        {
                                                        item?.status === "active" ? (
                                                            <td className='active-coupons'>Active</td>

                                                        ):(
                                                            <td className='expired-coupons'>Expired</td>

                                                        )
                                                        }
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="text-center">
                                                                    {/* <Dropdown.Item onClick={() => deleteOpen()}>Delete</Dropdown.Item> */}
                                                                    <Dropdown.Item onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                          
                                                        </td>
                                </tr>
                                    )
                                    })
                               
                               }

                               {
                                state?.total < 1 && (
                                    <p className='mt-5 mx-2'>No results found</p>

                                )
                               }
                               
                              
                             
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal
                                                                    show={Delete}
                                                                    onHide={()=>setDelete(false)}
                                                                    keyboard={false}
                                                                    className="text-center"
                                                                    centered
                                                                >
                                                                    <Modal.Header closeButton>
                                                                    
                                                                    </Modal.Header>
                                                                    <Modal.Body className='mb-5 px-3 py-0'>
                                                                        <h2>Delete</h2>
                                                                    <h6>Are you sure? you want to delete this profile permanently?</h6>
                                                                    <Row className='mt-5'>
                                                                        <Col md={6}>
                                                                        <Button className="image-delete-cancel" variant="" onClick={()=>setDelete(false)}>Cancel</Button>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                        <Button className="image-delete-delete" variant="" onClick={() => handleDeleteChange()}>
                                                                        Yes, Delete
                                                                        </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    </Modal.Body>
                                                                    
                                                                    
                                                                    
                                                            </Modal>
            </section>
            <div>
               
                {
                    state?.total > 1 && (
   
                        <ReactPaginate 
        
                        previousLabel="<<"
                        nextLabel=">>"
                        breakLabel="..."
                        pageCount={state.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination justify-content-center pagination-box'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                       />
                    )
                }
             
            </div>
        </div>
        </div>
    )
}

export default UsedCouponList;