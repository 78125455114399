import React from 'react'
import Footer from '../../components/elements/footer/Footer'
import Header from '../../components/elements/header/Header'
import Support from './Support'

function UserSupport() {
  return (
    <div>
      <Header />
      <div className='mt-5'>
      <Support />

      </div>
      <Footer />
    </div>
  )
}

export default UserSupport
