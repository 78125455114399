//Business/freelancer types
export const CHANGE_DASHBOARD_TYPE = 'CHANGE_DASHBOARD_TYPE';

export const CHANGE_DASHBOARD_STATUS = 'CHANGE_DASHBOARD_STATUS';
export const CHANGE_DASHBOARD_STATUS_FAIL = "CHANGE_DASHBOARD_STATUS_FAIL";

export const SERVICE_CREATION_SUCCESS = 'SERVICE_CREATION_SUCCESS';
export const SERVICE_CREATION_FAIL = 'SERVICE_CREATION_FAIL';
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_FAIL = 'SERVICE_UPDATE_FAIL';
export const GET_ONE_SERVICE_DETAILS_SUCCESS = 'GET_ONE_SERVICE_DETAILS_SUCCESS';
export const GET_ONE_SERVICE_DETAILS_FAIL = 'GET_ONE_SERVICE_DETAILS_SUCCESS';
export const GET_ALL_SERVICE_DETAILS_SUCCESS = 'GET_ALL_SERVICE_DETAILS_SUCCESS';
export const GET_ALL_SERVICE_DETAILS_FAIL = 'GET_ALL_SERVICE_DETAILS_FAIL';


export const APPOINTMENT_CREATION_SUCCESS = 'APPOINTMENT_CREATION_SUCCESS';
export const APPOINTMENT_CREATION_FAIL = 'APPOINTMENT_CREATION_FAIL';
export const APPOINTMENT_UPDATE_SUCCESS = 'APPOINTMENT_UPDATE_SUCCESS';
export const APPOINTMENT_UPDATE_FAIL = 'APPOINTMENT_UPDATE_FAIL';
export const GET_ONE_APPOINTMENT_DETAILS_SUCCESS = 'GET_ONE_APPOINTMENT_DETAILS_SUCCESS';
export const GET_ONE_APPOINTMENT_DETAILS_FAIL = 'GET_ONE_APPOINTMENT_DETAILS_FAIL';
export const GET_ALL_APPOINTMENT_DETAILS_SUCCESS = 'GET_ALL_APPOINTMENT_DETAILS_SUCCESS';
export const GET_ALL_APPOINTMENT_DETAILS_FAIL = 'GET_ALL_APPOINTMENT_DETAILS_FAIL';



export const ORDER_CREATION_SUCCESS = 'ORDER_CREATION_SUCCESS';
export const ORDER_CREATION_FAIL = 'ORDER_CREATION_FAIL';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';
export const GET_ONE_ORDER_DETAILS_SUCCESS = 'GET_ONE_ORDER_DETAILS_SUCCESS';
export const GET_ONE_ORDER_DETAILS_FAIL = 'GET_ONE_ORDER_DETAILS_FAIL';
export const GET_ALL_ORDER_DETAILS_SUCCESS = 'GET_ALL_ORDER_DETAILS_SUCCESS';
export const GET_ALL_ORDER_DETAILS_FAIL = 'GET_ALL_ORDER_DETAILS_FAIL';

export const GET_ALL_REQUEST_DETAILS_SUCCESS = "GET_ALL_REQUEST_DETAILS_SUCCESS";
export const GET_ALL_REQUEST_DETAILS_FAIL = ".GET_ALL_REQUEST_DETAILS_FAIL";

export const STAFF_CREATION_SUCCESS = 'STAFF_CREATION_SUCCESS';
export const STAFF_CREATION_FAIL = 'STAFF_CREATION_FAIL';
export const STAFF_UPDATE_SUCCESS = 'STAFF_UPDATE_SUCCESS';
export const STAFF_UPDATE_FAIL = 'STAFF_UPDATE_FAIL';
export const GET_ONE_STAFF_DETAILS_SUCCESS = 'GET_ONE_STAFF_DETAILS_SUCCESS';
export const GET_ONE_STAFF_DETAILS_FAIL = 'GET_ONE_STAFF_DETAILS_FAIL';
export const GET_ALL_STAFF_DETAILS_SUCCESS = 'GET_ALL_STAFF_DETAILS_SUCCESS';
export const GET_ALL_STAFF_DETAILS_FAIL = 'GET_ALL_STAFF_DETAILS_FAIL';

export const GET_ALL_DASHBOARD_SETTINGS_FAIL = "GET_ALL_DASHBOARD_SETTINGS_FAIL";
export const GET_ALL_DASHBOARD_SETTINGS_SUCCESS = "GET_ALL_DASHBOARD_SETTINGS_SUCCESS";


//Admin Dashboard types
export const CHANGE_ADMIN_DASHBOARD_STATUS = 'CHANGE_ADMIN_DASHBOARD_STATUS';
export const CHANGE_ADMIN_DASHBOARD_STATUS_FAIL = "CHANGE_ADMIN_DASHBOARD_STATUS_FAIL";


export const PROPOSAL_UPDATE_SUCCESS = 'PROPOSAL_UPDATE_SUCCESS';
export const PROPOSAL_UPDATE_FAIL = 'PROPOSAL_UPDATE_FAIL';
export const GET_ONE_PROPOSAL_DETAILS_SUCCESS = 'GET_ONE_PROPOSAL_DETAILS_SUCCESS';
export const GET_ONE_PROPOSAL_DETAILS_FAIL = 'GET_ONE_PROPOSAL_DETAILS_FAIL';
export const GET_ALL_PROPOSAL_DETAILS_SUCCESS = 'GET_ALL_PROPOSAL_DETAILS_SUCCESS';
export const GET_ALL_PROPOSAL_DETAILS_FAIL = 'GET_ALL_PROPOSAL_DETAILS_FAIL';


export const USER_CREATION_SUCCESS = 'USER_CREATION_SUCCESS';
export const USER_CREATION_FAIL = 'USER_CREATION_FAIL';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const GET_ONE_USER_DETAILS_SUCCESS = 'GET_ONE_USER_DETAILS_SUCCESS';
export const GET_ONE_USER_DETAILS_FAIL = 'GET_ONE_USER_DETAILS_FAIL';
export const GET_ALL_USER_DETAILS_SUCCESS = 'GET_ALL_USER_DETAILS_SUCCESS';
export const GET_ALL_USER_DETAILS_FAIL = 'GET_ALL_USER_DETAILS_FAIL';



export const BUSINESS_CREATION_SUCCESS = 'BUSINESS_CREATION_SUCCESS';
export const BUSINESS_CREATION_FAIL = 'BUSINESS_CREATION_FAIL';
export const BUSINESS_UPDATE_SUCCESS = 'BUSINESS_UPDATE_SUCCESS';
export const BUSINESS_UPDATE_FAIL = 'BUSINESS_UPDATE_FAIL';
export const GET_ONE_BUSINESS_DETAILS_SUCCESS = 'GET_ONE_BUSINESS_DETAILS_SUCCESS';
export const GET_ONE_BUSINESS_DETAILS_FAIL = 'GET_ONE_BUSINESS_DETAILS_FAIL';
export const GET_ALL_BUSINESS_DETAILS_SUCCESS = 'GET_ALL_BUSINESS_DETAILS_SUCCESS';
export const GET_ALL_BUSINESS_DETAILS_FAIL = 'GET_ALL_BUSINESS_DETAILS_FAIL';



export const FREELANCER_CREATION_SUCCESS = 'FREELANCER_CREATION_SUCCESS';
export const FREELANCER_CREATION_FAIL = 'FREELANCER_CREATION_FAIL';
export const FREELANCER_UPDATE_SUCCESS = 'FREELANCER_UPDATE_SUCCESS';
export const FREELANCER_UPDATE_FAIL = 'FREELANCER_UPDATE_FAIL';
export const GET_ONE_FREELANCER_DETAILS_SUCCESS = 'GET_ONE_FREELANCER_DETAILS_SUCCESS';
export const GET_ONE_FREELANCER_DETAILS_FAIL = 'GET_ONE_FREELANCER_DETAILS_FAIL';
export const GET_ALL_FREELANCER_DETAILS_SUCCESS = 'GET_ALL_FREELANCER_DETAILS_SUCCESS';
export const GET_ALL_FREEALNCER_DETAILS_FAIL = 'GET_ALL_FREEALNCER_DETAILS_FAIL';


export const GET_ALL_USED_COUPONS = 'GET_ALL_USED_COUPONS';
export const GET_ALL_USED_COUPONS_FAIL = 'GET_ALL_USED_COUPONS_FAIL';