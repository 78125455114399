const apiUrl = process.env.REACT_APP_API_URL;

export const USER_CONFIG = {
    getUserLocation: `${apiUrl}/userlocation/getone`,
    updateUserLocation: `${apiUrl}/userlocation/update`,
    createUserLocation: `${apiUrl}/userlocation/create`,

    getAvailableSlotsApi: `${apiUrl}/availableSlots`,
    slotBookingApi: `${apiUrl}/slotbooking`,
    freelancerRequestApi: `${apiUrl}/requests/create`,


    createOrderApi: `${apiUrl}/order/create`,
    updateOrderApi: `${apiUrl}/order/reschedule`,

    createReviewApi: `${apiUrl}/reviews/create`,

    getAllUserOrdersApi: `${apiUrl}/order/getUserall`,
    getOneUserOrdersApi: `${apiUrl}/order/getone`,
    getOneUserRequestApi: `${apiUrl}/requests/findone`,

    getAllUserRequestApi:`${apiUrl}/request/getUserall`,

    getUserFavoritesApi: `${apiUrl}/likeOrDislike`,
    getFavoriteServiceApi: `${apiUrl}/favlist`,
    getFavoriteServiceUpdateApi: `${apiUrl}/profilefav`,

};
