import React, { useEffect, useState } from "react";
import Header from "../../components/elements/header/Header";
import Footer from "../../components/elements/footer/Footer";
import BusinessHome from "./businessHome";
import NewBusinessHome from "./NewBusinessHome"

const BusinessRegisterPage = () => {

    return (
        <div className="businessRegisterPage">
            <Header />
            {/* <BusinessHome /> */}
            <NewBusinessHome />
      
        </div>
    );
};

export default BusinessRegisterPage;