import axios from 'axios';
import * as TYPES from './types.js';
import { toast } from "react-toastify";
import VENDOR_SERVICES from "./services";
import { logoutFromLocalStorage } from '../auth/actions';

export const changeVendorType = (type) => (dispatch) => {
    dispatch({
        type: TYPES.CHANGE_VENDOR_TYPE,
        payload: type
    });
};

export const changeHomeSearch = (data) => (dispatch) => {
    dispatch({
        type: TYPES.CHANGE_HOME_SEARCH,
        payload: data
    });
};


export const clearHomeSearch = (data) => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_HOME_SEARCH,
    });
};
export const getAllVendorSearch = (data)=> (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_VENDOR_SEARCH,
    })
}




export const vendorRegister = (data) => (dispatch) => {
    return VENDOR_SERVICES.vendorRegister(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                console.log("data => ", data);
                toast.success(data.message);
                return dispatch({
                    type: TYPES.VENDOR_REGISTER_SUCESS,
                    payload: { ...data.serviceprovider }
                });
            } else {
                toast.error(data.message);
                return dispatch({
                    type: TYPES.VENDOR_REGISTER_FAIL,
                });
            }

        },
        (error) => {
            // console.log("Error => ", error.response)
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    // console.log(data.error);
                    const { code, keyValue } = data?.error
                    if (code && code === 11000) {
                        if (keyValue) {
                            Object.keys(keyValue).map((item) => {
                                toast.error(item + " already exists");
                            })
                        }
                    }
                    else {
                        toast.error(data.message);
                    }

                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.VENDOR_REGISTER_FAIL,
            });
            return Promise.reject(error);
        },
    );
};



export const fileUpload = (data) => (dispatch) => {
    return VENDOR_SERVICES.fileUpload(data).then(
        (response) => {
            const { data } = response;
            console.log("Response => ", data);
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
            }
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};

export const fileUploadLocalHost = (data) => (dispatch) => {
    return VENDOR_SERVICES.fileUploadLocalHost(data).then(
        (response) => {
            const { data } = response;
            console.log("Response => ", data);
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
            }
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};

export const downloadServicesTemplateAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.downloadServicesTemplateService().then(
        (response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
            }
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};

export const downloadServicesInfoTemplateAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.downloadServicesInfoTemplateService().then(
        (response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
            }
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};





export const exploreAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.exploreService(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                // dispatch({
                //     type: TYPES.GET_ALL_VENDOR_SEARCH,
                //     payload: data
                // });
                return data;

            } else {
                console.log(response);
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};


export const businessDetailsAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.businessDetailsService(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                return data;
            } else {
                console.log(response);
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};



export const businessDetailsByGenderAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.businessDetailsByGenderService(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                return data;
            } else {
                console.log(response);
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        },
    );
};




export const createBusinessReview = (data) => (dispatch) => {
    return VENDOR_SERVICES.createBusinessReviewService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllBusinessReview = (data) => (dispatch) => {
    return VENDOR_SERVICES.getAllBusinessReviewService(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const getAvgBusinessReview = (data) => (dispatch) => {
    return VENDOR_SERVICES.getAvgReviewsService(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const getAllBusinessGallery = (data) => (dispatch) => {
    return VENDOR_SERVICES.getAllBusinessGalleryService(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const getSomeSalonsAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.getSomeSalonsService(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getSomeSalonsLocationAction = (data) => (dispatch) => {
    return VENDOR_SERVICES.getSalonsLocationService(data)
        .then((response) => {
            console.log(response , "location res")

            if (response && response.status === 200) {
                const { data } = response;
                console.log(data , "location res")

                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


