import React from "react";
import { Modal } from "react-bootstrap";
import RegisterImg from "../../../assets/images/vendor_icons/Secure-login.svg";

const RegisterMsgModal = (props) => {

    return(
        <div className="vendor-register-success-modal">
             <Modal
                {...props}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="m-3">
                    <div className="text-center">
                        <img className="logo"
                            src={RegisterImg}
                            alt="Success logo" 
                        />
                        <h4 className="mt-4">Login or Register to create an Business account </h4>
                        <div>
                            <p className="text-muted mt-2">
                            Our team will verify the details and update once your page is live on Slaylewks!
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RegisterMsgModal;