import React, { useState, useEffect, useContext, forwardRef } from 'react';
import InfoImg from "../../assets/images/info.png"
import CalenderImg from "../../assets/images/calender.png"
import MenImg from "../../assets/images/men.png"
import TimerImg from "../../assets/images/timer.png"
import RazorpayImg from "../../assets/images/Razorpay.svg"
import { Accordion, Card, Form, Row, Col, Button } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import OrderSummary from './OrderSummary';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import Payment from '../payment';
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer';
import { getAvailableSlotsAction, slotBookingAction, updateUserLocationAction, createUserLocationAction, getUserLocationAction, addOrderDetails, addAppointmentDetails, updateOrderAction, getUserOneOrderAction, addUserLocationDetails, clearSlotDetails, RequestBridalAction, clearUserAllDetailsAction, getUserOneRequestAction } from "../../redux/user/actions";
import { changeServiceProvider, addManyServicesToCart, createCartActions, clearCartAction, clearAllCartDetails, RequestResponce } from "../../redux/cart/actions";
import RescheduleConfirm from './RescheduleConfirm';
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { NutFill } from 'react-bootstrap-icons';
import CartFooter from '../../components/elements/footer/CartFooter';
import { cartCheckOutValidation } from '../../validations/yub_schemas/cartCheckOutValidation';
import OrderSummaryNew from './OrderSummaryNew';
import("moment-timezone");

function CustomToggle({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a class="btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>
            <h5>{children}</h5>
        </a>
    );
}

function CartCheckoutBridal() {
    const { order } = useSelector((state) => state.payment);
    const { couponCodeName } = useSelector((state) => state.getlocation);
    const [orderDetails, setOrderDetails] = useState({})
    const [currentSlotDetails, setCurrentSlotDetails] = useState({});
    const [userContactDetails, setUserContactDetails] = useState({});
    const [appointmentTimeStamp, setAppointmentTimeStamp] = useState("");
    const [isToday, setIsToday] = useState(true);
    const [serviceType, setServiceType] = useState("home");
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [message, setMessage] = useState("");

    const [tomorrowDate, settomorrow] = useState(moment(new Date().getDate() + 1).format("YYYY-MM-DD"))
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [day, setDay] = useState(weekdays[moment(new Date()).weekday()]);
    const [currentTime, setCurrentTime] = useState(moment(new Date()).format("hh:mm A"));
    const [selectedTime, setSelectedTime] = useState("");
    const [rescheduleConfirm, setRescheduleConfirm] = useState(false);
    const [originalDate, setOriginalDate] = useState("");
    const [originalTime, setOriginalTime] = useState("");
    const [isOriginalDate, setIsOriginalDate] = useState(false);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [datePick, setDatePick] = useState(new Date());
    const [taxAmount, setTaxAmout] = useState(0);
    const [taxAbleAmount, setTaxableAmount] = useState(0);
    const [gstPrice, setGstPrice] = useState(0);
    const [disableSet, setDisableSet] = useState(true);
    const [activeTab, setActiveTab] = useState("");
    if (activeTab !== window.location.pathname)
        setActiveTab(window.location.pathname);

    const changeEdit = () => {
        setDisableSet(false);
    }

    const [key, setKey] = useState(0)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const type = searchParams.get("type");
    const id = searchParams.get("id");


    const { slotDetails, appointmentDetails, userLocationDetails } = useSelector(state => state.user)
    const { user } = useSelector((state) => state.auth);
    const { servicesCart, serviceProviderCart } = useSelector((state) => state.cart);


    const [isSelectedSlot, setIsSelectedSlot] = useState(false);
    const { vendor } = useSelector(state => state.business);

    const tomorrow = new Date();

    const [startDate, setStartDate] = useState(tomorrow.setDate(tomorrow.getDate() + 1));


    useEffect(() => {

        if (servicesCart && servicesCart.length > 0) {
            let cartPrice = 0
            let discountPrice = 0;
            servicesCart.forEach((item) => {
                let discount = parseInt(item?.service_original_price?.['$numberDecimal']) * parseInt(item?.service_discount?.['$numberDecimal']) / 100
                cartPrice += parseInt(item?.service_original_price?.['$numberDecimal']);
                discountPrice += discount;

            })
            setOriginalPrice(cartPrice.toFixed(2));
            setDiscount(discountPrice.toFixed(2));
            let total_amount = cartPrice - discountPrice;
            let gst_price = 18 / 100 * total_amount;
            let taxable_amount = (total_amount * 100 / 118).toFixed(2);
            let tax_amount = (total_amount - taxable_amount).toFixed(2);
            setTotalAmount(total_amount.toFixed(2));
            setTaxAmout(tax_amount);
            setTaxableAmount(taxable_amount);
            setGstPrice(gst_price)




        }

    }, [servicesCart]);

  
   
    const redirectRequestSucess = (type) => {
        navigate(`/business/request/${id}}?type=${type}`);

    }
    useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneRequestAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);


    const handleMessageChange = (event) => {
        const message = event.target.value;
        if (message.length-1 >= 120) {
            formik.setFieldValue('message', message.substring(0, 120));
        } else {
            setMessage(message);
        }
    };


    const formik = useFormik({
        initialValues: {
            selected_date: new Date(),
            selected_time: "",
            user: user?._id,
            alternate_name: user?.username || '',
            alternate_phone: user?.mobile_no || '',
            alternate_email: user?.email_id || '',
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            message: "",
            services: servicesCart.map(item => item._id),
            serviceprovider: serviceProviderCart?._id,
            service_type: servicesCart[0]?.type,
            vendor_type: serviceProviderCart?.vendor_type,
            original_price: originalPrice,
            discount: order?.discount,
            final_price: order?.total_amount / 100,
            coupon: order?.coupon_amount,
            gst_value: order?.gst_amount / 100,
            coupon_code: couponCodeName,

        },
        validationSchema: cartCheckOutValidation,
        onSubmit: (values) => {

            const data = {
                selected_date: values?.selected_date || startDate,
                selected_time: values.selected_time,
                user: user?._id,
                alternate_name: user?.username || values.alternate_name,
                alternate_phone: user?.mobile_no || values.alternate_phone,
                alternate_email: user?.email_id || values.alternate_email,
                service_type: servicesCart[0]?.type,
                vendor_type: serviceProviderCart?.vendor_type,
                original_price: originalPrice,
                coupon: order?.coupon_amount,
                discount: discount,
                final_price: order?.total_amount / 100,
                address: values.address,
                city: values.city,
                state: values.state,
                country: values.country,
                zipcode: values.zipcode,
                message: values.message,
                services: servicesCart.map(item => item._id),
                serviceprovider: serviceProviderCart?._id,
                taxable_amount: taxAbleAmount,
                tax_amount: taxAmount,
                appointment_date_time: values?.selected_date,
                gst_value: order?.gst_amount / 100,
                coupon_code: couponCodeName,

            };
            dispatch(RequestBridalAction(data))
                .then((res) => {
                    let cartIds = servicesCart?.map(item => item?.cart_id)
                    if (cartIds?.length > 0) {
                        dispatch(clearCartAction({ id: cartIds }))
                    }
                    dispatch(clearAllCartDetails());
                    dispatch(clearUserAllDetailsAction());
                    navigate(`/business/bridal/request?id=${res?.data?.data?._id}`)
                })


        },
    });

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button variant="outline-none" onClick={onClick} ref={ref}>
            <img src={CalenderImg} alt="calender" />Select Date
        </Button>

    ));
    const selectSlotDate = (date) => {
        setDate(moment(date).format("YYYY-MM-DD"))

    }

    const handleReschduleConfirm = () => {
        setRescheduleConfirm(true)
    }

    const handleReschduleCancel = () => {
        navigate("/orders")
    }

    const handleReschduleSlotBooking = async () => {
        let appointmentData = {
            appointment_id: appointmentDetails?._id,
            serviceprovider: serviceProviderCart?._id,
            date: date,
            timeSlot: moment(date + " " + selectedTime, "YYYY-MM-DD hh:mm A").utc().format(),
            time: selectedTime,
            user: user?._id,
            services: servicesCart.map(item => item._id)
        }

        const appointmentRes = await dispatch(slotBookingAction(appointmentData));
        if (!appointmentRes?._id) {
            toast.warn("Slot booking failed");
            return
        }
        handleReschdule();
    }

    const handleReschdule = () => {
        let data = {
            id: order,
            appointment: Math.floor(moment(appointmentDetails.appointment_start_dateTime).valueOf() / 1000),
        }
        dispatch(updateOrderAction(data))
            .then((res) => {
                navigate(`/business/reschdule/success/${id}`)
            })
            .catch((err) => {
                console.log(err)
                navigate(`/business/reschdule/failed/${id}`)
            })
    }


    const handleSlotBooking = (slot) => {

        setSelectedTime(slot);

        // let data = {
        //     serviceprovider: serviceProviderCart?._id,
        //     date: date,
        //     timeSlot: moment(date + " " + slot).utc().format(),
        //     time: slot,
        //     user: user?._id,
        //     services: servicesCart.map(item => item._id)
        // }

        // if (appointmentDetails?._id) {
        //     console.log("Slot updating successfully");
        //     data.appointment_id = appointmentDetails?._id
        // }

        // setAppointmentTimeStamp(moment(date + " " + slot).utc().format());
        // dispatch(slotBookingAction(data))
        //     .then((res) => {
        //         const { message, appointment } = res;
        //         setCurrentSlotDetails(appointment)
        //         dispatch(addAppointmentDetails(appointment))
        //         let slotRequest = {
        //             id: serviceProviderCart?._id,
        //             date: date
        //         }
        //         dispatch(getAvailableSlotsAction(slotRequest))
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })

    }


    const redirectBusinessPage = () => {
        navigate(`/business/${serviceProviderCart?._id}`)
    }


    const changesServiceType = (e) => {
        const { name, value, checked } = e.target
        setServiceType(value)
    }
    const changeServiceTab = (value) => {
        setServiceType(value)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 100,

            behavior: 'smooth'

        });
    };



    const [openAccordion, setOpenAccordion] = useState(true);
    const [errortime, setErrorTime] = useState(false);
    const [errorcontinueMsg, setErrorContinueMsg] = useState(false);

    const ChangeAccordion = () => {
        if (formik.values.selected_time == "") {
            setErrorTime(true);
        } else {
            setErrorTime(false);
            setOpenAccordion(false);
            setErrorContinueMsg(false);
        }
    }
    const ChangeAccordionUpdate = () => {
        setOpenAccordion(true);
    }

    const viewerrorContinue = () => {
        if (openAccordion === true) {
            setErrorContinueMsg(true);
        } else {
            setErrorContinueMsg(false);
        }
    }

    const handleChange = (date) => {
        setDate(date);
    };

    return (
        <div className="cart-checkout-page">
            <RescheduleConfirm
                show={rescheduleConfirm}
                onHide={() => setRescheduleConfirm(false)}
                selectedDate={date}
                selectedTime={selectedTime}
                handleReschdule={() => handleReschduleSlotBooking()}
            />
            <Header />
            <section>
                <div class="container mt-2">
                    <div class="row">

                        {
                            type !== "reschedule" && (
                                <div class="col-md-8 mt-3">
                                    <Form noValidate className="" onSubmit={formik.handleSubmit}>
                                        <FormikProvider value={formik}>

                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Card.Header className="checkout-card-header-active">
                                                        <CustomToggle eventKey="0" >
                                                            <span className=''>Date / Time</span>
                                                        </CustomToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>

                                                            <Row>

                                                                <Col md={4}>

                                                                    <h6>Select Date</h6>
                                                                    <DatePicker
                                                                        name="selected_date"
                                                                        // selected={selected_date}
                                                                        // onChange={(date) => selectSlotDate(date)}
                                                                        onChange={date => formik.setFieldValue('selected_date', date)}
                                                                        customInput={<ExampleCustomInput />}
                                                                        minDate={startDate}
                                                                        showDisabledMonthNavigation
                                                                        inline
                                                                    />

                                                                </Col>
                                                                <Col md={8}>
                                                                    <div className="mx-3">
                                                                        <h6>Select Time</h6>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <Form.Select
                                                                                        name="selected_time"
                                                                                        onChange={formik.handleChange}
                                                                                        value={formik.values.selected_time}
                                                                                        isInvalid={!!formik.touched.selected_time && !!formik.errors.selected_time}>
                                                                                        <option value="">00:00 AM</option>
                                                                                        <option value="01:00 AM">01:00 AM</option>
                                                                                        <option value="02:00 AM">02:00 AM</option>
                                                                                        <option value="03:00 AM">03:00 AM</option>
                                                                                        <option value="04:00 AM">04:00 AM</option>
                                                                                        <option value="05:00 AM">05:00 AM</option>
                                                                                        <option value="06:00 AM">06:00 AM</option>
                                                                                        <option value="07:00 AM">07:00 AM</option>
                                                                                        <option value="08:00 AM">08:00 AM</option>
                                                                                        <option value="09:00 AM">09:00 AM</option>
                                                                                        <option value="10:00 AM">10:00 AM</option>
                                                                                        <option value="11:00 AM">11:00 AM</option>
                                                                                        <option value="12:00 AM">12:00 AM</option>
                                                                                        <option value="01:00 PM">01:00 PM</option>
                                                                                        <option value="02:00 PM">02:00 PM</option>
                                                                                        <option value="03:00 PM">03:00 PM</option>
                                                                                        <option value="04:00 PM">04:00 PM</option>
                                                                                        <option value="05:00 PM">05:00 PM</option>
                                                                                        <option value="06:00 PM">06:00 PM</option>
                                                                                        <option value="07:00 PM">07:00 PM</option>
                                                                                        <option value="08:00 PM">08:00 PM</option>
                                                                                        <option value="09:00 PM">09:00 PM</option>
                                                                                        <option value="10:00 PM">10:00 PM</option>
                                                                                        <option value="11:00 PM">11:00 PM</option>
                                                                                        <option value="12:00 PM">12:00 PM</option>

                                                                                    </Form.Select>
                                                                                    {
                                                                                        errortime && formik.values.selected_time == "" ? (
                                                                                            <p className='error-message'> Please select your time</p>

                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Row className='mt-2'>
                                                                    <Col md={6}>
                                                                        {
                                                                            openAccordion ? (
                                                                                <button type='button' className="cart-checkout-continue-update" onClick={ChangeAccordion}>Continue</button>

                                                                            ) : (
                                                                                <button type='button' className="cart-checkout-continue-update" onClick={ChangeAccordionUpdate}>Change</button>

                                                                            )
                                                                        }

                                                                    </Col>
                                                                </Row>
                                                            </Row>

                                                            <Row>
                                                                <Accordion defaultActiveKey="0">
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>Services</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <Row className="mt-3">
                                                                                <Col className='cart-serviceprovider-details' md={12}>
                                                                                    <h6>{serviceProviderCart?.business_name}</h6>
                                                                                    <p>{serviceProviderCart?.location_details?.area + " " + serviceProviderCart?.location_details?.city}</p>
                                                                                </Col>
                                                                                <hr />
                                                                            </Row>
                                                                            {

                                                                                type !== "reschedule" && (
                                                                                    <div class="row">
                                                                                        <div class="col-md-12">
                                                                                            {
                                                                                                servicesCart && servicesCart.length > 0 && (

                                                                                                    <div>


                                                                                                        {
                                                                                                            servicesCart && servicesCart.length > 0 && (
                                                                                                                servicesCart.map((item, index) => {
                                                                                                                    let gender = ""
                                                                                                                    if (item.gender && item.gender.length > 0) {
                                                                                                                        gender = item?.gender?.map((i) => i.gender_name).join(" ")
                                                                                                                    }
                                                                                                                    let itemPrice = item?.service_price?.["$numberDecimal"];
                                                                                                                    let servicePrice = Math.round(itemPrice)
                                                                                                                    return (
                                                                                                                        <div key={index}>

                                                                                                                            <Row className='mb-2'>

                                                                                                                                <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                                                                    <span>{gender}</span>
                                                                                                                                    <p class="service-heading">{item?.service_name}</p>
                                                                                                                                    <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                                                                </Col>
                                                                                                                                <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                                                                    <div className='cart-check-out-amount'>
                                                                                                                                        <p className='mt-4'>{servicePrice + ".00" + " ₹ "}</p>
                                                                                                                                    </div>
                                                                                                                                </Col>

                                                                                                                            </Row>
                                                                                                                        </div>

                                                                                                                    )
                                                                                                                })
                                                                                                            )
                                                                                                        }
                                                                                                    </div>

                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card onClick={viewerrorContinue}>
                                                    <Card.Header className={openAccordion ? "checkout-card-header" : "checkout-card-header-active"}>
                                                        <CustomToggle eventKey="1" >
                                                            <span>Request Form</span>
                                                        </CustomToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1" hidden={openAccordion}>
                                                        <Card.Body>


                                                            <>
                                                                <h5 className='text-bold my-3'>
                                                                    Select Service
                                                                </h5>
                                                                <Row>

                                                                    <Col md={12}>
                                                                        <Card className={serviceType === "home" ? "service-type-active" : "default-service-type"} onClick={() => changeServiceTab('home')}>
                                                                            <p>Home Service</p>
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </>

                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="contact-details mt-0">

                                                                        <span className="edit-icon-checkout" onClick={changeEdit}> <ion-icon name="create-outline"></ion-icon>Edit</span>
                                                                        <h6 class="btn p-0 mb-0">Contact detail</h6>
                                                                        <div class="mb-3 mt-3">
                                                                            <input type="text" class="form-control" id="username" placeholder="Username"
                                                                                name="alternate_name"
                                                                                onChange={formik.handleChange}
                                                                                value={formik.values.alternate_name}
                                                                                disabled={disableSet}
                                                                            />
                                                                        </div>
                                                                        <p className="manual-error-style px-3">
                                                                            <ErrorMessage name="alternate_name" />
                                                                        </p>
                                                                        <div class="mb-3">
                                                                            <input type="phone" class="form-control" id="phone_no" placeholder="Phone Number"
                                                                                name="alternate_phone"
                                                                                onChange={formik.handleChange}
                                                                                value={formik.values.alternate_phone}
                                                                                disabled={disableSet}
                                                                            />
                                                                        </div>
                                                                        <p className="manual-error-style px-3">
                                                                            <ErrorMessage name="alternate_phone" />
                                                                        </p>
                                                                        <div class="mb-3 mt-3">
                                                                            <input type="alternate_email" class="form-control" id="alternate_email" placeholder="Enter alternate_email"
                                                                                name="alternate_email"
                                                                                onChange={formik.handleChange}
                                                                                value={formik.values.alternate_email}
                                                                                disabled={disableSet}

                                                                            />
                                                                        </div>
                                                                        <p className="manual-error-style px-3">
                                                                            <ErrorMessage name="alternate_email" />
                                                                        </p>
                                                                        {
                                                                            serviceType === "home" && (
                                                                                <>
                                                                                    <h6 class="btn p-0 my-4">Venue Address</h6>
                                                                                    <div class="mb-0 mt-0">
                                                                                        <input type="text" class="form-control" id="address" placeholder="Address"
                                                                                            name="address"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.address}
                                                                                            style={{color:"red !important"}}
                                                                                        />
                                                                                    </div>
                                                                                    <p className="manual-error-style px-3">
                                                                                        <ErrorMessage name="address" />
                                                                                    </p>
                                                                                    <div class="mb-3 mt-3">
                                                                                        <input type="text" class="form-control" id="city" placeholder="City"
                                                                                            name="city"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.city} />
                                                                                    </div>
                                                                                    <p className="manual-error-style px-3">
                                                                                        <ErrorMessage name="city" />
                                                                                    </p>
                                                                                    <div class="mb-3 mt-3">
                                                                                        <input type="text" class="form-control" id="state" placeholder="State"
                                                                                            name="state"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.state} />
                                                                                    </div>
                                                                                    <p className="manual-error-style px-3">
                                                                                        <ErrorMessage name="state" />
                                                                                    </p>
                                                                                    <div class="mb-3 mt-3">
                                                                                        <input type="text" class="form-control" id="country" placeholder="Country"
                                                                                            name="country"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.country} />
                                                                                    </div>
                                                                                    <p className="manual-error-style px-3">
                                                                                        <ErrorMessage name="country" />
                                                                                    </p>
                                                                                    <div class="mb-3 mt-3">
                                                                                        <input type="text" class="form-control" id="zipcode" placeholder="ZIP code" name="zipcode"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.zipcode} />
                                                                                    </div>
                                                                                    <p className="manual-error-style px-3">
                                                                                        <ErrorMessage name="zipcode" />
                                                                                    </p>
                                                                                    <div class="mb-3 mt-3">
                                                                                        <textarea class="form-control" rows="5" id="comment" name="message" placeholder="Message"

                                                                                            onChange={(event) => {
                                                                                                formik.handleChange(event);
                                                                                                handleMessageChange(event)
                                                                                            }}
                                                                                            value={formik.values.message}
                                                                                        ></textarea>
                                                                                        <span class="count-text">{message.length} / 120</span>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <button type="submit" class="cart-checkout-continue-bridal mt-3">Send Request</button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </FormikProvider>
                                    </Form>
                                    {
                                        errorcontinueMsg ? (
                                            <p className='error-message'>Please select date and time continue to the request form</p>
                                        ) : (
                                            null
                                        )
                                    }

                                    <p class="review-order mt-0 pt-0">Make sure the appointment information is correct before you send request</p>
                                </div>
                            )
                        }

                        <div class="col-md-4">
                            {
                                activeTab === "/payment/checkout/bridal" ? (

                                    <>
                                        {
                                            type !== "reschedule" && (
                                                <>
                                                    <OrderSummaryNew />
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            type !== "reschedule" && (
                                                <>
                                                    <OrderSummary continue={false} />
                                                </>
                                            )
                                        }
                                    </>
                                )

                            }

                        </div>
                    </div>
                </div>
            </section>
            <div className=''>
                <CartFooter />
            </div>
        </div>
    )
}

export default CartCheckoutBridal