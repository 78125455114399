import React, { useEffect, useState } from 'react';
import smallInfoImg from "../../assets/images/small-info.png";
import secureImg from "../../assets/images/secure.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCouponCodeAction, getApplyCouponCodeAction, removeFromCart } from '../../redux/cart/actions';
import { useNavigate } from "react-router-dom";
import { setOrderAmount } from '../../redux/payment/actions';
import { Card, Row, Col, Form, Modal, Table } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { FormikProvider, useFormik } from 'formik';
import { getCartAmounts } from '../../helpers/cartCalculation';

function OrderSummaryNew(props) {
    const { servicesCart } = useSelector((state) => state.cart);
    const { serviceProviderCart } = useSelector((state) => state.cart);
    const { order } = useSelector((state) => state.payment);
    const [gstPrice, setGstPrice] = useState(0);
    const [coupons, setCoupons] = useState();
    const [couponList, setCouponlist] = useState(false);
    const [gstEnable, setGstEnable] = useState(false)
    const [RemovedTotalAmount, setRemovedTotalAmount] = useState();
    const [couponValue, setCouponValue] = useState("");
    const [activeTab, setActiveTab] = useState("");
    if (activeTab !== window.location.pathname)
        setActiveTab(window.location.pathname);

    useEffect(() => {
        const data = {
            serviceprovider: serviceProviderCart?._id,
            servicecategory: serviceProviderCart?.service_category,
        }
        setGstEnable(serviceProviderCart?.is_GST_checked)
        if(activeTab == "/business/cart"){
            dispatch(getAllCouponCodeAction(data))
            .then((res) => {
                setCoupons(res)
                setGstEnable(res?.Serviceprovider?.is_GST_checked)
            })
        }
      
    }, []);

    const [originalPrice, setOriginalPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [addCoupon, setAddCoupon] = useState(false)
    const [couponAmount, setCouponAMount] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [taxAbleAmount, setTaxableAmount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const type = servicesCart?.map(service => service?.type);
    const servicetype = type[0];
    const [couponDiscount, setCouponDiscount] =useState(0)
    const vendorType = serviceProviderCart?.vendor_type;
    useEffect(() => {
        const { service_price,service_original_price, gstAmount, discountPrice, coupon, total } = getCartAmounts(servicesCart, serviceProviderCart);

        setOriginalPrice(service_original_price);
        setTaxableAmount(service_price);
        setDiscount(discountPrice);
        setGstPrice(gstAmount);
        setTaxAmount(gstAmount);
        setRemovedTotalAmount(total);
        setTotalAmount(total);
    }, [servicesCart]);


    const removeServiceFromCart = (service) => {
        dispatch(removeFromCart(service))
    }

    const goBack = () => {
        navigate(-1);
    }

    const redirectCheckoutPage = () => {
        navigate("/payment/checkout");
        dispatch(setOrderAmount({
            coupon_amount: couponDiscount,
            total_amount: totalAmount,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount:discount,
            currency: 'INR' // Change when going international
        }))
    }
    const redirectCheckoutPageBridal = () => {
        navigate("/payment/checkout/bridal");
    }


  const [applied, setApplied] = useState(false);
  const [CouponFinal, setCouponFinal] = useState(0);

    const getApplyCoupon = (item) => {
        let couponValue = 0;
        item?.discount_offer?.forEach((item)=>{
            if(totalAmount > item?.min && totalAmount < item?.max){
                couponValue = item?.amount
                setCouponDiscount(couponValue)
            }
        });

        if(item?.discount_offer_type === "in price") {
            let total_amount = (totalAmount - couponValue);
            let taxable_amount = (total_amount * 100 / 118).toFixed(2);
            let tax_amount = (total_amount - taxable_amount).toFixed(2);
            // setAddCoupon(true);
            setCouponValue(item?.custom_code)
            formik.setFieldValue('coupon_value',item?.custom_code)
            setApplied(true);
            setTotalAmount(total_amount);
            setCouponFinal(couponValue);
            dispatch(setOrderAmount({
                total_amount: total_amount,
                taxable_amount: taxable_amount,
                tax_amount: tax_amount,
                discount_amount: discount,
                coupon_amount: couponValue,
                currency: 'INR' // Change when going international
            }))
           
          
        } else {
            let already = totalAmount;
            let discount = couponValue;
            let Coupon_amount = Math.round(already * (discount / 100));
            let total_amount = (already - Coupon_amount)
            let taxable_amount = (total_amount * 100 / 118).toFixed(2);
            let tax_amount = (total_amount - taxable_amount).toFixed(2);
            setTotalAmount(total_amount);
            // setAddCoupon(true);
            setCouponValue(item?.custom_code)
            formik.setFieldValue('coupon_value',item?.custom_code)
            setApplied(true);
            setCouponFinal(Coupon_amount);
            dispatch(setOrderAmount({
                total_amount: total_amount,
                taxable_amount: taxable_amount,
                tax_amount: tax_amount,
                discount_amount: discount,
                coupon_amount: Coupon_amount,
                currency: 'INR' // Change when going international
            }))
         
        } 

    }

    const setRemoveCoupon = () => {
        setApplied(false);
        setCouponAMount("");
        setTotalAmount(RemovedTotalAmount);
        setAddCoupon(false);
        setCouponValue("");
        formik.setFieldValue('coupon_value', "")
        setCouponFinal("");
        dispatch(setOrderAmount({
            total_amount: RemovedTotalAmount,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount: discount,
            coupon_amount: 0,
            currency: 'INR' // Change when going international
        }))
    }

    const setRemoveCouponItem = () => {
        setCouponlist(false)
        setApplied(false);
        setCouponAMount("");
        setTotalAmount(RemovedTotalAmount);
        setAddCoupon(false);
        setCouponValue("");
        formik.setFieldValue('coupon_value', "")
        setCouponFinal("");
        dispatch(setOrderAmount({
            total_amount: RemovedTotalAmount,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount: discount,
            coupon_amount: 0,
            currency: 'INR' // Change when going international
        }))
    }
    const formik = useFormik({
        initialValues: {
            coupon_value: ''
        },
        // validationSchema: signUpValidation,
        onSubmit: (values) => {
            const data = {
                coupon_value: values.coupon_value
            };
            dispatch(getApplyCouponCodeAction(data))
          console.log(data, "coupons")
          setAddCoupon(true);
          setCouponlist(false);
              
        },
    });
  
    const applyCoupons = () =>{
        if(formik.values.coupon_value !== ""){
            formik.handleSubmit()
        }
        setAddCoupon(false)
    }

    return (
        <div className="order-summary">
            <Row className='mt-3'>
                <Card className='total-summary-card p-0 mb-0'>
                    <div className='mx-3'>
                        <div className='total-display-card'>
                            <Row className='item-component'>
                                <Col>
                                    <div className='serivice-item-header mt-2 mx-2'>
                                        <h6 className='w-75'>Total Services</h6>
                                        <p>{servicesCart?.length}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <hr className='p-0 m-0' />

                        <div className='mt-2 mx-2'>
                            <Row>
                                {
                                    activeTab === "/payment/checkout" || activeTab === "/payment/checkout/bridal"  ?(
                                        null
                                    ):(
                                        <>
                                         <Form onSubmit={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <div className='coupon-code-component mt-4 mb-2'>

                                        <Form.Control
                                            type="text"
                                            id="couponText"
                                            className="coupon-input-box"
                                            aria-describedby="passwordHelpBlock"
                                            placeholder='Discount coupon'
                                            value={formik.values.coupon_value}
                                            name="coupon_value"
                                            onChange={formik.handleChange}
                                        />
                                        {
                                            addCoupon ? (

                                                <h6 className='mt-2' onClick={setRemoveCoupon}>Remove</h6>
                                            ) : (
                                                <h5 className='mt-2' onClick={applyCoupons}>Apply</h5>
                                                )
                                        }


                                    </div>
                                    </FormikProvider>
                                </Form>
                                <div className='available-coupons'>
                                    <p
                                        onClick={() => setCouponlist(true)}
                                    >{coupons?.couponCount} coupons available</p>
                                </div>
                                        </>
                                    )
                                }
                           

                                <div className='float-end'>

                                    <Modal
                                        className="coupon-modal-size"
                                        show={couponList}
                                        // onHide={() => setCouponlist(false)}
                                        aria-labelledby="example-modal-sizes-title-sm"
                                    >
                                        <Modal.Header className='pb-0 mt-0 pt-0'>
                                            <div className='apply-coupon-headers mt-2'>
                                                <span onClick={()=>setCouponlist(false)}> <IoIosArrowBack /></span>
                                                <p className='py-0 my-0'>Apply Coupon</p>
                                            </div>
                                        </Modal.Header>
                                        <hr className='p-0 m-0' />
                                        <Modal.Body className=''>
                                            <Row>

                                                <Form onSubmit={formik.handleSubmit}>
                                                <FormikProvider value={formik}>

                                                    <div className='coupon-code-component mt-4 px-2'>

                                                        <Form.Control
                                                            type="text"
                                                            id="couponText"
                                                            className="coupon-input-box-card"
                                                            aria-describedby="passwordHelpBlock"
                                                            placeholder='Discount coupon'
                                                            value={formik.values.coupon_value}
                                                            onChange={formik.handleChange}
                                                            name="coupon_value"
                                                        />
                                                        {
                                                            addCoupon ? (

                                                                <h6 className='mt-2' onClick={setRemoveCoupon}>Remove</h6>
                                                            ) : (
                                                                <h5 className='mt-2' onClick={applyCoupons}>Apply</h5>
                                                                )
                                                        }



                                                    </div>
                                                    </FormikProvider>

                                                </Form>
                                            </Row>
                                            <Card className='coupon-list-inner-card mt-2'>
                                                <Card.Body >
                                                    {
                                                        coupons?.couponList?.map((item) => { 
                                                            return (
                                                                <div>
                                                                    {
                                                                        totalAmount > item.discount_offer?.[0]?.min && totalAmount < item.discount_offer?.[0]?.max && (
                                                                            <Card className='coupon-card-outer mb-3'>
                                                                            <Card.Body className='mb-0'>
                                                                                <Row> 
                                                                                <Table borderless>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                <th>
                                                                                                    {
                                                                                                        item?.coupon_type === "Custom code" ? (
                                                                                                            <h6 onClick={() => setCouponlist(false)}>{item?.custom_code}</h6>

                                                                                                        ):(
                                                                                                            <h6 onClick={() => setCouponlist(false)}>{item?.coupon_name}</h6>

                                                                                                        )

                                                                                                    }

                                                                                                </th>
                                                                                                <th>
                                                                                                  
                                                                                                {
                                                                                            applied ? (
                                                                                                null
                                                                                            ):(
                                                                                                <h2 onClick={() => getApplyCoupon(item)}>Apply</h2>
                                                                                            )
                                                                                        }
                                                                                                </th>
                                                                                            
                                                                                                </tr>
                                                                                            </thead>
                                                                                            </Table>
                                                         
                                                                                </Row>
                                                                                <Row>
                                                                      <Col md={11}>
                                                                      {
                                                                                            item?.discount_offer_type === "in price" ? (
                                                                                                <>
                                                                                                    {item?.discount_offer?.length > 0 && (
                                                                                                        <>
                                                                                                            {
                                                                                                                item?.discount_offer?.map((i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                        <h1>Get {i?.amount}₹ OFF On Orders Above {i?.min} ₹</h1>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
    
                                                                                                </>
    
                                                                                            ) : (
                                                                                                <>
                                                                                                {
                                                                                                    item?.discount_offer?.map((i) => {
                                                                                                        return <h1>Get {i?.amount}% OFF On Orders Above {i?.min} ₹</h1>
                                                                                                    })
                                                                                                }
                                                                                            </>
    
                                                                                            )
                                                                                        }
                                                                      </Col>
                                                                                </Row>
                                                                                <Row className='mb-0 mp-0'>
                                                                                    {
                                                                                        item?.time_limit_type === "life time" ? (
                                                                                            <p className='mt-3 mb-0'>Valid for {item?.time_limit_type}</p>
    
                                                                                        ) : (
                                                                                            <p className='mt-3 mb-0'>Valid till {moment(item?.toTime).format("MMM Do YY")}</p>
                                                                                        )
                                                                                    }
                                                                                </Row>
                                                                            </Card.Body>
    
                                                                        </Card>
                                                                        )
                                                                    }
                                                                   
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Modal.Body>
                                    </Modal>
                                </div>

                                <Col>
                                    <div  className={`
                                          ${activeTab === '/payment/checkout' ? "mt-4 serivice-item-amounts" : "serivice-item-amounts"}`}>
                                        <h6 className='w-75'>Price</h6>
                                        <p>{Math.round(originalPrice) + ".00"}<span className='float-end'>₹</span></p>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className='serivice-item-discount'>
                                        <h6 className='w-75'>Discount</h6>
                                        <p>{Math.round((order?.discount_amount)/ 100 )+ ".00"} <span className='float-end'>₹</span></p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                {
                                    gstEnable && (
                                        <div className='serivice-item-discount'>
                                        <h6 className='w-75'>GST 18%</h6>
                                        <p>{Math.round((order?.gst_amount)/100 ) + ".00"} <span className='float-end'>₹</span></p>
                                    </div>
                                    )
                                }
                                  
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <div className='serivice-item-coupon'>
                                        <h6 className='w-75'>Coupons for you</h6>
                                        {
                                            !order?.coupon_amount ? (
                                                <p>0.00<span className='float-end'>₹</span></p>
                                            ):(
                                                <p>{Math.round((order?.coupon_amount)/100)+ ".00"}<span className='float-end'>₹</span></p>

                                            )
                                        }
                                                {/* <p>{CouponFinal}<span className='float-end'>₹</span></p> */}
                                        {/* <h6 className='rupee-symbol'>₹</h6> */}

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <hr className='p-0 m-0' />
                        <Row className=''>
                            <Col>
                                <div className='serivice-item-total-end mt-2 mx-2'>
                                    <h6 className='w-75'>Total Amount</h6>
                                    <p>{Math.round(order?.total_amount)/100 + ".00"} <span className='float-end'>₹</span></p>
                                </div>
                            </Col>

                            {
                                discount > 0 && (
                                    <>
                                     {
                                            !order?.coupon_amount ? (
                                                <p class="order-save discount-text">You will save ₹{Math.round(order?.discount_amount)/100 + ".00"} on this order</p>
  
                                            ):(
                                                <p class="order-save discount-text">You will save ₹{Math.round((order?.discount_amount)/100  + (order?.coupon_amount)/100)+ ".00"} on this order</p>
                                            )
                                            }
                                    </>
                                    // <p class="order-save discount-text">You will save ₹{Math.round((order?.discount_amount) + (order?.coupon_amount))/100 + ".00"} on this order</p>
                                )
                            }


                        </Row>
                        {
                            vendorType === "business" ? (
                                <div>
                                    {
                                        servicetype !== "basic" ? (
                                            <>
                                                {
                                                    props.continue && (
                                                        <button class="cart-checkout-continue" onClick={redirectCheckoutPageBridal}>Continue</button>
                                                    )
                                                }
                                            </>

                                        ) : (
                                            <>
                                                {
                                                    props.continue && (
                                                        <button class="cart-checkout-continue" onClick={redirectCheckoutPage}>Continue</button>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            ) : (
                                <div>
                                    {
                                        props.continue && (
                                            <button class="cart-checkout-continue" onClick={redirectCheckoutPageBridal}>Continue</button>
                                        )
                                    }
                                </div>
                            )
                        }




                    </div>
                </Card>
                <p class="safe-secure mx-5 mt-2 w-75"><span className='me-2'><ion-icon name="shield-checkmark"></ion-icon></span>Safe and Secure Payment 100%.</p>
            </Row>
        </div>
    )
}

export default OrderSummaryNew