import logo from './logo.svg';
import './App.css';
import './assets/css/style.css';
import './assets/css/payment.css'
import './assets/css/stepper.css';
import "./assets/css/explore.css";
import "./assets/css/singlePage.css";
import "./assets/css/cart.css";
import "./assets/css/checkout.css";
import "./assets/css/payment-status.css";
import "./assets/css/orders.css";
import "./assets/css/profile.css";
import "./assets/css/home.css";
import "./assets/css/businessHome.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from './pages/home';
import Payment from './pages/payment';
import Header from './components/elements/header/Header';
import HomeSearchBar from './pages/home/HomeSearchBar';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationSP from "./assets/locales/sp/translation.json";
import RoutesPage from './routes';
import CacheBuster from 'react-cache-buster';
// import Loading from './loading';
import "react-step-progress/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import { SpinnerDotted } from 'spinners-react';
import Scrolltotop from './routes/scrolltotop';
import { Modal, Button } from 'react-bootstrap';
import LocationSearch from './components/locationSearch/locationSearch';
import ReactGA from 'react-ga';
import RouteChangeTracker from './googleAnalytics/Tracker';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'underscore';


var pjson = require('../package.json');

const TRACKING_ID = "G-JHE54XL5JL"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function usePageViews() {
  let location = useLocation();
  useEffect(()=>{
    if(!window.GA_INITIALIZED){
      ReactGA.initialize(TRACKING_ID);
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
  }, [location]);
}

function App() {

  usePageViews();
 
  const isProduction = process.env.REACT_APP_SERVER !== 'localhost';
  const fallbackLng = ["en"];
  const availableLanguages = ["en", "sp"];

  const resources = {
    en: {
      translation: translationEN
    },
    sp: {
      translation: translationSP
    }
  };

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng,

      detection: {
        checkWhitelist: true
      },

      debug: false,

      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });
  const {locationDetails }  = useSelector((state) => state.getlocation);
  // const state  = useSelector((state) => state);

  // console.log(state.payment.order,"overalll state")

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if(locationDetails==""){
      setLoading(true)
    } else {
      setLoading(false)
    }

  }, [])

  const closeLocation = ()=>{
    setLoading(false)
  }

 

  return (

    <CacheBuster
      currentVersion={pjson.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
      <Scrolltotop />
      {/* <div className="App">
        {
          loading ?
          <div style={{
            width: "10%",
            margin: "18% auto",
          }}>
                <SpinnerDotted
                    loading={loading} 
                    size="100%"
                    thickness={180}
                    speed={180}
                    color ="#ECB390"
                    secondaryColor="rgb(255,255,255)"
           />

            </div>
            :
            <div> 
              <RoutesPage />
            <ToastContainer autoClose={4000} hideProgressBar={true} />
            </div>
        }


        
      </div> */}

{/* it will wait for the user to enter the location detail, then user can see the home page. */}

      {/* <div className="App">
      {console.log(" loading ==> ",loading)}

        {
          loading ?
            <LocationSearch info={loading} closeSearch={closeLocation}  />
            :
            <div>
              <RoutesPage />
              <ToastContainer autoClose={4000} hideProgressBar={true} />
            </div>
        } */}

{/* it will not wait for the location to be enter, so user can see the home page. */}

<div className="App">
        {
            <div>
              {/* <LocationSearch info={loading} closeSearch={closeLocation}  /> */}
              <RoutesPage />
              <ToastContainer autoClose={4000} hideProgressBar={true} />
            </div>
        }




      </div>
    </CacheBuster>
  );
}

export default App;
