import React, { useState, useEffect } from 'react';
import { getAllCouponsGrouping,getAllCouponsGrouping_business, adminGetAllUsers, addBusinessCouponCodeUsers, deleteBusinessCouponCodeUsers, deleteAllBussinessCouponCodeAction, updateAdminGetOneUser_business,updateBusinessCouponCodeUsers, } from "../../../redux/dashboard/actions";
import { useDispatch } from "react-redux";
import { Button, Dropdown, Modal, Row, Col, Table, Form } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { useFormik, FormikProvider} from 'formik';
import ReferralAddEdit from "./ReferralAddEditSalon"
import UsedCouponList_Business from "../UsedCoupons/usedCoupons_business"
import { MultiSelect } from "react-multi-select-component";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function GroupingUsers_salon() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [addUsers, setAddUsers] = useState(false);
    const [usersUpdate, setUsersUpdate ] = useState(false)
    const [options, setOptions] = useState()
    const [activeTab , setActiveTab] = useState("Business Coupons")
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedIds, setSelectedIds] = useState({});
    const [couponId, setCouponId] = useState('')
    const [updateId, setUpdateId] = useState('');
    const [firstSelection, setFirstSelection] = useState(false)
    const [addedUsers, setAddedUsers] = useState();
    const [error, setError] = useState(false)


    const [viewOpen, setViewOpen] = useState(false);
    const [DeleteConform, setDeleteConform] = useState(false);

    const [selectallCheck, setSelectallCheck] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);


    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });

    const [emails, setEmails] = useState("");
    const [getUpdatedEmails, setUpdatedEmails] = useState("")
    const [emailError, setEmailError] = useState("")
    const [emailNotAvailable, setEmailNotAvailable] = useState("")
    const [emailErrorStatus, setEmailErrorStatus] = useState(false)
    const [usersList , setUsersList] = useState([])
    const [availableEmails, setAvailableEmails] = useState([])



    // console.log(" state --> ", state)
    useEffect(() => {
        const data = {
            page: state.activePage,
            search: "",
        };
        dispatch(getAllCouponsGrouping_business(data))
            .then((res) => {
                console.log(res,'res')
                const { total_count, couponMain, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })
    }, []);
    useEffect(() => {
        const data = {
            search: ''
        }
        dispatch(adminGetAllUsers(data))
            .then((res) => {
                console.log(res)

                setOptions(res.data.userMain)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }, [])

    const getUserRefresh = () =>{
        const data = {
            search: ''
        }
        dispatch(adminGetAllUsers(data))
            .then((res) => {
                console.log(res)

                setOptions(res.data.userMain)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }


    const getRefresh = () => {
        const data = {
            page: state.activePage,
            search: "",
        };
        dispatch(getAllCouponsGrouping_business(data))
            .then((res) => {
                const { total_count, couponMain, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })

    }
    const overrideStrings = {
        "allItemsAreSelected": "All the users are selected",
        "clearSearch": "Clear Search",
        "clearSelected": "Clear Selected",
        "noOptions": "No options",
        "search": "Search for users",
        "selectAll": "Select All",
        "selectAllFiltered": "Select All (Filtered)",
        "selectSomeItems": "Choose users for this coupon",
        "create": "Create",
    }
    const addGroupUsers = (id) => {
        setCouponId(id)
        setAddUsers(true)
    }
    const addingServiceProviders = (e) => {
        setSelectedIds(e.value)
        setSelectedProviders(e)
        formik.setFieldValue("users", e)
        setSelectedIds(e.map((item)=> item.value))
        firstSelection(true)
    }
    const updatingServiceProviders = (e) => {
        setSelectedIds(e.value)
        setSelectedProviders(e)
        formikUpdate.setFieldValue("users", e)
        setSelectedIds(e.map((item)=> item.value))
        firstSelection(true)
    }

    const emailSubmit = useFormik({
        enableReinitialize: true,

        initialValues: {
            users: '',
        },

        onSubmit:(values) => {

             const data = {
                    coupon_id: couponId,
                    users: usersList,        
                };
    
                if(!usersList.length < 1){
                    setError(false)
                    dispatch(addBusinessCouponCodeUsers(data))
                    .then(()=>{
                        getRefresh();
                        getUserRefresh();
                        formik.resetForm();
                        setEmails("")
                        setEmailErrorStatus(false)
                        setEmailNotAvailable("")
                        setAddUsers(false);
                    })
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false)
                    }, 1000);
                }
        }
    })

    const emailUpdateSubmit = useFormik({
        enableReinitialize: true,

        initialValues: {
            users: '',
        },

        onSubmit:(values) => {

            console.log("email update submit called .....", couponId)
             const data = {
                    coupon_id: updateId,
                    users: usersList,        
                };
    
                if(!usersList.length < 1){
                    setError(false)
                    dispatch(updateBusinessCouponCodeUsers(data))
                    .then(()=>{
                        getRefresh();
                        getUserRefresh();
                        formik.resetForm();
                        setEmails("")
                        setEmailErrorStatus(false)
                        setEmailNotAvailable("")
                        setAddUsers(false);
                        setUsersUpdate(false);

                    })
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false)
                    }, 1000);
                }
        }
    })


    const formik = useFormik({
        enableReinitialize: true,
        
        initialValues: {
            users: '',
        },
        onSubmit: (values) => {

            const usersList = emails.split(",").map(email => email.trim())
            let available_users = []
            let notavailable = []
            let userIds = usersList.map(email => {
                const user = options.find(option => option.email_id === email);
                return user ? available_users.push(user._id) : notavailable.push(email);
              });

            setUsersList(available_users)

            if (notavailable.length > 0){
                setEmailErrorStatus(true)
                setEmailNotAvailable(`${notavailable}`);

            }else{

                const data = {
                    coupon_id: couponId,
                    users: available_users,        
                };
    
                if(!available_users.length < 1){
                    setError(false)
                    dispatch(addBusinessCouponCodeUsers(data))
                    .then(()=>{
                        getRefresh();
                        getUserRefresh();
                        formik.resetForm();
                        setEmails("")
                        setEmailErrorStatus(false)
                        setEmailNotAvailable("")
                        setAddUsers(false);
                        

                    })
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false)
                    }, 1000);
                }

            }
                
            }
            
        
                
          
    });
  
    const updateUsers = (id) =>{

        setEmailNotAvailable("");
        setEmailErrorStatus(false)
        setEmailError("")
        setUpdateId(id)
        setUsersUpdate(true)
        const data = {
            coupon_id: id,
            page: state?.activePage,
        }
        dispatch(updateAdminGetOneUser_business(data))
        .then((res)=>{
            //   let alreadyAddedUsers = res?.data?.results?.users.map((item)=>{
            //     return ({"label": item?.email_id, "value": item?._id})
            //   })
            let available_emails = []
            let available_users = []

            let alreadyAddedUsers = res?.data?.results?.users.map((item)=>{
                // console.log(" email id : ", item?.email_id, "Value : ", item?._id)
                available_emails.push(item?.email_id)
                available_users.push(item?._id)

            })
                // setSelectedProviders(alreadyAddedUsers)
            setUpdatedEmails(available_emails)
            formikUpdate.setFieldValue("users", selectedProviders)
        })
      
    }
    const formikUpdate = useFormik({
        enableReinitialize: true,
        initialValues: {
            users: '',
        },
        onSubmit: (values) => {

            const usersList = emails.split(",").map(email => email.trim())
            let available_users = []
            let notavailable = []
            let userIds = usersList.map(email => {
                const user = options.find(option => option.email_id === email);
                return user ? available_users.push(user._id) : notavailable.push(email);
              });

            setUsersList(available_users)

            if (notavailable.length > 0){
                setEmailErrorStatus(true)
                setEmailNotAvailable(`${notavailable}`);

            }else{

                const data = {
                coupon_id: updateId,
                users: available_users,        
            };
            
            dispatch(updateBusinessCouponCodeUsers(data))
                .then(()=>{
                    formikUpdate.resetForm();
                    getRefresh();
                    getUserRefresh();
                    setEmailErrorStatus(false)
                    setEmailNotAvailable("");
                    setEmails("")
                    setUsersUpdate(false);
                }) 

                
                
            }

            
            
        }

          
    });

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setSelectallCheck(false);
        setSelectedItems([]);

        const data = {
            page: pageNumber?.selected + 1
        };
        dispatch(getAllCouponsGrouping_business(data))
            .then((res) => {
                const { total_count, couponMain, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))

            })

    };

    const deleteUsers = (id) =>{
        const data = {
            coupon_id: id
        }
        dispatch(deleteBusinessCouponCodeUsers(data))
        .then((res)=>{
            getRefresh();
        })
    }
    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        setPage(0);
        const data = {
          search: e.target.value,
          page: 1
        };
    
        dispatch(getAllCouponsGrouping_business(data))
          .then((res) => {
            const { couponMain, total_count, resultsPerPage } = res
            setState((prev) => ({
              ...prev,
              data: couponMain,
              total: total_count / resultsPerPage
            }))
          })
      };

    
  const handleChange = (event) => {
    const { value } = event.target;
    setEmailErrorStatus(false)


    // check if emails are comma-separated and valid
    const emailsArray = value.split(",");
    const invalidEmails = emailsArray.filter(
      (email) =>
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
    );

    if (invalidEmails.length > 0) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    setEmails(value.toLowerCase());

  };

  const handleUpdateChange = (event) => {
    const { value } = event.target;
    setEmailErrorStatus(false)
    setUpdatedEmails(value)



    // check if emails are comma-separated and valid
    const emailsArray = value.split(",");
    const invalidEmails = emailsArray.filter(
      (email) =>
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
    );

    if (invalidEmails.length > 0) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    setEmails(value.toLowerCase());
  }


  const onCheckboxBtnClick = (item, e) => {
    item.isChecked = e.target.checked;
  
    if (item.isChecked) {
      selectedItems.push(item);
    } else {
      selectedItems.splice(item, 1);
    }
    let isAllSelect = state.data.every((item)=>{
      return item.isChecked == true;
    })
    setSelectedItems([...selectedItems]);
    if (isAllSelect){
      setSelectallCheck(true);
    } else{
      setSelectallCheck(false);
    }
  };
  
  const selectAllCheckBox =(event)=>{
    if (event.target.checked) {
      state.data.map((item)=>{
        selectedItems.push(item);
        return item.isChecked = true;
      });
  
      setSelectedItems([...selectedItems]);
      setSelectallCheck(true);
    }
     else {
      state.data.map((item)=>{
        selectedItems.splice(item, 1);
        return item.isChecked = false;
      });
      setSelectedItems([]);
      setSelectallCheck(false);
  
    }
    
  }


  const generateCode = () => {
    setViewOpen(true);

    }

    const multiDelete =()=>{
    let selectedItemId = selectedItems.map((service) => {
        return service._id;
      })
      const data = {
                id: selectedItemId,
             }

            // console.log(" the selected id datas  -----> ", data )

            dispatch(deleteAllBussinessCouponCodeAction(data))
                getRefresh()
                setDeleteConform(false);
                setSelectedItems([]);
                setSelectallCheck(false);
                
        }

    const handleActiveTab = (event) => {
            setActiveTab(event)
          }

  


    return (


        <div className='container-xxl'>

        <div className="mt-3">
            <ReferralAddEdit
                show={viewOpen}
                onHide={() => setViewOpen(false)}

            />

            <div class="row mb-2">
                <div class="col-md-6">
                    <div className="p-2">
                        <h3 className='marketing-header'>
                        Marketing <span className="marketing-sub-header">/ Salon </span><span className="marketing-sub-header">/ {activeTab}</span>
                        </h3>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex heading-btn-coupon">
                        <div className="mt-1 mx-2">
                            <Button onClick={generateCode} variant="" className="generate-btn-admin"> Generate <span class="ionic-icon-btn"><ion-icon name="duplicate-outline"></ion-icon></span></Button>{' '}
                        </div>
                        <div className="mt-1 mx-2">
                            <Button className="delete-btn-admin" onClick={() => setDeleteConform(true)}> Delete <span class="ionic-icon-btn"><ion-icon name="trash-outline"></ion-icon></span></Button>{' '}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <hr /> */}
        <Modal  centered size="md" show={DeleteConform} onHide={()=>setDeleteConform(false)} className='text-center'>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    Delete Coupons?
                                  </Modal.Title>
                                    <Modal.Body className='mb-5 px-2'>
                                    <p className='mt-2'>Are you sure you want to Delete?</p>

                                          <Row className='mt-5'>
                                            <Col>
                                             <Button className='image-delete-cancel' onClick={()=>{setDeleteConform(false)}}>Cancel</Button>
                                            </Col>
                                            <Col>
                                            <Button className='image-delete-delete' onClick={multiDelete}>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

             </Modal>
        <Tabs 
        defaultActiveKey="Business Coupons"
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={handleActiveTab}
        >
        
        <Tab eventKey="Business Coupons" title="Business Coupons">
        <div className="order-list mt-3" >
                  {/* <Row>
                    <input type="text" class="form-control searchbar-copon" id="" placeholder="Search Coupon Name " name="search" value={search} onInput={onChangeSearch}  />
                    </Row> */}
                    <div className="service-list-header-option mt-4">
                    <div className="business-search w-50">
                        <input type="text" class="form-control" id="" placeholder="Search Coupon Name" name="search" onInput={onChangeSearch} />
                    </div>
                </div>
                {/* <hr className='p-0 mb-0' /> */}
                <section class="pt-0">
                    <div class=" mt-4">
                   
                        <div class="table-responsive slaylewks-table">
                            <Table responsive>
                                <thead className='admin-coupon-list-head'>
                                    <tr className='tabel-data-content'>
                                        <th className='admin-tabel-heading'>
                                            <input class="form-check-input" type="checkbox" checked={selectallCheck} onClick={(e) => selectAllCheckBox(e)} />
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Coupon Code
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Coupon Name
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Service Providers
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Generated by
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Status
                                        </th>
                                        <th className='admin-tabel-heading'>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        state?.data?.map((item, index) => {
                                            {console.log(item,'it1')}
                                            return (
                                                <tr className='tabel-data-content' key={index}>
                                                    <td scope="row"><input class="form-check-input" type="checkbox" value={item?._id} checked={item.isChecked} onChange={(e)=>onCheckboxBtnClick(item, e)}/></td>
                                                    <td class="sss">{item?.coupon_code_business}</td>
                                                    <td>{item?.coupon_name}</td>
                                                    <td>{item?.service_provider?.business_name}</td>
                                                    <td>{item?.generated_by}</td>

                                                    {
                                                        item?.is_active ? (
                                                            <td className='active-coupons'>Active</td>

                                                        ) : (
                                                            <td className='expired-coupons'>Expired</td>

                                                        )
                                                    }
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                <BsThreeDotsVertical />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="text-center">
                                                                {
                                                                    !item?.grouped_coupon_status ? (
                                                                        <Dropdown.Item onClick={() => addGroupUsers(item?._id)}>Add</Dropdown.Item>

                                                                    ) : (
                                                                        <>
                                                                         <Dropdown.Item onClick={() => updateUsers(item?._id)}>Update</Dropdown.Item>
                                                                         <Dropdown.Item onClick={()=> deleteUsers(item?._id)}>Delete</Dropdown.Item>
                                                                        </>
                                                                    )
                                                                }
                                                               
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }

                                    {
                                        state?.total < 0 && (
                                            <p className='mt-5 mx-2'>No results found</p>
                                        )
                                    }



                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/* <Modal
                        show={addUsers}
                        onHide={() => setAddUsers(false)}
                        keyboard={false}
                        className="text-center"
                        centered
                    >
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body className='mb-5 py-0'>
                            <h2>Grouping Users</h2>
                            <h6>Please Select your users below</h6>
                            <Row>
                                <Form noValidate className="marketing-register-form text-start" onSubmit={formik.handleSubmit}>
                                    <FormikProvider value={formik}>

                                        <Form.Group as={Row} className="px-5 mt-3" controlId="coupon_name">
                                            {/* <Form.Label column sm="12" className="form-label-coupon-code">Service Provider</Form.Label> */}
                                            {/* <Col sm="12">
                                                <MultiSelect
                                                    options={options?.map((item) => {
                                                        return (
                                                            { "label": item?.email_id, "value": item?._id }
                                                        )
                                                    })}
                                                    hasSelectAll={("hasSelectAll", true)}
                                                    isLoading={("isLoading", false)}
                                                    value={selectedProviders}
                                                    disabled={("disabled", false)}
                                                    onChange={(e) => addingServiceProviders(e)}
                                                    name="users"
                                                    className={("className", "multi-select")}
                                                    overrideStrings={overrideStrings}
                                                    />
     
                                                {
                                                    error && (
                                                        <p className='text-danger'>Please add users to save</p>
                                                    )
                                                }

                                            </Col> */}
                                            {/* <Row className='mt-5'>
                                <Col md={6}>
                                    <Button className="image-delete-cancel" variant="" onClick={() => setAddUsers(false)}>Cancel</Button>
                                </Col>
                                <Col md={6}>
                                    <Button type='submit' className="image-delete-delete" variant="">
                                        save
                                    </Button>
                                </Col>
                            </Row>
                                        </Form.Group>
                                    </FormikProvider>
                                </Form>

                            </Row>
                        
                        </Modal.Body>



                    </Modal> */} 


                <Modal
                    show={addUsers}
                    onHide={() => setAddUsers(false)}
                    keyboard={false}
                    className="text-center custom-modal"
                    centered
                >
                    <Modal.Header closeButton onClick={() => {
                        setEmails("")
                        setEmailErrorStatus(false)
                        setEmailNotAvailable("")
                    }}>

                    </Modal.Header>
                    <Modal.Body >
                        <h2>Grouping Users</h2>
                        <h6>Please give users email id's with , seperated below</h6>
                        <Row>
                            <Form noValidate className="marketing-register-form text-start" onSubmit={emailErrorStatus ? emailSubmit.handleSubmit :formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                        <Form.Group className="mb-3 p-2" >
                                                <Form.Label className="review-headers">Add Users</Form.Label>

                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder="Eg: abc@gmail.com, xyz@gmail.com"
                                                    name="addusers"
                                                    value={emails}
                                                    onChange={handleChange}
                                                    isInvalid={!!emailError}
                                                    required
                                                    style= {{
                                                        height: '214px'
                                                    }}
                                                />

                                                <Form.Control.Feedback className="text" type="invalid">
                                                    {emailError}
                                                </Form.Control.Feedback>

                                               {emailNotAvailable && 
                                               <div>
                                               <p className="email-error"> Not available Users</p>

                                             <Form.Control
                                             className="email-errorArea"
                                             as="textarea"
                                             disabled={true}
                                             rows={5}
                                             value={emailNotAvailable}
                                             name="errorEmail"
                                            //  onChange={formik.handleSubmit}
                                             
                                         />
                                               </div>
                                         
                                            }

                                        </Form.Group>       
                                    <Row className='mt-5 mb-3 justify-content-between'>
                                      
                                        <Col md={6} lg={6}>
                                            <Button className="image-delete-cancel" variant="" onClick={() => {
                                                setEmails("")
                                                setEmailErrorStatus(false)
                                                setEmailNotAvailable("")
                                                setAddUsers(false)
                                            }}>Cancel</Button>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Button type='submit' className="image-delete-delete" id="add-group" variant="" disabled={emailError || !emails.trim()} >
                                                Save
                                            </Button>
                                        </Col>
                                        
                                    </Row>

                                </FormikProvider>
                            </Form>

                        </Row>

                    </Modal.Body>



                </Modal>





                    {/* <Modal
                        show={usersUpdate}
                        onHide={() => setUsersUpdate(false)}
                        keyboard={false}
                        className="text-center"
                        centered
                    > */}
                    {/* <Modal.Header closeButton>

                    </Modal.Header> */}
                        {/* <Modal.Body className='mb-5 py-0'>
                            <h2>Grouping Users</h2>
                            <h6>Update users</h6> */}
                            {/* <Row> */}
                                {/* <Form noValidate className="marketing-register-form text-start" onSubmit={formikUpdate.handleSubmit}> */}
                                    {/* <FormikProvider value={formikUpdate}> */}

                                        {/* <Form.Group as={Row} className="px-5 mt-3" controlId="coupon_name"> */}
                                            {/* <Form.Label column sm="12" className="form-label-coupon-code">Service Provider</Form.Label> */}
                                            {/* <Col sm="12">
                                                <MultiSelect
                                                    options={options?.map((item) => {
                                                        return (
                                                            { "label": item?.email_id, "value": item?._id }
                                                        )
                                                    })}
                                                    hasSelectAll={("hasSelectAll", true)}
                                                    isLoading={("isLoading", false)}
                                                    value={selectedProviders}
                                                    disabled={("disabled", false)}
                                                    onChange={(e) => updatingServiceProviders(e)}
                                                    name="users"
                                                    className={("className", "multi-select")}
                                                    overrideStrings={overrideStrings}
                                                    isInvalid={!!formik.touched.users && !!formik.errors.users}
                                                />
 
                                                                 <Form.Control.Feedback type="invalid">
                                                                     {formik.errors.users}
                                                                  </Form.Control.Feedback>
                                            </Col> */}
                                            {/* <Row className='mt-5'>
                                                
                                <Col md={6}>
                                    <Button className="image-delete-cancel" variant="" onClick={() => setUsersUpdate(false)}>Cancel</Button>
                                </Col>
                                <Col md={6}>
                                    <Button type='submit' className="image-delete-delete" variant="">
                                        save
                                    </Button>
                                </Col>
                            </Row> */}
                                        {/* </Form.Group> */}
                                    {/* </FormikProvider> */}
                                {/* </Form> */}

                            {/* </Row> */}
                        
                        {/* </Modal.Body> */}


                    {/* </Modal> */}


                    <Modal
                        show={usersUpdate}
                        onHide={() => setUsersUpdate(false)}
                        keyboard={false}
                        className="text-center custom-modal"
                        centered
                    >
                    <Modal.Header closeButton >

                    </Modal.Header>
                        <Modal.Body>
                            <h2>Grouping Users</h2>
                            <h6>Update users</h6>
                            <Row>
                                <Form noValidate className="marketing-register-form text-start" onSubmit={emailErrorStatus ? emailUpdateSubmit.handleSubmit :formikUpdate.handleSubmit}>
                                    <FormikProvider value={formikUpdate}>

                                        {/* <Form.Group as={Row} className="px-5 mt-3" controlId="coupon_name"> */}
                                        <Form.Group className="mb-3 p-2" >

                                        <Form.Label className="review-headers">Update Users</Form.Label>
                                            
                                                       <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder="Eg: abc@gmail.com, xyz@gmail.com"
                                                    name="updateusers"
                                                    value={getUpdatedEmails}
                                                    // onChange={handleChange}
                                                    onChange = {handleUpdateChange}
                                                    isInvalid={!!emailError}
                                                    style= {{
                                                        height: '214px'
                                                    }}
                                                />

 
                                                <Form.Control.Feedback className="text" type="invalid">
                                                    {emailError}
                                                </Form.Control.Feedback>

                                                {emailNotAvailable && 
                                                <div>
                                                <p className="email-error"> Not available Users</p>

                                                <Form.Control
                                                className="email-errorArea"
                                                as="textarea"
                                                disabled={true}
                                                rows={5}
                                                value={emailNotAvailable}
                                                name="errorEmail"
                                                //  onChange={formik.handleSubmit}
                                                
                                                />
                                                </div>
                                                }

                                            </Form.Group>

                                            <Row className='mt-5 mb-3 justify-content-between'>
                                                                
                                                <Col md={6} lg={6}>
                                                    <Button className="image-delete-cancel" variant="" onClick={() => setUsersUpdate(false)}>Cancel</Button>
                                                </Col>
                                                <Col md={6} lg={6}>
                                                    <Button type='submit' className="image-delete-delete" variant="" disabled={emailError || !emails.trim()}>
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                    </FormikProvider>
                                </Form>

                            </Row>
                        
                        </Modal.Body>


                    </Modal>
                   
                    
                </section>
                <div>

                    {
                       state?.total > 1 &&  (

                            <ReactPaginate

                                previousLabel="<<"
                                nextLabel=">>"
                                breakLabel="..."
                                pageCount={state.total}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination justify-content-center pagination-box'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                activeClassName={'active'}
                            />
                        )
                    }
                </div>
            </div>
        </Tab>
        <Tab eventKey="Coupon Used Count" title="Coupon Used Count">
            <UsedCouponList_Business />
        </Tab>
        </Tabs>
        
        </div>

    )
}

export default GroupingUsers_salon;
