import React from 'react';
import { Accordion } from "react-bootstrap"

function PaymentCancelled() {
    return (
        <div className="payment-cancelled">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Easy Cancellation
                        </button>
                    </h2>
                    <button class="change-btn">Change</button>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p class="reason-cancel">Reason for cancellation: Select reason for cancellation</p>
                            <div class="mb-3">
                                <div class="label-text">
                                    <label for="exampleFormControlInput1" class="form-label">Reason for cancellation *</label>
                                </div>
                                <div class="select-content">
                                    <select class="form-select common-text" aria-label="Default select example">
                                        <option selected>Select Reason for cancellation</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="label-text">
                                    <label for="exampleFormControlTextarea1" class="form-label">Comment</label>
                                </div>
                                <div class="select-content">
                                    <textarea class="form-control common-text" id="exampleFormControlTextarea1" rows="3" placeholder="Autosize height based on content lines"></textarea>
                                </div>
                                <button class="continue-btn">Continue</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Refund Modes
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p class="select-mode">Select a Mode of Refund </p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio"  />
                                <label class="form-check-label">
                                    Orginal Payment Mode
                                </label>
                            </div>
                            <p class="refund-process">Refund will be processed with in 8 days</p>
                            <p class="terms-condtion">By  clicking on “Request Cancellation”, I agree to <span>Tearms and Conditions</span> of Regund.</p>
                            <button class="continue-btn mt-0">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentCancelled