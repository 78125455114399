import React from 'react'
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer'
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { useFormik, FormikProvider} from "formik";
import { HiOutlineMail } from "react-icons/hi";
import Contactimg from "../../assets/images/home/Contact-us.webp"
import { useDispatch, useSelector  } from 'react-redux';
import { supportValidation } from '../../validations/yub_schemas/supportValidation';
import {BusinessSupportMessage } from '../../redux/auth/actions';
import { useNavigate } from 'react-router-dom';

function BusinessSupport() {
  
  const { user } = useSelector((state) => state.auth)
  const { vendor } = useSelector((state) => state.business);
 
  const Navigate = useNavigate();
  const dispatch = useDispatch();
   
    const formik = useFormik({
      enableReinitialize: true,
        initialValues:{
            username: vendor?.business_name,
            email: vendor?.business_email,
            message: '',
                
        },
        validationSchema: supportValidation,
        onSubmit: (values) => {
          const data = {
            _id: vendor?._id,
            username: values?.username,
            email_id: values?.email,
            message: values.message
          };
          console.log(data);
          formik.resetForm();
          dispatch(BusinessSupportMessage(data)).
          then((res) => {
            
           Navigate("/dashboard")
            
          })
          .catch((error) => {
            console.log(error);
            
        });
      },
  });
   

  return (
    <div>
      {/* <Header /> */}
      <Container className='mt-4'>
        <Row>
            <Col lg={6} md={6} sm={12}>
            <h2>Get in touch</h2>
            <p className='drop-message'>Drop a message</p>

            <div className='support-form'>
                  <Form onSubmit={formik.handleSubmit}>
                  <FormikProvider value={formik}>

                    <Form.Group className="mb-3 p-2" controlId="formBasicyourName">
                        
                        <Form.Control
                            disabled
                            type="text"
                            placeholder="Name"
                            name="username"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            isInvalid={!!formik.touched.username && !!formik.errors.username}
                            
                        />  
                        <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                        {/* {formik.errors.yourName ? <div className="m-2 text-danger">{formik.errors.yourName}</div> : null} */}
                        
                    </Form.Group> 
                    <Form.Group className="mb-3 p-2" controlId="formBasicEmail">
                        
                        <Form.Control
                            disabled
                            type="email"
                            placeholder="Phone / Email id"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            isInvalid={!!formik.touched.email && !!formik.errors.email}
                        />
                      {/* {formik.errors.email ? <div className="m-2 text-danger">{formik.errors.email}</div> : null} */}
                      <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                        
                    </Form.Group>
                    <Form.Group className="mb-3 p-2" controlId="formBasicReference">
                       
                        <Form.Control
                        
                            as="textarea"
                            placeholder="Message"
                            name="message"
                            style={{ height: '150px' }}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            isInvalid={!!formik.touched.message && !!formik.errors.message}
                        />

                       
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.message}
                                </Form.Control.Feedback> {/* {formik.errors.message ? <div className="m-2 text-danger">{formik.errors.message}</div> : null}    */}
                    </Form.Group>
                    <Button className="support-button" type='submit'> Submit</Button>
                  </FormikProvider>
                 </Form>
            </div>

            </Col>
            <Col lg={6} md={6} sm={12} className="mt-5">
                <img className="img-fluid support-img-align" src= {Contactimg} alt="support-Img" />
              <p className='support-mail-align'>
              <HiOutlineMail  className="support-email-icon" /><span className="email-weight">info@slaylewks.com</span>
              </p>
            </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </div>
  )
}

export default BusinessSupport
