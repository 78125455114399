import React from 'react';
import Header from '../../components/elements/header/Header';
import "../../assets/css/cookie.css"
import Footer from '../../components/elements/footer/Footer';

function Cookie() {
    return (
        <div>
            <Header />
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 className="policy-header">Cookie Policy</h1>
                            <h2 class="terms-paragraph">What are cookies?</h2>
                            <p class="terms-paragraph">Cookies are text files containing small amounts of information which are downloaded to your computer or mobile device when you visit a site and allow a site to recognize your device. Cookies managed by Slaylewks only are called “first party cookies” whereas cookies from third parties are called “third party cookies” as explained below. </p>
                            <h2 class="terms-paragraph">What Are Cookies Used For?</h2>
                            <p class="terms-paragraph">We use cookies to collect and store information when you visit our website and use Slaylewks services. </p>
                            <p class="terms-paragraph">We use cookies for various purposes such as: </p>

                            <ul>
                                <li>Identifying your location</li>
                                <li>Identifying your browser and device</li>
                                <li>The amount of time you spend on a website</li>
                                <li>The links you click while using the website</li>
                                <li>The options, preferences, or settings you choose</li>
                                <li>Recording which pages you've visited in the past.</li>
                            </ul>
                            <h2 class="terms-paragraph mt-4">What type of Cookies do we use?</h2>
                            <div class="cookies-use">
                                <ul>
                                    <li>
                                        <span>Strictly Necessary Cookies</span>
                                        <p>These cookies are essential for the operation of the website and cannot be disabled without interrupting the operation of the website. Cookies are configured to respond to what you do, including browsing the web and using basic functions. These cookies do not store any personal information</p>
                                    </li>
                                    <li>
                                        <span>Analytical/Performance Cookies</span>
                                        <p>These cookies are essential for the operation of the website and cannot be disabled without interrupting the operation of the website. Cookies are configured to respond to what you do, including browsing the web and using basic functions. These cookies do not store any personal information</p>
                                    </li>
                                    <li>
                                        <span>Functionality Cookies</span>
                                        <p>These cookies provide advanced features and customizations to our site, such as remembering your preferences. These are set by Slaylewks or by third party providers who has added services to our pages. If you do not accept these cookies, some or all these services may not function properly. These cookies do not track your activity on other websites or store personal data.</p>
                                    </li>
                                    <li>
                                        <span>Targeting/Advertising Cookies</span>
                                        <p>This cookie creates a user profile or serves advertisements on social networks. Social sharing tools used on our website include Facebook, LinkedIn, Twitter, and YouTube. These platforms allow you to share content with your friends and networks. Instead of identifying themselves or storing personal data, they can uniquely identify internet browsers and devices. If you do not allow these cookies, you will not be able to use or view these sharing tools on our site.</p>
                                    </li>
                                </ul>
                            </div>

                            <h2 class="terms-paragraph">What if I don’t want cookies or similar tracking technologies?</h2>
                            <p class="terms-paragraph">You can change your cookie settings through our <b>cookie consent manager.</b> To eliminate existing treats from your gadget, you can do this using your browser options. If you want to block cookies in future being put on your device, you can user our <b>cookie consent manager.</b>
                                If it's not too much trouble, remember that deleting and blocking cookies might affect your user experience</p>
                            <p class="terms-paragraph">If you have any questions now or during your visit, please submit your request through email via <a href="JavaScript:void(0);">info@slaylewks.com</a></p>
                        </div>
                    </div>
                </div>
                
            </section>
            <Footer />
        </div>
    )
}

export default Cookie