import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from "./DashboardHeader";
import VerificationCard from "./verification";
import DashboardHome from "./Home";
import { Routes, Route, Link } from "react-router-dom";
import { getDashboardStatus, adminDashboardStatus } from "../../redux/dashboard/actions";

import AdminHome from "./Home/AdminHome";
import BusinessSidebar from "../../components/elements/siderbar";
import AdminSidebarPage from "../../components/elements/siderbar/AdminSidebarPage";
import { Col, Row } from "react-bootstrap";
import moment from "moment";


const Dashboard = () => {

    const [businessDashboard, setBusinessDashboard] = useState({});
    const [adminDashboard, setAdminDashboard] = useState({});
    const [orders, setOrders] = useState({});
    const [revenue, setRevenue] = useState({});
    const [adminApproved, setAdminApproved] = useState(false);
    const dispatch = useDispatch();

    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    useEffect(() => {

        if (role.role_name === "admin") {
            const data = {
                id: user._id,
                start : moment(new Date()).format("YYYY-MM-DD"),
                end : moment(new Date()).format("YYYY-MM-DD"),
            }
            dispatch(adminDashboardStatus(data))
                .then((res) => {
                    setAdminDashboard(res.data);
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            const data = {
                id: vendor._id,
                start : moment(new Date()).format("YYYY-MM-DD"),
                end : moment(new Date()).format("YYYY-MM-DD"),
            }
            dispatch(getDashboardStatus(data))
                .then((res) => {
                    const { orders, revenue_and_services, admin_approval, document_submission, payment_verification } = res;
                    if (admin_approval?.verified_status === "completed") {
                        setAdminApproved(true)
                    }
                    else {
                    }
                    const status = [];

                    // for (var i = 0; i < 3; i++) {
                    //     status.push({
                    //         name: "Document submission",
                    //         status: document_submission?.status === "completed" ? "completed" : "pending"
                    //     })
                    // }

                    setOrders(orders);
                    setRevenue(revenue_and_services);

                })
                .catch((err) => {
                })
        }
    }, [vendor, role, user]);

    useEffect(() => {
    }, [adminApproved])


    const dashboardFilter = (data) =>{
       
        if (role.role_name === "admin") {
            const adminDetails = {
                id: user._id,
                start: data?.start,
                 end: data?.end,
            }
            dispatch(adminDashboardStatus(adminDetails))
                .then((res) => {
                    setAdminDashboard(res.data);
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            const request = {
                id: vendor._id,
                start: data?.start,
               end: data?.end,
            }
            dispatch(getDashboardStatus(request))
                .then((res) => {
                   
                    const { orders, revenue_and_services, admin_approval, document_submission, payment_verification } = res;
                    if (admin_approval?.verified_status === "completed") {
                        setAdminApproved(true)
                    }
                    else {
                    }
                    const status = [];

                    // for (var i = 0; i < 3; i++) {
                    //     status.push({
                    //         name: "Document submission",
                    //         status: document_submission?.status === "completed" ? "completed" : "pending"
                    //     })
                    // }

                    setOrders(orders);
                    setRevenue(revenue_and_services);

                })
                .catch((err) => {
                    console.log("Err => ", err)
                })
        }
    }




    return (
        <>
            <div className="dashboard-page">
                <DashboardHeader />
                <div className="dashboard-body">
                    <div className="">
                    <Row>
                        <Col className="p-0" md={2} sm={12}>
                        <div className="sidebar-content">

{
    role.role_name === "admin" ?
        (
            <AdminSidebarPage />
        )
        :
        (
            <BusinessSidebar
                approved={adminApproved}
            />
        )
}

</div>
                        </Col>
                        <Col md={10} sm={12}>
                        <div className="main-content">

{
    role.role_name === "admin" ?
        (
            <AdminHome
                status={adminDashboard}
                dashboardFilter={dashboardFilter}
            />
        )
        :
        (
            <>
                {
                    !vendor?.business_verified ? (

                        <VerificationCard
                            regId={vendor?.reg_id || ""}
                        />
                    )
                        :
                        (
                            <DashboardHome
                                orders={orders}
                                revenue={revenue}
                                name={vendor?.business_name || ""}
                                dashboardFilter={dashboardFilter}
                            />
                        )
                }
            </>
        )
}
</div>
                        </Col>
                    </Row>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;