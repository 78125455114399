import React, { useState, useEffect } from 'react';
// import reviewImg from "../../../assets/images/review.png";
import AvatarImg from "../../../assets/images/avatar.webp";
import { useDispatch, useSelector } from "react-redux";
import { getAllBusinessReview, getAvgBusinessReview } from "../../../redux/business/actions";
import ReactStars from "react-rating-stars-component";
import { Image, Row, Col, ProgressBar } from "react-bootstrap";
import AddReviewModal from './AddReviewModal';
import { toast } from 'react-toastify';
import moment from 'moment';


function ReviewPage(props) {

    const [reviewDetails, setReviewDetails] = useState({})
    const [reviewCard, setReviewCard] = useState(true)
    const [filterBy, setFilterBy] = useState("");


    const dispatch = useDispatch();
    const [reviewModal, setReviewModal] = useState(false)
    const { user, role, isLoggedIn } = useSelector((state) => state.auth)

    const [totalReview, setReview] = useState(undefined)

    const { id } = props;

    const [reviews, setReviews] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });


    useEffect(() => {
        let data = {
            id: id,
            user_id: user?._id
        }
        dispatch(getAllBusinessReview(data))
            .then((res) => {
                const { results, total_count, resultsPerPage } = res
                setReviews((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
        setReviewCard(false)
        dispatch(getAvgBusinessReview(data))
            .then((res) => {
                setReviewDetails(res)

            })
            .catch((err) => {
                console.log("Err => ", err)
            })
        setInterval(() => {
            setReviewCard(true)
        }, 2000);

    }, [id])

    const getAllReviews = () => {
        setReviewCard(false)
        let data = {
            id: id,
            user_id: user?._id,
            review_category: "",
        }
        dispatch(getAllBusinessReview(data))
            .then((res) => {
                const { results, total_count, resultsPerPage } = res
                setReviews((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
        dispatch(getAvgBusinessReview(data))
            .then((res) => {
                setReviewDetails(res)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

        setInterval(() => {
            setReviewCard(true)
        }, 2000);
    }

    const onChangeFilter = async (e) => {
        await setFilterBy(e.target.value);
        const data = {
            id: id,
            user_id: user?._id,
            review_category: e.target.value,
        };
    
        dispatch(getAllBusinessReview(data))
            .then((res) => {
                const { results, total_count, resultsPerPage } = res
                setReviews((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
        dispatch(getAvgBusinessReview(data))
            .then((res) => {
                setReviewDetails(res)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

        setInterval(() => {
            setReviewCard(true)
        }, 2000);
        // onServiceDataChange();
      }


    useEffect(() => {
        setReview(reviewDetails?.average_ratings?.avg)
    }, [reviewDetails])


    function getstars(Rating) {
        return (
            <ReactStars
                value={Rating}
                count={5}
                size={30}
                edit={false}
                isHalf={false}
                activeColor="#FFC107"
            />
        )
    }


    const handleReviewModel = () => {
        let isOwner = props?.serviceprovider?.owner === user?._id ? true : false;
        if (isLoggedIn) {
            if (reviewDetails.user_rating.length > 0) {
                toast.warning("Already posted review");
                
                return
            }
            if (isOwner) {
                toast.warning("Owner can't add review")
            } else {
                setReviewModal(true)
            }
        } else {
            toast.warning("Please login to post review")
        }
        // if (isLoggedIn && isOwner) {
        //     toast.warning("Owner can't add review")
        // } else {
        //     setReviewModal(true)
        // }
    }


    return (
        <div className="business-review-page">
            <AddReviewModal
                show={reviewModal}
                onHide={() => setReviewModal(false)}
                getAllReviews={getAllReviews}
                user={user?._id}
                serviceprovider={id}
            />
            <div className="d-flex flew-row mb-3">
              
                {/* <div className="p-2">
                    <select class="form-select filter-sortby" aria-label="Default select example">

                        <option value="1">Shop</option>
                        <option value="2">Home Service</option>
                        <option value="3">Both</option>
                    </select>
                </div>
                <div className="p-2 ms-4">
                    <select class="form-select filter-sort" aria-label="Default select example">

                        <option value="1">Shop</option>
                        <option value="2">Home Service</option>
                        <option value="3">Both</option>
                    </select>
                </div> */}
            </div>
            <div class="row">
                <div class="col-md-7">
                <Row>
                    <Col>
                    <div className='float-start mt-3'>
                    {
                        reviewCard && (
                             <p class="common-text-review float-end mb-2">{reviews.total} users ratings</p>
                        )
                    }
                        </div>
                    
                    </Col>

                    <Col>
                    <div className="float-end">
                    <button type="button" class="btn add-review" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleReviewModel}>Add Review</button>
                </div>
                    </Col>
                    <Col>
                    <div className='float-start mt-1'>
                <select class="form-select filter-service"
                                    aria-label="Default select example"
                                    name="filterService"
                                    onChange={onChangeFilter}
                                    value={filterBy}
                                    >
                                    <option value="" selected>Filter</option>  
                                    <option value="Hair">Hair</option>
                                    <option value="Spa">Spa</option>
                                    <option value="Nails">Nails</option>
                                    <option value="Makeup">Makeup</option>
                                    <option value="Mehendi">Mehendi</option>
                                    <option value="Skin">Skin</option>
              </select>
                </div>
                    </Col>
                </Row>
                    {
                        reviewCard && (
                            <>
                                
                                <div class="customer-review-list mt-5">
                                    <ul>
                                        {
                                            reviews.data && reviews.data.length > 0 ? (
                                                reviews.data.map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div class="review-img">
                                                                {/* <Tab.Pane eventKey="service"> */}
                                                                {/* <div class="tab-content" id="tabContent"> */}
                                                                {/* <img src={item?.user[0]?.profileURL || reviewImg} alt="review" class="img-fluid" /> AvatarImg */}
                                                                <Image
                                                                    src={item?.user[0]?.profileURL || AvatarImg}
                                                                    roundedCircle
                                                                    width="40px"
                                                                    height="40px"
                                                                />
                                                            </div>
                                                            <div class="review-list">
                                                                <p class="common-text">{item?.user[0]?.username}
                                                                    <span class="review-date">{moment(item?.created_at).format("YYYY-MM-DD")}</span>
                                                                </p>
                                                                <span style={{ float: "left", width: "100%" }}>
                                                                    <ReactStars
                                                                        count={5}
                                                                        value={item?.rating}
                                                                        // onChange={ratingChanged}
                                                                        edit={false}
                                                                        size={30}
                                                                        activeColor="#FFC107"
                                                                    />
                                                                </span>
                                                                <p class="common-text content-align">{item?.review}</p>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            ) : (
                                                <p>No reviews available</p>
                                            )


                                        }

                                    </ul>
                                    {/* <div class="load-more-outer text-center pb-5">
                            <button class="btn load-more">Load more</button>
                        </div> */}
                                </div>
                            </>
                        )
                    }
                </div>
                <div class="col-md-5">
                    {
                        reviewCard && (
                            <div class="card customer-rating-box">
                                <div class="card-body p-5">
                                    <h5 class="card-title">Customer reviews</h5>
                                    <p className='d-flex'>
                                        {
                                            getstars(totalReview)

                                        }
                                        <span class="customer-rating">{reviewDetails?.average_ratings?.avg} out of 5</span></p>
                                    <span class="common-text mb-3">{reviewDetails?.total_ratings?.reviewCount} users ratings</span>
                                    <div class="review-progressbar">
                                        <ul>
                                            <li>
                                                <span class="common-text">5 Star</span>                         
                                               <span className="rating-average-progress"> <ProgressBar now={reviewDetails?.five_star_count?.value_five} /></span>
                                            </li>
                                            <li>
                                                <span class="common-text">4 Star</span>
                                                        <span className="rating-average-progress"> <ProgressBar now={reviewDetails?.four_star_count?.value_four} /></span>
                                            </li>
                                            <li>
                                                <span class="common-text">3 Star</span>
                                                        <span className="rating-average-progress"> <ProgressBar now={reviewDetails?.three_star_count?.value_three} /></span>
                                            </li>
                                            <li>
                                                <span class="common-text">2 Star</span>
                                                        <span className="rating-average-progress"> <ProgressBar now={reviewDetails?.two_star_count?.value_two} /></span>
                                            </li>
                                            <li>
                                                <span class="common-text">1 Star</span>
                                                        <span className="rating-average-progress"> <ProgressBar now={reviewDetails?.one_star_count?.value_one} /></span>
                                            </li>
                                            
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ReviewPage