import React, { useEffect, useState, useContext } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container, Breadcrumb, Table } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import { MdOutlineNavigateNext } from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import PaymentFailed from './PaymentFailed';
import CancellationSucess from './CancellationSucess';

function CustomToggle({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a class="btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>
            <h5>{children}</h5>
        </a>
    );
}


function ContinueButton({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button class="continue-btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>Continue</button>
    );
}


function PaymentSuccess() {


    const [searchParams] = useSearchParams();

    const [orderDetails, setOrderDetails] = useState({})
    const [loading, setLoading] = useState(false)

    const type = searchParams.get("type");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const redirectPaymentDetails = (type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectReschedule = () => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    const navigateTerms = () => {
        navigate('/term-of-use')
    }



    const formik = useFormik({
        initialValues: {
            refundReason: 'One',
            refundMessage: '',
            payment_id: '',
            confirmation: 'false'
        },
    });

    useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)

                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);


    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }

    const raiseRefunds = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (formik.values.confirmation !== 'true') {
            toast.warning('Please select mode of refund.');
            setLoading(false);
            return
        }
        const obj = {
            refundReason: formik.values.refundReason,
            refundMessage: formik.values.refundMessage,
            payment_id: orderDetails.payment_id,
            order_id: orderDetails._id,
            appointment_id: orderDetails.appointment._id
        }
        console.log(obj);
        const result = await dispatch(raiseRefund(obj));
        setLoading(false)
        if (result.result) {
            toast.success('Cancellation successfully raised')
            navigate(`/business/payment/${id}?type=cancellation_raised`)
        } else {
            toast.error('Could not raise a cancellation request.')
            navigate(`/business/payment/cancel/failed/${id}`)
        }
    }



    const redirectToRating = (id) => {
        navigate(`/business/${id}`);

    }
    const goToHome = () => {
        navigate("/")
    }
    console.log(orderDetails, "complete")
    return (
        <div className="payment-success">
            <Header />
            <section className='pb-0 mt-0 pt-0'>
                {
                    type && type === "payment-success" && (
                        <div className='container-fluid' id='thanks-note-outer'>
                            <Row>
                                <Col md={12}>
                                    {
                                        type && type === "payment-success" && (
                                            <div>
                                                <Container>
                                                    <Row className='text-center mt-5'>
                                                        <img src={YesSuccess} alt="small-info" className="payment-success-icon" />
                                                        <div className='thanks-note'>
                                                            <h1>Thank you for your order! </h1>
                                                            <p>The order confirmation details of your order has been sent to your register email address.</p>
                                                            <Card className='orderid-card'>
                                                                <h6>YOUR ORDER ID: {orderDetails?.order_id}</h6>
                                                            </Card>
                                                            <p>Placed on: {moment(orderDetails?.created_at).local().format("DD MMM YYYY")}</p>
                                                            <h5 onClick={redirectToExplore}>Continue Explore <MdOutlineNavigateNext size={18} /> </h5>
                                                        </div>
                                                    </Row>
                                                </Container>
                                                <hr />

                                                <div>
                                                    <Container className='mt-5'>
                                                        <div className='payment-sub-cards'>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Row className='text-center'>
                                                                                <p>Appointment Date / Time</p>
                                                                                <h6>{moment(orderDetails?.appointment?.appointment_start_dateTime).local().format("DD MMM YY hh:mm A")}</h6>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Row className='text-center'>
                                                                                <p>Service type</p>
                                                                                {
                                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                                                        <h6>Home Service</h6>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    orderDetails?.service_type === "bridal" && (
                                                                                        <h6>Home Service</h6>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    orderDetails?.service_provider?.vendor_type === "business" && (
                                                                                        <>
                                                                                            {
                                                                                                orderDetails?.service_type === "basic" && (
                                                                                                    <h6>Shop Service</h6>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Row className='text-center'>
                                                                                <p>Verification CODE</p>
                                                                                <h6>{orderDetails?.order_OTP}</h6>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Container>
                                                </div>

                                                <Container>
                                                    <Row>
                                                        <Col md={7}>
                                                            {
                                                                type && type === "payment-success" && (
                                                                    <div className='order-view-info mt-5'>


                                                                        <p className='payment-info-text'>You will receive updates from</p>
                                                                        <Card className='payment-info-card'>
                                                                            <Row className='mx-2'>
                                                                                <Col md={7} className="payment-info-inner">
                                                                                    <div>
                                                                                        <Row>
                                                                                            <div className='payment-suceess-service-info'>
                                                                                                <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                                                                                                <h6>{orderDetails?.service_provider?.business_email}</h6>
                                                                                            </div>
                                                                                        </Row>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col md={5} className="">
                                                                                    <Row>
                                                                                        <div className='payment-suceess-service-info'>
                                                                                            <p> <ion-icon name="call-outline"></ion-icon></p>
                                                                                            <h6>{orderDetails?.service_provider?.business_contactNo}</h6>

                                                                                        </div>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                        <Row className='payment-order-info mt-5'>
                                                                            <Col>
                                                                                <p className='payment-info-text'>Order Details</p>
                                                                            </Col>
                                                                            <Col>
                                                                                <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                                                            </Col>

                                                                        </Row>
                                                                        <Card>
                                                                            <Card.Header className=' bg-white'>
                                                                                <Row className='mt-2 mb-2'>
                                                                                    <Col md={2} sm={4} xs={4}>
                                                                                        <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                                                                    </Col>
                                                                                    <Col className='payment-orderInfo-details px-0' md={10} sm={8} xs={8}>
                                                                                        <h6 className='mt-2'>{orderDetails?.service_provider?.business_name}</h6>
                                                                                        <p className='mt-2'>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                                                                    </Col>

                                                                                </Row>
                                                                            </Card.Header>
                                                                            <Card.Header className='bg-white'>
                                                                                <Row>
                                                                                    {
                                                                                        orderDetails?.services && orderDetails?.services.length > 0 && (
                                                                                            orderDetails?.services?.map((item, index) => {

                                                                                                return (
                                                                                                    <div key={index}>
                                                                                                        <Row className='mb-2'>
                                                                                                            <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                                                <span>{item?.gender?.[0]?.gender_name}</span>
                                                                                                                <p class="service-heading">{item?.service_name}</p>
                                                                                                                <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                                            </Col>
                                                                                                            <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                                                <div className='cart-check-out-amount'>
                                                                                                                    <p></p>
                                                                                                                    <p></p>
                                                                                                                    <p className='mt-5'>{item?.service_price?.["$numberDecimal"] + " ₹ "}</p>
                                                                                                                </div>
                                                                                                            </Col>

                                                                                                        </Row>
                                                                                                        <hr />

                                                                                                    </div>

                                                                                                );
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                </Row>
                                                                            </Card.Header>

                                                                        </Card>
                                                                    </div>

                                                                )
                                                            }
                                                        </Col>
                                                        <Col md={5}>
                                                            <div className='mt-5'>
                                                                <p className='payment-info-text'>Payment Details</p>
                                                                <Card>
                                                                    <Card.Header className='pb-0 payment-info-totalCard'>
                                                                        <Row>
                                                                            <Col>
                                                                                <h1>Grand Total:</h1>
                                                                                <h1>Payment Method:</h1>
                                                                            </Col>
                                                                            <Col>
                                                                                <div className='float-end'>
                                                                                    <Row className='mt-1 mb-0 pb-0'>
                                                                                        <Col className='text-end px-2'>
                                                                                            <h6 className='float-end'>₹{Math.round(orderDetails?.final_price) + ".00"}</h6>
                                                                                        </Col>

                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col className='text-end px-2 mt-2'>
                                                                                            <h6 className='float-end'>UPI</h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Row>
                                                                            <Col>
                                                                                <div className='payment-total-card-inner'>
                                                                                    <p>Price</p>
                                                                                    <p>GST 18%</p>
                                                                                    <p>Discount</p>
                                                                                    <p>Coupon for you</p>
                                                                                    <p>Grand Total</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <div className='float-end payment-total-card-inner'>
                                                                                    <Row>
                                                                                        <Col className='text-end px-0'>
                                                                                        <p>{Math.round(orderDetails?.final_price + (orderDetails?.coupon/100) - (orderDetails?.gst_value/100)) + ".00" }</p>
                                                                                        <p>{Math.round(orderDetails?.gst_value/100) + ".00"}</p>
                                                                                            <p className='green_text'>{Math.round(orderDetails?.discount) + ".00"}</p>
                                                                                            <p className='green_text'>{(orderDetails?.coupon)/100 + ".00"}</p>
                                                                                            <p>{Math.round(orderDetails?.final_price)+ ".00"}</p>
                                                                                        </Col>
                                                                                        <Col className='Payment-amount-inner'>
                                                                                            <p>₹</p>
                                                                                            <p>₹</p>
                                                                                            <p>₹</p>
                                                                                            <p>₹</p>
                                                                                            <p>₹</p>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>
                                                            <div className='mt-5'>
                                                                <p className='payment-info-text'>Contact Details</p>
                                                                {
                                                                    orderDetails?.service_type === "bridal" && (
                                                                        <>
                                                                            <Card>
                                                                                <Card.Body>
                                                                                    <div className='payment-user-contact-info'>
                                                                                        <p>{orderDetails?.customer_details?.username}</p>
                                                                                        <p>{orderDetails?.customer_venue_details?.[0]?.address + ", " + orderDetails?.customer_venue_details?.[0]?.city + ", " + orderDetails?.customer_venue_details?.[0]?.state + ", " + orderDetails?.customer_venue_details?.[0]?.country + ", " + orderDetails?.customer_venue_details?.[0]?.zipcode}</p>
                                                                                        <h6>{orderDetails?.customer_details?.mobile_no}</h6>
                                                                                        <h6>{orderDetails?.customer_details?.email_id}</h6>
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" && (
                                                                                    <>
                                                                                        <Card>
                                                                                            <Card.Body>
                                                                                                <div className='payment-user-contact-info'>
                                                                                                    <p>{orderDetails?.customer_details?.username}</p>
                                                                                                    <p>{orderDetails?.customer_venue_details?.[0]?.address + ", " + orderDetails?.customer_venue_details?.[0]?.city + ", " + orderDetails?.customer_venue_details?.[0]?.state + ", " + orderDetails?.customer_venue_details?.[0]?.country + ", " + orderDetails?.customer_venue_details?.[0]?.zipcode}</p>
                                                                                                    <h6>{orderDetails?.customer_details?.mobile_no}</h6>
                                                                                                    <h6>{orderDetails?.customer_details?.email_id}</h6>
                                                                                                </div>
                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    orderDetails?.service_provider?.vendor_type === "business" && (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" && (
                                                                                    <>
                                                                                        <Card>
                                                                                            <Card.Body>
                                                                                                <div className='payment-user-contact-info'>
                                                                                                    <p>{orderDetails?.customer_location_details?.alternate_username}</p>
                                                                                                    <p>{orderDetails?.customer_location_details?.area}{orderDetails?.customer_location_details?.city}</p>
                                                                                                    <h6>{orderDetails?.customer_location_details?.alternate_phone}</h6>
                                                                                                    <h6>{orderDetails?.customer_location_details?.alternate_emailID}</h6>
                                                                                                </div>
                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }


                                                            </div>
                                                            <div className='payment-sucess-need-help mt-5'>
                                                                <h1>Need Help?</h1>
                                                               

                                                                {
                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" ? (
                                                                        null
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" ? (
                                                                                    <>
                                                                                        {
                                                                                            orderDetails?.rescheduled !== true && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "failed" && orderDetails.order_status !== "noshow" && (
                                                                                                <div className='d-flex'>
                                                                                                    <p className='help-icons'><ion-icon name="calendar-outline"></ion-icon></p>
                                                                                                    <p onClick={redirectReschedule}>Reschedule</p>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }

{
                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" ? (
                                                                        null
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" ? (
                                                                                    <>
                                                                                       {
                                                                                            orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "failed" && orderDetails.order_status !== "noshow" && (
                                                                                                <div className='d-flex'>
                                                                                                    <p className='help-icons'><ion-icon name="close-circle-outline"></ion-icon></p>
                                                                                                    <p onClick={() => redirectPaymentDetails("cancel")}>Cancel Order</p>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }


                                                                <div className='d-flex'>
                                                                    <p className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></p>
                                                                    <p onClick={redirectSupport}>Support Center</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </Container>

                                                {/* <div>
                                                <h1>Your Payment is <span className='text-success'>Successful</span></h1>
                                                <h6 className='mt-4'>Order Id: <span>{orderDetails?.order_id}</span> </h6>
                                               
                                                <h6>Placed on: {moment(orderDetails?.created_at).local().format("YYYY-MM-DD hh:mm A")}</h6>
                                            </div> */}
                                            </div>

                                        )}

                                </Col>
                            </Row>
                        </div>
                    )
                }

                {
                    type && type === "view" && (
                        <div>
                            <Container>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={goToHome} className="subnav-list">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item className="subnav-list" onClick={redirectOrdersPage}>
                                        My Order
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="subnav-list">
                                        Order Id: {orderDetails?.order_id}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>
                            <Container>
                                <Row className='order-id-info'>
                                    <Table responsive borderless className='order-info-tabel px-0 mx-0'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'> Order Id: {orderDetails?.order_id}</p>
                                                </td>
                                                <td>
                                                    {
                                                        orderDetails?.order_status === "completed" && (
                                                            <div className='float-end'>
                                                                <Button className='rate-invite' onClick={() => redirectToRating(orderDetails?.service_provider?._id)} >Rate & Review</Button>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                </Row>
                                <hr />
                                <Container className='mt-5'>
                                    <div className='payment-sub-cards'>
                                        <Row>
                                            <Col md={4}>
                                                <Card>
                                                    <Card.Body>
                                                        <Row className='text-center'>
                                                            <p>Appointment Date / Time</p>
                                                            <h6>{moment(orderDetails?.appointment?.appointment_start_dateTime).local().format("DD MMM YY hh:mm A")}</h6>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4}>
                                                <Card>
                                                    <Card.Body>
                                                        <Row className='text-center'>
                                                            <p>Service type</p>
                                                            {
                                                                orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                                    <h6>Home Service</h6>
                                                                )
                                                            }
                                                            
                                                            {
                                                                orderDetails?.service_provider?.vendor_type === "business" && (
                                                                    <>
                                                                        {
                                                                            orderDetails?.service_type === "basic" ? (
                                                                                <h6>Shop Service</h6>
                                                                            ) : (
                                                                                <h6>Home Service</h6>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }

                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4}>
                                                <Card>
                                                    <Card.Body>
                                                        <Row className='text-center'>
                                                            <p>Verification CODE</p>
                                                            <h6>{orderDetails?.order_OTP}</h6>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                                <Row>
                                    <Col md={7}>
                                        <div className='order-view-info mt-5'>
                                            <Row>
                                                <p className='payment-info-text'>Order Status</p>
                                                <Card className='order-payment-view'>
                                                    <Card.Body className=''>
                                                        <Row>
                                                            <Table responsive borderless className='order-info-tabel'>
                                                                <tbody>

                                                                    <tr>
                                                                        <td>
                                                                            <div className='order-status-card'>
                                                                                <span><ion-icon name="ellipse"></ion-icon></span>
                                                                                <p >Order Status</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <p className='text-end'>Order {orderDetails?.order_status}</p>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        orderDetails?.service_provider?.vendor_type === "business" && (
                                                                            <>
                                                                                {
                                                                                    orderDetails?.service_type === "bridal" && (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td><p>Payment 1 Status</p></td>
                                                                                                <td className='text-end'>
                                                                                                    {
                                                                                                        orderDetails?.payment?.[0]?.status && orderDetails?.payment?.[0]?.status === "complete" ? (
                                                                                                            <h3 className='payment-completed-order'>Payment 1 Completed</h3>
                                                                                                        ) : (
                                                                                                            <a href={orderDetails?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(orderDetails?.amount_to_pay_1.toFixed(2))} ₹</a>
                                                                                                        )
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                {
                                                                                                    orderDetails?.payment?.[0]?.status && orderDetails?.payment?.[0]?.status === "complete" ? (
                                                                                                        <>
                                                                                                            {
                                                                                                                orderDetails?.payment?.[1].status === "complete" ? (
                                                                                                                    <>
                                                                                                                        <td><p>Payment 2 Status</p></td>
                                                                                                                        <td className='text-end'>
                                                                                                                            <h3 className='payment-completed-order'>Payment 2 completed</h3>
                                                                                                                        </td>
                                                                                                                    </>

                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <td><p>Payment 2 Status</p></td>
                                                                                                                        <td className='text-end'>
                                                                                                                            <a href={orderDetails?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(orderDetails?.amount_to_pay_2.toFixed(2))} ₹</a>
                                                                                                                        </td>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }

                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <td><p>Payment 2 Status</p></td>
                                                                                                            <td className='text-end'>
                                                                                                                <p className='click-to-pay-in-active'>click to pay {(orderDetails?.amount_to_pay_2.toFixed(2))} ₹</p>
                                                                                                            </td>


                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                            </tr>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                    {
                                                                        orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                                            <>
                                                                                {
                                                                                    orderDetails?.service_type === "bridal" ? (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td><p>Payment 1 Status</p></td>
                                                                                                <td className='text-end'>
                                                                                                    {
                                                                                                        orderDetails?.payment?.[0]?.status && orderDetails?.payment?.[0]?.status === "complete" ? (
                                                                                                            <h3 className='payment-completed-order'>Payment 1 Completed</h3>
                                                                                                        ) : (
                                                                                                            <a href={orderDetails?.payment_link} target="blank" className='text-end'>Click to pay {(orderDetails?.amount_to_pay_1.toFixed(2))} ₹</a>
                                                                                                        )
                                                                                                    }

                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td><p>Payment 2 Status</p></td>
                                                                                                <td className='text-end'>
                                                                                                    <a href={orderDetails?.payment_link} target="blank" className='text-end'>Click to pay {(orderDetails?.amount_to_pay_2.toFixed(2))} ₹</a>

                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                  <tr>
                                                                                                {
                                                                                                    orderDetails?.payment_id !== "" ?
                                                                                                    (
                                                                                                            <>
                                                                                                            <td><p>Payment Status</p></td>
                                                                                                <td className='text-end'>

                                                                                                <h3 className='payment-completed-order'>Payment Completed</h3>
                                                                                                </td>
                                                                                                            </>
                                                                                                    ): (
                                                                                                        <>
                                                                                                        <td><p>Payment Status</p></td>
                                                                                                <td className='text-end'>

                                                                                                    <a href={orderDetails?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(orderDetails?.final_price)} ₹</a>
                                                                                                </td>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                            </tr>
                                                                                        </>

                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                    {
                                                                        orderDetails?.service_provider?.vendor_type === "business" && (
                                                                            <>
                                                                                {orderDetails?.service_type === "basic" && (
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className='payment-status-card'>
                                                                                                <span><ion-icon name="ellipse"></ion-icon></span>
                                                                                                <p>Payment Status</p>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h6 className='text-end'>{orderDetails?.payment_status}</h6>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    }


                                                                </tbody>
                                                            </Table>

                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                            <Row className='mt-5'>
                                                <p className='payment-info-text'>You will receive updates from</p>
                                                <Card className='payment-info-card'>
                                                    <Row className='mx-2'>
                                                        <Col md={7} className="payment-info-inner">
                                                            <div>
                                                                <Row>
                                                                    <div className='payment-suceess-service-info'>
                                                                        <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                                                                        <h6>{orderDetails?.service_provider?.business_email}</h6>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col md={5} className="">
                                                            <Row>
                                                                <div className='payment-suceess-service-info'>
                                                                    <p> <ion-icon name="call-outline"></ion-icon></p>
                                                                    <h6>{orderDetails?.service_provider?.business_contactNo}</h6>

                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Row>


                                            <Row>
                                                <Row className='payment-order-info mt-5'>
                                                    <Col>
                                                        <p className='payment-info-text'>Order Details</p>
                                                    </Col>


                                                </Row>
                                                <Card>
                                                    <Card.Header className=' bg-white'>
                                                        <Row className='mt-2 mb-2'>
                                                            <Col md={2} sm={4} xs={4}>
                                                                <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                                            </Col>
                                                            <Col className='payment-orderInfo-details px-0' md={10} sm={8} xs={8}>
                                                                <h6 className='mt-2'>{orderDetails?.service_provider?.business_name}</h6>
                                                                <p className='mt-2'>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                                            </Col>

                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Header className='bg-white'>
                                                        <Row>
                                                            {
                                                                orderDetails?.services && orderDetails?.services.length > 0 && (
                                                                    orderDetails?.services?.map((item, index) => {

                                                                        return (
                                                                            <div key={index}>
                                                                                <Row className='mb-2'>

                                                                                    <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                        <span>{item?.gender?.[0]?.gender_name}</span>
                                                                                        <p class="service-heading">{item?.service_name}</p>
                                                                                        <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                    </Col>
                                                                                    <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                        <div className='cart-check-out-amount'>
                                                                                            <p></p>
                                                                                            <p></p>
                                                                                            <p className='mt-5'>{item?.service_price?.["$numberDecimal"] + " ₹ "}</p>
                                                                                        </div>
                                                                                    </Col>

                                                                                </Row>
                                                                                <hr />

                                                                            </div>

                                                                        );
                                                                    })
                                                                )
                                                            }
                                                        </Row>
                                                    </Card.Header>

                                                </Card>
                                            </Row>
                                        </div>
                                    </Col>


                                    <Col md={5}>
                                        <div>
                                            <p className='payment-info-text mt-5'>Payment Details</p>
                                            <Card>
                                                <Card.Header className='pb-0 payment-info-totalCard'>
                                                    <Row>
                                                        <Col>
                                                            <h1>Grand Total:</h1>
                                                            <h1>Payment Method:</h1>
                                                        </Col>
                                                        <Col>
                                                            <div className='float-end'>
                                                                <Row className='mt-1 mb-0 pb-0'>
                                                                    <Col className='text-end px-2'>
                                                                        <h6 className='float-end'>₹{Math.round(orderDetails?.final_price) + ".00"}</h6>
                                                                    </Col>

                                                                </Row>
                                                                <Row>
                                                                    <Col className='text-end px-2 mt-2'>
                                                                        <h6 className='float-end'>UPI</h6>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row className=''>
                                                        <Col>
                                                            <div className='payment-total-card-inner'>
                                                                <p>Price</p>
                                                                <p>GST 18%</p>
                                                                <p>Discount</p>
                                                                <p>Coupon for you</p>
                                                                <p>Grand Total</p>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='float-end payment-total-card-inner'>
                                                                <Row>
                                                                    <Col className='text-end px-0'>
                                                                    <p>{Math.round(orderDetails?.final_price + (orderDetails?.coupon/100) - (orderDetails?.gst_value/100))  + ".00" }</p>
                                                                    <p>{Math.round(orderDetails?.gst_value/100) + ".00"}</p>
                                                                        <p className='green_text'>{orderDetails?.discount}</p>
                                                                        <p className='green_text'>{(orderDetails?.coupon)/100}</p>
                                                                        <p>{orderDetails?.final_price}</p>
                                                                    </Col>
                                                                    <Col className='Payment-amount-inner'>
                                                                        <p>₹</p>
                                                                        <p>₹</p>
                                                                        <p>₹</p>
                                                                        <p>₹</p>
                                                                        <p>₹</p>

                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className='mt-5'>
                                            <p className='payment-info-text'>Contact Details</p>
                                            {
                                                orderDetails?.service_type === "bridal" && (
                                                    <>
                                                        <Card>
                                                            <Card.Body>
                                                                <div className='payment-user-contact-info'>
                                                                    <p>{orderDetails?.customer_details?.username}</p>
                                                                    <p>{orderDetails?.customer_venue_details?.[0]?.address + ", " + orderDetails?.customer_venue_details?.[0]?.city + ", " + orderDetails?.customer_venue_details?.[0]?.state + ", " + orderDetails?.customer_venue_details?.[0]?.country + ", " + orderDetails?.customer_venue_details?.[0]?.zipcode}</p>
                                                                    <h6>{orderDetails?.customer_details?.mobile_no}</h6>
                                                                    <h6>{orderDetails?.customer_details?.email_id}</h6>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </>
                                                )
                                            }
                                            {
                                                orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                    <>
                                                        {
                                                            orderDetails?.service_type === "basic" && (
                                                                <>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <div className='payment-user-contact-info'>
                                                                                <p>{orderDetails?.customer_details?.username}</p>
                                                                                <p>{orderDetails?.customer_venue_details?.[0]?.address + ", " + orderDetails?.customer_venue_details?.[0]?.city + ", " + orderDetails?.customer_venue_details?.[0]?.state + ", " + orderDetails?.customer_venue_details?.[0]?.country + ", " + orderDetails?.customer_venue_details?.[0]?.zipcode}</p>
                                                                                <h6>{orderDetails?.customer_details?.mobile_no}</h6>
                                                                                <h6>{orderDetails?.customer_details?.email_id}</h6>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            {
                                                orderDetails?.service_provider?.vendor_type === "business" && (
                                                    <>
                                                        {
                                                            orderDetails?.service_type === "basic" && (
                                                                <>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <div className='payment-user-contact-info'>
                                                                                <p>{orderDetails?.customer_location_details?.alternate_username}</p>
                                                                                <p>{orderDetails?.customer_location_details?.area}{orderDetails?.customer_location_details?.city}</p>
                                                                                <h6>{orderDetails?.customer_location_details?.alternate_phone}</h6>
                                                                                <h6>{orderDetails?.customer_location_details?.alternate_emailID}</h6>
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }


                                        </div>


                                        <div className='payment-sucess-need-help mt-5'>
                                            <h1>Need Help?</h1>

                                            
                                            {
                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" ? (
                                                                        null
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" ? (
                                                                                    <>
                                                                                        {
                                                                                            orderDetails?.rescheduled !== true && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "failed" && orderDetails.order_status !== "noshow" && (
                                                                                                <div className='d-flex'>
                                                                                                    <p className='help-icons'><ion-icon name="calendar-outline"></ion-icon></p>
                                                                                                    <p onClick={redirectReschedule}>Reschedule</p>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }

{
                                                                    orderDetails?.service_provider?.vendor_type === "freelancer" ? (
                                                                        null
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                orderDetails?.service_type === "basic" ? (
                                                                                    <>
                                                                                       {
                                                                                            orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "failed" && orderDetails.order_status !== "noshow" && (
                                                                                                <div className='d-flex'>
                                                                                                    <p className='help-icons'><ion-icon name="close-circle-outline"></ion-icon></p>
                                                                                                    <p onClick={() => redirectPaymentDetails("cancel")}>Cancel Order</p>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }


                                            {/* {
                                                orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "failed" && orderDetails?.order_status !== "noshow" && (
                                                    <div className='d-flex'>
                                                        <p className='help-icons'><ion-icon name="close-circle-outline"></ion-icon></p>
                                                        <p onClick={() => redirectPaymentDetails("cancel")}>Cancel Order</p>
                                                    </div>
                                                )
                                            }

                                            {
                                                orderDetails?.rescheduled !== true && orderDetails?.order_status !== "completed" && orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "failed" && orderDetails?.order_status !== "noshow" && (
                                                    <div className='d-flex'>
                                                        <p className='help-icons'><ion-icon name="calendar-outline"></ion-icon></p>
                                                        <p onClick={redirectReschedule}>Rescheduled</p>
                                                    </div>
                                                )
                                            } */}

                                            <div className='d-flex'>
                                                <p className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></p>
                                                <p onClick={redirectSupport}>Support Center</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                    )
                }



                <div>
                    {
                        type && type === "cancel" && (
                            <div className='order-cancel-process mt-5'>
                                <Container>
                                    <Row>
                                        <Col md={7}>
                                            <div className='cancel-form-outer'>
                                                <div>
                                                    <p className='payment-info-text'>Order Cancellation Details</p>
                                                </div>
                                                {
                                                    type && type === "cancel" && (
                                                        <div className="mb-5">

                                                            <Card className='cancel-card-inner'>
                                                                <Form>
                                                                    <FormikProvider value={formik}>
                                                                        <Card.Header className='cancel-card-header'>
                                                                            <h6 className='mt-2'>Easy Cancellation</h6>
                                                                        </Card.Header>
                                                                        <Card.Body className='cancel-body-inner'>
                                                                            <div class="card-body">
                                                                                <div class="mb-3">
                                                                                    <Form.Label className="">Reason for cancellation *</Form.Label>
                                                                                    <Form.Select
                                                                                        name="refundReason"
                                                                                        placeholder="Reason"
                                                                                        onChange={formik.handleChange}
                                                                                        value={formik.values.refundReason}
                                                                                    >
                                                                                        <option>Select Reason for cancellation</option>
                                                                                        <option value="Concerned about service quality/hygiene">Concerned about service quality/hygiene</option>
                                                                                        <option value="Found another service provider in Slaylewks">Found another service provider in Slaylewks</option>
                                                                                        <option value="Found another service provider outside Slaylewks">Found another service provider outside Slaylewks</option>
                                                                                        <option value="Booked by mistake">Booked by mistake</option>
                                                                                        <option value="Change of plans">Change of plans</option>
                                                                                        <option value="Others">Others</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                                <div class="mb-3">
                                                                                    <Form.Label >Comment</Form.Label>
                                                                                    <div class="select-content">
                                                                                        <textarea class="form-control common-text" id="refundMessage" rows="5"
                                                                                            placeholder="Message"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.refundMessage}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </Card.Body>

                                                                        <Card.Header className='cancel-card-Subheader'>
                                                                            <h6 className='mt-1 mb-0'>Refund Modes</h6>
                                                                        </Card.Header>
                                                                        <Card.Body className=''>
                                                                            <Row className='eligible-text'>
                                                                                <Col>
                                                                                    <p className=''>Eligible Refund amount</p>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className='float-end'>
                                                                                        <p>{orderDetails?.final_price} ₹</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <p class="select-mode-cancel">Select a Mode of Refund </p>

                                                                            </Row>
                                                                            <Row>
                                                                                <div class="form-check mx-3">
                                                                                    <Form.Check
                                                                                        className=""
                                                                                        type='radio'
                                                                                        id='confirmation'
                                                                                        name="confirmation"
                                                                                        checked={formik.values.confirmation === "true"}
                                                                                        onChange={() => formik.setFieldValue("confirmation", "true")}
                                                                                    />
                                                                                    <label class="form-check-label mb-2 orginal-payment" for="flexRadioDefault1" >
                                                                                        Orginal Payment Mode
                                                                                    </label>
                                                                                </div>
                                                                            </Row>
                                                                            <Row className='mx-2'>
                                                                                <button class="proceed-cancel" onClick={raiseRefunds} disabled={loading}>Proceed to cancel</button>
                                                                            </Row>

                                                                        </Card.Body>
                                                                    </FormikProvider>
                                                                </Form>
                                                            </Card>
                                                            <div className='refund-note mt-2'>
                                                                <h6>Refund will be processed with in 8 days</h6>
                                                                <p>By clicking on “Request Cancellation”, I agree to <span onClick={navigateTerms}>Terms and Conditions</span> of Refund.</p>
                                                            </div>

                                                            <div className='mt-2'>
                                                                <Card className='refund-policy'>
                                                                    <h6>Refund policy</h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                                </Card>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <Row className='payment-order-info'>
                                                <Col>
                                                    <p className='payment-info-text'>Order Details</p>
                                                </Col>
                                                <Col>
                                                    <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                                </Col>

                                            </Row>
                                            <Row >
                                                <Card className='order-details-cancel'>
                                                    <Card.Body className='mt-3'>
                                                        <Row >
                                                            <Col>
                                                                <div>
                                                                    <p>Appointment Date / Time</p>
                                                                    <p>Service type</p>
                                                                    <p>Verification CODE</p>

                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='float-end'>
                                                                    <p>{moment(orderDetails?.appointment?.appointment_start_dateTime).local().format("DD MMM YY hh:mm A")}</p>

                                                                    {
                                                                        orderDetails?.service_provider?.vendor_type === "freelancer" && (
                                                                            <p>Home Service</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        orderDetails?.service_type === "bridal" && (
                                                                            <p>Home Service</p>
                                                                        )
                                                                    }
                                                                    {
                                                                        orderDetails?.service_provider?.vendor_type === "business" && (
                                                                            <>
                                                                                {
                                                                                    orderDetails?.service_type === "basic" && (
                                                                                        <p>Shop Service</p>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }


                                                                    <p>{orderDetails?.order_OTP}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                            <Row>
                                                <Card>
                                                    <Card.Header className=' bg-white'>
                                                        <Row className='mt-2 mb-2'>
                                                            <Col md={1}>
                                                                <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                                            </Col>
                                                            <Col className='payment-cancel-details px-0' md={11}>
                                                                <h6 className='mt-2'>{orderDetails?.service_provider?.business_name}</h6>
                                                                <p className='mt-2'>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                                            </Col>

                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Header className='bg-white'>
                                                        <Row>
                                                            {
                                                                orderDetails?.services && orderDetails?.services.length > 0 && (
                                                                    orderDetails?.services?.map((item, index) => {

                                                                        return (
                                                                            <div key={index}>
                                                                                <Row className='mb-2'>

                                                                                    <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                        <span>{item?.gender?.[0]?.gender_name}</span>
                                                                                        <p class="service-heading">{item?.service_name}</p>
                                                                                        <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                    </Col>
                                                                                    <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                        <div className='cart-check-out-amount'>
                                                                                            <p></p>
                                                                                            <p></p>
                                                                                            <p className='mt-5'>{item?.service_price?.["$numberDecimal"] + "₹"}</p>
                                                                                        </div>
                                                                                    </Col>

                                                                                </Row>
                                                                                <hr />

                                                                            </div>

                                                                        );
                                                                    })
                                                                )
                                                            }
                                                        </Row>
                                                    </Card.Header>

                                                </Card>
                                            </Row>

                                            <div>
                                                <p className='payment-info-text mt-3 mb-1'>Payment Details</p>
                                                <Card>
                                                    <Card.Header className='pb-0 payment-info-totalCard'>
                                                        <Row>
                                                            <Col>
                                                                <h1>Grand Total:</h1>
                                                                <h1>Payment Method:</h1>
                                                            </Col>
                                                            <Col>
                                                                <div className='float-end'>
                                                                    <Row className='mt-1 mb-0 pb-0'>
                                                                        <Col className='text-end px-2'>
                                                                            <h6 className='float-end'>₹{Math.round(orderDetails?.final_price) + ".00"}</h6>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='text-end px-2 mt-2'>
                                                                            <h6 className='float-end'>UPI</h6>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>
                                                                <div className='payment-total-card-inner'>
                                                                    <p>Price</p>
                                                                    <p>GST 18%</p>
                                                                    <p>Discount</p>
                                                                    <p>Coupon for you</p>
                                                                    <p>Grand Total</p>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='float-end payment-total-card-inner'>
                                                                    <Row>
                                                                        <Col className='text-end px-0'>
                                                                        <p>{Math.round(orderDetails?.final_price + (orderDetails?.coupon/100) - (orderDetails?.gst_value/100)) + ".00" }</p>
                                                                        <p>{Math.round(orderDetails?.gst_value/100) + ".00"}</p>
                                                                            <p className='green_text'>{orderDetails?.discount + ".00"}</p>
                                                                            <p className='green_text'>{(orderDetails?.coupon)/100 + ".00"}</p>
                                                                            <p>{orderDetails?.final_price + ".00"}</p>
                                                                        </Col>
                                                                        <Col className='Payment-amount-inner'>
                                                                            <p>₹</p>
                                                                            <p>₹</p>
                                                                            <p>₹</p>
                                                                            <p>₹</p>
                                                                            <p>₹</p>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>

                                            <div>
                                                <p className='payment-info-text mt-3 mb-1'>Contact Details</p>
                                                <Card>
                                                    <Card.Body>
                                                        <div className='payment-user-contact-info'>
                                                            <p>{orderDetails?.customer_location_details?.alternate_username}</p>
                                                            <p>{orderDetails?.customer_location_details?.area}{orderDetails?.customer_location_details?.city}</p>
                                                            <h6>{orderDetails?.customer_location_details?.alternate_phone}</h6>
                                                            <h6>{orderDetails?.customer_location_details?.alternate_emailID}</h6>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )
                    }
                </div>
                {
                    type && type === "cancel-success" && (
                        <div className='p-0 m-0'>
                            <CancellationSucess />
                        </div>
                    )
                }
                {
                    type && type === "cancellation_raised" && (
                        <div>
                            <CancellationSucess />
                        </div>
                    )
                }

            </section>
            <div className="mt-5">
                {
                    type === "view" && (
                        <div>
                            <Footer />
                        </div>
                    )
                }
                {
                    type !== "view" && (
                        <div>
                            <CartFooter />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PaymentSuccess