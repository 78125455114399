import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap'
// import profile from '../../../assets/images/profile.webp';
import { AiOutlineUpload } from 'react-icons/ai';
import { useFormik, FormikProvider } from 'formik';
// import ProfileImg from "../../../assets/images/profile.webp"
import AvatarImg from "../../../assets/images/avatar.webp";
import EditImg from "../../../assets/images/edit.png"
// import InfoImg from "../../../assets/images/info.png";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/elements/header/Header';
import Footer from '../../../components/elements/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { logoutFromLocalStorage, updateUserAction, verifyEmail, verifyRegisteredEmail, resendOtp, verifyProfileEmailAction } from '../../../redux/auth/actions';
import { fileUpload } from '../../../redux/business/actions';
import ResetPassword from "../../../components/elements/auth/ResetPassword";
// import { GrFormFilter } from 'react-icons/gr';
// import { profileValidationSchema } from '../../../validations/yub_schemas/profileValidation';
// import { BsInfoCircle } from 'react-icons/bs';


const BusinessProfilePage = () => {
    const [loading, setLoading] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [optInput, setOtpInput] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [profileImg, setProfileImg] = useState(null)

    const { user } = useSelector((state) => state.auth)
    const { vendor } = useSelector((state) => state.business)

    const validate = values => {
        const errors = {};
        if (!values.business_name) {
            errors.business_name = 'Required';
        } else if (values.business_name.length > 15) {
            errors.business_name = 'Must be 15 Characters or less';

        }

        if (!values.business_address) {
            errors.business_address = 'Required';
        } else if (values.business_address.length > 15) {
            errors.business_address = 'Must be 15 Characters or less';

        }
        if (!values.business_address_map) {
            errors.business_address_map = 'Required';
        } else if (values.business_address_map.length > 15) {
            errors.business_address_map = 'Must be 15 Characters or less';

        }
        if (!values.city) {
            errors.city = 'Required';
        } else if (values.city.length > 15) {
            errors.city = 'Must be 15 Characters or less';

        }

        if (!values.State) {
            errors.State = 'Required';
        } else if (values.State.length > 15) {
            errors.State = 'Must be 15 Characters or less';

        }


        if (!values.country) {
            errors.country = 'Required';
        } else if (values.country.length > 15) {
            errors.country = 'Must be 15 Characters or less';

        }

        if (!values.zip_code) {
            errors.zip_code = 'Required';
        } else if (values.zip_code.length > 15) {
            errors.zip_code = 'Must be 15 Characters or less';

        }

        if (!values.phone_no) {
            errors.phone_no = 'Required';
        } else if (!/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/.test(values.phone_no)) {
            errors.phone_no = 'Invalid Phone Number';
        }

        // if (!values.landline_no) {
        //     errors.landline_no = 'Required';
        // } else if (!/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/.test(values.landline_no)) {
        //     errors.landline_no = 'Invalid Phone Number';
        // }

        // if (!values.account_no) {
        //     errors.account_no = 'Required';
        // } else if (!/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/.test(values.account_no)) {
        //     errors.account_no = 'Invalid Phone Number';
        // }

        return errors;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            reg_id: vendor?.reg_id || '',
            business_name: vendor?.business_name || '',
            phone_no: vendor?.mobile_no || '',
            email: vendor?.email_id || '',
            profileURL: vendor?.profileURL || '',
            landline_no: vendor?.landline_no || '',
            account_no: vendor?.account_no || '',
            business_address: vendor?.business_address || '',
            business_address_map: vendor?.business_address_map || '',
            city: vendor?.city || '',
            State: vendor?.State || '',
            country: vendor?.country || '',
            zip_code: vendor?.zip_code || ''

        },
        validate,
        onSubmit: (values) => {
            const data = {

                id: vendor?._id,
                reg_id: values?.reg_id,
                business_name: values?.business_name,
                mobile_no: values?.phone_no,
                email_id: values?.email_id,
                profileURL: values?.profileURL,
                landline_no: values?.landline_no,
                account_no: values?.account_no,
                business_address: values?.business_address,
                business_address_map: values?.business_address_map,
                city: values?.city,
                State: values?.State,
                country: values?.country,
                zip_code: values?.zip_code

            }; console.log(data)
            dispatch(updateUserAction(data)).then((res) => {
            });

            setEditOption(false);
            console.log(values)
        }
        
    })
  console.log(formik.values);
  console.log(formik.errors);
    const formikVerify = useFormik({
        initialValues: {
            otp: '',
        },
        onSubmit: values => {
            console.log(values);
            const data = {
                userId: vendor?._id,
                otp: values.otp
            }
            dispatch(verifyEmail(data))
                .then(res => {
                    formikVerify.handleReset();
                    setOtpInput(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    })

    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);
    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            console.log(file);
            setProfileImg(file)
        }

    }
    const changeEditOption = (type) => {
        console.log(type)
        setEditOption(type)
    }

    const changePasswordOption = () => {
        if (resetPasswordModal) {
            setResetPasswordModal(false);
        }
        else {
            setResetPasswordModal(true);
        }
    }

    const signoutOption = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    }

    const getVerifiedOption = () => {
        if (optInput) {
            setOtpInput(false)
        }
        else {
            setOtpInput(true)
        }

    }

    const resendOTPviaMail = () => {

        const data = {
            _id: vendor?._id,
            email_id: vendor?.email_id,
        }
        dispatch(resendOtp(data))
            .then(res => {
                console.log("OTP has been resent")
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const requestVerifyEmail = () => {
        setOtpInput(true)
        const data = {
            email_id: vendor?.email_id,
        }
        dispatch(verifyProfileEmailAction(data))
            .then(res => {
                console.log("Res =>  ", res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const uploadProfile = () => {

        if (profileImg) {
            setLoading(true)
            const formData = new FormData();
            formData.append('file', profileImg);
            formData.append('path', "profile");

            console.log("file => ", profileImg);
            console.log(formData)

            dispatch(fileUpload(formData))
                .then((res) => {
                    const { data } = res
                    formik.setFieldValue("profileURL", data?.location)
                    setLoading(false)

                }).catch((err) => {
                    setLoading(false)
                    console.log("Error => ", err);
                })
        } else {
            setLoading(false)
            console.log('Please select a file to add.');
        }

    }

    return (
        <div>
            <Header />
            <ResetPassword
                show={resetPasswordModal}
                onHide={() => setResetPasswordModal(false)}
            />
            {
                !editOption ?
                    (
                        <div className="user-profile-page">
                            <section>
                                <div class="container mt-4">
                                    <div class="row">
                                        <div class="cols-md-12 text-center">
                                            <img
                                                src={vendor?.profileURL && vendor?.profileURL !== "" ? vendor?.profileURL : AvatarImg} className='profileEdit' alt="profile" />
                                            <div class="edit-icon" onClick={() => changeEditOption(true)}>
                                                <img src={EditImg} class="img-fluid" alt="eidt" />Edit
                                            </div>
                                            <Row className="user-profile-details d-flex justify-content-center">
                                                <Col md={3} sm={3} className="profile-left">
                                                    <p >Reg Id</p>
                                                    <p>Business Name</p>
                                                    <p>Email Id</p>
                                                    <p>Phone Number</p>
                                                    <p>Landline Number</p>
                                                    <p>Account Number</p>
                                                    <p>Business Address</p>
                                                    <p>Business Address G-Map URL</p>
                                                    <p>City</p>
                                                    <p>State</p>
                                                    <p>Country</p>
                                                    <p>Zip Code</p>
                                                </Col>
                                                <Col md={4} sm={3} className="profile-right">
                                                   
                                                    <p>{vendor?.reg_id}</p>
                                                    <p>{vendor?.business_name}</p>
                                                    
                                                    <p>{vendor?.email_id}
                                                        {
                                                            vendor?.email_verified ? (
                                                                <span className="text-success ms-3 "> verified</span>
                                                            )
                                                                : (
                                                                    <span className="text-primary ms-3 cur-pointer" onClick={requestVerifyEmail}> Get verify</span>
                                                                )
                                                        }

                                                    </p>
                                                    <p>{vendor?.landline_no}</p>
                                                    <p>{vendor?.account_no}</p>
                                                    <p>{vendor?.business_address_map}</p>
                                                    <p>{vendor?.business_address}</p>
                                                    <p>{vendor?.city}</p>
                                                    <p>{vendor?.State}</p>
                                                    <p>{vendor?.country}</p>
                                                    <p>{vendor?.zip_code}</p>
                                                </Col>
                                            </Row>
                                            {
                                                optInput && (
                                                    <Row className="profile-verify-otp d-flex justify-content-center">
                                                        <Col md={12}>
                                                            <Form onSubmit={formikVerify.handleSubmit}>
                                                                <FormikProvider value={formikVerify}>
                                                                    <InputGroup className="mb-3 p-2" controlId="formBasicEmail">
                                                                        <Form.Control
                                                                            className="input-border"
                                                                            type="text"
                                                                            placeholder="Enter otp"
                                                                            name="otp"
                                                                            onChange={formikVerify.handleChange}
                                                                            value={formikVerify.values.otp}
                                                                            isInvalid={!!formikVerify.touched.otp && !!formikVerify.errors.otp}
                                                                        />

                                                                        <Button
                                                                            className="Verify-Border"
                                                                            // onClick={handleVerifyEmailOtp}
                                                                            type="submit"
                                                                        >
                                                                            Verify
                                                                        </Button>

                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formikVerify.errors.otp}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                    <p className="text-end text-primary cur-pointer profile-resend-btn" onClick={resendOTPviaMail}>Resend</p>

                                                                </FormikProvider>
                                                            </Form>

                                                        </Col>
                                                    </Row>
                                                )
                                            }

                                            <Row className="user-profile-actions d-flex justify-content-center">
                                                <Col md={3} sm={3} className="profile-left">

                                                    <p className="text-primary my-3 cur-pointer" onClick={changePasswordOption}>
                                                        Change Password
                                                    </p>
                                                    <p className="text-primary my-3 cur-pointer" onClick={signoutOption}>
                                                        Signout
                                                    </p>
                                                </Col>
                                                <Col md={4} sm={3}>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    )
                    :
                    (
                        <>
                            <Container className="mt-5">
                                <Row>
                                    <Col lg={12} md={12} sm={12} className='d-flex justify-content-center'>
                                        <div className='profilePic '>

                                            {/* <img for="file"
                                                ref={uploadedImage}
                                                className='profileEdit'
                                                src={profile}
                                            /> */}
                                            <label class="upload" for="file" >
                                                <img for="file"
                                                    ref={uploadedImage}
                                                    className='profileEdit'
                                                    // src={profile}
                                                    src={vendor?.profileURL && vendor?.profileURL !== "" ? vendor?.profileURL : AvatarImg}
                                                /></label>
                                            <input type="file" id='file'
                                                className="profile-pic"
                                                accept='image/*'
                                                multiple='false'
                                                onChange={handleImageUpload}
                                                ref={imageUploader} />
                                        </div>
                                    </Col>
                                    <div class="input-group d-flex justify-content-center head-content">
                                        {/* <label class="upload" for="file" ><AiOutlineUpload className='upload-icon' /></label>
                                        <input type="file" id='file'
                                            className="profile-pic"
                                            accept='image/*'
                                            multiple='false'
                                            onChange={handleImageUpload}
                                            ref={imageUploader} /> */}
                                        <div className='upload-icon cur-pointer text-center' onClick={uploadProfile}>
                                            {
                                                loading ? (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <AiOutlineUpload style={{ fontSize: "1.5rem" }} />
                                                )
                                            }
                                        </div>
                                        {/* <AiOutlineUpload className='upload-icon cur-pointer' onClick={uploadProfile} /> */}
                                    </div>
                                    <p className='d-flex justify-content-center text-secondary fs-6 mt-3'>Select an image file on your computer</p>
                                </Row>
                            </Container>
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col lg={12} className='d-flex justify-content-center'>
                                        <div style={{ width: 500 }} className="CardBorder">
                                            <form onSubmit={formik.handleSubmit}>
                                                <div class="mb-3">
                                                    <label className='label-text-Color form-label required' for="exampleInputText1" >Reg_id</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="reg_id"
                                                        name="reg_id"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.reg_id}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Reg id"
                                                    />
                                                    {formik.touched.reg_id && formik.errors.reg_id ? (
                                                        <div className="text-danger mt-2">{formik.errors.reg_id}</div>
                                                    ) : null}
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback> */}
                                                </div>

                                                <div class="mb-3">
                                                    <label for="exampleInputText1" class="form-label required">Business Name</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="business_name"
                                                        name="business_name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.business_name}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Business Name"
                                                    />

                                                    {formik.touched.business_name && formik.errors.business_name ? (
                                                        <div className="text-danger mt-2">{formik.errors.business_name}</div>
                                                    ) : null}
                                                </div>

                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label required">Email</label>
                                                    <input type="email"
                                                        disabled
                                                        class="form-control"
                                                        id="exampleInputEmail1"
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Phone Number</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="phone_no"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone_no}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Phone Number"
                                                    />
                                                    {formik.touched.phone_no && formik.errors.phone_no ? (
                                                        <div className="text-danger mt-2">{formik.errors.phone_no}</div>
                                                    ) : null}
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        {formik.errors.phone_no}
                                                    </Form.Control.Feedback> */}
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Landline Number</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="landline_no"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.landline_no}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Landline Number"
                                                    />
                                                    {formik.touched.landline_no && formik.errors.landline_no ? (
                                                        <div className="text-danger mt-2">{formik.errors.landline_no}</div>
                                                    ) : null}
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        {formik.errors.phone_no}
                                                    </Form.Control.Feedback> */}
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Account Number</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="account_no"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_no}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Account Number"
                                                    />
                                                    {formik.touched.account_no && formik.errors.account_no ? (
                                                        <div className="text-danger mt-2">{formik.errors.account_no}</div>
                                                    ) : null}
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Business Address</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="business_address"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.business_address}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Business Address"
                                                    />
                                                    {formik.touched.business_address && formik.errors.business_address ? (
                                                        <div className="text-danger mt-2">{formik.errors.business_address}</div>
                                                    ) : null}
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Business Address G-Map URL</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="business_address_map"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.business_address_map}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="State"
                                                    />
                                                    {formik.touched.business_address_map && formik.errors.business_address_map ? (
                                                        <div className="text-danger mt-2">{formik.errors.business_address_map}</div>
                                                    ) : null}

                                                </div>

                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">City</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="city"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.city}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="City"
                                                    />
                                                    {formik.touched.city && formik.errors.city ? (
                                                        <div className="text-danger mt-2">{formik.errors.city}</div>
                                                    ) : null}
                                                </div>

                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">State</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="State"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.State}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="State"
                                                    />
                                                    {formik.touched.State && formik.errors.State ? (
                                                        <div className="text-danger mt-2">{formik.errors.State}</div>
                                                    ) : null}
                                                    
                                                </div>    

                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Country</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="country"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.country}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Country"
                                                    />
                                                    {formik.touched.country && formik.errors.country ? (
                                                        <div className="text-danger mt-2">{formik.errors.country}</div>
                                                    ) : null}

                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputPhone1" class="form-label required">Zip Code</label>
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="zip_code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.zip_code}
                                                        onBlur={formik.handleBlur}
                                                        placeholder="Zip Code"
                                                    />
                                                    {formik.touched.zip_code && formik.errors.zip_code ? (
                                                        <div className="text-danger mt-2">{formik.errors.zip_code}</div>
                                                    ) : null}

                                                </div>
                                
                                                  <Row>
                                                    <Col className='text-center' lg={6} md={12} sm={12}>
                                                        <Button onClick={() => changeEditOption(false)} className='buttonSizing slaylewks-outline-btn'>Cancel</Button>
                                                    </Col>
                                                    <Col className='text-center' lg={6} md={12} sm={12}>
                                                        <Button type="sumbit" className='buttonSizing slaylewks-outline-btn'>Save</Button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
            }

            <div className='user-view-footer'>
            <Footer />
            </div>
        </div>
    );
}

export default BusinessProfilePage;




