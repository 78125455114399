const apiUrl = process.env.REACT_APP_API_URL;

export const CART_CONFIG = {
    
    createCartApi: `${apiUrl}/cart/create`,
    createReadApi: `${apiUrl}/cart/read`,
    deleteCartAPI: `${apiUrl}/cart/delete`,
    deleteCartManyAPI : `${apiUrl}/cart/deleteMany`,
    getAllCouponCodes : `${apiUrl}/cart/availablecoupons `,
    getApplyCouponCodes: `${apiUrl}/cart/applycoupons`,

};