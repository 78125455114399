import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createRPOrder, verifyRazorpayPayment, paymentFailed } from '../../redux/payment/actions';
import { clearAllCartDetails, clearCartAction } from '../../redux/cart/actions';
import { createOrderAction, addAppointmentDetails, slotBookingAction, clearUserAllDetailsAction } from "../../redux/user/actions";
import { toast } from "react-toastify";
import {
	Spinner
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const Payment = (props) => {
	
	const { selectedDate, selectedTime } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { couponCodeName } = useSelector((state) => state.getlocation);
	const paymentState = useSelector((state) => state.payment);
	const state = useSelector(state=>state)
	const { user } = useSelector((state) => state.auth);
	const { servicesCart, serviceProviderCart } = useSelector((state) => state.cart);
	const { appointmentDetails, userLocationDetails } = useSelector((state) => state.user);
	console.log(paymentState,"paymentState",state)
	const order = paymentState.order;
	order.user = user.username;
	const isPaymentLoading = paymentState.isPaymentLoading;
     
	let total_amount = order?.total_amount / 100;
	let discount_amount = order?.discount_amount / 100;

	async function createOrder() {
		
		console.log(!userLocationDetails._id)
		// if (!appointmentDetails._id) {
		// 	toast.warn('Please confirm service slot.');
		// 	return
		// }

		if (selectedDate === "" || selectedTime === "") {

			toast.warn('Please confirm service slot.');
			return
		}
		else {

			console.log("Yes => ", selectedDate + " " + selectedTime)
		}
		if (!userLocationDetails._id) {
			toast.warn('Please confirm service type and contact details.');
			return
		}

		let appointmentData = {
			serviceprovider: serviceProviderCart?._id,
			date: selectedDate,
			timeSlot: moment(selectedDate + " " + selectedTime, "YYYY-MM-DD hh:mm A").utc().format(),
			time: selectedTime,
			user: user?._id,
			services: servicesCart.map(item => item._id)
		}

		const appointmentRes = await dispatch(slotBookingAction(appointmentData));
		console.log("Appointment => ", appointmentRes)
		if (!appointmentRes?._id) {
			toast.warn("Slot booking failed");
			return
		}

		let orderDetails = {
			order_status: "pending",
			original_price: total_amount - discount_amount,
			discount: discount_amount,
			final_price: total_amount,
			customer_details: user?._id,
			service_provider: serviceProviderCart?._id,
			services: servicesCart?.map(item => item._id),
			appointment: appointmentRes?._id,
			customer_location_details: userLocationDetails?._id,
			coupon: order?.coupon_amount,
			gst_value: order?.gst_amount,
			coupon_code: couponCodeName,
		}
console.log(orderDetails,'orderDetails')
		const userOrder = await dispatch(createOrderAction(orderDetails))
		console.log("order details =>", userOrder)
		order._id = userOrder?.data?._id;
		order.orderId = userOrder?.data?.order_id;
		console.log(appointmentRes?.appointment_start_dateTime);
		const date = new Date(appointmentRes?.appointment_start_dateTime);
		console.log('date: ', date)
		const timestamp = date.getTime() / 1000;
		order.appointment_ts = timestamp;
		const razorPayOrder = await dispatch(createRPOrder(order));

		if (razorPayOrder) displayRazorpay(razorPayOrder);
	}

	async function displayRazorpay(razorPayOrder) {
		console.log("display razorpay");
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		


		if (!res) {
			// relpace with notification once complete
			toast.warn('Razorpay SDK failed to load. Are you online?')
			return
		}

		const options = {
			key: process.env.REACT_APP_SERVER === "production" ? process.env.REACT_APP_RAZORPAY_PROD_ID : process.env.REACT_APP_RAZORPAY_TEST_ID,
			currency: razorPayOrder.currency,
			amount: razorPayOrder.amount.toString(),
			order_id: razorPayOrder.id,
			name: 'Slaylewks',
			description: razorPayOrder.id,
			handler: async function (response) {
				response.order_id = razorPayOrder.id;
				const verification = await dispatch(verifyRazorpayPayment(response));
				if (verification) {
					let cartIds = servicesCart?.map(item => item?.cart_id)
					if(cartIds?.length > 0){
						await dispatch(clearCartAction({id : cartIds}))
					}

					await dispatch(clearAllCartDetails());
					await dispatch(clearUserAllDetailsAction());
					
					console.log('show success notification and route to a page');
					navigate(`/business/payment/${order._id}?type=payment-success`);

				} else {
					console.log('show failure notification and route to a page');
					navigate(`/business/payment/failed`);
				}
			},
			prefill: {
				name: userLocationDetails.alternate_username,
				email: userLocationDetails.alternate_emailID,
				contact: userLocationDetails.alternate_phone
			},
			modal: {
				escape: false,
				"ondismiss": async function () {
					toast.warning('Transaction was cancelled')
					await dispatch(paymentFailed({ orderId: order.orderId }))
				}
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open();
	}

	return (
		<div>
			<button class="continue-btn-login mt-4" disabled={isPaymentLoading} onClick={() => createOrder()}>
				<span>PAY</span>  <span>₹  {total_amount}</span> {isPaymentLoading && <Spinner animation="border" />}
			</button>
		</div>
	);
};

export default Payment;