import React, { useEffect, useState } from "react";
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import BusinessLogo from "../../../assets/images/business-logo.svg";
// import { BsGoogle } from 'react-icons/bs';
// import googleIcon from "../../../assets/images/icons/google.webp";
import { signup } from "../../../redux/auth/actions";
import { signUpValidation } from "../../../validations/yub_schemas/signUpValidation";
import BounceLoader from "react-spinners/BounceLoader";
// import RegisterContext from "../../../context/registerModalContext";
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import Login from "./Login";
import VerifyYourEmailCode from "./VerifyYourEmailCode";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyForgotPasswordCode from "./VerifyForgotPasswordEmail";


export const Register = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [signupLodading, setSignupLoading] = useState(false);
    const [signUpSuccess, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [registerHeader, setRegisterHeader] = useState("Create an Account");
    const [serviceRegister, setServiceRegister] = useState(false);
    const [registerType, setRegisterType] = useState("user");
    const [loginModal, setLoginModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailCode] = useState(false);
    const [forgetVerifyEmail, setForgetVerifyEmail] = useState(false)
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);

    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    useEffect(() => {
        if (vendorType && vendorType !== "") {
            switch (vendorType) {
                case "business":
                    setRegisterHeader("Create a Business account");
                    setRegisterType(vendorType);
                    setServiceRegister(true);
                    break;
                case "freelancer":
                    setRegisterHeader("Create a Freelancer account");
                    setRegisterType(vendorType);
                    setServiceRegister(true);
                    break;
                default:
                    setRegisterHeader("Create an Account");
                    setServiceRegister(false);
                    setRegisterType("user");
                    break;
            }
        }
        else {
            setRegisterHeader("Create an Account");
            setServiceRegister(false);
            setRegisterType("user");
        }
    }, [vendorType]);

    // if(serviceRegister){
    //     props.setRegisterModal(true);
    // }
    const formik = useFormik({
        initialValues: {
            username: '',
            phone_no: '',
            email: '',
            password: '',
            confirmPassword: '',
            reference_code: '',
            saloon_type: "single",
            termsAndPrivacy: false,
        },
        validationSchema: signUpValidation,
        onSubmit: (values) => {
            setSignupLoading(true);
            const data = {
                username: values.username,
                mobile_no: values.phone_no,
                email_id: values.email,
                password: values.password,
                referral_code: values.reference_code || null,
                roles: [registerType],
                saloon_type: values.saloon_type || "single",
                is_agree: values.termsAndPrivacy,
                is_active: true,
            };
            dispatch(signup(data))
                .then(() => {

                    setSuccess(true);
                    setSignupLoading(false);
                    formik.resetForm();
                    // props.onHide(true);
                    // props.setverifyemailcode();
                    setVerifyEmailCode(true);

                })
                .catch((error) => {
                    if (error?.response?.data?.email) {
                        setSignupLoading(false);
                    } else {
                        setSignupLoading(false);
                    }
                    setSuccess(false);
                    setSignupLoading(false);
                });
        },
    });

    const showLoginModal = () => {
        // props.onHide(true);
        // props.onLoginHide(true);
        setLoginModal(true);
    }

    const modalClose = () => {
        setServiceRegister(false);
        // props.onHide();
        // props.setRegisterModal(false);
        setRegisterModal(false);
    }

    const [eyeState, setEyeState] = useState(false)
    const [conformEyeState, setConformEyeState] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const Eyeview = () => {
        setEyeState(prevState => !prevState)
    }

    const ConformEyeview = () => {
        setConformEyeState(prevState => !prevState)
    }

    const TermsCondition = () => {
        navigate("/term-of-use")
    }

    const PrivacyPolicy = () => {
        navigate("/privacy-policy")
    }


    return (
        <div>
            <Header />
            <Container>

                {/* ====== Modals ===== */}

                <Login
                    show={loginModal}
                    onHide={() => {setLoginModal(false); if(signUpSuccess)navigate('/')}}
                    registerShow={registerModal}
                    onRegisterHide={() => setRegisterModal(true)}
                    forgotPasswordModal={forgotPasswordModal}
                    setforgotpasswordmodal={() => setForgotPasswordModal(true)}
                />

                <VerifyYourEmailCode
                    show={verifyEmailModal}
                    onHide={() => setVerifyEmailCode(false)}
                    resetPasswordModal={resetPasswordModal}
                    setResetPasswordModal={() => setResetPasswordModal(true)}
                    loginModal={loginModal}
                    setloginmodal={() => setLoginModal(true)}
                />
                <VerifyForgotPasswordCode
                    show={forgetVerifyEmail}
                    onHide={() => setForgetVerifyEmail(false)}
                    resetPasswordModal={resetPasswordModal}
                    setResetPasswordModal={() => setResetPasswordModal(true)}
                    loginModal={loginModal}
                    setloginmodal={() => setLoginModal(true)}
                />

                <ForgotPassword
                    show={forgotPasswordModal}
                    onHide={() => setForgotPasswordModal(false)}
                    verifyEmailModal={forgetVerifyEmail}
                    setverifyemailcode={() => setForgetVerifyEmail(true)}
                />

                <ResetPassword
                    show={resetPasswordModal}
                    onHide={() => setResetPasswordModal(false)}
                    loginModal={loginModal}
                    setloginmodal={() => setLoginModal(true)}
                />

                {/* ======== Modal End ======== */}

                <Row className="justify-content-md-center">
                    <Col xs xl="6" lg="8">
                        <div className="text-center">
                            {/* {vendorType === "user" ? (
                                <img className="logo"

                                    src={logo}
                                    alt="Slaylewks logo" />
                            ) : (
                                <img className="logo"
                                    src={BusinessLogo}
                                    alt="Slaylewks logo" />
                            )} */}


                            <h4 className="register-header">{registerHeader}</h4>
                            <div className="">
                                <h6><small className="text-muted">Start your journey!</small></h6>
                            </div>
                        </div>

                        <div>
                            <Form noValidate className="register-form " onSubmit={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <Form.Group className="mb-4" controlId="formBasicName">

                                        <Form.Control
                                            required
                                            placeholder="Name *"
                                            type="text"
                                            name="username"
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            isInvalid={!!formik.touched.username && !!formik.errors.username}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.username}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicPhone">

                                        <Form.Control
                                            placeholder="Phone Number *"
                                            type="text"
                                            name="phone_no"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone_no}
                                            isInvalid={!!formik.touched.phone_no && !!formik.errors.phone_no}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.phone_no}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-4 " controlId="formBasicEmail">

                                        <Form.Control
                                            placeholder="Email *"
                                            type="email"
                                            name="email"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            isInvalid={!!formik.touched.email && !!formik.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-4 position-relative" controlId="formBasicPassword">

                                        <Form.Control

                                            type={eyeState ? "text" : "password"}
                                            placeholder="Password"
                                            name="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            isInvalid={!!formik.touched.password && !!formik.errors.password}
                                        />
                                        <span className="eye-icon" onClick={Eyeview}>
                                            {eyeState ? <AiOutlineEye /> :
                                                <AiOutlineEyeInvisible />
                                            }
                                        </span>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-4 position-relative " controlId="formBasicConformPassword">

                                        <Form.Control

                                            type={conformEyeState ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            isInvalid={!!formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                                        />
                                        <span className="eye-icon" onClick={ConformEyeview}>
                                            {conformEyeState ? <AiOutlineEye /> :
                                                <AiOutlineEyeInvisible />
                                            }
                                        </span>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-0" controlId="formBasicReference">

                                        <Form.Control
                                            placeholder="Referral Code"
                                            type="text"
                                            name="reference_code"
                                            onChange={formik.handleChange}
                                            value={formik.values.reference_code}

                                        />
                                    </Form.Group>


                                    <div>
                                        <p className="sign-up-referral text-end" >Optional</p>
                                    </div>
                                    {
                                        vendorType && vendorType == "business" && (
                                            <Row className="saloon-type-container">
                                                <Col> <Form.Check type="radio"
                                                    aria-label="single"
                                                    name="saloon_type"
                                                    label='Single-salon'
                                                    value="single"
                                                    checked={formik.values.saloon_type === "single"}
                                                    onChange={() => formik.setFieldValue("saloon_type", "single")}
                                                /></Col>

                                                <Col> <Form.Check type="radio"
                                                    aria-label="multi"
                                                    name="saloon_type"
                                                    label='Multi-salons'
                                                    value="multiple"
                                                    checked={formik.values.saloon_type === "multiple"}
                                                    onChange={() => formik.setFieldValue("saloon_type", "multiple")}
                                                />
                                                </Col>
                                            </Row>
                                        )
                                    }



                                    <div className="text-center mt-4 d-grid gap-2">
                                        <Button className="btn register-button" type="submit" size="lg">
                                            Register
                                            <BounceLoader color={"FFFFFF"} loading={signupLodading} size={60} />
                                        </Button>
                                    </div>

                                    <Form.Group className="mb-4 d-flex">
                                        <Form.Check
                                            required
                                            name="termsAndPrivacy"
                                            label=""
                                            onChange={formik.handleChange}
                                            value={formik.values.termsAndPrivacy}
                                            isInvalid={!!formik.touched.termsAndPrivacy && !!formik.errors.termsAndPrivacy}
                                        />
                                        <Form.Label >
                                            <p className="agree-terms">I agree to Slaylewks <span onClick={TermsCondition} className="link-text-register">Terms and Conditions</span> and <span onClick={PrivacyPolicy} className="link-text-register">Privacy Policy</span></p>
                                            <ErrorMessage name="termsAndPrivacy" render={msg => <div><p className="agree-terms-error">{msg}</p></div>} />
                                        </Form.Label>

                                        <div className="mt-5 float-start">

                                        </div>
                                    </Form.Group>


                                    {/* <div className="text-center mt-3">
                                <h6>
                                    <small className="text-muted">
                                        Or Sign in with
                                    </small>
                                </h6>
                            </div> */}

                                    {/* <div className="text-center mt-3">
                                <img src={googleIcon} width="40" height="40" alt="Google icon" />
                            </div> */}
                                    <div className="text-center mt-4">
                                        <h6>
                                            <small className="text-muted">
                                                Already have an account?
                                            </small>
                                            &nbsp;
                                            <span>
                                                <a className="login-register" onClick={showLoginModal}>Log in</a>
                                            </span>
                                        </h6>
                                    </div>
                                </FormikProvider>
                            </Form>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Footer />
        </div>
    )
}
