import React, { useState } from "react";
import {
    Form,
    Modal,
    Button
} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
// import { BsGoogle } from 'react-icons/bs';
// import googleIcon from "../../../assets/images/icons/google.webp";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { verifyEmail, logoutFromLocalStorage, resendOtp } from '../../../redux/auth/actions';
import { verifyEmailCodeValidation } from "../../../validations/yub_schemas/verifyEmailCodeValidation";
import OtpTimer from 'otp-timer'

const VerifyForgotPasswordCode = (props) => {
    const [count, setCount] = useState(0);
    const [otperror, setOtperror] = useState(false)


    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { user, forgotPasswordSent } = useSelector((state) => state.auth);

    const formik = useFormik({
        initialValues: {
            verify_code: '',
        },
        validationSchema: verifyEmailCodeValidation,
        onSubmit: (values) => {
            const data = {
                userId: user._id || user.user_id,
                otp: values.verify_code,
            };

            // alert(JSON.stringify(data, null, 2));

            dispatch(verifyEmail(data))
                .then((res) => {
                    if(res !== false){
                        formik.resetForm();
                        props.onHide(true);
                        if (forgotPasswordSent) {
                            props.setResetPasswordModal(true)
                        }
                        else {
                            dispatch(logoutFromLocalStorage());
                            props.setloginmodal(true);
                        } 
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        },
    });

   

    const resendOTPviaMail = () => {

        let data = {
            // _id: user?._id ,
            email_id: user?.email_id,
        }

        if (user?._id) {
            data["_id"] = user?._id
        }

        if (user?.user_id) {
            data["_id"] = user?.user_id
        }


        dispatch(resendOtp(data))
            .then(res => {
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // const handleTimeClick=()=>{
    //     resendOTPviaMail()
    //     //desired function to be performed on clicking resend button
    //   }

    //   const handelClickCount =()=>{
    //     setCount(count +1)
    //   }

      const handleTimeClick =()=> {
        setCount(count +1);
        if(count < 3){
            resendOTPviaMail()
        }
        else{
            console.log("warning CLick")
        }
      }
      const handleOtpLimit = () => {
        setCount(count +1);
        if(count < 3){
            handleTimeClick();
        } else{
            setOtperror(true)
            console.log("warning CLick")
        }
      }

    return (
        <div className="verify-your-email-code">
            <Modal
                // {...props}
                show={props.show}
                onHide={props.onHide}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="">
                    <div className="text-center">
                        {/* <img className="logo"
                            src={logo}
                            width="182.46px"
                            height="40px"
                            alt="Slaylewks logo" /> */}

                        <h4 className="register-header">Forgot Password</h4>
                        <div className="m-1">
                            <h6><small className="text-muted">Please enter the verification code sent to your registered email address</small></h6>
                        </div>
                    </div>

                    <div>
                        <Form className="register-form" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>

                                <Form.Group className="mb-3 p-1" controlId="formBasicPassword">
                                    
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter verification code"
                                        name="verify_code"
                                        onChange={formik.handleChange}
                                        value={formik.values.verify_code}
                                        isInvalid={!!formik.touched.verify_code && !!formik.errors.verify_code}
                                    />
                                    {/* {formik.errors.verify_code ? <div className="m-2 text-danger">{formik.errors.verify_code}</div> : null} */}
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.verify_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div>
                                <p className="resend-otp text-end">
                                    {
                                        !otperror && (
                                            <span>   
                                            <OtpTimer seconds= {60}  resend={handleOtpLimit}  textColor={"#C78963"} buttonColor={"#C78963"} background={'none'}	 />
                                            </span>
                                        )
                                    }
                               
                                <span>
                                    {
                                        otperror && (
                                            <p className="text-danger">Only 3 times allows to get the Otp</p>
                                        )
                                    }
                                </span>
                                </p>
                                </div>
                                <div className="text-center mt-4 d-grid gap-2">
                                    <Button  className="register-button" type="submit" size="lg">
                                        Submit
                                    </Button>
                                </div>
                            </FormikProvider>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VerifyForgotPasswordCode;