import React, { useState, useEffect } from 'react';
import { Button, Form, Dropdown, Table } from "react-bootstrap";
import { getAllServices, deleteService, deleteManyServicesAction } from "../../../redux/dashboard/actions";
import { downloadServicesInfoTemplateAction, downloadServicesTemplateAction } from "../../../redux/business/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash, BsUpload, BsDownload } from "react-icons/bs";
import MultipleServiceUpload from './MultipleServiceUpload';
import download from 'downloadjs';
import fileDownload from 'js-file-download';
import { Row, Col, Modal } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import DownloadOne from "../../../assets/images/DownloadNote_one.svg"
import DownloadTwo from "../../../assets/images/DownloadNote_two.svg"
import { boolean } from 'yup';



function ServiceList() {

  const [search, setSearch] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [ageCategory, setAgeCategory] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [showAction, setShowAction] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectallCheck, setSelectallCheck] = useState(false)
  const [selectSort, setSelectSort] = useState("Z-A")
  const [serviceCategory, setServiceCategory] = useState("basic")


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [upload, setUpload] = useState(false);
  const uploadShow = () => setUpload(true);
  const uploadClose = () => setUpload(false);
   const [DeleteCoupons, setDeleteCoupons] = useState(false)
  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
    total: 0
  });


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { services } = useSelector((state) => state.dashboard);
  const { vendor } = useSelector((state) => state.business);


  const getAllServiceList = () => {
    const data = {
      id: vendor._id,
      page: 1,
      type: "basic",
    };
    dispatch(getAllServices(data))
        .then((res) => {
            let { servicesMain, total_count, resultsPerPage } = res;
            servicesMain.map((service) => {
              service.isChecked = false;
            });
            setState((prev) => ({
                ...prev,
                data: servicesMain,
                total: total_count / resultsPerPage
            }))
        })
}

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
    const data = {
      id: vendor._id,
      search: e.target.value,
      page: 1
    };

    dispatch(getAllServices(data))
      .then((res) => {
        const { servicesMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
  };

 
  const onServiceTypeSelect = async (e) => {
    
    await setServiceCategory(e.target.value);
    const data = {
      id: vendor._id,
      page: state.activePage,
      type: e.target.value,
    };
     
     await dispatch(getAllServices(data))
      .then((res) => {
        let { servicesMain, total_count, resultsPerPage } = res;
        servicesMain.map((service) => {
          service.isChecked = false;
        });
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
  }
  

  const onChangeAgeCategory = async (e) => {
    await setAgeCategory(e.target.value);
    const data = {
      id: vendor._id,
      page: state.activePage,
      gender_filter: e.target.value,
      type: serviceCategory,
    };
     
     await dispatch(getAllServices(data))
      .then((res) => {
        let { servicesMain, total_count, resultsPerPage } = res;
        servicesMain.map((service) => {
          service.isChecked = false;
        });
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
  }

  const onChangeFilter = async (e) => {
    await setFilterBy(e.target.value);
    const data = {
      id: vendor._id,
      page: state.activePage,
      service_filter: e.target.value,
      type: serviceCategory,
    };

    dispatch(getAllServices(data))
      .then((res) => {
        const { servicesMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
    // onServiceDataChange();
  }


  const onChangeSort = async () => {
    if(selectSort == "A-Z"){
      setSelectSort("Z-A")
     } else {
      setSelectSort("A-Z")
     }
    const data = {
      id: vendor._id,
      page: state.activePage,
      sortType: selectSort,
    };

    await dispatch(getAllServices(data))
      .then((res) => {
        const { servicesMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
  }
console.log(state, "dfbjdbfbdjfb")
  const onServiceDataChange = () => {

    const data = {
      id: vendor._id,
      page: state.activePage,
      type: serviceCategory,
    };

    if (search) data.search = search

    dispatch(getAllServices(data))
      .then((res) => {
        let { servicesMain, total_count, resultsPerPage } = res;
        servicesMain.map((service) => {
          service.isChecked = false;
        });
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })
  }
  
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber.selected);
    const data = {
      id: vendor._id,
      page: pageNumber?.selected + 1 
    };
    
    if (search) data.search = search
    dispatch(getAllServices(data))
      .then((res) => {
        let { servicesMain, total_count, resultsPerPage } = res;
        servicesMain.map((service) => {
          service.isChecked = false;
        });
        setSelectedItems([]);
        setSelectallCheck(false);

        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
      })

  };
  useEffect(() => {
    onServiceDataChange();
  }, [state.activePage]);


  const addNewServicePage = () => {
    navigate("/dashboard/inventory/create");
  }

  const onClickService = (e) => {
    const { checked, value } = e.target;
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      if (!selectedServices.includes(event.target.value)) {
        setSelectedServices(prevState => [...prevState, event.target.value])
      }
    } else {
      setSelectedServices(prevState => prevState.filter(id => id !== event.target.value));
    }
  }

  const handleEditChange = (id) => {
    navigate(`/dashboard/service/${id}`);
  }

  const handleDeleteChange = (id) => {
    const data = {
      id: id
    }
    dispatch(deleteService(data))
      .then((res) => {
        onServiceDataChange()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleMultipleUpload = () => {
    setShowUpload(true);
  }

  const serviceTemplateDownload = () => {
    dispatch(downloadServicesTemplateAction())
      .then((res) => {
        fileDownload(res, "template.xlsx");
      })
      .catch((err) => {
        console.log(err)
      })
      dispatch(downloadServicesInfoTemplateAction())
      .then((res) => {
        fileDownload(res, "template.pdf");
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const resetFilters = () => {
    setSearch("");
    const data = {
      id: vendor._id,
      page: state.activePage,
      
    };

    dispatch(getAllServices(data))
      .then((res) => {
        const { servicesMain, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: servicesMain,
          total: total_count / resultsPerPage
        }))
        setAgeCategory("Men")
        setFilterBy("Hair")
      })
  }

  const downloadFlie = () => {
    serviceTemplateDownload();
    handleClose();
  }

  const uploadFile = () => {
    handleMultipleUpload()
    uploadClose();
  }


const onCheckboxBtnClick = (item, e) => {
  item.isChecked = e.target.checked;

  if (item.isChecked) {
    selectedItems.push(item);
  } else {
    selectedItems.splice(item, 1);
  }
  let isAllSelect = state.data.every((item)=>{
    return item.isChecked == true;
  })
  setSelectedItems([...selectedItems]);
  if (isAllSelect){
    setSelectallCheck(true);
  } else{
    setSelectallCheck(false);
  }
};

const selectAllCheckBox =(event)=>{
  if (event.target.checked) {
    state.data.map((item)=>{
      selectedItems.push(item);
      return item.isChecked = true;
    });

    setSelectedItems([...selectedItems]);
    setSelectallCheck(true);
  }
   else {
    state.data.map((item)=>{
      selectedItems.splice(item, 1);
      return item.isChecked = false;
    });
    setSelectedItems([]);
    setSelectallCheck(false);

  }
  
}

const multiDelete =()=>{
  let selectedItemId = selectedItems.map((service) => {
    return service._id;
  })
  const data = {
     id: selectedItemId,
  }
  dispatch(deleteManyServicesAction(data));
  setDeleteCoupons(false);
  setSelectedItems([]);
  getAllServiceList();
  setSelectallCheck(false);

}
  

  return (
  <div className='container-xxl'>
      <div className="mt-4">
      <MultipleServiceUpload
        show={showUpload}
        onHide={() => setShowUpload(false)}
        serviceprovider={vendor._id}
        onServiceDataChange={() => onServiceDataChange()}
      />

      <div className="service-list-header-option">
        <Row>
          <Col md={6} sm={6} lg={6}>
            <h3>
            Inventory
            </h3>
          </Col>
          <Col md={6} sm={6} lg={6}>
            <div className='inventory-btn-list'>
                   <div className='mx-1'>
              <Button onClick={handleShow} variant="secondary" className="download-icon-btn"><span class="ionic-icon-btn"> <ion-icon name="download-outline"></ion-icon> </span></Button>{' '}
              </div>
              <div className='mx-1'>
              <Button onClick={()=>uploadFile()} variant="secondary" className="upload-icon-btn"><span class="ionic-icon-btn"> <ion-icon name="share-outline"></ion-icon> </span></Button>{' '}
              </div>
             
              <div className='mx-3'>
              <Button onClick={() => addNewServicePage()} variant="primary" className="add-service-btn"><span className="">Add</span> <span class="ionic-icon-btn"><ion-icon name="duplicate-outline"></ion-icon></span></Button>{' '}
              </div>
              <div className='mx-3'>
              <Button className="delete-btn-admin" onClick={()=>setDeleteCoupons(true)}> Delete <span class="ionic-icon-btn"><ion-icon name="trash-outline"></ion-icon></span></Button>{' '}
              </div>

            </div>
  

          </Col >
         
              <div className='excel-download'>
              <Modal show={show} onHide={handleClose} centered  size="lg">
               <Modal.Header closeButton></Modal.Header>
               <Modal.Title className='text-center p-3'>Important Note</Modal.Title>
            <Modal.Body>
            <div className='important-note-modal'>
                      <h6 className='note-text'>Hello User,</h6>
                      <h6 className='note-text'>Please make sure you read the instruction thoroughly before listing the service in Excel Template.</h6>
                      <h6 className='note-text'>Click on the below "Button" to download PDF ( Instructions ) and Excel Template ( to input the service details )</h6>
                     <p className='download-note'>* Click Allow to multiple download files</p>
                     <Row>
                      <Col>
                      <img src={DownloadOne} className="img-fluid" />
                      </Col>
                      <Col>
                      <img src={DownloadTwo} className="img-fluid"/>
                      </Col>
                     </Row>
                      <span className='continue-btn-outer'>
                      <Button className='info-continue-btn' variant="" onClick={() => downloadFlie()}>Download</Button>
                      <h6 className='info-note text-center mt-0'>If you have any further questions, please contact Slaylewks support team</h6>
                      </span>
    
                    </div>
            </Modal.Body>
             </Modal>
             </div>
       
         
         <div className='excel-upload'>
                <Modal show={upload} onHide={uploadClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Important Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                </Modal.Body>
                <Modal.Footer>
                  <Button className='download-btn-close' variant="" onClick={uploadClose}>
                    Go Back
                  </Button>
                  <Button className='download-btn' variant="" onClick={() => uploadFile()}>
                    Continue upload
                  </Button>
                </Modal.Footer>
                </Modal>
          </div>
        </Row>
      </div>
      <hr className='m-0 p-0' />
      <Modal show={DeleteCoupons} onHide={()=>setDeleteCoupons(false)} centered className='text-center'>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Service?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <p>Are you sure you want to delete the service?</p>

                 <Row className='mt-5 px-2'>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn-close w-100' variant="" onClick={()=>setDeleteCoupons(false)}>
                  Cancel                 
                   </Button>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn w-100' variant="" onClick={multiDelete}>
                  Delete                  
                  </Button>
                  </Col>
                 </Row>
               
                
                </Modal.Body>
                
      </Modal>


      <div className='mt-3'>
        <Row>
          <Col md={3}>
            <Row>
          <Col md={6}>
         <Form.Select aria-label="Default select example" className='inventory-selection-list'
         value={serviceCategory}
         onChange={onServiceTypeSelect}>
             <option value="basic">Basic</option>
              <option value="bridal">Bridal</option>
    </Form.Select>
         </Col>
          <Col md={6}>
          <Form.Select aria-label="Default select example"
          onChange={onChangeAgeCategory}
          value={ageCategory}
          className='inventory-selection-list'
          >         
             <option value="Men">Men</option>
              <option value="Women">Women</option>
              {
                serviceCategory !== "bridal" && (
                  <option value="Kids">Kids</option>
                )
              }
    </Form.Select>
          </Col>
          </Row>
          </Col>
          <Col md={6}>
          <div>
          <input type="text" class="form-control search-inventory" id="" value={search} placeholder="Search Service..." name="search" onInput={ onChangeSearch} />

          </div>
          </Col>
          <Col md={3}>
          <Row>
            <div className='fiters-section-inventory'>
            <Col md={4}>
                          <p className='mt-2' onClick={resetFilters}>
                            Reset Filters
                           </p>
                          </Col>
                          <Col md={4}>
                          <select class="form-select mx-2"
              aria-label="Default select example"
              name="filterService"
              onChange={onChangeFilter}
              value={filterBy}
            >
              <option value="Hair">Hair</option>
              <option value="Spa">Spa</option>
              <option value="Nails">Nails</option>
              <option value="Makeup">Makeup</option>
              <option value="Mehendi">Mehendi</option>
              <option value="Skin">Skin</option>
            </select>

                          </Col>
                          <Col md={4}>
                          <Button className='fiter-btn-coupon ms-4' onClick={onChangeSort}>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
          </div>
                         
          </Row>
          </Col>
         
        </Row>
      </div>
      <hr className='m-0 p-0'/>
      <div>


      <div class="mt-2">
      <Table responsive>
      <thead className='admin-coupon-list-head'>
            <tr>
              <th className='admin-tabel-heading'><input class="form-check-input" type="checkbox" checked={selectallCheck} onClick={(e)=>selectAllCheckBox(e)} /></th>
              <th className='admin-tabel-heading'>
                Service Title
              </th>
              <th className='admin-tabel-heading'>
                Category
              </th>
              <th className='admin-tabel-heading'>
                Gender
              </th>
              <th className='admin-tabel-heading'>
                Price
              </th>
              <th className='admin-tabel-heading'>
                Discount
              </th>
              <th className='admin-tabel-heading'>
                Status
              </th>
              <th className='admin-tabel-heading'>
              </th>
            </tr>
          </thead>
          <tbody className='admin-tabel-data'>
            {
              state.data && state.data.length > 0 ? (
                state.data.map((item, index) => {
                  return (
                    <tr key={index}>
                       <td scope="row"><input class="form-check-input" type="checkbox" value={item?._id} checked={item.isChecked} onChange={(e)=>onCheckboxBtnClick(item, e)}/></td>
                      <td>{item?.service_name}</td>
                      <td>{item?.service_category?.[0]?.servicecategory_name}</td>
                      <td>{item?.gender?.[0]?.gender_name}</td>
                      <td>{item?.service_price?.["$numberDecimal"]}</td>
                      <td>{item.service_discount?.["$numberDecimal"] !== "" ? item.service_discount?.["$numberDecimal"] + " %" : "0 %"}</td>
                      {
                                                            item?.is_active == true ? (
                                                                <td className='status-active'>Active</td>
                                                                ) :
                                                                (
                                                                    <td>In active</td>
                                                                )
                                                        }
                      {/* gender_name */}
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle>
                            <BsThreeDotsVertical />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleEditChange(item._id)}>Edit</Dropdown.Item>
                            <hr className='m-0 p-0' />
                            <Dropdown.Item  onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                      </td>
                    </tr>
                  );
                })
              )
                :
                (
                  <tr>
                    <td>
                      No records found
                    </td>
                  </tr>
                )
            }
          </tbody>
        </Table>
      </div>
      </div>
   {
    state?.total > 1 && (
      <ReactPaginate
      previousLabel="<<"
      nextLabel=">>"
      breakLabel="..."
      pageCount={state.total}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      onPageChange={handlePageChange}
      containerClassName={'pagination justify-content-center pagination-box'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link '}
      previousClassName={'page-item '}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item '}
      nextLinkClassName={'page-link '}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      activeClassName={'active'}
      forcePage={page}
     />
    )
   }
  </div>
  </div>  
  )
}

export default ServiceList