import React, { useState, useEffect } from 'react';
import { getAllStaffs } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs"
import { Button } from "react-bootstrap";
import ReactPaginate from 'react-paginate';



function StaffList() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [ageCategory, setAgeCategory] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [sortBy, setSortBy] = useState("");

  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
    total: 1
  });

  const { staffs } = useSelector((state) => state.dashboard);

  useEffect(() => {
    const data = {
      page: state.activePage
    };
    dispatch(getAllStaffs(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }, [state.activePage]);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    // const { value, name } = e.target;
  };

  const onChangeAgeCategory = (type) => {
    setAgeCategory(type);
  }

  const onChangeFilter = (e) => {
    // const [name, value] = e.target;
    // console.log(name + " => " + value);
    setFilterBy(e.target.value);
  }

  const onChangeSort = (e) => {
    setSortBy(e.target.value);
  }

  const addNewServicePage = () => {
    navigate("/dashboard/staff/create");
  }

  const handlePageChange = (pageNumber) => {
    setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
    const data = {
      page: pageNumber?.selected + 1
    };
    dispatch(getAllStaffs(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })

  };

  return (
    <div className="order-list">
      {/* <div className="d-flex flex-row flew-wrap service-list-header-option">
        <div className="p-2">
          <h4>
            Staffs
          </h4>
        </div>

        <div className="ms-3 p-2 w-50">
          <input type="text" class="form-control" id="" placeholder="Search" name="search" />
        </div>

        <div className="ms-auto p-2">
          <select class="form-select" aria-label="Default select example">
            <option selected>Filter by</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="ms-2 p-2">
          <select class="form-select" aria-label="Default select example">
            <option selected>Sort by</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

      </div> */}

      <div className="d-flex flex-row flew-wrap service-list-header-option">
        <div className="me-auto">
          <h4>
            Staffs
          </h4>
        </div>
        <div className="ms-4">
          <Button onClick={() => addNewServicePage()} variant="primary" className="p-2 px-5"> <BsPlusCircle /> Add</Button>{' '}
        </div>
      </div>
      <div className="service-operation-section d-flex flex-row flex-wrap mt-5">
        <div className=" p-2 w-50">
          <input type="text" class="form-control" id="" placeholder="Search" name="search" onInput={onChangeSearch} />
        </div>
        <div className="ms-auto p-2">
          <select class="form-select"
            aria-label="Default select example"
            name="filterService"
            onChange={onChangeFilter}
            value={filterBy}
          >
            <option selected>Filter by</option>
            <option value="hair">Hair</option>
            <option value="spa">Spa</option>
            <option value="nail">Nail</option>
          </select>
        </div>
        <div className="ms-2 p-2">
          <select class="form-select" aria-label="Default select example" name="sortService" onChange={onChangeSort}>
            <option selected>Sort by</option>
            <option value="asc">Asc</option>
            <option value="desc">Desc</option>
          </select>
        </div>
      </div>
      <section>
        <div class="container mt-5 px-2">
          <div class="table-responsive slaylewks-table">
            <table class="table table-responsive table-borderless">
              <thead>
                <tr>
                  {/* <th scope="col"><input class="form-check-input" type="checkbox" /></th> */}
                  <th scope="col">
                    <h4>Name</h4>
                  </th>
                  <th scope="col">
                    <h4>Stylish</h4>
                  </th>
                  <th scope="col">
                    <h4>Services</h4>
                  </th>
                  {/* <th scope="col">
                    <h4>Time</h4>
                  </th> */}
                  <th scope="col">
                    <h4>Status</h4>
                  </th>
                  <th scope="col">
                    <h4>Phone number</h4>
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  staffs && staffs.length > 0 ?
                    (
                      staffs.map((item, index) => {
                        return (
                          <tr
                            key={index}
                          >
                            {/* <th scope="row"><input class="form-check-input" type="checkbox" /></th> */}
                            <td class="sss">{item.stylist_name}</td>
                            <td>{item.stylist_category}</td>
                            <td>{item?.services?.length || 0}</td>
                            {/* <td></td> */}
                            <td class="active-status">{item.is_active ? "Active" : "Inactive"}
                            </td>
                            <td>
                              {item.mobile_no}
                            </td>
                            <td>
                              <BsThreeDotsVertical />
                            </td>

                          </tr>
                        )
                      })
                    )
                    :
                    (
                      <div className="mt-5 justify-content-center">
                        No staffs found
                      </div>
                    )
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div>
        {/* <Pagination className="px-4">

          {(() => {
            const rows = [];
            for (let i = 0; i < state.total; i++) {
              rows.push(<Pagination.Item
                onClick={() => handlePageChange(i + 1)}
                key={i + 1}
                active={i + 1 === state.activePage}
              >
                {i + 1}
              </Pagination.Item>);
            }
            return rows;
          })()}
        </Pagination> */}

        <ReactPaginate 
        
        previousLabel="<<"
        nextLabel=">>"
        breakLabel="..."
        pageCount={state.total}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'pagination justify-content-center pagination-box'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
       />
      </div>
    </div>
  )
}

export default StaffList