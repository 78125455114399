import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "../../components/elements/header/Header";
import { useFormik, FormikProvider} from 'formik';
import { Form, Button, Container, Row, Col, InputGroup} from "react-bootstrap";
import { businessInformationValidation } from "../../validations/yub_schemas/businessInformationValidation";
import BusinessFormImg from "../../assets/images/vendor_icons/Form.svg";
import MultiStepFormContext from "./MultiStepFormContext";
import { useSelector, useDispatch } from 'react-redux';
import { resendOtp} from "../../redux/auth/services";
import { updateUserActionForEmail, verifyEmail } from "../../redux/auth/actions";
import { verifyProfileEmailAction } from "../../redux/auth/actions";
import { useNavigate } from 'react-router-dom';
import { verifyProfilecodeValidation } from "../../validations/yub_schemas/verifyProfileValidations";
import OtpTimer from 'otp-timer'
import LocationSearch from "../../components/locationSearch/locationSearch";



const BusinessStep1 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { step1Details, setStep1Details, next, prev } = useContext(MultiStepFormContext);
    const { user, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
    const [optInput, setOtpInput] = useState(false);
    const [count, setCount] = useState(0);
    const [otperror, setOtperror] = useState(false)
    const cityRef = useRef();
    const cityAutoCompleteRef = useRef();
    const areaRef = useRef();
    const areaAutoCompleteRef = useRef();


    const refreshPage = () => {
        window.location.reload(false);
    }

    const resendOTPviaMail = () => {

        const data = {
            _id: user?._id,
            email_id: user?.email_id,
        }
        dispatch(resendOtp(data))
            .then(res => {
                console.log("OTP has been resent")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    
    const requestVerifyEmail = () => {
        setOtpInput(true)
        const data = {
            email_id: user?.email_id,
        }
        dispatch(verifyProfileEmailAction(data))
            .then(res => {
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleOtpLimit = () => {
        setCount(count +1);
        if(count < 3){
            handleTimeClick();
        } else{
            setOtperror(true)
            console.log("warning CLick")
        }
      }

      const handleTimeClick =()=> {
        setCount(count +1);
        if(count < 3){
            resendOTPviaMail()
        }
        else{
            console.log("warning CLick")
        }
      }
      const setupAutoCompleteListeners = (
        ref,
        autoCompleteRef,
        field,
        placeType
      ) => {
        const searchOptions = {
          // bounds: omanBounds, // Replace omanBounds with the desired bounds for Oman
          types: [placeType],
          componentRestrictions: { country: "om", }, // Update country code to 'om' for Oman
        };
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          ref.current,
          searchOptions
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
          const place = await autoCompleteRef.current.getPlace();
          console.log(place?.formatted_address);
          formik.setFieldValue(field, place?.name);
        });
      };

      useEffect(() => {
        setupAutoCompleteListeners(
          cityRef,
          cityAutoCompleteRef,
          "city",
          "locality"
        );
        setupAutoCompleteListeners(
          areaRef,
          areaAutoCompleteRef,
          "area",
          "address"
        );
        window.scrollTo(0, 0);
      }, []);

    

    const formik = useFormik({
        initialValues: {
            business_name: step1Details.business_name || "",
            business_address: step1Details.business_address || "",
            area: step1Details.area || "",
            city: step1Details.city || "",
            state: step1Details.state || "",
            zipcode: step1Details.zipcode || "",
            country: step1Details.country || "OMAN",
            business_address_url: step1Details.business_address_url || "",
            business_email_id: user?.email_id || "",
            email_verification_code: step1Details.email_verification_code || "",
            business_mobile_no: user?.mobile_no || "",
            secondary_mobile_no: step1Details.email_verification_code || "",
            mobile_verification_code: step1Details.mobile_verification_code || "",
            stdcode: step1Details.stdcode || "",
            landline_no: step1Details.landline_no || "",

        },
        validationSchema: businessInformationValidation,
        onSubmit: (values) => {
            let data = {
                ...values,
                business_email: values.business_email_id,
                business_contactNo: values.business_mobile_no || "",
                locationdetails: {
                    address: values.business_address,
                    area: values.area,
                    city: values.city,
                    zipcode: values.zipcode,
                    state: values.state,
                    country: values.country
                }
            }
            setStep1Details(data);
            next();
        },
    });

    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");

    const FindMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              setLat(position.coords.latitude);
              setLng(position.coords.longitude);
      
            }, () => {
               
            });
          } else {
               
          }
          FindLocationLink()
    }

    const FindLocationLink = ()=> {
        let latDes = lat;
        let longDes = lng;
        let url = "http://maps.google.com/?q=";
        let origin = "origin=" + latDes + "," + longDes;
        let destination = latDes + "," + longDes;
        let newUrl = new URL(url + destination);
        let locationLink = newUrl.href
       formik.setFieldValue("business_address_url", locationLink)
    }

    const getVerify = ()=>{
        const data = {
            id: user?._id,
            username: user?.username,
            mobile_no: user?.phone_no,
            email_id: user?.email,
            profileURL: user?.profileURL
    
        };
        dispatch(updateUserActionForEmail(data)).then((res) => {
        });
    
    }

    const formikVerify = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: verifyProfilecodeValidation,
        onSubmit: (values) => {
            console.log(values);
            const data = {
                userId: user?._id,
                otp: values.otp
            }
            dispatch(verifyEmail(data))
                .then(res => {
                    formikVerify.handleReset();
                    setOtpInput(false)
                    // refreshPage()
                    getVerify()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    })

   

  
    return (
        <div className="businessStep1 mt-5">
            <Container>
                <Row>
                    <Col md={12} sm={12}>
                        <Form noValidate className="business-register-form text-start" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                <div className="step-one-title">
                                    <h4>Business details</h4>
                                    <p>Name, address and location!</p>
                                </div>
                                <Form.Group className="mb-3 p-2" controlId="businessName">
                                    <Form.Label className="required">Business Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Business name"
                                        name="business_name"
                                        onChange={formik.handleChange}
                                        value={formik.values.business_name}
                                        isInvalid={!!formik.touched.business_name && !!formik.errors.business_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.business_name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                <Col>
                                        <Form.Group className="mb-3 p-2" controlId="state">
                                            <Form.Label>Address line *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Address line"
                                                name="business_address"
                                                onChange={formik.handleChange}
                                                value={formik.values.business_address}
                                                isInvalid={!!formik.touched.business_address && !!formik.errors.business_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.business_address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="city">
                                            <Form.Label>Area *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Area"
                                                ref={areaRef}
                                                name="area"
                                                onChange={formik.handleChange}
                                                value={formik.values.area}
                                                isInvalid={!!formik.touched.area && !!formik.errors.area}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.area}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                   
                                </Row>

                                {/* <Form.Group className="mb-3 p-2" controlId="businessAddress">
                                    <Form.Label className="required">Business Address </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Business Address"
                                        name="business_address"
                                        onChange={formik.handleChange}
                                        value={formik.values.business_address}
                                        isInvalid={!!formik.touched.business_address && !!formik.errors.business_address}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.business_address}
                                    </Form.Control.Feedback>
                                </Form.Group> */}

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="city">
                                            <Form.Label className="required">City</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="City"
                                                ref={cityRef}
                                                name="city"
                                                onChange={formik.handleChange}
                                                value={formik.values.city}
                                                isInvalid={!!formik.touched.city && !!formik.errors.city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col>
                                        <Form.Group className="mb-3 p-2" controlId="state">
                                            <Form.Label className="required">State</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="State"
                                                name="state"
                                                onChange={formik.handleChange}
                                                value={formik.values.state}
                                                isInvalid={!!formik.touched.state && !!formik.errors.state}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.state}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="zipcode">
                                            <Form.Label className="required">Postal Code</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Postal code"
                                                name="zipcode"
                                                onChange={formik.handleChange}
                                                value={formik.values.zipcode}
                                                isInvalid={!!formik.touched.zipcode && !!formik.errors.zipcode}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.zipcode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3 p-2" controlId="country">
                                            <Form.Label className="required">Country</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Country"
                                                name="country"                                                
                                                onChange={formik.handleChange}
                                                value={formik.values.country}
                                                isInvalid={!!formik.touched.country && !!formik.errors.country}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Form.Group className="mb-3 p-2" controlId="state">
                                    <Form.Label >Business Address URL Google Business (not mandatory) </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Ex: https://www.slaylewks.com"
                                        name="business_address_url"
                                        onChange={formik.handleChange}
                                        value={formik.values.business_address_url}
                                        isInvalid={!!formik.touched.business_address_url && !!formik.errors.business_address_url}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.business_address_url}
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        This will help your customers to locate your store <span className="find-my-location" onClick={FindMyLocation}>find my location</span>
                                    </Form.Text>

                                </Form.Group>

                                <div className="mt-5 mb-3 step-one-title">
                                    <h4>Contact number at Business</h4>
                                    <p>Your customers will call on this number for general enquiries</p>
                                </div>


                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3 p-2" controlId="emailId">
                                        <Form.Label>Email Id</Form.Label>
                                        <div class="mb-3 email-component">

                                                <input type="email"
                                                    disabled
                                                    class="form-control"
                                                    id="exampleInputEmail1"
                                                    name="business_email_id"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.business_email_id}
                                                    onBlur={formik.handleBlur}

                                                />
                                                <p>
                                                {
                                                        user?.email_verified && user?.email_verified ? (
                                                            <span> verified</span>
                                                        )
                                                            : (
                                                                <span onClick={requestVerifyEmail}> Get verify</span>
                                                            )
                                                    }
                                                </p>

                                                </div>
                                            {/* <Form.Control
                                                required
                                                disabled={true}
                                                type="email"
                                                placeholder="Email Id"
                                                name="business_email_id"
                                                onChange={formik.handleChange}
                                                value={formik.values.business_email_id}
                                                isInvalid={!!formik.touched.business_email_id && !!formik.errors.business_email_id}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.business_email_id}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                       
                                                <div>
                                                {
                                                optInput && (
                                                    <Row className="verify-email-component">
                                                        <Col md={12}>
                                                            <Form onSubmit={formikVerify.handleSubmit}>
                                                                <FormikProvider value={formikVerify}>
                                                                    <InputGroup className="mb-3 p-2" controlId="formBasicEmail">
                                                                        <Form.Control
                                                                            className="input-border"
                                                                            type="text"
                                                                            placeholder="Enter otp"
                                                                            name="otp"
                                                                            onChange={formikVerify.handleChange}
                                                                            value={formikVerify.values.otp}
                                                                            isInvalid={!!formikVerify.touched.otp && !!formikVerify.errors.otp}
                                                                        />

                                                                        <Button className="verify-email" type="submit" onClick={formikVerify.handleSubmit}>
                                                                            Verify
                                                                        </Button>

                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formikVerify.errors.otp}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                    <p className="resend-otp text-end">
                                    {
                                        !otperror && (
                                            <span>   
                                            <OtpTimer seconds= {60}  resend={handleOtpLimit}  textColor={"#C78963"} buttonColor={"#C78963"} background={'none'}	 />
                                            </span>
                                        )
                                    }
                               
                                <span>
                                    {
                                        otperror && (
                                            <p className="text-danger">Try after sometimes!</p>
                                        )
                                    }
                                </span>
                                </p>
                                                                    

                                                                </FormikProvider>
                                                            </Form>


                                                        </Col>

                                                    </Row>
                                                )
                                            }
                                            </div>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3 p-2" controlId="mobileNo">
                                            <Form.Label>Mobile Number at Business </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile No"
                                                name="mobile_no"
                                                onChange={formik.handleChange}
                                                value={formik.values.business_mobile_no}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </Col>
                             
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3 p-2" controlId="mobileNo">
                                            <Form.Label>Secondary Mobile No </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile Number"
                                                name="secondary_mobile_no"
                                                onChange={formik.handleChange}
                                                value={formik.values.secondary_mobile_no}
                                                isInvalid={!!formik.touched.secondary_mobile_no && !!formik.errors.secondary_mobile_no}

                                            />
                                            {/* <Form.Text className="text-muted mt-2">
                                                or want to share landline number
                                            </Form.Text> */}
                                            <Form.Control.Feedback type="invalid" className="mt-2">
                                        {formik.errors.secondary_mobile_no}
                                    </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col md={6}> 
                                        <Row className="mt-2 px-2" id="system-view">
                                            {/* <Col md={3}>
                                            <Form.Label>STD Code </Form.Label>
                                            </Col> */}
                                            <Col md={9}>
                                            <Form.Label>Landline Number at Business  </Form.Label>

                                            </Col>
                                        </Row>
                                         <Row className="px-2 py-2 secondary-mobile-outer" id="system-view">

                                    {/* <Col md={2} className="p-0">
                                        <Form.Group className="" controlId="stdCode">
                                            <Form.Control
                                            className="no-border-style p-0"
                                                required
                                                type="text"
                                                placeholder="STD Code"
                                                name="stdcode"
                                                onChange={formik.handleChange}
                                                value={formik.values.stdcode}
                                            />
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col md={1} className="std-divider"><p>|</p></Col> */}
                                    <Col md={9} className="p-0">
                                        <Form.Group className="" controlId="landlineNo">
                                            <Form.Control
                                                required
                                                className="no-border-style"
                                                type="text"
                                                placeholder="Landline No"
                                                name="landline_no"
                                                onChange={formik.handleChange}
                                                value={formik.values.landline_no}
                                                isInvalid={!!formik.touched.landline_no && !!formik.errors.landline_no}

                                            />
                                             <Form.Control.Feedback type="invalid" className="mt-2">
                                        {formik.errors.landline_no}
                                    </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                         </Row>
                                    </Col>
                                </Row>

                                
                                
                                <div className="text-center my-5 d-grid">
                                    <Button className="next-btn-businessSteps" variant="" type="submit" size="lg">
                                        Next
                                    </Button>
                                </div>
                            </FormikProvider>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BusinessStep1;

