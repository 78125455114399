import * as TYPES from './types.js';

const initialState = {
    locationDetails:"",
    couponCodeName: "",
};

const locationReducer = (state = initialState, action) => {
    
    const { type, payload} = action;
    switch (type) {
        
        case TYPES.LOCATION_DETAILS:
            return{
                ...state,
                locationDetails: payload.location,
            };
        case TYPES.COUPON_DETAILS:
            return{
                ...state,
                couponCodeName: payload,
            }
            default: return state;
        }
       
    }

export default locationReducer;
