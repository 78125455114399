import React, { useState, useEffect } from 'react';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Container,
    Row,
    Col
} from "react-bootstrap";
import ServiceImage from "../../../assets/images/vendor_icons/service-register.svg";
import { createService, getOneService, updateService } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { serviceInformationValidation } from "../../../validations/yub_schemas/serviceInformationValidation";
import DashboardHeader from '../DashboardHeader';
import { businessSidebar } from "../../../configs";
import { Link, useNavigate, useParams } from "react-router-dom";
import BusinessSidebar from "../../../components/elements/siderbar";
import {IoIosArrowBack } from "react-icons/io";
import "./radio.css";


function ServiceAddEdit() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [serviceValue, setServiceValue] = useState({});


    const { vendor } = useSelector((state) => state.business);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getServiceDetails();
        }

    }, [id]);

    const onBack = () => {
        navigate(-1);
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: serviceValue?._id || "",
            type: "basic",
            serviceProvider: vendor._id,
            service_name: serviceValue?.service_name || "",
            service_description: serviceValue?.service_description || "",
            service_price: serviceValue?.service_price?.$numberDecimal || "",
            stylist_category: "",
            duration: serviceValue?.duration?.$numberDecimal || "",
            service_discount: serviceValue?.service_discount?.$numberDecimal,
            is_active: serviceValue?.is_active?.toString() || "true",
            gender: serviceValue?.gender?.[0]?.gender_name || "Men",
            service_category: serviceValue?.service_category?.[0]?.servicecategory_name || "Hair",
        },
        validationSchema: serviceInformationValidation,
        onSubmit: (values) => {

            if (id) {

                dispatch(updateService(values))
                    .then(() => {
                        formik.resetForm();
                        navigate("/dashboard/inventory");
                    })
                    .catch((error) => {
                        console.log("Error => ", error)
                    });
            }
            else {
                dispatch(createService(values))
                    .then(() => {
                        formik.resetForm();
                        navigate("/dashboard/inventory");
                    })
                    .catch((error) => {
                        console.log("Error => ", error)
                    });
            }

        },
    });


    const getServiceDetails = () => {
        dispatch(getOneService({ id: id }))
            .then((res) => {
                setServiceValue(res);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const goBack =() =>{
        navigate('/dashboard/inventory')
    }
    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <Row>
                        <Col className='p-0' md={2}>
                        <div className="sidebar-content">
                        <BusinessSidebar />

                       
                    </div>
                   
                        </Col>
                        <Col md={10}>
                        <div className="main-content">
                        <div className="service-details-update mt-4 mx-4">
                            <Row>
                                <Col md={12}>
                                <div className="service-header-view">
                                    <div className='row'>
                                        <Col md={10} sm={8} xs={8}>
                                        <p>Service</p>
                                        <h6>
                                    The most important feature in the service editing section is the service adding part . When adding service here, do not ignore
                                     to fill in all the required fields completely and follow the service adding rules.
                                    </h6>
                                    </Col>
                                    <Col md={2} sm={4} xs={4}>
                                        <h5 className='back-text' onClick={goBack}>
                                                 <span><IoIosArrowBack size={16} /></span>Back</h5>
                                    </Col>
                                       
                                    </div>
                                    
                                            </div>

                                         <hr />   
                                
                                    <Form noValidate className="service-register-form text-start" onSubmit={formik.handleSubmit}>
                                        <FormikProvider value={formik}>
                                           
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4" controlId="service_name">
                                                    <Row className='mb-3 mt-3'>
                                                   <Col md={4}>
                                                    <Row id='service-selector-radio'>
                                                    <Form.Label className="required">Service type</Form.Label>

                                                        <Col md={6} xs={6}>
                                                        <Form.Check
                                                        className="mb-2"
                                                        type='radio'
                                                        id='shop_only'
                                                        name="type"
                                                        label="Basic Service"
                                                        value="basic"
                                                        checked={formik.values.type === "basic"}
                                                        onChange={() => formik.setFieldValue("type", "basic")}
                                                        isInvalid={!!formik.touched.type && !!formik.errors.type}
      
    
                                                    />
                                                        </Col>
                                                        <Col md={6} xs={6}>
                                                        <Form.Check
                                                        type='radio'
                                                        id='shop_and_home'
                                                        name="type"
                                                        label="Bridal"
                                                        value="bridal"
                                                        className='bridal-check'
                                                        checked={formik.values.type === "bridal"}
                                                        onChange={() => formik.setFieldValue("type", "bridal")}
                                                        isInvalid={!!formik.touched.type && !!formik.errors.type}
                                                    />
                                                        </Col>
                                                    </Row>
                                                 
                                                   
                                                   </Col>
                                                   <Col md={8}>

                                                   </Col>
                                                    </Row>

                                                        <Form.Label className="required">Title</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Service Title"
                                                            name="service_name"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.service_name}
                                                            isInvalid={!!formik.touched.service_name && !!formik.errors.service_name}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.service_name}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>

                                                {/* <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="stylist_category">
                                                        <Form.Label className="required">Stylist</Form.Label>
                                                        <Form.Select
                                                            placeholder="stylish category"
                                                            name="stylist_category"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.stylist_category}
                                                            isInvalid={!!formik.touched.stylist_category && !!formik.errors.stylist_category}
                                                        >
                                                            <option value=""></option>
                                                            <option value="premium">Premium</option>
                                                            <option value="professional">Professional</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.stylist_category}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col> */}
                                                {/* <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="gender">
                                                        <Form.Label className="required">Customer Category</Form.Label>
                                                        <Form.Select
                                                            name="gender"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.gender}
                                                            isInvalid={!!formik.touched.gender && !!formik.errors.gender}
                                                        >
                                                            <option value="Men">Men</option>
                                                            <option value="Women">Women</option>
                                                            <option value="Kids">Kids</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.gender}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col> */}
                                            </Row>

                                            <Row>

                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="gender">
                                                        <Form.Label className="required">Customer Category</Form.Label>
                                                        <Form.Select
                                                            name="gender"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.gender}
                                                            isInvalid={!!formik.touched.gender && !!formik.errors.gender}
                                                        >
                                                            <option value="Men">Men</option>
                                                            <option value="Women">Women</option>
                                                            {
                                                                formik.values.type === "basic" && (
                                                                    <option value="Kids">Kids</option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.gender}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="service_category">
                                                        <Form.Label className="required">Service Category</Form.Label>
                                                        <Form.Select
                                                            name="service_category"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.service_category}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        >
                                                            <option value="Hair">Hair</option>
                                                            <option value="Nails">Nails</option>
                                                            <option value="Spa">Spa</option>
                                                            <option value="Skin">Skin</option>
                                                            <option value="Mehendi">Mehendi</option>
                                                            <option value="Makeup">Makeup</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.service_category}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="amount">
                                                        <Form.Label className='required' >Duration </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Duration in mins"
                                                            name="duration"
                                                            // onChange={formik.handleChange}
                                                            onChange={(event) => {
                                                                const { value } = event.target;
                                                                if (/^\d*$/.test(value)) { // allow only numbers
                                                                    formik.handleChange(event);
                                                                }
                                                            }}
                                                            value={formik.values.duration}
                                                            isInvalid={!!formik.touched.duration && !!formik.errors.duration}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.duration}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="amount">
                                                        <Form.Label className="required">Price Amount</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Service price"
                                                            name="service_price"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.service_price}
                                                            isInvalid={!!formik.touched.service_price && !!formik.errors.service_price}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.service_price}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="discount">
                                                        <Form.Label>Discount in %</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Discount in %"
                                                            name="service_discount"
                                                            // onChange={formik.handleChange}
                                                            onChange={(event) => {
                                                                const { value } = event.target;
                                                                if (/^\d*$/.test(value)) { // allow only numbers
                                                                    formik.handleChange(event);
                                                                }
                                                            }}
                                                            value={formik.values.service_discount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.service_discount}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-4" controlId="is_active">
                                                        <Form.Label className="required">Status</Form.Label>
                                                        <Form.Select
                                                            name="is_active"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.is_active}
                                                            isInvalid={!!formik.touched.is_active && !!formik.errors.is_active}
                                                        >
                                                            <option value="true">Active</option>
                                                            <option value="false">Disable</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.is_active}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-4" controlId="serviceDescription">
                                                        <Form.Label className='required'>Description</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={5}
                                                            placeholder="Service Description"
                                                            name="service_description"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.service_description}
                                                            isInvalid={!!formik.touched.service_description && !!formik.errors.service_description}
                                                        />
                                                    <Form.Control.Feedback type="invalid">
                                                            {formik.errors.service_description}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                               </Col>
                                            </Row>
                                            <div className="mt-3">
                                                <Row>
                                                   <Col md={4}></Col>
                                                   <Col md={4}></Col>
                                                   <Col md={4}>
                                                    <Row>
                                                    <Col md={6} sm={6} xs={6} lg={6} className="mb-2">
                                                        <Button className="add-service-button-cancel" variant="" size="lg" onClick={onBack}>
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                    <Col md={6} sm={6} xs={6} lg={6} className="mb-2">
                                                        <Button className="add-service-button-save" variant="" type="submit" size="lg">
                                                            Save
                                                        </Button>
                                                    </Col>
                                                
                                                    </Row>
                                                   </Col>
                                                
                                                  
                                                    
                                                </Row>
                                            </div>
                                        </FormikProvider>
                                    </Form>
                                </Col>
                                {/* <Col md={5} className="">
                                    <img className='img-fluid mt-5' src={ServiceImage}/>
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ServiceAddEdit