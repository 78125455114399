import axios from 'axios';
import * as TYPES from './types.js';
import { toast } from "react-toastify";
import DASHBOARD_SERVICES from "./services";
import * as VENDOR_TYPES from "../business/types.js";
import { ToastHeader } from 'react-bootstrap';
import { logoutFromLocalStorage } from '../auth/actions';
import { DASHBOARD_CONFIG } from '../../configs/dashboardConfig.js';


export const changeDashboardType = (type) => (dispatch) => {
    dispatch({
        type: TYPES.CHANGE_DASHBOARD_TYPE,
        payload: type
    });
};

export const getDashboardStatus = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.dashboardStatus(data)
        .then((response) => {

            if (response && response.status === 200) {
                // dispatch({
                //     type: TYPES.CHANGE_DASHBOARD_STATUS,
                //     payload: { user: response.data },
                // });
                const { data } = response
                return data;
            }
            else {
                console.log(response);

            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const adminDashboardStatus = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminDashboardStatus(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};





export const getAllServices = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_SERVICE_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_SERVICE_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_SERVICE_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getAllOrders = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllOrder(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_ORDER_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_ORDER_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_ORDER_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const requestAcceptDenyAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.acceptDenyRequest(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.message);
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getAllRequest = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllRequest(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_REQUEST_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_REQUEST_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_REQUEST_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getOneRequestAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneRequest(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response
                return data;
            }
            else {
                console.log(response);

            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const getAllAppointments = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllAppointment(data)
        .then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_APPOINTMENT_DETAILS_SUCCESS,
                    payload: { events: response.data },
                });
                return response;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_APPOINTMENT_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_APPOINTMENT_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getAllStaffs = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllStaff(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_STAFF_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_STAFF_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_STAFF_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getOneService = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneService(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response
                return data;
            }
            else {
                console.log(response);

            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getOneAppointment = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneAppointment(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(data);
                toast.warning(data.message)
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ONE_APPOINTMENT_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getOneOrder = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneOrder(data)
        .then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ONE_ORDER_DETAILS_SUCCESS,
                    payload: response.data,
                });
                return response;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ONE_ORDER_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ONE_ORDER_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};


export const createService = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {

                toast.success(data.message);
                return response;
            }
            else {
                console.log(data);
                toast.warning(data.message);
            }

        })
        .catch((error) => {

            if (error.response) {
                console.log(error.response)
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    console.log(data.message);
                    const { code, keyValue } = data?.message
                    if (code && code === 11000) {
                        if (keyValue) {
                            Object.keys(keyValue).map((item) => {
                                toast.warning(item + " already exists");
                            })
                        }
                    }
                    else {
                        toast.error(data.message);
                    }
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateService = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateService(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response)
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    console.log(data.message);
                    const { code, keyValue } = data?.message
                    if (code && code === 11000) {
                        if (keyValue) {
                            Object.keys(keyValue).map((item) => {
                                toast.warning(item + " already exists");
                            })
                        }
                    }
                    else {
                        toast.error(data.message);
                    }
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const deleteService = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteService(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const deleteManyServicesAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteManyService(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};




export const createOrder = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createOrder(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateOrder = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateOrder(data)
        .then((response) => {
            console.log(response, "fdgfgfg")
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.message(response)
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        // dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const verifyOrderOtp = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.verifyOrderOtp(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.warning(data.message)
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const deleteOrder = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteOrder(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const createAppointment = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createAppointment(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateAppointment = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateAppointment(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const deleteAppointment = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteAppointment(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};




export const createStaff = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createStaff(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateStaff = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateStaff(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteStaff = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteStaff(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const getDashboardAllSettings = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllDashboardSettings(data)
        .then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_DASHBOARD_SETTINGS_SUCCESS,
                    payload: response.data,
                });
                return response;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_DASHBOARD_SETTINGS_FAIL,
                });
            }

        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: TYPES.GET_ALL_DASHBOARD_SETTINGS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const updateDashboardSettings = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateDashboardSettings(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);

            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};



export const getAllUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllUsers(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_USER_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_USER_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const getAllUsedCoupons = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findAllUsedCoupons(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllUsedCouponsBusiness = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findAllUsedCouponsBusiness(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllCouponsGrouping = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllCouponsGrouping(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllCouponsGrouping_business = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllCouponsGrouping_business(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const findAllTransactions = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.transcationGetAll(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const findAllCommissions = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.commissionGetAll(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
export const updateCommissionsActions = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.commissionUpdate(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
// export const getAllUsedCoupons = (data) => (dispatch) => {
//     return DASHBOARD_SERVICES.findAllUsedCoupons(data)
//         .then((response) => {
//             const { data } = response;
//             if (response && response.status === 200) {
//                 dispatch({
//                     type: TYPES.GET_ALL_USED_COUPONS,
//                     payload: data.results,
//                 });
//                 return data;
//             }
//             else {
//                 console.log(response);
//                 return dispatch({
//                     type: TYPES.GET_ALL_USED_COUPONS_FAIL,
//                 });
//             }

//         })
//         .catch((error) => {
//             if (error.response) {
//                 const { status, data } = error.response
//                 if (status > 200 && status <= 500) {
//                     if (status === 401) {
//                         dispatch(logoutFromLocalStorage());
//                     }
//                     toast.warning(data.message);
//                 }
//                 else {
//                     toast.error("Something went wrong");
//                 }
//             }
//             else {
//                 toast.error("Something went wrong");
//             }
//             return Promise.reject(error);
//         });
// };

export const deleteUser = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteUser(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                console.log(response);
                toast.warning(data.message)
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const deleteServiceProvider = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteServiceProvider(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                console.log(response);
                toast.warning(data.message)
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getAllBusiness = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllBusiness(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_BUSINESS_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_BUSINESS_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: TYPES.GET_ALL_BUSINESS_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};


export const getAllFreelancer = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllFreelancer(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_BUSINESS_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_BUSINESS_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: TYPES.GET_ALL_BUSINESS_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};


export const getAllProposals = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllProposals(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_PROPOSAL_DETAILS_SUCCESS,
                    payload: data.results,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: TYPES.GET_ALL_PROPOSAL_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: TYPES.GET_ALL_PROPOSAL_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const getProposalDetails = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getProposalDetails(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
            return Promise.reject(error);
        });
};

export const updateProposalDetailsActions = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateProposalDetails(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
        });
};



export const getAllMarketing = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllMarketing(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
            return Promise.reject(error);
        });
};



export const changeProposalStatus = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.changeProposalStatus(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
            return Promise.reject(error);
        });
};


export const addStaffs = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.addStaffs(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
            return Promise.reject(error);
        });
};





export const createCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getOneCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};






export const createReferralCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createReferralCode(data)
    
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllReferralCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getAllReferralCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getOneReferralCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getOneReferralCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateReferralCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateReferralCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteReferralCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteReferralCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};




export const createResources = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createResources(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const findAllresources = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findAllresources(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ALL_STAFF_DETAILS_SUCCESS,
                    payload: { events: data },
                });
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const findOneResource = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findOneResource(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.warning(data.message)
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateResources = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateResources(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.success(data.message)
                console.log(response);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const deleteResources = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteResources(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.warning(data.message)
                console.log(data);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};






export const createBusinessCouponCode = (data) => (dispatch) => {
    
    return DASHBOARD_SERVICES.businessCreateCouponCode(data)
         
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const findOneBusinessCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessfindOneCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateBusinessCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessUpdateCouponCode(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteBusinessCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessDeleteCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
export const getAllBusinessCouponCodeAction = (data) => (dispatch) => {
    console.log(data.serviceprovider,"outer")
    return DASHBOARD_SERVICES.businessfindAllCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};



export const createAdminCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminCreateCouponCode(data)
    // return DASHBOARD_SERVICES.createBusinessCouponCode(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const addAdminCouponCodeUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminAddUsersCoupon(data)
    // return DASHBOARD_SERVICES.createBusinessCouponCode(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const addBusinessCouponCodeUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.addBusinessCouponCodeUsers(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};
export const deleteBusinessCouponCodeUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteBusinessCouponCodeUsers(data)
    // return DASHBOARD_SERVICES.createBusinessCouponCode(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};
export const updateBusinessCouponCodeUsers= (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateBusinessCouponCodeUsers(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteAdminCouponCodeUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminDeleteUsersCoupon(data)
    // return DASHBOARD_SERVICES.createBusinessCouponCode(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const UpdateAdminCouponCodeUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateAdminUserCoupons(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};



export const findOneAdminCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminfindOneCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateAdminCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminUpdateCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.data.message)
                return response;
            }
            else {
                toast.warning(response.data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
export const updateAdminGetOneUser = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminUpdateGetoneCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.data.message)
                return response;
            }
            else {
                toast.warning(response.data.message);
                console.log(response);
            }

        })      
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateAdminGetOneUser_business = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.viewBuinessCouponCodeUsers(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.data.message)
                return response;
            }
            else {
                toast.warning(response.data.message);
                console.log(response);
            }

        })      
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};



export const updatAdminGroupingUser = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateAdminGroupingCoupons(data)
        .then((response) => {
            if (response && response.status === 200) {
                toast.success(response.data.message)
                return response;
            }
            else {
                toast.warning(response.data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const deleteAllAdminCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminDeleteAllCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
export const deleteAdminCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminDeleteCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

// delete admin created business coupon
export const deleteBusinessCreatedCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessDeleteCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const getAllAdminCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminfindAllCouponCode(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            console.log(error);
            // toast.error("Something went wrong");
            return Promise.reject(error);
        });
};
export const adminGetServiceProviderAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminfindAllServiceProvider(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const adminGetAllUsers = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.adminfindAllUsers(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                // toast.success(data.message)
                return response;
            }
            else {
                // toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const findAllbusinessCouponCodes = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findAllbusinessCouponCodes(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                return data;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};


export const findOneBusinessCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findOneBusinessCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateBusinessCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateBusinessCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteBusinessCouponCode = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteBusinessCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
export const businessGetServiceCategoryAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessGetServiceCategory(data)

        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                // toast.success(data.message)
                return response;
            }
            else {
                // toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteAllBussinessCouponCodeAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.businessDeleteAllCouponCode(data)
        .then((response) => {
            if (response && response.status === 200) {
                const { data } = response;
                toast.success(data.message)
                return data;
            }
            else {
                console.log(data);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};




export const createGallery = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.createGallery(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const findAllGallery = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findAllGallery(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const findOneGallery = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.findOneGallery(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const updateGallery = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateGallery(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                return data;
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const deleteGallery = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.deleteGallery(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
                
            }
            else {
                console.log(response);
                toast.error("Something went wrong");
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getVendorDetailsAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getVendorDetails(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_SUCCESS,
                    payload: data.serviceprovider,
                });
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};


export const updateBusinessInformationAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateBusinessInformation(data)
        .then((response) => {
            const { data } = response;
            
            if (response && response.status === 200) {
                dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_SUCCESS,
                    payload: data.serviceProvider,
                });
                console.log(data);
                toast.success(data.message)
                return data;
            }
            else {
                console.log(response);
                toast.warning(data.message)
                return dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};

export const updateBusinessTimingAction = (data) => (dispatch) => {
    return DASHBOARD_SERVICES.updateBusinessTiming(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_SUCCESS,
                    payload: data.serviceprovider,
                });
                toast.success(data.message)
                return data;
            }
            else {
                console.log(response);
                return dispatch({
                    type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: VENDOR_TYPES.GET_VENDOR_DETAILS_FAIL,
            });
            return Promise.reject(error);
        });
};


export const getReferralCodeList= (data) => (dispatch) => {
    return DASHBOARD_SERVICES.getReferralList(data)
        .then((response) => {
            if (response && response.status === 200) {
                return response;
            }
            else {
                console.log(response);
            }

        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};











