import React,{useState} from 'react';
import { BsCartCheck, BsCartDash, BsCartPlus, BsCartX, BsCash, BsScissors, BsFillCalendar3WeekFill } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import {Col, Card, Modal, Button} from 'react-bootstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from "moment";


function DashboardHome(props) {
  console.log(props, "props")

  const businessCardData = [
    {
      header: "Total Orders",
      icon: <BsCartPlus />,
      value: props?.orders?.response?.totalOrders || 0,
      color: "rgba(108, 117, 125, 1)",
      backgroundColor: "rgba(108, 117, 125, .1)"
    },
    {
      header: "Pending",
      icon: <BsCartDash />,
      value: props?.orders?.response?.pendingOrders || 0,
      color: "rgba(255, 193, 7, 1)",
      backgroundColor: "rgba(255, 193, 7, .1)"
    },
    {
      header: "Completed",
      icon: <BsCartCheck />,
      value: props?.orders?.response?.completedOrders || 0,
      color: "rgba(25, 135, 84, 1)",
      backgroundColor: "rgba(25, 135, 84, .1)"
    },
    {
      header: "Cancelled",
      icon: <BsCartX />,
      value: props?.orders?.response?.cancelledOrders || 0,
      color: "rgba(220, 53, 69, 1)",
      backgroundColor: "rgba(220, 53, 69, .1)"
    }
  ]

  const businessCardData2 = [
    {
      header: "Revenue",
      icon: <BsCash />,
      value: props?.revenue?.data?.totalrevenue || 0,
      color: "#F8F9FA",
      valueIcon : <BiRupee />,
    },
    {
      header: "Services",
      icon: <BsScissors />,
      value: props?.revenue?.data?.totalServices || 0,
      color: "#F8F9FA",
    },
  ]

  const [show, setShow] = useState(false);
  const [stateDate, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const dateRange = () => {
    let data = {
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    }
    props.dashboardFilter({...data});
  }


  return (
   <div className='container-xxl'>
     <div className="dashboard-home-content mb-5">
      <div className="row py-3">
        <div className="col-md-6 mt-2" onClick={()=> setShow(false)}>
          <h3 className='business-welcome'>   
            Welcome Back, <span className="provider-name-dashboard">{props.name}</span>
          </h3>
        </div>
        <div className="col-md-6 mt-2">
        
          <h4 class="float-end date-calen">
            {/* {new Date().toLocaleDateString()} &nbsp; */}
            <span className='cur-pointer' onClick={()=> setShow(true)}>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <>
                   <span className='provider-name-dashboard'>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                   <span className='mx-2'>to</span>
                   <span className='provider-name-dashboard'>{moment(stateDate?.[0]?.endDate).format("MMM D, YYYY")}</span>
                  </>
                ) : (
                  <>
                  <span className='provider-name-dashboard'>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                  </>
                )
              }
            </span>
          
            
            <span className="provider-name-dashboard mx-3">
              <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
            </span>

            <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <Modal.Body>
            <div className='calendar-view'>
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>
              </Modal.Body>
            </Modal>
          </h4>
        </div>
      </div>
      <div className="home-cards">
        <div className="row">
          <div className="col-md-12 mb-3">
          <h4 className="mt-4"> Orders </h4>
          </div>
        </div>
        <div className="row">
          {
            businessCardData.map((item, index) => {
              return (
                <div className="col-md-3">
                <div className="dashboard-home-card" style={{ background: `${item.backgroundColor}` }} key={index}>
                  <div className="">
                    <div class="icon-header p-2 mt-2" style={{ color: `${item.color}` }}> {item.icon} &nbsp; {item.header}</div>
                    <div className="home-card-value p-2 mx-4">
                      <h1>
                        {item.value}
                      </h1>
                    </div>
                  </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="row mt-5">
          {
            businessCardData2.map((item) => {
              return (
                
                <Col md={3}>
                  <div className="text-start mb-3">
                    <h4> {item.header} </h4>
                  </div>
                     <Card className="admin-dashboard-card">
                      <Card.Title>
                      <div class="icon-header"> {item.icon} &nbsp; {item.header}</div>
                      </Card.Title>
                      <Card.Body>
                      <div className="home-card-value">
                      <p className="rupee-icon">{item.valueIcon}</p>
                        <h2>
                          {item.value}
                        </h2>
                      </div>
                      </Card.Body>
                     </Card>
                  
                 
                
                </Col>
              
              );
            })
          }
        </div>
      </div>
    </div>
   </div>
  )
}

export default DashboardHome;