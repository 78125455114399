import * as Yup from 'yup';

export const cartCheckOutValidation = Yup.object({
    alternate_name: Yup.string('Enter your username')
        .min(3, 'Username must have minimum 3 characters')
        .max(30, 'Username cannot be more than 15 characters' )
        .required('Username is required'),
        alternate_phone: Yup.string('Enter your phone no')
        .min(10, 'Enter a valid phone no')
        .required('Phone no is required'),
        alternate_email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
        address: Yup.string('Enter a valid address')
        .required('address is required'),
        // area: Yup.string('Enter your area')
        // .required('area is required'),
        city: Yup.string('Enter your city')
        .required('city is required'),
        state: Yup.string('Enter your state')
        .required('state is required'),
        country: Yup.string('Enter your state')
        .required('country is required'),
        zipcode: Yup.string().required("please enter a valid ZipCode"),
        message: ""
});
