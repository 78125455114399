import * as Yup from 'yup';

export const changeEmailValidation = Yup.object({
    email_id: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),


    password: Yup.string('Enter your password').min(8, 'Password should be of minimum 8 characters length').max(15, 'Password should be of maximum 15 characters length')
    .required('Password is required'),
   
});
