import React, { useState, useEffect, useContext, forwardRef } from 'react';
import InfoImg from "../../assets/images/info.png"
import CalenderImg from "../../assets/images/calender.png"
import MenImg from "../../assets/images/men.png"
import TimerImg from "../../assets/images/timer.png"
import RazorpayImg from "../../assets/images/Razorpay.svg"
import { Accordion, Card, Form, Row, Col, Button } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import OrderSummary from './OrderSummary';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import Payment from '../payment';
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer';
import { getAvailableSlotsAction, slotBookingAction, updateUserLocationAction, createUserLocationAction, getUserLocationAction, addOrderDetails, addAppointmentDetails, updateOrderAction, getUserOneOrderAction, addUserLocationDetails, clearSlotDetails } from "../../redux/user/actions";
import { changeServiceProvider, addManyServicesToCart } from "../../redux/cart/actions";
import RescheduleConfirm from './RescheduleConfirm';
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { NutFill } from 'react-bootstrap-icons';
import CartFooter from '../../components/elements/footer/CartFooter';
import { cartCheckOutValidation } from '../../validations/yub_schemas/cartCheckOutValidation';
import { cartCheckOutValidationbasic } from '../../validations/yub_schemas/cartCheckOutValidationbasic';
import OrderSummaryNew from './OrderSummaryNew';
import("moment-timezone");



function CustomToggle({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a class="btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>
            <h5>{children}</h5>
        </a>
    );
}

function CartCheckout() {

    const [orderDetails, setOrderDetails] = useState({})
    const [currentSlotDetails, setCurrentSlotDetails] = useState({});
    const [userContactDetails, setUserContactDetails] = useState({});
    const [appointmentTimeStamp, setAppointmentTimeStamp] = useState("");
    const [order, setOrder] = useState("");
    const [isToday, setIsToday] = useState(true);
    const [serviceType, setServiceType] = useState("shop");
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const[tomorrowDate, settomorrow] = useState(moment(new Date().getDate()+1).format("YYYY-MM-DD")) 
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [day, setDay] = useState(weekdays[moment(new Date()).weekday()]);
    const [currentTime, setCurrentTime] = useState(moment(new Date()).format("hh:mm A"));
    const [selectedTime, setSelectedTime] = useState("");
    const [rescheduleConfirm, setRescheduleConfirm] = useState(false);
    const [originalDate, setOriginalDate] = useState("");
    const [originalTime, setOriginalTime] = useState("");
    const [isOriginalDate, setIsOriginalDate] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    if (activeTab !== window.location.pathname)
        setActiveTab(window.location.pathname);

     const getTomorrow = () => {
     }

    const [key, setKey] = useState(0)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const type = searchParams.get("type");
    const id = searchParams.get("id");
 

    const { slotDetails, appointmentDetails,userLocationDetails } = useSelector(state => state.user)
    const { user } = useSelector((state) => state.auth);
    const { servicesCart, serviceProviderCart } = useSelector((state) => state.cart);
   

    const [isSelectedSlot, setIsSelectedSlot] = useState(false);
    const { vendor } = useSelector(state => state.business);


    const tomorrow = new Date();
    // const [startDate, setStartDate] = useState(tomorrow.setDate(tomorrow.getDate() + 1));
    const [startDate, setStartDate] = useState(tomorrow.setDate(tomorrow.getDate()));


    useEffect(() => {
        if (id) {
            let data = {
                id: id
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    let { message, results, userLocation } = res
                    if (results) {
                        let orderDetails = results;
                        if (orderDetails) {
                            let { appointment, customer_details, service_provider, services, _id } = orderDetails;
                            setOrder(_id)
                            dispatch(addAppointmentDetails(appointment))
                            dispatch(changeServiceProvider(service_provider));
                            // dispatch(addManyServicesToCart(services));
                            // selectSlotDate(appointment?.appointment_start_dateTime)
                            setDate(moment(appointment?.appointment_start_dateTime).format("YYYY-MM-DD"))
                            setSelectedTime(moment(appointment?.appointment_start_dateTime).format("hh:mm A"))
                            setIsToday(moment(appointment?.appointment_start_dateTime).format("YYYY-MM-DD") === moment.unix(new Date() / 1000).format("YYYY-MM-DD"));
                            setDay(weekdays[moment(appointment?.appointment_start_dateTime).weekday()]);                    
                            if (type==='reschedule') {
                                setOriginalDate(moment(appointment?.appointment_start_dateTime).format("YYYY-MM-DD"));
                                setOriginalTime(moment(appointment?.appointment_start_dateTime).format("hh:mm A"));
                                setIsOriginalDate(true)
                                
                            }
                            let data = {
                                id: service_provider?._id,
                                date: moment(appointment?.appointment_start_dateTime).format("YYYY-MM-DD")
                                
                            }
                            dispatch(getAvailableSlotsAction(data))
                        }
                        else {
                            console.log("Order details not found")
                        }
                    } else {
                        console.log("Order not found")
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        }
        // else{
        //     selectSlotDate(new Date()); 
        // }
    }, [id])

    useEffect( () => {
        selectSlotDate(startDate)
        return async () => {
            await dispatch(clearSlotDetails());

        }
    }, [startDate]);

    const decoratedOnClick1 = useAccordionButton(key, () =>
        console.log('totally custom!'),
        
    );

    const changeSection = (type) => {
        // setKey(type);
        decoratedOnClick1()

    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: userLocationDetails?._id || "",
            user: user?._id,
            username: user?.username || '',
            phone_no: user?.mobile_no || '',
            email: user?.email_id || '',
            type: serviceType,
            address: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            message: "",
            // vendor_type: vendor?.type
        },
        // validationSchema: serviceType !== "home" ? cartCheckOutValidationbasic : cartCheckOutValidation,

        onSubmit: (values) => {
            const data = {
                id: userLocationDetails?._id || "",
                user: user?._id,
                username: values.username,
                phone_no: values.phone_no,
                email: values.email,
                type: serviceType,
                address: values.address,
                area: values.area,
                city: values.city,
                state: values.state,
                country: values.country,
                zipcode: values.zipcode,
                message: values.message
            }
            if (userLocationDetails?._id && userLocationDetails?._id !== "") {
                dispatch(updateUserLocationAction(data))
                    .then((res) => {
                        console.log("hellooo=>", res)
                        const { userLocation } = res;
                        if (userLocation) {
                            setUserContactDetails(userLocation)
                            dispatch(addUserLocationDetails(userLocation))
                        }
                        else {
                            setUserContactDetails({})
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                dispatch(createUserLocationAction(data))
                    .then((res) => {
                        const { userLocation } = res;
                        if (userLocation) {
                            setUserContactDetails(userLocation)
                            dispatch(addUserLocationDetails(userLocation))
                            
                        }
                        else {
                            setUserContactDetails({})
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

        },
    });
 

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button variant="outline-none" onClick={onClick} ref={ref}>
            <img src={CalenderImg} alt="calender" />Select Date
        </Button>

    ));

    const selectSlotDate = (date) => {
        setSelectedTime("")
        setDate(moment(date).format("YYYY-MM-DD"))
        setIsToday(moment(date).format("YYYY-MM-DD") === moment.unix(new Date() / 1000).format("YYYY-MM-DD"));
        setIsOriginalDate(moment(date).format("YYYY-MM-DD") === originalDate)
        if ((moment(date).format("YYYY-MM-DD") === originalDate) && type === 'reschedule') setSelectedTime(originalTime);
        setDay(weekdays[moment(date).weekday()]);
        let data = {
            id: serviceProviderCart?._id,
            date: moment(date).format("YYYY-MM-DD")
        }
        dispatch(getAvailableSlotsAction(data))
        
    }

    const handleReschduleConfirm = () => {
        setRescheduleConfirm(true)
    }

    const handleReschduleCancel = () => {
        navigate("/orders")
    }

    const handleReschduleSlotBooking = async () => {
        let appointmentData = {
            appointment_id: appointmentDetails?._id,
            serviceprovider: serviceProviderCart?._id,
            date: date,
            timeSlot: moment(date + " " + selectedTime,"YYYY-MM-DD hh:mm A").utc().format(),
            time: selectedTime,
            user: user?._id,
            services: servicesCart.map(item => item._id)
        }
        
        const appointmentRes = await dispatch(slotBookingAction(appointmentData));
        if (!appointmentRes?._id) {
            toast.warn("Slot booking failed");
            return
        }
        handleReschdule();
    }

    const handleReschdule = () => {
        let data = {
            id: order,
            appointment: Math.floor(moment(appointmentDetails.appointment_start_dateTime).valueOf()/1000),
        }
        dispatch(updateOrderAction(data))
            .then((res) => {
                navigate(`/business/reschdule/success/${id}`)
            })
            .catch((err) => {
                console.log(err)
                navigate(`/business/reschdule/failed/${id}`)
            })
    }


    const handleSlotBooking = (slot) => {
        setSelectedTime(slot);

        // let data = {
        //     serviceprovider: serviceProviderCart?._id,
        //     date: date,
        //     timeSlot: moment(date + " " + slot).utc().format(),
        //     time: slot,
        //     user: user?._id,
        //     services: servicesCart.map(item => item._id)
        // }

        // if (appointmentDetails?._id) {
        //     console.log("Slot updating successfully");
        //     data.appointment_id = appointmentDetails?._id
        // }

        // setAppointmentTimeStamp(moment(date + " " + slot).utc().format());
        // dispatch(slotBookingAction(data))
        //     .then((res) => {
        //         const { message, appointment } = res;
        //         setCurrentSlotDetails(appointment)
        //         dispatch(addAppointmentDetails(appointment))
        //         let slotRequest = {
        //             id: serviceProviderCart?._id,
        //             date: date
        //         }
        //         dispatch(getAvailableSlotsAction(slotRequest))
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })

    }


    const redirectBusinessPage = () => {
        navigate(`/business/${serviceProviderCart?._id}`)
    }

    const changesServiceType = (e) => {
        const { name, value, checked } = e.target
        setServiceType(value)
    }
    const changeServiceTab = (value) => {
        setServiceType(value)
    }
   
    const scrollToTop = () =>{
        window.scrollTo({
          top: 100, 
          
          behavior: 'smooth'
          
        });
      };

      useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);
    
   

    return (
        <div className="cart-checkout-page">
            <RescheduleConfirm
                show={rescheduleConfirm}
                onHide={() => setRescheduleConfirm(false)}
                selectedDate={date}
                selectedTime={selectedTime}
                handleReschdule={() => handleReschduleSlotBooking()}
            />
            <Header />
            <section>
                <div class="container mt-2">
                    <div class="row">
                        
                        {
                            type == "reschedule" && (
                                <div class="col-md-12 mt-3">
                                 <div className='reschedule-outer'>

                                 <div className='text-center'>
                                      <h6>Reschedule Appointment</h6>
                                      <Row>
                                        <Col>
                                        <Card>
                                                <Card.Body>
                                                <Row className='text-center'>
                                                    <p>Appointment Date / Time</p>
                                                    <h6>{moment(orderDetails?.appointment?.appointment_start_dateTime).local().format("DD MMM YY hh:mm A")}</h6>
                                                </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                        <Card>
                                            <Card.Body>
                                                
                                            <Row className='text-center'>
                                                    <p>Service type</p>
                                                    <h6>Shop Service</h6>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        </Col>
                                      </Row>
                                   </div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="checkout-card-header-active">
                                            <CustomToggle eventKey="0" >
                                                <span className=''>Date / Time</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
    
                                                <Row>
    
                                                    <Col md={4}>
                                                        {/* <h6>Select Date</h6>
                                                        {
                                                            date !== "" && selectedTime !== "" && (
                                                                <div className="selected-date-display">
                                                                    <h6>
                                                                        Selected Time slot :
                                                                    </h6>
                                                                    <span className='text-primary'>
                                                                        {
                                                                            date + " " + selectedTime
    
                                                                        }
                                                                    </span>
                                                                </div>
    
                                                            )
                                                        }
                                                        <p>Date:<span>{date}</span> </p> 
                                                       <p class="common-date">Day:<span>{day}</span> </p> */}
                                                     <h6>Select Date</h6>
                                                    <DatePicker
                                                                    // selected={startDate}
                                                                    onChange={(date) => selectSlotDate(date)}
                                                                    customInput={<ExampleCustomInput />}
                                                                    minDate={startDate}
                                                                    showDisabledMonthNavigation
                                                                    inline
                                                                />
                                                            
                                                    </Col>
                                                    <Col md={8}>        
                                                        <div className="mx-3">
                                                        <h6>Select Time</h6>
                                                        <div>
                                                     
                                                        <>
                                                            <div className="row">
                                                                    {
                                                                        Object.keys(slotDetails).map((item, index) => {
                                                                            const itemInMoment = moment(item, 'HH:mm a')
                                                                            const currentTimeInMoment = moment(currentTime, 'HH:mm a')
                                                                            return (            
                                                                    <div className="col-md-3">
                                                                                <div className="" key={index}>
                                                                                    {
                                                                                        slotDetails[item]?.total > 0 ? (
                                                                                            <Button className={slotDetails[item]?.available ? (item === selectedTime ? "slot-booking-time" : "slot-time-btn") : "slot-time-btn-danger"} variant=""
                                                                                            onClick={() => handleSlotBooking(item)} disabled={(isToday && (currentTimeInMoment.diff(itemInMoment) > 0)) || !slotDetails[item]?.available || (type === 'reschedule' && isOriginalDate && originalTime === item)}
                                                                                        >
                                                                                            {item}
                                                                                        </Button>
                                                                                        ) : (
                                                                                            <Button className="slot-time-btn-none" disabled> 
                                                                                            <p>{item}</p>
                                                                                        </Button>
                                                                                        )
                                                                                    }
                                                                                   
                                                                                    {
                                                                                        slotDetails[item]?.total > 0 ? (
                                                                                            <>
                                                                                             <p className="slot-available-text">{(item === selectedTime && slotDetails[item]?.available) && !(type === 'reschedule' && isOriginalDate && originalTime === item) ? slotDetails[item]?.remaining - 1: slotDetails[item]?.remaining} slots available</p>
    
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                            <p className="slot-available-text"> No slots available</p>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                      </div>
                                                                                </div>
                                                                            )
    
                                                                        })
                                                                    }   
                                                            </div>
                                                            <hr />
                                                        </>
                                                   
                                                        </div>
                                                        </div>
                                                    </Col>
                                            </Row>
    
                                        <Row>
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Services</Accordion.Header>
                                                        <Accordion.Body>
                                                        <Row className="mt-3">
                                        <Col className='cart-serviceprovider-details' md={12}>
                                        <h6>{serviceProviderCart?.business_name}</h6>
                                        <p>{serviceProviderCart?.location_details?.area + " " + serviceProviderCart?.location_details?.city}</p>
                                        </Col>
                                        <hr />
                                    </Row>
                                                      
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                               </Accordion>
                                         </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                   
    
                                </Accordion>
    
                                <p class="review-order mt-0 pt-0">Make sure the appointment information is correct before you pay.</p>
                                {
                                    type === "reschedule" && (
                                        <Row>
                                            <Col>
                                            <button type="submit" class="Reschedule-cancel mt-3" onClick={handleReschduleCancel}>Cancel</button>

                                            </Col>
                                            <Col>
                                            <button type="submit" class="Reschedule-process mt-3" onClick={handleReschduleConfirm}>Reschedule</button>

                                            </Col>
                                        </Row>
                                    )
                                }

                                 </div>
    
                            </div>
                            )
                        }
             {
                type !=="reschedule" && (
                    <div class="col-md-8 mt-3">

                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header className="checkout-card-header-active">
                                <CustomToggle eventKey="0" >
                                    <span className=''>Date / Time</span>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>

                                    <Row>

                                        <Col md={4}>
                                            {/* <h6>Select Date</h6>
                                            {
                                                date !== "" && selectedTime !== "" && (
                                                    <div className="selected-date-display">
                                                        <h6>
                                                            Selected Time slot :
                                                        </h6>
                                                        <span className='text-primary'>
                                                            {
                                                                date + " " + selectedTime

                                                            }
                                                        </span>
                                                    </div>

                                                )
                                            }
                                            <p>Date:<span>{date}</span> </p> 
                                           <p class="common-date">Day:<span>{day}</span> </p> */}
                                         <h6>Select Date</h6>
                                        <DatePicker
                                                        // selected={startDate}
                                                        onChange={(date) => selectSlotDate(date)}
                                                        customInput={<ExampleCustomInput />}
                                                        minDate={startDate}
                                                        showDisabledMonthNavigation
                                                        inline
                                                    />
                                                
                                        </Col>
                                        <Col md={8}>        
                                            <div className="mx-3">
                                            <h6>Select Time</h6>
                                            <div>
                                         
                                            <>
                                                <div className="row">
                                                        {
                                                            Object.keys(slotDetails).map((item, index) => {
                                                                const itemInMoment = moment(item, 'HH:mm a')
                                                                const currentTimeInMoment = moment(currentTime, 'HH:mm a')
                                                                return (            
                                                        <div className="col-md-3">
                                                                    <div className="" key={index}>
                                                                        {
                                                                            slotDetails[item]?.total > 0 ? (
                                                                                <Button className={slotDetails[item]?.available ? (item === selectedTime ? "slot-booking-time" : "slot-time-btn") : "slot-time-btn-danger"} variant=""
                                                                                onClick={() => handleSlotBooking(item)} disabled={(isToday && (currentTimeInMoment.diff(itemInMoment) > 0)) || !slotDetails[item]?.available || (type === 'reschedule' && isOriginalDate && originalTime === item)}
                                                                            >
                                                                                {item}
                                                                            </Button>
                                                                            ) : (
                                                                                <Button className="slot-time-btn-none" disabled> 
                                                                                <p>{item}</p>
                                                                            </Button>
                                                                            )
                                                                        }
                                                                       
                                                                        {
                                                                            slotDetails[item]?.total > 0 ? (
                                                                                <>
                                                                                 <p className="slot-available-text">{(item === selectedTime && slotDetails[item]?.available) && !(type === 'reschedule' && isOriginalDate && originalTime === item) ? slotDetails[item]?.remaining - 1: slotDetails[item]?.remaining} slots available</p>

                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                <p className="slot-available-text"> No slots available</p>
                                                                                </>
                                                                            )
                                                                        }
                                                                          </div>
                                                                    </div>
                                                                )

                                                            })
                                                        }   
                                                </div>
                                                <hr />
                                            </>
                                       
                                            </div>
                                            </div>
                                        </Col>
                                </Row>
                            
                            <Row>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Services</Accordion.Header>
                                            <Accordion.Body>
                                            <Row className="mt-3">
                            <Col className='cart-serviceprovider-details' md={12}>
                            <h6>{serviceProviderCart?.business_name}</h6>
                            <p>{serviceProviderCart?.location_details?.area + " " + serviceProviderCart?.location_details?.city}</p>
                            </Col>
                            <hr />
                        </Row>
                                            {

                                        type !== "reschedule" && (
                                            <div class="row">
                                                <div class="col-md-12">
                                                    {
                                                        servicesCart && servicesCart.length > 0 && (
                                                            
                                                                    <div>
                                                                        
                                                                                                                                                           
                                                                        {
                                                                            servicesCart && servicesCart.length > 0 && (
                                                                                servicesCart.map((item, index) => {
                                                                                    let gender = ""
                                                                                    if (item.gender && item.gender.length > 0) {
                                                                                        gender = item?.gender?.map((i) => i.gender_name).join(" ")
                                                                                    }
                                                                                    return (
                                                                                        <div key={index}>
                                                                                        <Row className='mb-2'>
                                                                                        
                                                                                            <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                            <span>{gender}</span>
                                                                                            <p class="service-heading">{item?.service_name}</p>
                                                                                            <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                            </Col>
                                                                                            <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                                  <div className='cart-check-out-amount'>
                                                                                                    <p className='mt-4'>{ item?.service_price?.["$numberDecimal"] +" ₹ "}</p>
                                                                                                  </div>
                                                                                            </Col>
                                                                                        
                                                                                        </Row>
                                                                                    </div>
                                                                                       
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                    </div>
                                                 
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                   </Accordion>
                             </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {
                            type !== "reschedule" && (
                                <Card>
                                    <Card.Header className="checkout-card-header-active">
                                        <CustomToggle eventKey="1">
                                            <span>Services Type</span>
                                        </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                         
                                                {
                                                    serviceProviderCart?.serviceTypes?.[0]?.servicetype_name === "Both Shop and Home service" && (
                                                        <>
                                                         <h5 className='text-bold my-3'>
                                                            Select Service
                                                        </h5>   
                                                         <Row>
                                                       <Col md={6}>
                                                             <Card className={serviceType==="shop" ? "service-type-active" : "default-service-type"} onClick={()=>changeServiceTab('shop')}>
                                                            <p>Shop Service</p>
                                                            </Card>
                                                        </Col>
                                                        <Col md={6}>
                                                             <Card className={serviceType==="home" ? "service-type-active" : "default-service-type"} onClick={()=>changeServiceTab('home')}>
                                                                <p>Home Service</p>
                                                             </Card>
                                                        </Col>
                                                        </Row>
                                                        </>
                                                       
                                                    )
                                                }
                                    
                                                              {
                                                                    serviceProviderCart?.serviceTypes && serviceProviderCart?.serviceTypes[0]?.servicetype_name === "Shop only" && (
                                                                       <Row>
                                                                        <Card className="service-type-active">
                                                                        <p>Shop Service</p>
                                                                        </Card>
                                                                       </Row>
                                                                        
                                                                    )
                                                                }
                                                                                        {
                                                                    serviceProviderCart?.serviceTypes && serviceProviderCart?.serviceTypes[0]?.servicetype_name === "Home service" && (
                                                                       <Row>
                                                                        <Card className="service-type-active">
                                                                        <p>Home Service</p>
                                                                        </Card>
                                                                       </Row>
                                                                      
                                                                    )
                                                                }
                                            <div class="row">    
                                                <div class="col-md-12">
                                                    <div class="contact-details mt-0">

                                                        {/* <span className="edit-icon-checkout"><img src={EditImg} alt="edit" class="img-fluid edit" />Edit</span> */}
                                                        <Form noValidate className="register-form" onSubmit={formik.handleSubmit}>
                                                            <FormikProvider value={formik}>
                                                                <h6 class="btn p-0 mb-0">Contact detail</h6>
                                                                <div class="mb-3 mt-3">
                                                                    <input type="text" class="form-control" id="username" placeholder="Username"
                                                                        name="username"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.username}
                                                                    />
                                                                </div>
                                                                <p className="manual-error-style px-3">
                                                                <ErrorMessage name="username" />
                                                                </p>
                                                                <div class="mb-3">
                                                                    <input type="phone" class="form-control" id="phone_no" placeholder="Phone Number"
                                                                        name="phone_no"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.phone_no} />
                                                                </div>
                                                                <p className="manual-error-style px-3">
                                                                <ErrorMessage name="phone_no" />
                                                                </p>
                                                                <div class="mb-3 mt-3">
                                                                    <input type="email" class="form-control" id="email" placeholder="Enter email"
                                                                        name="email"
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.email}
                                                                    />
                                                                </div>
                                                                <p className="manual-error-style px-3">
                                                                <ErrorMessage name="email" />
                                                                </p>
                                                                {
                                                                    serviceType === "home" && (
                                                                        <>
                                                                            <h6 class="btn p-0 my-4">Residential Address</h6>
                                                                            <div class="mb-0 mt-0">
                                                                                <input type="text" class="form-control" id="address" placeholder="Address"
                                                                                    name="address"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.address}
                                                                                />
                                                                            </div>
                                                                            <p className="manual-error-style px-3">
                                                                <ErrorMessage name="address" />
                                                                </p>
                                                                            <div class="mb-3 mt-3">
                                                                                <input type="text" class="form-control" id="city" placeholder="City"
                                                                                    name="city"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.city} />
                                                                            </div>
                                                                            <p className="manual-error-style px-3">
                                                                <ErrorMessage name="city" />
                                                                </p>
                                                                            <div class="mb-3 mt-3">
                                                                                <input type="text" class="form-control" id="state" placeholder="State"
                                                                                    name="state"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.state} />
                                                                            </div>
                                                                            <p className="manual-error-style px-3">
                                                                <ErrorMessage name="state" />
                                                                </p>
                                                                            <div class="mb-3 mt-3">
                                                                                <input type="text" class="form-control" id="country" placeholder="Country"
                                                                                    name="country"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.country} />
                                                                            </div>
                                                                            <p className="manual-error-style px-3">
                                                                <ErrorMessage name="country" />
                                                                </p>
                                                                            <div class="mb-3 mt-3">
                                                                                <input type="text" class="form-control" id="zipcode" placeholder="ZIP code" name="zipcode"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.zipcode} />
                                                                            </div>
                                                                            <p className="manual-error-style px-3">
                                                                <ErrorMessage name="zipcode" />
                                                                </p>
                                                                            <div class="mb-3 mt-3">
                                                                                <textarea class="form-control" rows="5" id="comment" name="message" placeholder="Message"

                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.message}
                                                                                ></textarea>
                                                                                <span class="count-text">0 / 120</span>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }

                                                                <button type="submit" class="continue-btn-login mt-3" onClick={changeSection("3")}>Continue</button>
                                                            </FormikProvider>
                                                        </Form>
                                                        {/* </form> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )}
                        {
                            type !== "reschedule" && (
                                <Card>
                                    <Card.Header className="checkout-card-header-active">
                                        <CustomToggle eventKey="2" onClick={scrollToTop}>
                                            <span >Payment option</span>
                                        </CustomToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <div class="payment-options">
                                                    <img src={RazorpayImg} alt="small-info" class="img-fluid small-info" />
                                                        <Payment
                                                            class="continue-btn-login mt-4"
                                                            selectedDate={date}
                                                            selectedTime={selectedTime}
                                                        ></Payment>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        }

                    </Accordion>

                    <p class="review-order mt-0 pt-0">Make sure the appointment information is correct before you pay.</p>
                    {/* {
                        type === "reschedule" && (
                            <button type="submit" class="continue-btn-login mt-3" onClick={handleReschduleConfirm}>Reschedule</button>
                        )
                    } */}

                </div>
                )
             }
                        
                        <div class="col-md-4">
                            {
                                activeTab === "/payment/checkout" ? (
                                    
                                    <>
                                    {
                                   type !== "reschedule" && (
                                       <>
                                       <OrderSummaryNew/>
                                       </>
                                   )
                               }
                                    </>
                                ):(
                                    <>
                                    {
                                   type !== "reschedule" && (
                                       <>
                                       <OrderSummary continue={false} />
                                       </>
                                   )
                               }
                                   </>
                                )
                             
                            }
                           

                        </div>
                    </div>
                </div>
            </section>
           <div className=''>
           <CartFooter />
           </div>
        </div>
    )
}

export default CartCheckout