import React, { useState, useEffect } from 'react';
import { getAllUsers, deleteUser, findAllTransactions,getAllAdminCouponCodeAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Modal, Row, Col, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import ReferralAddEdit from "./ReferralAddEditSalon"
import GroupingUsers_salon from './groupingUsers_salon';

import { DateRange } from 'react-date-range';
import moment from "moment";
import { BsFillCalendar3WeekFill } from 'react-icons/bs'

function GroupingSalon() {

    return (


        <GroupingUsers_salon />


    )
}

export default GroupingSalon;
