import React from 'react'
import DashboardHeader from '../DashboardHeader/index';
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import {Row, Col} from "react-bootstrap";
import GroupingSalon from './groupingSalon';

function GroupingSalonMain() {
  return (
    <div className="dashboard-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="">
          <Row>
            <Col className='p-0' md={2} sm={12}>
            <div className="sidebar-content">
            <AdminSidebarPage />
          </div>
            </Col>
            <Col md={10} sm={12}>
            <div className="main-content">
                <GroupingSalon />
          </div>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  )
}

export default GroupingSalonMain;
