import * as Yup from 'yup';

export const cartCheckOutValidationbasic = Yup.object({
    username: Yup.string('Enter your username')
    .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name")
        // .strict()
        .min(3, 'Username must have minimum 3 characters')
        .max(30, 'Username cannot be more than 15 characters' )
        .required('Username is required'),
    phone_no: Yup.string('Enter your phone no')
        .min(10, 'Enter a valid phone no')
        .required('Phone no is required'),
    email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
        address: Yup.string('Enter a valid address')
        .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name").notRequired('address is required'),
        area: Yup.string('Enter your area')
        .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name").notRequired('area is required'),
        city: Yup.string('Enter your city')
        .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name").notRequired('city is required'),
        state: Yup.string('Enter your state')
        .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name").notRequired('state is required'),
        country: Yup.string('Enter your state')
        .matches(/^[a-zA-Z]{3}[a-zA-Z0-9 .*!,&^%$#@)(_+=?/'_-]+$/, "Please enter valid Name").notRequired('country is required'),
        zipcode: Yup.string().notRequired("please enter a valid ZipCode"),
        message: ""
});
