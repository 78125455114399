import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { deleteAdminCouponCodeAction, deleteAllAdminCouponCodeAction, findOneAdminCouponCodeAction, getAllAdminCouponCodeAction, getAllBusiness, getAllMarketing, updateAdminCouponCodeAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Table, Modal, Row, Col } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReferralAddEdit from "./ReferralAddEdit"
import UsedCouponList from "../UsedCoupons/usedCoupons"
import GroupingUsers from "../GroupingUsers/groupingUsers"
import ReferralList from "../Referral/ReferralList"
import { Formik } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import AdminCouponEdit from './AdminCoponEdit';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



function ReferralCode() {

    const [search, setSearch] = useState("");
    const [referralSearch, setReferralSearch] = useState("");
    const [couponCountSearch, setCouponCountSearch] = useState("")
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [code_filter, setCode_filter] = useState("Coupon");
    const [activeTab , setActiveTab] = useState("Coupon")
    const [switchTabUsedcouponCount, setSwitchTabUsedcouponCount] = useState(false)

    const [viewOpen, setViewOpen] = useState(false);
    const [viewOpenEdit, setViewOpenEdit] = useState(false);
   const [selectCoupon, setSelectCoupon] = useState({});
   const [page, setPage] = useState(0);
   const [DeleteConform, setDeleteConform] = useState(false);
   const [selectedItems, setSelectedItems] = useState([]);
   const [selectallCheck, setSelectallCheck] = useState(false)
   const [selectSort, setSelectSort] = useState("Z-A")


    const navigate = useNavigate();
    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 0,
    });

    const dispatch = useDispatch();

    const { orders } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const data = {
            page: state.activePage,
        };

        dispatch(getAllAdminCouponCodeAction(data))
            .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                couponMain.map((checkItem)=>{
                 checkItem.isChecked = false;
                });
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })
    }, []);


 
    const handleActiveTab = (event) => {
      setActiveTab(event)
    }



    const renderSearchBar = () => {
      if (activeTab === 'Coupon') {
        return (
          <Row>
            <input
              type="text"
              className="form-control searchbar-copon"
              placeholder="Search coupon name..."
              name="search"
              value={search}
              onInput={onChangeSearch}
            />
          </Row>
        );
      }else if (activeTab === 'Referral') {
        return (
          <Row>
            <input
              type="text"
              className="form-control searchbar-copon"
              placeholder="Search referral code..."
              name="search"
              value={referralSearch}
              onInput={onChangeSearch_referral}
            />
          </Row>
        );
      }else if (activeTab === 'Coupon Used Count') {
        return (
          <Row>
            <input
              type="text"
              className="form-control searchbar-copon"
              placeholder="Search coupon code..."
              name="search"
              value={couponCountSearch}
              onInput={onChangeSearch_couponCount}
            />
          </Row>
        );
      }
    };


    const getAllCouponList = (props) => {
        const data = {
            page: state.activePage,
        };

        dispatch(getAllAdminCouponCodeAction(data))
            .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                couponMain.map((item)=>{
                  item.isChecked = false;
                });
                setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }))
            })
    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber.selected);

        const data = {
            page: pageNumber?.selected +1
        };
        dispatch(getAllAdminCouponCodeAction(data))
            .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                couponMain.map((item)=>{
                  item.isChecked = false;
                });   
                setSelectedItems([]);
                setSelectallCheck(false);             
            setState((prev) => ({
                    ...prev,
                    data: couponMain,
                    total: total_count / resultsPerPage
                }));
            })

    };

    const generateCode = () => {
        setViewOpen(true);
    }

    const handleDeleteChange = (_id) => {
        const data = {
            id: _id,
          }
          dispatch(deleteAdminCouponCodeAction(data))
          getAllCouponList();
    }
   
    const handleEditChange = (_id) => {
        const data = {
            id: _id,
          }
          dispatch(findOneAdminCouponCodeAction(data)).then((res) => {
            setSelectCoupon(res?.data);
            setViewOpenEdit(true);
          })
        }

const onCheckboxBtnClick = (item, e) => {
            item.isChecked = e.target.checked;
          
            if (item.isChecked) {
              selectedItems.push(item);
            } else {
              selectedItems.splice(item, 1);
            }
            let isAllSelect = state.data.every((item)=>{
              return item.isChecked == true;
            })
            setSelectedItems([...selectedItems]);
            if (isAllSelect){
              setSelectallCheck(true);
            } else{
              setSelectallCheck(false);
            }
          };
          
          const selectAllCheckBox =(event)=>{
            if (event.target.checked) {
              state.data.map((item)=>{
                selectedItems.push(item);
                return item.isChecked = true;
              });
          
              setSelectedItems([...selectedItems]);
              setSelectallCheck(true);
            }
             else {
              state.data.map((item)=>{
                selectedItems.splice(item, 1);
                return item.isChecked = false;
              });
              setSelectedItems([]);
              setSelectallCheck(false);
          
            }
            
          }


const multiDelete =()=>{
    let selectedItemId = selectedItems.map((service) => {
        return service._id;
      })
      const data = {
                id: selectedItemId,
             }
             dispatch(deleteAllAdminCouponCodeAction(data))
             .then((res)=>{
              getAllCouponList();
              setDeleteConform(false);
              getAllCouponList();
              setSelectallCheck(false);
              setSelectedItems([]);
              window.location.reload();

             })
            
        }


        const onChangeSearch = (e) => {
            setSearch(e.target.value);
            setPage(0);
            const data = {
              search: e.target.value,
              page: 1
            };

            dispatch(getAllAdminCouponCodeAction(data))
              .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                setState((prev) => ({
                  ...prev,
                  data: couponMain,
                  total: total_count / resultsPerPage
                }))
              })
          };

        const onChangeSearch_referral= (e) => {
            setReferralSearch(e.target.value);
            
          };
        const onChangeSearch_couponCount = (e) => {
          setCouponCountSearch(e.target.value);

        };


          const onChangeSort = async () => {
            if(selectSort == "A-Z"){
              setSelectSort("Z-A")
             } else {
              setSelectSort("A-Z")
             }
            const data = {
              page: state.activePage,
              sortType: selectSort,
            };
        
            await dispatch(getAllAdminCouponCodeAction(data))
              .then((res) => {
                const { couponMain, total_count, resultsPerPage } = res
                setState((prev) => ({
                  ...prev,
                  data: couponMain,
                  total: total_count / resultsPerPage
                }))
              })
          }


    return (
        <div className='container-xxl'>
          <div className="mt-3">
            <ReferralAddEdit
                show={viewOpen}
                onHide={() => setViewOpen(false)}
                getCouponCodes={getAllCouponList}

            />
            <AdminCouponEdit
            formData={selectCoupon}
                show={viewOpenEdit}
                onHide={() => setViewOpenEdit(false)}
                getCouponCodes={getAllCouponList}
            />
            <div class="row mb-2">
                <div class="col-md-6">
                <div className="p-2">
                <h3 className='marketing-header'>
                    Marketing <span className="marketing-sub-header">/ Admin </span><span className="marketing-sub-header">/ {activeTab}</span>
                </h3>
            </div>
                </div>
                <div class="col-md-6">
               <div class="d-flex heading-btn-coupon">
               <div className="mt-1 mx-2">
                    <Button onClick={generateCode} variant="" className="generate-btn-admin"> Generate <span class="ionic-icon-btn"><ion-icon name="duplicate-outline"></ion-icon></span></Button>{' '}
                </div>
                <div className="mt-1 mx-2">
                    <Button className="delete-btn-admin" onClick={()=>setDeleteConform(true)}> Delete <span class="ionic-icon-btn"><ion-icon name="trash-outline"></ion-icon></span></Button>{' '}
                </div>
               </div>
                </div>
            </div>
            {/* <hr  className='mt-0 mb-0 p-0'/> */}
            <Modal  centered size="md" show={DeleteConform} onHide={()=>setDeleteConform(false)} className='text-center'>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    Delete Coupons?
                                  </Modal.Title>
                                    <Modal.Body className='mb-5 px-2'>
                                    <p className='mt-2'>Are you sure you want to Delete?</p>

                                          <Row className='mt-5'>
                                            <Col>
                                             <Button className='image-delete-cancel' onClick={()=>{setDeleteConform(false)}}>Cancel</Button>
                                            </Col>
                                            <Col>
                                            <Button className='image-delete-delete' onClick={multiDelete}>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

             </Modal>

            <div className='pt-0'>
                <div className='coupon-headers-inner' id='desktop-view'>
                <Row >
                    {/* <Col md={8} sm={8} xs={8}> */}
                    <Col md={8} sm={8} xs={8}>

                    {/* <input type="text" class="form-control searchbar-copon" id="" placeholder="Search coupon name..." name="search" value={search} onInput={onChangeSearch}  /> */}
                    {renderSearchBar()}

                    </Col>
                    <Col md={4} sm={4} xs={4}>
                         <Row >
                         <Col md={8} sm={8} xs={8}>
                          <p className='coupon-headers-reset'>Reset Filters</p>
                          </Col>
                          <Col md={2} sm={2} xs={2}>
                          <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                          </Col>
                          <Col md={2} sm={2} xs={2}>
                          <Button className='fiter-btn-coupon' onClick={onChangeSort}>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
                          </Row>
                         
                    </Col>
                    
                </Row>
                </div>

                <div className='coupon-headers-inner' id='mobile-view'>
                    <Row>
                    {renderSearchBar()}
  
                    {/* <input type="text" class="form-control searchbar-copon" id="" placeholder="Search coupon name..." name="search" value={search} onInput={onChangeSearch}  /> */}
                    </Row>
                    <div class='row mt-2 p-0'>
                        <div class="col-6">
                        <p>Reset Filters</p>
                        </div>
                       <div class="col-3 px-3">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="options-outline"></ion-icon>
                          </Button>

                       </div>
                       <div class="col-3 px-3">
                       <Button className='fiter-btn-coupon'>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                       </div>
                    </div>
                </div>

                </div>
            <div className='p-0'>
                <Tabs
                defaultActiveKey="Coupon"
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={handleActiveTab}
                >
                <Tab eventKey="Coupon" title="Coupon">
                    <div>
                      <Table responsive>
                      <thead className='admin-coupon-list-head'>
                      <tr>
                        <th className='admin-tabel-heading'>
                        <input class="form-check-input" type="checkbox" checked={selectallCheck} onClick={(e)=>selectAllCheckBox(e)} />
                        </th>
                        <th className='admin-tabel-heading'>
                        Code
                        </th>
                        <th className='admin-tabel-heading'>
                        Name
                        </th>
                        <th className='admin-tabel-heading'>
                        Type
                        </th>
                        <th className='admin-tabel-heading'>
                        Provider
                        </th>
                        <th className='admin-tabel-heading'>
                        Usage limit
                        </th>
                        <th className='admin-tabel-heading'>
                        Status
                        </th>
                        <th>

                      </th>
                      </tr>
                      </thead>
                      <tbody className='admin-tabel-data'>
                      {
                                    state && state.data.length > 0 ?
                                        (
                                            state.data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td scope="row"><input class="form-check-input" type="checkbox" value={item?._id} checked={item.isChecked} onChange={(e)=>onCheckboxBtnClick(item, e)}/></td>
                                                        <td class="sss">{item?.coupon_code_admin || item?.coupon_code_business}</td>
                                                            <td class="sss">{item.coupon_name}</td> 

                                                        
                                                        <td>{item.coupon_type}</td>
                                                        <td>{item?.serviceprovider.length}</td>
                                                        <td>{item.time_limit_type}</td>
                                                        {
                                                            item?.is_active == true ? (
                                                                <td className='status-active'>Active</td>
                                                                ) :
                                                                (
                                                                    <td>In active</td>
                                                                )
                                                        }

                                                        
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                <Dropdown.Item onClick={() => handleEditChange(item._id)}>Edit</Dropdown.Item>

                                                                    <Dropdown.Item onClick={() => handleDeleteChange(item._id)}>Delete</Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                          null
                                        )

                                }
                      
                      </tbody>

                      </Table>
                      
                    </div>
                    {
                        state?.data?.length < 1 && (
                            <div className="mt-5">
                            <h6 className='text-center'>No coupons Found</h6>
                        </div>
                        )
                      }
                    

{
    state?.total > 1 && (
        <div className='mt-5'>
        <ReactPaginate 
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            pageCount={state?.total}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={'pagination justify-content-center pagination-box'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={page}
           />
         </div>
    )
}
       

                   
                </Tab>


                <Tab eventKey="Coupon Used Count" title="Coupon Used Count">
                    <>
                    <UsedCouponList search={couponCountSearch} />                    
                    </>
                </Tab>

                <Tab eventKey="Grouping" title="Grouping">
                    <>
                    <GroupingUsers />                   
                    </>
                </Tab>
                
                <Tab eventKey="Referral" title="Referral">
                    <>
                    {/* <div className="mt-5 justify-content-center">
                                                No referral Found
                    </div> */}
                    <ReferralList search={referralSearch} />
                    
                    </>
                </Tab>

                </Tabs>
            </div>

          
            {/* <Modal  centered size="md" show={DeleteConform} onHide={()=>setDeleteConform(false)}>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    <h6>Delete Coupons</h6>
                                    <p>Are you sure you want to Delete?</p>
                                    </Modal.Title>
                                    <Modal.Body>
                                          <Row className='p-3'>
                                            <Col>
                                             <Button className='image-delete-cancel' onClick={()=>{setDeleteConform(false)}}>Cancel</Button>
                                            </Col>
                                            <Col>
                                            <Button onClick={()=>setDeleteConform(false)} className='image-delete-delete'>Delete</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>

            </Modal> */}


        </div>
        </div>
    )
}

export default ReferralCode