import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/elements/header/Header";
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from "formik";
import {
  Form,
  Modal,
  Alert,
  Spinner,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ImageUploder from "../../helpers/imageUploder";
import {
  businessDocValidation,
  freelancerDocValidation,
} from "../../validations/yub_schemas/docInformationValidation";
import uploadFormImg from "../../assets/images/vendor_icons/Upload-photos.svg";
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import MultiStepFormContext from "./MultiStepFormContext";
import { useDispatch, useSelector } from "react-redux";

const BusinessStep3 = () => {
  const [value, setValue] = useState("");
  const iconStyle = { color: "#FFF", fontSize: "1em" };

  const [kycPath, setKycPath] = useState("business/kyc/");

  const { step3Details, setStep3Details, next, prev } =
    useContext(MultiStepFormContext);

  const { vendor, isVendorLoggedIn, vendorType } = useSelector(
    (state) => state.business
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      business_store_image_url: step3Details.business_store_image_url || "",
      business_store_logo_image_url:
        step3Details.business_store_logo_image_url || "",
      // pan_no: step3Details.pan_no || "",
      // pan_no_image_url: step3Details.pan_no_image_url || "",
      trade_licence: step3Details.trade_licence || "",
      trade_licence_url: step3Details.trade_licence_url || "",
      emirates_id: step3Details.emirates_id,
      emirates_id_url: step3Details.emirates_id_url,
      aadhaar_no: step3Details.aadhaar_no || "",
      aadhaar_no_image_url: step3Details.aadhaar_no_image_url || "",
      // gst_no: step3Details.gst_no || "",
    },
    validationSchema:
      vendorType === "business"
        ? businessDocValidation
        : freelancerDocValidation,
    onSubmit: (values) => {
      setStep3Details(values);
      next();
    },
  });

  const onBack = () => {
    prev();
  };

  const onFileChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="businessStep3 mt-5">
      <Container>
        <Row>
          <Col md={12} sm={12}>
            <Form
              noValidate
              className="business-register-form text-start px-5"
              onSubmit={formik.handleSubmit}
            >
              <FormikProvider value={formik}>
                {vendorType && vendorType === "business" ? (
                  <>
                    <div className="step-one-title mb-4">
                      <h4>Upload Business Logo *</h4>
                      <p>Upload your brand logo</p>
                    </div>

                    <div className="logo_url">
                      <ImageUploder
                        name="business_store_logo_image_url"
                        onChange={onFileChange}
                        value={formik.values.business_store_logo_image_url}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="business_store_logo_image_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>

                    <div className="step-one-title mb-4">
                      <h4>Upload Business Image *</h4>
                      <p>
                        Please upload atleast one facade shot (picture of the
                        Business front)
                      </p>
                    </div>

                    <div className="business_image_url">
                      <ImageUploder
                        name="business_store_image_url"
                        onChange={onFileChange}
                        value={formik.values.business_store_image_url}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="business_store_image_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>

                    <div className="step-one-title">
                      <h4>Upload Commercial Registration Number Details *</h4>
                      <p>Please upload Commercial Registration Number image</p>
                    </div>

                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group className="mb-2 py-2" controlId="">
                          <Form.Label>
                            Commercial Registration Number{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Commercial Registration Number"
                            name="trade_licence"
                            onChange={formik.handleChange}
                            value={formik.values.trade_licence}
                            isInvalid={
                              !!formik.touched.trade_licence &&
                              !!formik.errors.trade_licence
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.trade_licence}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="trade_licence_url">
                      <ImageUploder
                        name="trade_licence_url"
                        onChange={onFileChange}
                        value={formik.values.trade_licence_url}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="trade_licence_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>
                    <div className="step-one-title">
                      <h4>Upload Civil Id Details *</h4>
                      <p>Please upload Civil Id image</p>
                    </div>

                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group className="mb-2 py-2" controlId="">
                          <Form.Label>Sponsor Civil ID number as per Commercial Registration </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Civil ID"
                            name="emirates_id"
                            onChange={formik.handleChange}
                            value={formik.values.emirates_id}
                            isInvalid={
                              !!formik.touched.emirates_id &&
                              !!formik.errors.emirates_id
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.emirates_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="emirates_id_url">
                      <ImageUploder
                        name="emirates_id_url"
                        onChange={onFileChange}
                        value={formik.values.emirates_id_url}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="emirates_id_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>
                    {/* <div className="step-one-title">
                                                <h4>Upload PAN Card Details *</h4>
                                                <p>Please upload Pan card image</p>
                                            </div>

                                            <Row className="mb-4">
                                                <Col md={12}>
                                                    <Form.Group className="mb-2 py-2" controlId="panNo">
                                                        <Form.Label>PAN Number </Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="PAN number"
                                                            name="pan_no"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.pan_no}
                                                            isInvalid={!!formik.touched.pan_no && !!formik.errors.pan_no}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.pan_no}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                            </Row> */}

                    {/* <div className="pan_image_url">
                                                <ImageUploder
                                                    name="pan_no_image_url"
                                                    onChange={onFileChange}
                                                    value={formik.values.pan_no_image_url}
                                                    filePath={kycPath}
                                                >
                                                </ImageUploder>
                                                <ErrorMessage name="pan_no_image_url" render={msg => <div className="text-danger">{msg}</div>} />
                                            </div> */}

                    {/* <div className="step-one-title">
                                                <h4 className="">GST Details</h4>
                                                <p>Please enter Business GST Number</p>
                                            </div>
                                            <Row className="mb-4">
                                                <Col md={12}>
                                                    <Form.Group className="mb-2 py-2" controlId="panNo">
                                                        <Form.Label>GST Number </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="GST No"
                                                            name="gst_no"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.gst_no}
                                                            isInvalid={!!formik.touched.gst_no && !!formik.errors.gst_no}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.gst_no}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                            </Col>
                                            </Row> */}
                  </>
                ) : (
                  <>
                    <div className="step-one-title">
                      <h4>Upload PAN Card Details</h4>
                      <p>Please upload Pan card image</p>
                    </div>

                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group className="mb-3 py-2" controlId="panNo">
                          <Form.Label>PAN number </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="PAN number"
                            name="pan_no"
                            onChange={formik.handleChange}
                            value={formik.values.pan_no}
                            isInvalid={
                              !!formik.touched.pan_no && !!formik.errors.pan_no
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.pan_no}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="pan_image_url">
                      <ImageUploder
                        name="pan_no_image_url"
                        onChange={onFileChange}
                        value={formik.values.pan_no_image_url}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="pan_no_image_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>
                    <div className="step-one-title">
                      <h4>Upload Adhaar Card Details</h4>
                      <p>Please upload Adhaar card image</p>
                    </div>
                    <Row className="mb-4">
                      <Col md={12}>
                        <Form.Group className="mb-3 py-2" controlId="panNo">
                          <Form.Label>Aadhar number </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="aadhar number"
                            name="aadhaar_no"
                            onChange={formik.handleChange}
                            value={formik.values.aadhaar_no}
                            isInvalid={
                              !!formik.touched.aadhaar_no &&
                              !!formik.errors.aadhaar_no
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.aadhaar_no}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="aadhaar_image_url">
                      <ImageUploder
                        value={formik.values.aadhaar_no_image_url}
                        name="aadhaar_no_image_url"
                        onChange={onFileChange}
                        filePath={kycPath}
                      ></ImageUploder>
                      <ErrorMessage
                        name="aadhaar_no_image_url"
                        render={(msg) => (
                          <div className="text-danger">{msg}</div>
                        )}
                      />
                    </div>
                  </>
                )}
                <div className="text-center my-5">
                  <Row>
                    <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                      <button
                        class="back-btn-businessSteps"
                        size="lg"
                        onClick={onBack}
                      >
                        Back
                      </button>
                    </Col>
                    <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                      <Button
                        variant=""
                        className="next-btn-businessSteps"
                        type="submit"
                        size="lg"
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </div>
              </FormikProvider>
            </Form>
          </Col>
          {/* <Col md={4} sm={12}>
                        <div className="form-image-container mt-5">
                            <img className="img-fluid" src={uploadFormImg} />
                        </div>
                    </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default BusinessStep3;
