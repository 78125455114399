import React, {useState} from 'react';
import { BsCartCheck, BsCartDash, BsCartPlus, BsCartX, BsCash, BsScissors, BsFillCalendar3WeekFill } from "react-icons/bs";
import { FaStore } from "react-icons/fa";
import { IoStorefrontSharp } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import {Row, Col, Card, Modal, Button} from 'react-bootstrap';
import { BiRupee } from "react-icons/bi";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from "moment";


function AdminHome(props) {

    const businessCardData = [
        {
            header: "Total Orders",
            icon: <BsCartPlus />,
            value: "50",
            color: "#bbc0c4",
        },
        {
            header: "Pending",
            icon: <BsCartDash />,
            value: "25",
            color: "#FFF380",
        },
        {
            header: "Completed",
            icon: <BsCartCheck />,
            value: "10",
            color: "#66D4A1",
        },
        {
            header: "Canceled",
            icon: <BsCartX />,
            value: "15",
            color: "#FFB4C4",
        }
    ]

    const businessCardData2 = [
        {
            header: "Revenue",
            icon: <BsCash />,
            value: props.status?.revenue?.count || 0,
            valueIcon : <BiRupee />,
            color: "#eeeefa",
        },
        {
            header: "Users",
            icon: <FiUsers />,
            value: props.status?.user?.count || 0,
            color: "#eeeefa",
        },
        {
            header: "Business",
            icon: <IoStorefrontSharp />,
            value: props.status?.business?.count || 0,
            color: "#eeeefa",
        },
        {
            header: "Freelancers",
            icon: <FaStore />,
            value: props.status?.freelancer?.count || 0,
            color: "#eeeefa",
        },
    ]
    const [show, setShow] = useState(false);
    const [stateDate, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
    const dateRange = () => {

      let data = {
        start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
        end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
      }
      props.dashboardFilter({...data});
    }
  

    return (
        <div className='container-xxl'>
            <div className="dashboard-home-content mt-3 ">
                <Row className='mb-5 mt-4'>
                    <Col md={6} onClick={()=> setShow(false)}>
                    <h3>
                        Welcome Back !
                    </h3>
                    </Col>
                    <Col md={6}>
                    <h4 className='date-right'>
                        {/* {new Date().toLocaleDateString()} &nbsp; */}
                        <span>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <span className="cur-pointer" onClick={()=> setShow(true)}>
                   <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                   <span className='mx-2'>to</span>
                   <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.endDate).format("MMM D, YYYY")}</span>
                  </span>
                ) : (
                  <span className='cur-pointer' onClick={()=> setShow(true)}>
                  <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                  </span>
                )
              }
            </span>
                        <span className="provider-name-dashboard  mx-3">
                        <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
                        </span>
                        <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <div className='calendar-view'>            
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>

            </Modal>

                    </h4>
                    </Col>

                </Row>
                
          
            <div className="home-cards mb-5">
                <div className="">
                <Row>
                    {
                        businessCardData2.map((item, index) => {
                            return (
                                
                               
                                    <Col md={3}>
                                       
                                    <div key={index}>
                                    <div className="text-start my-3">
                                        
                                        <h4> {item.header} </h4>
                                    </div>
                                    <Card className='admin-dashboard-card'>
                                    <div>
                                        <div>
                                            <Card.Title>
                                            <div class="icon-header"> {item.icon} &nbsp; {item.header}</div>
                                            </Card.Title>
                                            <Card.Body>
                                            <div className="home-card-value" >
                                            <p className="rupee-icon">{item.valueIcon}</p>
                                                <h2>
                                                    {item.value}
                                                </h2>
                                            </div>
                                            </Card.Body>
                                           
                                        </div>


                                    </div>
                                    </Card>
                                </div>
                                
                                    </Col>
                                

                            );
                        })
                    }
                    </Row>
                </div>
            </div>

            
        </div>
        </div>
    )
}

export default AdminHome;