export const ADD_SERVICE_TO_CART = 'ADD_SERVICE_TO_CART';
export const REMOVE_SERVICE_FROM_CART = 'REMOVE_SERVICE_FROM_CART';
export const ADD_SERVICE_QTY_TO_CART = 'ADD_SERVICE_QTY_TO_CART';
export const REMOVE_SERVICE_QTY_FROM_CART = 'REMOVE_SERVICE_QTY_FROM_CART';
export const CHANGE_SERVICE_PROVIDER_DETAILS = 'CHANGE_SERVICE_PROVIDER_DETAILS';
export const CLEAR_SERVICE_PROVIDER_DETAILS = 'CLEAR_SERVICE_PROVIDER_DETAILS';
export const CLEAR_CART_DETAILS = 'CLEAR_CART_DETAILS';

export const ADD_MANY_SERVICES_TO_CART = 'ADD_MANY_SERVICES_TO_CART';

export const GET_ALL_SLOT_DETAILS = 'GET_ALL_SLOT_DETAILS';
export const CLEAR_SLOT_DETAILS = 'CLEAR_SLOT_DETAILS';
export const CLEAR_ALL_CART_DETAILS = 'CLEAR_ALL_CART_DETAILS';
export const CREATE_SERVICE_TO_CART_SUCCESS = 'CREATE_SERVICE_TO_CART_SUCCESS';
export const CREATE_SERVICE_TO_CART_FAIL = 'CREATE_SERVICE_TO_CART_FAIL';
export const READ_CART_SERVICE_SUCCESS = 'READ_CART_SERVICE_SUCCESS';
export const READ_CART_SERVICE_FAIL = 'READ_CART_SERVICE_FAIL';
export const DELETE_CART_ONE_SUCCESS = 'DELETE_CART_ONE_SUCCESS';
export const DELETE_CART_ONE_FAIL = 'DELETE_CART_ONE_FAIL';
export const DELETE_CART_MANY_SUCCESS = 'DELETE_CART_MANY_SUCCESS';
export const DELETE_CART_MANY_FAIL = 'DELETE_CART_MANY_FAIL';
