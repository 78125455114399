import React, { useState } from "react";
import {
    Form,
    Modal,
    Button
} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { verifiedForgotPassword, logoutFromLocalStorage, ChangePasswordAction, ChangeEmailAction } from '../../../redux/auth/actions';
import { resetPasswordValidation } from "../../../validations/yub_schemas/resetPasswordValidation";
import { useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { changeEmailValidation } from "../../../validations/yub_schemas/changeEmailValidation";

const ChangeEmailUser = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const [eyeState, setEyeState] = useState(false)


    const formik = useFormik({
        initialValues: {
            email_id: '',
            password: '',

        },
        validationSchema: changeEmailValidation,
        onSubmit: (values) => {
            const data = {
                id: user?.user_id || user?._id,
                new_email: values?.email_id,
                current_pass: values?.password,
                
            };

            dispatch(ChangeEmailAction(data))
                .then(() => {
                    formik.resetForm();
                    props.onHide(true);
                    props.setverifyemailcode();
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        },
    });

    const Eyeview = () => {
        setEyeState(prevState => !prevState)
    }
    return (
        <div className="new-password">
            <Modal
                // {...props}
                show={props.show}
                onHide={props.onHide}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="m-0 p-0">
                    <div className="text-center">
                        {/* <img className="logo"
                            src={logo}
                            width="182.46px"
                            height="40px"
                            alt="Slaylewks logo" /> */}

                        <h4 className="register-header">Change Email</h4>
                        <div className="m-0 p-0">
                            <h6><small className="text-muted p-0 m-0">Please enter your Email Id.</small></h6>
                        </div>
                    </div>

                    <div>
                        <Form className="register-form" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>

                                <Form.Group className="px-2" controlId="formBasicPassword">
                                <Form.Control
                                className="mb-3"
                                        type="email"
                                        placeholder="New Email id"
                                        name="email_id"
                                        onChange={formik.handleChange}
                                        value={formik.values.email_id}
                                        isInvalid={!!formik.touched.email_id && !!formik.errors.email_id}
                                    />
                                   <Form.Control.Feedback type="invalid" className="mt-2 mb-3">
                                        {formik.errors.email_id}
                                    </Form.Control.Feedback>

                                    
                                    <Form.Control
                                        type={eyeState ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                    />
                                    <span className="reset-eye-icon" onClick={Eyeview}>
                                        {eyeState ? <AiOutlineEye /> :
                                            <AiOutlineEyeInvisible />
                                        }
                                    </span>
                                    <Form.Control.Feedback type="invalid" className="mt-0 mb-3">
                                        {formik.errors.password}
                                    </Form.Control.Feedback>
                                     
                                    
                                </Form.Group>

                               

                                <div className="text-center d-grid gap-2">
                                    <Button className="register-button" type="submit" size="lg">
                                        Verify
                                    </Button>
                                </div>
                            </FormikProvider>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ChangeEmailUser;