import * as TYPES from './types.js';

const initialState = {
    dashboardType: "",
    dashboardStatus: {},
    services: [],
    orders: [],
    appointments: [],
    staffs: [],
    settings: {},
    users: [],
    proposals: [],
    business: [],
    freelancer: [],
    adminDashboardStatus: []
};


const dashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.CHANGE_DASHBOARD_STATUS:
            return {
                ...state,
                dashboardStatus: payload,
            };
        case TYPES.CHANGE_DASHBOARD_STATUS_FAIL:
            return {
                ...state,
                dashboardStatus: {},
            };
        case TYPES.CHANGE_DASHBOARD_TYPE:
            return {
                ...state,
                dashboardType: payload,
            };
        case TYPES.GET_ALL_SERVICE_DETAILS_SUCCESS:
            return {
                ...state,
                services: payload,
            };
        case TYPES.GET_ALL_SERVICE_DETAILS_FAIL:
            return {
                ...state,
                services: [],
            };
        case TYPES.GET_ALL_APPOINTMENT_DETAILS_SUCCESS:
            return {
                ...state,
                appointments: payload,
            };
        case TYPES.GET_ALL_APPOINTMENT_DETAILS_FAIL:
            return {
                ...state,
                appointments: [],
            };
        case TYPES.GET_ALL_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                orders: payload,
            };
        case TYPES.GET_ALL_ORDER_DETAILS_FAIL:
            return {
                ...state,
                orders: [],
            };
        case TYPES.GET_ALL_STAFF_DETAILS_SUCCESS:
            return {
                ...state,
                staffs: payload,
            };
        case TYPES.GET_ALL_STAFF_DETAILS_FAIL:
            return {
                ...state,
                staffs: [],
            };
        case TYPES.GET_ALL_DASHBOARD_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: payload,
            };
        case TYPES.GET_ALL_DASHBOARD_SETTINGS_FAIL:
            return {
                ...state,
                settings: {},
            };
        case TYPES.GET_ALL_PROPOSAL_DETAILS_SUCCESS:
            return {
                ...state,
                proposals: payload,
            };
        case TYPES.GET_ALL_PROPOSAL_DETAILS_FAIL:
            return {
                ...state,
                proposals: [],
            };
        case TYPES.GET_ALL_USER_DETAILS_SUCCESS:
            return {
                ...state,
                users: payload,
            };
        case TYPES.GET_ALL_USER_DETAILS_FAIL:
            return {
                ...state,
                users: [],
            };
        case TYPES.GET_ALL_BUSINESS_DETAILS_SUCCESS:
            return {
                ...state,
                business: payload,
            };
        case TYPES.GET_ALL_BUSINESS_DETAILS_FAIL:
            return {
                ...state,
                business: [],
            };
        case TYPES.GET_ALL_FREELANCER_DETAILS_SUCCESS:
            return {
                ...state,
                freelancer: payload,
            };
        case TYPES.GET_ALL_FREEALNCER_DETAILS_FAIL:
            return {
                ...state,
                freelancer: [],
            };
        default:
            return state;
    }
};

export default dashboardReducer;
