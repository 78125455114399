import React from 'react';
import CouponCodeList from './CouponCodeList';
import DashboardHeader from '../DashboardHeader';
// import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import BusinessSidebar from '../../../components/elements/siderbar';
import { Row, Col } from 'react-bootstrap';


function BusinessMarketing() {
    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <Row>
                        <Col className = "p-0" md={2}>
                        <div className="sidebar-content">
                        <BusinessSidebar />
                    </div>
                        </Col>
                        <Col md={10}>
                        <div className="main-content">
                        <CouponCodeList />
                    </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default BusinessMarketing