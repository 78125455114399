import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spinner } from "react-bootstrap";
import { BiCloudUpload, BiUpload } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { Button } from "react-bootstrap";
import { fileUpload } from '../redux/business/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import uploadImage from "../assets/images/upload-list-image-card.svg"


const ImageUploder = (props) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // state for storing actual image
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage

  const dispatch = useDispatch();

  useEffect(() => {
    setPreviewSrc(props.value);
    setIsPreviewAvailable(true);
  }, [props.value])

  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
  const dropRef = useRef(); // React ref for managing the hover state of droppable area

  const iconStyle = { color: "#ECB390", fontSize: "7.5em" };
  const uploadIconStyle = { fontSize: "1em" };
  const deleteIconStyle = { fontSize: "1em" };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setPreviewSrc('')
    if(acceptedFiles.length===0) {
      toast.warn('Please upload a valid file');
    }
    const [uploadedFile] = acceptedFiles;
    setFile(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png|svg|pdf)$/));
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/jpg, image/png, image/jpeg, application/pdf",
    minSize: 0,
    maxSize: 6291456
  })

  const onDrop1 = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png|pdf)$/));
    dropRef.current.style.border = '2px dashed #e9ebeb';
  };

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  const uploadFile = () => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', props.filePath);

      dispatch(fileUpload(formData))
        .then((res) => {
          setLoading(false)
          props.onChange(props.name, res.data.location);
        }).catch((err) => {
          setLoading(false)
          console.log("Error => ", err);
        })
    } else {
      console.log('Please select a file to add.');
    }
  }

  const deleteFile = () => {
    setFile(null);
    setPreviewSrc("")
    setIsPreviewAvailable(false)

  }

  return (
    <>
      <div className="image-uploader" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="uploader-icon">
          <img src={uploadImage} />
        </div>
        <br />
        <div className='uploader-text'>
          <h6>Click or drag file to this area to upload</h6>
          <p className="text-muted">
            Support for a single upload. Maximum file size 5MB.
          </p>
        </div>
      </div>
      <div className="upload-section mt-5">
        {previewSrc && (
          isPreviewAvailable ? (
            <div className="image-preview">
              <img className="preview-image" src={previewSrc} alt="Preview" />
            </div>

          ) : (
            <div className="preview-message">
              <p>No preview available for this file</p>
            </div>
          )
        )}
      </div>
      {
        file && file !== "" && (
          <div className="my-5">
            <strong>Selected file:</strong> {file.name} 
           
            <span className="ms-12">
            <p>Upload</p>
              <Button variant="outline-primary" className="me-3" size="sm" onClick={uploadFile}>
                {
                  loading ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <BiUpload style={uploadIconStyle} />
                  )
                }
              </Button>
              <p>Cancel</p>
              <Button variant="outline-danger" size="sm" onClick={deleteFile}>
                <TiDeleteOutline style={deleteIconStyle} />
              </Button>
            </span>
            <div>
              <p className="text-muted mt-2">
                <span className="me-1"><BsInfoCircle /></span> After preview, Please upload or remove Document
              </p>
            </div>
          </div>
        )
      }

    </>
  )
}

export default ImageUploder