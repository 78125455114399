import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Container, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getUserFavoriteServiceAction, getUserProfileUpdateAction } from '../../redux/user/actions';
import { useNavigate } from "react-router-dom"
import { getUserFavoritesAction } from "../../redux/user/actions";
import {AiFillHeart} from 'react-icons/ai';
import { BsFillStarFill } from "react-icons/bs";



function FavoriteService() {

    const navigate = useNavigate();

    const [state, setState] = useState({})
    const { user, isLoggedIn, needToLogin } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    

    useEffect(()=>{
        const data = {
            user: user?._id,
          };
        dispatch(getUserFavoriteServiceAction(data))
        .then((res)=>{
            const {favList} = res
            setState((prev)=>({
                ...prev,
                data: favList
            }))
        })
    }, [])
    

    const redirectDetailsPage = (id) => {
      navigate(`/business/${id}`)
  }
  const getUpdatedFavoriteList = async() => {
    const data = {
      user: user?._id,
    };
    await dispatch(getUserFavoriteServiceAction(data)).then((res)=>{
      const {favList} = res
      setState((prev)=>({
        ...prev,
        data: favList
    }))
    })

  }

  const handelUnFavorites = async (item) => {
    const data = {
        id: item?._id,
        type: "dislike",
        
    }
    await dispatch(getUserProfileUpdateAction(data))
    getUpdatedFavoriteList()
   }

   const explorePageRedirect = () => {
    navigate("/explore")
}

 
  return (
    <div>
      <Container>
        <div className='fav-section'>
          <h1>Favorites</h1>
        </div>
     {
         state?.data?.length > 0 ? (
          <>
         <div>
          {
           state?.data?.map((item, index)=>{
             return (
               <>
               <Card className='fav-list-card' key={index}>
                 <div className='fav-list-inner'>
                   <div className='img-container'>
                   <img className='fav-business-images' src={item?.serviceprovider.businesslogoURL} />
                   </div>
                   <div className='info-container'>
                   
                  
                    
                    
              
              
                   <Card.Title className='mt-0' onClick={() => redirectDetailsPage(item?.serviceprovider?._id)}>
                  <>
                  <Badge bg="" className='review-content-profile'>
                        <span>
                            <BsFillStarFill className='single-page-review-profile' />
                        </span>
                        <span>
                            <span className='single-page-review-count-profile'>
                            {item?.rating_avg}                                                            </span>
                        </span>
                    </Badge>
                    <span className='card-item-review-profile'>({item?.review_count})</span>
                  </>
                       <p className='p-0 m-0'>{item?.serviceprovider.business_name}</p>  
                       <h6 className='p-0 m-0'>{item?.serviceprovider.location_details?.city},<span>{item?.serviceprovider.location_details?.country}</span></h6>
                   </Card.Title>  
                   </div>
                   <div className='fav-container'>
                     {
                        item?.type && item?.type === "like" && (
                          <p className='profile-fav-card'>
                         <AiFillHeart alt="" className="favorite-icon-favscreen" size={18} onClick={()=> handelUnFavorites(item)} />
                         </p>
                     )
                     
                     }
                   </div>
                 </div>
                        
               </Card>
               </>
             )
           })
          }
          </div>
          </>
         ) : (
          <div className='no-Fav text-center mt-5'>
          <p>No Favorites, please add one</p>
          <a href="JavaScript:void(0);" class="cart-explore" onClick={explorePageRedirect}>Start Explore</a>
          </div>
         )
     }
          
       
       
     </Container>
    </div>
  )
}

export default FavoriteService
