import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { useFormik, FormikProvider } from 'formik';
import { staffAddValidation } from "../../../validations/yub_schemas/staffAddValidation"

function StaffUpdateDaily(props) {

    const { show, onHide, selectInfo, staffCount, updateResource, deleteResource } = props

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: staffCount?.staff_count || 1,
        },
        validationSchema: staffAddValidation,
        onSubmit: (values) => {
            updateResource({
                ...staffCount,
                staff_count: values.title
            })
            formik.resetForm();
            onHide();
        },
    });

    const deleteEvent = () => {
        deleteResource({
            id: staffCount?._id
        });
        onHide();
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-40w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="Marketing-body-content">
                    <Form className="register-form" onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <Form.Group className="mb-3 p-2" controlId="formBasicEmail">
                                <Form.Label>
                                    <h6>Please enter the staff count *</h6>
                                    <h6>You can edit or delete your the staff count</h6>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Staff count"
                                    name="title"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                    isInvalid={!!formik.touched.title && !!formik.errors.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                                <Row className="mb-5 text-center px-5 mx-5">
                                    <Col md={4} sm={4} xs={4}>
                                    <Button variant="" onClick={() => props.onHide()} size="sm" className="staff-cancel-service-btn">
                                    Cancel
                                </Button>
                                    </Col>
                                  
                                    <Col md={4} sm={4} xs={4}>
                                    <Button variant="" className='staff-delete-service-btn' size="sm" onClick={deleteEvent}>
                                    Delete
                                </Button>
                                    </Col>
                                    <Col md={4} sm={4} xs={4}>
                                    <Button variant="" className="staff-update-service-btn" type="submit" size="sm">
                                    Update
                                </Button>
                                    </Col>
                                </Row>
                              
                             
                              
                        </FormikProvider>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default StaffUpdateDaily