import React, { useEffect, useState } from 'react';
import { Badge, Container, Breadcrumb, Nav, Tab, Tabs, Form, Table } from "react-bootstrap";
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer';
import NaturalImg from "../../assets/images/natural-image.webp";
import { getUserOneOrderAction, getUserAllOrdersAction, getUserAllRequestAction } from "../../redux/user/actions"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Row, Col, Modal, Button, Card } from "react-bootstrap";
import moment from "moment";
import { SpinnerDotted } from 'spinners-react';


function MyOrdersPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [responseTime, setResponceTime] = useState(false);
    const [ordersearch, setOrdersearch] = useState("")
    const [requestSearch, setRequestsearch] = useState("");
    const [orderStatusArrFilter, setOrderStatusArrFilter] = useState([]);
    const [orderFilters, setOrderFilters] = useState("");
    const [requestFilters, setRequestFilters] = useState("");
    const [showOtp, setOtp] = useState(false);
    const [selected, setSelected] = useState("");
    const handleClose = () => setOtp(false);
    const handleShow = (service) => {
        setSelected(service)
        setOtp(true);
    }

    const { user } = useSelector(state => state.auth);


    const [orders, setOrders] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1,
        next: false
    });
    const [request, setRequest] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1,
        next: false
    });

    useEffect(() => {
        let data = {
            userid: [user?._id],
            page: orders.activePage,
            status_filter: orderFilters,
            ordersearch: ordersearch,
        }
        dispatch(getUserAllOrdersAction(data))
            .then((res) => {
                console.log(res, "orders")
                const { results, total_count, resultsPerPage, next } = res
                setOrders((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count,
                    next: next
                }))
                setResponceTime(true);
            })
            .catch(err => {
                console.log(err)
            })
    }, [orderFilters, ordersearch])

    useEffect(() => {
        let data = {
            userid: [user?._id],
            page: request?.activePage,
            status_filter: requestFilters,
            requestSearch: requestSearch,
        }
        dispatch(getUserAllRequestAction(data))
            .then((res) => {
                console.log(res, "request")
                const { results, total_count, requestCountPerPage, next } = res
                setRequest((prev) => ({
                    ...prev,
                    data: results,
                    total: total_count,
                    next: next
                }))
            })
            .catch(err => {
                console.log(err)
            })
    }, [requestSearch, requestFilters])

    const onChangeFilterOrders = (e) => {
        setOrderFilters(e.target.value);
    }
    const onChangeFilterRequest = (e) => {
        setRequestFilters(e.target.value);
    }

    const handleOrderCategoryArrFilter = (e) => {
        const { name, value, checked } = e.target;
        var gender_array = [orderStatusArrFilter];
        if (checked) {
            gender_array = [value];
        } else {
            gender_array.splice(orderStatusArrFilter.indexOf(value), 1);
        }
        setOrderStatusArrFilter(gender_array);
    }

    const handleorderViewStatus = (e) => {
        setOrdersearch(e.target.value)
    }
    const handleRequestViewStatus = (e) => {
        setRequestsearch(e.target.value)
    }

    const resetFilters = () => {
        setOrderStatusArrFilter([]);
    }

    const redirectPaymentDetails = (id, type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectToRequestView = (id) =>{
        console.log(id, "idss")
        navigate(`/orders/request/${id}`)
    }


    const redirectReschedule = (id) => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const handlePageChange = () => {
        let data = {
            userid: [user?._id],
            page: orders.activePage + 1
        }
        dispatch(getUserAllOrdersAction(data))
            .then((res) => {
                const { results, total_count, next } = res
                setOrders((prev) => ({
                    ...prev,
                    data: prev.data.concat(results),
                    total: total_count,
                    activePage: orders.activePage + 1,
                    next
                }))
            })
            .catch(err => {
                console.log(err)
            })

    }
    const handlePageChangeRequest = () => {
        let data = {
            userid: [user?._id],
            page: request.activePage + 1,
            status_filter: requestFilters,
            requestSearch: requestSearch,
        }
        dispatch(getUserAllRequestAction(data))
            .then((res) => {
                const { results, total_count, next } = res
                setOrders((prev) => ({
                    ...prev,
                    data: prev.data.concat(results),
                    total: total_count,
                    activePage: request.activePage + 1,
                    next
                }))
            })
            .catch(err => {
                console.log(err)
            })

    }
    const goToHome = () => {
        navigate("/")
    }
    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    return (
       <>
        {
            responseTime ? (
                <div className="myOrder-outer-view">

                <Header />
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={goToHome} className="subnav-list">Home</Breadcrumb.Item>
                        <Breadcrumb.Item className="subnav-list" onClick={redirectOrdersPage}>
                            My Order
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
    
    
                <Container className='mt-5'>
                    <div className='my-order-tabs'>
                    <Tabs
                        defaultActiveKey="home"
                        id="fill-tab-example"
                        className="mb-3"
    
                    >
                        <Tab eventKey="home" title="Order Details">
                            <Row className=''>
                                <Col md={12}>
                                <Row>
                                    <Col md={10} sm={8} xs={8}>
                                    <div class="">
                                        <span className='d-flex'>
                                            <span className="explore-search-icon fs-5 mt-2 mx-0">
                                                <ion-icon name="search-outline" ></ion-icon>
                                            </span>
                                            <input type="text" class="form-control exploremain-search-box" id="" placeholder="Search your order, service" name="search"
                                                value={ordersearch} onInput={handleorderViewStatus}
                                            />
                                        </span>
                                    </div>
                                    </Col>
                                    <Col md={2} sm={4} xs={4} >
                                    <Form.Select
                                name="status"
                                onChange={onChangeFilterOrders}
                                className="order-filters"
                              >
                                <option value="">Filter</option>
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                                <option value="cancelled">Cancelled</option>
                                {/* <option value="noshow">No show</option> */}
                                <option value="failed">Failed</option>
                              </Form.Select>
                                    </Col>
                                   </Row>
                                   <hr className="line-search" />
                                    {
                                        orders?.total < 1 ? (
                                            <div className='text-center'>
                                                <p className='no-result-found'> No order found</p>
                                            </div>
                                        ): (
                                            <div className='mx-3 ml-5'> 
                                                {
                                                    orders.data && orders.data.length > 0 && (
                                                        orders.data.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Card className='mb-5'>
                                                                        <Card.Header className='bg-white mx-3 py-0'>
                                                                            <Row className='p-0'>
                                                                            <Table responsive borderless  className='order-info-tabel px-0 mx-0'>
                                                                                          <tbody>
                                                                                                <tr>
                                                                                                <td>
                                                                                                <p className='myOrder-id-info'> Order Id: {item?.order_id}</p>
                                                                                                </td>
                                                                                                <td>
                                                                                        {
                                                                                            item?.order_status && (
                                                                                                <div className='text-end'>
                                                                                                    {
                                                                                                        item?.order_status === "completed" && (
                                                                                                            <h5><Badge pill bg="success" className='badge-font-all'>Order {item?.order_status}</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.order_status === "pending" && (
                                                                                                            <h5><Badge pill bg="" className='badge-font-pending'>Order {item?.order_status}</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.order_status === "cancelled" && (
                                                                                                            <h5><Badge pill bg="danger" className='badge-font-all'>Order {item?.order_status}</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.order_status === "failed" && (
                                                                                                            <h5><Badge pill bg="danger" className='badge-font-all'>Order {item?.order_status}</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.order_status === "noshow" && (
                                                                                                            <h5><Badge pill bg="danger" className='badge-font-all'>Order {item?.order_status}</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                </div>
    
                                                                                            )
                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                              
                                                                                                </tbody>
                                                                            </Table>
                                                                              
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={2} sm={4} xs={4}>
                                                                                    <img className='payment-info-image' src={item?.service_provider?.businesslogoURL} />
                                                                                </Col>
                                                                                <Col className='payment-orderInfo-details px-0' md={10} xs={8} sm={8}>
                                                                                    <h6 className='mt-2'>{item?.service_provider?.business_name}</h6>
                                                                                    <p className='mt-2'>{item?.service_provider?.location_details?.area + " " + item?.service_provider?.location_details?.city + " " + item?.service_provider?.location_details?.state}</p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <div className='myOrder-payment-info'>
                                                                                    <Table responsive borderless className='order-info-tabel'>
                                                                    <tbody>
                                                                                        
                                                                       
                                                                        {
                                                                            item?.service_provider?.vendor_type === "business" && (
                                                                                <>
                                                                                    {
                                                                                        item?.service_type === "bridal" && (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td><p>Payment 1 Status</p></td>
                                                                                                    <td className='text-end'>
                                                                                                        {
                                                                                                            item?.payment?.[0]?.status && item?.payment?.[0]?.status === "complete" ? (
                                                                                                                <h3 className='payment-completed-order'>Payment 1 Completed</h3>
                                                                                                            ) : (
                                                                                                                <a href={item?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(item?.amount_to_pay_1.toFixed(2))} ₹</a>
                                                                                                            )
                                                                                                        }
    
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    {
                                                                                                        item?.payment?.[0]?.status && item?.payment?.[0]?.status === "complete" ? (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item?.payment?.[1].status === "complete" ? (
                                                                                                                        <>
                                                                                                                            <td><p>Payment 2 Status</p></td>
                                                                                                                            <td className='text-end'>
                                                                                                                                <h3 className='payment-completed-order'>Payment 2 completed</h3>
                                                                                                                            </td>
                                                                                                                        </>
    
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <td><p>Payment 2 Status</p></td>
                                                                                                                            <td className='text-end'>
                                                                                                                                <a href={item?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(item?.amount_to_pay_2.toFixed(2))} ₹</a>
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
    
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <td><p>Payment 2 Status</p></td>
                                                                                                                <td className='text-end'>
                                                                                                                    <h6 className='click-to-pay-in-active'>Click to pay {(item?.amount_to_pay_2.toFixed(2))} ₹</h6>
                                                                                                                </td>
    
                                                                                                                {/* {
                                                                                                                    item?.payment?.[1].status === "complete" ? (
                                                                                                                        <>
                                                                                                                            <td><p>Payment 2 Status</p></td>
                                                                                                                            <td className='text-end'>
                                                                                                                                <p className='text-end'>Payment 2 completed</p>
                                                                                                                            </td>
                                                                                                                        </>
    
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <td><p>Payment 2 Status</p></td>
                                                                                                                            <td className='text-end'>
                                                                                                                                <a href={item?.payment_link} target="blank" className='click-to-pay-active'>click to pay {(item?.amount_to_pay_2 / 100)} ₹</a>
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                } */}
                                                                                                            </>
                                                                                                        )
                                                                                                    }
    
                                                                                                </tr>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
    
                                                                        {
                                                                            item?.service_provider?.vendor_type === "freelancer" && (
                                                                                <>
                                                                                    {
                                                                                        item?.service_type === "bridal" ? (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td><p>Payment 1 Status</p></td>
                                                                                                    <td className='text-end'>
                                                                                                        {
                                                                                                            item?.payment?.[0]?.status && item?.payment?.[0]?.status === "complete" ? (
                                                                                                                <h3 className='payment-completed-order'>Payment 1 Completed</h3>
                                                                                                            ) : (
                                                                                                                <a href={item?.payment_link} target="blank"className='click-to-pay-active'>Click to pay {(item?.amount_to_pay_1.toFixed(2))} ₹</a>
                                                                                                            )
                                                                                                        }
    
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p>Payment 2 Status</p></td>
                                                                                                    <td className='text-end'>
                                                                                                    {
                                                                                                            item?.payment?.[1]?.status && item?.payment?.[1]?.status === "complete" ? (
                                                                                                                <h3 className='payment-completed-order'>Payment 2 Completed</h3>
                                                                                                            ) :
                                                                                                            
                                                                                                                item?.payment?.[0]?.status === "complete"?(
                                                                                                                    <a href={item?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(item?.amount_to_pay_2.toFixed(2))} ₹</a>

                                                                                                                ):(
                                                                                                                    <a href="" target="blank" className='click-to-pay-in-active'>Click to pay {(item?.amount_to_pay_2.toFixed(2))} ₹</a>

                                                                                                                )
                                                                                                            
                                                                                    
                                                                                                        }
                                                                                                        {/* <a href={item?.payment_link} target="blank" className='click-to-pay-in-active'>Click to pay {(item?.amount_to_pay_2.toFixed(2))} ₹</a> */}
    
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <tr>
                                                                                                    {
                                                                                                    //     item?.payment_id !== "" ?
                                                                                                    //     (
                                                                                                    //             <>
                                                                                                    //             <td><p>Payment Status</p></td>
                                                                                                    // <td className='text-end'>
    
                                                                                                    // <h3 className='payment-completed-order'>Payment Completed</h3>
                                                                                                    // </td>
                                                                                                    //             </>
                                                                                                    //     ): (
                                                                                                    //         <>
                                                                                                    //         <td><p>Payment Status</p></td>
                                                                                                    // <td className='text-end'>
    
                                                                                                    //     <a href={item?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(item?.final_price)} ₹</a>
                                                                                                    // </td>
                                                                                                    //         </>
                                                                                                    //     )

                                                                                                    item?.payment_id !== "" && item?.payment_status !=="complete" ?
                                                                                                    (
                                                                                                        <>
                                                                                                        <td><p>Payment Status</p></td>
                                                                                                <td className='text-end'>

                                                                                                    <a href={item?.payment_link} target="blank" className='click-to-pay-active'>Click to pay {(item?.final_price)} ₹</a>
                                                                                                </td>
                                                                                                        </>
                                                                                                    ): (
                                                                                                        <>
                                                                                                        <td><p>Payment Status</p></td>
                                                                                            <td className='text-end'>

                                                                                            <h3 className='payment-completed-order'>Payment Completed</h3>
                                                                                            </td>
                                                                                                        </>
                                                                                                        
                                                                                                    )
                                                                                                    }
                                                                                                    
                                                                                                </tr>
                                                                                            </>
    
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
    
                                                                        {
                                                                            item?.service_provider?.vendor_type === "business" && (
                                                                                <>
                                                                                    {item?.service_type === "basic" && (
                                                                                        <tr>
                                                                                            <td>
                                                                                               <>
                                                                                               
                                                                                        {
                                                                                            item?.payment_status === "failed" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-danger'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment Failed</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "success" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-success'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment success</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "created" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-secondary'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment created</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "pending" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-warning'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment pending</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "complete" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-success'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment Completed</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                               </>
                                                                                            </td>
                                                                                            <td>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </>
                                                                            )
                                                                        }
    
    
                                                                    </tbody>
                                                                </Table>
    
                                                                                        <Table responsive borderless  className='order-info-tabel'>
                                                                                          <tbody>
                                                                                            {/* <tr>
                                                                                                <td><p>Payment 1 pending</p></td>
                                                                                                <td className='text-end'>
                                                                                                <a href={item?.payment_link} target="blank" className='text-end'>click to pay 2000 ₹</a>
    
                                                                                                </td>
                                                                                            </tr> */}
                                                                                            {/* <tr>
                                                                                                <td><p>Payment 1 pending</p></td>
                                                                                            </tr> */}
                                                                                                <tr>
                                                                                                <td><p>Appointment Date and Time</p></td>
                                                                                                <td>
                                                                                                <p className='text-end'>{moment(item?.appointment?.appointment_start_dateTime).local().format("DD MMM YYYY, hh:mm A")}</p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td><p>Price</p></td>
                                                                                                <td>
                                                                                                <h6 className='text-end'>{Math.round(item?.final_price)} ₹</h6>
                                                                                                </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td><p>Service type</p></td>
                                                                                                <td>
                                                                                            <p className='text-end'>{item?.service_type}</p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td>
                                                                                                <p>Total Service</p>
    
                                                                                                </td>
                                                                                                <td>
                                                                                                <p className='text-end'>{item?.services?.length}</p>
                                                                                                 </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                        </Table>
                                                                                        
                                                                                    </div>
                                                                                </Col>
                                                                                {/* <Col>
                                                                                    <div className='text-end'>
                                                                                        <div className='myOrder-payment-info'>
                                                                                            <a href={item?.payment_link} target="blank">click to pay 2000 ₹</a>
                                                                                            <p>click to pay 2000 ₹</p>
                                                                                            <h6>{item?.final_price} ₹</h6>
                                                                                            <p>{moment(item?.appointment?.appointment_start_dateTime).local().format("DD MMM YYYY, hh:mm A")}</p>
                                                                                            <p>Shop service</p>
                                                                                            <p>{item?.total_services}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col> */}
                                                                            </Row>
    
                                                                        </Card.Body>
    
                                                                        <Card.Footer className='bg-white mx-3 py-0'>
                                                                            <Row className='mt-3'>
                                                                                <Col>
                                                                                    <p className='myOrder-verification-otp'>Verification OTP: {item?.order_OTP}</p>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className='text-end'>
                                                                                        <p className='myOrder-more-info' onClick={() => redirectPaymentDetails(item?._id, "view")}>View more details</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
    
    
                                            </div>
                                        )
                                    }
    
                                    {/* {
                                        request?.data < 1 && (
                                            <div className='text-center'>
                                                <p className='no-result-found'> No order found</p>
                                            </div>
                                        )
                                    } */}
    
                                </Col>
                            </Row>
                            <div className='mb-5'>
                             
                                                        {
                                                            orders?.next && (
                                                                <div class="loadmore-btn">
                                                                    <button class="load-btn" onClick={handlePageChange}>Load more</button>
                                                                </div>
                                                            )
                                                        }
                                                    
                                  
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Request Details">
                            <Row>
                               
                                <Col md={12}>
                                   <Row>
                                    <Col md={10} sm={8} xs={8}>
                                    <div class="">
                                        <span className='d-flex'>
                                            <span className="explore-search-icon fs-5 mt-2 mx-0">
                                                <ion-icon name="search-outline" ></ion-icon>
                                            </span>
                                            <input type="text" class="form-control exploremain-search-box" id="" placeholder="Search your order, service" name="search"
                                                value={requestSearch} onInput={handleRequestViewStatus}
                                            />
                                        </span>
                                    </div>
                                    </Col>
                                    <Col md={2} sm={4} xs={4}>
                                    <Form.Select
                                name="status"
                                className="order-filters"
                                onChange={onChangeFilterRequest}
                              > <option value="">Filter</option>
                                <option value="pending">Pending</option>
                                <option value="accept">Accepted</option>
                                <option value="deny">Rejected</option>
                                <option value="cancelled">Cancelled</option>
                              </Form.Select>
                                    </Col>
                                   </Row>
                                   <hr className="line-search" />
                                                    
                                    {
                                        request?.total < 1 ? (
                                            <div className='text-center'>
                                                <p className='no-result-found'> No Request found</p>
                                            </div>
                                        ) : (
                                            <div className='mx-3 ml-5'>
    
                                                {
                                                    request.data && request.data.length > 0 && (
                                                        request.data.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Card className='mb-5'>
                                                                        <Card.Header className='bg-white mx-3 py-0'>
                                                                            <Row className='p-0'>
                                                                            <Table responsive borderless  className='order-info-tabel px-0 mx-0'>
                                                                                          <tbody>
                                                                                                <tr>
                                                                                                <td>
                                                                                                <p className='myOrder-id-info'> Request Id: {item?.request_id}</p>
    
                                                                                                    </td>
                                                                                                <td>
                                                                                                {
                                                                                            item?.request_status && (
                                                                                                <div className='text-end'>
                                                                                                    {
                                                                                                        item?.request_status == "accept" && (
                                                                                                            <h5><Badge pill bg="" className='request-badge-accepted'>Req-accepted</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.request_status === "pending" && (
                                                                                                            <h5><Badge pill bg="" className='request-badge-pending'>Req-pending</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.request_status === "cancelled" && (
                                                                                                            <h5><Badge pill bg="" className='request-badge-cancelled'>Req-cancelled</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    {
                                                                                                        item?.request_status === "deny" && (
                                                                                                            <h5><Badge pill bg="" className='request-badge-cancelled'>Req-rejected</Badge></h5>
                                                                                                        )
    
                                                                                                    }
                                                                                                    
                                                                                                </div>
    
                                                                                            )
                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                              
                                                                                                </tbody>
                                                                            </Table>
                                                                              
                                                                            </Row>
                                                                        </Card.Header>
                                                                        <Card.Body>
                                                                            <Row className='mt-2 mb-2'>
                                                                                <Col md={2} sm={4} xs={4}>
                                                                                    <img className='payment-info-image' src={item?.service_provider?.businesslogoURL} />
                                                                                </Col>
                                                                                <Col className='payment-orderInfo-details' md={10} sm={8} xs={8}>
                                                                                    <h6 className='mt-2'>{item?.service_provider?.business_name}</h6>
                                                                                    <p className='mt-2'>{item?.service_provider?.location_details?.area + " " + item?.service_provider?.location_details?.city + " " + item?.service_provider?.location_details?.state}</p>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                    <div className='myOrder-payment-info'>
    
                                                                                        {
                                                                                            item?.payment_status === "failed" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-danger'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment Failed</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "success" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-success'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment success</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "created" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-secondary'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment created</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "pending" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-warning'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment pending</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                        {
                                                                                            item?.payment_status === "complete" && (
                                                                                                <div className='myOrder-status-card'>
                                                                                                    <span className='text-success'><ion-icon name="ellipse"></ion-icon></span>
                                                                                                    <h6>Payment Completed</h6>
                                                                                                </div>
                                                                                            )
    
                                                                                        }
                                                                                          <Table responsive borderless  className='order-info-tabel'>
                                                                                          <tbody>
                                                                                                <tr>
                                                                                                <td><p>Appointment Date and Time</p></td>
                                                                                                <td>
                                                                                                    {/* <p className='text-end'> */}
                                                                                                    {/* {item?.selected_date +" "+ item?.selected_time}</p> */}
                                                                                                    <p className='text-end'>{moment(item?.selected_date).local().format("DD MMM YYYY, hh:mm A")}</p>
    
                                                                                                    </td>
    
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td><p>Price</p></td>
                                                                                                <td>
                                                                                                <h6 className='text-end'>{Math.round(item?.final_price)} ₹</h6>
                                                                                                </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td><p>Service type</p></td>
                                                                                                <td>
                                                                                                <p className='text-end'>{item?.service_type}</p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                <td>
                                                                                                <p>Total Service</p>
    
                                                                                                </td>
                                                                                                <td>
                                                                                                <p className='text-end'>{item?.services?.length}</p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                       
                                                                                    </div>
                                                                           
                                                                            </Row>
    
                                                                        </Card.Body>
    
                                                                        <Card.Footer className='bg-white mx-3 py-0'>
                                                                            <Row className='mt-3'>
                                                                                <Col>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className='text-end'>
                                                                                        <p className='myOrder-more-info' onClick={() => redirectToRequestView(item?._id)}>View more details</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Footer>
                                                                    </Card>
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                }
    
    
                                            </div>
                                        )
                                    }
    
                                    {/* {
                                        orders?.data < 1 && (
                                            <div className='text-center'>
                                                <p className='no-result-found'> No Request found</p>
                                            </div>
                                        )
                                    } */}
    
                                </Col>
                            </Row>
                            <div className='mb-5'>
                            
                                                        {
                                                            request?.next === "true" && (
                                                                <div class="loadmore-btn">
                                                                    <button class="load-btn" onClick={handlePageChangeRequest}>Load more</button>
                                                                </div>
                                                            )
                                                        }
                                                    
    
                                        
                            </div>
                        </Tab>
                    </Tabs>
                    </div>
                </Container>
                <Footer />
            </div >
            ) : (
                <div style={{
                    width: "10%",
                    margin: "18% auto",
                  }}>
                        <SpinnerDotted
                            loading={responseTime} 
                            size="100%"
                            thickness={180}
                            speed={180}
                            color ="#ECB390"
                            secondaryColor="rgb(255,255,255)"
                   />
        
                    </div>
            )
        }
       </>
      
    )
}

export default MyOrdersPage