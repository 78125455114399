import React, { useEffect, useState } from "react";
import {

    Container,
    NavDropdown,
    Dropdown,
    Modal,
    Button,
    Navbar,
    Nav,

    Image,

} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import Login from "../auth/Login";
import Signup from "../auth/Signup";
import VerifyYourEmailCode from "../auth/VerifyYourEmailCode";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import { useSelector, useDispatch } from 'react-redux';
import { logoutFromLocalStorage } from '../../../redux/auth/actions';
import { Link, useNavigate } from 'react-router-dom';
import { BsBell, BsCart4 } from 'react-icons/bs';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ProfileImg from "../../../assets/images/profile.webp";
import AvatarImg from "../../../assets/images/avatar.webp";
import { changeVendorType, getAllVendorSearch } from "../../../redux/business/actions";
import { exploreAction, getSomeSalonsAction, clearHomeSearch } from '../../../redux/business/actions';
import VerifyForgotPasswordCode from "../auth/VerifyForgotPasswordEmail";
import LocationSearch from "../../locationSearch/locationSearch";
import { BiMessageAlt } from "react-icons/bi";
import { Offcanvas, Form } from "react-bootstrap";

const Header = () => {
    const { exploreSearch } = useSelector(state => state.business)

    const exploreServices = (e) => {
        let data = {
            user_id: "",
            page: 1,
            location_search: "",
            shopNameSearch: "",
            serviceNameSearch: "",
            services_filter: "",
            servicetypes_filter: "",
            gender_filter: "",
            service_shop_search: e.target.value,
        }
        dispatch(exploreAction(data))
    }



    const [registerModal, setRegisterModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailCode] = useState(false);
    const [forgetVerifyEmail, setForgetVerifyEmail] = useState(false)
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [locationSelect, setLocationSelect] = useState(false);

    const [businessPage, setBusinessPage] = useState("/business");

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
    const { servicesCart } = useSelector((state) => state.cart);

    const { locationDetails } = useSelector((state) => state.getlocation);

    const [activeTab, setActiveTab] = useState("");
    if (activeTab !== window.location.pathname)
        setActiveTab(window.location.pathname);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const closeLocation = () => {
        setLoading(false)
    }

    const registerChange = () => {
        if (registerModal) {
            setRegisterModal(false);
        }
        else {
            setRegisterModal(true);
        }
    }

    const loginChange = () => {
        if (loginModal) {
            setLoginModal(false);
        }
        else {
            setLoginModal(true);
            navigate("")
        }
    }

    useEffect(() => {
        if (isLoggedIn && (vendorType === "business" || vendorType === "freelancer")) {
            setBusinessPage("/business/steps")
        }
        else {
            setBusinessPage("/business")
        }
    }, [vendorType])

    const [businessType, setBusinessType] = useState("user");

    const changeBusinessType = (type) => {
        setBusinessType(type);
        dispatch(changeVendorType(type));

        if (isLoggedIn) {
            // dispatch(changeVendorType(type));
            navigate('/');
        }
        else {
            // dispatch(changeVendorType(type));
            navigate('register');
            // navigate('/business/steps');
        }
    }

    const typeUser = () => {
        changeBusinessType('user')
    }
    const typeBusiness = () => {
        changeBusinessType('business')
    }

    const typeFreelancer = () => {
        changeBusinessType('freelancer')
    }
    const emailVerificationChange = () => {
        if (verifyEmailModal) {
            setVerifyEmailCode(false);
        }
        else {
            setVerifyEmailCode(true);
        }
    }
    const forgotPasswordChange = (event) => {
        if (forgotPasswordModal) {
            setForgotPasswordModal(false);
        }
        else {
            setForgotPasswordModal(true);
        }
    }
    const resetPasswordChange = () => {
        if (resetPasswordModal) {
            setResetPasswordModal(false);
        }
        else {
            setResetPasswordModal(true);
        }
    }
    const handleLogout = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    };

    const redirectCheckoutPage = () => {
        navigate("/business/cart");
    };

    const redirectOrdersPage = () => {
        navigate("/orders");
    };

    const redirectProfilePage = () => {
        navigate(`/profile/${user?._id}`);
    }


    const profileImag = () => {
        return <Image
            src={ProfileImg}
            roundedCircle
            width="30px"
            height="30px"
        />
    }

    return (
        <div>
            <Signup
                show={registerModal}
                onHide={() => setRegisterModal(false)}
                setRegisterModal={setRegisterModal}
                loginShow={loginModal}
                onLoginHide={() => setLoginModal(true)}
                verifyEmailModal={verifyEmailModal}
                setverifyemailcode={() => setVerifyEmailCode(true)}
            />
            <Login
                show={loginModal}
                onHide={() => setLoginModal(false)}
                registerShow={registerModal}
                onRegisterHide={() => setRegisterModal(true)}
                forgotPasswordModal={forgotPasswordModal}
                setforgotpasswordmodal={() => setForgotPasswordModal(true)}
            />

            <VerifyYourEmailCode
                show={verifyEmailModal}
                onHide={() => setVerifyEmailCode(false)}
                resetPasswordModal={resetPasswordModal}
                setResetPasswordModal={() => setResetPasswordModal(true)}
                loginModal={loginModal}
                setloginmodal={() => setLoginModal(true)}
            />
            <VerifyForgotPasswordCode
                show={forgetVerifyEmail}
                onHide={() => setForgetVerifyEmail(false)}
                resetPasswordModal={resetPasswordModal}
                setResetPasswordModal={() => setResetPasswordModal(true)}
                loginModal={loginModal}
                setloginmodal={() => setLoginModal(true)}
            />

            <ForgotPassword
                show={forgotPasswordModal}
                onHide={() => setForgotPasswordModal(false)}
                verifyEmailModal={forgetVerifyEmail}
                setverifyemailcode={() => setForgetVerifyEmail(true)}
            />

            <ResetPassword
                show={resetPasswordModal}
                onHide={() => setResetPasswordModal(false)}
                loginModal={loginModal}
                setloginmodal={() => setLoginModal(true)}
            />

        <div id="advance-nav">
        <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="white" expand={expand} className="shadow">
          <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

          <Link to="/">
                        <Navbar.Brand >
                            <img className="logo-header"
                                src={logo}
                                alt="Slaylewks logo" />
                        </Navbar.Brand>

                    </Link>
            {
                            !isLoggedIn && !user?.email_verified ? (
                                <>


                                    <Dropdown className="mt-1 " id="advance-nav">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <Button className="sign-in" variant="">Sign in</Button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="mt-5 drop-down-items">
                                            <Dropdown.Item href="#" onClick={loginChange} className="sign-in-dropdowns">Login</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => { typeUser(); registerChange() }} className="sign-in-dropdowns">Register as Customer</Dropdown.Item>
                                            {/* <Dropdown.Item href="#" onClick={() => { registerChange(); typeBusiness() }} className="sign-in-dropdowns">Register as Business</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => { registerChange(); typeFreelancer() }} className="sign-in-dropdowns">Register as Freelancer</Dropdown.Item>   */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            ) : (
                                <>
                                    {role?.role_name === "user" && (
                                       
                                        <>
                                         {
                                            servicesCart && servicesCart.length > 0 ? (
                                                <>
                                                 <Nav.Link className="mt-0" onClick={redirectCheckoutPage} id="advance-nav">
                                            <ion-icon name="cart-outline" id="cart-icon-mobile"></ion-icon> {
                                                servicesCart && servicesCart.length > 0 && (

                                                    <div>
                                                        <span id='lblCartCount'>
                                                            <ion-icon name="chatbox"></ion-icon>
                                                        </span>
                                                        <span id='lblCartCountNumber'>{servicesCart.length}</span>
                                                    </div>
                                                )
                                            }
                                        </Nav.Link>
                                                </>
                                             ) : (
                                                <>
                                                   <Nav.Link className="mt-0" onClick={redirectCheckoutPage} id="advance-nav">
                                            <ion-icon name="cart-outline" id="cart-icon-empty-mobile"></ion-icon>
                                        </Nav.Link>
                                                </>

                                             )
                                        }
                                        
                                        </>
                                       
                                    )}

                                    <span className="mobile-profile-pic">
                                        <Dropdown className="m-0 p-0" id="advance-nav">
                                            <Dropdown.Toggle>
                                                <Image
                                                    src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                                    roundedCircle
                                                    width="30px"
                                                    height="30px"
                                                />
                                                {/* &nbsp;{user?.username} */}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="profile-dropdown-mobile mt-3" >
                                                <Dropdown.Item onClick={redirectProfilePage}>
                                                    <h6> {t("header.profile_settings")}</h6>
                                                </Dropdown.Item>

                                                <Dropdown.Item onClick={handleLogout}>
                                                    <h6>{t("header.logout")}</h6>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </>
                            )

                        }
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Link to="/">
                        <Navbar.Brand >
                            <img className="logo-header"
                                src={logo}
                                alt="Slaylewks logo" />
                        </Navbar.Brand>

                    </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <>
                {
                        activeTab === "/payment/checkout" ? (
                            null
                        ) : (

                                <Nav className="ms-auto navfont-size">


                                    {/* {
                                activeTab === "/explore" && (
                                    <span className='nav-search-hide'>
                                    <span className="explore-nav-search fs-5 mt-2 mx-0">
                                    <ion-icon name="search-outline" ></ion-icon>
                                    </span>
                                    <input type="text" class="form-control search-box-nav" id="" placeholder="Services, Stylist or Salon...." name="search" onInput={exploreServices}  />
                                    </span>
                                )
                            } */}
                                    {
                                        !vendor?._id && role?.role_name !== "user" && (
                                            <Link to={businessPage}>
                                                <Nav.Link
                                                    className={`me-4 mt-1
                                          ${activeTab === '/business' ? "active-Navbar" : ""}`}
                                                    href="#business"

                                                >
                                                    <h6>{t("header.setup_my_business")}</h6>
                                                </Nav.Link>
                                            </Link>
                                        )

                                    }
                                    <Link to="/">

                                        <Nav.Link className={`me-4 mt-1 ${activeTab === '/' ? "active-Navbar" : ""}`}
                                            href="#home" >
                                            <h6 >{t("header.home")}</h6>
                                        </Nav.Link>
                                    </Link>
                                    <Link to="/explore">
                                        <Nav.Link className={`me-4 mt-1  ${activeTab === '/explore' ? "active-Navbar" : ""}`} href="#explore" >
                                            <h6>{t("header.explore")}</h6>
                                        </Nav.Link>
                                    </Link>
                                    {
                                        vendor && role?.role_name && (role?.role_name === "business" || role?.role_name === "freelancer") && (
                                            <Link to="/dashboard">
                                                <Nav.Link className="me-4 mt-1" href="#explore" >
                                                    <h6>Dashboard</h6>
                                                </Nav.Link>
                                            </Link>
                                        )
                                    }

                                    {
                                        role?.role_name && role?.role_name === "admin" && (
                                            <Link to="/dashboard">
                                                <Nav.Link className="me-4 mt-1" href="#dashboard" >
                                                    <h6>Admin Dashboard</h6>
                                                </Nav.Link>
                                            </Link>
                                        )
                                    }

                                    {
                                        !isLoggedIn && !user?.email_verified
                                            ?
                                            (
                                                <>
                                                    {/* <Nav.Link className="me-4 mt-2" onClick={()=>{typeUser(); registerChange()}}>
                                                <h6>{t("header.register")}</h6>
                                            </Nav.Link> */}

                                                    {/* <Nav.Link onClick={loginChange}>
                                                <Button className="px-4" variant="dark">{t("header.login")}</Button>
                                            </Nav.Link> */}

                                                    <Dropdown className="mt-2" id="normal-nav">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <Button className="px-4 sign-in" variant="">Sign in</Button>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="mt-3 drop-down-items">
                                                            <Dropdown.Item href="#" onClick={loginChange} className="sign-in-dropdowns">Login</Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={() => { typeUser(); registerChange() }} className="sign-in-dropdowns">Register as Customer</Dropdown.Item>
                                                            {/* <Dropdown.Item href="#" onClick={() => { registerChange(); typeBusiness() }} className="sign-in-dropdowns">Register as Business</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => { registerChange(); typeFreelancer() }} className="sign-in-dropdowns">Register as Freelancer</Dropdown.Item>   */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {role?.role_name === "user" && (
                                                        <>
                                                            <Nav.Link
                                                                className={`mt-1 ${activeTab === '/orders' ? "active-Navbar" : ""}`}
                                                                onClick={redirectOrdersPage}>
                                                                <h6 className="">{t("header.my_orders")}</h6>
                                                            </Nav.Link>
                                                            {/* <Nav.Link className="mt-4" >
                                                        <BsBell />
                                                    </Nav.Link> */}
                                                            <Nav.Link className="mt-3" onClick={redirectCheckoutPage} id="normal-nav">
                                                                <span className="cart-icon-system">
                                                                    <ion-icon name="cart-outline"></ion-icon>
                                                                </span>
                                                                {
                                                                    servicesCart && servicesCart.length > 0 && (

                                                                        <div>
                                                                            <span className="system-cart-outer">
                                                                                <ion-icon name="chatbox"></ion-icon>
                                                                            </span>
                                                                            <span className="system-cart-inner">{servicesCart.length}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </Nav.Link>

                                                        </>
                                                    )}

                                                    <Dropdown className="mt-3" id="normal-nav">
                                                        <Dropdown.Toggle>
                                                            <Image
                                                                src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                                                roundedCircle
                                                                width="30px"
                                                                height="30px"
                                                            />
                                                            {/* &nbsp;{user?.username} */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="profile-dropdown mt-3">
                                                            <Dropdown.Item onClick={redirectProfilePage} className="sign-in-dropdowns">
                                                                {t("header.profile_settings")}
                                                            </Dropdown.Item>

                                                            <Dropdown.Item onClick={handleLogout} className="sign-in-dropdowns">
                                                                {t("header.logout")}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )
                                    }
                                    {
                                        activeTab !== "/payment/checkout" && (
                                            <Nav id="advance-nav">
                                                <Nav.Link
                                                    className="me-4 mt-2 "
                                                    onClick={() => setLoading(true)}
                                                >
                                                    <span className="d-flex">
                                                        {
                                                          locationDetails && locationDetails && (
                                                                <h6>{locationDetails?.slice(0, 15)?.concat("...")}</h6>
                                                            )
                                                        }
                                                        <span><ion-icon name="chevron-down-outline"></ion-icon></span>
                                                    </span>
                                                </Nav.Link>
                                            </Nav>
                                        )}

                                </Nav>
                        )
                    }
                </>
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
</>
        </div>
            <div id="normal-nav">
            <Navbar bg="white" expand="lg" className="shadow">
                <Container fluid="xxl" className="navbar-outer">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-3" />
                    <Link to="/">
                        <Navbar.Brand >
                            <img className="logo-header"
                                src={logo}
                                alt="Slaylewks logo" />
                        </Navbar.Brand>

                    </Link>
                    {/* {
                        activeTab !== "/payment/checkout" && (
                            <Nav>
                                <Nav.Link
                                    className="me-4 mt-2 location-in-navbar"
                                    onClick={() => setLoading(true)}
                                >
                                    <span className="d-flex">
                                        {
                                           locationDetails && locationDetails && (
                                                <h6>{locationDetails?.slice(0, 15)?.concat("...")}</h6>
                                            )
                                        }
                                        <span><ion-icon name="chevron-down-outline"></ion-icon></span>
                                    </span>
                                </Nav.Link>
                            </Nav>
                        )} */}


                    {/* {
                        activeTab !== "/payment/checkout" && ( */}
                    <>
                        {
                            !isLoggedIn && !user?.email_verified ? (
                                <>


                                    <Dropdown className="mt-1" id="advance-nav">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <Button className="sign-in" variant="">Sign in</Button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="mt-5 drop-down-items">
                                            <Dropdown.Item href="#" onClick={loginChange} className="sign-in-dropdowns">Login</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => { typeUser(); registerChange() }} className="sign-in-dropdowns">Register as Customer</Dropdown.Item>
                                            {/* <Dropdown.Item href="#" onClick={() => { registerChange(); typeBusiness() }} className="sign-in-dropdowns">Register as Business</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => { registerChange(); typeFreelancer() }} className="sign-in-dropdowns">Register as Freelancer</Dropdown.Item>   */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            ) : (
                                <>
                                    {role?.role_name === "user" && (
                                        <Nav.Link className="mt-0" onClick={redirectCheckoutPage} id="advance-nav">
                                            <ion-icon name="cart-outline" id="cart-icon-mobile"></ion-icon> {
                                                servicesCart && servicesCart.length > 0 && (

                                                    <div>
                                                        <span id='lblCartCount'>
                                                            <ion-icon name="chatbox"></ion-icon>
                                                        </span>
                                                        <span id='lblCartCountNumber'>{servicesCart.length}</span>
                                                    </div>
                                                )
                                            }
                                        </Nav.Link>
                                    )}

                                    <span className="mobile-profile-pic">
                                        <Dropdown className="" id="advance-nav">
                                            <Dropdown.Toggle>
                                                <Image
                                                    src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                                    roundedCircle
                                                    width="30px"
                                                    height="30px"
                                                />
                                                {/* &nbsp;{user?.username} */}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="profile-dropdown-mobile mt-1" >
                                                <Dropdown.Item onClick={redirectProfilePage}>
                                                    <h6> {t("header.profile_settings")}</h6>
                                                </Dropdown.Item>

                                                <Dropdown.Item onClick={handleLogout}>
                                                    <h6>{t("header.logout")}</h6>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </>
                            )

                        }

                    </>

                    {/* )
                     }    */}


                    {
                        activeTab === "/payment/checkout" ? (
                            null
                        ) : (

                            <Navbar.Collapse id="basic-navbar" className="mt-1 navbar-inner">
                                <Nav className="ms-auto navfont-size">


                                    {/* {
                                activeTab === "/explore" && (
                                    <span className='nav-search-hide'>
                                    <span className="explore-nav-search fs-5 mt-2 mx-0">
                                    <ion-icon name="search-outline" ></ion-icon>
                                    </span>
                                    <input type="text" class="form-control search-box-nav" id="" placeholder="Services, Stylist or Salon...." name="search" onInput={exploreServices}  />
                                    </span>
                                )
                            } */}
                                    {
                                        !vendor?._id && role?.role_name !== "user" && (
                                            <Link to={businessPage}>
                                                <Nav.Link
                                                    className={`me-4 mt-3
                                          ${activeTab === '/business' ? "active-Navbar" : ""}`}
                                                    href="#business"

                                                >
                                                    <h6>{t("header.setup_my_business")}</h6>
                                                </Nav.Link>
                                            </Link>
                                        )

                                    }
                                    <Link to="/">

                                        <Nav.Link className={`me-4 mt-3 ${activeTab === '/' ? "active-Navbar" : ""}`}
                                            href="#home" >
                                            <h6 >{t("header.home")}</h6>
                                        </Nav.Link>
                                    </Link>
                                    <Link to="/explore">
                                        <Nav.Link className={`me-4 mt-3  ${activeTab === '/explore' ? "active-Navbar" : ""}`} href="#explore" >
                                            <h6>{t("header.explore")}</h6>
                                        </Nav.Link>
                                    </Link>
                                    {
                                        vendor && role?.role_name && (role?.role_name === "business" || role?.role_name === "freelancer") && (
                                            <Link to="/dashboard">
                                                <Nav.Link className="me-4 mt-3" href="#explore" >
                                                    <h6>Dashboard</h6>
                                                </Nav.Link>
                                            </Link>
                                        )
                                    }

                                    {
                                        role?.role_name && role?.role_name === "admin" && (
                                            <Link to="/dashboard">
                                                <Nav.Link className="me-4 mt-3" href="#dashboard" >
                                                    <h6>Admin Dashboard</h6>
                                                </Nav.Link>
                                            </Link>
                                        )
                                    }

                                    {
                                        !isLoggedIn && !user?.email_verified
                                            ?
                                            (
                                                <>
                                                    {/* <Nav.Link className="me-4 mt-2" onClick={()=>{typeUser(); registerChange()}}>
                                                <h6>{t("header.register")}</h6>
                                            </Nav.Link> */}

                                                    {/* <Nav.Link onClick={loginChange}>
                                                <Button className="px-4" variant="dark">{t("header.login")}</Button>
                                            </Nav.Link> */}

                                                    <Dropdown className="mt-4" id="normal-nav">
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <Button className="px-4 sign-in" variant="">Sign in</Button>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="mt-1 drop-down-items">
                                                            <Dropdown.Item href="#" onClick={loginChange} className="sign-in-dropdowns">Login</Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={() => { typeUser(); registerChange() }} className="sign-in-dropdowns">Register as Customer</Dropdown.Item>
                                                            {/* <Dropdown.Item href="#" onClick={() => { registerChange(); typeBusiness() }} className="sign-in-dropdowns">Register as Business</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => { registerChange(); typeFreelancer() }} className="sign-in-dropdowns">Register as Freelancer</Dropdown.Item>   */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    {role?.role_name === "user" && (
                                                        <>
                                                            <Nav.Link
                                                                className={`mt-3 ${activeTab === '/orders' ? "active-Navbar" : ""}`}
                                                                onClick={redirectOrdersPage}>
                                                                <h6 className="">{t("header.my_orders")}</h6>
                                                            </Nav.Link>
                                                            {/* <Nav.Link className="mt-4" >
                                                        <BsBell />
                                                    </Nav.Link> */}
                                                            <Nav.Link className="mt-4" onClick={redirectCheckoutPage} id="normal-nav">
                                                                <span className="cart-icon-system">
                                                                    <ion-icon name="cart-outline"></ion-icon>
                                                                </span>
                                                                {
                                                                    servicesCart && servicesCart.length > 0 && (

                                                                        <div>
                                                                            <span className="system-cart-outer">
                                                                                <ion-icon name="chatbox"></ion-icon>
                                                                            </span>
                                                                            <span className="system-cart-inner">{servicesCart.length}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </Nav.Link>

                                                        </>
                                                    )}

                                                    <Dropdown className="mt-4" id="normal-nav">
                                                        <Dropdown.Toggle>
                                                            <Image
                                                                src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                                                roundedCircle
                                                                width="30px"
                                                                height="30px"
                                                            />
                                                            {/* &nbsp;{user?.username} */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="profile-dropdown mt-3">
                                                            <Dropdown.Item onClick={redirectProfilePage} className="sign-in-dropdowns">
                                                                {t("header.profile_settings")}
                                                            </Dropdown.Item>

                                                            <Dropdown.Item onClick={handleLogout} className="sign-in-dropdowns">
                                                                {t("header.logout")}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            )
                                    }
                                    {
                                        activeTab !== "/payment/checkout" && (
                                            <Nav id="advance-nav">
                                                <Nav.Link
                                                    className="me-4 mt-2 "
                                                    onClick={() => setLoading(true)}
                                                >
                                                    <span className="d-flex">
                                                        {
                                                          locationDetails && locationDetails && (
                                                                <h6>{locationDetails?.slice(0, 15)?.concat("...")}</h6>
                                                            )
                                                        }
                                                        <span><ion-icon name="chevron-down-outline"></ion-icon></span>
                                                    </span>
                                                </Nav.Link>
                                            </Nav>
                                        )}

                                </Nav>
                            </Navbar.Collapse>
                        )
                    }

                </Container>
            </Navbar>
            </div>



            {/* {
                loading ? (
                    <LocationSearch info={loading} closeSearch={closeLocation} />
                ) : (
                    null
                )
            } */}

        </div >
    );
};

export default Header;