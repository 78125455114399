import * as Yup from 'yup';

export const staffInformationValidation = Yup.object({

    stylist_name: Yup.string('Enter the stylish name').required('stylish name is required'),

    mobile_no: Yup.string('Enter the stylish mobile no').required('stylish mobile is required'),


    stylist_category: Yup.string('Enter the stylish category').required('stylish category is required'),

    is_active: Yup.string('Enter the service active').required('service active is required'),

    age_category: Yup.string('Enter the gender category').required('gender category is required'),

    service_category: Yup.array().min(1).required('service type is required'),

    staff_basic_hours: Yup.array().min(1).required('business hours is required'),
    staff_working_days: Yup.array().min(1).required('business days is required'),
});
