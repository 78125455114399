import React, { useState, useEffect } from 'react';
import { findAllCommissions, updateCommissionsActions } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Modal, Row, Col, Table, Form } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { commissionValidation } from '../../../validations/yub_schemas/commissionValidation';
import { useFormik, FormikProvider} from 'formik';
import { useNavigate } from 'react-router-dom';

function OwnerListDetails() {
    const navigate = useNavigate()
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [update, setUpdate] = useState(false);
    const [updatingId, setUpdatingId] = useState('');
    const [gstEnabled, setGstEnabled] = useState(false);
    const [percentageCommision, setPercentageCommision] = useState();

    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });

    const dispatch = useDispatch();


    useEffect(() => {
        const data = {
            page: state.activePage,
            search: "",
        };
        dispatch(findAllCommissions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })
    }, []);

  const getAllCommisions = () =>{
    const data = {
        page: state.activePage,
        search: "",
    };
    dispatch(findAllCommissions(data))
        .then((res) => {
            const { resultsPerPage, results, totalcount } = res
            setState((prev) => ({
                ...prev,
                data: results,
                total: totalcount / resultsPerPage
            }))
        })
  }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber.selected);
        const data = {
            page: pageNumber?.selected + 1,
            search: "",
        };
        dispatch(findAllCommissions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })

    };


    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        const data = {
            page: 1,
            search: e.target.value,
        };

        dispatch(findAllCommissions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })
    };


const ViewOneSaloon = (item) => {
     console.log(item)
     navigate(`/dashboard/branches/${item?.business_name}`)
}





const formik = useFormik({
    initialValues: {
        commission_percentage: percentageCommision,
        gstChecked: false,
    },
    validationSchema: commissionValidation,
    onSubmit: (values) => {
        const data = {
            id: updatingId,
            payment_commission: values.commission_percentage,
            is_GST_checked: gstEnabled,
        };
        console.log(data)
        dispatch(updateCommissionsActions(data))
            .then(() => {
                getAllCommisions();
                formik.resetForm();
                setGstEnabled(false);
                setUpdate(false);
            })
          
    },
});

    return (
        <div className='container-xxl'>
            <div className="order-list mt-3">
                <Row>
                    <Col md={6}>
                        <h3 className='marketing-header'>Saloon Branches</h3>
                    </Col>
                    <Col md={6}>
                        <div className='text-end'>
                        <Button >Add Branches</Button>
                        </div>
                    </Col>
                  

                </Row>
                <hr />
                <div className="service-list-header-option mt-4">
                    <div className="business-search w-50">
                        <input type="text" class="form-control" id="" placeholder="search by salon name" name="search" onInput={onChangeSearch} />
                    </div>
                </div>
                <hr className='p-0 mb-0' />

                <section class="pt-0">
                    <div class=" mt-4">
                        <div class="table-responsive slaylewks-table">
                            <Table responsive>
                                <thead className='admin-coupon-list-head'>
                                    <tr className='tabel-data-content'>
                                        <th className='admin-tabel-heading'>
                                            Saloon Name
                                        </th>
                                        
                                        <th className='admin-tabel-heading'>
                                        Business Email 
                                        </th>
                                                                               
                                        <th className='admin-tabel-heading text-center'>
                                        Branch Location</th>
                                        <th className='admin-tabel-heading'>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    state?.total > 0 ? (
<>
{
                                        state?.data?.map((item, index) => {
                                            return (
                                                <tr className='tabel-data-content' key={index}>
                                                    <td class="sss">{item?.reg_id}</td>
                                                    <td >{item?.business_email}</td>
                                                   
                                                    <td className='active-coupons text-center'>{item?.payment_commission}%</td>
                                                    <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="text-center">
                                                                    <Dropdown.Item onClick={()=>ViewOneSaloon(item)}>Edit</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                          
                                                        </td>

                                                </tr>
                                            )
                                        })
                                    }
</>
                                    ): (
                                        <>
                                        <p className='mt-5 mx-2'>No results Found </p>
                                        </>
                                    )
                                }
                                    

                                </tbody>
                            </Table>
                        </div>
                    </div>

                </section>
                <div>
                <Modal centered size="md" show={update} onHide={()=>setUpdate(false)} className='text-center'>
                <Form noValidate className="" onSubmit={formik.handleSubmit}>
                                            <FormikProvider value={formik}>
                                 <Modal.Header closeButton></Modal.Header>
                                    <Modal.Title className='text-center'>
                                    Update Commission and GST
                                  </Modal.Title>
                                    <Modal.Body className='update-commition-container'>
                                    <p className='mt-2'>Are you want to update the commission and gst?</p>
                                            <div>
                                           
                                            <Form.Group className="percentage-commission" controlId="advance_percentage">
                                            <Form.Control 
                                            type="text"
                                             placeholder="Enter your commission percentage"
                                             name="commission_percentage"
                                             onChange={formik.handleChange}
                                             value={formik.values.commission_percentage}
                                             isInvalid={!!formik.touched.commission_percentage && !!formik.errors.commission_percentage}
                                             />

                                       
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.commission_percentage}
                                        </Form.Control.Feedback>

                                       <Row className='mt-2'>
                                       <Form.Check 
                                           className='gst-enable-radio'
                                            name='gstChecked'
                                            type="switch"
                                            id="custom-switch"
                                            label="Enable GST"
                                            checked={gstEnabled}
                                            onChange={() => setGstEnabled(prev => !prev)}
                                        />
                                                                           
                                       </Row>
                                              </Form.Group>
                                    
                                            </div>
                                          <Row className='commission-btn-group'>
                                            <Col sm={6} xs={6}>
                                             <Button className='update-commission-cancel' onClick={()=>{setUpdate(false)}}>Cancel</Button>
                                            </Col>
                                            <Col sm={6} xs={6}>
                                            <Button className='update-commission-update' type='submit'>Update</Button>
                                            </Col>
                                          </Row>
                                        
                                    </Modal.Body>
                                    </FormikProvider>
                                    </Form>
             </Modal>

                    {
                        state?.total > 1 && (
                            <div className='mt-5'>
                                <ReactPaginate
                                    previousLabel="<"
                                    nextLabel=">"
                                    breakLabel="..."
                                    pageCount={state?.total}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-center pagination-box'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    forcePage={page}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default OwnerListDetails;