import * as TYPES from './types.js';

const initialState = {
    order: {
        total_amount: 0,
        taxable_amount: 0,
        tax_amount: 0,
        discount_amount: 0,
        coupon_amount: 0,
        gst_amount: 0,
        currency: '',
        vendorId: '',
        appointment_ts: '',
        orderId: '',
        coupon_name: '',
        isAdmin: false,
        coupon_amount_reduced: '',
    },
    razorPayOrder: {},
    isPaymentLoading: false,
};

const paymentReducer = (state = initialState, action) => {

    const { type, payload } = action;
    // console.log(type,payload,"PAYMENT - ORDER ==================");
    // console.log(state,"PAYMENT STATE")
   
    switch (type) {
        case TYPES.CREATE_RP_ORDER:
            return {
                ...state,
                isPaymentLoading: true,
            };

        case TYPES.CREATE_RP_ORDER_SUCCESS:
            return {
                ...state,
                razorPayOrder: payload.data,
                isPaymentLoading: false,
            };

        case TYPES.CREATE_RP_ORDER_FAIL:
            return {
                ...state,
                isPaymentLoading: false,
            };
        case TYPES.SET_VENDOR_ID:
            return {
                ...state,
                order: {
                    ...state.order,
                    vendorId: payload.vendorId
                }
            };
        case TYPES.SET_ORDER_AMOUNT:
            return {
                ...state,
                order: {
                    ...state.order,
                    total_amount: payload.total_amount,
                    taxable_amount: payload.taxable_amount,
                    tax_amount: payload.tax_amount,
                    discount_amount: payload.discount_amount,
                    currency: payload.currency,
                    coupon_amount:payload.coupon_amount,
                    gst_amount: payload.gst_amount,
                    coupon_name: payload.coupon_name,
                    isAdmin: payload.isAdmin,
                    coupon_amount_reduced: payload.coupon_amount_reduced,
                }
            };
        default:
            return state;
    }
};

export default paymentReducer;