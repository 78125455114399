import React, { useState, useEffect } from 'react';
import { getAllProposals } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector} from "react-redux";
import { Badge, Pagination, Button, Dropdown, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs"
import { useNavigate, useSearchParams  } from "react-router-dom";
import ReactPaginate from 'react-paginate';

function ProposalsList() {

    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [page, setPage] = useState(0);

    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 0
    });

    // const [searchParams] = useSearchParams();
    // const type = searchParams.get("type");

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { proposals } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const data = {
            page: state.activePage,
            // gender_filter: ageCategory,
            // service_filter: filterBy,
            // sortType: sortBy,
            // search: search
        };
        if (search) data.search = search
        dispatch(getAllProposals(data))
            .then((res) => {
                const { results, count, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: count / resultsPerPage
                }))
            })
        // dispatch(getAllProposals(data))
    }, []);


    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1  }));
        setPage(pageNumber.selected);
        const data = {
            page: pageNumber?.selected + 1 
        };
        // onServiceDataChange();
        if (search) data.search = search
        dispatch(getAllProposals(data))
            .then((res) => {
                const { results, count, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: count / resultsPerPage
                }))
            })

    };

    const viewProposalPage = (id) => {
        navigate(`/dashboard/proposal/${id}`)
    }

    const handleEditChange = (id) => {
        navigate(`/dashboard/proposal/${id}?type=edit`);
    }
    const handleViewChange = (id) => {
        navigate(`/dashboard/proposal/${id}?type=view`);
    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        setPage(0)
        const data = {
            search: e.target.value
        };

        dispatch(getAllProposals(data))
            .then((res) => {
                const { results, count, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: count / resultsPerPage
                }))
            })

    };

    return (
       <div className='container-xxl'>
         <div className="order-list mt-3">              
                <h3 className='marketing-header'> Proposals</h3>
                <hr />     

            <div className="service-list-header-option">
                <div className="business-search w-50">
                    <input type="text" class="form-control" id="" placeholder="Search by business name" name="search" onInput={onChangeSearch} />
                </div>
            </div>
            <hr className='p-0 mb-0' />

            <section>
                <div class="mt-4">
                    <div class="table-responsive slaylewks-table">
                        <Table responsive>

                        <thead className='admin-coupon-list-head'>
                                <tr>
                                    {/* <th scope="col" width="4%"><input class="form-check-input" type="checkbox" /></th> */}
                                    <th className='admin-tabel-heading'>
                                        Reg Id
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Business Name
                                    </th>

                                    <th className='admin-tabel-heading'>
                                        Type
                                    </th>
                                    <th className='admin-tabel-heading text-center'>
                                        Status
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Phone Number
                                    </th>
                                    <th className='admin-tabel-heading'>
                                    </th>
                                    {/* <th>

                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className='admin-tabel-data'>

                                {
                                    proposals && proposals.length > 0 ?
                                        (
                                            proposals.map((item, index) => {
                                                return (
                                                    <tr className='tabel-data-content' key={index}>
                                                        {/* <th scope="row"><input class="form-check-input" type="checkbox" /></th> */}
                                                        <td class="sss" onClick={() => viewProposalPage(item._id)}>{item.reg_id}</td>
                                                        <td>{item.business_name}</td>

                                                        <td>{item.vendor_type}</td>
                                                       
                                                        <td className='text-center'>
                                                            {item.business_verified ?
                                                             <Badge className='accepted-badge' bg="accepted-badge">Completed</Badge>

                                                             :
                                                             <Badge className='pending-badge' bg="pending-badge">Pending</Badge>

                                                            }
                                                        </td>
                                                        <td>
                                                            {item.business_contactNo}
                                                        </td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                    <Dropdown.Item onClick={() => handleViewChange(item._id)}>View</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleEditChange(item._id)}>Edit</Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>

                                                );
                                            })

                                        )
                                        :

                                        (
                                            <tr>
                                                No records Found
                                            </tr>
                                        )

                                }
                            </tbody>
                            </Table>
                    </div>
                </div>
            </section>
            <div>
                {/* <Pagination className="px-4">

                    {(() => {
                        const rows = [];
                        for (let i = 0; i < state.total; i++) {
                            rows.push(<Pagination.Item
                                onClick={() => handlePageChange(i + 1)}
                                key={i + 1}
                                active={i + 1 === state.activePage}
                            >
                                {i + 1}
                            </Pagination.Item>);
                        }
                        return rows;
                    })()}
                </Pagination> */}
{
    state?.total > 1 && (
         <ReactPaginate 
        
         previousLabel="<<"
         nextLabel=">>"
         breakLabel="..."
         pageCount={state.total}
         marginPagesDisplayed={2}
         pageRangeDisplayed={2}
         onPageChange={handlePageChange}
         containerClassName={'pagination justify-content-center pagination-box'}
         pageClassName={'page-item'}
         pageLinkClassName={'page-link'}
         previousClassName={'page-item'}
         previousLinkClassName={'page-link'}
         nextClassName={'page-item'}
         nextLinkClassName={'page-link'}
         breakClassName={'page-item'}
         breakLinkClassName={'page-link'}
         activeClassName={'active'}
         forcePage={page}
        />
    )
}
              
                
            </div>
        </div>
       </div>
    )
}

export default ProposalsList