import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, Spinner, Card, ListGroup, Modal, Breadcrumb } from 'react-bootstrap'
// import profile from '../../assets/images/profile.webp';
import { AiOutlineUpload } from 'react-icons/ai';
import { useFormik, FormikProvider } from 'formik';
// import ProfileImg from "../../assets/images/profile.webp"
import AvatarImg from "../../assets/images/avatar.webp";
import EditImg from "../../assets/images/edit.png"
// import InfoImg from "../../assets/images/info.png";
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { logoutFromLocalStorage, updateUserAction, verifyEmail, verifyRegisteredEmail, resendOtp, verifyProfileEmailAction, updateUserActionForEmail } from '../../redux/auth/actions';
import { fileUpload } from '../../redux/business/actions';
import ResetPassword from "../../components/elements/auth/ResetPassword";
import ChangePasswordProfile from '../../components/elements/auth/ChangePassword';
// import { GrFormFilter } from 'react-icons/gr';
import { profileValidationSchema } from '../../validations/yub_schemas/profileValidation';
import ChangeEmailUser from '../../components/elements/auth/ChangeEmail';
import VerifyYourEmailCode from '../../components/elements/auth/VerifyYourEmailCode';
import FavoriteService from './FavoriteService';
import { verifyEmailCodeValidation } from '../../validations/yub_schemas/verifyEmailCodeValidation';
import { verifyProfilecodeValidation } from '../../validations/yub_schemas/verifyProfileValidations';
import ContactUsProfile from './ContactUsProfile';
import Support from '../support/Support';
import ReferAndEarn from './ReferAndEarn';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import OtpTimer from 'otp-timer'
import BusinessSupport from '../support/BusinessSupport';

// import { RiDivideLine } from 'react-icons/ri';





const UserProfilePage = () => {

    const [count, setCount] = useState(0);
    const [otperror, setOtperror] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [optInput, setOtpInput] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [ChangePasswordEdit, SetChangePasswordEdit] = useState(false);
    const [ChangeEmailProfile, SetChangeEmailProfile] = useState(false);
    const [verifyEmailModal, setVerifyEmailCode] = useState(false);
    const [profileImg, setProfileImg] = useState(null)
    const [logout, setLogout] = useState(false)
    const [discard, setDiscard] = useState(false)

    const [menu, setMenu] = useState({
        account: true,
        myOrder: false,
        favorite: false,
        contact: false,
        supportCenter: false,  
        referAndEarn: false,
    })

    const handleTimeClick =()=> {
        setCount(count +1);
        if(count < 3){
            resendOTPviaMail()
        }
        else{
        }
      }
      const handleOtpLimit = () => {
        setCount(count +1);
        if(count < 3){
            handleTimeClick();
        } else{
            setOtperror(true)
        }
      }

    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: user?.username || '',
            phone_no: user?.mobile_no || '',
            email: user?.email_id || '',
            profileURL: user?.profileURL || ''

        },
        validationSchema: profileValidationSchema,
        onSubmit: (values) => {
            const data = {
                id: user?._id,
                username: values?.username,
                mobile_no: values?.phone_no,
                email_id: values?.email,
                profileURL: values?.profileURL

            };
            dispatch(updateUserAction(data)).then((res) => {
            });

            setEditOption(false);
        }
    })
    const getVerify = ()=>{
        const data = {
            id: user?._id,
            username: user?.username,
            mobile_no: user?.phone_no,
            email_id: user?.email,
            profileURL: user?.profileURL
    
        };
        dispatch(updateUserActionForEmail(data)).then((res) => {
        });
    
    }
    const refreshPage = () => {
        window.location.reload(false);
    }
    const formikVerify = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: verifyProfilecodeValidation,
        onSubmit: (values) => {
            const data = {
                userId: user?._id,
                otp: values.otp
            }
            dispatch(verifyEmail(data))
                .then(res => {
                    formikVerify.handleReset();
                    setOtpInput(false)
                    getVerify();
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    })

    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);
    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setProfileImg(file)
        }

    }


    const changeEditOption = (type) => {
        setEditOption(type)
    }

    const changePasswordOption = () => {
        if (resetPasswordModal) {
            setResetPasswordModal(false);
        }
        else {
            setResetPasswordModal(true);
        }
    }

    const ChangePasswordClick = () => {
        if (ChangePasswordEdit) {
            SetChangePasswordEdit(false);
        }
        else {
            SetChangePasswordEdit(true);
        }
    }

    const ChangeEmailUserProfile = () => {
        if (ChangeEmailProfile) {
            SetChangeEmailProfile(false);
        }
        else {
            SetChangeEmailProfile(true);
        }
    }

    const signoutOption = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    }

    const getVerifiedOption = () => {
        if (optInput) {
            setOtpInput(false)
        }
        else {
            setOtpInput(true)
        }

    }


    const resendOTPviaMail = () => {

        const data = {
            _id: user?._id,
            email_id: user?.email_id,
        }
        dispatch(resendOtp(data))
            .then(res => {
            })
            .catch((err) => {
                console.log(err)
            })
    }

   

    const requestVerifyEmail = () => {
        setOtpInput(true)
        const data = {
            email_id: user?.email_id,
        }
        dispatch(verifyProfileEmailAction(data))
            .then(res => {
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const uploadProfile = () => {

        if (profileImg) {
            setLoading(true)
            const formData = new FormData();
            formData.append('file', profileImg);
            formData.append('path', "profile");


            dispatch(fileUpload(formData))
                .then((res) => {
                    const { data } = res
                    formik.setFieldValue("profileURL", data?.location)
                    setLoading(false)

                }).catch((err) => {
                    setLoading(false)
                    console.log("Error => ", err);
                })
        } else {
            setLoading(false)
        }

    }

    const scrollDown = () => {
        window.scrollTo(0, 760);
        }    
    
    const goToHome = () => {
        navigate("/")
    }


    return (
        <div>
            <Header />
            <ResetPassword
                show={resetPasswordModal}
                onHide={() => setResetPasswordModal(false)}
            />
            <ChangePasswordProfile 
             show={ChangePasswordEdit}
             onHide={()=> SetChangePasswordEdit(false)}
             />

             <ChangeEmailUser 
              show={ChangeEmailProfile}
              onHide={()=> SetChangeEmailProfile(false)}
              verifyEmailModal={verifyEmailModal}
              setverifyemailcode={() => setVerifyEmailCode(true)}
              />
               <VerifyYourEmailCode
                show={verifyEmailModal}
                onHide={() => setVerifyEmailCode(false)}
            />
           
          <Container>
          <Breadcrumb>
                <Breadcrumb.Item onClick={goToHome} className="subnav-list">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="subnav-list">
                 Profile
                </Breadcrumb.Item>
                </Breadcrumb>
          </Container>
          

            <Container  className="tabelt-view">
            
            <Row className='mt-5'>
                <Col md={4} sm={12}>
                 <Card className="user-details-profile">
                    <Card.Title className='text-center card-profile-img-card'>
                    <Card.Img variant="top" className='profile-card-pic ' src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg} />
                    <div className='card-user-details-edit'>
                        <h6>{user?.username}</h6>
                        <h3>{user?.email_id}</h3>
                       <div className='system-view-profile'>
                       <p onClick={() => {changeEditOption(true); setMenu({account:true,favorite:false,myOrder:false, contact:false, supportCenter:false, referAndEarn: false})}}>Edit Profile</p>
                       </div>
                       <div className='mobile-view-profile'>
                       <p onClick={() => {changeEditOption(true); setMenu({account:true,favorite:false,myOrder:false, contact:false, supportCenter:false, referAndEarn: false}); scrollDown()}}>Edit Profile</p>
                       </div>
                    </div>
                    </Card.Title>
            
                    <Card.Body className='card-body-lists p-0'>
                         <div className='mobile-view-profile'>
                         <ListGroup className="list-group-flush">
        <ListGroup.Item className={menu.account ? "active-profile-tab" : ""} onClick={scrollDown}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:true,favorite:false,myOrder:false, contact:false, supportCenter:false, referAndEarn: false})}>
            <p className='w-75'>Account settings</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
           {
            !vendor?._id && role?.role_name === "user" && (
                <>
           
           <ListGroup.Item className={menu.favorite ? "active-profile-tab" : ""} onClick={scrollDown}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:true,myOrder:false, contact:false, supportCenter:false, referAndEarn: false})}>
            <p className='w-75'>Favorite</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
                </>
            ) }
          
           
                <ListGroup.Item className={menu.contact ? "active-profile-tab" : ""} onClick={scrollDown}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:true, supportCenter:false, referAndEarn: false})}>
            <p className='w-75'>Contact Us</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
        
           <ListGroup.Item className={menu.supportCenter ? "active-profile-tab" : ""} onClick={scrollDown}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:false, supportCenter:true, referAndEarn: false})}>
            <p className='w-75'>Help / Support center</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
           <ListGroup.Item className={menu.referAndEarn ? "active-profile-tab" : ""} onClick={scrollDown}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:false, supportCenter:false, referAndEarn: true})}>
            <p className='w-75'>Refer and Invite friends</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
           <ListGroup.Item onClick={()=>setLogout(true)}>
            <div className='d-flex mt-2'>
            <p className='w-75'>Logout</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>

           <Modal show={logout} onHide={()=> setLogout(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                            <Modal.Header closeButton>
                            
                            </Modal.Header>
                            <Modal.Body className='mb-5'>
                                <h5 class="text-center">Logout</h5>
                             <p class="text-center mt-4">Are you sure you want to logout ?</p>
                             <Row className='mt-3'>
                                <Col className='text-center'>
                                <Button className='replace-cancel-btn' onClick={()=> setLogout(false)}>
                                 Cancel
                            </Button>
                            
                                </Col>
                                <Col className='text-center'>
                                <Button className='replace-btn' onClick={signoutOption}>
                                 Logout
                            </Button>
                                </Col>
                             </Row>
                            </Modal.Body>
                    </Modal>
       
             </ListGroup>
                         </div>

                    <div className='system-view-profile'>
                    <ListGroup className="list-group-flush">
        <ListGroup.Item className={menu.account ? "active-profile-tab" : ""}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:true,favorite:false,myOrder:false, contact:false, supportCenter:false})}>
            <p className='w-75'>Account settings</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
           {
            !vendor?._id && role?.role_name === "user" && (
                <>
            {/* <ListGroup.Item className={menu.myOrder ? "active-profile-tab" : ""}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:true,contact:false, supportCenter:false})}>
            <p className='w-75'>My Orders</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item> */}
           <ListGroup.Item className={menu.favorite ? "active-profile-tab" : ""}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:true,myOrder:false, contact:false, supportCenter:false})}>
            <p className='w-75'>Favorite</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
                </>
            ) }
          
           
                <ListGroup.Item className={menu.contact ? "active-profile-tab" : ""}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:true, supportCenter:false})}>
            <p className='w-75'>Contact Us</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
        
           <ListGroup.Item className={menu.supportCenter ? "active-profile-tab" : ""}>
            <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:false, supportCenter:true})}>
            <p className='w-75'>Help / Support center</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>
           {
            !vendor?._id && role?.role_name === "user" && (
                <ListGroup.Item className={menu.referAndEarn ? "active-profile-tab" : ""}>
                <div className='d-flex mt-2' onClick={()=>setMenu({account:false,favorite:false,myOrder:false, contact:false, supportCenter:false, referAndEarn:true})}>
                <p className='w-75'>Refer and Invite friends</p>
               <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
                </div>
               </ListGroup.Item>
            ) }
        
           <ListGroup.Item onClick={()=>setLogout(true)}>
            <div className='d-flex mt-2'>
            <p className='w-75'>Logout</p>
           <h5 className=''><ion-icon name="chevron-forward-outline"></ion-icon></h5>
            </div>
           </ListGroup.Item>

           <Modal show={logout} onHide={()=> setLogout(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                            <Modal.Header closeButton>
                            
                            </Modal.Header>
                            <Modal.Body className='mb-5'>
                                <h5 class="text-center">Logout</h5>
                             <p class="text-center mt-4">Are you sure you want to logout ?</p>
                             <Row className='mt-3'>
                                <Col className='text-center'>
                                <Button className='replace-cancel-btn' onClick={()=> setLogout(false)}>
                                 Cancel
                            </Button>
                            
                                </Col>
                                <Col className='text-center'>
                                <Button className='replace-btn' onClick={signoutOption}>
                                 Logout
                            </Button>
                                </Col>
                             </Row>
                            </Modal.Body>
           </Modal>

           <Modal show={discard} onHide={()=> setDiscard(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                            <Modal.Header closeButton>
                            
                            </Modal.Header>
                            <Modal.Body className='mb-5'>
                                <h5 class="text-center">Discard changes</h5>
                             <p class="text-center mt-4 px-5">Are you sure you want to discard changes made on this page?</p>
                             <Row className='mt-3'>
                                <Col className='text-center'>
                                <Button className='replace-cancel-btn' onClick={()=> setDiscard(false)}>
                                 Cancel
                            </Button>
                            
                                </Col>
                                <Col className='text-center'>
                                <Button className='replace-btn' onClick={()=> {setDiscard(false); changeEditOption(false)}} >
                                Discard
                              </Button>
                                </Col>
                             </Row>
                            </Modal.Body>
           </Modal>
           
                    </ListGroup>

                    </div>
                    </Card.Body>
      
      
                 </Card>
                </Col>
                <Col md={8} sm={12}>
                  
               {menu.favorite ?
                   <div className=''>
                   <FavoriteService  />
               </div>:<></>} 
               {menu.contact ?
                   <div className=''>
                    <ContactUsProfile />
               </div>:<></>} 
               {menu.supportCenter ?
                   <div className=''>
                    {
                        !vendor?._id && role?.role_name === "user" ? (
                            <Support />

                        ):(
                             <BusinessSupport />
                        )
                    }
               </div>:<></>} 
               {menu.referAndEarn ?
                   <div className=''>
                   <ReferAndEarn/>
               </div>:<></>} 
                    
                    {
                        menu.account ? (
                            <div>
                                { 
                    !editOption ? (
                        <Card className='user-details-profile-tab'>
                        <Row>
                            <Col md={2}>
                           <div className='mt-3'>
                           <Card.Img variant="top" className='profile-card-pic-right' src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg} />
                           </div>
                            </Col>
                            <Col md={10}>
                            <div className='card-user-details'>
                            <p>{user?.username}</p>
                            <p>{user?.mobile_no}</p>
                            <p>{user?.email_id}</p>
                            </div>
                            </Col>
                        </Row>
                    </Card>
                    ): (

                        <>
                             <Container className="mt-5">
                                <Row>
                                    <Col lg={12} md={12} sm={12} className='d-flex justify-content-center'>
                                        <div className='profilePic '>

                                            {/* <img for="file"
                                                ref={uploadedImage}
                                                className='profileEdit'
                                                src={profile}
                                            /> */}
                                            <label class="upload" for="file" >
                                                <img for="file"
                                                    ref={uploadedImage}
                                                    className='profileEdit-card'
                                                    // src={profile}
                                                    src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                                /></label>
                                            <input type="file" id='file'
                                                className="profile-pic"
                                                accept='image/*'
                                                multiple='false'
                                                onChange={handleImageUpload}
                                                ref={imageUploader} />

                                        </div>

                                    </Col>
                                    <div class="input-group d-flex justify-content-center head-content">
                                        {/* <label class="upload" for="file" ><AiOutlineUpload className='upload-icon' /></label>
                                        <input type="file" id='file'
                                            className="profile-pic"
                                            accept='image/*'
                                            multiple='false'
                                            onChange={handleImageUpload}
                                            ref={imageUploader} /> */}
                                        <div className='upload-icon cur-pointer text-center' onClick={uploadProfile}>
                                            {
                                                loading ? (
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"

                                                    />
                                                ) : (
                                                    <AiOutlineUpload style={{ fontSize: "1.5rem" }} />
                                                )
                                            }

                                        </div>

                                        {/* <AiOutlineUpload className='upload-icon cur-pointer' onClick={uploadProfile} /> */}

                                    </div>


                                    <p className='d-flex justify-content-center text-brand-color fs-6 mt-3 cur-pointer' onClick={uploadProfile}>Change photo</p>
                                </Row>
                            </Container>
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col lg={12} className='d-flex justify-content-center'>
                                        <div style={{ width: 500 }} className="CardBorder">

                                            <form onSubmit={formik.handleSubmit}>

                                                <div class="mb-4">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="username"
                                                        name="username"
                                                        placeholder='User name'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        onBlur={formik.handleBlur}
                                                    />

                                                    {formik.touched.username && formik.errors.username ? (
                                                        <div className="text-danger mt-2">{formik.errors.username}</div>
                                                    ) : null}
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback> */}

                                                </div>

                                                <div class="mb-4">
                                                    <input type="text"
                                                        class="form-control"
                                                        id="exampleInputPhone1"
                                                        name="phone_no"
                                                        placeholder='Phone number'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.phone_no}
                                                        onBlur={formik.handleBlur}

                                                    />
                                                    {formik.touched.phone_no && formik.errors.phone_no ? (
                                                        <div className="text-danger mt-2">{formik.errors.phone_no}</div>
                                                    ) : null}
                                                    {/* <Form.Control.Feedback type="invalid">
                                                        {formik.errors.phone_no}
                                                    </Form.Control.Feedback> */}
                                                </div>


                                                <div class="mb-3 email-component">
                                                    <input type="email"
                                                        disabled
                                                        class="form-control"
                                                        id="exampleInputEmail1"
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.email}
                                                        onBlur={formik.handleBlur}

                                                    />
                                                    <p>
                                                    {
                                                            user?.email_verified ? (
                                                                <span> verified</span>
                                                            )
                                                                : (
                                                                    <span onClick={requestVerifyEmail}> Get verify</span>
                                                                )
                                                        }
                                                    </p>
                                               
                                                </div>
                                                <div>
                                                {
                                                optInput && (
                                                    <Row className="verify-email-component">
                                                        <Col md={12}>
                                                            <Form onSubmit={formikVerify.handleSubmit}>
                                                                <FormikProvider value={formikVerify}>
                                                                    <InputGroup className="mb-3 p-2" controlId="formBasicEmail">
                                                                        <Form.Control
                                                                            className="input-border"
                                                                            type="text"
                                                                            placeholder="Enter otp"
                                                                            name="otp"
                                                                            onChange={formikVerify.handleChange}
                                                                            value={formikVerify.values.otp}
                                                                            isInvalid={!!formikVerify.touched.otp && !!formikVerify.errors.otp}
                                                                        />

                                                                        <Button className="verify-email" type="submit" onClick={formikVerify.handleSubmit}>
                                                                            Verify
                                                                        </Button>

                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formikVerify.errors.otp}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                    <p className="resend-otp text-end">
                                    {
                                        !otperror && (
                                            <span>   
                                            <OtpTimer seconds= {60}  resend={handleOtpLimit}  textColor={"#C78963"} buttonColor={"#C78963"} background={'none'}	 />
                                            </span>
                                        )
                                    }
                               
                                <span>
                                    {
                                        otperror && (
                                            <p className="text-danger">Try after sometimes!</p>
                                        )
                                    }
                                </span>
                                </p>
                                                                    

                                                                </FormikProvider>
                                                            </Form>


                                                        </Col>

                                                    </Row>
                                                )
                                            }
                                                </div>
                                                <p className="text-brand-color my-3 cur-pointer" onClick={ChangeEmailUserProfile}>
                                                Change Email Id
                                                    </p>
                                                <p className="text-brand-color my-3 cur-pointer" onClick={ChangePasswordClick}>
                                                        Change Password
                                                    </p>
                                                <Row>
                                                    <Col>


                                                        {/* <a>
                                            <ChangePassword />
                                        </a> */}


                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='text-center' lg={6} md={6} sm={6} xs={6}>
                                                        <Button onClick={()=> setDiscard(true)}className='buttonSizing slaylewks-outline-btn'>Cancel</Button>
                                                    </Col>
                                                    <Col className='text-center' lg={6} md={6} sm={6} xs={6}>
                                                        <Button type="sumbit" className='buttonSizing slaylewks-outline-btn'>Update</Button>
                                                    </Col>
                                                </Row>

                                            </form>
                                        </div>
                                       
                                      
                                    </Col>
                                </Row>
                             
                                                <div>
                                                {/* <CountdownCircleTimer
                                                        isPlaying
                                                        duration={60}
                                                        initialRemainingTime={timer}
                                                        colors="#A30000"
                                                        size={100}
                                                    >
                                                 {({ remainingTime }) => remainingTime}
                                                </CountdownCircleTimer> */}
                                                </div>
                                       
                            </Container>
                        </>
                        
                    )
                }
                            </div>
                        ) :(<></>)
                    }
                    
               
                </Col>
            </Row>
            </Container>



            {/* {
                !editOption ?
                    (
                        <div className="user-profile-page">

                            <section>
                                <div class="container mt-4">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <img
                                                src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg} className='profileEdit' alt="profile" />
                                            <div class="edit-icon" onClick={() => changeEditOption(true)}>
                                                <img src={EditImg} class="img-fluid" alt="eidt" /><span className='cur-pointer'>Edit</span>
                                            </div>
                                          <div className='user-profile-actions-outer'>
                                          <Row>
                                            
                                            <Row className="user-profile-details">
                                                <div className='user-profile-actions-inner'>
                                                <Col md={6} className="profile-left">
                                                    <p>User Name</p>
                                                    <p>Phone Number</p>
                                                    <p>Email Id</p>


                                                </Col>
                                                <Col md={6} className="profile-right">
                                                    <p>{user?.username}</p>
                                                    <p>{user?.mobile_no}</p>
                                                    <p>{user?.email_id}
                                                        {
                                                            user?.email_verified ? (
                                                                <span className="text-success ms-3 "> verified</span>
                                                            )
                                                                : (
                                                                    <span className="text-primary ms-3 cur-pointer" onClick={requestVerifyEmail}> Get verify</span>
                                                                )
                                                        }

                                                    </p>

                                                </Col>
<div>
                                                {
                                                optInput && (
                                                    <Row className="profile-verify-otp">
                                                        <Col md={12}>
                                                            <Form onSubmit={formikVerify.handleSubmit}>
                                                                <FormikProvider value={formikVerify}>
                                                                    <InputGroup className="mb-3 p-2" controlId="formBasicEmail">
                                                                        <Form.Control
                                                                            className="input-border"
                                                                            type="text"
                                                                            placeholder="Enter otp"
                                                                            name="otp"
                                                                            onChange={formikVerify.handleChange}
                                                                            value={formikVerify.values.otp}
                                                                            isInvalid={!!formikVerify.touched.otp && !!formikVerify.errors.otp}
                                                                        />

                                                                        <Button
                                                                            className="Verify-Border"
                                                                            // onClick={handleVerifyEmailOtp}
                                                                            type="submit"
                                                                        >
                                                                            Verify
                                                                        </Button>

                                                                        <Form.Control.Feedback type="invalid">
                                                                            {formikVerify.errors.otp}
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                    <p className="text-end text-primary cur-pointer profile-resend-btn" onClick={resendOTPviaMail}>Resend</p>

                                                                </FormikProvider>
                                                            </Form>


                                                        </Col>

                                                    </Row>
                                                )
                                            }
                                            </div>

                                            <Row className="user-profile-actions">
                                                <div className="profile-left changepwd-signout">

                                                    <p className="text-primary my-3 cur-pointer" onClick={changePasswordOption}>
                                                        Change Password
                                                    </p>
                                                    <p className="text-primary my-3 cur-pointer" onClick={signoutOption}>
                                                        Signout
                                                    </p>

                                                </div>
                                                <Col>
                                                </Col>
                                            </Row>
                                                </div>
                                            </Row>
                                            


                                            
                                            

                                          
                                            
                                            

                                          </Row>
                                          </div>

                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    )
                    :
                    (
                        <>
                           
                        </>

                    )
            } */}

              <div className='user-view-footer'>
              <Footer />
              </div>
        </div>
    );
}

export default UserProfilePage;




