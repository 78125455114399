import React from 'react';
import Appointment from '../Appointment';
import DashboardHeader from '../DashboardHeader';
import BusinessSidebar from '../../../components/elements/siderbar';
import StaffCalendar from '../Appointment/StaffCalendar';
import { Col, Row } from 'react-bootstrap';

function StaffCalendarView() {
    return (
        <div className="staffCalendarView">
            <DashboardHeader />
            <div className="dashboard-body">
            <div className="">
                    <Row>
                        <Col className='p-0' md={2} sm={12}>
                            <div className="sidebar-content p-0">
                                <BusinessSidebar />
                            </div>
                        </Col>
                        <Col md={10} sm={12}>
                            <div className="main-content">
                                {/* <Calendar /> */}
                                <StaffCalendar />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default StaffCalendarView;