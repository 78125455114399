import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/elements/header/Header";
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import {Form,Modal,Alert,Spinner,Button,Container,Row,Col} from "react-bootstrap";
import TimePickerHelper from "../../helpers/TimePickerHelper";
import TimePicker from 'react-gradient-timepicker';
import { BsPlusLg, BsTrash, BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { businessTimingValidation } from "../../validations/yub_schemas/businessTimingInformation";
import BusinessTimingImg from "../../assets/images/vendor_icons/Complete-task.svg";
import MultiStepFormContext from "./MultiStepFormContext";
import { useDispatch, useSelector } from 'react-redux';
import { freelancerValidation } from "../../validations/yub_schemas/freelancerValidation";




const BusinessStep2 = () => {

    const iconStyle = { color: "#DC3545", fontSize: "1.5em", cursor: "pointer" };
    const iconBtnStyle = { color: "#FFF", fontSize: "1em", cursor: "pointer" };
    const [errorMessage, setErrorMessage] = useState(false);

    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    const [openAt, setOpenAt] = useState("");
    const [closeAt, setCloseAt] = useState("");

    const [mondayOpenAt, setMondayOpenAt] = useState("");
    const [mondayCloseAt, setMondayCloseAt] = useState("");

    const [timeSlot, setTimeSlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [mondaySlot, setMondaySlot] = useState(
        [
            {
                mondayOpenAt: "",
                mondayCloseAt: ""
            }
        ]
    );
    const [tuesdaySlot, setTuesdaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [wednesdaySlot, setWednesdaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [thurdaySlot, setThurdaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [fridaySlot, setFridaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [SatdaySlot, setSatdaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );
    const [sundaySlot, setSundaySlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const addTimeSlot = () => {
            setTimeSlot([...timeSlot, { open_at: "", open_at: "" }])
            formik.business_basic_hours = "";

    }
    const remoteTimeSlot = (index) => {
        let newFormValues = [...timeSlot];
        newFormValues.splice(index, 1);
        setTimeSlot(newFormValues)
    };
    const addTimeSlotMonday = () => {

        setMondaySlot([...mondaySlot, { mondayOpenAt: "", mondayCloseAt: "" }])
    }
     const addTimeSlotTuesDay = () => {

        setTuesdaySlot([...tuesdaySlot, { open_at: "", open_at: "" }])
    } 
    const addTimeSlotThursday = () => {

        setThurdaySlot([...thurdaySlot, { open_at: "", open_at: "" }])
    } 
    const addTimeSlotFriday = () => {

        setFridaySlot([...fridaySlot, { open_at: "", open_at: "" }])
    }
     const addTimeSlotSat = () => {

        setSatdaySlot([...SatdaySlot, { open_at: "", open_at: "" }])
    }
     const addTimeSlotSun = () => {

        setSundaySlot([...sundaySlot, { open_at: "", open_at: "" }])
    }
  
    const removeTimeSlotMon = (index) => {
        let newFormValues = [...mondaySlot];
        newFormValues.splice(index, 1);
        setMondaySlot(newFormValues)
    };
    const removeTimeSlottue = (index) => {
        let newFormValues = [...tuesdaySlot];
        newFormValues.splice(index, 1);
        setTuesdaySlot(newFormValues)
    };
    const removeTimeSlotWed = (index) => {
        let newFormValues = [...wednesdaySlot];
        newFormValues.splice(index, 1);
        setWednesdaySlot(newFormValues)
    };
    const removeTimeSlotThurs = (index) => {
        let newFormValues = [...thurdaySlot];
        newFormValues.splice(index, 1);
        setThurdaySlot(newFormValues)
    };
    const removeTimeSlotFri = (index) => {
        let newFormValues = [...fridaySlot];
        newFormValues.splice(index, 1);
        setFridaySlot(newFormValues)
    };
    const removeTimeSlotSat = (index) => {
        let newFormValues = [...SatdaySlot];
        newFormValues.splice(index, 1);
        setSatdaySlot(newFormValues)
    };
    const removeTimeSlotSun = (index) => {
        let newFormValues = [...sundaySlot];
        newFormValues.splice(index, 1);
        setSundaySlot(newFormValues)
    };
    const [dayslot, setDaySlot] = useState(false)

    const { step2Details, setStep2Details, next, prev } = useContext(MultiStepFormContext);

        const formik = useFormik({
            enableReinitialize: true,
            initialValues: {
                business_type: step2Details.business_type || "",
                age_category: step2Details.age_category || [],
                service_category: step2Details.service_category || [],
                business_open_at : "",
                business_close_at : "",
                business_basic_hours: step2Details.business_basic_hours || [{
                    'open_at': "",
                    "close_at": ""
                }],
                business_open_days: step2Details.business_open_days || [],
                business_days_hours: step2Details.business_days_hours || [],
                advance_percentage: step2Details.advance_percentage,
            },
            validationSchema: vendorType === "business" ? businessTimingValidation : freelancerValidation,
            onSubmit: (values) => {
                values["business_days_hours"] = {}
                if(vendorType === "business"){
                    values.business_basic_hours.forEach(item => {
                        if(item.open_at > item.close_at && item.open_at == item.close_at){
                            setErrorMessage(true)
                        }
                        else{
                            values["business_open_days"].forEach((item) => {
                                values["business_days_hours"][item] = { ...values['business_basic_hours'].map(item => item) }
                            })            
                            values["serviceTypes"] = values["business_type"];
                            values["gender"] = values["age_category"];
                
                            setStep2Details(values);
                            next();
                        }
                    })
                } else {
                    values["advance_percentage"] = values["advance_percentage"];         
                    values["serviceTypes"] = values["business_type"];
                    values["gender"] = values["age_category"];
        
                    setStep2Details(values);
                    next();
                }
                // values.business_basic_hours.forEach(item => {
                //     if(item.open_at > item.close_at && item.open_at == item.close_at){
                //         setErrorMessage(true)
                //     }
                //     else{
                //         values["business_open_days"].forEach((item) => {
                //             values["business_days_hours"][item] = { ...values['business_basic_hours'].map(item => item) }
                //         })            
                //         values["serviceTypes"] = values["business_type"];
                //         values["gender"] = values["age_category"];
            
                //         setStep2Details(values);
                //         next();
                //     }
                // })
    
                
    
               
            },
        });

    
   

    const onBack = () => {
        prev();
    }

    const handleCheckboxChange = (e) => {
        const { checked, value, name } = e.target;
        if (checked) {
            formik.setFieldValue(name, [...formik.values[name], value]);
        } else {
            formik.setFieldValue(
                name,
                formik.values[name].filter((v) => v !== value)
            );
        }
    };

    const removeservice = (data, index) => {
        formik.values.service_category.splice(index, 1);
        formik.setFieldValue(data);
    }
    const handleTimeChange = async(e, index, type) => {
    
        const { value, name } = e.target;
        let currObject = formik.values.business_basic_hours[index];
        formik.setFieldTouched(name, true);
       
        formik.values.business_basic_hours[index] = { ...currObject }
        formik.setFieldValue(name, value);

        await Promise.resolve()

        if(type == "open_at"){
           
            setOpenAt(value)
        }
        if(type == "close_at"){
            
            setCloseAt(value)
        }
        formik.setFieldTouched(name, true);
               
         validateTimeSettings()
       
    };

    // const handleTimeChangeMonday = async(e, index, type) => {
    
    //     const { value, name } = e.target;
    //     let currObject = formik.values.business_basic_hours_monday[index];
    //     formik.setFieldTouched(name, true);
       
    //     formik.values.business_basic_hours_monday[index] = { ...currObject }
    //     formik.setFieldValue(name, value);

    //     await Promise.resolve()

    //     if(type == "mondayOpenAt"){
           
    //         setMondayOpenAt(value)
    //     }
    //     if(type == "mondayCloseAt"){
            
    //         setMondayCloseAt(value)
    //     }
    //     formik.setFieldTouched(name, true);
       
        
    //     console.log(formik.values)
        
    //     //  validateTimeSettings()
       
    // };

    const validateTimeSettings = () => {
        console.log(openAt+ " : " +closeAt)
    }

  



    return (
        <div className="businessStep2 mt-5">
            <Container>
                <Row>
               
                    <Col md={12} sm={12}>
                        <Form noValidate className="business-register-form text-start px-5" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                <div className="step-one-title">
                                    <h4>Establishment type *</h4>
                                    <p>Select most relevant category for your Business type</p>
                                </div>
                                <Form.Group className="mb-3 p-2" controlId="businessType">

                                    {
                                        vendorType && vendorType === "business" && (
                                            <>
                                                <Form.Check
                                                    className="mb-2"
                                                    type='radio'
                                                    id='shop_only'
                                                    name="business_type"
                                                    label="Shop only"
                                                    value="Shop only"
                                                    checked={formik.values.business_type === "Shop only"}
                                                    onChange={() => formik.setFieldValue("business_type", "Shop only")}
                                                    isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                />
                                                <Form.Check
                                                    type='radio'
                                                    id='shop_and_home'
                                                    name="business_type"
                                                    label="Both Shop and Home service"
                                                    value="Both Shop and Home service"
                                                    checked={formik.values.business_type === "Both Shop and Home service"}
                                                    onChange={() => formik.setFieldValue("business_type", "Both Shop and Home service")}
                                                    isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                />
                                            </>
                                        )
                                    }

                                    {
                                        vendorType && vendorType === "freelancer" && (
                                            <Form.Check
                                                className="mb-2"
                                                type='radio'
                                                id='home_service'
                                                name="business_type"
                                                label="Home service"
                                                value="Home service"
                                                checked={formik.values.business_type === "Home service"}
                                                onChange={() => formik.setFieldValue("business_type", "Home service")}
                                                isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                            />
                                        )
                                    }
                                </Form.Group>

                                <div className="step-one-title mt-5">
                                    <h4>Category *</h4>
                                    <p>Select most relevant category for your Business type</p>
                                </div>

                                <Form.Group className="mb-3 p-2" controlId="ageCategory">

                                    <Form.Check
                                        className="mb-2"
                                        type='checkbox'
                                        id='Men'
                                        name="age_category"
                                        label="Men"
                                        value="Men"
                                        checked={formik.values.age_category.includes("Men")}
                                        onChange={handleCheckboxChange}
                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                    />
                                    <Form.Check
                                        className="mb-2"
                                        type='checkbox'
                                        id='Women'
                                        name="age_category"
                                        label="Women"
                                        value="Women"
                                        checked={formik.values.age_category.includes("Women")}
                                        onChange={handleCheckboxChange}
                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                    />
                                    <Form.Check
                                        type='checkbox'
                                        id='Kids'
                                        name="age_category"
                                        label="Kids"
                                        value="Kids"
                                        checked={formik.values.age_category.includes("Kids")}
                                        onChange={handleCheckboxChange}
                                        isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.age_category}
                                    </Form.Control.Feedback>


                                </Form.Group>

                                <div className="step-one-title mt-5">
                                    <h4>Select options which best describe your Business Services *</h4>
                                    <p>Select all types services you provide</p>
                                    <div className="my-4">
                                        <h3 className="selected-text-category">
                                            Selected:
                                        </h3>

                                        {
                                            formik.values.service_category.map((item, index) => {
                                                return (
                                                    <span key={index} className="badge badge-bg me-2 px-2" onClick={()=>removeservice(item, index)}>
                                                        {item} <span><ion-icon name="close-outline"></ion-icon></span>
                                                    </span>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                                <Form.Group className="mb-3 p-2" controlId="serviceCategory">
                                    <Row>
                                        <Col>
                                        <Form.Check
                                                className="mb-3"
                                                type='checkbox'
                                                id='Hair'
                                                name="service_category"
                                                label="Hair"
                                                value="Hair"
                                                checked={formik.values.service_category.includes("Hair")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        <Col>
                                        <Form.Check
                                                className="mb-3"
                                                type='checkbox'
                                                id='Nails'
                                                name="service_category"
                                                label="Nails"
                                                value="Nails"
                                                checked={formik.values.service_category.includes("Nails")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        <Col>
                                        <Form.Check
                                                type='checkbox'
                                                id='Spa'
                                                name="service_category"
                                                label="Spa"
                                                value="Spa"
                                                checked={formik.values.service_category.includes("Spa")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        <Col>
                                        <Form.Check
                                                className="mb-3"
                                                type='checkbox'
                                                id='Skin'
                                                name="service_category"
                                                label="Skin"
                                                value="Skin"
                                                checked={formik.values.service_category.includes("Skin")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Form.Check
                                                className="mb-3"
                                                type='checkbox'
                                                id='Makeup'
                                                name="service_category"
                                                label="Makeup"
                                                value="Makeup"
                                                checked={formik.values.service_category.includes("Makeup")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        <Col>
                                        <Form.Check
                                                type='checkbox'
                                                id='Mehendi'
                                                name="service_category"
                                                label="Mehendi"
                                                value="Mehendi"
                                                checked={formik.values.service_category.includes("Mehendi")}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                            />
                                        </Col>
                                        <Col>
                                        
                                        </Col>
                                        <Col>
                                        
                                        </Col>
                                        
                                    </Row>

                                   

                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.service_category}
                                    </Form.Control.Feedback>

                                </Form.Group>
                                {
                                    vendorType && vendorType === "business" ? (
                                        <>
                                            <div className="step-one-title mt-5">
                                                <h4>Business operational hours *</h4>
                                                <p>Mark Business opening and closing hours</p>
                                            </div>
                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningHours">
                                                <Row className="mb-2">
                                                    <Col md={6}>
                                                        <h5 className="text-muted">
                                                            Opens At
                                                        </h5>
                                                    </Col>

                                                    <Col md={6}>
                                                        <h5 className="text-muted">
                                                            Closes At
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                {
                                                    timeSlot.map((slot, index) => {
                                                        const fieldName = `business_basic_hours[${index}]`;
                                                        return (
                                                            <div key={index}>
                                                                <Row className="mb-4">
                                                                    <Col md={5}>
                                                                        <Form.Control
                                                                            required
                                                                            type="time"
                                                                            format="HH:MM a"
                                                                            name={`${fieldName}.open_at`}
                                                                            onChange={(e) => {
                                                                                setOpenAt(e.target.value)
                                                                                formik.setFieldValue("business_open_at", e.target.value);
                                                                                handleTimeChange(e, index, "open_at");

                                                                            }}

                                                                            // value={formik.values.business_basic_hours}
                                                                            // value={formik.values.business_open_at}
                                                                            isInvalid={!!formik.errors.business_basic_hours}
                                                                            step={60}
                                                                        />
                                                                    </Col>

                                                                    <Col md={5}>
                                                                        <Form.Control
                                                                            required
                                                                            type="time"
                                                                            format="hh:mm"
                                                                            name={`${fieldName}.close_at`}
                                                                            // onChange={(e) => handleTimeChange(e, index, "close_at")}
                                                                            onChange={(e) => {
                                                                                setCloseAt(e.target.value)
                                                                                formik.setFieldValue("business_close_at", e.target.value);
                                                                                handleTimeChange(e, index, "close_at")
                                                                                console.log(formik.values.business_close_at)

                                                                            }}
                                                                            // value={formik.values.business_close_at}
                                                                            isInvalid={!!formik.errors.business_basic_hours}
                                                                            step={60}

                                                                        />

                                                                    </Col>

                                                                    {
                                                                        index != 0 &&
                                                                        <Col md={2}>
                                                                            {/* <BsTrash style={iconStyle} /> */}
                                                                            <span className="deleteTrash" onClick={() => remoteTimeSlot(index)}>
                                                                                <ion-icon name="trash-outline"></ion-icon>
                                                                            </span>
                                                                        </Col>
                                                                    }

                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                    )

                                                }

                                                {/* {
                                        mondaySlot.map((slot, index) => {
                                            const fieldName = `business_basic_hours_monday[${index}]`;
                                            return (
                                                <div key={index}>
                                                    <Row className="mb-4">
                                                        <Col md={5}>
                                                            <Form.Control
                                                                required
                                                                type="time"
                                                                format="HH:MM a"
                                                                name={`${fieldName}.mondayOpenAt`}
                                                                onChange={(e) => {
                                                                    setMondayOpenAt(e.target.value)
                                                                    formik.setFieldValue("business_open_at_monday", e.target.value);
                                                                    handleTimeChangeMonday(e, index, "mondayOpenAt");
                                                                    
                                                                }}
                                                                step={60}
                                                            />
                                                        </Col>
                                                     
                                                        <Col md={5}>
                                                            <Form.Control
                                                                required
                                                                type="time"
                                                                format="hh:mm"
                                                                name={`${fieldName}.mondayCloseAt`}
                                                                onChange={(e) => {
                                                                    setMondayCloseAt(e.target.value)
                                                                    formik.setFieldValue("business_close_at_monday", e.target.value);
                                                                    handleTimeChangeMonday(e, index, "mondayCloseAt")
                                                                    
                                                                }}
                                                                isInvalid={!!formik.errors.business_basic_hours}
                                                                step={60}

                                                            />

                                                        </Col>
                                                       
                                                        {
                                                            index != 0 &&
                                                            <Col md={2}>
                                                                <span className="deleteTrash" onClick={() => removeTimeSlotMon(index)}>
                                                                <ion-icon name="trash-outline"></ion-icon>
                                                                </span>
                                                            </Col>
                                                        }
                                                      
                                                    </Row>
                                                </div>
                                            )
                                        }
                                        )
                                        
                                    } */}
                                                {
                                                    <p className={errorMessage ? "text-danger" : "text-warning"}>The Closing time shouldn't be less that the opening time.</p>
                                                }


                                            </Form.Group>

                                            <div className="mx-3">
                                                <div className="add-time-slot">
                                                    <span><ion-icon name="add-outline"></ion-icon></span>
                                                    <p className="ms-2" onClick={() => addTimeSlot()}>Add Time Slot</p>
                                                </div>

                                            </div>

                                            <div className="step-one-title mt-5">
                                                <h4>Mark open days *</h4>
                                                <p>Don’t forget to uncheck your off-day</p>
                                            </div>
                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningDays">
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Monday'
                                                    name="business_open_days"
                                                    label="Monday"
                                                    value="Monday"
                                                    checked={formik.values.business_open_days.includes("Monday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Tuesday'
                                                    name="business_open_days"
                                                    label="Tuesday"
                                                    value="Tuesday"
                                                    checked={formik.values.business_open_days.includes("Tuesday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Wednesday'
                                                    name="business_open_days"
                                                    label="Wednesday"
                                                    value="Wednesday"
                                                    checked={formik.values.business_open_days.includes("Wednesday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Thursday'
                                                    name="business_open_days"
                                                    label="Thursday"
                                                    value="Thursday"
                                                    checked={formik.values.business_open_days.includes("Thursday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Friday'
                                                    name="business_open_days"
                                                    label="Friday"
                                                    value="Friday"
                                                    checked={formik.values.business_open_days.includes("Friday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Saturday'
                                                    name="business_open_days"
                                                    label="Saturday"
                                                    value="Saturday"
                                                    checked={formik.values.business_open_days.includes("Saturday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Sunday'
                                                    name="business_open_days"
                                                    label="Sunday"
                                                    value="Sunday"
                                                    checked={formik.values.business_open_days.includes("Sunday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                                />
                                            </Form.Group>
                                            {/* <div className="my-3">
                                                <h6 className="slaytextBlack">Want more options ? <span className="slayText">Apply advance settings</span></h6>
                                            </div> */}

                                        
                                         {/* <div className="step-one-title">
                                    <h4>Advance Percentage *</h4>
                                    <p>Select most relevant category for your Business type</p>
                                   </div>
                                   <Form.Group className="mb-3 p-2" controlId="advance_percentage">
                                        <Form.Select
                                            name="advance_percentage"
                                            onChange={formik.handleChange}
                                            value={formik.values.advance_percentage}
                                            isInvalid={!!formik.touched.advance_percentage && !!formik.errors.advance_percentage}
                                        >
                                    <option value="">Open this select menu</option>
                                    <option value="10">10 Percentage</option>
                                    <option value="15">15 Percentage</option>
                                    <option value="20">20 Percentage</option>
                                    <option value="25">25 Percentage</option>
                                    <option value="30">30 Percentage</option>
                                    <option value="35">35 Percentage</option>
                                    <option value="40">40 Percentage</option>
                                    <option value="45">45 Percentage</option>
                                    <option value="50">50 Percentage</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.advance_percentage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    */}

                                    
                                        </>
                                    ) : (
                                        <></>
                                //         <>
                                //          <div className="step-one-title">
                                //     <h4>Advance Percentage *</h4>
                                //     <p>Select most relevant category for your Business type</p>
                                //    </div>
                                //    <Form.Group className="mb-3 p-2" controlId="advance_percentage">
                                //         <Form.Select
                                //             name="advance_percentage"
                                //             onChange={formik.handleChange}
                                //             value={formik.values.advance_percentage}
                                //             isInvalid={!!formik.touched.advance_percentage && !!formik.errors.advance_percentage}
                                //         >
                                //     <option value="">Open this select menu</option>
                                //     <option value="10">10 Percentage</option>
                                //     <option value="15">15 Percentage</option>
                                //     <option value="20">20 Percentage</option>
                                //     <option value="25">25 Percentage</option>
                                //     <option value="30">30 Percentage</option>
                                //     <option value="35">35 Percentage</option>
                                //     <option value="40">40 Percentage</option>
                                //     <option value="45">45 Percentage</option>
                                //     <option value="50">50 Percentage</option>

                                //         </Form.Select>
                                //         <Form.Control.Feedback type="invalid">
                                //             {formik.errors.advance_percentage}
                                //         </Form.Control.Feedback>
                                //     </Form.Group>
                                //         </>
                                    )

                                }
                                

                              
                                {/* <div className="mx-3">
                                    <div className="add-time-slot">
                                        <span><ion-icon name="add-outline"></ion-icon></span>
                                    <p className="ms-2" onClick={() => addTimeSlotMonday()}>Add Time Slot</p>
                                    </div>

                                </div> */}
                               
                                <div className="text-center my-5">
                                    <Row>
                                        <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                                            <button class="back-btn-businessSteps" size="lg" onClick={onBack}>
                                                Back
                                            </button>
                                        </Col>
                                        <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                                            <Button variant="" className="next-btn-businessSteps" type="submit" size="lg">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </FormikProvider>
                        </Form>
                    </Col>

                    {/* <Col md={4} sm={12}>
                        <div className="form-image-container mt-5">
                            <img className="img-fluid" src={BusinessTimingImg} />
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default BusinessStep2;