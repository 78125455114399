import * as TYPES from './types.js';

const initialState = {
    user: {},
    role: {},
    getUserLoading: false,
    isLoggedIn: localStorage.getItem('access') ? true : false,
    forgotPasswordSent: false,
    needToLogin: false,
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.SIGNUP_SUCCESS:
            return {
                ...state,
                user: payload,
                isLoggedIn: false,
            };
        case TYPES.SIGNUP_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.VERIFY_SIGNUP_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.VERIFY_SIGNUP_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                access: payload.accessToken,
                refresh: payload.accessToken,
                user: payload,
                role: payload.roles[0]
            };
        case TYPES.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case TYPES.GET_ME_REQUEST:
            return {
                ...state,
                getUserLoading: true,
            };
        case TYPES.GET_ME_SUCCESS:
            return {
                ...state,
                user: payload.user,
                getUserLoading: false,
            };
        case TYPES.GET_ME_FAIL:
            return {
                ...state,
                getUserLoading: false,
            };
        case TYPES.CHANGE_USER_AVATAR_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: { ...state.user, avatar: payload.data },
            };
        case TYPES.LOGIN_REFRESH_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                access: payload.accessToken,
                refresh: payload.accessToken,
            };
        case TYPES.LOGIN_REFRESH_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                access: null,
                refresh: null,
            };
        case TYPES.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                user: payload,
                isLoggedIn: false,
                forgotPasswordSent: true,
            };
        case TYPES.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordSent: false,
            };
            case TYPES.CHANGE_EMAIL_SUCCESS:
                return {
                    ...state,
                    user: payload,
                    isLoggedIn: true,
                };
            case TYPES.CHANGE_EMAIL_FAIL:
                return {
                    ...state,
                    isLoggedIn: true,
                };
        case TYPES.VERIFY_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: payload.code,
            };
        case TYPES.VERIFY_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
        case TYPES.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                emailCode: payload.code,
            };
        case TYPES.VERIFY_EMAIL_FAIL:
            return {
                ...state,
                isLoggedIn: true,
                emailCode: null,
            };
        case TYPES.VERIFIED_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
        case TYPES.VERIFIED_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                forgotPasswordCode: null,
            };
            case TYPES.CHANGE_PASSWORD_SUCCESS:
                return {
                    ...state,
                    isLoggedIn: true,
                    forgotPasswordCode: null,
                };
            case TYPES.CHANGE_PASSWORD_FAIL:
                return {
                    ...state,
                    isLoggedIn: true,
                    forgotPasswordCode: null,
                };
        case TYPES.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                access: null,
                refresh: null,
                user: null,
                role: null,
                getUserLoading: false,
                forgotPasswordSent: false,
                needToLogin: false,
            };
        case TYPES.NEED_TO_LOGIN:
            return {
                ...state,
                needToLogin: payload,
            };
        case TYPES.ADD_FOLLOWING_COUNT_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    following_count: state.user?.following_count + 1,
                },
            };
        case TYPES.REDUCE_FOLLOWING_COUNT_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    following_count: state.user?.following_count - 1,
                },
            };
            case TYPES.SUPPORT_MESSAGE_SUCCESS:
                return {
                    ...state,
                    isLoggedIn: true,
                    message: payload.message,
                };
            case TYPES.SUPPORT_MESSAGE_FAIL:
                return {
                    ...state,
                    isLoggedIn: true,
                    message: payload.message,
                }
                // case TYPES.SUPPORT_ORDER_SUCCESS:
                //     return {
                //         ...state,
                //         isLoggedIn: true,
                //         orders: payload.orders,
                //     };
                // case TYPES.SUPPORT_ORDER_FAIL:
                //     return {
                //         ...state,
                //         isLoggedIn: true,
                //         orders: payload.orders,
                //     }
            


        default:
            return state;
    }
};

export default authReducer;
