import React, { useEffect, useState, useContext, Fragment } from 'react'
import TickImg from "../../../assets/images/Tick.svg";
import WrongImg from "../../../assets/images/Wrong.svg";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container, Breadcrumb, Table, Modal } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction, getUserOneRequestAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import { MdOutlineNavigateNext } from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import { requestAcceptDenyAction } from '../../../redux/dashboard/actions';


function RequestView() {
    const [orderDetails, setOrderDetails] = useState({});
    const [acceptModal, setAcceptModal] = useState(false);
    const [successModal, setSucessModal] = useState(false);
    const [failureModal, setFailureModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const  { id } = useParams("");

    useEffect(() => {

        let data = {
            id: id,
        }
        dispatch(getUserOneRequestAction(data))
            .then((res) => {
                console.log("Res => ", res);
                console.log(res)
                setOrderDetails(res)

            })
            .catch((err) => {
                console.log(err)
            })
        

    },[id]);


const readAgain = () => {
    let data = {
        id: id,
    }
    dispatch(getUserOneRequestAction(data))
        .then((res) => {
            console.log("Res => ", res);
            console.log(res)
            setOrderDetails(res)

        })
        .catch((err) => {
            console.log(err)
        })
}  
const goBack = () =>{
    navigate("/dashboard/request")
}

const acceptRequest = async() => {
    const data = {
        id:id,
        request_status:"accept"
      };
  
      await dispatch(requestAcceptDenyAction(data))
        .then((res) => {
          setAcceptModal(false);
          setSucessModal(true);
          readAgain();
        })
        .catch((err) => {
          console.log("Err => ", err)
        })
  
  }
  const rejectRequest = async()=>{
    const data = {
        id: id,
        request_status:"deny"
      };
  
      await dispatch(requestAcceptDenyAction(data))
        .then((res) => {
          setRejectModal(false);
          setFailureModal(true);
          readAgain();
        })
        .catch((err) => {
          console.log("Err => ", err)
        })
  
  }
    return (
        <Fragment>
         <div className='container-xxl'>
         <Container fluid className='single-view-request'>
            <Row className='px-1 mt-4'>
                <Col md={11} sm={9} xs={9}>
                    <h1>Service details</h1>
                    <p>Upon accepting the order, a payment link will be sent to the customer. An order will be created in the appointment section once the customer has paid </p>
                </Col>
                <Col md={1} sm={3} xs={3}>
                <div className='goback-to-request' onClick={goBack}>
                    <span>                <ion-icon name="chevron-back-outline"></ion-icon>
</span>
                    <h6>back</h6>
                </div>
                </Col>
            </Row>
            <hr />
            <Row className='px-2'>
                <h2>{orderDetails?.results?.service_provider?.business_name}</h2>
                <p className='mt-2'>{orderDetails?.results?.service_provider?.location_details?.address + "" + orderDetails?.results?.service_provider?.location_details?.area + ", " + orderDetails?.results?.service_provider?.location_details?.city + ", " + orderDetails?.results?.service_provider?.location_details?.state + " " + orderDetails?.results?.service_provider?.location_details?.zipcode}</p>

            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <Table borderless >

                        <tbody >
                            <tr>
                                <Row >
                                    <Col md={3} sm={3} xs={3}>
                                        <td className='request-info-headers'>Request Id:</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                        <td>{orderDetails?.results?.request_id}</td>

                                    </Col>
                                </Row>
                            </tr>
                            <tr>
                                <Row>
                                    <Col md={3} sm={3} xs={3}>
                                        <td>Appointment Date / Time:</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                    <td>{moment(orderDetails?.results?.selected_date).format("DD MMM YYYY") + ", " + orderDetails?.results?.selected_time}</td>
                                    </Col>
                                </Row>
                            </tr>
                            <tr>
                                <Row>
                                    <Col md={3} sm={3} xs={3}>
                                        <td className='request-info-headers'>Service Type</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                    <td>{orderDetails?.results?.service_type}</td>
                                    </Col>
                                </Row>
                            </tr>
                            <tr>
                                <Row>
                                    <Col md={3} sm={3} xs={3}>
                                        <td className='request-info-headers'>Contact Details:</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                    <td>{orderDetails?.results?.alternate_phone}</td>
                                    <h6 className='request-view-email'>{orderDetails?.results?.alternate_email}</h6>


                                    </Col>
                                </Row>
                            </tr>
                            <tr>
                                <Row>
                                    <Col md={3} sm={3} xs={3}>
                                        <td className='request-info-headers'>Venu Address:</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                        <td>{orderDetails?.results?.address + ", " + orderDetails?.results?.city + ", " + orderDetails?.results?.state + ", " + orderDetails?.results?.country + ", " + orderDetails?.results?.zipcode}</td>
                                    </Col>
                                </Row>
                            </tr>
                            <tr>
                                <Row>
                                    <Col md={3} sm={3} xs={3}>
                                        <td className='request-info-headers'>Message:</td>

                                    </Col>
                                    <Col md={9} sm={9} xs={9}>
                                    {orderDetails?.results?.message == "" ? (
                                        <td>No Messages to read</td>

                                    ): (
                                        <td>{orderDetails?.results?.message }</td>

                                    )
                                }
                                    </Col>
                                </Row>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Modal show={acceptModal} onHide={()=>setAcceptModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title>Accept Request?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <p>Are you sure you want to accept the request?</p>

                 <Row className='mt-5 px-2'>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn-close w-100' variant="" onClick={()=>setAcceptModal(false)}>
                  Cancel                 
                   </Button>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn w-100' variant="" onClick={acceptRequest}>  
                  Accept             
                  </Button>
                  </Col>
                 </Row>
               
                
                </Modal.Body>
                
           </Modal>
            <Modal show={rejectModal} onHide={()=>setRejectModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title>Reject Request?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <p>Are you sure you want to reject the request?</p>

                 <Row className='mt-5 px-2'>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn-close w-100' variant="" onClick={()=>setRejectModal(false)}>
                  Cancel                 
                   </Button>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn w-100' variant="" onClick={rejectRequest}>  
                   Reject             
                  </Button>
                  </Col>
                 </Row>
               
                
                </Modal.Body>
                
           </Modal>

           <Modal show={successModal} onHide={()=>setSucessModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title className='mt-3'>
                    <img src={TickImg} className="img-fluid" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <h6>Request Successfully Accepted!</h6>
                <p>note</p>
                <h5 className="close-text" onClick={()=>setSucessModal(false)}>Close</h5> 
                </Modal.Body>
                
           </Modal>
           <Modal show={failureModal} onHide={()=>setFailureModal(false)} centered className='text-center'>
           <Modal.Header>
                  <Modal.Title className='mt-3'>
                    <img src={WrongImg} className="img-fluid" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <h6>Request Successfully Rejected!</h6>
                <p>note</p>
                <h5 className="close-text" onClick={()=>setFailureModal(false)}>Close</h5> 
                </Modal.Body>
           </Modal>

            <Row>
                <h3>Service details</h3>
                <Row  className='px-4'>
                <Table borderless hover>
      <thead>
        <tr>
          <th>Service Title</th>
          <th className='text-center'>Service Category</th>
          <th className='text-center'>Service Price</th>
          <th className='text-center'>Customer Category</th>
        </tr>
      </thead>
      <tbody>
     
               {/* {
           orderDetails?.results?.services?.map((item, index)=>{
                return(
                    <tr key={item?._id}>
                    <td colSpan={2}>{item?.service_name}</td>
                    <td>{item?.servicecategory_name}</td>
                    <td>{item?.gender}</td>
                  </tr>
                )
            })
        }
       */}

{
                                                orderDetails?.results?.services && orderDetails?.results?.services.length > 0 && (
                                                    orderDetails?.results?.services?.map((item, index) => {
                                                   
                                                        return (
                                                            <tr key={index}>
                                                         
                                                                <td>{item?.service_name}</td>
                                                                <td>{item?.service_category?.[0]?.servicecategory_name}</td>
                                                                <td>{ item?.service_price?.["$numberDecimal"] +" ₹ "}</td>
                                                                <td>{item?.gender?.[0]?.gender_name}</td>
                                                        </tr>
        
                                                        );
                                                    })
                                                )
                                            }
      </tbody>
               </Table>
                </Row>
            </Row>
            {
                    orderDetails?.results?.request_status == "pending" && (
                        <Row className='request-decision-btn'>
               
                        <Col md={6} sm={6} xs={6}>
                        <Button className='request-reject-btn' variant='' onClick={()=>setRejectModal(true)}>Reject</Button>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                                <Button className='request-accept-btn' variant="" onClick={()=>setAcceptModal(true)}>Accept</Button>
                                </Col>
                       
                      
                    </Row>
                    )
                }

          
            </Container>
         </div>
        </Fragment>
    )
}

export default RequestView