import React from 'react';
// import styles from "../assets/css/stepper.css";

function Stepper(props) {
  return (
    <div className='progress-bar-wrapper'>
      <ul className='step-progress-bar'>
        {props.steps.map(function (step, i) {
          return (
            <li
              key={i}
              className={
                `progress-step 
                ${props.currentStep == i ? 'current' : ''}
                ${props.currentStep > i ? 'completed' : ''}
                ${props.currentStep == i && props.hasError  ? 'has-error' : ''}
                `}
            >
              {
                (props.currentStep == i || props.currentStep < i) && (
                  <span className='step-index'>{i + 1}</span>
                )
              }

              {
                props.currentStep > i && (
                  <span className='step-icon'>
                    <svg
                      width="1.5rem"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 3.5L4.5 7.5L12 1" stroke="white" strokeWidth="1.5" />
                    </svg>
                  </span>
                )
              }

              {
                (props.currentStep == i && props.hasError ) && (
                  <span className='step-icon'>!</span>
                )
              }
              <div className='step-label'>
                {step.label}
                {step.subtitle && (
                  <div className='step-label-subtitle'>
                    {step.subtitle}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  )
}

export default Stepper