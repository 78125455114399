import React, { useEffect, useState, useContext } from "react";
import Header from "../../../components/elements/header/Header";
import DashboardHeader from '../DashboardHeader';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import {Form,  Modal,  Alert,  Spinner,  Button,  Container,  Row,  Col} from "react-bootstrap";
import TimePickerHelper from "../../../helpers/TimePickerHelper";
import TimePicker from 'react-gradient-timepicker';
import { BsPlusLg, BsTrash, BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import { businessTimingValidation } from "../../../validations/yub_schemas/businessTimingInformation";
import BusinessTimingImg from "../../../assets/images/vendor_icons/Complete-task.svg";
// import MultiStepFormContext from "./MultiStepFormContext";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { getVendorDetailsAction, updateBusinessTimingAction } from "../../../redux/dashboard/actions"
import { FolderSymlinkFill } from "react-bootstrap-icons";
import { freelancerValidation } from "../../../validations/yub_schemas/freelancerValidation";


const BusinessTimingSettings = () => {

    const iconStyle = { color: "#DC3545", fontSize: "1.5em", cursor: "pointer" };
    const iconBtnStyle = { color: "#FFF", fontSize: "1em", cursor: "pointer",marginBottom:"5px" };
    const [errorMessage, setErrorMessage] = useState(false);

    const [openAt, setOpenAt] = useState("");
    const [closeAt, setCloseAt] = useState("");
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);

    const [timeSlot, setTimeSlot] = useState(vendor?.business_hours || 
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );

    const addTimeSlot = () => {

        setTimeSlot([...timeSlot, { open_at: "", open_at: "" }])
    }
    const remoteTimeSlot = (index) => {
        let newFormValues = [...timeSlot];
        newFormValues.splice(index, 1);
        setTimeSlot(newFormValues)
        formik.setFieldValue("business_basic_hours", newFormValues)
    };
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // useEffect(() => {
    //     const data = {
    //         id: vendor?._id
    //     }

    //     dispatch(getVendorDetailsAction(data))
    //         .then((res) => {

    //             const { gender, slot_details, service_category } = vendor

    //             console.log("Yes => ", res)

    //             // if (gender && gender.length > 0) {
    //             //     formik.values.age_category = gender.map(item => item.gender_name)
    //             //     // let data = gender.map(item => item.gender_name)
    //             //     // formik.setFieldValue("age_category", data)
    //             // }

    //             // if (service_category && service_category.length > 0) {
    //             //     formik.values.service_category = service_category.map(item => item.servicecategory_name)
    //             // }

    //             // if (slot_details && Object.keys(slot_details).length > 0) {
    //             //     formik.values.business_days_hours = slot_details;
    //             //     formik.values.business_open_days = Object.keys(slot_details);
    //             //     formik.values.business_basic_hours = Object.keys(slot_details).map(item => slot_details[item])
    //             // }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })

    // }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: vendor?._id,
            business_type: vendor?.serviceTypes?.[0]?.servicetype_name || "",
            age_category: vendor?.gender?.map(item => item.gender_name) || [],
            service_category: vendor?.service_category?.map(item => item.servicecategory_name) || [],
            business_open_at : "",
            business_close_at : "",
            business_basic_hours: vendor?.business_hours || [{
                'open_at': "",
                "close_at": ""
            }],
            business_open_days: vendor?.slot_details ? Object.keys(vendor?.slot_details) : [],
            business_days_hours: vendor?.slot_details || [],
            advance_percentage: vendor?.advance_percentage || "",
        },
        validationSchema: vendorType === "business" ? businessTimingValidation : freelancerValidation,
        onSubmit: (values) => {
          

            values["business_days_hours"] = {}
            if(vendorType === "business"){
                values.business_basic_hours.forEach(item => {
                    if(item.open_at > item.close_at || item.open_at == "" || item.closeAt == "" ){
                        setErrorMessage(true)
                    }
                    else{
                        values["business_open_days"].forEach((item) => {
                            values["business_days_hours"][item] = { ...values['business_basic_hours'].map(item => item) }
                        })        
                        values["serviceTypes"] = values["business_type"];
                        values["gender"] = values["age_category"];
            
                        
                        let data = {
                            ...values,
                            
                            business_type: values.business_type,
                            age_category: values.age_category,
                            business_open_days: values.business_open_days,
                            service_category: values.service_category,
                            business_basic_hours: values.business_basic_hours,
                            business_open_days: values.business_open_days,
                            business_days_hours: values.business_days_hours
    
                        }
                        
                        dispatch(updateBusinessTimingAction(data))
                        .then((res) => {
                            navigate("/dashboard/settings")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
            
                    }
                })
            } else{
                values["serviceTypes"] = values["business_type"];
                values["gender"] = values["age_category"];
    
                
                let data = {
                    ...values,
                    
                    business_type: values.business_type,
                    age_category: values.age_category,
                    business_open_days: values.business_open_days,
                    service_category: values.service_category,
                    business_basic_hours: values.business_basic_hours,
                    business_open_days: values.business_open_days,
                    business_days_hours: values.business_days_hours,
                    advance_percentage: values.advance_percentage
                }
                
                dispatch(updateBusinessTimingAction(data))
                .then((res) => {
                    navigate("/dashboard/settings")
                })
                .catch((err) => {
                    console.log(err)
                })
    
            }
           

            // console.log("values => ", values["business_days_hours"]);

            // values["serviceTypes"] = values["business_type"];
            // values["gender"] = values["age_category"];

           
            // setStep2Details(values);
            // next();
        },
    });


    // useEffect(() => {

    //     // formik.setFieldValue()
    // }, [])

    const onBack = () => {
        navigate("/dashboard/settings")
    }

    const handleCheckboxChange = (e) => {
        const { checked, value, name } = e.target;
        if (checked) {
            formik.setFieldValue(name, [...formik.values[name], value]);
        } else {
            formik.setFieldValue(
                name,
                formik.values[name].filter((v) => v !== value)
            );
        }
    };


    const handleTimeChange = async(e, index, type) => {
        const { value, name } = e.target;
        let currObject = formik.values.business_basic_hours[index];
        formik.setFieldTouched(name, true);
       
        formik.values.business_basic_hours[index] = { ...currObject }
        formik.setFieldValue(name, value);

        await Promise.resolve()

        if(type == "open_at"){
           
            setOpenAt(value)
        }
        if(type == "close_at"){
            
            setCloseAt(value)
        }
        formik.setFieldTouched(name, true);        
         validateTimeSettings()
    };

    const validateTimeSettings = () => {
    }
    const [value, onChange] = useState('');

    return (
        <>
            <DashboardHeader />
            <div className="businessStep2 mt-3">
                <Container>
                    <Row>
                        <Col md={8} sm={12}>
                            <Form noValidate className="business-register-form text-start px-5" onSubmit={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <div className="my-5">
                                        <h4 className="required">Establishment type</h4>
                                        <small className="text-muted">Select most relevant category for your Business type</small>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="businessType">
                                        {
                                            vendor?.vendor_type && vendor?.vendor_type === "business" && (
                                                <>
                                                    <Form.Check
                                                        className="mb-2"
                                                        type='radio'
                                                        id='shop_only'
                                                        name="business_type"
                                                        label="Shop only"
                                                        value="Shop only"
                                                        checked={formik.values.business_type === "Shop only"}
                                                        onChange={() => formik.setFieldValue("business_type", "Shop only")}
                                                        isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}

                                                    />
                                                    <Form.Check
                                                        type='radio'
                                                        id='shop_and_home'
                                                        name="business_type"
                                                        label="Both Shop and Home service"
                                                        value="Both Shop and Home service"
                                                        checked={formik.values.business_type === "Both Shop and Home service"}
                                                        onChange={() => formik.setFieldValue("business_type", "Both Shop and Home service")}
                                                        isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                    />
                                                </>
                                            )
                                        }

                                        {
                                             vendor?.vendor_type && vendor?.vendor_type === "freelancer" && (
                                                <Form.Check
                                                    className="mb-2"
                                                    type='radio'
                                                    id='home_service'
                                                    name="business_type"
                                                    label="Home service"
                                                    value="Home service"
                                                    checked={formik.values.business_type === "Home service"}
                                                    onChange={() => formik.setFieldValue("business_type", "Home service")}
                                                    isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
                                                />
                                            )
                                        }
                                    </Form.Group>
                                    <div className="my-5">
                                        <h4 className="required">Category</h4>
                                        <small className="text-muted">Select most relevant category for your Business type</small>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="ageCategory">
                                        <Form.Check
                                            className="mb-2"
                                            type='checkbox'
                                            id='Men'
                                            name="age_category"
                                            label="Men"
                                            value="Men"
                                            checked={formik.values.age_category.includes("Men")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                        />
                                        <Form.Check
                                            className="mb-2"
                                            type='checkbox'
                                            id='Women'
                                            name="age_category"
                                            label="Women"
                                            value="Women"
                                            checked={formik.values.age_category.includes("Women")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                        />
                                        <Form.Check
                                            type='checkbox'
                                            id='Kids'
                                            name="age_category"
                                            label="Kids"
                                            value="Kids"
                                            checked={formik.values.age_category.includes("Kids")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.age_category}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="mt-5 mb-3">
                                        <h4 className="required">Select options which best describe your Business Services</h4>
                                        <small className="text-muted">Select all types services you provide</small>
                                        <div className="my-4">
                                            <h4 className="text-muted">
                                                Selected:
                                            </h4>

                                            {
                                                formik.values.service_category.map((item, index) => {
                                                    return (
                                                        <span key={index} className="badge bg-primary me-2 px-2">
                                                            {item}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="serviceCategory">
                                        <Row>
                                            <Col md={4}>
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Hair'
                                                    name="service_category"
                                                    label="Hair"
                                                    value="Hair"
                                                    checked={formik.values.service_category.includes("Hair")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Nails'
                                                    name="service_category"
                                                    label="Nails"
                                                    value="Nails"
                                                    checked={formik.values.service_category.includes("Nails")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                                <Form.Check
                                                    type='checkbox'
                                                    id='Spa'
                                                    name="service_category"
                                                    label="Spa"
                                                    value="Spa"
                                                    checked={formik.values.service_category.includes("Spa")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Skin'
                                                    name="service_category"
                                                    label="Skin"
                                                    value="Skin"
                                                    checked={formik.values.service_category.includes("Skin")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Makeup'
                                                    name="service_category"
                                                    label="Makeup"
                                                    value="Makeup"
                                                    checked={formik.values.service_category.includes("Makeup")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                                <Form.Check
                                                    type='checkbox'
                                                    id='Mehendi'
                                                    name="service_category"
                                                    label="Mehendi"
                                                    value="Mehendi"
                                                    checked={formik.values.service_category.includes("Mehendi")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                />
                                            </Col>
                                        </Row>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.service_category}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {
                                        vendorType && vendorType === "business" ? (
                                         <>
                                          <div className="my-5">
                                        <h4 className="required">Business operational hours</h4>
                                        <small className="text-muted">Mark Business opening and closing hours</small>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="basicOpeningHours">
                                        <Row className="mb-2">
                                            <Col md={3}>
                                                <h5 className="text-muted">
                                                    Opens At
                                                </h5>
                                            </Col>
                                            <Col md={2}>
                                           </Col>
                                            <Col md={3}>
                                                <h5 className="text-muted">
                                                    Closes At
                                                </h5>
                                            </Col>
                                        </Row>
                                        {
                                            timeSlot.map((slot, index) => {
                                                const fieldName = `business_basic_hours[${index}]`;
                                                return (
                                                    <div key={index}>
                                                        <Row className="mb-4">
                                                            <Col md={3}>
                                                                <Form.Control
                                                                    type="time"
                                                                    required step="3600"
                                                                    format="HH:MM a"
                                                                    name={`${fieldName}.open_at`}
                                                                    onChange={(e) => {
                                                                        setOpenAt(e.target.value)
                                                                        formik.setFieldValue("business_open_at", e.target.value);
                                                                        handleTimeChange(e, index, "open_at");                                                                        
                                                                    }}
                                                                    value={formik.values.business_basic_hours[index]?.open_at}
                                                                    isInvalid={!!formik.errors.business_basic_hours}

                                                                />
                                                            </Col>
                                                            
                                                            <Col md={2}>
                                                                <h6 className="text-muted mt-2 ms-4">
                                                                    to
                                                                </h6>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Control
                                                                    required
                                                                    type="time"

                                                                    format="hh:mm"
                                                                    name={`${fieldName}.close_at`}
                                                                    onChange={(e) => {
                                                                        setCloseAt(e.target.value)
                                                                        formik.setFieldValue("business_close_at", e.target.value);
                                                                        handleTimeChange(e, index, "close_at")                                                                        
                                                                    }}
                                                                    value={formik.values.business_basic_hours[index]?.close_at}
                                                                    isInvalid={!!formik.errors.business_basic_hours}
                                                                />
                                                            </Col>
                                                            {
                                                                index != 0 &&
                                                                <Col md={1}>
                                                                    <BsTrash style={iconStyle} onClick={() => remoteTimeSlot(index)} />
                                                                </Col>
                                                            }

                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                         {  
                                    <p className={errorMessage ? "text-danger" : "text-warning"}>Note! The Closing time shouldn't be less than the opening time.</p>
                                    }
                                    </Form.Group>
                                    <div className="mx-3">
                                        <p className="text-start text-primary add-time-slot" >
                                            <BsPlusLg /><span className="ms-2" onClick={() => addTimeSlot()}>Add Time Slot</span>
                                        </p>
                                    </div>
                                    <div className="my-5">
                                        <h4 className="required">Mark open days</h4>
                                        <small className="text-muted">Don’t forget to uncheck your off-day</small>
                                    </div>
                                    <Form.Group className="mb-3 p-2" controlId="basicOpeningDays">
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Monday'
                                            name="business_open_days"
                                            label="Monday"
                                            value="Monday"
                                            checked={formik.values.business_open_days.includes("Monday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Tuesday'
                                            name="business_open_days"
                                            label="Tuesday"
                                            value="Tuesday"
                                            checked={formik.values.business_open_days.includes("Tuesday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Wednesday'
                                            name="business_open_days"
                                            label="Wednesday"
                                            value="Wednesday"
                                            checked={formik.values.business_open_days.includes("Wednesday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Thursday'
                                            name="business_open_days"
                                            label="Thursday"
                                            value="Thursday"
                                            checked={formik.values.business_open_days.includes("Thursday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Friday'
                                            name="business_open_days"
                                            label="Friday"
                                            value="Friday"
                                            checked={formik.values.business_open_days.includes("Friday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Saturday'
                                            name="business_open_days"
                                            label="Saturday"
                                            value="Saturday"
                                            checked={formik.values.business_open_days.includes("Saturday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />
                                        <Form.Check
                                            className="mb-3"
                                            type='checkbox'
                                            id='Sunday'
                                            name="business_open_days"
                                            label="Sunday"
                                            value="Sunday"
                                            checked={formik.values.business_open_days.includes("Sunday")}
                                            onChange={handleCheckboxChange}
                                            isInvalid={!!formik.touched.business_open_days && !!formik.errors.business_open_days}
                                        />

                                    </Form.Group>

                                    <div className="my-3">
                                        <h6>Want more options ? <a href="#">Apply advance settings</a></h6>
                                    </div>

                                    
                                    <div className="step-one-title mt-5">
                                    <h4 className="required">Advance Percentage </h4>

                                    <p>Select most relevant category for your Business type</p>
                                   </div>
                                   <Form.Group className="mb-3 p-2" controlId="advance_percentage">
                                        <Form.Select
                                            name="advance_percentage"
                                            onChange={formik.handleChange}
                                            value={formik.values.advance_percentage}
                                            isInvalid={!!formik.touched.advance_percentage && !!formik.errors.advance_percentage}
                                        >
                                    <option value="">Open this select menu</option>
                                    <option value="10">10 Percentage</option>
                                    <option value="15">15 Percentage</option>
                                    <option value="20">20 Percentage</option>
                                    <option value="25">25 Percentage</option>
                                    <option value="30">30 Percentage</option>
                                    <option value="35">35 Percentage</option>
                                    <option value="40">40 Percentage</option>
                                    <option value="45">45 Percentage</option>
                                    <option value="50">50 Percentage</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.advance_percentage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                   
                                         </>
                                        ) : (
                                            <>
                                            <div className="step-one-title">
                                       <h4>Advance Percentage *</h4>
                                       <p>Select most relevant category for your Business type</p>
                                      </div>
                                      <Form.Group className="mb-3 p-2" controlId="advance_percentage">
                                           <Form.Select
                                               name="advance_percentage"
                                               onChange={formik.handleChange}
                                               value={formik.values.advance_percentage}
                                               isInvalid={!!formik.touched.advance_percentage && !!formik.errors.advance_percentage}
                                           >
                                       <option value="">Open this select menu</option>
                                       <option value="10">10 Percentage</option>
                                       <option value="15">15 Percentage</option>
                                       <option value="20">20 Percentage</option>
                                       <option value="25">25 Percentage</option>
                                       <option value="30">30 Percentage</option>
                                       <option value="35">35 Percentage</option>
                                       <option value="40">40 Percentage</option>
                                       <option value="45">45 Percentage</option>
                                       <option value="50">50 Percentage</option>
   
                                           </Form.Select>
                                           <Form.Control.Feedback type="invalid">
                                               {formik.errors.advance_percentage}
                                           </Form.Control.Feedback>
                                       </Form.Group>
                                      
                                           </>

                                        )
                                    }
                                   
                                <div className="text-center my-5">
                                        <Row>
                                            <Col md={6} className="d-grid mb-2">
                                                <Button variant="primary" size="lg" onClick={onBack}>
                                                    <BsFillCaretLeftFill style={iconBtnStyle} /> Back
                                                </Button>
                                            </Col>
                                            <Col md={6} className="d-grid mb-2">
                                                <Button variant="primary" type="submit" size="lg">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </FormikProvider>
                            </Form>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="form-image-container mt-5">
                                <img className="img-fluid" src={BusinessTimingImg} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BusinessTimingSettings;