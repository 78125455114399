import React, { useEffect, useState, useContext } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container, Breadcrumb } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction, getUserOneRequestAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import {MdOutlineNavigateNext} from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import PaymentFailed from './PaymentFailed';
import CancellationSucess from './CancellationSucess';







function RequestCancelSuccess() {


    const [searchParams] = useSearchParams();

    const [orderDetails, setOrderDetails] = useState({})
    const [loading, setLoading] = useState(false)

    const type = searchParams.get("type");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams("");
    const _id = searchParams.get("id");

    const redirectPaymentDetails = (type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectReschedule = () => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    const navigateTerms = () => {
        navigate('/term-of-use')
    }
    


    const formik = useFormik({
        initialValues: {
            refundReason: 'One',
            refundMessage: '',
            payment_id: '',
            confirmation: 'false'
        },
    });

   
    useEffect(() => {
            let data = {
                id: id,
            }
            dispatch(getUserOneRequestAction(data))
                .then((res) => {
                    setOrderDetails(res)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        
    }, [id]);


    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }

    const raiseRefunds = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (formik.values.confirmation !== 'true') {
            toast.warning('Please select mode of refund.');
            setLoading(false);
            return
        }
        const obj = {
            refundReason: formik.values.refundReason,
            refundMessage: formik.values.refundMessage,
            payment_id: orderDetails.payment_id,
            order_id: orderDetails._id,
            appointment_id: orderDetails.appointment._id
        }
        const result = await dispatch(raiseRefund(obj));
        setLoading(false)
        if (result.result) {
            toast.success('Cancellation successfully raised')
            navigate(`/business/payment/${id}?type=cancellation_raised`)
        } else {
            toast.error('Could not raise a cancellation request.')
            navigate(`/business/payment/cancel/failed/${id}`)
        }
    }

  

    const redirectToRating = (id) =>{
        navigate(`/business/${id}`);
        
    }
    const goToHome = () => {
        navigate("/")
    }

    return (
        <div className="payment-success">
            <Header />
            <section className='pb-0 mt-0 pt-0'>
               
                        <div className='container-fluid' id='thanks-note-outer'>
                        <Row>
                                <Col md={12}>
                              
                                              <div>    
                                                <Container>
                                                    <Row className='text-center mt-5'>
                                                    <img src={YesSuccess} alt="small-info" className="payment-success-icon" />
                                                        <div className='thanks-note'>
                                                        <h1>Successfully Cancelled! </h1>
                                                        <p>The request confirmation details of your order has been sent to your register email address.</p>
                                                         <Card className='orderid-card'>
                                                            <h6>YOUR REQUEST ID: {orderDetails?.results?.request_id}</h6>
                                                         </Card>
                                                         <p>Cancelled on: {moment(orderDetails?.results?.created_at).local().format("DD MMM YYYY")}</p>
                                                         <h5 onClick={redirectToExplore}>Continue Explore <MdOutlineNavigateNext size={18}/> </h5>
                                                        </div>
                                                    </Row>
                                                </Container>
                                                <hr />
        
                                                <div>
                                                    <Container className='mt-5'>
                                                           <Card>
                                                            <Card.Body className='success-note'>
                                                                <h6>Notes:</h6>
                                                                <p>intimation message to wait for serviceprovider's accept/denial status</p>
                                                            </Card.Body>
                                                           </Card>
                                                    </Container>
                                        <Container className='mt-5'>
                                           <div className='payment-sub-cards'>
                                           <Row>
                                                <Col md={7}>
                                                    <Row>
                                                    <Col md={6}>
                                                    <Card>
                                                        <Card.Body>
                                                        <Row className='text-center'>
                                                            <p>Appointment Date / Time</p>
                                                            <h6>{moment(orderDetails?.results?.selected_date).local().format("DD MMM YY hh:mm A")}</h6>
                                                        </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                <Card>
                                                    <Card.Body>
                                                    <Row className='text-center'>
                                                            <p>Service type</p>
                                                            <h6>Home Service</h6>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                </Col>
                                                    </Row>

                                                    <Row className='mt-5'>
                                                    <h1 className=''>You will receive updates from</h1>

                                                    <div className='order-view-info'>
        
                                            
     <Card className='payment-info-card'>
         <Row className='mx-2'>
             <Col md={7} className="payment-info-inner">
            <div>
            <Row>
            <div className='payment-suceess-service-info'>
                       <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                       <h6>{orderDetails?.results?.service_provider?.business_email}</h6>
                       </div>
             </Row>
             </div>   
             </Col>
             <Col md={5} className="">
             <Row>
                       <div className='payment-suceess-service-info'>
                       <p> <ion-icon name="call-outline"></ion-icon></p>
                       <h6>{orderDetails?.results?.service_provider?.business_contactNo}</h6>

                       </div>
             </Row>
             </Col>
         </Row>
         </Card>
         <Row className='payment-order-info mt-5'>
             <Col>
             <h1 className=''>Order Details</h1>
             </Col>
             <Col>
             <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
             </Col>

         </Row>
         <Card>
             <Card.Header className=' bg-white'>
         <Row className='mt-2 mb-2'>
             <Col md={1}>
             <img className='payment-info-image' src={orderDetails?.results?.service_provider?.businesslogoURL} />
             </Col>
            <Col className='payment-orderInfo-details' md={11}>
                 <h6 className='mt-3'>{orderDetails?.results?.service_provider?.business_name}</h6>
                 <p className='mt-2'>{orderDetails?.results?.service_provider?.location_details?.address + "" + orderDetails?.results?.service_provider?.location_details?.area + ", " + orderDetails?.results?.service_provider?.location_details?.city + ", " + orderDetails?.results?.service_provider?.location_details?.state + " " + orderDetails?.results?.service_provider?.location_details?.zipcode}</p>

           </Col>

         </Row>
         </Card.Header>
         <Card.Header className='bg-white'>
         <Row>
         {
             orderDetails?.results?.services && orderDetails?.results?.services.length > 0 && (
                 orderDetails?.results?.services?.map((item, index) => {
                    let price =  item?.service_price?.["$numberDecimal"];
                    let servicePrice = Math.round(price);
                     return (
                         <div key={index}>
                         <Row className='mb-2'>
                         
                             <Col md={6} sm={6} className="cart-checkout-item-list">
                             <span>{item?.gender?.[0]?.gender_name}</span>
                             <p class="service-heading">{item?.service_name}</p>
                             <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                             </Col>
                             <Col md={6} sm={6} className="cart-checkout-amount-list">
                                   <div className='cart-check-out-amount'>
                                     
                                     <p className='mt-4'>{ servicePrice + ".00" +" ₹ "}</p>
                                   </div>
                             </Col>

                         </Row>
                         <hr />

                     </div>

                     );
                 })
             )
         }
         </Row>
         </Card.Header>

         </Card>
         </div>
                                                    </Row>
                                                </Col>
                                                <Col md={5}>
                                                    <Row >
                                                        <div>
                                    <h1>Payment Details</h1>
                                    <Card>
                                        
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                <div className='payment-total-card-inner'>
                                                    <p>Price</p>
                                                    <p>GST 18%</p>
                                                    <p>Discount</p>
                                                    <p>Coupon for you</p>
                                                    <h1>Grand Total</h1>
                                                </div>
                                                </Col>
                                                <Col>
                                                <div className='float-end payment-total-card-inner'>
                                                    <Row>
                                                        <Col className='text-end px-0'>
                                                        <p>{Math.round(orderDetails?.results?.original_price) + ".00"}</p>
                                                        <p className=''>{Math.round(orderDetails?.results?.gst_value) +".00"}</p>
                                                        <p className='green_text'>{Math.round(orderDetails?.results?.discount) + ".00"}</p>
                                                        <p className='green_text'>{orderDetails?.results?.coupon/100 + ".00"}</p>
                                                        <h1>{Math.round(orderDetails?.results?.final_price) + ".00"}</h1>
                                                        </Col>
                                                        <Col className='Payment-amount-inner'>
                                                        <p>₹</p>
                                                        <p>₹</p>
                                                        <p>₹</p>
                                                        <p>₹</p>
                                                        <h1>₹</h1>
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                                        </div>
                                                    </Row>
                                                    <Row className='mt-5'>
                                                    <div>
                                <h1>Contact Details</h1>
                                <Card>
                                    <Card.Body>
                                        <div className='payment-user-contact-info'>
                                        <p className='mb-0 pb-0'>{orderDetails?.results?.alternate_name}</p>
                                        <p className='mt-0 pt-0'>{orderDetails?.results?.address + ", " + orderDetails?.results?.city + ", " + orderDetails?.results?.state + ", " + orderDetails?.results?.country + ", " + orderDetails?.results?.zipcode}</p>
                                        <h5>{orderDetails?.results?.alternate_phone}</h5>
                                        <h5>{orderDetails?.results?.alternate_email}</h5>
                                        </div>
                                    </Card.Body>
                                </Card>
                              </div>
                                                    </Row>

                                                    <Row className='mt-5'>
                                                    <div className='payment-sucess-need-help'>
                                <h1>Need Help?</h1>
                                <div className='d-flex'>
                                <h2 className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></h2>
                                <h2 onClick={redirectSupport}>Support Center</h2>
                                 </div>
                              </div>
                                                    </Row>
                                                </Col>
                                               
                                            </Row>
                                           </div>
                                        </Container>
                                                </div>
        
                                                <Container>
                            <Row>
                            <Col md={7}>
                            {
                                        type && type === "payment-success" && (
                                            <div className='order-view-info'>
        
                                            
                                           <p className='payment-info-text'>You will receive updates from</p>
                                        <Card className='payment-info-card'>
                                            <Row className='mx-2'>
                                                <Col md={7} className="payment-info-inner">
                                               <div>
                                               <Row>
                                               <div className='payment-suceess-service-info'>
                                                          <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                                                          <h6>{orderDetails?.results?.service_provider?.business_email}</h6>
                                                          </div>
                                                </Row>
                                                </div>   
                                                </Col>
                                                <Col md={5} className="">
                                                <Row>
                                                          <div className='payment-suceess-service-info'>
                                                          <p> <ion-icon name="call-outline"></ion-icon></p>
                                                          <h6>{orderDetails?.results?.service_provider?.business_contactNo}</h6>
        
                                                          </div>
                                                </Row>
                                                </Col>
                                            </Row>
                                            </Card>
                                            <Row className='payment-order-info'>
                                                <Col>
                                                <p className='payment-info-text'>Order Details</p>
                                                </Col>
                                                <Col>
                                                <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                                </Col>
        
                                            </Row>
                                            <Card>
                                                <Card.Header className=' bg-white'>
                                            <Row className='mt-2 mb-2'>
                                                <Col md={1}>
                                                <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                                </Col>
                                               <Col className='payment-orderInfo-details px-0' md={11}>
                                                    <h6 className='mt-2'>{orderDetails?.results?.service_provider?.business_name}</h6>
                                                    <p className='mt-2'>{orderDetails?.results?.service_provider?.location_details?.area + " " + orderDetails?.results?.service_provider?.location_details?.city + " " + orderDetails?.results?.service_provider?.location_details?.state}</p>
                                              </Col>
                                            
                                            </Row>
                                            </Card.Header>
                                            <Card.Header className='bg-white'>
                                            <Row>
                                            {
                                                orderDetails?.results?.services && orderDetails?.results?.services.length > 0 && (
                                                    orderDetails?.results?.services?.map((item, index) => {
                                                            let price = item?.service_price;
                                                            let finalprice = Math.round(price)
                                                        return (
                                                            <div key={index}>
                                                            <Row className='mb-2'>
                                                            
                                                                <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                <span>{item?.gender?.[0]?.gender_name}</span>
                                                                <p class="service-heading">{item?.service_name}</p>
                                                                <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                </Col>
                                                                <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                      <div className='cart-check-out-amount'>
                                                                        <p></p>
                                                                        <p></p>
                                                                        <p className='mt-5'>{ Math.round(item?.service_price?.["$numberDecimal"] +" ₹ ")}</p>
                                                                      </div>
                                                                </Col>
        
                                                            </Row>
                                                            <hr />
        
                                                        </div>
        
                                                        );
                                                    })
                                                )
                                            }
                                            </Row>
                                            </Card.Header>
        
                                            </Card>
                                            </div>
        
                                        )
                            }
                            </Col>
                            
                            </Row>
                            
                        </Container>
                                               
                                       
                                            </div>
        
                                        
                                        
                                </Col>
                            </Row>
                        </div>
                   

                

                
            </section>
            <div className="mt-5">
                {
                    type === "view" && (
                        <div>
                            <Footer />
                        </div>
                    )
                }
                {
                    type !== "view" && (
                        <div>
                        <CartFooter />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default RequestCancelSuccess