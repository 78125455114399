import React, { useEffect } from 'react';
import Header from "../../components/elements/header/Header";
import Footer from '../../components/elements/footer/Footer';
import EmptyCart from './EmptyCart';
import CartSummary from './CartSummary';
import { useDispatch, useSelector } from "react-redux";
import CartCheckout from "./CartCheckout";
import PaymentFailed from './paymentStatus/PaymentFailed';
import { removeFromCart, clearCart, clearServiceProvider } from '../../redux/cart/actions';
import CartFooter from '../../components/elements/footer/CartFooter';

function CartMainPage() {

    const dispatch = useDispatch();
    const { servicesCart } = useSelector((state) => state.cart);

    return (
        <div className="checkout-main-page">
            <Header />
            {
                servicesCart && servicesCart.length > 0 ?
                    (
                        <>
                            <CartSummary />
                        </>
                    )
                    :
                    (
                        <EmptyCart />
                    )
            }
            <CartFooter />
        </div>
    )
}

export default CartMainPage;