import * as Yup from 'yup';



export const businessInfoValidation = Yup.object({
    business_name: Yup.string('Enter your business name')
    .matches(/^[a-zA-Z0-9 .!?/'_-]+$/, "Please enter a valid name. We allow only a-z A-Z 0-9 .!?/'_-")
    .required('Business name is required'),
    business_address: Yup.string('Enter your business address').required('Business address is required'),
    area: Yup.string('Enter your area').required('Area is required'),
    city: Yup.string('Enter your city').required('City is required'),
    state: Yup.string('Enter your state').required('State is required'),
    zipcode: Yup.string('Enter your zipcode').matches(/^\d+$/, "Zipcode should be digits only").required('Zipcode is required'),
    country: Yup.string('Enter your country').required('Country is required'),
    business_email_id: Yup.string('Enter your business email')
                .email('Enter a valid email')
                .required('Email is required'),
    business_address_url: Yup.string().url("Please enter valid location url"),
    mobile_no: Yup.string('Enter your phone no').matches(/^\d+$/, "Should be digits only")
    .min(10, 'Enter a valid phone no'),
    business_type: Yup.string('Enter the business type').required('business type is required'),
    age_category: Yup.array().min(1).required('gendor category is required'),
    service_category: Yup.array().min(1).required('service type is required'),

});

