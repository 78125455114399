import axios from 'axios';
import { USER_CONFIG } from '../../configs';

const updateUserLocation = (data) => axios.post(USER_CONFIG.updateUserLocation, data);
const createUserLocation = (data) => axios.post(USER_CONFIG.createUserLocation, data);
const getUserLocation = (data) => axios.post(USER_CONFIG.getUserLocation, data);

const getAvailableSlotsService = (data) => axios.post(USER_CONFIG.getAvailableSlotsApi, data);
const slotBookingService = (data) => axios.post(USER_CONFIG.slotBookingApi, data);
const freelancerRequestService = (data) => axios.post(USER_CONFIG.freelancerRequestApi, data)


const createOrderService = (data) => axios.post(USER_CONFIG.createOrderApi, data);
const updateOrderService = (data) => axios.post(USER_CONFIG.updateOrderApi, data);


const getAllUserOrdersService = (data) => axios.post(USER_CONFIG.getAllUserOrdersApi, data);
const getOneUserOrdersService = (data) => axios.post(USER_CONFIG.getOneUserOrdersApi, data);
const getOneUserRequestService = (data) => axios.post(USER_CONFIG.getOneUserRequestApi, data);

const getAllUserRequestService = (data) => axios.post(USER_CONFIG.getAllUserRequestApi, data);


const getUserFavorites = (data) => axios.post(USER_CONFIG.getUserFavoritesApi, data);
const getServiceFavorites = (data) => axios.post(USER_CONFIG.getFavoriteServiceApi, data);
const getFavoriteProfileUpdates = (data) => axios.post(USER_CONFIG.getFavoriteServiceUpdateApi, data);




const data = {
    freelancerRequestService,
    getOneUserRequestService,
    updateUserLocation,
    getAvailableSlotsService,
    slotBookingService,
    createUserLocation,
    getUserLocation,
    createOrderService,
    updateOrderService,
    getAllUserOrdersService,
    getOneUserOrdersService,
    getUserFavorites,
    getServiceFavorites,
    getFavoriteProfileUpdates,
    getAllUserRequestService
};

export default data;