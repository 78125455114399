import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap'



function CartFooter() {
    const navigate = useNavigate()

const Terms = () => {
    navigate('/term-of-use')
}
const privacy = () => {
    navigate("/privacy-policy")
}
const Cookie = () => {
    navigate("/cookie-policy")
}
const Cancel = () => {
    navigate("/reschedule-cancel-policy")
}
const support =()=>{
    navigate("/support")
}
  return (
    <div>
       <Container fluid className='cart-footer'>
        <Container fluid='xl'>
            <Row>
                <div className='cart-footer-content'>
                    
                <p className='mt-2'>
                <span>Policies: </span>
                <span className='cart-footer-links' onClick={Terms}>Terms of use | </span>
                 <span className='cart-footer-links' onClick={privacy}>Privacy Policy | </span>
                  <span className='cart-footer-links' onClick={Cancel}> Cancellation and Reschedule | </span>
                  <span className='cart-footer-links' onClick={Cookie}> Cookie Policy |</span> 
                  <span className='cart-footer-help'> Need Help? Visit the <span className='support-cart-center' onClick={support}> Support center</span> </span>
                </p>
                </div>
            </Row>
        </Container>
       </Container>
    </div>
  )
}

export default CartFooter
