import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube, BsLinkedin } from 'react-icons/bs';
import { HiOutlineMail } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link , useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
// import IndiaImg from "../../../assets/images/icons/TwemojiFlagForFlagIndia.webp"
// import SingaporeImg from "../../../assets/images/icons/TwemojiFlagForFlagSingapore.webp"


const Footer = () => {

    const { t } = useTranslation();
    const iconStyle = { fontSize: "20px" }
    const { user, role, isLoggedIn } = useSelector((state) => state.auth);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
    

    const navigate = useNavigate();

    const viewSupport = () => {
        if(!vendor?._id && role?.role_name === "user"){
            navigate('/support')
        } else if(vendor?._id && role?.role_name !== "user"){
            navigate('/businessSupportMain')
        } else if(isLoggedIn !== true){
            toast.warning("Please login to reach support")
        } else if (role?.role_name === 'business'){
            navigate('/businessSupportMain')

        }

    }

    return (
        <div className="footer">
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <a href="JavaScript:void(0);"><img src={logo} class="img-fluid" alt="footer-logo" /></a>
                            {/* <p className="text-center px-4 ">Discover the Golden Glow</p> */}
                        </div>
                        <div class="col-md-3">
                            <div class="footer-common mt-2">
                                <h3>Company</h3>
                                <ul>
                                    <Link to={"/about"}>
                                    <li><a href="JavaScript:void(0);">About Us</a></li>
                                    </Link>
                                    <Link to={"/explore"}>
                                    <li><a href="JavaScript:void(0);">Explore</a></li>
                                    </Link>
                                    <Link to={"/business"}>
                                    <li><a href="JavaScript:void(0);">Business Page</a></li>
                                    </Link>
                                    
                                    <li onClick={viewSupport}><a href="JavaScript:void(0);">Support Center</a></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-common mt-2">
                                <h3>Legal</h3>
                                <ul>
                                    <Link to={"/term-of-use"}><li><a href="JavaScript:void(0);">Terms of use</a></li></Link>
                                    <Link to={"/privacy-policy"}><li><a href="JavaScript:void(0);">Privacy Policy</a></li></Link>
                                    <Link to={"/reschedule-cancel-policy"}><li><a href="JavaScript:void(0);">Cancellation and Reschedule</a></li></Link>
                                    <Link to={"/cookie-policy"}><li><a href="JavaScript:void(0);">Cookie Policy</a></li></Link>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="footer-common mt-2">
                                <h3>For Enquiry</h3>
                                <ul>
                                    {/* <li><b>Address</b></li>
                                    <li>5G Voora Villa 96 Bhaktivedanta Swami<br /> Salai Akkarai Chennai 600115</li> */}
                                    {/* <li><b>Email Id</b></li> */}
                                    <li className="d-flex email"><HiOutlineMail  className="email-footer-icon" /><a href="mailto: info@slaylewks.com" className="email-weight">info@slaylewks.com</a></li>
                                    {/* <li><b>Phone Number</b></li>
                                    <li><Image src={SingaporeImg} /><span className="ms-4">+65 96431604</span></li>
                                    <li><Image src={IndiaImg} /><span className="ms-4">+91 9962811048</span></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="social-media">
                <div class="container">
                    <div class="row d-flex">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <p>copyright © SLAYLEWKS PRIVATE LIMITED</p>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="social-media-inner">
                                <span>Follow us on :</span>
                                <ul>
                                    
                                    <li className="me-1">
                                    <a href="https://m.facebook.com/people/Slaylewks/100087101419921/" target="_blank" rel="noopener noreferrer"><BsFacebook /> </a>
                                    </li>
                                   
                                    
                                    <li className="me-1">
                                    <a href="https://www.instagram.com/slaylewks/" target="_blank" rel="noopener noreferrer">
                                        <BsInstagram />
                                        </a>    
                                    </li>
                                    <li className="me-1">
                                        <a href="https://twitter.com/slaylewks" target="_blank" rel="noopener noreferrer">
                                            <BsTwitter />
                                        </a>
                                        </li>
                                        {/* <li className="me-1">
                                            <a href="https://twitter.com/slaylewks">
                                            <BsYoutube />
                                            </a>
                                        </li> */} 
                                    <li>
                                        <a href="https://www.linkedin.com/company/slaylewks/" target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;