import React, { useEffect, useState } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import { useDispatch, useSelector } from 'react-redux';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { createResources, findAllresources, findOneResource, updateResources, deleteResources } from "../../../redux/dashboard/actions";
import AppointmentView from './AppointmentView';
import StaffAddDaily from '../Staff/StaffAddDaily';
import StaffUpdateDaily from '../Staff/StaffUpdateDaily';
import StaffDraggableModal from './StaffDraggableModal';

function StaffCalendar() {

    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [viewOpen, setViewOpen] = useState(false);
    const [resizeModalOpen, setResizeModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState({});
    const [staffCount, setStaffCount] = useState({
        id: 1,
        title: "No staffs",
        start: "",
    });

    const [selectedResource, setSelectedResource] = useState({})

    const dispatch = useDispatch();

    const { vendor } = useSelector((state) => state.business);
    const { staffs } = useSelector((state) => state.dashboard);



    useEffect(() => {
        const data = {
            id: vendor?._id
        }
        dispatch(findAllresources(data))
            .then(async (res) => {
                const { data } = res
            })
    }, [vendor]);

    const getAllStaffs = () => {
        const data = {
            id: vendor?._id
        }
        dispatch(findAllresources(data))
            .then((res) => {
            })
    }

    const addStaff = (selectedInfo) => {

    }

    const updateStaff = (changedInfo) => {
        setSelectedDate(changedInfo?.event);
        setResizeModalOpen(true);
    }

    const AddResourceFunction = (resource) => {
        setStaffCount(resource)
        dispatch(createResources({ ...resource, serviceProvider: vendor._id }))
            .then((res) => {
                getAllStaffs();
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }


    const UpdateResourceFunction = (resource) => {
        dispatch(updateResources({ ...resource, serviceProvider: vendor._id }))
            .then((res) => {
                getAllStaffs();
                setResizeModalOpen(false);
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }

    const deleteResourceFunction = (resource) => {
        dispatch(deleteResources(resource))
            .then((res) => {
                getAllStaffs();
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }


    const handleDateSelect = (selectInfo) => {
        setSelectedDate(selectInfo);
        setViewOpen(true);
    }


    const handleEventClick = (clickInfo) => {
        let { event } = clickInfo;

        if (event) {
            let { _def } = event;
            let resouceDetails = _def?.extendedProps?._doc;
            if (resouceDetails) {
                setSelectedResource(resouceDetails);
            }
            else {
                console.log("No resource details found");
            }
        } else {
            console.log("No events found")
        }
        setEditModalOpen(true);
    }



    const renderEventContent = (eventInfo) => {
        return (
            <>
                <h5 className="staff-counts">{eventInfo.event.title}</h5>
            </>
        )
    }

    const renderSidebarEvent = (event) => {
        return (
            <li key={event.id}>
                <b className="me-3">{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
                <i>{event.title}</i>
            </li>
        )
    }

    return (
        <div className='px-3 py-3'>
        <div className='demo-app '>
                <StaffAddDaily
                    show={viewOpen}
                    onHide={() => setViewOpen(false)}
                    selectInfo={selectedDate}
                    staffCount={staffCount}
                    setStaffCount={AddResourceFunction}
                />

                <StaffUpdateDaily
                    show={editModalOpen}
                    onHide={() => setEditModalOpen(false)}
                    selectInfo={selectedDate}
                    staffCount={selectedResource}
                    updateResource={UpdateResourceFunction}
                    deleteResource={deleteResourceFunction}
                />

                <StaffDraggableModal
                    show={resizeModalOpen}
                    onHide={() => setResizeModalOpen(false)}
                    selectInfo={selectedDate}
                    staffCount={selectedResource}
                    updateResource={UpdateResourceFunction}
                />
                <div className='demo-app-main'>
                <div className="service-list-header-option  mb-3">
            <h3 className='marketing-header'>Adding staffs</h3>

            </div>
                    <div className='booking-note text-center mt-3 mb-5'
                     id='mobile-view'
                     >
                        <h6 className='mb-5 mt-3'>Long press the date to add staffs</h6>
                    </div>
                    <FullCalendar
                        timeZone='local'
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth'
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        draggable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={weekendsVisible}
                        selectOverlap={false}
                        eventOverlap={false}
                        eventResize={updateStaff}
                        defaultAllDay={true}
                        events={staffs}
                        select={handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        longPressDelay={0}
                        // dateClick={handleDateClick}
                        // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        /* you can update a remote database when these fire:
                        eventAdd={function(){}}
                         eventChange={function(){}}
                        eventRemove={function(){}}
                        */
                        // eventChange={function (info) { console.log("change info => ", info) }}
                        validRange={{
                            start: new Date(),
                            end: null
                        }}
                        eventAdd={addStaff}
                        eventChange={updateStaff}
                    />
                </div>
          
            </div>




        </div>
    )
}

export default StaffCalendar