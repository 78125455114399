import React, { useEffect, useState } from 'react'
import sorryImg from "../../../assets/images/text-sry.webp";
import paymentFailedImg from "../../../assets/images/creditcard-payment-failed.webp";
import PaymentFailedX from "../../../assets/images/PaymentFailNew.svg"
import Header from '../../../components/elements/header/Header';
import Footer from '../../../components/elements/footer/Footer';
import { Container, Row, Card } from "react-bootstrap"
import { getUserOneOrderAction } from "../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { MdArrowBackIosNew } from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';

function ReschduleFailed() {

    const [searchParams] = useSearchParams();
    const [orderDetails, setOrderDetails] = useState({})
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);




    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="payment-failed-section">
            <Header />
            <section className='failure-inner'>
                <div class="container-fluid" id='transcation-failed'>
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                        <Container>
                                            <Row className='text-center'>
                                            <img src={PaymentFailedX} alt="small-info" className="payment-success-icon mt-5" />
                                                <div className='thanks-note'>
                                                <h1 className='mt-5'>Reschedule failed! </h1>
                                                <p>Reschedule failed!, please Try again..</p>
                                                 <Card className='orderid-card'>
                                                    <h6>YOUR ORDER ID: {orderDetails?.order_id}</h6>
                                                 </Card>
                                                 <p>Reschedule on:{moment(new Date()).local().format("DD MMM YYYY")}</p>
                                                 <h5 onClick={goBack}><MdArrowBackIosNew size={10}/>Try again</h5>
                                                </div>
                                            </Row>
                                        </Container>
                                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <CartFooter />
        </div>
    )
}

export default ReschduleFailed