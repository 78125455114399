import * as Yup from 'yup';

export const businessDocValidation = Yup.object({

    business_store_logo_image_url: Yup.string('Upload business logo image').required('business logo image is required'),
    business_store_image_url: Yup.string('Upload business store image').required('business store image is required'),
    // pan_no: Yup.string('Enter the pan number').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid pan number").required("Pan number is required"),
    // pan_no_image_url: Yup.string('upload pan card image').required('Pan card image is required'),
    // gst_no: Yup.string('Enter the GST No').matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Please enter valid gst number").notRequired('GST No is required'),
    trade_licence:Yup.string().matches(/^\d{7}$/, 'Commercial Registration Number must be an 7-digit number'),
    trade_licence_url:Yup.string('upload Commercial Registration Number image').required('Commercial Registration Number image is required'),
    emirates_id: Yup.string().min(1, 'Civil Id can not be empty').required('Civil id is required'),
    emirates_id_url:Yup.string('upload Civil id image').required('Civil id image is required'),
});

export const freelancerDocValidation = Yup.object({
    pan_no: Yup.string('Enter the pan number').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid pan number").required('Pan card number is required'),
    pan_no_image_url: Yup.string('upload pan card image').required('Pan card image is required'),
    aadhaar_no: Yup.string('Enter the aadhaar number').matches(/^\d{4}\d{4}\d{4}$/, "Please enter valid aadhaar number").required('Aadhaar number is required'),
    aadhaar_no_image_url: Yup.string('Upload Aadhaar card image').required('Aadhaar card image is required'),
});
