import axios from 'axios';
import * as TYPES from './types.js';
import UserService from './services';
import { toast } from "react-toastify";
import { logoutFromLocalStorage } from '../auth/actions';

export const getUserFavoritesAction = (type) => () => {
    return UserService.getUserFavorites(type).then((response) => {
        const { data } = response; 
        if (response && response.status === 200 ){
            toast.success(data.message)
        } else {
            toast.warning(data.message)
        }
    })
}

export const getUserFavoriteServiceAction = (type) => () => {
    return UserService.getServiceFavorites(type).then((response) => {
        const { data } = response; 
        if (response && response.status === 200 ){
            return data
        } else {
            toast.warning(data.message)
        }
    })
}
export const getUserProfileUpdateAction = (type) => () => {
    return UserService.getFavoriteProfileUpdates(type).then((response) => {
        const {data} = response;
        if (response && response.status === 200){
            return data
        } else {
            toast.warning(data.message)
        }
    })
}
export const getAvailableSlotsAction = (type) => (dispatch) => {
    return UserService.getAvailableSlotsService(type)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                const { date, response } = data;
                if (response[date]) {
                    toast.warning(data.message);
                    dispatch({
                        type: TYPES.GET_ALL_SLOT_DETAILS,
                        payload: {
                            slot: response[date],
                            date: date
                        },
                    });
                    return data;
                }
                else {
                    dispatch({
                        type: TYPES.GET_ALL_SLOT_DETAILS_FAIL,
                        payload: {},
                    });
                }

            }
            else {
                toast.warning(data.message);
                dispatch({
                    type: TYPES.GET_ALL_SLOT_DETAILS_FAIL,
                    payload: {},
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ALL_SLOT_DETAILS_FAIL,
                payload: {},
            });
            return Promise.reject(error);
        });
};


export const slotBookingAction = (data) => (dispatch) => {
    return UserService.slotBookingService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                // toast.success(data.message)
                console.log(response, "hshdjshdjhsjd")
                dispatch({
                    type: TYPES.ADD_APPOINTMENT_DETAILS,
                    payload: data.appointment
                });
                return data.appointment;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const RequestBridalAction = (data) => (dispatch) =>{
  return UserService.freelancerRequestService(data)
  .then((response)=>{
    console.log(response, "redux")
    const {data} = response;
    if(response && response.status === 200){
        toast.success(data.message)
        return response;
    } else {
        toast.warning(data.message);
    }
  })
  .catch((error)=>{
    if(error.response){
        const { status, data} = error.response
        if(status > 200 && status <= 500){
            if (status === 401) {
                dispatch(logoutFromLocalStorage());
            }
            toast.warning(data.message);

        }else {
            toast.error("Something went wrong");
        }
    }  else {
        toast.error("Something went wrong");
    }
    return Promise.reject(error);
  });
};

export const updateUserLocationAction = (data) => (dispatch) => {
    return UserService.updateUserLocation(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const createUserLocationAction = (data) => (dispatch) => {
    return UserService.createUserLocation(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getUserLocationAction = (data) => (dispatch) => {
    return UserService.getUserLocation(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return response;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};




export const createOrderAction = (data) => (dispatch) => {
    return UserService.createOrderService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};



export const updateOrderAction = (data) => (dispatch) => {
    return UserService.updateOrderService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message);
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getUserAllOrdersAction = (data) => (dispatch) => {
    return UserService.getAllUserOrdersService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const getUserOneOrderAction = (data) => (dispatch) => {
    return UserService.getOneUserOrdersService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const getUserOneRequestAction = (data) => (dispatch) => {
    return UserService.getOneUserRequestService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};

export const getUserAllRequestAction = (data) => (dispatch) => {
    return UserService.getAllUserRequestService(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                return data;
            }
            else {
                toast.warning(data.message);
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject(error);
        });
};


export const addAppointmentDetails = (type) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_APPOINTMENT_DETAILS,
        payload: type
    });
};





export const addOrderDetails = (type) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_ORDER_DETAILS,
        payload: type
    });
};


export const addUserLocationDetails = (type) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_USER_LOCATION_DETAILS,
        payload: type
    });
};


export const clearUserAllDetailsAction = (type) => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_ALL_USER_ORDER_DETAILS,
        payload: type
    });
};


export const clearSlotDetails = () => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_SLOT_DETAILS,
        payload: {}
    });
};

