import React, { useState, useEffect } from 'react';
import { getAllUsers, deleteUser, findAllTransactions } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Modal, Row, Col, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { DateRange } from 'react-date-range';
import moment from "moment";
import { BsFillCalendar3WeekFill } from 'react-icons/bs'

function TransactionDetails() {

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [Delete, setDelete] = useState(false);
    const [show, setShow] = useState(false);
    const [stateDate, setStateDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });

    const dispatch = useDispatch();

    const { users } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const data = {
            start: moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
            end: moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
            page: state.activePage,
            search: "",
        };
        dispatch(findAllTransactions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })
    }, []);

    const dateRange = () => {
        const data = {
            start: moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
            end: moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
            page: state.activePage,
            search: "",
        };
        dispatch(findAllTransactions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })

    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber.selected);
        const data = {
            page: pageNumber?.selected + 1,
            start: moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
            end: moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
            search: "",
        };
        dispatch(findAllTransactions(data))
            .then((res) => {
                const { resultsPerPage, results, totalcount } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: totalcount / resultsPerPage
                }))
            })

    };


    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        const data = {
            page: state.activePage,
            search: e.target.value,
            start: moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
            end: moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
        };

        dispatch(findAllTransactions(data))
            .then((res) => {
                const { orderCountPerPage, results } = res
                setState((prev) => ({
                    ...prev,
                    data: results,
                    total: orderCountPerPage
                }))
            })
    };


    return (
        <div className='container-xxl'>
            <div className="order-list mt-3">
                <Row>
                    <Col md={6}>
                        <h3 className='marketing-header'>Transaction History</h3>
                    </Col>
                    <Col md={6}>
                        <h4 className='date-right'>
                            <span>
                                {
                                    moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                                        <span className="cur-pointer" onClick={() => setShow(true)}>
                                            <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                                            <span className='mx-2'>to</span>
                                            <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.endDate).format("MMM D, YYYY")}</span>
                                        </span>
                                    ) : (
                                        <span className='cur-pointer' onClick={() => setShow(true)}>
                                            <span className='provider-name-dashboard '>{moment(stateDate?.[0]?.startDate).format("MMM D, YYYY")}</span>
                                        </span>
                                    )
                                }
                            </span>
                            <span className="provider-name-dashboard  mx-3">
                                <BsFillCalendar3WeekFill className='cur-pointer' onClick={() => setShow(true)} />
                            </span>
                            <Modal show={show}>
                                <Modal.Header closeButton onClick={() => setShow(false)} className="mt-2">
                                    <Button className="date-apply" variant="" onClick={dateRange}>Apply</Button>
                                </Modal.Header>
                                <div className='calendar-view'>
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={item => setStateDate([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={stateDate}
                                        minDate={(new Date('01/01/2022'))}
                                    />
                                </div>

                            </Modal>

                        </h4>
                    </Col>

                </Row>
                <hr />
                <div className="service-list-header-option mt-4">
                    <div className="business-search w-50">
                        <input type="text" class="form-control" id="" placeholder="search by salon name" name="search" onInput={onChangeSearch} />
                    </div>
                </div>
                <hr className='p-0 mb-0' />

                <section class="pt-0">
                    <div class=" mt-4">
                        <div class="table-responsive slaylewks-table">
                            <Table responsive>
                                <thead className='admin-coupon-list-head'>
                                    <tr className='tabel-data-content'>
                                        <th className='admin-tabel-heading'>
                                            Reg Id
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Salon Name
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Salon Payout
                                        </th>
                                        <th className='admin-tabel-heading'>
                                            Slaylewks Commission </th>
                                        <th className='admin-tabel-heading'>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                        
                                          {
                                        state?.data?.map((item, index) => {
                                            return (
                                                <tr className='tabel-data-content' key={index}>
                                                    <td class="sss">{item?.vendor_id}</td>
                                                    <td >{item?.salon_name}</td>
                                                    <td >{Math.round(item?.payout_to_salon) + ".00"}</td>
                                                    <td className='active-coupons'>{Math.round(item?.commission_to_slaylewks) + ".00"}</td>



                                                </tr>
                                            )
                                        })
                                    }
                                        
                                       

                                  

                                </tbody>
                            </Table>
                        </div>
                    </div>

                </section>
                <div>


                    {
                        state?.total > 1 && (
                            <div className='mt-5'>
                                <ReactPaginate
                                    previousLabel="<"
                                    nextLabel=">"
                                    breakLabel="..."
                                    pageCount={state?.total}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-center pagination-box'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    forcePage={page}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TransactionDetails;