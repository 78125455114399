import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Form,
    Modal,
    Alert,
    FloatingLabel,
    Button,
    Row,
    Col,
    Card
} from "react-bootstrap";
import { useFormik, FormikProvider, ErrorMessage } from 'formik';
import { createAdminCouponCode, updateAdminCouponCodeAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import moment, { min } from "moment";
import "react-datepicker/dist/react-datepicker.css"
import { couponAdminValidation } from "../../../validations/yub_schemas/couponAdminValidation";
import { adminGetServiceProviderAction } from "../../../redux/dashboard/actions";
import { couponAdminEditValidation } from "../../../validations/yub_schemas/couponAdminEditValidation";
import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";


function AdminCouponEdit(props) {
    const couponData = props.formData;

   
    const [discountAmount, setDiscountAmount] = useState( props.formData ? props.formData.discount_offer : [])
    
    const [state, setState] = useState({
    
    });

    const { vendor } = useSelector((state) => state.business);
    const [copyText, setCopyText] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [code, setCode] = useState("");
    const [generateCode, setGenerateCode] = useState(false)
    const [adddiscounts, setAdddiscounts] = useState(false);
    const [dateText, setDateText] = useState(false);
    const [validrange, SetValidrange] = useState(false)
    const [discountminus, Setdiscountminus] = useState(false)
    const [generateText, setGenerateText] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [options, setOptions] = useState()
    const [selectedProviders, setSelectedProviders] = useState([]);

    const [discountData, setDiscountData] = useState(
        {
            min: "",
            max: "",
            amount: ""
        }
    )

    const selectStartDate = (date) => {
        setStartDate(date);
    }
    const selectEndDate = (date) => {
        setendDate(date);
    }
    const serviceproviderslist = couponData?.serviceprovider?.map((item)=>{
            return ({"label": item?.business_name, "value": item?.business_name})
         })

    useEffect(()=>{
        setSelectedProviders(serviceproviderslist)
        formik.setFieldValue("serviceprovider", selectedProviders)
    },[couponData])  
  
    const addDiscountAmount = () => {
        let numbers = /^[0-9]+$/;
      
        discountData.max = Number(discountData.max);
        discountData.min = Number(discountData.min);
        discountData.amount = Number(discountData.amount);
        if( numbers.test(discountData.min  || discountData.max || discountData.amount)) {
            if(discountData.max > discountData.min && discountData.amount < discountData.max){
                if(discountData.max && discountData.min && discountData.amount >= 0){
                    let temp = formik.values.discount_offer
                    temp = temp.concat({...discountData})
                    formik.setFieldValue("discount_offer", temp)
                    setAdddiscounts(false);
                    SetValidrange(false);
                    Setdiscountminus(false)

                } else {
                    Setdiscountminus(true)
                    setAdddiscounts(false);
                    SetValidrange(false);
                }
               
            }else {
                SetValidrange(true);
                setAdddiscounts(false);
                Setdiscountminus(false)
            }
        } else {
            setAdddiscounts(true);
            Setdiscountminus(false);
            SetValidrange(true);
        }
        
    }
    // const [startTimeValue, setStartTimeValue] = useState([]);
    // const [endTimeValue, setEndTimeValue] = useState([])

    // if(couponData.time_limit_type === "limited duration"){
    //     setStartTimeValue(couponData?.fromTime);
    //     setEndTimeValue(couponData?.toTime);
    // }else{
    //     setStartTimeValue([]);
    //     setEndTimeValue([]);
    // }

  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: couponData?._id,
            coupon_type: couponData?.coupon_type,
            coupon_name: couponData?.coupon_name,
            custom_code: couponData?.custom_code,
            serviceprovider: '',
            usage_limit_type: couponData?.usage_limit_type,
            usage_limit: couponData?.usage_limit,
            time_limit_type: couponData?.time_limit_type,
            toTime: couponData?.toTime || '',
            fromTime: couponData?.fromTime || '',
            per_user_limit_type: couponData?.per_user_limit_type,
            per_user_limit: couponData?.per_user_limit,
            discount_offer_type: couponData?.discount_offer_type,
            discount_offer: [
            ],
            description: couponData?.description,

        },
        validationSchema: couponAdminEditValidation,
        onSubmit: (values) => {
              let finalDiscount = discountAmount.concat(values.discount_offer)
            const data = {
                id: couponData?._id,
                coupon_type: values.coupon_type,
                coupon_name: values.coupon_name,
                custom_code: values.custom_code,
                serviceprovider: values.serviceprovider,
                usage_limit_type: values.usage_limit_type,
                usage_limit: values.usage_limit,
                time_limit_type: values.time_limit_type,
                toTime: values.toTime,
                fromTime: values.fromTime,
                per_user_limit_type: values.per_user_limit_type,
                per_user_limit: values.per_user_limit,
                discount_offer_type: values.discount_offer_type,
                discount_offer: finalDiscount,
                description: values.description,

            };
            dispatch(updateAdminCouponCodeAction(data))

                .then((res) => {
                    props.onHide();
                    props.getCouponCodes();
                })
                .catch((err) => {
                    console.log("Err => ", err)
                })


        },
    });

    const handelGenerateCode = () => {

        if (formik.values.coupon_name !== "" && formik.values.serviceprovider !== ""
            && formik.values.discount_offer !== "") {

            setGenerateCode(true)
            makeid()
            setGenerateText(false)

        } else {
            setGenerateCode(false)
            setGenerateText(true)
        }
    }

    const handelGenerateCodeCustom = () => {

        if (formik.values.coupon_name !== "" && formik.values.serviceprovider !== ""
            && formik.values.discount_offer !== "" && formik.values.custom_code !== "") {

            setGenerateCode(true);
            customid();
            setGenerateText(false);

        } else {
            setGenerateCode(false);
            setGenerateText(true);
        }
    }

    const makeid = () => {

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        formik.values.coupon_code_business = text;
        setCode(text)
    }

    const customid = () => {
        var text = formik.values.custom_code;
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 4; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        formik.values.coupon_code_business = text;
        setCode(text);
    }
    const changediscountPercentage = (e) => {
        formik.setFieldValue("discount_offer_type", e)
    }

    const changeUsagelimit = (e) => {
        formik.setFieldValue("usage_limit_type", e)
    }

    const changeuserLimt = (e) => {
        formik.setFieldValue("time_limit_type", e)
    }

    const changeTimeLimit = (e) => {
        formik.setFieldValue("per_user_limit_type", e)
    }


    const copiedtoClip = () => {
        setCopyText(true)
        setTimeout(() => {
            setCopyText(false)

        }, 2000)
    }

    useEffect(() => {
       
        dispatch(adminGetServiceProviderAction())
            .then((res) => {
                const {result} =res
                setState(res)
                setOptions(res.data)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }, [])


    const removeDiscount = (index) => {
        let discountValues = formik.values.discount_offer;
        discountValues.splice(index, 1);
        formik.setFieldValue("discount_offer", discountValues)
    };
    const removeDiscountCoupon = (index) => {
         const removedItem = discountAmount.splice(index, 1);
         setDiscountAmount(removedItem)
    };



    useEffect(()=>{
         setDiscountAmount(props?.formData?.discount_offer)
    }, [props?.formData, discountAmount])

    const addingServiceProviders = (e) => {
        setSelectedProviders(e)
        formik.setFieldValue("serviceprovider", e)
    }
    const overrideStrings = {
        "allItemsAreSelected": "All the service providers are selected",
        "clearSearch": "Clear Search",
        "clearSelected": "Clear Selected",
        "noOptions": "No options",
        "search": "Search for service providers",
        "selectAll": "Select All",
        "selectAllFiltered": "Select All (Filtered)",
        "selectSomeItems": "Choose the service provider for this coupon",
        "create": "Create",
      }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="marketing-body-content">
                    <Form noValidate className="marketing-register-form text-start" onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <div className="code-modal-header">
                                <h4>Generate coupon code</h4>
                            </div>
                            <Row className="mt-4 custom-code-container">
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3" controlId="coupon_type">
                                        <Form.Select
                                            className="form-select-category"
                                            name="coupon_type"
                                            onChange={formik.handleChange}
                                            value={formik.values.coupon_type}
                                            isInvalid={!!formik.touched.coupon_type && !!formik.errors.coupon_type}
                                        >
                                            <option value="Custom code">Custom Code</option>
                                            <option value="Auto code">Auto Code</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    formik.values.coupon_type != "Auto code" ? (
                                        <div className="p-0">
                                            <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                <Form.Label column sm="12" className="form-label-coupon-code">Coupon Code</Form.Label>
                                                <Col sm="12">
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Custom Coupon Code*"
                                                        name="custom_code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.custom_code}
                                                        isInvalid={!!formik.touched.custom_code && !!formik.errors.custom_code}
                                    
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.custom_code}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                            </Row>
                            <hr />

                            <div className="code-modal-body">

                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code">Coupon Name</Form.Label>
                                    <Col sm="12">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Coupon Name*"
                                            name="coupon_name"
                                            onChange={formik.handleChange}
                                            value={formik.values.coupon_name}
                                            isInvalid={!!formik.touched.coupon_name && !!formik.errors.coupon_name}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.coupon_name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Service Provider</Form.Label>
                                                    <Col sm="12">
                                                    {/* <Multiselect
                                                    showCheckbox 
                                                    hidePlaceholder
                                                     options={options}
                                                     displayValue='business_name'
                                                     placeholder="Please select"
                                                     onSelect={(event)=>{ formik.setFieldValue("serviceprovider", event) }}
                                                     onRemove={(event)=>{ formik.setFieldValue("serviceprovider", event) }}
                                                     name="serviceprovider"
                                                     selectedValues={couponData?.serviceprovider || formik.values.serviceprovider}
                                                    /> */}

<MultiSelect
                                                       options={options?.map((item)=>{
                                                        return(
                                                            {"label": item?.business_name, "value": item?.business_name }
                                                        )
                                                     })}
                                                       hasSelectAll={("hasSelectAll", true)}
                                                       isLoading={("isLoading", false)}
                                                       value={selectedProviders}
                                                       disabled={("disabled", false)}
                                                       onChange={(e)=> addingServiceProviders(e)}
                                                       name="serviceprovider"
                                                       overrideStrings={overrideStrings}

                                                    />
                                                  
                                                        <p>
                                                        <ErrorMessage name="serviceprovider" />
                                                        </p>

                                                        {/* <Form.Control.Feedback type="invalid">
                                                            {formik.errors.serviceprovider}
                                                        </Form.Control.Feedback> */}
                                                    </Col>
                                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code" >Discount Offer</Form.Label>
                                    <Col sm="12">

                                        <Row className="mb-3 px-3">
                                            <Col md={6} sm={6} xs={6} className="p-0 ">
                                                <Card onClick={() => {
                                                    changediscountPercentage("in price")
                                                }} className={formik.values.discount_offer_type === "in price" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p>In price</p>
                                                </Card>
                                            </Col>
                                            <Col md={6} sm={6} xs={6} className="p-0">
                                                <Card onClick={() => {

                                                    changediscountPercentage("in percentage")
                                                }} className={formik.values.discount_offer_type === "in percentage" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p>In Percentage</p>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="px-3 mt-2">
                                            <Col md={10} sm={10} xs={10}>
                                                <Row>
                                                    <Col md={4} sm={4} xs={4}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Min"
                                                            name="min"
                                                            onChange={(e) => {
                                                                setDiscountData({ ...discountData, min: e.target.value });
                                                                // formik.setFieldValue("min", e.target.value);
                                                            }}
                                                            value={discountData?.min}
                                                        // value={formik.values.discount_offer.min}
                                                        />

                                                    </Col>
                                                    <Col md={4} sm={4} xs={4}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Max"
                                                            name="max"
                                                            onChange={(e) => {
                                                                setDiscountData({ ...discountData, max: e.target.value });
                                                            }}
                                                            value={discountData?.max}
                                                        />
                                                    </Col>
                                                    <Col md={4} sm={4} xs={4}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Amount"
                                                            name="amount"
                                                            onChange={(e) => {
                                                                setDiscountData({ ...discountData, amount: e.target.value });
                                                            }}
                                                            value={discountData?.amount}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2} sm={2} xs={2}>
                                                <h6 className="add-discount-amt-icon" onClick={addDiscountAmount}>
                                                    <ion-icon name="add-circle-outline"></ion-icon>
                                                </h6>
                                            </Col>
                                        </Row>
                                       {

                                        props.formData &&  (
                                            <div>
                                            {
                                                             discountAmount?.map((item, index) => {
                                                                 return (
                                                                     <Row className="d-flex px-3 mt-2 mb-0 py-0" key={index}>
                                                                         <Col md={10} sm={10} xs={10}>
                                                                             <Row>
                                                                                 <Col md={4} sm={4} xs={4}>
                                                                                     <p>{item?.min}</p>
                                                                                 </Col>
                                                                                 <Col md={4} sm={4} xs={4}>
                                                                                     <p>{item?.max}</p>
                                                                                 </Col>
                                                                                 <Col md={4} sm={4} xs={4}>
                                                                                     <p>{item?.amount}</p>
                                                                                 </Col>
                                                                             </Row>
                                                                         </Col>
                                                                         <Col md={2} sm={2} xs={2}>
                                                                             <h6 className="close-discount-amt" onClick={() => removeDiscountCoupon(index)}><ion-icon name="close-circle-outline"></ion-icon></h6>
                                                                         </Col>
                                                                     </Row>
                                                                 )
                                                             })
                                                         }
                                            </div>
                                        )
                                       }




                                     
                                        {
                                            formik.values.discount_offer.length > 0 ? (
                                                <Row className="px-3">
                                                    
                                                    {
                                                        formik.values.discount_offer?.map((item, index) => {
                                                            return (
                                                                <Row className="d-flex px-3 mt-2 mb-0 py-0" key={index}>
                                                                    <Col md={10} sm={10} xs={10}>
                                                                        <Row>
                                                                            <Col md={4} sm={4} xs={4}>
                                                                                <p>{item?.min}</p>
                                                                            </Col>
                                                                            <Col md={4} sm={4} xs={4}>
                                                                                <p>{item?.max}</p>
                                                                            </Col>
                                                                            <Col md={4} sm={4} xs={4}>
                                                                                <p>{item?.amount}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={2} sm={2} xs={2}>
                                                                        <h6 className="close-discount-amt" onClick={() => removeDiscount(index)}><ion-icon name="close-circle-outline"></ion-icon></h6>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            ) : ( null)
                                        }
                                        <p className="manual-error-style px-3">
                                            <ErrorMessage name="discount_offer" />
                                        </p>
                                        {
                                            adddiscounts && (
                                                <p className="manual-error-style px-3"> please enter the amount</p>
                                            )
                                        }
                                        {
                                            validrange && (
                                                <p className="manual-error-style px-3"> please choose the min and max amount correctly</p>
                                            )
                                        }
                                        {
                                            discountminus && (
                                                <p className="manual-error-style px-3"> Cant accept values in minus</p>
                                            )
                                        }
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code">Usage Limit</Form.Label>
                                    <Col sm="12">
                                        <Row className="mb-3 px-3">
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => { changeUsagelimit("limited") }} className={formik.values.usage_limit_type === "limited" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p> Limited</p>
                                                </Card>
                                            </Col>
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => {
                                                    changeUsagelimit("unLimited")
                                                }} className={formik.values.usage_limit_type === "unLimited" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p> Unlimited</p>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {
                                            formik.values.usage_limit_type === "limited" ? (
                                                <Row className="px-3">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Coupon Limit"
                                                        name="usage_limit"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.usage_limit}
                                                        isInvalid={!!formik.touched.usage_limit && !!formik.errors.usage_limit}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.usage_limit}
                                                    </Form.Control.Feedback>

                                                </Row>
                                            ) : (
                                                <Row className="px-3">
                                                    <Form.Control
                                                        disabled

                                                    />
                                                </Row>
                                            )
                                        }

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code">Time Limit</Form.Label>
                                    <Col sm="12">
                                        <Row className="mb-3 px-3">
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => { changeuserLimt("life time") }} className={formik.values.time_limit_type === "life time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p> Life Time</p>
                                                </Card>
                                            </Col>
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => { changeuserLimt("limited duration") }} className={formik.values.time_limit_type === "limited duration" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p>Limited Duration</p>
                                                </Card>
                                            </Col>
                                            <Row className="calander-section-coupon mt-3">
                                                {
                                                    formik.values.time_limit_type === "limited duration" ? (
                                                        <>
                                                            <Col md={6} sm={6} xs={6} className="p-0">
                                                                <DatePicker
                                                                    className="to-datePicker"
                                                                    name="fromTime"
                                                                    minDate={new Date()}
                                                                    selected={startDate}
                                                                    onChange={(date) => {
                                                                        selectStartDate(date);
                                                                        formik.setFieldValue("fromTime", moment(date).format("YYYY-MM-DD"));
                                                                    }}
                                                                    placeholderText="From"
                                                                />
                                                                <span className="date-cal-coupon">
                                                                    <ion-icon name="calendar-outline"></ion-icon>
                                                                </span>

                                                            </Col>
                                                            <Col md={6} sm={6} xs={6} className="p-0">
                                                                <DatePicker
                                                                    className="to-datePicker"
                                                                    name="toTime"
                                                                    minDate={new Date()}
                                                                    selected={endDate}
                                                                    onChange={(date) => {
                                                                        selectEndDate(date);
                                                                        formik.setFieldValue("toTime", moment(date).format("YYYY-MM-DD"));
                                                                    }}
                                                                    placeholderText="To"
                                                                />
                                                                <span className="date-cal-coupon">
                                                                    <ion-icon name="calendar-outline"></ion-icon>
                                                                </span>



                                                            </Col>

                                                        </>
                                                    ) : (
                                                        < div className="life-time-empty px-3">
                                                            <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    value={""}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    )
                                                }

                                            </Row>
                                        </Row>

                                    </Col>
                                    <Row>
                                        <Col>
                                            <p className="manual-error-style px-3">
                                                <ErrorMessage name="fromTime" />
                                            </p>

                                        </Col>
                                        <Col>
                                            <p className="manual-error-style px-3">
                                                <ErrorMessage name="toTime" />
                                            </p>
                                        </Col>
                                    </Row>

                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code">Per User Limit</Form.Label>
                                    <Col sm="12">

                                        <Row className="mb-3 px-3">
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => { changeTimeLimit("one time") }} className={formik.values.per_user_limit_type === "one time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p>One Time</p>
                                                </Card>
                                            </Col>
                                            <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                <Card onClick={() => { changeTimeLimit("multiple time") }} className={formik.values.per_user_limit_type === "multiple time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                    <p>Multiple Time</p>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {
                                            formik.values.per_user_limit_type === "one time" ? (
                                                <Row className="px-3">
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        value={""}
                                                    />
                                                </Row>
                                            ) : (
                                                <Row className="px-3">
                                                    <Form.Control

                                                        type="text"
                                                        placeholder="Enter user limit"
                                                        name="per_user_limit"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.per_user_limit}
                                                        isInvalid={!!formik.touched.per_user_limit && !!formik.errors.per_user_limit}
                                                    />
                                                </Row>
                                            )
                                        }
                                        <p className="manual-error-style px-3">
                                            <ErrorMessage name="per_user_limit" />
                                        </p>
                                    </Col>

                                </Form.Group>
                                <Form.Group className="mb-3 px-2" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label className="form-label-coupon-code">Description</Form.Label>
                                    <Form.Control as="textarea" name='description' rows={3} placeholder="Description Eg: Get 20% on orders of 250rs, 50% on orders of 2000rs by using code SLAY50" onChange={formik.handleChange} values={formik.values.description} />
                                </Form.Group>
                                {/* <div className="text-center mt-5 d-grid gap-2">
                                    {
                                         formik.values.coupon_type != "Auto code" ? (
                                            <Button variant="primary" size="lg" onClick={() => handelGenerateCodeCustom()} className="generate-coupon-code">
                                            Generate Custom Code
                                        </Button>
                                         ) : (
                                            <Button variant="primary" size="lg" onClick={() => handelGenerateCode()} className="generate-coupon-code">
                                            Generate Auto Code
                                        </Button>
                                         )
                                    }
                                   
                                </div> */}
                            </div>
                            <hr />
                            {/* {
                            
                            generateCode && code && code !== "" && (
                                    <div className="code-generate-sec">
                                        <h4>
                                            Copy Coupon Code
                                        </h4>
                                        <div className="random-code-sec text-center">
                                            <h1 className="mt-5">
                                                {code}
                                            </h1>
                                            <div className="marketing-code-share mt-4">
                                            <span className="mx-2">
                                            <CopyToClipboard text={code} onCopy={copiedtoClip} >
                                            <ion-icon name="copy-outline"></ion-icon>
                                            </CopyToClipboard>
                                           
                                            </span>
                                            <span className="mx-2">
                                            <ion-icon name="share-social-outline"></ion-icon>

                                            </span>
                                                    {
                                                        copyText ? (
                                                            <>
                                                            <p className="text-success">Coupon code copied</p>
                                                            </>
                                                        ) : (
                                                              null
                                                        )
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } 
                            {
                                generateText && (
                                    <p className="manual-error-style px-3">Please fill the form before generating the Coupon</p>
                                )
                            } */}

                            <div className="code-action-btn">
                                <Row>
                                    <Col md={6} sm={6} xs={6}>
                                        <Button variant="" className="cancel-coupon-code-cancel" onClick={props.onHide}>Cancel</Button>
                                    </Col>
                                    <Col md={6} sm={6} xs={6}>
                                        <Button variant="" className="save-coupon-code" type="submit">Save</Button>
                                    </Col>
                                </Row>
                            </div>
                        </FormikProvider>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AdminCouponEdit