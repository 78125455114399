import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Header from "../../components/elements/header/Header";
import Footer from "../../components/elements/footer/Footer";
import HomeSearchBar from "./HomeSearchBar";
import MainServices from "./MainServices";
import TrendingServices from "./TrendingServices";
import HowItWorks from "./HowItWorks";
import CustomerReviews from "./CustomerReviews";
import { changeVendorType } from "../../redux/business/actions";
import NewUserHome from "./NewUserHome";



const Home = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeVendorType(""));
    }, [])

    return (
        <div className="homepage">
            <Header />
            {/* <HomeSearchBar />
            <MainServices />
            <TrendingServices />
            <HowItWorks />
            <CustomerReviews /> */}
            <NewUserHome />
            <Footer />
        </div>
    );
};

export default Home;