import React from 'react';
import Header from '../../components/elements/header/Header';
import "../../assets/css/policy.css"
import Footer from '../../components/elements/footer/Footer';

function Term() {
    return (
        <div>
            <Header />
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 className="policy-header">Terms of Use</h1>
                            <p className="sub-header">UPDATED AND EFFECTIVE AS OF 30th April 2022</p>
                            <p class="terms-paragraph">Welcome to the SLAYLEWKS website <a href="JavaScript:void(0);">https://slaylewks.com/</a> <b>(“Website”)</b> and/or SLAYLEWKS mobile apps <b>(“App”)</b>(collectively, the <b>“Platform”</b>).PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY SO THAT YOU ARE AWARE OF YOUR RIGHTS AND OBLIGATIONS TOWARDS <b>SLAYLEWKS Pte Ltd.</b></p>
                            <p class="terms-paragraph">
                                The terms of use constitute a legally binding agreement made between you, whether individually or on behalf of an entity (“you”) and Slaylewks Pte Ltd. doing business as Slaylewks( “slaylewks”, “we”, “us”, or “our”) in respect of the use of the platform and services provided by us.
                                BY ACCESSING THE PLATFORM, USING THE SERVICES AND/OR REGISTERING FOR AN ACCOUNT WITH US, YOU GIVE YOUR ACCEPTANCE OF AND CONSENT TO THESE TERMS OF USE, WHICH SHALL INCLUDE THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO AND/OR IMPOSED BY US FROM TIME TO TIME. YOUR ACCEPTANCE OF THESE TERMS OF USE SHALL CONSTITUTE LEGALLY BINDING AGREEMENT BETWEEN SLAYLEWKS AND YOU AS THE USER. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, IN WHOLE OR IN PART, PLEASE DISCONTINUE ACCESSING AND USING THE PLATFORM, ACCOUNT AND/OR SERVICES IMMEDIATELY.
                            </p>

                            <h2 class="terms-paragraph">1.  Description of Services</h2>
                            <p class="terms-paragraph">We enable Customers to avail bookings on Slaylewks Platform by engaging Cervice Providers or an entity for professional beauty related services.  You may use the platform for your personal and business use or for internal business purpose in the organization that you represent. You may connect to the platform using any Internet browser supported. You are responsible for obtaining access to the Internet and the equipment necessary to use the platform. You can create and edit content with your user account and if you choose to do so, you can publish and share such content.</p>
                            <h2 class="terms-paragraph">2.  Intellectual Property Rights</h2>
                            <p class="terms-paragraph">Unless otherwise stated the website/platform is our intellectual property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the platform( collectively the ‘Content’) and the trademarks, service marks and logos contained therein(the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of Singapore, international copy right laws and international conventions. The Contents and Marks provided on the platform are for your information and personal use only. Except as expressly provided in these Terms of use, no part of the platform and no Content or Marks may be copied distributed, republished, transmitted, publicly displayed, modified, rented, sold, or create derivative works of any portion of the Services, the Platform or its Content. You also may not, unless with our prior written consent, mirror or frame any part or whole of the contents of the Platform on any other server or as part of any other website. In addition, you agree that you will not use any robot, spider or any other automatic device or manual process to monitor or copy our Content, without our prior written consent (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this website).</p>
                            <h2 class="terms-paragraph">3.  User Registration</h2>
                            <p class="terms-paragraph">You may be required to register with the platform to make transactions. If you represent an organization and wish to use the Services for corporate internal use, we recommend that you, and all other users from your organization, sign up for user accounts by providing your corporate contact information. In particular, we recommend that you use your corporate email address. You agree to: (i) provide true, accurate, current and complete information about yourself as prompted by the sign up process; and (ii) maintain and promptly update the information provided during sign up to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, outdated, or incomplete, or if Slaylewks has reasonable grounds to suspect that such information is untrue, inaccurate, outdated, or incomplete, Slaylewks may terminate your user account and refuse current or future use of any or all of the Services.</p>
                            <h2 class="terms-paragraph">4.  Guidelines for Reviews</h2>
                            <p class="terms-paragraph">We may provide you areas on the platform to leave ratings and reviews. When posting reviews you must comply with the following criteria:</p>
                            <ul>
                                <li>You should have first-hand experience with person/entity being reviewed</li>
                                <li>Your reviews should not contain offensive profanity, abusive, racist, offensive or hate language</li>
                                <li>Your reviews should not contain discriminatory references based on religion, race, gender ,national origin, age, marital status, sexual orientation or disability</li>
                                <li>Your reviews should not contain reference to illegal activity</li>
                                <li>You should not be affiliated with competitors if posting negative reviews</li>
                                <li>You should not make any conclusions as to the legality of conduct</li>
                                <li>You may not post any false or misleading statement </li>
                                <li>You may not organize a campaign encouraging others to post a review either positive or negative</li>
                            </ul>
                            <p class="terms-paragraph">We may accept, reject or remove reviews in our sole discretion if found to violate any of the guidelines above or otherwise. Reviews are not endorsed by us and do not necessarily represent our opinion or views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities or losses resulting from any review.</p>
                            <h2 class="terms-paragraph">5.  Dispute Resolution</h2>
                            <p class="terms-paragraph">In the event of dispute between Service Provider and Customer, Slaylewks will attempt to facilitate the resolution relating but not limited to transactions made via the platform between the Service Provider and Customer. Slaylewks is not responsible for the dispute resolution under any circumstance and will merely play role as a facilitator. We do not assume liability for any dispute and resultant loses for either Customer or Service Provider. The Customer agrees that Slaylewks has the right to disclose any information Slaylewks retains regarding the Customer to any authorities requesting information from Slaylewks regarding any product, service or advise the Customer may have obtained.</p>
                            <h2 class="terms-paragraph">6.  Third Party Links</h2>
                            <p class="terms-paragraph">The Platform may contain links to third-party websites and services (“Third Party Services”). A link to any Third Party Service does not mean that we endorse it or the quality or accuracy of information presented on it. If You decide to visit a Third Party Service you may do so at your own risk. You are subject to its terms and conditions, and not these Terms. We encourage You to carefully review the legal and privacy notices of all other digital services that You visit.</p>
                            <p class="terms-paragraph">We are in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. We provide these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation, endorsement or sponsorship by us of any linked site and/or any of its content therein.</p>
                            <h2 class="terms-paragraph">7. Suspension and Termination of Account</h2>
                            <p class="terms-paragraph">In the event the Company determines, in its sole discretion, that any activity you perform through our Website is suspicious or related to any prohibited activity, the Company may cancel or suspend your Account and/or access to the Website, block any outstanding Transactions, deny any new Transactions, and/or freeze any funds that may be available to you through our Services. You understand that you shall be held liable for losses incurred by the Company or by any third party due to your non-compliance and/or violation of any of the terms herein or any applicable law. The Company shall be entitled to inform any relevant authorities or entities (including credit reference agencies) of any payment fraud or other criminal activity, and may also engage collection services to recover payments. You further acknowledge and understand that the Company also has the right to suspend or terminate your for any other reason.</p>
                            <h2 class="terms-paragraph">8.  Limitation of Liability</h2>
                            <p class="terms-paragraph">In no event will we or our directors, employees, shareholders or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages,. including lost profit, lost revenue, loss of data and other damages arising from the use of our Platform even if we have been advised of the possibility of  incurring the same. Notwithstanding any other provisions of this Terms of Use, our maximum cumulative liability to you or to any other party for all losses arising out of use of our Platform and/or our Services shall not exceed $100 USD in your jurisdiction or the sum that you have paid to us under such individual contract or transaction, whichever is. lower.</p>
                            <h2 class="terms-paragraph">9.  Governing Law and Jurisdictions </h2>
                            <p class="terms-paragraph">These Terms of Use shall be governed by and construed in accordance with the laws of your jurisdiction without regard to the conflict or choice of law principles.
                                Any dispute, legal action or proceeding arising out of or in connection with these Terms of Use shall be submitted to the jurisdiction of the courts in your jurisdiction, unless Slaylewks in its own discretion chooses to submit the same for settlement via arbitration in your jurisdiction or otherwise.</p>
                            <h2 class="terms-paragraph">10. Changes to Terms of Use </h2>
                            <p class="terms-paragraph">WE MAY AMEND, MODIFY OR UPDATE THESE TERMS OF USE FROM TIME TO TIME. ANY CHANGE WE MADE TO THESE TERMS OF USE WILL BE PUBLISHED ON THE PLATFORM AND, WHERE APPROPRIATE, NOTIFIED TO YOU BY EMAIL OR VIA THE COMMUNICATION CHANNEL MADE AVAILABLE ON THE PLATFORM, WHEREUPON YOUR CONTINUED ACCESS TO THE PLATFORM, ACCOUNT THEREAFTER AND/OR USE OF ANY OF THE SERVICES SHALL CONSTITUTE YOUR ACKNOWLEDGMENT, ACCEPTANCE AND AGREEMENT OF THE CHANGE WE MADE TO THESE TERMS OF USE. PLEASE CHECK BACK FREQUENTLY TO SEE ANY UPDATES OR CHANGES TO THESE TERMS OF USE.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Term