import React, { useEffect, useState, useContext} from 'react';
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer";
import NaturalImg from "../../../assets/images/natural-image.webp"
import { useNavigate, useParams } from "react-router-dom";
import { businessDetailsAction, businessDetailsByGenderAction } from '../../../redux/business/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Nav, Row, Tab, Accordion, Button, Modal, Badge, Tabs, Card, Breadcrumb } from 'react-bootstrap';
import { addToCart, changeServiceProvider, clearCart, clearCartAction, ReadCartActions } from '../../../redux/cart/actions';
import { verifyToken } from '../../../redux/auth/actions'
import { setVendorID } from '../../../redux/payment/actions';
import ReviewPage from './ReviewPage';
import GalleryPage from './GalleryPage';
import { toast } from "react-toastify";
import moment from "moment";
import { BsCash, BsFillCartCheckFill } from "react-icons/bs"
import { BsFillStarFill } from "react-icons/bs";
import { createCartActions } from "../../../redux/cart/actions"
import { setOrderAmount } from '../../../redux/payment/actions';
import { SpinnerDotted } from 'spinners-react';
import { getCartAmounts } from '../../../helpers/cartCalculation';


function SingleBusinessPage() {
    const { servicesCart, serviceProviderCart } = useSelector((state) => state.cart);
    const { user, role, isLoggedIn } = useSelector((state) => state.auth);
    const [serviceprovider, setServiceprovider] = useState({});
    const [genderCategory, setGenderCategory] = useState({});
    const [genderCategoryBridal, setGenderCategoryBridal] = useState({});
    const [timeDate, setTimeDate] = useState(false)
    const [filterView, setFilterView] = useState(false)
    const [search, setSearch] = useState("");
    const [bridalSearch, setBridalSearch] = useState("");



    const iconStyle = { fontSize: "1.5em" };

    const [services, setServices] = useState({
        data: [],
        limit: 5,
        activePage: 1,
        total: 1,
        next: false
    });

    const [bridalServices, setBridalServices] = useState({
        data: [],
        limit: 5,
        activePage: 1,
        total: 1,
        next: false
    });

    const { id } = useParams();
    const dispatch = useDispatch();

    const [originalPrice, setOriginalPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [replace, setReplace] = useState(false);
    const handleClose = () => setReplace(false);
    const handleShow = () => setReplace(true);
    const [showCartIcon, setShowCartIcon] = useState(false);

    const ViewCartShow = () => setShowCartIcon(true);

    const navigate = useNavigate();

    const [select, setSelect] = useState("");
    const [genderFilter, setGender] = useState('')
    const [serviceCat, setServiceCat] = useState('')
    useEffect(async() => {
           
        const data = {
            id: id,
            page: services.activePage,
            type:"basic",
        }
         await dispatch(businessDetailsAction(data))
            .then(async (res) => {
                const { servicesMain, total_count, resultsPerPage, next } = res
                await setServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage,
                    next: next
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

         dispatch(businessDetailsByGenderAction(data))
            .then(async (res) => {
                const { service_provider, gender_service_result } = res
                if (service_provider) {
                    await setServiceprovider(service_provider);
                    await dispatch(setVendorID({ vendorId: service_provider?._id }))
                }

                if (gender_service_result) {
                    setGenderCategory(gender_service_result);
                }
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }, [id])
    

    useEffect(() => {
           
        const data = {
            id: id,
            page: bridalServices.activePage,
            type:"bridal",

        }
         dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage, next } = res
                setBridalServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage,
                    next: next
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

         dispatch(businessDetailsByGenderAction(data))
            .then(async (res) => {
                const { service_provider, gender_service_result } = res
                if (service_provider) {
                   await setServiceprovider(service_provider);
                await dispatch(setVendorID({ vendorId: service_provider?._id }))
                }

                if (gender_service_result) {
                    setGenderCategoryBridal(gender_service_result);
                }
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }, [id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const resetFilters = () => {
        setSearch("")
        const data = {
            id: id,
            type:"basic"
        }
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }

    const resetFilterBridal = () => {
        setBridalSearch("")
        const data = {
            id: id,
            type:"bridal"
        }
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setBridalServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }

    useEffect(() => {

        const {service_price,service_original_price,gstAmount,discountPrice,total} =getCartAmounts(servicesCart,serviceProviderCart);
        setOriginalPrice(service_original_price);
        setDiscount(discountPrice);
        setTotalAmount(service_price);
        dispatch(setOrderAmount({
                total_amount: total,
                taxable_amount: service_price,
                tax_amount: gstAmount,
                discount_amount: discountPrice,
                currency: 'INR' // Change when going international
            }))

    }, [servicesCart]);

    


    const replaceCart = async (service) => {
        await dispatch(verifyToken());

        if (isLoggedIn && role?.role_name === "user") {
            if (user?.email_verified) {
                if (serviceProviderCart?._id) {
                    if (serviceProviderCart?._id !== serviceprovider?._id)  {
                        let cartIds = servicesCart?.map(item => item?.cart_id)
                        if (cartIds?.length > 0) {
                            let clearCartDetails = await dispatch(clearCartAction({ id: cartIds }));
                                dispatch(changeServiceProvider(serviceprovider))
                                await dispatch(createCartActions({ ...select, services: select?._id, user: user?._id, serviceprovider: serviceprovider?._id }))
                                await dispatch(ReadCartActions({ user: user?._id }));
                                setReplace(false)
                            }
                        else {
                            console.log("no cart found");
                        }

                    }
                    else {
                        let type = servicesCart.filter(item => item.type === select.type)
                        if(type.length == 0){
                            let cartIds = servicesCart?.map(item => item?.cart_id)
                            if (cartIds?.length > 0) {
                                let clearCartDetails = await dispatch(clearCartAction({ id: cartIds }));
                                    await dispatch(createCartActions({ ...select, services: select?._id, user: user?._id, serviceprovider: serviceprovider?._id }))
                                    await dispatch(ReadCartActions({ user: user?._id }));
                                    setReplace(false)
                                }
                        }
                        else{
                            setReplace(false)
                        }

                        
                    }

                }
                else {
                    dispatch(changeServiceProvider(serviceprovider));
                    dispatch(createCartActions({ ...select, services: select?._id, user: user?._id, serviceprovider: serviceprovider?._id }));

                }
            } else {
                toast.warning("Please verify your user account in profile settings")
            }

        } else {
            toast.warning("Please login to user account")
        }

    }


    const addServiceToCart = async (service) => {
        setSelect(service)
        await dispatch(verifyToken());

        if (isLoggedIn && role?.role_name === "user") {
            if (user?.email_verified) {
                if (serviceProviderCart?._id) {
                        if (serviceProviderCart?._id === serviceprovider?._id) {
                            let type = servicesCart.filter(item => item.type === service.type)
                            if(type.length > 0){
                                await dispatch(createCartActions({ ...service, services: service?._id, user: user?._id, serviceprovider: serviceprovider?._id }));
                                await dispatch(ReadCartActions({ user: user?._id }));
                            }else{
                                setReplace(true)
                            }
                           
                    } else {
                        setReplace(true)
                    }      
                }
                else {
                    await dispatch(changeServiceProvider(serviceprovider));
                    await dispatch(createCartActions({ ...service, services: service?._id, user: user?._id, serviceprovider: serviceprovider?._id }));
                    await dispatch(ReadCartActions({ user: user?._id }));
                }
            } else {
                toast.warning("Please verify your user account in profile settings")
            }

        } else {
            toast.warning("Please login to user account")
        }

    }

    const checkServiceInCart = (id) => {
        if (servicesCart && servicesCart?.length > 0) {
            let service = servicesCart?.filter(item => item?._id === id)

            if (service?.length > 0) {
                return true
            }
            else return false;
        } else {
            return false
        }
    }

    const onChangeSearch = (e) => {

        setSearch(e.target.value);
        const data = {
            id: id,
            search: e.target.value,
            type: "basic",
            page: services.activePage,
        };
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    };
    
    const onChangeSearchBridal = (e) => {
        setBridalSearch(e.target.value);
        const data = {
            id: id,
            search: e.target.value,
            type: "bridal",
            page: bridalServices.activePage,
        };
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setBridalServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    };

   

    const genderCategoryFilter = (gender, serviceCategory) => {
        setGender(gender)
        setServiceCat(serviceCategory)
        const data = {
            id: id,
            genderCategoryFilter: gender,
            serviceCategory: serviceCategory,
            type:"basic"
        };
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }

    const genderCategoryFilterBridal = (gender, serviceCategory) => {
        setGender(gender)
        setServiceCat(serviceCategory)
        const data = {
            id: id,
            genderCategoryFilter: gender,
            serviceCategory: serviceCategory,
            type: "bridal"
        };
        dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage } = res
                setBridalServices((prev) => ({
                    ...prev,
                    data: servicesMain,
                    total: total_count / resultsPerPage
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }
  

    const ViewcartIcon = () => {
        navigate("/business/cart");
    }

    const handlePageChange = async() => {
        const data = {
            id: id,
            page: services.activePage + 1,
            type:"basic",
            genderCategoryFilter: genderFilter,
            serviceCategory: serviceCat,
            search: search,

        }

        await dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage, next } = res
                setServices((prev) => ({
                    ...prev,
                    data: prev.data.concat(servicesMain),
                    total: total_count / resultsPerPage,
                    activePage: services.activePage + 1,
                    next
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }
    const handlePageChangeBridal = async() => {
        const data = {
            id: id,
            page: bridalServices.activePage + 1,
            type:"bridal",
            genderCategoryFilter: genderFilter,
            serviceCategory: serviceCat,
        }

        await dispatch(businessDetailsAction(data))
            .then((res) => {
                const { servicesMain, total_count, resultsPerPage, next } = res
                setServices((prev) => ({
                    ...prev,
                    data: prev.data.concat(servicesMain),
                    total: total_count / resultsPerPage,
                    activePage: bridalServices.activePage + 1,
                    next
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }
    const homeNavigation = () => {
        navigate("/")
    }

    const goBack = () => {
        navigate("/explore")
      
    }


    const today_slot_time = () =>  {
        let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let day = weekday[moment(new Date()).weekday()];
        let todayTime = serviceprovider?.slot_details?.[day];
          if(todayTime?.[0]?.['open_at'] && todayTime?.[0]?.['close_at']) {
            return<div><p>{day} <span>{moment(todayTime?.[0]?.['open_at'], "HH:mm a")?.format("hh:mm A")+" to " +moment(todayTime?.[0]?.['close_at'], "HH:mm a")?.format("hh:mm A")}</span></p></div>
          }
          return <p>{day} <span className='text-danger mx-2 mt-2'>{" "} closed</span></p>

      }
      const [loading, setLoading] = useState(false);

      useEffect(() => {
        setLoading(true)
        setTimeout(()=> {
           setLoading(false)
        }, 500)
      }, [])

      const entireAddress = serviceprovider?.location_details?.address
       + ' ' + serviceprovider?.location_details?.area 
        + ', ' + serviceprovider?.location_details?.city
         + ', ' + serviceprovider?.location_details?.state
          + ', ' + serviceprovider?.location_details?.country 
          + ' ' + serviceprovider?.location_details?.zipcode 
    return (
        <div>
            {
            loading ?
            <div style={{
              width: "10%",
              margin: "18% auto",
            }}>
                  <SpinnerDotted
                      loading={loading} 
                      size="100%"
                      thickness={180}
                      speed={180}
                      color ="#ECB390"
                      secondaryColor="rgb(255,255,255)"
             />
  
              </div>
              :
<div className="business-details-page">
            
            <Header />
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={homeNavigation} className="subnav-list">Home</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={goBack} className="subnav-list">
                        Explore
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active className="subnav-list">{serviceprovider?.business_name}</Breadcrumb.Item>
                </Breadcrumb>

            </Container>
            <div className="business-detail-content">
          
                <section class="naturals-outer py-2">

                    <div class="container">
                        <Row>
                            <Col md={3}>
                                <div className="mobile-promote-btn">
                                    <img src={serviceprovider?.businessURL} class="img-fluid natural-promote" alt="business-image" />
                                    {/* <a href="JavaScript:void(0);" class="promote-btn"><span>Promoted</span></a> */}
                                </div>
                            </Col>
                            <Col md={9}>
                                <Row>

                                    <div class="natural-list d-flex">
                                        {
                                            serviceprovider?.rating_avg > 0 && (
                                                <div className='me-2'>
                                                    <Badge bg="" className='review-content'>
                                                        <span>
                                                            <BsFillStarFill className='single-page-review' />
                                                        </span>
                                                        <span>
                                                            <span className='single-page-review-count'>
                                                                {serviceprovider?.rating_avg}
                                                            </span>
                                                        </span>
                                                    </Badge>
                                                </div>
                                            )
                                        }
                                        <ul>
                                            {
                                                serviceprovider.gender && serviceprovider.gender.length > 0 && (

                                                    serviceprovider.gender.map((item) => {
                                                        return (
                                                            <li> <span class="common-text">{item.gender_name}</span></li>
                                                        )
                                                    })
                                                )
                                            }
                                        </ul>

                                    </div>

                                    <h2 class="service-title">{serviceprovider?.business_name}</h2>

                                    <div className='d-flex'>
                                        <span className='service-details-singlePage me-3'><ion-icon name="location-outline"></ion-icon></span>
                                        <p class="service-details-singlePage">
                                                {entireAddress}
                                               {
                                                serviceprovider?.location_url && serviceprovider?.location_url !== "" && (
                                                    <a href={serviceprovider?.location_url} target="_blank" class="direction" rel="noreferrer">Direction</a>
                                                )
                                            }
                                        </p>

                                    </div>
                                    <div className='d-flex'>
                                        <span className='me-3 service-details-singlePage mt-1'><ion-icon name="call-outline"></ion-icon></span>
                                        <p className="service-details-singlePage">

                                            {serviceprovider?.business_contactNo}</p>

                                    </div>
                                    <div className='d-flex'>
                                        <span className='me-3 service-details-singlePage mt-1'>
                                            <ion-icon name="mail-outline"></ion-icon>
                                        </span>
                                        <p className='service-details-singlePage'>{serviceprovider?.business_email}</p>
                                    </div>
                                    <div onClick={() => setTimeDate(true)} className="d-flex">
                                        <span className='service-details-singlePage me-3 mt-1'>
                                            <ion-icon name="time-outline"></ion-icon>
                                        </span>
                                        <div className='service-details-singlePage cur-pointer'>                                            
                                                <div>
                                                {today_slot_time()}
                                                </div>      
                                        </div>
                                        <span className='mx-2 mt-1 cur-pointer service-details-singlePage'>
                                            <ion-icon name="caret-down-outline"></ion-icon>
                                        </span>
                                    </div>

                                    <Modal show={timeDate}
                                        onHide={() => setTimeDate(false)}
                                        size="sm"
                                    >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Title className='d-flex mx-5 px-5'>
                                        <p className='mt-1 mx-1'><ion-icon name="time"> </ion-icon></p> 
                                        <p>Timing</p>
                                        </Modal.Title>
                                        <Modal.Body className='px-3 mx-3 mb-4'>
                                            <div className='business-time-section'>
                                                {
                                                    serviceprovider?.slot_details && Object.keys(serviceprovider?.slot_details).length > 0 && (


                                                        Object.keys(serviceprovider?.slot_details).map((key, index) => {

                                                            let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                            let day = weekday[moment(new Date()).weekday()];
                                                            let todaySlot = serviceprovider?.slot_details[day];

                                                            let slot_time = Object.keys(serviceprovider?.slot_details[key]).map((slot) => {
                                                                let open_at = serviceprovider?.slot_details[key][slot]['open_at'] ? moment(serviceprovider?.slot_details[key][slot]['open_at'], "HH:mm").format("hh:mm A") : "";
                                                                let close_at = serviceprovider?.slot_details[key][slot]['close_at'] !== "" ? moment(serviceprovider?.slot_details[key][slot]['close_at'], "HH:mm").format("hh:mm A") : "";

                                                                let slotStr = "";
                                                                if (open_at !== "") {
                                                                    slotStr += open_at;
                                                                    if (close_at !== "") {
                                                                        slotStr += " to " + close_at;
                                                                    }
                                                                }
                                                                return slotStr
                                                            });

                                                            return (

                                                                // <span class="common-text time-icon m-0" key={index}>{key} &nbsp; &nbsp; &nbsp; &nbsp;  : &nbsp; &nbsp; &nbsp; &nbsp;{slot_time.join(" , ")}</span>
                                                               
                                                                  <Row>
                                                                    <div className='display-slot-time'>
                                                                       <p className='w-25'>{key.slice(0,3)} </p>
                                                                       <p className='w-25'>-</p>
                                                                       <p className='service-details-singlePage'>{slot_time.join(" , ")}</p>
                                                                    </div>
                                                                  </Row>
                                                                
                                                                

                                                            )
                                                        })
                                                    )
                                                }
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </section>
                <section class="service-main-outer p-0">

                    <div className='container'>
                        <div className='row'>
                            <Tabs fill justify
                                defaultActiveKey ="services"
                                id="uncontrolled-tab-example"
                                className="mb-3"                           
                            >
                                <Tab tabClassName="explore-single-tab" eventKey="services" title="Services">
                                    <div>
                                        <div class="row">
                                            <div id='system-view' class="col-md-4 sm-12 xs-12 mobile-padding mt-1" style={{ paddingLeft: "0px" }}>
                                                
                                                <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                    <Row className='d-flex mt-3'>
                            <Col >
                                <div className='d-flex'>
                                <span className='fs-5 mx-2 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className=''>Filter</h5>
                                </div>
                            </Col>
                            <Col>
                                <h5 className='text-primary cur-pointer float-end mx-3 reset-fliter-set' onClick={resetFilters}>
                                    Reset
                                </h5>
                                </Col>
                        </Row>  
                        </Accordion.Item>
                                                    {
                                                        Object.keys(genderCategory).length > 0 && (
                                                            Object.keys(genderCategory).map((item, index) => {
                                                                return (
                                                                    <Accordion.Item eventKey={index} className="busiess-details-accordion p-0">
                                                                        <Accordion.Header>
                                                                            <h5 className='fiter-header'>
                                                                                {item}
                                                                            </h5>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>

                                                                            {

                                                                                genderCategory[item].length > 0 && (
                                                                                    genderCategory[item].map(innerItem => {
                                                                                        return (
                                                                                            <div className="cur-pointer-list" onClick={() => genderCategoryFilter(item, innerItem)}>
                                                                                                <a>{innerItem}</a>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                )
                                                                            }

                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                );
                                                            })
                                                        )
                                                    }
                                                </Accordion>
                                            </div>
                                            <div id="mobile-view" class="col-md-4 mobile-padding mt-1" style={{ paddingLeft: "0px" }}>
                                                <Card className='filter-card-mobile'>
                                                    <Row className='d-flex mt-2'>
                                                        <Col >
                                                            <div className='d-flex' onClick={() => setFilterView(true)}>
                                                            <span className='fs-5 mx-2 mt-3 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className='mt-3'>Filter</h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <h5 className='text-primary cur-pointer mt-3 float-end mx-3' onClick={resetFilters}>
                                                                Reset
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Modal show={filterView} onHide={() => setFilterView(false)} className="m-0 p-0">
                                                    <Modal.Header className='pb-0' closeButton></Modal.Header>
                                                    <Modal.Body className='pt-0'>
                                                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                                            {
                                                                Object.keys(genderCategory).length > 0 && (
                                                                    Object.keys(genderCategory).map((item, index) => {
                                                                        return (
                                                                            <Accordion.Item eventKey={index} className="busiess-details-accordion p-0">
                                                                                <Accordion.Header>
                                                                                    <h5 className='fiter-header'>
                                                                                        {item}
                                                                                    </h5>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>

                                                                                    {

                                                                                        genderCategory[item].length > 0 && (
                                                                                            genderCategory[item].map(innerItem => {
                                                                                                return (
                                                                                                    <div className="cur-pointer-list" onClick={() => genderCategoryFilter(item, innerItem)}>
                                                                                                        <p onClick={() => setFilterView(false)}>{innerItem}</p>
                                                                                                    </div>
                                                                                                );
                                                                                            })
                                                                                        )
                                                                                    }

                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        );
                                                                    })
                                                                )
                                                            }
                                                        </Accordion>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                            <div class="col-md-8" style={{ paddingLeft: "0px" }}>

                                                <div class="search-sortby-outer">
                                                    <span className='d-flex'>
                                                        <span className="explore-search-icon fs-5 mt-2 mx-0">
                                                            <ion-icon name="search-outline" ></ion-icon>
                                                        </span>
                                                        <input type="text" class="form-control search-box" id="" placeholder="Services, Stylist or Salon...." name="search" onInput={onChangeSearch} value={search} />
                                                    </span>
                                                    {/* <div class="search-sortby-left">
                                                                                    {/* <p>Stylist:</p>
                                                                                <select class="form-select" aria-label="Default select example">
                                                                                    <option selected>Professional</option>
                                                                                    <option value="1">Premium</option>
                                                                                </select> */}

                                                    {/* </div> */}



                                                    {/* <div class="search-sortby-right">

                                                                                   
                                                                                    <select class="form-select" aria-label="Default select example">
                                                                                        <option selected>Sort by</option>
                                                                                        <option value="1">Shop</option>
                                                                                        <option value="2">Home Service</option>
                                                                                        <option value="3">Both</option>
                                                                                    </select>
                                                                                </div> */}
                                                    {/* <div className="w-50">
                                                                                    <input type="text" class="form-control search-box" id="" placeholder="Service, Category" name="search" />
                                                                                </div> */}

                                                </div>
                                                <div class="service-list">
                                                    {/* <div class="cart-outer">
                                                                                <img src="public/images/cart-image.gif" class="img-fluid cart-image" alt="cart-image" />
                                                                                <span>view cart</span>
                                                                            </div> */}
                                                    <ul>
                                                        {
                                                            services?.data && services?.data?.length > 0 && (
                                                                services.data.map((item, index) => {
                                                                    let gender = ""
                                                                    if (item?.gender && item?.gender?.length > 0) {
                                                                        gender = item?.gender?.map((i) => i.gender_name).join(" ")
                                                                    }
                                                                    if (item.is_active) {
                                                                        let isItemAdded = checkServiceInCart(item?._id)
                                                                        return (
                                                                            <li key={index}>
                                                                                <p className='service-gender-name'>{gender}</p>
                                                                                <h3 className='item-service-name'>{item?.service_name}</h3>
                                                                                <p class="item-service-description">{item?.service_description}</p>

                                                                                <p class="price-time d-flex">
                                                                                    <span className='fs-4 text-dark'><ion-icon name="wallet-outline"></ion-icon></span>
                                                                                    <span class="price-one">₹ {item?.service_price?.['$numberDecimal']}</span>
                                                                                    {
                                                                                        item?.service_discount?.['$numberDecimal'] !== '0' && (
                                                                                            <>
                                                                                                <span class="price-two"><s>₹ {item?.service_original_price?.['$numberDecimal']}</s></span><span class="discount-off">{item?.service_discount?.['$numberDecimal']}% Off</span>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </p>
                                                                                <p class="price-time estimate-time d-flex">
                                                                                    <span className='fs-4 text-dark'><ion-icon name="timer-outline"></ion-icon></span>
                                                                                    <span class="item-time">{item?.duration?.['$numberDecimal']} mins</span></p>
                                                                                {/* <span class="offer-right">{item?.service_discount?.['$numberDecimal']}% Off</span> */}
                                                                                {
                                                                                    isItemAdded ? (
                                                                                        <>
                                                                                            <button class="btn added-btn">Added</button>
                                                                                        </>

                                                                                    )
                                                                                        :
                                                                                        (

                                                                                            <button class="btn book-now" onClick={() => { addServiceToCart(item); ViewCartShow(); }}>Book Now</button>
                                                                                        )
                                                                                }
                                                                                {/* <button class="btn book-now" onClick={() => { addServiceToCart(item) }}>Book Now</button> */}
                                                                                {/* <br /> */}
                                                                                {/* <button class="btn added-btn">Added</button> */}
                                                                            </li>

                                                                        );
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    </ul>
                                                </div>

                                                {
                                                    services?.next ? (
                                                        <div className="loadmore-outer cur-pointer">
                                                            <a onClick={handlePageChange}>Load more</a>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab tabClassName="explore-single-tab" eventKey="bridal" title="Bridal">
                                    <div>
                                        <div class="row">
                                            <div id='system-view' class="col-md-4 mobile-padding mt-1">
                                               
                                                <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                    <Row className='d-flex mt-3'>
                            <Col className='d-flex'>
                            <span className='fs-5 mx-2 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className=''>Filter</h5>
                            </Col>
                            <Col>
                                <h5 className='text-primary cur-pointer float-end mx-3 reset-fliter-set' onClick={resetFilterBridal}>
                                    Reset
                                </h5>
                                </Col>
                        </Row>  
                        </Accordion.Item>
                                                    {
                                                        Object.keys(genderCategoryBridal).length > 0 && (
                                                            Object.keys(genderCategoryBridal).map((item, index) => {
                                                                return (
                                                                    <Accordion.Item eventKey={index} className="busiess-details-accordion p-0">
                                                                        <Accordion.Header>
                                                                            <h5 className='fiter-header'>
                                                                                {item}
                                                                            </h5>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>

                                                                            {

                                                                                genderCategoryBridal[item].length > 0 && (
                                                                                    genderCategoryBridal[item].map(innerItem => {
                                                                                        return (
                                                                                            <div className="cur-pointer-list" onClick={() => genderCategoryFilterBridal(item, innerItem)}>
                                                                                                <a>{innerItem}</a>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                )
                                                                            }

                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                );
                                                            })
                                                        )
                                                    }
                                                </Accordion>
                                            </div>
                                            <div id="mobile-view" class="col-md-4 mobile-padding mt-1" style={{ paddingLeft: "0px" }}>
                                                <Card className='px-1 py-0 filter-card-mobile'>
                                                    <Row className='d-flex mt-2'>
                                                        <Col className='d-flex' onClick={() => setFilterView(true)}>
                                                            <span className='fs-5 mx-2 mt-3 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className='mt-3'>Filter</h5>
                                                        </Col>
                                                        <Col>
                                                            <h5 className='text-primary cur-pointer mt-3 float-end mx-3' onClick={resetFilterBridal}>
                                                                Reset
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Modal show={filterView} onHide={() => setFilterView(false)} className="m-0 p-0">
                                                    <Modal.Header className='pb-0' closeButton></Modal.Header>
                                                    <Modal.Body className='pt-0'>
                                                        <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                                            {
                                                                Object.keys(genderCategoryBridal).length > 0 && (
                                                                    Object.keys(genderCategoryBridal).map((item, index) => {
                                                                        return (
                                                                            <Accordion.Item eventKey={index} className="busiess-details-accordion p-0">
                                                                                <Accordion.Header>
                                                                                    <h5 className='fiter-header'>
                                                                                        {item}
                                                                                    </h5>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>

                                                                                    {

                                                                                        genderCategoryBridal[item].length > 0 && (
                                                                                            genderCategoryBridal[item].map(innerItem => {
                                                                                                return (
                                                                                                    <div className="cur-pointer-list" onClick={() => genderCategoryFilterBridal(item, innerItem)}>
                                                                                                        <p onClick={() => setFilterView(false)}>{innerItem}</p>
                                                                                                    </div>
                                                                                                );
                                                                                            })
                                                                                        )
                                                                                    }

                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        );
                                                                    })
                                                                )
                                                            }
                                                        </Accordion>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                            <div class="col-md-8" style={{ paddingLeft: "0px" }}>

                                                <div class="search-sortby-outer">
                                                    <span className='d-flex'>
                                                        <span className="explore-search-icon fs-5 mt-2 mx-0">
                                                            <ion-icon name="search-outline" ></ion-icon>
                                                        </span>
                                                        <input type="text" class="form-control search-box" id="" placeholder="Services, Stylist or Salon...." name="search" onInput={onChangeSearchBridal} value={bridalSearch} />
                                                    </span>
                                                                                                   </div>
                                                <div class="service-list">
                                             
                                                    <ul>
                                                        {
                                                            bridalServices?.data && bridalServices?.data?.length > 0 && (
                                                                bridalServices.data.map((item, index) => {
                                                                    let gender = ""
                                                                    if (item?.gender && item?.gender?.length > 0) {
                                                                        gender = item?.gender?.map((i) => i.gender_name).join(" ")
                                                                    }
                                                                    if (item.is_active) {
                                                                        let isItemAdded = checkServiceInCart(item?._id)
                                                                        return (
                                                                            <li key={index}>
                                                                                <p className='service-gender-name'>{gender}</p>
                                                                                <h3 className='item-service-name'>{item?.service_name}</h3>
                                                                                <p class="item-service-description">{item?.service_description}</p>

                                                                                <p class="price-time d-flex">
                                                                                    <span className='fs-4 text-dark'><ion-icon name="wallet-outline"></ion-icon></span>
                                                                                    <span class="price-one">₹ {item?.service_price?.['$numberDecimal']}</span>
                                                                                    {
                                                                                        item?.service_discount?.['$numberDecimal'] !== '0' && (
                                                                                            <>
                                                                                                <span class="price-two"><s>₹ {item?.service_original_price?.['$numberDecimal']}</s></span><span class="discount-off">{item?.service_discount?.['$numberDecimal']}% Off</span>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </p>
                                                                                <p class="price-time estimate-time d-flex">
                                                                                    <span className='fs-4 text-dark'><ion-icon name="timer-outline"></ion-icon></span>
                                                                                    <span class="item-time">{item?.duration?.['$numberDecimal']} mins</span></p>
                                                                                {/* <span class="offer-right">{item?.service_discount?.['$numberDecimal']}% Off</span> */}
                                                                                {
                                                                                    isItemAdded ? (
                                                                                        <>
                                                                                            <button class="btn added-btn">Added</button>
                                                                                        </>

                                                                                    )
                                                                                        :
                                                                                        (

                                                                                            <button class="btn book-now" onClick={() => { addServiceToCart(item); ViewCartShow(); }}>Book Now</button>
                                                                                        )
                                                                                }
                                                                                {/* <button class="btn book-now" onClick={() => { addServiceToCart(item) }}>Book Now</button> */}
                                                                                {/* <br /> */}
                                                                                {/* <button class="btn added-btn">Added</button> */}
                                                                            </li>

                                                                        );
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    </ul>
                                                </div>

                                                {
                                                    bridalServices?.next && (
                                                        <div className="loadmore-outer cur-pointer">
                                                            <a onClick={handlePageChangeBridal}>Load more</a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab tabClassName="explore-single-tab" eventKey="reviews" title="Reviews">
                                    <div className='container'>
                                        <div className='row'>
                                            <ReviewPage
                                                id={id}
                                                serviceprovider={serviceprovider}
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab tabClassName="explore-single-tab" eventKey="gallery" title="Gallery">
                                    <div className='container'>
                                        <div className='row'>
                                            <GalleryPage id={id} />
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>

                    <Modal show={replace} onHide={handleClose}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body className='mb-5'>
                            <h5 class="text-center">Replace Cart</h5>
                            <p class="text-center mt-4">Are you sure you want to Replace cart?</p>
                            <Row className='mt-3'>
                                <Col className='text-center'>
                                    <Button className='replace-cancel-btn' onClick={handleClose}>
                                        Cancel
                                    </Button>

                                </Col>
                                <Col className='text-center'>
                                    <Button className='replace-btn' onClick={replaceCart}>
                                        Replace
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>    
                </section>
            </div>
            { }
            {isLoggedIn && role?.role_name === "user" && showCartIcon ?
                <div>
                    <div className='viewcard-tab'>
                        <Row className='p-2 view-cart-btn'>
                            <Col md={4} className="view-cart-cl">
                                <p className='viewcart-service mt-2'>{servicesCart.length} Services added</p>
                            </Col>
                            <Col md={3} className="view-cart-cl">
                                <p className='mt-2'>
                                    <span>
                                        ₹ {Math.round(totalAmount)}
                                    </span>
                                    <strike class="text-secondary mx-1">₹ {originalPrice}</strike>
                                    <span class="mx-2 view-save">You save ₹{Math.round(discount)}</span>


                                </p>
                            </Col>
                            <Col md={5}>
                                <Button onClick={ViewcartIcon} className='view-cart-tab-btn'>View Cart</Button>
                            </Col>
                        </Row>
                    </div>
                    {/* <Button onClick={ViewcartIcon} className="img-fluid view-cart-button"> View Cart <span><BsFillCartCheckFill className='icon-cart' /></span></Button> */}
                </div>
                : null
            }
            <Footer />
        </div>
        }
        </div>
        
    )
}

export default SingleBusinessPage