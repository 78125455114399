import React from 'react';
import ReferralCode from './ReferralCode';
import DashboardHeader from '../DashboardHeader';
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import {Row, Col} from 'react-bootstrap';

function MarketingPage() {
    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <Row>
                        <Col className="p-0" md={2}>
                        <div className="sidebar-content p-0">
                        <AdminSidebarPage />
                    </div>
                        </Col>
                        <Col md={10}>
                        <div className="main-content">
                        <ReferralCode />
                    </div>
                        </Col>
                    </Row>
                  
                    

                </div>
            </div>

        </div>
    )
}

export default MarketingPage