import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Tabs, Tab, Button, Form, Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import BusinessSidebar from '../../../components/elements/siderbar';
import DashboardHeader from '../DashboardHeader';
import { verifyOrderOtp, updateOrder, getOneOrder, getOneRequestAction } from "../../../redux/dashboard/actions";
import { useDispatch } from "react-redux";
import { useFormik, FormikProvider } from 'formik';
import moment from "moment";

const OrderDetails = () => {
    const navigate = useNavigate();
    
    const { id } = useParams("");
    const [status, setStatus] = useState("pending")
    const [orderOtp, setOrderOtp] = useState("")
    const [orderDetails, setOrderDetails] = useState();
    const [cancelReason, setCancelReason] = useState("")
    const [message, setMessage] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [disableButton , setDisableButton] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            id: id,
        }
        dispatch(getOneRequestAction(data))
            .then((res) => {
                console.log(res);
                setOrderDetails(res);
            })
    }, [id])

    const goBack = () => {
        navigate("/dashboard/orders")
    }
    const onChangeFilter = (e) => {
        console.log(" ==> ", e.target.value)
        setStatus(e.target.value)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            order_otp: ''
        },
        // validationSchema: loginValidationSchema,
        onSubmit: (values) => {

            if (values.order_otp !== "") {
                let data = {
                    orderid: id,
                    serviceprovider: orderDetails?.results?.service_provider?._id,
                    orderOTP: values.order_otp
                }
                dispatch(verifyOrderOtp(data))
                    .then((res) => {
                        formik.resetForm()
                        setUpdated(true);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                console.log("Order otp not entered")
            }
        },
    });
    const onChangeFilterCancel =(e) => {
        setCancelReason(e.target.value);
        setErrorMsg(false)

    }

    const handleMessageChange = (event) => {
      setMessage(event.target.value);
      setErrorMsg(false)
    };
        
    const updateOrderStatus = () => {
        let data = {
            id: id,
            serviceproviderID: orderDetails?.results?.service_provider?._id,
            order_status: status,
            order_cancellation_reason: cancelReason,
            order_cancellation_comments: message,

        }
       if(status === "cancelled"){

        if(cancelReason !== "" && message !== ""){
            setDisableButton(true)
            dispatch(updateOrder(data))
            .then((res) => {
              setDisableButton(false)
              navigate("/dashboard/orders")
            })
            .catch((error) => {
                setDisableButton(false)
              });
        } else{
            setErrorMsg(true)
        }
       } else {
        setDisableButton(true)
        dispatch(updateOrder(data))
        .then((res) => {
          setDisableButton(false)
          navigate("/dashboard/orders")
        })
        .catch((error) => {
            setDisableButton(false)
          });
       }
       
    }
console.log("Order===>",orderDetails)

    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <Row>
                        <Col className='p-0' md={2}>
                            <div className="sidebar-content">
                                <BusinessSidebar />
                            </div>

                        </Col>
                        <Col md={10}>
                            
                            <div className="container-xxl">
                            <div className='single-view-request'>
                                <Row className='px-1 mt-4 '>
                                    <Col md={11} sm={9} xs={9}>
                                        <h1>Order details</h1>
                                        {
                                                    status === "cancelled" && (
                                                    <p className='mt-3'>
                                                    Please note that you are required to call and inform the customer ahead of cancellation. You will not be allowed to change the status again after cancellation
            The customer will get a full refund on this order
                                                    </p> 
                                                    )}
                                                     {
                                                    status === "completed" && (
                                                    <p className='mt-3'>
                                                  Please enter the payment verification code to complete the order. The completed status cannot be reversed
                                                    </p> 
                                                    )}

                                        

                                   
                                    </Col>
                                    <Col md={1} sm={3} xs={3}>
                                        <div className='goback-to-request' onClick={goBack}>
                                            <span>                <ion-icon name="chevron-back-outline"></ion-icon>
                                            </span>
                                            <h6>back</h6>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={4}>
                                    <div>
                                            <Row className='mt-4'>
                                                <h1>Service Status</h1>
                                                {
                                                        orderDetails?.results?.order_status === "pending" && (
                                                            <>
                                                              <Col md={12}>
                                                   
                                                   <Row className='mt-4'>
                                                       <Col md={4} xs={4} sm={4}>
                                                           <div class="form-check">
                                                               <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="pending" onChange={onChangeFilter} checked={status === "pending"}
                                                               />
                                                               <label class="form-check-label" for="flexRadioDefault1">
                                                                   Pending  </label>
                                                           </div>
                                                       </Col>
                                                       <Col md={4} xs={4} sm={4}>
                                                           <div class="form-check">
                                                               <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="completed" onChange={onChangeFilter} checked={status === "completed"} />
                                                               <label class="form-check-label" for="flexRadioDefault2">
                                                                   Completed 
                                                               </label>
                                                           </div>
                                                       </Col>
                                                       {
                                                        orderDetails?.results?.service_type !== "bridal" && (
                                                            <Col md={4} xs={4} sm={4}>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value="cancelled" onChange={onChangeFilter} checked={status === "cancelled"} />
                                                                <label class="form-check-label" for="flexRadioDefault3">
                                                                Cancelled  
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        )
                                                       }
                                                    
                                                       {/* <Col md={3} xs={3} sm={3}>
                                                           <div class="form-check">
                                                               <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" value="noshow" onChange={onChangeFilter} checked={status === "noshow"} />
                                                               <label class="form-check-label" for="flexRadioDefault4">
                                                                   No-show  </label>
                                                           </div>
                                                       </Col> */}
                                                   </Row>
                                               </Col>
                                                            </>
                                                        )
                                                    }
                                              {/* {
                                                orderDetails?.results?.order_status === "pending" && (
                                                    <p className='mt-3'>
                                                    Please note that you are required to call and inform the customer ahead of cancellation. You will not be allowed to change the status again after cancellation
            The customer will get a full refund on this order
                                                    </p>                                                )
                                              } */}
                                                {
                                        orderDetails?.results?.order_status === "cancelled" && (
                                            <div className="single-view-request">
                                                <h2>Cancelled</h2>
                                                <div className="mt-3">
                                                <h6>Reason for cancellation</h6>
                                                <p>{orderDetails?.results?.order_cancellation_reason}</p>
                                                <h6>Comments on cancellation</h6>
                                                <p>{orderDetails?.results?.order_cancellation_comments}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        orderDetails?.results?.order_status === "completed" && (
                                            <div className="single-view-request">
                                                <h2>Completed</h2>
                                            </div>
                                        )
                                    }

                                            </Row>
                                            <Row className="comments-container-order">
                                                {
                                                    status === "completed" && (
                                                        <Row className="mt-5">

                                                            <Form className="" onSubmit={formik.handleSubmit}>
                                                                <FormikProvider value={formik}>
                                                                    <Row>
                                                                        <Col md={8}>
                                                                            <Form.Group className="order-view-box" controlId="verifcationCode">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Payment Verfication code"
                                                                                    name="order_otp"
                                                                                    onChange={formik.handleChange}
                                                                                    value={formik.values.order_otp}
                                                                                    isInvalid={!!formik.touched.order_otp && !!formik.errors.order_otp}

                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            {
                                                                                updated ? (
                                                                                    <Button className="order-update-btn" type="submit">Updated</Button>

                                                                                ): (
                                                                                    <Button className="order-update-btn" type="submit">Update</Button>

                                                                                )
                                                                            }

                                                                        </Col>
                                                                        {/* <Col md={4} className="text-end">
                                                                            <div className="mb-2">
                               
        
                                <Form.Group className="order-view-status" controlId="order-status">
                                  <Form.Select
                                    name="status"
                                    onChange={onChangeFilter}
                                    value={status}
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                    <option value="cancelled">Cancelled</option>
                                  </Form.Select>
        
                                </Form.Group>
        
                              </div>
                                                                        </Col> */}
                                                                    </Row>
                                                                </FormikProvider>
                                                            </Form>
                                                        </Row>
                                                    )
                                                }

                                                {
                                                    status === "cancelled" && (
                                                        <Row className="noshow-container mt-4">
                                                            <Col md={12}>
                                                                <div class="mb-3">
                                                                    <div class="label-text">
                                                                        <label for="exampleFormControlInput1" class="form-label">Reason for cancellation *</label>
                                                                    </div>
                                                                    <div class="select-content">
                                                                        <select class="form-select common-text" aria-label="Default select example"
                                                                         onChange={onChangeFilterCancel}
                                                                         value={cancelReason}
                                                                         >
                                                                            <option value="" selected>Select Reason for cancellation</option>
                                                                            <option value="Service no longer available ">Service no longer available </option>
                                                                            <option value="Service provider not available">Service provider not available</option>
                                                                            <option value="Salon is closed">Salon is closed</option>
                                                                            <option value="Customer asked to cancel">Customer asked to cancel</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    errorMsg ? (
                                                                        <p className="text-danger"> Please select</p>

                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                <div class="mb-3">
                                                                    <div class="label-text">
                                                                        <label for="exampleFormControlTextarea1" class="form-label">Comment</label>
                                                                    </div>
                                                                    <div class="select-content">
                                                                        <textarea class="form-control common-text" id="exampleFormControlTextarea1" rows="5" placeholder="Comment"
                                                                       value={message}
                                                                       onChange={handleMessageChange}
                                                                       ></textarea>
                                                                    </div>
                                                                    {
                                                                    errorMsg ? (
                                                                        <p className="text-danger"> Please enter your comment</p>

                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }

                                                {/* {
                                                    status === "noshow" && (
                                                        <Row className="noshow-container">
                                                            <Col md={6}>
                                                                <div class="mb-3">
                                                                    <div class="label-text">
                                                                        <label for="exampleFormControlInput1" class="form-label">Reason for cancellation *</label>
                                                                    </div>
                                                                    <div class="">
                                                                        <select class="form-select" aria-label="Default select example">
                                                                            <option selected>Select reason for no-show</option>
                                                                            <option value="1">Reason for cancellation 1</option>
                                                                            <option value="2">Reason for cancellation 2</option>
                                                                            <option value="3">Reason for cancellation 3</option>
                                                                            <option value="3">Reason for cancellation 4</option>
                                                                            <option value="3">Reason for cancellation 5</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <div class="label-text">
                                                                        <label for="exampleFormControlTextarea1" class="form-label">Comment</label>
                                                                    </div>
                                                                    <div class="select-content">
                                                                        <textarea class="form-control common-text" id="exampleFormControlTextarea1" rows="5" placeholder="Comment"></textarea>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                } */}
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    {
                                                        orderDetails?.results?.order_status === "pending" && (
                                                            <Row className='order-save-btn-container'>
                                                            <Col className=''>
                                                                <Button className='order-cancel-btn' onClick={goBack}>Cancel</Button>
                                                            </Col>
                                                            <Col>
                                                                <Button className='order-save-btn' disabled={disableButton} onClick={updateOrderStatus}>Save</Button>
                                                            </Col>
                                                        </Row>
                                                        )
                                                    }
                                                   
                                                </Col>
                                            </Row>
                                            </div>
                                          
                                    </Col>
                                    <Col md={8}>
                                    <div className='single-view-request'>
                                                <Row className='px-1 mt-4'>
                                                    <Col md={12} sm={12} xs={12}>
                                                        <h2>{orderDetails?.results?.service_provider?.business_name}</h2>
                                                        <p className='mt-2'>{orderDetails?.results?.service_provider?.location_details?.address + "" + orderDetails?.results?.service_provider?.location_details?.area + ", " + orderDetails?.results?.service_provider?.location_details?.city + ", " + orderDetails?.results?.service_provider?.location_details?.state + " " + orderDetails?.results?.service_provider?.location_details?.zipcode}</p>

                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mt-3">
                                                    <Col md={12}>
                                                        {
                                                            orderDetails?.results?.service_provider?.vendor_type == "freelancer" ? (
                                                                <Table borderless >

                                                                <tbody >
                                                                    <tr>
                                                                        <Row >
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Order Id:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                                <td>{orderDetails?.results?.order_id}</td>
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td>Appointment Date / Time:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{moment(orderDetails?.results?.appointment?.appointment_start_dateTime).format("DD MMM YYYY hh:mm a") 
                                                                            // + ", " + moment(orderDetails?.results?.created_at).format("DD MMM YYYY h:mm a")
                                                                            }</td>
    
    
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Service Type</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.service_type.charAt(0).toUpperCase() + orderDetails?.results?.service_type.slice(1)}</td>
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Contact Details:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.customer_details?.username}</td>
                                                                            <h6 className='request-view-email'>{orderDetails?.results?.customer_details?.email_id}</h6>
    
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    {
                                                                        orderDetails?.results?.customer_location_details?.type !== "shop" && (
                                                                            <tr>
                                                                            <Row>
                                                                                <Col md={3} sm={5} xs={5}>
                                                                                    <td className='request-info-headers'>Venu Address:</td>
        
                                                                                </Col>
                                                                                <Col md={9} sm={7} xs={7}>
                                                                                    {
                                                                                        orderDetails?.results?.customer_venue_details?.map((item)=>{
                                                                                            return(
                                                                                                <td>{item?.address + ", " + item?.city + ", " + item?.state + ", " + item?.country + ", " + item?.zipcode}</td>

                                                                                            )
                                                                                        })
                                                                                    }    
                                                                                </Col>
                                                                            </Row>
                                                                        </tr>
                                                                        )
                                                                    }

                                                                    {
                                                                        orderDetails?.results?.service_type !== "bridal" ? (
                                                                            <tr>
                                                                            <Row>
                                                                                <Col md={3} sm={5} xs={5}>
                                                                                    <td className='request-info-headers'>Message:</td>
        
                                                                                </Col>
                                                                                <Col md={9} sm={7} xs={7}>
                                                                                                            {orderDetails?.results?.customer_location_details?.message == "" ? (
                                                                            <td>No Messages to read</td>
        
                                                                        ): (
                                                                            <td>{orderDetails?.results?.customer_location_details?.message }</td>
        
                                                                        )
                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </tr>
                                                                        ):(
                                                                            <tr>
                                                                            <Row>
                                                                                <Col md={3} sm={5} xs={5}>
                                                                                    <td className='request-info-headers'>Message:</td>
        
                                                                                </Col>
                                                                                <Col md={9} sm={7} xs={7}>
                                                                                                            {orderDetails?.results?.customer_venue_details[0].message === "" ? (
                                                                            <td>No Messages to read</td>
        
                                                                        ): (
                                                                            <td>{orderDetails?.results?.customer_venue_details[0].message }</td>
        
                                                                        )
                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </tr>
                                                                        )
                                                                    }
                                                                   
                                                                  
                                                                </tbody>
                                                            </Table>
                                                            ) : (
                                                                <>
                                                                {
                                                                    orderDetails?.results?.service_type === "basic" ? (
                                                                        <Table borderless >

                                                                        <tbody >
                                                                            <tr>
                                                                                <Row >
                                                                                    <Col md={3} sm={5} xs={5}>
                                                                                        <td className='request-info-headers'>Order Id:</td>
            
                                                                                    </Col>
                                                                                    <Col md={9} sm={7} xs={7}>
                                                                                        <td>{orderDetails?.results?.order_id}</td>
                                                                                    </Col>
                                                                                </Row>
                                                                            </tr>
                                                                            <tr>
                                                                                <Row>
                                                                                    <Col md={3} sm={5} xs={5}>
                                                                                        <td>Appointment Date / Time:</td>
            
                                                                                    </Col>
                                                                                    <Col md={9} sm={7} xs={7}>
                                                                                    <td>{moment(orderDetails?.results?.appointment?.appointment_start_dateTime).format("DD MMM YYYY hh:mm a") 
                                                                                    // + ", " + orderDetails?.results?.appointment?.selected_time
                                                                                    }</td>
            
            
                                                                                    </Col>
                                                                                </Row>
                                                                            </tr>
                                                                            <tr>
                                                                                <Row>
                                                                                    <Col md={3} sm={5} xs={5}>
                                                                                        <td className='request-info-headers'>Service Type</td>
            
                                                                                    </Col>
                                                                                    <Col md={9} sm={7} xs={7}>
                                                                                    <td>{orderDetails?.results?.service_type.charAt(0).toUpperCase() + orderDetails?.results?.service_type.slice(1)}</td>
                                                                                    </Col>
                                                                                </Row>
                                                                            </tr>
                                                                            <tr>
                                                                                <Row>
                                                                                    <Col md={3} sm={5} xs={5}>
                                                                                        <td className='request-info-headers'>Contact Details:</td>
            
                                                                                    </Col>
                                                                                    <Col md={9} sm={7} xs={7}>
                                                                                    <td>{orderDetails?.results?.customer_location_details?.alternate_phone}</td>
                                                                                    <h6 className='request-view-email'>{orderDetails?.results?.customer_location_details?.alternate_emailID}</h6>
            
                                                                                    </Col>
                                                                                </Row>
                                                                            </tr>
                                                                            {
                                                                                orderDetails?.results?.customer_location_details?.type !== "shop" && (
                                                                                    <tr>
                                                                                    <Row>
                                                                                        <Col md={3} sm={5} xs={5}>
                                                                                            <td className='request-info-headers'>Venu Address:</td>
                
                                                                                        </Col>
                                                                                        <Col md={9} sm={7} xs={7}>
                                                                                        <td>{orderDetails?.results?.customer_location_detailsaddress?.area + ", " + orderDetails?.results?.customer_location_detailsaddress?.city + ", " + orderDetails?.results?.customer_location_detailsaddress?.state + ", " + orderDetails?.results?.customer_location_detailsaddress?.country + ", " + orderDetails?.results?.customer_location_detailsaddress?.zipcode}</td>
            
                                                                                        </Col>
                                                                                    </Row>
                                                                                </tr>
                                                                                )
                                                                            }
                                                                           
                                                                            <tr>
                                                                                <Row>
                                                                                    <Col md={3} sm={5} xs={5}>
                                                                                        <td className='request-info-headers'>Message:</td>
            
                                                                                    </Col>
                                                                                    <Col md={9} sm={7} xs={7}>
                                                                                                                {orderDetails?.results?.customer_location_details?.message == "" ? (
                                                                                <td>No Messages to read</td>
            
                                                                            ): (
                                                                                <td>{orderDetails?.results?.customer_location_details?.message }</td>
            
                                                                            )
                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </tr>
                                                                        </tbody>
                                                                       </Table>
                                                                    ):(
                                                                        <Table borderless >

                                                                <tbody >
                                                                    <tr>
                                                                        <Row >
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Order Id:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                                <td>{orderDetails?.results?.order_id}</td>
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td>Appointment Date / Time:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{moment(orderDetails?.results?.appointment?.appointment_start_dateTime).format("DD MMM YYYY hh:mm a") 
                                                                            // + ", " + moment(orderDetails?.results?.created_at).format("DD MMM YYYY h:mm a")
                                                                            }</td>
    
    
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Service Type</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                                <td>{orderDetails?.results?.service_type.charAt(0).toUpperCase() + orderDetails?.results?.service_type.slice(1)}</td>
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Contact Details:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.customer_details?.username}</td>
                                                                            <h6 className='request-view-email'>{orderDetails?.results?.customer_details?.email_id}</h6>
    
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    {
                                                                        orderDetails?.results?.customer_location_details?.type !== "shop" && (
                                                                            <tr>
                                                                            <Row>
                                                                                <Col md={3} sm={5} xs={5}>
                                                                                    <td className='request-info-headers'>Venu Address:</td>
        
                                                                                </Col>
                                                                                <Col md={9} sm={7} xs={7}>
                                                                                    {
                                                                                        orderDetails?.results?.customer_venue_details?.map((item)=>{
                                                                                            return(
                                                                                                <td>{item?.address + ", " + item?.city + ", " + item?.state + ", " + item?.country + ", " + item?.zipcode}</td>

                                                                                            )
                                                                                        })
                                                                                    }    
                                                                                </Col>
                                                                            </Row>
                                                                        </tr>
                                                                        )
                                                                    }
                                                                   
                                                                    <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Message:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                                                        {orderDetails?.results?.customer_location_details?.message == "" ? (
                                                                        <td>No Messages to read</td>
    
                                                                    ): (
                                                                        <td>{orderDetails?.results?.customer_location_details?.message }</td>
    
                                                                    )
                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                </tbody>
                                                            </Table> 
                                                                    )
                                                                }
                                                                </>
                                                              
                                                            )
                                                        }
                                                        {/* <Table borderless >

                                                            <tbody >
                                                                <tr>
                                                                    <Row >
                                                                        <Col md={3} sm={5} xs={5}>
                                                                            <td className='request-info-headers'>Order Id:</td>

                                                                        </Col>
                                                                        <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.order_id}</td>
                                                                        </Col>
                                                                    </Row>
                                                                </tr>
                                                                <tr>
                                                                    <Row>
                                                                        <Col md={3} sm={5} xs={5}>
                                                                            <td>Appointment Date / Time:</td>

                                                                        </Col>
                                                                        <Col md={9} sm={7} xs={7}>
                                                                        <td>{moment(orderDetails?.results?.appointment?.appointment_start_dateTime).format("DD MMM YYYY") 
                                                                        // + ", " + orderDetails?.results?.selected_time
                                                                        }</td>


                                                                        </Col>
                                                                    </Row>
                                                                </tr>
                                                                <tr>
                                                                    <Row>
                                                                        <Col md={3} sm={5} xs={5}>
                                                                            <td className='request-info-headers'>Service Type</td>

                                                                        </Col>
                                                                        <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.customer_location_details?.type}</td>
                                                                        </Col>
                                                                    </Row>
                                                                </tr>
                                                                <tr>
                                                                    <Row>
                                                                        <Col md={3} sm={5} xs={5}>
                                                                            <td className='request-info-headers'>Contact Details:</td>

                                                                        </Col>
                                                                        <Col md={9} sm={7} xs={7}>
                                                                        <td>{orderDetails?.results?.customer_location_details?.alternate_phone}</td>
                                                                        <h6 className='request-view-email'>{orderDetails?.results?.customer_location_details?.alternate_emailID}</h6>

                                                                        </Col>
                                                                    </Row>
                                                                </tr>
                                                                {
                                                                    orderDetails?.results?.customer_location_details?.type !== "shop" && (
                                                                        <tr>
                                                                        <Row>
                                                                            <Col md={3} sm={5} xs={5}>
                                                                                <td className='request-info-headers'>Venu Address:</td>
    
                                                                            </Col>
                                                                            <Col md={9} sm={7} xs={7}>
                                                                            <td>{orderDetails?.results?.customer_location_detailsaddress?.area + ", " + orderDetails?.results?.customer_location_detailsaddress?.city + ", " + orderDetails?.results?.customer_location_detailsaddress?.state + ", " + orderDetails?.results?.customer_location_detailsaddress?.country + ", " + orderDetails?.results?.customer_location_detailsaddress?.zipcode}</td>

                                                                            </Col>
                                                                        </Row>
                                                                    </tr>
                                                                    )
                                                                }
                                                               
                                                                <tr>
                                                                    <Row>
                                                                        <Col md={3} sm={5} xs={5}>
                                                                            <td className='request-info-headers'>Message:</td>

                                                                        </Col>
                                                                        <Col md={9} sm={7} xs={7}>
                                                                                                    {orderDetails?.results?.customer_location_details?.message == "" ? (
                                                                    <td>No Messages to read</td>

                                                                ): (
                                                                    <td>{orderDetails?.results?.customer_location_details?.message }</td>

                                                                )
                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                    </Col>
                                                </Row>

                                                <hr />

                                                <Row className="mt-3 mb-5">
                                                    <h3>Service details</h3>
                                                    <Row className='px-4'>
                                                        <Table borderless hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Service Title</th>
                                                                    <th className='text-center'>Service Category</th>
                                                                    <th className='text-center'>Service Price</th>
                                                                    <th className='text-center'>Customer Category</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>


                                                                {
                                                                    orderDetails?.results?.services && orderDetails?.results?.services.length > 0 && (
                                                                        orderDetails?.results?.services?.map((item, index) => {

                                                                            return (
                                                                                <tr key={index}>

                                                                                    <td className="">{item?.service_name}</td>
                                                                                    <td>{item?.service_category?.[0]?.servicecategory_name}</td>
                                                                                    <td>{item?.service_price?.["$numberDecimal"] + " ₹ "}</td>
                                                                                    <td>{item?.gender?.[0]?.gender_name}</td>
                                                                                </tr>

                                                                            );
                                                                        })
                                                                    )
                                                                } 
                                                            </tbody>
                                                        </Table>
                                                    </Row>
                                                </Row>

                                            </div>

                                    </Col>
                                </Row>
                                {/* <Row className='px-3 mt-4'>
                                    <Tabs
                                        defaultActiveKey="home"
                                        transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="Service Status">
                                          

                                        </Tab>
                                        <Tab eventKey="profile" title="Order details">
                                        
                                        </Tab>
                                    </Tabs>
                                </Row> */}
                            </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;
