import React from 'react'
import Header from '../../components/elements/header/Header';
import "../../assets/css/policy.css"
import Footer from '../../components/elements/footer/Footer';

function Privacy() {
    return (
        <div>
            <Header />
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 className="policy-header">Privacy Policy</h1>
                            <p class="terms-paragraph">The privacy policy for Slaylewks ( “we”, “us”, or “our”) describes how and why we might collect, store, use and/or share your information when you use our services.</p>

                            <h2 class="terms-paragraph">1.  Collection of Personal Data</h2>
                            <p class="terms-paragraph">Personal “Data” is defined as information that can be used to identify an individual either on its own or in combination with other information. This includes but not limited to information such as name, age, address, contact details, email id etc. </p>
                            <ul>
                                <li>We may collect the following personal data from you:</li>
                                <li>Identity data, such as your name, nationality, gender, date of birth, biometric data, image;</li>
                                <li>Contact data, such as billing address, home address, email address, and phone number(s);</li>
                                <li>Account data, such as bank and credit/debit card details, e-wallets, payment details, financial information.</li>
                                <li>Transaction data, such as payment manners, details about payments to and from you, and other details of goods, products and or services you have purchased from or through us;</li>
                                <li>Technical data, such as internet protocol (IP) address, your login data, browser type and version, time zone setting and - location, browser plug-in types and versions, operating system and platform, mobile carrier, URLs, and other technology(ies) on the devices you use to access the Platform and/ or Service;</li>
                                <li>Profile data, such as your username and password, purchases or orders made by you, your interests, preferences, feedback and - survey responses;</li>
                                <li>Location data, such as GPS coordinates or similar geographical information of your computer or mobile device;</li>
                                <li>Location data, such as GPS coordinates or similar geographical information of your computer or mobile device;</li>
                                <li>Usage data, such as information on how you use the Platform and/or the Services; and/or</li>
                                <li>Marketing and communications data, such as your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                            </ul>
                            <p class="terms-paragraph">The types of personal data collected depend on the purpose(s) of collection. We may process your personal data by way of collecting, recording, altering, holding, storing, transferring, erasing, correcting, retrieving, destructing, aligning, combining, disclosing and/or deleting it.</p>
                            <h2 class="terms-paragraph">2.  How the collected information is used </h2>
                            <p class="terms-paragraph">We may share certain information with governmental agencies or other third parties, where we are:</p>
                            <ul>
                                <li>Obligated under the applicable laws or in good faith to respond to court orders and processes; or</li>
                                <li>Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of Services and other illegal acts;</li>
                                <li>Responding to claims that an advertisement, posting or other content violates the intellectual property rights of a third party;</li>
                                <li>Under a duty to disclose or share your personal data in order to enforce our Terms of Use and other agreements, policies or to protect the rights, property, or safety of the Company, our customers, or others, or in the event of a claim or dispute relating to your use of our Services. This includes exchanging information with other companies and organisations for the purposes of fraud detection and credit risk reduction.</li>
                            </ul>
                            <p class="terms-paragraph">Please note that the Company will not ask you to share any sensitive data or information via email or telephone. If you receive any such request by email or telephone, please do not respond/divulge any sensitive data or information and forward the information relating to the same to info@slaylewks.com for necessary action.</p>
                            <h2 class="terms-paragraph">3.  Disclosure of information to business partners and service providers</h2>
                            <p class="terms-paragraph">We may share information to our business partners and service providers who are promotion partners. Your information will be used by us as described in this Policy or by promotion partners as described in their policies. We may also share information with companies who serve as our vendors or third parties who perform services on our behalf.  They may help us deliver products or services to you. We may share information with companies who operate our sites and apps or who run our promotions.</p>
                            <h2 class="terms-paragraph">4.  Your choices and rights</h2>
                            <p class="terms-paragraph">You have certain rights and choices about how we use your information.</p>
                            <ul>
                                <li>You can opt out of email marketing</li>
                                <li>To stop receiving promotional emails from us, follow the instructions in any promotional email message you get from us. </li>
                                <li>If you opt out of getting marketing email messages, you may continue to receive non-marketing email messages from us where permitted by law.</li>
                                <li>To learn how to manage how we and our vendors use cookies and other tracking tools, and to read our About Our Ads policy, please click here. </li>
                                <li>Some web browsers transmit "do-not-track" signals to websites. Because the standards for these signals are still under development, we currently do not act in response to these signals.</li>
                                <li>You can control tools on your mobile devices. </li>
                                <li>For example, you can turn off the GPS locator or push notifications on your phone. To modify the push notifications, you get from our app, you can alter your preferences in your profile.</li>
                                <li>You may have other choices under state laws.</li>
                                <li>Depending on where you live, you may have certain additional rights, such as the right to request access to your personal information or to request deletion of your personal information.</li>
                            </ul>

                            <h2 class="terms-paragraph">5.  Retention of your Data</h2>
                            <p class="terms-paragraph">Our Company retains your Personal Data for as long as the retention is required for the purposes for which we collected the Personal Data, the purposes described in this Policy and for our business and legal purposes. </p>
                            <p class="terms-paragraph">Generally, we do not retain Personal Data for a period of longer than <span>2 years</span> after the original purposes for which the Personal Data was collected have ceased to be applicable, unless otherwise required by law, or other mandatory directions by court or government authorities or for purposes of legal proceedings or other similar proceedings or investigations.</p>
                            <h2 class="terms-paragraph">6.  Security of your Data</h2>
                            <p class="terms-paragraph">We store and process your Personal Data only in India. Our data center and systems also maintain backup and disaster recovery systems in India. We will ensure that the personal information that we hold is subject to appropriate security measures.</p>
                            <h2 class="terms-paragraph">7.  Privacy Policy Updates</h2>
                            <p class="terms-paragraph">Users are asked to inform themselves regularly about the content of the Privacy Policy. You will find the latest version of the Privacy Policy at <a href="JavaScript:void(0);">www.slaylewks.com/en/</a> privacy-policy</p>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Privacy