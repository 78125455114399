import * as Yup from 'yup';

export const couponBusinessEditValidation = Yup.object().shape({
    coupon_type: Yup.string(),
    usage_limit_type:Yup.string(),
    per_user_limit_type: Yup.string(),
    time_limit_type: Yup.string(),

    coupon_name: Yup.string('Enter your Coupon name')
    .required('Coupon name is required')
    .min(4, 'Coupon name should be of minimum 4 characters length')
    .max(25, 'Coupon name should be of maximum 25 characters length'),

    // serviceprovider: Yup.string('Enter the service provider name').required("service provider is required")
    // .min(10, 'Name should be minimum 4 characters').max(35, 'Name should be maximum 35 characters'),

    custom_code: Yup.string().when(['coupon_type'], {
        is: "Custom code",
        then: Yup.string("Enter your Coupon Code").required('Coupon name is Required').min(4, 'Code should be of minimum 4 characters length')
        .max(25, 'code should be of maximum 25 characters length'),
        otherwise: Yup.string(),
    }),

    servicecategory: Yup.string()
    .required('service category'),

    usage_limit: Yup.string().when(['usage_limit_type'], {
        is: "limited",
        then: Yup.string("Please enter in numbers").required('Usage limit is required').matches(/^\d+$/,"Please enter limit only in numbers"). max(4, 'must be below 10000'),
        otherwise: Yup.string(),
    }),

    per_user_limit:Yup.string().when(['per_user_limit_type'], {
        is: "multiple time",
        then:  Yup.string("Please enter in numbers").required('Usercoupon limit is required').matches(/^\d+$/,"Please enter the coupon limit only in numbers"). max(4, 'must be below 10000'),
        otherwise: Yup.string(),
    }),

    description: Yup.string(''),


    discount_offer:  Yup.array().min(1, "Please enter a valid amount"),
  
    fromTime: Yup.date().when(['time_limit_type'], {
        is: "limited duration",
        then:Yup.date().nullable().required('Start Date is required'),
        otherwise: Yup.date().notRequired(),
    }),
    
    toTime: Yup.date().when(['time_limit_type'], {
        is: "limited duration",
        then:Yup.date().nullable().required('End Date is required').min(Yup.ref('fromTime'), "End date can't be before start date"),
        otherwise: Yup.date().notRequired(),
    }),

  
    
});
