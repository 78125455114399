import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col
} from "react-bootstrap";
import { useFormik, FormikProvider } from 'formik';
import { createResources } from "../../../redux/dashboard/actions";
import { staffAddValidation } from "../../../validations/yub_schemas/staffAddValidation";
import moment from "moment";

function StaffAddDaily(props) {

    const { selectInfo } = props;

    const formik = useFormik({
        initialValues: {
            title: props.staffCount.count || 1,
        },
        validationSchema: staffAddValidation,
        onSubmit: (values) => {
            // let title = prompt('Please enter the staff count')
            let calendarApi = props.selectInfo.view.calendar
            // calendarApi.unselect() // clear date selection;
            // props.setStaffCount(values.title)

            if (values.title) {
                // calendarApi.addEvent({
                //     title: values.title + " staffs",
                //     start: props.selectInfo.startStr,
                //     end: props.selectInfo.endStr
                // })
                props.setStaffCount({
                    count: values.title,
                    start: props.selectInfo.startStr,
                    end: props.selectInfo.endStr
                })
            }

            formik.resetForm();

            props.onHide();
        },
    });

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-40w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="Marketing-body-content">
                    <Form className="register-form" onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <Form.Group className="mb-3 p-2" controlId="formBasicEmail">
                                <Form.Label className="">
                                   <h6> Please enter the staff count &nbsp;
                                    {
                                        selectInfo?.startStr
                                    } *</h6>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Staff count"
                                    name="title"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                    isInvalid={!!formik.touched.title && !!formik.errors.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center mt-4 mb-5">
                                <Button variant="" onClick={() => props.onHide()} size="lg" className="me-3 btn add-service-btn">
                                    cancel
                                </Button>
                                <Button variant="" className="btn add-service-btn" type="submit" size="lg">
                                    submit
                                </Button>
                            </div>
                        </FormikProvider>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default StaffAddDaily