import React from 'react'
import { useState, useEffect} from 'react'
import Header from '../../components/elements/header/Header';
import Footer from '../../components/elements/footer/Footer'
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { useFormik, FormikProvider} from "formik";
import { HiOutlineMail } from "react-icons/hi";
import Contactimg from "../../assets/images/home/Contact-us.webp"
import { useDispatch, useSelector  } from 'react-redux';
import { supportValidation } from '../../validations/yub_schemas/supportValidation';
import {supportMessage, supportOrdersAction } from '../../redux/auth/actions';
import { useNavigate } from 'react-router-dom';

function Support() {
  
  const { user } = useSelector((state) => state.auth)
 console.log(user, "test")
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderId, setOrderID] = useState({});
   
  useEffect(()=>{
    const data ={
      id: user?._id
    }
    dispatch(supportOrdersAction(data)).then(
      (res) => {
        console.log(res, "test")
        setOrderID(res)
      }
    )
  },[])
  
console.log(orderId, "test2")

  const formik = useFormik({
      enableReinitialize: true,
        initialValues:{
            username: user?.username,
            phone: user?.mobile_no,
            email: user?.email_id,
            support_type: "service",
            order_id: "",
            message: '',
                
        },
        validationSchema: supportValidation,
        onSubmit: (values) => {
          const data = {
            id: user?._id,
            username: values?.username,
            phone: values?.phone,
            email_id: values?.email,
            support_type: values?.support_type,
            order_id: values?.order_id,
            message: values.message
          };
          console.log(data);
          dispatch(supportMessage(data)).
          then((res) => {
            formik.resetForm();  
            // Navigate("/")

          })
          .catch((error) => {
            console.log(error);
            
        });
      },
  });
   console.log(formik.errors)
   console.log(formik.values)

  return (
    <div>
      <Container className=''>
        <Row>
          <div className='support-profile-page'>
          <h1>Help / Support center</h1>
            <h2>Get in touch with us</h2>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
               Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p> */}
               <h2>Drop a message</h2>
          </div>
        </Row>
        <Row>
            <Col lg={8} md={8} sm={12}>
            <div className='support-form'>
                  <Form onSubmit={formik.handleSubmit}>
                  <FormikProvider value={formik}>
                  <Form.Group className="mb-3 p-2" controlId="businessType">
                    <Row>
                      <Col md={3}>
                      <Form.Check
                className="mb-2"
                type='radio'
                name="support_type"
                label="Service"
                value="service"
                checked={formik.values.support_type === "service"}
                onChange={() => formik.setFieldValue("support_type", "service")}
                isInvalid={!!formik.touched.business_type && !!formik.errors.business_type}
            />
                      </Col>
                      <Col md={9}>
                      <Form.Check
                type='radio'
                name="support_type"
                label="General"
                value="general"
                checked={formik.values.support_type === "general"}
                onChange={() => formik.setFieldValue("support_type", "general")}
                isInvalid={!!formik.touched.support_type && !!formik.errors.support_type}
            />
                      </Col>
                    </Row>
            
          
</Form.Group>

{
  formik.values.support_type === "service" ? (
    <Form.Group className="mb-3 p-2" controlId="formBasicyourName">
    <Form.Select
        name="order_id"
        placeholder="Select Order Id"
        onChange={formik.handleChange}
        value={formik.values.order_id}
        isInvalid={!!formik.touched.order_id && !!formik.errors.order_id}
    >
        <option>Select Order Id</option>

       {
        orderId?.length > 0 ? (
          <>
           {
          orderId?.map((item)=>{
            
            return <option value={item?.order_id}>{item?.order_id}</option>
          })
        }
          </>
        ) : (
          <option>No orders available</option>
        )
       }
      

    </Form.Select>
    <Form.Control.Feedback type="invalid">
        {formik.errors.order_id}
    </Form.Control.Feedback>
</Form.Group>
  ) : (
    <Form.Group className="mb-3 p-2" controlId="formBasicyourName">
    <Form.Select
       disabled
        name="select_order_id_empty"
        placeholder="Select Order Id"
    >
        <option value="">Select Order Id</option>


    </Form.Select>
  
</Form.Group>
  )
}
                    <Form.Group className="mb-3 p-2" controlId="formBasicyourName">
                        
                        <Form.Control
                            disabled
                            type="text"
                            placeholder="Name"
                            name="username"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            isInvalid={!!formik.touched.username && !!formik.errors.username}
                            
                        />  
                        <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                        {/* {formik.errors.yourName ? <div className="m-2 text-danger">{formik.errors.yourName}</div> : null} */}
                        
                    </Form.Group> 
                    <Form.Group className="mb-3 p-2" controlId="formBasicyourName">
                        
                        <Form.Control
                            disabled
                            type="text"
                            placeholder="Phone"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            isInvalid={!!formik.touched.phone && !!formik.errors.phone}
                            
                        />  
                        <Form.Control.Feedback type="invalid">
                                    {formik.errors.phone}
                                </Form.Control.Feedback>
                        {/* {formik.errors.yourName ? <div className="m-2 text-danger">{formik.errors.yourName}</div> : null} */}
                        
                    </Form.Group> 
                    <Form.Group className="mb-3 p-2" controlId="formBasicEmail">
                        
                        <Form.Control
                            disabled
                            type="email"
                            placeholder="Phone / Email id"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            isInvalid={!!formik.touched.email && !!formik.errors.email}
                        />
                      {/* {formik.errors.email ? <div className="m-2 text-danger">{formik.errors.email}</div> : null} */}
                      <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                        
                    </Form.Group>
                    <Form.Group className="mb-3 p-2" controlId="formBasicReference">
                       
                        <Form.Control
                        
                            as="textarea"
                            placeholder="Message"
                            name="message"
                            style={{ height: '100px' }}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            isInvalid={!!formik.touched.message && !!formik.errors.message}
                        />

                       
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.message}
                                </Form.Control.Feedback> {/* {formik.errors.message ? <div className="m-2 text-danger">{formik.errors.message}</div> : null}    */}
                    </Form.Group>
                    <Button className="support-button" type='submit'> Submit</Button>
                  </FormikProvider>
                 </Form>
            </div>

            </Col>
            <Col lg={4} md={4} sm={12} className="mt-5">
                {/* <img className="img-fluid support-img-align" src= {Contactimg} alt="support-Img" />
              <p className='support-mail-align'>
              <HiOutlineMail  className="support-email-icon" /><span className="email-weight">info@slaylewks.com</span>
              </p> */}
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Support
