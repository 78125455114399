import * as TYPES from './types.js';


export const locationDetailsAction = (data) => (dispatch) => {
    dispatch({
        type: TYPES.LOCATION_DETAILS,
        payload: data
    });
}

export const couponCodeDetailsAction = (data) => (dispatch) => {
    dispatch({
        type: TYPES.COUPON_DETAILS,
        payload: data
    })
}