import React, { useState, useEffect } from 'react';
import { getAllBusiness, getAllFreelancer, deleteServiceProvider } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Row, Col, Modal, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';


function BusinessList() {

    const navigate = useNavigate();
    
    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");
     const [page, setPage] = useState(0);
     const [Delete, setDelete] = useState(false);

    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 0
    });
   
   
    const dispatch = useDispatch();

    const { orders } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const data = {
            page: state.activePage,
            // gender_filter: ageCategory,
            // service_filter: filterBy,
            // sortType: sortBy,
            // search: search
        };
        if (search) data.search = search

        dispatch(getAllBusiness(data))
            .then((res) => {
                const { providerMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: providerMain,
                    total: totalcount / resultsPerPage
                }))
            })
           

        // dispatch(getAllProposals(data))
    }, []);


    const handlePageChange = (pageNumber) => {
        // setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
        setPage(pageNumber?.selected);
        const data = {
            page: pageNumber?.selected + 1
        };
        if (search) data.search = search
        // onServiceDataChange();
        dispatch(getAllBusiness(data))
            .then((res) => {
                const { providerMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: providerMain,
                    total: totalcount / resultsPerPage
                }))
            })

    };

    const onChangeCategory = (type) => {
        const data = {
            page: state.activePage,
            vendor_filter: type,
        };

        dispatch(getAllBusiness(data))
            .then((res) => {
                const { providerMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: providerMain,
                    total: totalcount / resultsPerPage
                }))
            })

    }

    const handleDeleteChange = (id) => {
        const data = {
            id: id
        }
        dispatch(deleteServiceProvider(data))
            .then((res) => {
                const data = {
                    page: state.activePage,
                };
                dispatch(getAllBusiness(data))
                    .then((res) => {
                        const { providerMain, total_count, resultsPerPage } = res
                        setState((prev) => ({
                            ...prev,
                            data: providerMain,
                            total: total_count / resultsPerPage
                        }))
                    })

            })
            .catch((err) => {
                console.log(err)
            })
    }


    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        setPage(0);
        const data = {
            search: e.target.value,
            page: 1
        };

        dispatch(getAllBusiness(data))
            .then((res) => {
                const { providerMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: providerMain,
                    total: totalcount / resultsPerPage
                }))
            })
    };

    const redirectToEditPage = (id) => {
        navigate(`/dashboard/vendors/${id}`)
     }
    
    return (
        <div className='container-xxl'>
            <div className="order-list mt-4">
          
            <h3 className='marketing-header'> Business / Freelancer</h3>
                <hr /> 

            <div className="service-list-header-option mt-4">
                 <Row>
                    <Col md={4}>
                    <Button className="slaylewks-outline-btn freelance-business-btn px-4 mb-2" onClick={() => onChangeCategory("business")}>Business</Button>{' '}
                    <Button className="slaylewks-outline-btn freelance-business-btn px-4 mb-2" onClick={() => onChangeCategory("freelancer")}>Freelancer</Button>{' '}
                    </Col>
                    <Col md={8}>
                    <div className="free-business-search w-50">
                    <input type="text" class="form-control" id="" placeholder="Search by name" name="search" onInput={onChangeSearch} />
                    </div>
                    </Col>
                 </Row>
                 <hr className='p-0 m-0' />
            </div>
            <section class="p-0">
                <div class="mt-4">
                    <div class="table-responsive slaylewks-table">
                            <Table responsive>
                        <thead className='admin-coupon-list-head'>
                                <tr className='tabel-data-content'>
                                    {/* <th scope="col" width="2%"><input class="form-check-input" type="checkbox" /></th> */}
                                    <th className='admin-tabel-heading'>
                                        Reg Id
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Business Name
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Email Id
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Phone number
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Status
                                    </th>
                                    <th className='admin-tabel-heading'>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    state?.data && state?.data?.length > 0 ?
                                        (
                                            state?.data?.map((item, index) => {
                                                return (
                                                    <tr className='tabel-data-content' key={index}>
                                                        {/* <th scope="row"><input class="form-check-input" type="checkbox" /></th> */}
                                                        <td class="sss">{item.reg_id}</td>
                                                        <td>{item.business_name}</td>
                                                        <td>{item.business_email}</td>
                                                        <td>{item.business_contactNo}</td>
                                                        <td>
                                                            {item.is_active ? "Active" : "Inactive"}
                                                        </td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                    <Dropdown.Item onClick={()=>redirectToEditPage(item?._id)}>Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => setDelete(true)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <>
                                                            <Modal
                                                            
                                                                    show={Delete}
                                                                    onHide={()=>setDelete(false)}
                                                                    backdrop="static"
                                                                    keyboard={false}
                                                                    className="text-center"
                                                                    centered
                                                                    animation={true}
                                                                    backdropClassName="business-backdrop"
                                                                    
                                                                >
                                                                    <Modal.Header closeButton>
                                                                
                                                                    </Modal.Header>
                                                                    <Modal.Body className='mb-5 px-5 py-0'>
                                                                        <h2>Delete</h2>
                                                                    <h6>Are you sure? you want to delete this profile permanently?</h6>
                                                                    <Row className='mt-5'>
                                                                        <Col md={6}>
                                                                        <Button className="image-delete-cancel" variant="" onClick={()=>setDelete(false)}>Cancel</Button>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                        
                                                                        <Button className="image-delete-delete" variant="" onClick={() => {handleDeleteChange(item._id); setDelete(false)}}>
                                                                        Yes, Delete
                                                                        </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    </Modal.Body>
      </Modal>

                                                            </>
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                            <div className="mt-4 p-5 d-flex">
                                                No records Found
                                            </div>
                                        )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </section>

            <div>
                {
                    state?.total > 1 && (
                        <ReactPaginate 
        
                        previousLabel="<<"
                        nextLabel=">>"
                        breakLabel="..."
                        pageCount={state.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination justify-content-center pagination-box'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                        forcePage={page}
                       />

                    )
                }
                        
               
             

                
            </div>
        </div>
        </div>
    )
}

export default BusinessList;