import React, { useState, useEffect } from 'react';
import { getAllUsers, deleteUser } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Pagination, Button, Dropdown, Modal, Row, Col, Table } from "react-bootstrap";
import { BsThreeDotsVertical, BsPlusCircle, BsTrash } from "react-icons/bs";
import ReactPaginate from 'react-paginate';

function UsersList() {

    const [search, setSearch] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [ageCategory, setAgeCategory] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [sortBy, setSortBy] = useState("");

    const [Delete, setDelete] = useState(false);
    const [userId, setUserId] = useState();
    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });

    const deleteOpen = (id) => {
        setDelete(true)
      console.log("delete id",id)
      setUserId(id)
    //   handleDeleteChange(id)
    }

    const dispatch = useDispatch();

    const { users } = useSelector((state) => state.dashboard);

    useEffect(() => {
        const data = {
            page: state.activePage,
        };
        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })
        // dispatch(getAllProposals(data))
    }, []);

    const getAllData = () => {
        const data = {
            page: state.activePage,
        };
        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })
    }

    const handlePageChange = (pageNumber) => {
        setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1  }));
        const data = {
            page: pageNumber?.selected + 1 
        };
        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })

    };

    const handleDeleteChange = () => {
        const data = {
            id: userId
        }
        dispatch(deleteUser(data))
            .then((res) => {
                const data = {
                    page: state.activePage,
                };
                dispatch(getAllUsers(data))
                    .then((res) => {
                        const { userMain, totalcount, resultsPerPage } = res
                        setState((prev) => ({
                            ...prev,
                            data: userMain,
                            total: totalcount / resultsPerPage
                        }))
                        getAllData();
                        setDelete(false)
                    })


            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
        const data = {
            search: e.target.value
        };

        dispatch(getAllUsers(data))
            .then((res) => {
                const { userMain, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: userMain,
                    total: totalcount / resultsPerPage
                }))
            })
    };

    return (
        <div className='container-xxl'>
        <div className="order-list mt-3">
                <h3 className='marketing-header'> Users</h3>
                <hr />
            <div className="service-list-header-option mt-4">

                <div className="business-search w-50">
                    <input type="text" class="form-control" id="" placeholder="Search by name" name="search" onInput={onChangeSearch} />
                </div>

                {/* <div className="ms-auto p-2">
                    <select class="form-select" aria-label="Default select example">
                        <option selected>Filter by</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>

                <div className="ms-2 p-2">
                    <select class="form-select" aria-label="Default select example">
                        <option selected>Sort by</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div> */}

            </div>
            <hr className='p-0 mb-0' />

            <section class="pt-0">
                <div class=" mt-4">
                    <div class="table-responsive slaylewks-table">
                            <Table responsive>
                        <thead className='admin-coupon-list-head'>
                                <tr className='tabel-data-content'>
                                    {/* <th scope="col" width="4%"><input class="form-check-input" type="checkbox" /></th> */}
                                    <th className='admin-tabel-heading'>
                                        Username
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Email Id
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Phone number
                                    </th>
                                    <th className='admin-tabel-heading'>
                                        Status
                                    </th>
                                    <th className='admin-tabel-heading'>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                               {
                                    state.data && state.data?.length > 0 ?
                                        (
                                            state.data?.map((item, index) => {
                                                return (
                                                    <tr className='tabel-data-content' key={index}>
                                                        {/* <th scope="row"><input class="form-check-input" type="checkbox" /></th> */}
                                                        <td class="sss">{item.username}</td>
                                                        <td>{item.email_id}</td>
                                                        <td>{item.mobile_no}</td>
                                                        <td>{item.is_active ? "Active" : "Inactive"}</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="text-center">
                                                                    <Dropdown.Item onClick={() => deleteOpen(item?._id)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                          
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                            <tr>
                                                No records Found
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal
                                                                    show={Delete}
                                                                    onHide={()=>setDelete(false)}
                                                                    keyboard={false}
                                                                    className="text-center"
                                                                    centered
                                                                >
                                                                    <Modal.Header closeButton>
                                                                    
                                                                    </Modal.Header>
                                                                    <Modal.Body className='mb-5 px-3 py-0'>
                                                                        <h2>Delete</h2>
                                                                    <h6>Are you sure? you want to delete this profile permanently?</h6>
                                                                    <Row className='mt-5'>
                                                                        <Col md={6}>
                                                                        <Button className="image-delete-cancel" variant="" onClick={()=>setDelete(false)}>Cancel</Button>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                        <Button className="image-delete-delete" variant="" onClick={() => handleDeleteChange()}>
                                                                        Yes, Delete
                                                                        </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    </Modal.Body>
                                                                    
                                                                    
                                                                    
                                                            </Modal>
            </section>
            <div>
                {/* <Pagination className="px-4">

                    {(() => {
                        const rows = [];
                        for (let i = 0; i < state.total; i++) {
                            rows.push(<Pagination.Item
                                onClick={() => handlePageChange(i + 1)}
                                key={i + 1}
                                active={i + 1 === state.activePage}
                            >
                                {i + 1}
                            </Pagination.Item>);
                        }
                        return rows;
                    })()}

                </Pagination> */}
                {
                    state?.total > 1 && (
   
                        <ReactPaginate 
        
                        previousLabel="<<"
                        nextLabel=">>"
                        breakLabel="..."
                        pageCount={state.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination justify-content-center pagination-box'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                       />
                    )
                }
             
            </div>
        </div>
        </div>
    )
}

export default UsersList;