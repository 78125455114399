import React, {useState} from 'react'
import { businessSidebarNew, freelancerSidebarNew } from "../../../configs";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SupportImg from "../../../assets/images/support.svg"
import logoutImg from "../../../assets/images/logout.svg"
import { logoutFromLocalStorage } from '../../../redux/auth/actions';
import Button from 'react-bootstrap/Button';
import { MdAdsClick } from "react-icons/md";
import Offcanvas from 'react-bootstrap/Offcanvas';


function BusinessSidebar(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
    const { vendor, isVendorLoggedIn, vendorType } = useSelector((state) => state.business);
    const { user, role, isLoggedIn } = useSelector(state => state.auth);

     console.log(role, "role")

    const [activeTab, setActiveTab] = useState(""); 
    const [activeUrl, setActiveUrl] = useState(""); 
    if (activeUrl !== window.location.pathname){
        setActiveUrl(window.location.pathname);
        const DashboardActive = window.location.pathname.split('/')
        if(DashboardActive[2]){
            setActiveTab(DashboardActive[2])
        } else {
            setActiveTab("dashboard")
        }
       
    }


    

    const handleLogout = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    };
  
    


    return (
        <div className='container-xxl'>
        <div id='mobile-view'>
        <Button variant="" className='menu-btn-dashboard' onClick={handleShow}>
             DashBoard <span><MdAdsClick /></span>
      </Button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dashboard</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div class="business-left-menu">
            <ul>
                {
                    role?.role_name === "freelancer" ? (
                        <>
                         {
                    freelancerSidebarNew.map((tab, index) => {
                    
                        return (
                            <Link to={vendor?.business_verified ? tab.link : "#"} key={index}>
                                <li className={tab.heading?.toLowerCase().includes(activeTab) ? "active-menu" : ""} >
                                    <img src={tab.image} class="img-fluid"  alt={tab.heading} />
                                    <a href="JavaScript:void(0);" >{tab.heading}</a>
                                </li>
                            </Link>
                        )
                    })
                }
                        </>

                    ):(
                            <>
                              {
                    businessSidebarNew.map((tab, index) => {
                    
                        return (
                            <Link to={vendor?.business_verified ? tab.link : "#"} key={index}>
                                <li className={tab.heading?.toLowerCase().includes(activeTab) ? "active-menu" : ""} >
                                    <img src={tab.image} class="img-fluid"  alt={tab.heading} />
                                    <a href="JavaScript:void(0);" >{tab.heading}</a>
                                </li>
                            </Link>
                        )
                    })
                }
                            </>
                    )
                }
               

              
                <div class="support-logout">
                <Link to={"/businessSupportMain"}>
                    <li>
                        <img src={SupportImg} class="img-fluid" alt="Support" />
                        <a href="JavaScript:void(0);">Support</a></li>
                        </Link>
                    <li><img src={logoutImg} class="img-fluid" alt="Log out" />
                        <a href="JavaScript:void(0);" class="logout" onClick={handleLogout}>Log out</a></li>
                </div>
            </ul>
        </div>
        </Offcanvas.Body>
      </Offcanvas>
        
        </div>

        <div id="system-view">
        <div class="business-left-menu">
            <ul>
                {
                    role?.role_name === "freelancer" ? (
                        <>
                         {
                    freelancerSidebarNew.map((tab, index) => {
                    
                        return (
                            <Link to={vendor?.business_verified ? tab.link : "#"} key={index}>
                                <li className={tab.heading?.toLowerCase().includes(activeTab) ? "active-menu" : ""} >
                                    <img src={tab.image} class="img-fluid"  alt={tab.heading} />
                                    <a href="JavaScript:void(0);" >{tab.heading}</a>
                                </li>
                            </Link>
                        )
                    })
                }
                        </>

                    ):(
                            <>
                              {
                    businessSidebarNew.map((tab, index) => {
                    
                        return (
                            <Link to={vendor?.business_verified ? tab.link : "#"} key={index}>
                                <li className={tab.heading?.toLowerCase().includes(activeTab) ? "active-menu" : ""} >
                                    <img src={tab.image} class="img-fluid"  alt={tab.heading} />
                                    <a href="JavaScript:void(0);" >{tab.heading}</a>
                                </li>
                            </Link>
                        )
                    })
                }
                            </>
                    )
                }
               

              
                <div class="support-logout">
                <Link to={"/businessSupportMain"}>
                    <li>
                        <img src={SupportImg} class="img-fluid" alt="Support" />
                        <a href="JavaScript:void(0);">Support</a></li>
                        </Link>
                    <li><img src={logoutImg} class="img-fluid" alt="Log out" />
                        <a href="JavaScript:void(0);" class="logout" onClick={handleLogout}>Log out</a></li>
                </div>
            </ul>
        </div>
        </div>
        
        </div>
      
    )
}
export default BusinessSidebar;