import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col
} from "react-bootstrap";
import moment from "moment";

function StaffDraggableModal(props) {

    const { show, onHide, selectInfo, updateResource } = props;
    let { _def, _instance } = selectInfo
    let text = _def?.title;

    let startDate = moment(_instance?.range?.start)
    let endDate = moment(_instance?.range?.end)

    // console.log("Before From " + startDate?.format("DD-MM-YYYY") + " To " + endDate.format("DD-MM-YYYY"))

    if (endDate?.diff(startDate, 'days') > 1) {
        let tempEndDate = moment(endDate).subtract(1, "days");
        // endDate = moment(endDate).subtract(1, "days");
        text += " From " + startDate?.format("DD-MM-YYYY") + " To " + tempEndDate.format("DD-MM-YYYY");
    }
    else {
        text += " on " + startDate?.format("DD-MM-YYYY")
    }


    // console.log("After From " + startDate?.format("DD-MM-YYYY") + " To " + endDate.format("DD-MM-YYYY"))

    const changeResouceTime = () => {
        let resourceDetails = _def?.extendedProps?._doc;
        let data = {
            ...resourceDetails,
            start_dateTime: startDate?.format("YYYY-MM-DD").concat("T00:00:00.000Z"),
            end_dateTime: endDate.format("YYYY-MM-DD").concat("T00:00:00.000Z")
        }
        // console.log("Update req => ", data);
        updateResource(data);
    }



    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-50w"
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

                <div className="staff-body-content">
                    <div className="text-center">
                        <p>
                            Do you want to change staff details ?
                        </p>
                        <p className="font-weight-bold">
                            <b>
                                {
                                    text
                                }
                            </b>
                        </p>
                    </div>
                    <div className="text-center mt-4">
                        <Button variant="outline-danger" onClick={() => onHide()} className="me-3">
                            cancel
                        </Button>
                        <Button variant="primary" type="submit" onClick={() => changeResouceTime()}>
                            Update
                        </Button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default StaffDraggableModal;