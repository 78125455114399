import React, { useEffect, useState } from 'react';
import smallInfoImg from "../../assets/images/small-info.png";
import secureImg from "../../assets/images/secure.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCouponCodeAction, getApplyCouponCodeAction, removeFromCart } from '../../redux/cart/actions';
import { useNavigate } from "react-router-dom";
import { setOrderAmount } from '../../redux/payment/actions';
import { Card, Row, Col, Form, Modal, Table } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { FormikProvider, useFormik } from 'formik';
import { SpinnerDotted } from 'spinners-react';
import { couponCodeDetailsAction } from '../../redux/location/action';
import { getCartAmounts } from '../../helpers/cartCalculation';

function OrderSummary(props) {
    const { servicesCart } = useSelector((state) => state.cart);
    const { serviceProviderCart } = useSelector((state) => state.cart);
    const { order } = useSelector((state) => state.payment);
    const { user } = useSelector((state) => state.auth);
    const [loadingRoll, setLoadingRoll] = useState(false);

    const [coupons, setCoupons] = useState();
    const [gstEnable, setGstEnable] = useState(false)
    const [couponList, setCouponlist] = useState(false);
    const [RemovedTotalAmount, setRemovedTotalAmount] = useState();
    const [couponValue, setCouponValue] = useState("");
    const [activeTab, setActiveTab] = useState("");
    
    if (activeTab !== window.location.pathname)
        setActiveTab(window.location.pathname);

    useEffect(() => {
        const data = {
            serviceprovider: serviceProviderCart?._id,
            servicecategory: servicesCart?.map((item) => item?.service_category?.[0]?._id),
            userid: user?._id,
        }
        dispatch(getAllCouponCodeAction(data))
            .then((res) => {
                setCoupons(res)
                setGstEnable(res?.Serviceprovider?.is_GST_checked)
            })
    }, [serviceProviderCart?._id]);

    useEffect(() => {
        const data = {
            serviceprovider: serviceProviderCart?._id,
            servicecategory: servicesCart?.map((item) => item?.service_category?.[0]?._id),
            userid: user?._id,
        }
        dispatch(getAllCouponCodeAction(data))
            .then((res) => {
                setCoupons(res)
                setGstEnable(res?.Serviceprovider?.is_GST_checked)
                setRemoveCoupon()
                props.onServiceDeleted()

            })
    }, [props.serviceDeleted]);

    // console.log(gstEnable, "test")

    const [originalPrice, setOriginalPrice] = useState(0);
    const [serviceCategoryPrice , setServiceCategoryPrice] = useState([])
    const [selectedCouponCategory, setSelectedCouponCategory] = useState([])
    const [couponCategoryAdmin,setCouponCategoryAdmin] = useState(true)
    const [discount, setDiscount] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [addCoupon, setAddCoupon] = useState(false)
    const [couponAmount, setCouponAMount] = useState(0);
    const [couponCodeName, setCouponCodeName] = useState("");
    const [adminVerification, setAdminVerification] = useState(false);
    const [couponReducedValue, setCouponReducedValue] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [gstPrice, setGstPrice] = useState(0);
    const [taxAbleAmount, setTaxableAmount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const type = servicesCart?.map(service => service?.type);
    const servicetype = type[0];
    const [couponDiscount, setCouponDiscount] = useState(0)
    const [getCouponItem, setGetCouponItem] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const vendorType = serviceProviderCart?.vendor_type;
    localStorage.setItem('isAdmin',adminVerification)

    useEffect(() => {

        const couponObj={
           adminVerification,
           couponCodeName,
           couponReducedValue
        }
        const { service_price,service_original_price,gstAmount, discountPrice, total, categoryFilter_services } = getCartAmounts(servicesCart, serviceProviderCart,couponObj);
        setServiceCategoryPrice(categoryFilter_services)
        setOriginalPrice(service_original_price);
        setDiscount(discountPrice);
        setGstPrice(gstAmount);
        setTotalAmount(Math.abs(total));
        setTaxAmount(gstAmount);
        setTaxableAmount(service_price)
      
    }, [servicesCart, gstEnable,adminVerification,couponAmount,totalAmount,couponReducedValue,couponCodeName]);


    const removeServiceFromCart = (service) => {
        dispatch(removeFromCart(service))
    }

    const goBack = () => {
        navigate(-1);
    }




    const [applied, setApplied] = useState(false);
    const [applyText, setApplyText] = useState(false);
    const [CouponFinal, setCouponFinal] = useState(0);



    const applytext = (item) => {
        let coupon_serviceCategory = []
        item?.coupon_code_business && (
            item?.servicecategory?.forEach((coupon_item) => {
                coupon_serviceCategory.push(coupon_item)
            })
    
        )
        item?.coupon_code_admin && (
            setCouponCategoryAdmin(false)
        )
        
        setSelectedCouponCategory(coupon_serviceCategory)
        formik.setFieldValue('coupon_value', item?.coupon_code_business || item?.coupon_code_admin)
    }

  

    const setRemoveCoupon = () => {
        try {
            let total =totalAmount+CouponFinal;
            setDisabled(false)
            setApplied(false);
            setApplyText(false)
            setCouponAMount("");
            setTotalAmount(Math.abs(total));
            setAddCoupon(false);
            setCouponValue("");
            formik.setFieldValue('coupon_value', "")
            setCouponFinal("");
            setCouponCodeName("");
            setCouponReducedValue(0);
            dispatch(setOrderAmount({
                total_amount: total,
                taxable_amount: taxAbleAmount,
                tax_amount: taxAmount,
                discount_amount: discount,
                coupon_amount: 0,
                currency: 'INR' // Change when going international
            })) 
            console.log("try success")
        } catch (error) {
            console.log(error,"error")
        }
    
    }

    const setRemoveCouponItem = () => {
        let total =totalAmount+couponAmount;
        setCouponlist(false)
        setApplied(false);
        setCouponAMount("");
        setTotalAmount(total);
        setAddCoupon(false);
        setCouponValue("");
        setDisabled(false)
        formik.setFieldValue('coupon_value', "")
        setCouponFinal("");
        setAdminVerification(false)
        dispatch(setOrderAmount({
            total_amount: total,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount: discount,
            coupon_amount: 0,
            currency: 'INR' // Change when going international
        }))
    }
    const formik = useFormik({
        initialValues: {
            coupon_value: '',
        },
        onSubmit: (values) => {

            // selectedCouponCategory.some(item => serviceCategoryPrice?.map((item)=>
            // item?.service_category
            // ))
            let category_originalPrice = 0;
            if(couponCategoryAdmin){
                serviceCategoryPrice?.map((item) => {
                    let categoryMatch = selectedCouponCategory.includes(item?.service_category)
                    if(categoryMatch){
                        if(parseInt(item?.service_discount) == 0){
                            category_originalPrice +=parseInt(item?.service_original_price);
                        }else{
                            category_originalPrice +=parseInt(item?.service_price);
                        }
    
                    }
                })
            }else{
                category_originalPrice = originalPrice
            }
           

            // serviceCategoryPrice

            const data = {
                coupon: values.coupon_value,
                total_amount: parseInt(category_originalPrice),
                userid: user?._id,
            };
            dispatch(getApplyCouponCodeAction(data))
                .then((res) => {
                    setCouponCodeName(res?.coupon_code)
                 

                    setCouponReducedValue(res?.coupon_amount_reduced)
                    setAdminVerification(res?.isAdmin)
               
                    const data = res;
                    setLoadingRoll(true);
                    setTimeout(() => {
                        setLoadingRoll(false)
                        setCouponFinal(data?.coupon_amount_reduced);
                        setTotalAmount(Math.abs(data?.discounted_final_price));
                        setDisabled(true)
                    }, [2000])
                    setAddCoupon(true);
                    setCouponCategoryAdmin(true)
                    setCouponlist(false);
                })


        },
    });
    const redirectCheckoutPage = () => {
        navigate("/payment/checkout");
        dispatch(setOrderAmount({
            coupon_amount: CouponFinal,
            total_amount: totalAmount,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount: discount,
            gst_amount: gstPrice,
            coupon_code: couponCodeName,
            isAdmin: adminVerification,
            coupon_amount_reduced: couponReducedValue,
            currency: 'INR' // Change when going international
        }))
        dispatch(couponCodeDetailsAction(couponCodeName))
    }
    const redirectCheckoutPageBridal = () => {
        navigate("/payment/checkout/bridal");
        dispatch(setOrderAmount({
            coupon_amount: CouponFinal,
            total_amount: totalAmount,
            taxable_amount: taxAbleAmount,
            tax_amount: taxAmount,
            discount_amount: discount,
            gst_amount: gstPrice,
            coupon_code: couponCodeName,
            isAdmin: adminVerification,
            coupon_amount_reduced: couponReducedValue,
            currency: 'INR' // Change when going international
        }))
    }
    const applyCoupons = () => {
        if (formik.values.coupon_value !== "") {
            formik.handleSubmit()
        }
        setAddCoupon(false)
    }
    const oneApply = (item) => {
        const selectedCoupon = item?._item?.coupon_code_business || item?.coupon_code_admin
        if (formik.values.coupon_value === selectedCoupon) {
            return true;
        } else {
            return false;
        }

    }

    return (

        <div className="order-summary">
            <Row className='mt-3'>
                <Card className='total-summary-card p-0 mb-0'>
                    <div className='mx-3'>
                        <div className='total-display-card'>
                            <Row className='item-component'>
                                <Col>
                                    <div className='serivice-item-header mt-2 mx-2'>
                                        <h6 className='w-75'>Total Services</h6>
                                        <p>{servicesCart?.length}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <hr className='p-0 m-0' />

                        <div className='mt-2 mx-2'>
                            <Row>
                                {
                                    activeTab === "/payment/checkout" || activeTab === "/payment/checkout/bridal" ? (
                                        null
                                    ) : (
                                        <>
                                            <Form onSubmit={formik.handleSubmit}>
                                                <FormikProvider value={formik}>
                                                    <div className='coupon-code-component mt-4 mb-2'>

                                                        <Form.Control
                                                            type="text"
                                                            id="couponText"
                                                            className="coupon-input-box"
                                                            aria-describedby="passwordHelpBlock"
                                                            placeholder='Discount coupon'
                                                            value={formik.values.coupon_value}
                                                            name="coupon_value"
                                                            onChange={formik.handleChange}
                                                            disabled={disabled}
                                                        />
                                                        {
                                                            addCoupon ? (

                                                                <h6 className='mt-2' onClick={setRemoveCoupon}>
                                                                    <span>
                                                                        {
                                                                            loadingRoll ? (
                                                                                <div style={{
                                                                                    width: "10%",
                                                                                    margin: "0% auto",
                                                                                }}>
                                                                                    <SpinnerDotted
                                                                                        loading={loadingRoll}
                                                                                        size="100%"
                                                                                        thickness={180}
                                                                                        speed={180}
                                                                                        color="#ECB390"
                                                                                        secondaryColor="rgb(255,255,255)"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <h6>Remove</h6>
                                                                            )
                                                                        }
                                                                    </span>
                                                                </h6>
                                                            ) : (
                                                                <h5 className='mt-2' onClick={applyCoupons}>Apply</h5>
                                                            )
                                                        }


                                                    </div>
                                                </FormikProvider>
                                            </Form>
                                            <div className='available-coupons'>
                                                <p
                                                    onClick={() => setCouponlist(true)}
                                                >{coupons?.couponCount} coupons available</p>
                                            </div>
                                        </>
                                    )
                                }


                                <div className='float-end'>
                                    <Modal
                                        className="coupon-modal-size"
                                        show={couponList}
                                        // onHide={() => setCouponlist(false)}
                                        aria-labelledby="example-modal-sizes-title-sm"
                                    >
                                        <Modal.Header className='pb-0 mt-0 pt-0'>
                                            <div className='apply-coupon-headers mt-2'>
                                                <span onClick={()=>setCouponlist(false)}> <IoIosArrowBack /></span>
                                                <p className='py-0 my-0'>Apply Coupon</p>
                                            </div>
                                        </Modal.Header>
                                        <hr className='p-0 m-0' />
                                        <Modal.Body className=''>
                                            <Row>

                                                <Form onSubmit={formik.handleSubmit}>
                                                    <FormikProvider value={formik}>

                                                        <div className='coupon-code-component mt-4 px-2'>

                                                            <Form.Control
                                                                type="text"
                                                                id="couponText"
                                                                className="coupon-input-box-card"
                                                                aria-describedby="passwordHelpBlock"
                                                                placeholder='Discount coupon'
                                                                value={formik.values.coupon_value}
                                                                onChange={formik.handleChange}
                                                                name="coupon_value"
                                                                disabled={disabled}
                                                            />
                                                            {
                                                                addCoupon ? (

                                                                    <h6 className='mt-2' onClick={setRemoveCoupon}>Remove</h6>
                                                                ) : (
                                                                    <h5 className='mt-2' onClick={applyCoupons}>Apply</h5>
                                                                )
                                                            }



                                                        </div>
                                                    </FormikProvider>

                                                </Form>
                                            </Row>
                                            <Card className='coupon-list-inner-card mt-2'>
                                                <Card.Body >
                                                    {
                                                        coupons?.couponList?.map((item, index) => {
                                                            let isItemAdded = oneApply(item)

                                                            return (
                                                                <div>
                                                                    {/* {
                                                                            totalAmount > item.discount_offer?.[index]?.min && totalAmount < item.discount_offer?.[index]?.max && ( */}
                                                                    <Card className='coupon-card-outer mb-3'>
                                                                        <Card.Body className='mb-0'>
                                                                            <Row>
                                                                                <Table borderless>
                                                                                    <thead key={item?._id}>
                                                                                        <tr>
                                                                                            <th>
                                                                                                <h6 onClick={() => setCouponlist(false)}>{item?.coupon_code_business || item?.coupon_code_admin}</h6>
                                                                                            </th>
                                                                                            <th>
                                                                                                {
                                                                                                    isItemAdded ? (
                                                                                                        <h2>Applied</h2>

                                                                                                    ) : (
                                                                                                        <h2 onClick={() => applytext(item)}>Apply</h2>

                                                                                                    )
                                                                                                }
                                                                                                {/* {
                                                                                            //     applied ? (
                                                                                            //         null
                                                                                            //     ):(
                                                                                            //         <h2 onClick={() => applytext(item)}>Apply</h2>
                                                                                            //     )
                                                                                            // } */}
                                                                                            </th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                </Table>

                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={11}>
                                                                                    <h1>{item?.description}</h1>
                                                                                    {/* {
                                                                                                item?.discount_offer_type === "in price" ? (
                                                                                                    <>
                                                                                                        {item?.discount_offer?.length > 0 && (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item?.discount_offer?.map((i) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                            <h1>Get {i?.amount}₹ OFF On Orders Above {i?.min} ₹</h1>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
        
                                                                                                    </>
        
                                                                                                ) : (
                                                                                                    <>
                                                                                                    {
                                                                                                        item?.discount_offer?.map((i) => {
                                                                                                            return <h1>Get {i?.amount}% OFF On Orders Above {i?.min} ₹</h1>
                                                                                                        })
                                                                                                    }
                                                                                                </>
        
                                                                                                )
                                                                                            } */}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mb-0 mp-0'>
                                                                                {
                                                                                    item?.time_limit_type === "life time" ? (
                                                                                        <p className='mt-3 mb-0'>Valid for {item?.time_limit_type}</p>

                                                                                    ) : (
                                                                                        <p className='mt-3 mb-0'>Valid till {moment(item?.toTime).format("MMM Do YY")}</p>
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                        </Card.Body>

                                                                    </Card>

                                                                    {/* // )} */}
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Modal.Body>
                                    </Modal>
                                </div>

                                <Col>
                                    <div className={`
                                              ${activeTab === '/payment/checkout' ? "mt-4 serivice-item-amounts" : "serivice-item-amounts"}`}>
                                        <h6 className='w-75'>Price </h6>
                                        <p>{Math.round(originalPrice) + ".00"}<span className='float-end'>₹</span></p>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    {
                                        gstEnable && (
                                            <div className='serivice-item-discount'>
                                                <h6 className='w-75'>GST 18%</h6>

                                                <p>{Math.round(gstPrice) + ".00"} <span className='float-end'>₹</span></p>

                                            </div>
                                        )
                                    }

                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className='serivice-item-discount'>
                                        <h6 className='w-75'>Discount</h6>

                                        <p>{Math.round(discount) + ".00"} <span className='float-end'>₹</span></p>

                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <div className='serivice-item-coupon'>
                                        <h6 className='w-75'>Coupons for you</h6>
                                        {/* {
                                                !order?.coupon_amount ? (
                                                    <p>0<span className='float-end'>₹</span></p>
                                                ):(
                                                    <p>{(order?.coupon_amount)/100}<span className='float-end'>₹</span></p>
    
                                                )
                                            } */}
                                        <p>{Math.round(CouponFinal) + ".00"}<span className='float-end'>₹</span></p>
                                        {/* <h6 className='rupee-symbol'>₹</h6> */}

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <hr className='p-0 m-0' />
                        <Row className=''>
                            <Col>
                                <div className='serivice-item-total-end mt-2 mx-2'>
                                    <h6 className='w-75'>Total Amount</h6>
                                    {/* <p>{Math.round(order?.total_amount)/100 + ".00"} <span className='float-end'>₹</span></p> */}
                                    {/* <h6 className='rupee-symbol'>₹</h6> */}
                                    
                                    <p>{Math.round(parseInt(totalAmount)) + ".00"} <span className='float-end'>₹</span></p>

                                </div>
                            </Col>

                            {/* {
                                    discount > 0 && ( */}
                            <>
                                {
                                    !order?.coupon_amount ? (
                                        // <p class="order-save discount-text">You will save ₹{Math.round((order?.discount_amount)/100 )+ ".00"} on this order</p>
                                        parseInt(CouponFinal) > 0 ? (
                                        <p class="order-save discount-text">You will save ₹{Math.round(parseInt(order?.discount_amount) / 100 + parseInt(CouponFinal)) + ".00"} on this order</p>
                                        ):(
                                            order?.discount_amount?
                                            <p class="order-save discount-text">You will save ₹{Math.round(parseInt(order?.discount_amount) / 100 )+ ".00"} on this order</p>:
                                            <p class="order-save discount-text">You will save ₹0 on this order</p>
                                        )
                                        

                                    ) : (
                                        <p class="order-save discount-text">You will save ₹{Math.round(parseInt(order?.discount_amount) / 100 + parseInt(CouponFinal)) + ".00"} on this order</p>
                                    )
                                }
                            </>
                            {/* // <p class="order-save discount-text">You will save ₹{Math.round((order?.discount_amount) + (order?.coupon_amount))/100 + ".00"} on this order</p>
                                    )
                                } */}


                        </Row>
                        {
                            vendorType === "business" ? (
                                <div>
                                    {
                                        servicetype !== "basic" ? (
                                            <>
                                                {
                                                    props.continue && (
                                                        <button class="cart-checkout-continue" onClick={redirectCheckoutPageBridal}>Continue</button>
                                                    )
                                                }
                                            </>

                                        ) : (
                                            <>
                                                {
                                                    props.continue && (
                                                        <button class="cart-checkout-continue" onClick={redirectCheckoutPage}>Continue</button>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            ) : (
                                <div>
                                    {
                                        props.continue && (
                                            <button class="cart-checkout-continue" onClick={redirectCheckoutPageBridal}>Continue</button>
                                        )
                                    }
                                </div>
                            )
                        }




                    </div>
                </Card>
                <p class="safe-secure mx-5 mt-2 w-75"><span className='me-2'><ion-icon name="shield-checkmark"></ion-icon></span>Safe and Secure Payment 100%.</p>
            </Row>
        </div>

    )
}

export default OrderSummary