//Business / Freelancer sidebar Data
import { FiHome, FiSettings, FiCalendar, FiUsers } from "react-icons/fi";
import { FaUserFriends } from "react-icons/fa";
import { RiFileListLine } from "react-icons/ri"
import { BiSupport, BiLogOut } from "react-icons/bi"
import { BsImages, BsPatchCheck } from "react-icons/bs";
import { GrTicket } from "react-icons/gr";
import { MdOutlineMarkEmailUnread } from "react-icons/md"
import DashboardImg from "../assets/images/dashboard.svg"
import InventoryImg from "../assets/images/inventory.svg"
import OrdersImg from "../assets/images/orders.svg"
import AppoinmentImg from "../assets/images/appoinment.svg"
import StaffImg from "../assets/images/staff.svg"
import SettingsImg from "../assets/images/settings.svg"
import GallerysImg from "../assets/images/gallerys.svg"
import RequestImg from "../assets/images/request.svg"
import SupportImg from "../assets/images/support.svg"
import logoutImg from "../assets/images/logout.png";
import proposalImg from "../assets/images/proposal-icon.svg"
import marketingImg from "../assets/images/marketing-icon.svg"
import CouponuserImg from '../assets/images/couponUsers.png'
import TransactionImg from '../assets/images/transaction.png'
import CommissionImg from '../assets/images/commission.png'
import BranchesImg from '../assets/images/branches.png'
import chevron from '../assets/images/chevron-down-outline.svg'


export const businessSidebar = [
    {
        icon: <FiHome />,
        heading: "home",
        link: "/dashboard"
    },
    {
        icon: <RiFileListLine />,
        heading: "Inventory",
        link: "/dashboard/inventory"
    },
    {
        icon: <RiFileListLine />,
        heading: "Orders",
        link: "/dashboard/orders"
    },
    {
        icon: <MdOutlineMarkEmailUnread />,
        heading: "Requests",
        link: "/dashboard/request",
    },
    {
        icon: <FiCalendar />,
        heading: "Appointments",
        link: "/dashboard/appointments"
    },
    
    {
        icon: <FiUsers />,
        heading: "Staff",
        link: "/dashboard/staff"
    },
    {
        icon: <BsImages />,
        heading: "Gallery",
        link: "/dashboard/gallery"
    },
    {
        icon: <FiSettings />,
        heading: "Settings",
        link: "/dashboard/settings"
    },
    {
        icon: <BiSupport />,
        heading: "Support",
        link: "/dashboard/support"
    },
    {
        icon: <BiLogOut />,
        heading: "Logout",
        link: "/logout"
    },


];



export const businessSidebarNew = [
    {
        icon: <FiHome />,
        heading: "Dashboard",
        link: "/dashboard",
        image: DashboardImg,
    },
    {
        icon: <RiFileListLine />,
        heading: "Inventory",
        link: "/dashboard/inventory",
        image: InventoryImg
    },
    {
        icon: <RiFileListLine />,
        heading: "Orders",
        link: "/dashboard/orders",
        image: OrdersImg
    },
    {
        icon: <MdOutlineMarkEmailUnread />,
        heading: "Requests",
        link: "/dashboard/request",
        image: RequestImg
    },
    {
        icon: <FiCalendar />,
        heading: "Appointments",
        link: "/dashboard/appointments",
        image: AppoinmentImg
    },
    {
        icon: <FiUsers />,
        heading: "Staff",
        link: "/dashboard/staff/calendar",
        image: StaffImg
    },
    {
        icon: <BsImages />,
        heading: "Gallery",
        link: "/dashboard/gallery",
        image: GallerysImg
    },
    {
        icon: <GrTicket />,
        heading: "Marketing",
        link: "/dashboard/marketing/vendors",
        image: marketingImg
    },
    {
        icon: <FiSettings />,
        heading: "Settings",
        link: "/dashboard/settings",
        image: SettingsImg
    }
    

];

export const freelancerSidebarNew = [
    {
        icon: <FiHome />,
        heading: "Dashboard",
        link: "/dashboard",
        image: DashboardImg,
    },
    {
        icon: <RiFileListLine />,
        heading: "Inventory",
        link: "/dashboard/inventory",
        image: InventoryImg
    },
    {
        icon: <RiFileListLine />,
        heading: "Orders",
        link: "/dashboard/orders",
        image: OrdersImg
    },
    {
        icon: <MdOutlineMarkEmailUnread />,
        heading: "Requests",
        link: "/dashboard/request",
        image: RequestImg
    },
    {
        icon: <FiCalendar />,
        heading: "Appointments",
        link: "/dashboard/appointments",
        image: AppoinmentImg
    },
    {
        icon: <BsImages />,
        heading: "Gallery",
        link: "/dashboard/gallery",
        image: GallerysImg
    },
    // {
    //     icon: <GrTicket />,
    //     heading: "Marketing",
    //     link: "/dashboard/marketing/vendors",
    //     image: marketingImg
    // },
    {
        icon: <FiSettings />,
        heading: "Settings",
        link: "/dashboard/settings",
        image: SettingsImg
    }
];




export const AdminSidebar = [
    {
        icon: <FiHome />,
        heading: "Dashboard",
        link: "/dashboard",
        image: DashboardImg,
    },
    {
        icon: <BsPatchCheck />,
        heading: "Proposal",
        link: "/dashboard/proposal",
        image: proposalImg,
    },
    {
        icon: <FiUsers />,
        heading: "User",
        link: "/dashboard/user",
        image: StaffImg
    },
    {
        icon: <FaUserFriends />,
        heading: "Vendors",
        link: "/dashboard/vendors",
        image: StaffImg

    },
    {
        icon: <GrTicket />,
        heading: "Marketing",
        link: "",
        // link: "/dashboard/marketing",
        image: marketingImg,
        dropdown: [
            {
                heading: "Admin",
                link: "/dashboard/admin"
            },
            {
                heading: "Salon",
                // link: "/dashboard/marketing/ads"
                link:"/dashboard/salon"
            }
        ]
        
    },
    // {
    //     heading: "Coupons",
    //     link: "/dashboard/coupons",
    //     image: CouponuserImg
    // },
    {
        heading: "Transactions",
        link: "/dashboard/transactions",
        image: TransactionImg
    },
    {
        heading: "Commissions",
        link: "/dashboard/commissions",
        image: CommissionImg
    },
    // {
    //     heading: "Branches",
    //     link: "/dashboard/branches",
    //     image: BranchesImg
    // },
    // {
    //     heading: "Grouping",
    //     link: "/dashboard/grouping",
    //     image: BranchesImg
    // },
    // {
    //     heading: "Salon",
    //     link: "/dashboard/salon",
    //     image: BranchesImg
    // },

];

