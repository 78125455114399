import React from "react";
import { Form,  Modal, Button,  Row,  Col} from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useFormik, FormikProvider} from 'formik';
import { createBusinessReview } from "../../../redux/business/actions";
import { useDispatch} from "react-redux";
import { addReviewValidation } from "../../../validations/yub_schemas/addReviewValidation";

function AddReviewModal(props) {

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            review_count: 0,
            service_category: "",
            comments: ""
        },
        validationSchema: addReviewValidation,
        onSubmit: (values) => {

            let data = {
                ...values,
                service_provider: props.serviceprovider,
                user: props.user
            }
            dispatch(createBusinessReview(data))
                .then((res) => {
                    props.getAllReviews();
                    props.onHide()

                })
                .catch((err) => {
                    console.log(err)
                })
        },
    });

    const handleRatingChange = (value) => {
        formik.setFieldValue("review_count", value);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                {/* <h4>Add Review</h4> */}
            </Modal.Header>
            <Modal.Body>

                <div className="review-body-content px-4">
                    <h4>Add your Reviews</h4>
                    <Form noValidate className="business-review-form text-start my-4" onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-2" controlId="service_category">
                                        <Form.Label className="review-headers">Rating</Form.Label>
                                        <ReactStars
                                            count={5}
                                            value={formik.values.review_count}
                                            size={30}
                                            onChange={(value) => handleRatingChange(value)}
                                            activeColor="#FFC107"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.review_count}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                          <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3 p-2" controlId="service_category">
                                        <Form.Label className="review-headers">Choose Category</Form.Label>
                                        <Form.Select
                                            name="service_category"
                                            placeholder="service category"
                                            onChange={formik.handleChange}
                                            value={formik.values.service_category}
                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                        >
                                            <option value="">Select Category</option>
                                            <option value="Hair">Hair</option>
                                            <option value="Nail">Nails</option>
                                            <option value="Spa">Spa</option>
                                            <option value="Skin">Skin</option>
                                            <option value="Mehendi">Mehendi</option>
                                            <option value="Makeup">Makeup</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.service_category}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3 p-2" controlId="comments">
                                        <Form.Label className="review-headers">Review</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            placeholder="Write your review here...."
                                            name="comments"
                                            onChange={formik.handleChange}
                                            value={formik.values.comments}
                                            isInvalid={!!formik.touched.comments && !!formik.errors.comments}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.comments}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="text-center my-2 mx-2">
                                <Row>
                                 <Col md={12} className="mt-2">
                                        <Button variant="primary" type="submit" className="slaylewks-outline-btn w-25 ">
                                            Add Comment
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </FormikProvider>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddReviewModal;