import React, { useEffect, useState } from "react";
import {
    Row,
    Container,
    Col,
    Dropdown,
    Modal,
    Alert,
    Spinner,
    Button,
    Navbar,
    Nav,
    Form,
    Image,
    NavDropdown
} from "react-bootstrap";
// import logo from "../../../assets/images/logo.svg";
import logo from "../../../assets/images/logo.svg";
import { useSelector, useDispatch } from 'react-redux';
import { logoutFromLocalStorage } from '../../../redux/auth/actions';
import { Link, useNavigate } from 'react-router-dom';
import { BsBell, BsCart4 } from 'react-icons/bs';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ProfileImg from "../../../assets/images/profile.webp";
import BusinessLogo from "../../../assets/images/business-logo.svg"
import AdminLogo from "../../../assets/images/admin-logo.svg"
import AvatarImg from "../../../assets/images/avatar.webp"
import { Offcanvas} from "react-bootstrap";


const DashboardHeader = () => {

    const [registerModal, setRegisterModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailCode] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { user, role, isLoggedIn, needToLogin } = useSelector((state) => state.auth);


    const dispatch = useDispatch();

    const registerChange = () => {
        if (registerModal) {
            setRegisterModal(false);
        }
        else {
            setRegisterModal(true);
        }
    }

    const loginChange = () => {
        if (loginModal) {
            setLoginModal(false);
        }
        else {
            setLoginModal(true);
        }
    }



    const emailVerificationChange = () => {
        if (verifyEmailModal) {
            setVerifyEmailCode(false);
        }
        else {
            setVerifyEmailCode(true);
        }
    }

    const forgotPasswordChange = () => {
        if (forgotPasswordModal) {
            setForgotPasswordModal(false);
        }
        else {
            setForgotPasswordModal(true);
        }
    }

    const resetPasswordChange = () => {
        if (resetPasswordModal) {
            setResetPasswordModal(false);
        }
        else {
            setResetPasswordModal(true);
        }
    }

    const handleLogout = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    };


    const redirectUserProfile = () => {
        navigate(`/profile/${user?._id}`);
    }
    const redirectHomePage = () => {
        navigate('/')
    }


    return (
        <div>
            <div id="advance-nav">
        <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="white" expand={expand} className="shadow">
          <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

          <Link to="/">
                        <Navbar.Brand >
                            <img className="logo-header"
                                src={logo}
                                alt="Slaylewks logo" />
                        </Navbar.Brand>

                    </Link>
           
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img className="logo-header"
                                src={logo}
                                alt="Slaylewks logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className="ms-auto navfont-size">
                        
                        <Nav.Link className="me-4 mt-2" onClick={redirectHomePage}>
                                <h6 className="">Home</h6>
                            </Nav.Link>
    
                            <Nav.Link className="me-4 mt-2 active-Navbar" style={{
                                textTransform: "capitalize"
                            }}>
                                <h6> {role.role_name} DashBoard</h6>
                            </Nav.Link>
                            
                            {/* <Nav.Link className="me-4 mt-1">
                                <BsBell />
                            </Nav.Link> */}
    
                            <Nav.Link className="me-4 mt-3" onClick={redirectUserProfile}>
                                <Image
                                    src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                    roundedCircle
                                    width="30px"
                                    height="30px"
                                /> &nbsp;{user?.username}
                            </Nav.Link>
                        </Nav>
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
</>
        </div>
           <div id ="normal-nav">
           <Navbar bg="white" className="bg-white dashboard-header" expand="lg">
                
                <Container fluid="xxl"   className="navbar-view">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-3" />
                    <Link to="/">
                        <Navbar.Brand>
                            <img className="logo-header"
                                src={role?.role_name === "admin" ? AdminLogo : BusinessLogo}
                                width="230px"
                                height="20%"
                                alt="Slaylewks logo" />
                        </Navbar.Brand>
                    </Link>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav" className="bg-white text-center" >
                        <Nav className="ms-auto navfont-size">
                        
                        <Nav.Link className="me-4 mt-2" onClick={redirectHomePage}>
                                <h6 className="">Home</h6>
                            </Nav.Link>
    
                            <Nav.Link className="me-4 mt-2 active-Navbar" style={{
                                textTransform: "capitalize"
                            }}>
                                <h6> {role.role_name} DashBoard</h6>
                            </Nav.Link>
                            
                            {/* <Nav.Link className="me-4 mt-1">
                                <BsBell />
                            </Nav.Link> */}
    
                            <Nav.Link className="me-4 mt-3" onClick={redirectUserProfile}>
                                <Image
                                    src={user?.profileURL && user?.profileURL !== "" ? user?.profileURL : AvatarImg}
                                    roundedCircle
                                    width="30px"
                                    height="30px"
                                /> &nbsp;{user?.username}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
    
                    </Container>
                   
                </Navbar>
           </div>
        </div>
    );
};

export default DashboardHeader;