import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Modal,
  Alert,
  Spinner,
  Button,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { verifyOrderOtp, updateOrder, getOneOrder } from "../../../redux/dashboard/actions";
import { useDispatch } from "react-redux";
import { useFormik, FormikProvider } from 'formik';
import moment from "moment";

function OrderView(props) {

  const { _id, order_id, order_status, service_provider, services, customer_details, appointment } = props.order

  const [status, setStatus] = useState(order_status || "")
  const [orderOtp, setOrderOtp] = useState("")

  const dispatch = useDispatch();

  const onChangeFilter = (e) => {
    setStatus(e.target.value)
  }

  useEffect(() => {
    setStatus(order_status)
  }, [order_status])

  const onChangeOtp = (e) => {
    setOrderOtp(e)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      order_otp: ''
    },
    // validationSchema: loginValidationSchema,
    onSubmit: (values) => {

      if (values.order_otp !== "") {
        let data = {
          orderid: _id,
          serviceprovider: service_provider?._id,
          orderOTP: values.order_otp
        }
        dispatch(verifyOrderOtp(data))
          .then((res) => {
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        console.log("Order otp not entered")
      }
    },
  });

  const updateOrderStatus = () => {
    let data = {
      id: _id,
      order_status: status
    }
    dispatch(updateOrder(data))
      .then((res) => {
        props.handleOrderDataChange()
        props.onHide();
      })
  }

  const orderOtpVerify = async () => {

    if (orderOtp !== "") {
      let data = {
        orderid: _id,
        order_status: status
      }
      let otpVerify = await dispatch(verifyOrderOtp(data))
      if (otpVerify) {
      }
    }
    else {
      console.log("No otp input field added")
    }

  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-80w"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>

        <Container>
          <Row>
            <Col md={12}>
            <div className="order-view-body">
          <div className="order-view-details">
            <Row>
              <Col md={8}>
              <div className="order-id-details">
                <h5>
                  Order Id : &nbsp; {order_id}
                </h5>
                <h5 className="text-primary mt-4">
                  {(service_provider?.business_name ? service_provider?.business_name : "")}
                </h5>
                <p className="mt-2 text-muted">
                  {(service_provider?.location_details?.area ? service_provider?.location_details?.area : "") + " " + (service_provider?.location_details?.city ? service_provider?.location_details?.city : "")}
                  <br />
                  {(service_provider?.location_details?.state ? service_provider?.location_details?.state : "") + " " + (service_provider?.location_details?.country ? service_provider?.location_details?.country : "")}
                </p>
              </div>
              </Col>
              <Col md={4} className="float-start">
              <div className="">
                <Button variant="outline-primary" className="order-view-btn" onClick={updateOrderStatus}>
                  Save
                </Button>
                <Button variant="outline-danger" className="order-view-btn" onClick={props.onHide}>
                  Cancel
                </Button>
              </div>
              </Col>
            </Row>
            
            <Row>
            <div className="">
              {/* <Form> */}

              <Form className="" onSubmit={formik.handleSubmit}>
                <FormikProvider value={formik}>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="order-view-box" controlId="verifcationCode">
                        <Form.Control
                          type="text"
                          placeholder="Payment Verfication code"
                          name="order_otp"
                          onChange={formik.handleChange}
                          value={formik.values.order_otp}
                          isInvalid={!!formik.touched.order_otp && !!formik.errors.order_otp}

                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Button className="order-verify-otp-btn" type="submit">Verify OTP</Button>
                    </Col>
                    <Col md={4} className="text-end">
                      <div className="mb-2">
                        {/* <select class="form-select"
                          aria-label="Default select example"
                          name="filterService"
                          onChange={onChangeFilter}
                          value={status}
                        >
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                          <option value="cancelled">Cancelled</option>
                        </select> */}

                        <Form.Group className="order-view-status" controlId="order-status">
                          {/* <Form.Label className="required">Order Status</Form.Label> */}
                          <Form.Select
                            name="status"
                            onChange={onChangeFilter}
                            value={status}
                          >
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                            <option value="cancelled">Cancelled</option>
                          </Form.Select>

                        </Form.Group>

                      </div>
                    </Col>
                  </Row>
                </FormikProvider>
              </Form>
              {/* </Form> */}

            </div>
            </Row>
          </div>

          <hr />

          {
            appointment && (
              <>
                <div className="order-customer-details mt-5">
                  <h5>
                    Appointment : <span className="text-primary"> {moment(appointment?.appointment_start_dateTime).format("YYYY-MM-DD hh:mm A")}</span>
                  </h5>
                  {

                  }
                </div>
                <hr />
              </>

            )
          }
          <div className="order-customer-details">
            <h5>
              Customer Details
            </h5>
            <div>
              <Row>
                <Col md={6}>
                  <div class="common-busniess-box">
                    <h6>
                      Name :  <span className="text-muted">
                        {customer_details?.username}
                      </span>
                    </h6>
                    <h6>
                      Phone Number :  <span className="text-muted">
                        {customer_details?.mobile_no}
                      </span>
                    </h6>
                    <h6>
                      Email Id:  <span className="text-muted">
                        {customer_details?.email_id}
                      </span>
                    </h6>
                    <h6>
                      Address:  <span className="text-muted">
                        {customer_details?.address}
                      </span>
                    </h6>
                  </div>
                </Col>
                {/* <Col md={6}>
                  <div class="common-busniess-box">
                    <h6>
                      City :  <span className="text-muted">
                        {customer_details?.city}
                      </span>
                    </h6>

                    <h6>
                      State :  <span className="text-muted">
                        {customer_details?.state}
                      </span>
                    </h6>

                    <h6>
                      Country :  <span className="text-muted">
                        {customer_details?.country}
                      </span>
                    </h6>

                    <h6>
                      Zipcode:  <span className="text-muted">
                        {customer_details?.zipcode}
                      </span>
                    </h6>
                  </div>
                </Col> */}
              </Row>
            </div>
          </div>
          <hr />
          <div className="order-customer-details">
            <h5>
              Services
            </h5>
            <div className="order-services-table-view">
              <div class="container px-2">
                <div class="slaylewks-table tabel-responsive">
                  <table class="table table-borderless tabel-responsive">
                    <thead>
                      <tr>
                        <th scope="col" class="table-head">
                          <h5>Category</h5>
                        </th>
                        <th scope="col" class="table-head">
                          <h5>Service name</h5>
                        </th>
                        <th scope="col" class="table-head">
                          <h5>Duration</h5>
                        </th>
                        <th scope="col" class="table-head">
                          <h5>Price</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="order-tabel-view">

                      {
                        services && services.length > 0 ?
                          (
                            services.map((item, index) => {
                              let category = item?.service_category?.map((item) => item.servicecategory_name)?.join(" ")
                              return (
                                <tr  key={index}>
                                  <td>{category}</td>
                                  <td class="sss">{item?.service_name}</td>
                                  {/* <td>{item?.services?.length}</td> */}
                                  <td>{item?.duration["$numberDecimal"]} mins</td>
                                  <td>₹ {item?.service_price["$numberDecimal"]}</td>
                                  {/* <td><BsThreeDotsVertical /></td> */}

                                </tr>
                              );
                            })
                          )
                          :
                          (
                            <tr>
                              <td>
                                No Records Found
                              </td>

                            </tr>
                          )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default OrderView