import axios from 'axios';
import { CART_CONFIG } from '../../configs';
import { REMOVE_SERVICE_FROM_CART } from './types';

export function authHeader() {
    let token = localStorage.getItem('access');

    if (token) {
        return { 'x-access-token': `${token}` };
    } else {
        return {};
    }
}

export const createCartServices = (data) => axios.post(CART_CONFIG.createCartApi, data);
export const ReadCartServices = (data) => axios.post(CART_CONFIG.createReadApi, data);
export const deleteCartServices = (data) => axios.post(CART_CONFIG.deleteCartAPI, data);
export const deleteCartManyServices = (data) => axios.post(CART_CONFIG.deleteCartManyAPI, data);
export const getAllCouponCodes = (data) => axios.post(CART_CONFIG.getAllCouponCodes, data);
export const getApplyCouponCodes = (data) => axios.post(CART_CONFIG.getApplyCouponCodes, data);



const data = {
    createCartServices,
    getApplyCouponCodes,
    ReadCartServices,
    deleteCartServices,
    deleteCartManyServices,
    getAllCouponCodes,
}

export default data