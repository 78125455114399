import React, { useEffect, useState } from 'react';
import useGeolocation from "react-hook-geolocation";
import Autocomplete from "react-google-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { Col, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { locationValidation } from '../../validations/yub_schemas/locationValidation';
import Geocode from "react-geocode";
import { useGeolocated } from "react-geolocated";
import { locationDetailsAction } from '../../redux/location/action';
import { IoMdArrowRoundForward } from "react-icons/io";
import { clearHomeSearch, exploreAction } from '../../redux/business/actions';

export default function LocationSearch(props) {
  

  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [status, setStatus] = useState(null);
  const LOCATION_GOOGLE_KEY = "AIzaSyCfvv90wDraInr6DLJqa2KJ8-4VC_RALx8";
  const dispatch = useDispatch();
  
  useEffect(async()=>{
    if (navigator.geolocation) {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition(async(position) => {
       
        setStatus(null);
       await  setLat(position.coords.latitude);
       await setLng(position.coords.longitude);

      }, () => {
        setStatus('Unable to retrieve your location');
      });
    } else {
      setStatus('Geolocation is not supported by your browser');

    }

  },[lat, lng]);
 



  const formik = useFormik({
    initialValues: {
      location: "",
    },
    validationSchema: locationValidation,
    onSubmit: (values) => {
      dispatch(locationDetailsAction(values))
      props.closeSearch();
    },
  });



  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    formik.setFieldValue("location", value)
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      dispatch(locationDetailsAction(e.values))
      props.closeSearch()
    }
  }



  const GetLocation = async () => {
    if (navigator.geolocation) {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition(async(position) => {
        setStatus(null);
       await  setLat(position.coords.latitude);
       await setLng(position.coords.longitude);

      }, (error) => {
        console.log("position====>",error)
        setStatus('Unable to retrieve your location');
      });
    } else {
      setStatus('Geolocation is not supported by your browser');

    }
    await getMyLocation();

  }


  const getMyLocation = async() => {
   await Geocode.setApiKey(LOCATION_GOOGLE_KEY);
   console.log("lat==>",lat,lng,status)
    await Geocode.fromLatLng(lat, lng).then(
      (response) => {
        console.log("res==>",response)
        const address = response.results[0].formatted_address;

        formik.setFieldValue("location", address)
      },
      (error) => {
        console.error(error);
      }
    );
  };



  const coimbatoreChennai = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(11.045444, 77.060466),
    new window.google.maps.LatLng(13.086612, 80.238139));


  // const searchOptions = {
  //   bounds: coimbatoreChennai,
  //   types: ['address'],
  //   componentRestrictions: { country: 'in' },
  // }
  const searchOptions = {
    // bounds: omanBounds, // Replace omanBounds with the desired bounds for Oman
    types: ['address'],
    componentRestrictions: { country: 'om' }, // Update country code to 'om' for Oman
  };




  return (
    <div>
      <Modal className='location-initial' show={props.info} onHide={props.closeSearch} >
      <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='location-modal-style'>
          <div className='location-modal-body'>
            <p className='text-center mt-3'>Select Location</p>
            <form
              onSubmit={formik.handleSubmit}
            >
              <PlacesAutocomplete
                name="location"
                value={formik.values.location}
                onChange={(e) => formik.setFieldValue("location", e)}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                  

                    <div className='location-searchBar-outer mt-5'>
                      <Row>
                        <Col md={1} xs={1} sm={1}>
                          <span className='searchIcon-location'>
                            <ion-icon name="search-outline" ></ion-icon>

                          </span>
                        </Col>
                        <Col md={10} sm={10} xs={10}>
                          <input className='locationAuto' {...getInputProps({ placeholder: "Enter your city" })} onKeyDown={handleKeyDown} />
                        </Col>
                        <Col md={1} xs={1} sm={1}>
                          <button type="submit" className='location-enter-btn'>
                            <IoMdArrowRoundForward />
                          </button>
                        </Col>
                      </Row>
                    </div>

                    <div className='get-location-container mt-2 px-2'>
                      <div className='row getLocation-icons'>

                        <div className='col-12 getLocation-icons'>
                          <span><ion-icon name="locate-outline"></ion-icon></span>
                          <h6 onClick={GetLocation}>Get my location</h6>
                        </div>
                      </div>

                      <h5>Location suggestion</h5>
                    </div>

                    <div className='sugeestion-container px-2'>
                      {loading ? <div className='loading-location'>...loading</div> : null}

                      {suggestions.map(suggestion => {
                        return (
                          <div {...getSuggestionItemProps(suggestion)} key={suggestion?.index} className="suggestion-places">

                            {suggestion.description}
                            <hr className='m-0' />
                          </div>
                        );
                      })}
                    </div>


                  </div>

                )}
              </PlacesAutocomplete>
  {/* <div className='w-100'>
  <Autocomplete
  className='w-100'
  apiKey={LOCATION_GOOGLE_KEY}
  onPlaceSelected={(place) => {
    console.log(place);
  }}
/>
  </div> */}
            </form>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  )
}
