import React from 'react';
import OrderList from './OrderList';
import { businessSidebar } from "../../../configs";
import DashboardHeader from '../DashboardHeader';
import { Link } from "react-router-dom";
import BusinessSidebar from "../../../components/elements/siderbar";
import { Col, Row } from 'react-bootstrap';
import OrderRequestList from './orderRequestList';

const OrderRequest = () => {
    return (
        <div className="dashboard-page">
        <DashboardHeader />
        <div className="dashboard-body">
          <div className="">
            <Row>
              <Col className='p-0' md={2} sm={12}>
              <div className="sidebar-content">
              <BusinessSidebar />
            </div>
              </Col>
            <Col md={10} sm={12}>
              <div className="main-content">
              <OrderRequestList />
            </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
}

export default OrderRequest;
