import React, { useEffect, useState, useRef } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import { useDispatch, useSelector } from 'react-redux';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { getAllAppointments, getOneAppointment } from "../../../redux/dashboard/actions";
import AppointmentView from './AppointmentView';
import { Row, Col } from "react-bootstrap"

function CalendarComponent() {

    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [viewOpen, setViewOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState({});

    const { vendor } = useSelector((state) => state.business);
    const { appointments } = useSelector((state) => state.dashboard);


    const calendarRef = useRef();
    const dispatch = useDispatch();

    let todayStr = new Date().toISOString().replace(/T.*$/, '')


    useEffect(() => {
        const data = { id: vendor._id }

        dispatch(getAllAppointments(data))
            .then((res) => {
                const { data } = res;
                const events = data.map((item, index) => {
                    let res = {
                        id: index + 1,
                        title: "Test",
                        start: new Date().toISOString()
                    }
                    return res;
                })

            })

    }, [vendor]);

    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    const handleEventClick = (clickInfo) => {
        let { event } = clickInfo;

        if (event) {
            let { _def } = event
            if (_def) {
                let data = {
                    id: _def?.extendedProps?._id
                }
                dispatch(getOneAppointment(data))
                    .then((res) => {
                        setSelectedAppointment(res)
                    })
                    .catch((err) => {
                        console.log("Err")
                    })

            } else {
                console.log("_def not found");
            }
        }
        else {
            console.log("No event found")
        }

        setViewOpen(true);
    }

    const handleEvents = (events) => {
        // this.setState({
        //     currentEvents: events
        // })
        // setCurrentEvents(events)
    }

    const renderEventContent = (eventInfo) => {
        console.log("Event info => ", eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        )
    }

    const renderSidebarEvent = (event) => {
        return (
            <li key={event.id}>
                <b className="me-3">{formatDate(event.date, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
                <i>{event.title}</i>
            </li>
        )
    }

    return (
        <div className='demo-app'>
           <div className='container'>
           <Row>

<AppointmentView
    show={viewOpen}
    onHide={() => setViewOpen(false)}
    order={selectedAppointment}
/>
<Col md={12}>
    <div className='demo-app-main'>
    <div className="service-list-header-option mt-2 mb-3">
            <h3 className='marketing-header'> Your Appointments</h3>
            </div>
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={calendarRef}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            events={appointments}
            // select={handleDateSelect}
            // eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
        // validRange={{
        //     start: new Date(),
        //     end: null
        // }}
        />
    </div>
</Col>
</Row>
           </div>
        </div>
    )
}

export default CalendarComponent