import React, { useEffect, useState, useContext } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container, Breadcrumb } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction, getUserOneRequestAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import { MdOutlineNavigateNext } from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import { requestAcceptDenyAction } from '../../../redux/dashboard/actions';








function RequestCancellation() {

    const { id } = useParams("");

    useEffect(() => {
        let data = {
            id: id,
        }
        dispatch(getUserOneRequestAction(data))
            .then((res) => {
                console.log(res, "request")
                setOrderDetails(res)

            })
            .catch((err) => {
                console.log(err)
            })

    }, [id]);

    const [searchParams] = useSearchParams();

    const [orderDetails, setOrderDetails] = useState({})
    const [loading, setLoading] = useState(false)

    const type = searchParams.get("type");

    const navigate = useNavigate();
    const dispatch = useDispatch();




    const redirectPaymentDetails = (type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectReschedule = () => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    const navigateTerms = () => {
        navigate('/term-of-use')
    }



    const formik = useFormik({
        initialValues: {
            id: id,
            request_status:"cancelled",   
            cancellation_reason: '',
            cancellation_comments: '',
        },
        onSubmit: (values) => {
            const data = {
                id: id,
                request_status:"cancelled",
                cancellation_reason: values.cancellation_reason,
                cancellation_comments: values.cancellation_comments,
        }  
        dispatch(requestAcceptDenyAction(data))
        .then(() => {
             navigate(`/request/successfully/cancelled/${id}`)
        })
    }
    });





    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }




    const redirectToRating = (id) => {
        navigate(`/business/${id}`);

    }
    const goToHome = () => {
        navigate("/")
    }

    return (
        <div className="payment-success">
            <Header />
            <section className='pb-0 mt-0 pt-0'>

                <div className='container-fluid' id='thanks-note-outer'>
                    <Row>
                        <Col md={12}>
                            <div>
                                <div>

                                    <Container className='mt-5'>
                                        <div className='payment-sub-cards'>
                                            <Row>
                                                <Col md={7}>
                                                    <Row>
                                                        <Col md={10}>
                                                            <Card className='cancel-card-inner'>
                                                                <Form onSubmit={formik.handleSubmit}>
                                                                    <FormikProvider value={formik}>
                                                                        <Card.Header className='cancel-card-header'>
                                                                            <h1 className='mt-2'>Easy Cancellation</h1>
                                                                        </Card.Header>
                                                                        <Card.Body className='cancel-body-inner'>
                                                                            <div class="card-body">
                                                                                <div class="mb-3">
                                                                                    <Form.Label className="">Reason for cancellation *</Form.Label>
                                                                                    <Form.Select
                                                                                        name="cancellation_reason"
                                                                                        placeholder="Reason"
                                                                                        onChange={formik.handleChange}
                                                                                        value={formik.values.cancellation_reason}
                                                                                    >
                                                                                        <option>Select Reason for cancellation</option>
                                                                                        <option value="Concerned about service quality/hygiene">Concerned about service quality/hygiene</option>
                                                                                        <option value="Found another service provider in Slaylewks">Found another service provider in Slaylewks</option>
                                                                                        <option value="Found another service provider outside Slaylewks">Found another service provider outside Slaylewks</option>
                                                                                        <option value="Booked by mistake">Booked by mistake</option>
                                                                                        <option value="Change of plans">Change of plans</option>
                                                                                        <option value="Others">Others</option>
                                                                                    </Form.Select>
                                                                                </div>
                                                                                <div class="mb-3">
                                                                                    <Form.Label >Comment</Form.Label>
                                                                                    <div class="select-content">
                                                                                        <textarea class="form-control common-text" rows="5"
                                                                                            name='cancellation_comments'
                                                                                            placeholder="Message"
                                                                                            onChange={formik.handleChange}
                                                                                            value={formik.values.cancellation_comments}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        </Card.Body>
                                                                        <Row className='mx-2 mb-3'>
                                                                            <button class="proceed-cancel" type='submit'>Proceed to cancel</button>
                                                                        </Row>

                                                                    </FormikProvider>
                                                                </Form>
                                                            </Card>
                                                            <div className='refund-note'>
                                                                <p>By clicking on “Request Cancellation”, I agree to <span onClick={navigateTerms}>Terms and Conditions</span> of Refund.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={5}>
                                                    <Row className='mb-3'>
                                                    <Row className='payment-order-info'>
                                                <Col>
                                                <p className='payment-info-text'>Request Details</p>
                                                </Col>
                                                <Col>
                                                <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                                </Col>
                                               
        
                                            </Row>
                                                        <Col md={12}>
                                                            <Card className='cancel-request-card mb-0'>
                                                                <Card.Body>
                                                                    <Row className=''>
                                                                        <Col md={6} sm={12}>
                                                                            <p>Appointment Date / Time</p>
                                                                            <p>Service type</p>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                           <div className='text-end'>
                                                                           <p>{moment(orderDetails?.results?.selected_date).local().format("DD MMM YY hh:mm A")}</p>
                                                                            <p>Home Service</p>
                                                                           </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>

                                                    </Row>
                                                    <Card className='mb-4'>
                                                        <Card.Header className='bg-white'>
                                                            <Row className='mb-2'>
                                                                <Col md={2} sm={12} xs={12}>
                                                                    <img className='payment-info-image' src={orderDetails?.results?.service_provider?.businesslogoURL} />
                                                                </Col>
                                                                <Col className='payment-orderInfo-details' md={10} xs={12} sm={12}>
                                                                    <h6 className=''>{orderDetails?.results?.service_provider?.business_name}</h6>
                                                                    <p className='mt-2'>{orderDetails?.results?.service_provider?.location_details?.address + "" + orderDetails?.results?.service_provider?.location_details?.area + ", " + orderDetails?.results?.service_provider?.location_details?.city + ", " + orderDetails?.results?.service_provider?.location_details?.state + " " + orderDetails?.results?.service_provider?.location_details?.zipcode}</p>

                                                                </Col>

                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Header className='bg-white'>
                                                            <Row>
                                                                {
                                                                    orderDetails?.results?.services && orderDetails?.results?.services.length > 0 && (
                                                                        orderDetails?.results?.services?.map((item, index) => {
                                                                            let price = item?.service_price?.["$numberDecimal"];
                                                                            let servicePrice = Math.round(price);

                                                                            return (
                                                                                <div key={index}>
                                                                                    <Row className='mb-2'>

                                                                                        <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                                            <span>{item?.gender?.[0]?.gender_name}</span>
                                                                                            <p class="service-heading">{item?.service_name}</p>
                                                                                            <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                                        </Col>
                                                                                        <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                                            <div className='cart-check-out-amount'>

                                                                                                <p className='mt-4'>{servicePrice + ".00" + " ₹ "}</p>
                                                                                            </div>
                                                                                        </Col>

                                                                                    </Row>
                                                                                    <hr />

                                                                                </div>

                                                                            );
                                                                        })
                                                                    )
                                                                }
                                                            </Row>
                                                        </Card.Header>

                                                    </Card>
                                                    <Row>
                                                        <div>
                                                            <h1>Price Details</h1>
                                                            <Card>

                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='payment-total-card-inner'>
                                                                                <p>Price</p>
                                                                                <p>GST 18%</p>
                                                                                <p>Discount</p>
                                                                                <p>Coupon for you</p>
                                                                                <h1>Grand Total</h1>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className='float-end payment-total-card-inner'>
                                                                                <Row>
                                                                                    <Col className='text-end px-0'>
                                                                                    <p>{Math.round(orderDetails?.results?.original_price) + ".00"}</p>
                                                                                    <p className=''>{Math.round(orderDetails?.results?.gst_value) +".00"}</p>
                                                                                        <p className='green_text'>{Math.round(orderDetails?.results?.discount)}</p>
                                                                                        <p className='green_text'>{(orderDetails?.results?.coupon)/100 + ".00"}</p>
                                                                                        <h1>{Math.round(orderDetails?.results?.final_price) + ".00"}</h1>
                                                                                    </Col>
                                                                                    <Col className='Payment-amount-inner'>
                                                                                        <p>₹</p>
                                                                                        <p>₹</p>
                                                                                        <p>₹</p>
                                                                                        <p>₹</p>
                                                                                        <h1>₹</h1>
                                                                                    </Col>
                                                                                </Row>

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <div>
                                                            <h1>Contact Details</h1>
                                                            <Card>
                                                                <Card.Body>
                                                                    <div className='payment-user-contact-info'>
                                                                        <p className='mb-0 pb-0'>{orderDetails?.results?.alternate_name}</p>
                                                                        <p className='mt-0 pt-0'>{orderDetails?.results?.address + ", " + orderDetails?.results?.city + ", " + orderDetails?.results?.state + ", " + orderDetails?.results?.country + ", " + orderDetails?.results?.zipcode}</p>
                                                                        <h5>{orderDetails?.results?.alternate_phone}</h5>
                                                                        <h5>{orderDetails?.results?.alternate_email}</h5>
                                                                        <h5>{orderDetails?.results?.message}</h5>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <div className='payment-sucess-need-help'>
                                                            <h1>Need Help?</h1>




                                                            <div className='d-flex'>
                                                                <h2 className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></h2>
                                                                <h2 onClick={redirectSupport}>Support Center</h2>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Container>
                                </div>




                            </div>



                        </Col>
                    </Row>
                </div>





            </section>
            <div className="mt-5">
                {
                    type === "view" && (
                        <div>
                            <Footer />
                        </div>
                    )
                }
                {
                    type !== "view" && (
                        <div>
                            <CartFooter />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default RequestCancellation