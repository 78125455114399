import React from 'react';
import GalleryList from './GalleryList';
import DashboardHeader from '../DashboardHeader';
import BusinessSidebar from '../../../components/elements/siderbar';
import {Row, Col} from "react-bootstrap"


function GalleryPage() {
    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                   <Row>
                    <Col className='p-0' md={2} sm={12}>         
                    <div className="sidebar-content">
                        <BusinessSidebar />
                    </div>        
                </Col>
                    <Col md={10} sm={12}>
                    <div className="main-content">
                        <GalleryList />
                    </div>
                    </Col>
                   </Row>
                </div>
            </div>
        </div>
    )
}

export default GalleryPage