import * as Yup from 'yup';

export const supportValidation = Yup.object({
    support_type:Yup.string(),
    
    order_id: Yup.string().when(['support_type'],{
        is: "service",
        then: Yup.string('order Id  is required').required("please select your order id"),
        otherwise: Yup.string().notRequired(),
    }),
    message: Yup.string('')
    .min(30, 'Hoo! Its too Short!')
     .max(250, 'Hoo! Its too Long!')
     .required('Message is required to submit'),
     usage_limit: Yup.string().when(['usage_limit_type'], {
        is: "limited",
        then: Yup.string("Please enter in numbers").required('Usage limit is required').matches(/^\d+$/,"Please enter limit only in numbers"). max(4, 'must be below 10000'),
        otherwise: Yup.string(),
    }),
   
});
