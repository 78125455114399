import { TypeStrikethrough } from 'react-bootstrap-icons';
import * as TYPES from './types.js';

const initialState = {
    date: "",
    slotDetails: {},
    appointmentDetails: {},
    orderDetails: {},
    userLocationDetails: {},

};


const userReducer = (state = initialState, action) => {
    const { type, payload} = action;
    switch (type) {
       
        case TYPES.GET_ALL_SLOT_DETAILS:
            return {
                ...state,
                slotDetails: payload.slot,
                date: payload.date
            };
        case TYPES.GET_ALL_SLOT_DETAILS_FAIL:
            return {
                ...state,
                slotDetails: {},
                date: ""
            };
        case TYPES.CLEAR_SLOT_DETAILS:
            return {
                ...state,
                slotDetails: {},
                date: ""
            };

        case TYPES.ADD_APPOINTMENT_DETAILS:
            return {
                ...state,
                appointmentDetails: payload,

            };
        case TYPES.ADD_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: payload,

            };
        case TYPES.ADD_USER_LOCATION_DETAILS:
            return {
                ...state,
                userLocationDetails: payload,

            };
        case TYPES.CLEAR_APPOINTMENT_DETAILS:
            return {
                ...state,
                appointmentDetails: {},

            };
        case TYPES.CLEAR_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: {},
            };
        case TYPES.CLEAR_ALL_USER_ORDER_DETAILS:
            return {
                ...state,
                date: "",
                slotDetails: {},
                appointmentDetails: {},
                orderDetails: {},
                userLocationDetails: {}
            };
        default:
            return state;
    }
};

export default userReducer;
