import React, { useEffect, useState } from 'react';
import EmptyCartImg from "../../assets/images/empty-cart.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, clearCart, clearServiceProvider, clearCartAction } from '../../redux/cart/actions';

function EmptyCart() {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { servicesCart } = useSelector((state) => state.cart);

    const explorePageRedirect = () => {
        navigate("/explore")
    }

    useEffect(() => {
        // dispatch(clearCartAction({}))
        dispatch(clearServiceProvider({}));
    }, [])

    return (
        <div className="empty-cart">
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="cart-empty">
                                <div class="empty-cart-inner">
                                    <img src={EmptyCartImg} alt="cart-empty" class="empty-cart-image" />
                                </div>
                                <div>
                                    <p className='empty-cart-title'>Your cart is empty</p>
                                    <p className='empty-cart-sub-title'>Looks like you haven’t added anything to your cart yet</p>
                                </div>

                                <a href="JavaScript:void(0);" class="cart-explore" onClick={explorePageRedirect}>Start Explore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EmptyCart