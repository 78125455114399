import * as Yup from 'yup';

export const serviceInformationValidation = Yup.object({

    service_name: Yup.string('Enter the service name').required('service name is required'),
    type: Yup.string('please select service type').required('service type is required'),

    service_description: Yup.string('Enter the service description')
        .max(250, 'Description should be less than 250 characters')
        .required('service description is required'),
    
    duration: Yup.number('Enter the duration').required('duration is required'),
    service_discount:Yup.number('Enter the discount'),

    service_price: Yup.string('Enter the service price').required('service price is required'),

    // stylist_category: Yup.string('Enter the stylish category').required('stylish category is required'),

    is_active: Yup.string('Enter the service active').required('service active is required'),

    gender: Yup.string('Enter the gender category').required('gender category is required'),

    service_category: Yup.string('Enter the service category').required('service type is required'),
});
