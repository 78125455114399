import * as Yup from 'yup';



export const bankInformationValidation = Yup.object({
        bank_username: Yup.string('Enter your account name')
                .matches(/^[A-Za-z\s]{1,}[\. ,]{0,1}[A-Za-z\s]{0,}$/, "Please enter valid account name")
                .required('Account name is required')
                .min(3, 'Hoo! Its too Short!')
                .max(120, 'Hoo! Its too Long!'),
        bank_account_number: Yup.string('Enter your account number').matches(/^\d+$/, "Account no should be digits only").required('Account number is required'),
        confirm_account_number: Yup.string('Enter account number confirmation')
                .required('Account no confirmation is required')
                .matches(/^\d+$/, "Account no should be digits only")
                .oneOf([Yup.ref('bank_account_number'), null], 'Account no must match'),
        bank_ifsc: Yup.string('Enter your bank branch').required('Branch name is required'),

});
