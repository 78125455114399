import React from 'react';
import Header from '../../components/elements/header/Header';
import { Container, Row, Col } from 'react-bootstrap';
import aboutOne from "../../assets/images/home/about1.webp"
import aboutTwo from "../../assets/images/home/about2.webp"
import aboutThree from "../../assets/images/home/about3.webp"
import Footer from '../../components/elements/footer/Footer';




export default function AboutUs() {
  return (
    <div>
       <Header />
       <Container>
        <Row className='mt-5 about-one-outer'>
            <Col md={6}>
            <div className='about-one-inner'>
            <h4>About Us</h4>
             <p className='mt-3'><span className='para-bold-text'>SLAYLEWKS</span> 	is your one stop shop for all your beauty services related queries.
                This discovery and review website will enable users to discover their next favourite hairstylist /nail artist
                (or any beauty service provider) and make a booking with them in a jiffy.
                 One no longer has to jump around multiple platforms or social media handles 
                 to read reviews of beauty services - some of which are not even credible reviews.
                They can use slaylewks to hear views of fellow customers and make their next booking with confidence.
                We bring you ease and reliability when it comes to booking your next appointment. </p>
            </div>
            </Col>
            <Col md={6}>
              <div className='about-one-img'>
              <img src={aboutThree} className="img-fluid" />    
              </div>
            </Col>
        </Row>
        <Row className='mt-5 about-two'>
            <Col md={6}>
            <div className='about-two-img'>
              <img src={aboutTwo} className="img-fluid" />    
              </div>
            </Col>
            <Col md={6}>
                <div className='about-two-left'>
                    <h4>For Customers</h4>
                    <ul>
                        <li><p>Discover your favorite Salon and Stylist</p> </li>
                        <li><p>Compare Services and Prices</p></li>
                        <li><p>Make a Booking in under 3 mins</p></li>
                        <li><p>Skip the Queue (Zero Wait time)</p></li>
                    </ul>
                </div>
            </Col>
        </Row> 
        <Row className='mt-5 mb-5 about-three-outer'>
            <Col md={6}>
            <div className='about-three-left'>
                    <h4>For Business</h4>
                    <ul>
                        <li><p>Showcase your work Digitally</p> </li>
                        <li><p>Business Dashboard – The Power of data</p></li>
                        <li><p>Manage your Bookings online</p></li>
                        <li><p>Receive feedback and rating directly from customers</p></li>
                    </ul>
                </div>
            </Col>
            <Col md={6}>
            <div className='about-three-img'>
              <img src={aboutOne} className="img-fluid" />    
              </div>
            </Col>
            
        </Row>
        
       </Container>
       <Footer />
    </div>
  )
}
