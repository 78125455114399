
const getGst = (amount) => {

    return amount * 18 / 100 ;
}

const coupnsCal=(couponobj,service_price)=>{
    let couponAmount= couponobj.couponCodeName ?couponobj.couponReducedValue:0
     let calculationAmount=couponobj.adminVerification? service_price : service_price-couponAmount
    return getGst(calculationAmount)

}

export const getCartAmounts = (cart = [], provider = {},couponobj={}) => {


    let service_original_price = 0;
    let discountPrice = 0;
    let coupon = cart?.coupon_amount || 0;
    let service_price=0;
    let categoryFilter_services= []

    cart.forEach((item) => {

        let discount = parseInt(item?.service_original_price?.['$numberDecimal']) * parseInt(item?.service_discount?.['$numberDecimal']) / 100
        service_price += parseInt(item?.service_price?.['$numberDecimal']);
        service_original_price+=parseInt(item?.service_original_price?.['$numberDecimal']);
        // if(item?.service_discount?.['$numberDecimal'] == 0){
        //     categoryFilter_services.push({service_category:item?.service_category[0]._id, service_price: parseInt(item?.service_price?.['$numberDecimal']), service_original_price:parseInt(item?.service_original_price?.['$numberDecimal'])})
        // }
        if(item?.service_category){
            categoryFilter_services.push({service_category:item?.service_category[0]?._id,service_discount:parseInt(item?.service_discount?.['$numberDecimal']), service_price: parseInt(item?.service_price?.['$numberDecimal']), service_original_price:parseInt(item?.service_original_price?.['$numberDecimal'])})
        }

        if(isNaN(discount)){
            discountPrice += parseInt(0)
        }else{
            discountPrice += discount;
        }
    });

    let gstAmount = provider?.is_GST_checked ? coupnsCal(couponobj,service_price) : 0;

    let total = service_price + gstAmount  - couponobj.couponReducedValue;
 
    return {
        service_price,
        service_original_price,
        gstAmount,
        discountPrice,
        coupon,
        total,
        categoryFilter_services
    };

}
