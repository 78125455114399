import React, { useState } from 'react';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Container,
    Row,
    Col
} from "react-bootstrap";
import ServiceImage from "../../../assets/images/vendor_icons/service-register.svg";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import DashboardHeader from '../DashboardHeader';
import { businessSidebar } from "../../../configs";
import { Link } from "react-router-dom";
import { createStaff } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { staffInformationValidation } from "../../../validations/yub_schemas/staffInformationValidation";
import BusinessSidebar from '../../../components/elements/siderbar';

function StaffAddEdit() {

    const iconStyle = { color: "#DC3545", fontSize: "1.5em", cursor: "pointer" };
    const iconBtnStyle = { color: "#FFF", fontSize: "1em", cursor: "pointer" };

    const dispatch = useDispatch();


    const [timeSlot, setTimeSlot] = useState(
        [
            {
                open_at: "",
                close_at: ""
            }
        ]
    );

    const addTimeSlot = () => {

        setTimeSlot([...timeSlot, { open_at: "", open_at: "" }])
    }
    const remoteTimeSlot = (index) => {
        let newFormValues = [...timeSlot];
        newFormValues.splice(index, 1);
        setTimeSlot(newFormValues)
    };

    const formik = useFormik({
        initialValues: {
            age_category: [],
            service_category: [],
            staff_basic_hours: [{
                'open_at': "",
                'close_at': ""
            }],
            staff_working_days: "",
            staff_timings: [],
            stylist_name: "",
            mobile_no: "",
            stylist_category: "",
            is_active: "",

        },
        validationSchema: staffInformationValidation,
        onSubmit: (values) => {


            values["staff_working_days_hours"] = values["staff_working_days"].map((day, index) => {
                let data = {};
                data[day] = { ...values['staff_basic_hours'].map(item => item) }
                return (data);
            })

            // values["serviceTypes"] = values["business_type"];
            values["gender_category"] = values["age_category"];

            dispatch(createStaff(values));

        },
    });

    const handleCheckboxChange = (e) => {
        const { checked, value, name } = e.target;
        if (checked) {
            formik.setFieldValue(name, [...formik.values[name], value]);
        } else {
            formik.setFieldValue(
                name,
                formik.values[name].filter((v) => v !== value)
            );
        }
    };


    const handleTimeChange = (e, index, type) => {
        const { value, name } = e.target;
        const currObject = formik.values.staff_basic_hours[index];
        formik.values.staff_basic_hours[index] = { ...currObject }
        formik.setFieldValue(name, value);
    };

    return (

        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <div className="sidebar-content p-0">
                        <BusinessSidebar />
                    </div>
                    <div className="main-content">
                        <div className="staff-details-update mt-5">
                            <Row>
                                <Col md="8">
                                    <Form noValidate className="service-register-form text-start px-5" onSubmit={formik.handleSubmit}>
                                        <FormikProvider value={formik}>
                                            <div className="my-5">
                                                <h4>Staffs</h4>
                                            </div>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3 p-2" controlId="stylist_name">
                                                        <Form.Label className="required">Stylish Name</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Stylish Name"
                                                            name="stylist_name"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.stylist_name}
                                                            isInvalid={!!formik.touched.stylist_name && !!formik.errors.stylist_name}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.stylist_name}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="mb-3 p-2" controlId="mobile_no">
                                                        <Form.Label className="required">Phone Number</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Phone number"
                                                            name="mobile_no"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.mobile_no}
                                                            isInvalid={!!formik.touched.mobile_no && !!formik.errors.mobile_no}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.mobile_no}
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3 p-2" controlId="stylist_category">
                                                        <Form.Label className="required">Stylish Type</Form.Label>
                                                        <Form.Select
                                                         name="stylist_category"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.stylist_category}
                                                            isInvalid={!!formik.touched.stylist_category && !!formik.errors.stylist_category}
                                                        >
                                                            <option value="premimum">Premimum</option>
                                                            <option value="professional">Professional</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.stylist_category}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3 p-2" controlId="age_category">
                                                        <Form.Label className="required">Age Category</Form.Label>
                                                        <Form.Select
                                                            name="age_category"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.age_category}
                                                            isInvalid={!!formik.touched.age_category && !!formik.errors.age_category}
                                                        >
                                                            <option value="Men">Men</option>
                                                            <option value="Women">Women</option>
                                                            <option value="Kids">Kids</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.age_category}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3 p-2" controlId="is_active">
                                                        <Form.Label className="required">Status</Form.Label>
                                                        <Form.Select
                                                            name="is_active"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.is_active}
                                                            isInvalid={!!formik.touched.is_active && !!formik.errors.is_active}
                                                        >
                                                            <option value="true">Active</option>
                                                            <option value="false">In Active</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.is_active}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className="mt-5 mb-3">
                                                <h4 className="required">Select options which best describe your Staff Services</h4>
                                                <small className="text-muted">Select all types services your staff provide</small>
                                                <div className="my-4">
                                                    <h4 className="text-muted">
                                                        Selected:
                                                    </h4>

                                                    {
                                                        formik.values.service_category.map((item, index) => {
                                                            return (
                                                                <span key={index} className="badge bg-primary me-2 px-2">
                                                                    {item}
                                                                </span>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                            <Form.Group className="mb-3 p-2" controlId="serviceCategory">
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Check
                                                            className="mb-3"
                                                            type='checkbox'
                                                            id='Hair'
                                                            name="service_category"
                                                            label="Hair"
                                                            value="Hair"
                                                            checked={formik.values.service_category.includes("Hair")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                        <Form.Check
                                                            className="mb-3"
                                                            type='checkbox'
                                                            id='Nails'
                                                            name="service_category"
                                                            label="Nails"
                                                            value="Nails"
                                                            checked={formik.values.service_category.includes("Nails")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                        <Form.Check
                                                            type='checkbox'
                                                            id='Spa'
                                                            name="service_category"
                                                            label="Spa"
                                                            value="Spa"
                                                            checked={formik.values.service_category.includes("Spa")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                    </Col>

                                                    <Col md={4}>
                                                        <Form.Check
                                                            className="mb-3"
                                                            type='checkbox'
                                                            id='Face'
                                                            name="service_category"
                                                            label="Face"
                                                            value="Face"
                                                            checked={formik.values.service_category.includes("Face")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                        <Form.Check
                                                            className="mb-3"
                                                            type='checkbox'
                                                            id='Makeup'
                                                            name="service_category"
                                                            label="Makeup"
                                                            value="Makeup"
                                                            checked={formik.values.service_category.includes("Makeup")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                        <Form.Check
                                                            type='checkbox'
                                                            id='Mehendi'
                                                            name="service_category"
                                                            label="Mehendi"
                                                            value="Mehendi"
                                                            checked={formik.values.service_category.includes("Mehendi")}
                                                            onChange={handleCheckboxChange}
                                                            isInvalid={!!formik.touched.service_category && !!formik.errors.service_category}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.service_category}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        <div className="my-5">
                                                <h4 className="required">Business operational hours</h4>
                                                <small className="text-muted">Mark Business opening and closing hours</small>
                                            </div>
                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningHours">
                                                <Row className="mb-2">
                                                    <Col md={4}>
                                                        <h5 className="text-muted">
                                                            Opens At
                                                        </h5>
                                                    </Col>
                                                    <Col md={4}>
                                                        <h5 className="text-muted">
                                                            Closes At
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                {
                                                    timeSlot.map((slot, index) => {
                                                        const fieldName = `staff_basic_hours[${index}]`;
                                                        return (
                                                            <div key={index}>
                                                                <Row className="mb-4">
                                                                    <Col md={3}>
                                                                        <Form.Control
                                                                            required
                                                                            type="time"
                                                                            format="hh:mm"
                                                                            name={`${fieldName}.open_at`}
                                                                            onChange={(e) => handleTimeChange(e, index, "open_at")}
                                                                            // value={formik.values.business_basic_hours}
                                                                            isInvalid={!!formik.errors.staff_basic_hours}
                                                                        />
                                                                    </Col>
                                                                    <Col md={1}>
                                                                        <h6 className="text-muted mt-2 ms-4">
                                                                            to
                                                                        </h6>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <Form.Control
                                                                            required
                                                                            type="time"
                                                                            format="hh:mm"
                                                                            name={`${fieldName}.close_at`}
                                                                            onChange={(e) => handleTimeChange(e, index, "close_at")}
                                                                            // value={formik.values.business_basic_hours}
                                                                            isInvalid={!!formik.errors.staff_basic_hours}
                                                                        />
                                                                    </Col>
                                                                    {
                                                                        index != 0 &&
                                                                        <Col md={1}>
                                                                            <BsTrash style={iconStyle} onClick={() => remoteTimeSlot(index)} />
                                                                        </Col>
                                                                    }
                                                            </Row>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Form.Group>
                                            <div className="mx-3">
                                                <p className="text-start text-primary add-time-slot" >
                                                    <BsPlusLg /><span className="ms-2" onClick={() => addTimeSlot()}>Add Time Slot</span>
                                                </p>
                                            </div>
                                            <div className="my-5">
                                                <h4 className="required">Mark open days</h4>
                                                <small className="text-muted">Don’t forget to uncheck your off-day</small>
                                            </div>
                                            <Form.Group className="mb-3 p-2" controlId="basicOpeningDays">
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Monday'
                                                    name="staff_working_days"
                                                    label="Monday"
                                                    value="Monday"
                                                    checked={formik.values.staff_working_days.includes("Monday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Tuesday'
                                                    name="staff_working_days"
                                                    label="Tuesday"
                                                    value="Tuesday"
                                                    checked={formik.values.staff_working_days.includes("Tuesday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Wednesday'
                                                    name="staff_working_days"
                                                    label="Wednesday"
                                                    value="Wednesday"
                                                    checked={formik.values.staff_working_days.includes("Wednesday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Thursday'
                                                    name="staff_working_days"
                                                    label="Thursday"
                                                    value="Thursday"
                                                    checked={formik.values.staff_working_days.includes("Thursday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Friday'
                                                    name="staff_working_days"
                                                    label="Friday"
                                                    value="Friday"
                                                    checked={formik.values.staff_working_days.includes("Friday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Saturday'
                                                    name="staff_working_days"
                                                    label="Saturday"
                                                    value="Saturday"
                                                    checked={formik.values.staff_working_days.includes("Saturday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                                <Form.Check
                                                    className="mb-3"
                                                    type='checkbox'
                                                    id='Sunday'
                                                    name="staff_working_days"
                                                    label="Sunday"
                                                    value="Sunday"
                                                    checked={formik.values.staff_working_days.includes("Sunday")}
                                                    onChange={handleCheckboxChange}
                                                    isInvalid={!!formik.touched.staff_working_days && !!formik.errors.staff_working_days}
                                                />
                                            </Form.Group>
                                            <div className="my-3">
                                                <h6>Want more options ? <a href="#">Apply advance settings</a></h6>
                                            </div>
                                            <div className="text-center my-5">
                                                <Row>
                                                    <Col md={4} className="d-grid mt-2">
                                                        <Button variant="outline-danger" size="lg">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                    <Col md={4} className="d-grid mt-2">
                                                        <Button variant="primary" type="submit" size="lg">
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </FormikProvider>
                                    </Form>
                                </Col>
                                <Col md={4} className="mt-5">
                                    <img src={ServiceImage} width="90%" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
          </div>
        </div>
    )
}

export default StaffAddEdit;