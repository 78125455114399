const apiUrl = process.env.REACT_APP_API_URL;

export const API_CONFIG = {
    signup: `${apiUrl}/auth/signup/`,
    verifySignup: `${apiUrl}/auth/signup/verify/`,
    verifyToken: `${apiUrl}/auth/token/verify`,
    login: `${apiUrl}/auth/signin/`,
    loginRefresh: `${apiUrl}/auth/login/refresh/`,
    forgotPassword: `${apiUrl}/auth/forgotpass`,
    forgotPasswordCode: `${apiUrl}/auth/password/reset/verify/`,
    verifiedForgotPassword: `${apiUrl}/auth/resetpass`,
    changePasswordProfile: `${apiUrl}/auth/changepassword`,
    changeProfileEmail: `${apiUrl}/auth/updateemail`,
    logout: `${apiUrl}/auth/logout/`,
    getUser: `${apiUrl}/user/profiles/`,
    updateProfile: `${apiUrl}/user/profiles/`,
    follow: `${apiUrl}/user/profiles/`,
    changeEmail: `${apiUrl}/user/email/change/`,
    verifyEmail: `${apiUrl}/auth/verifyotp/`,
    changePassword: `${apiUrl}/user/password/change/`,
    updateUser: `${apiUrl}/user/profile/update`,
    verifyRegisteredEmail: `${apiUrl}/auth/verifyemail`,
    verifyEmailProfile: `${apiUrl}/profile/verifyemail`,
    resendOtp: `${apiUrl}/auth/resendotp`,
    supportMessage: `${apiUrl}/usersupport/create`,
    BusinessSupportMessage: `${apiUrl}/support/create`,
    supportOrders: `${apiUrl}/support/orders`,
    

};
