import axios from 'axios';
import { DASHBOARD_CONFIG, VENDOR_CONFIG } from '../../configs';

export function authHeader() {
    let token = localStorage.getItem('access');

    if (token) {
        return { 'x-access-token': `${token}` };
    } else {
        console.log('not returning');
        return {};
    }
}

const dashboardStatus = (data) => axios.post(DASHBOARD_CONFIG.dashboardStatus, data, { headers: authHeader() });

const createService = (data) => axios.post(DASHBOARD_CONFIG.createService, data, { headers: authHeader() });
const updateService = (data) => axios.post(DASHBOARD_CONFIG.updateService, data, { headers: authHeader() });
const deleteService = (data) => axios.post(DASHBOARD_CONFIG.deleteService, data, { headers: authHeader() });
const deleteManyService = (data) => axios.post(DASHBOARD_CONFIG.deleteManyService, data, { headers: authHeader() });
const getOneService = (data) => axios.post(DASHBOARD_CONFIG.getService, data, { headers: authHeader() });
const getAllService = (data) => axios.post(DASHBOARD_CONFIG.getAllService, data, { headers: authHeader() });

const createAppointment = (data) => axios.post(DASHBOARD_CONFIG.createAppointment, data, { headers: authHeader() });
const updateAppointment = (data) => axios.post(DASHBOARD_CONFIG.updateAppointment, data, { headers: authHeader() });
const deleteAppointment = (data) => axios.post(DASHBOARD_CONFIG.deleteAppointment, data, { headers: authHeader() });
const getOneAppointment = (data) => axios.post(DASHBOARD_CONFIG.getAppointment, data, { headers: authHeader() });
const getAllAppointment = (data) => axios.post(DASHBOARD_CONFIG.getAllAppointment, data, { headers: authHeader() });


const createOrder = (data) => axios.post(DASHBOARD_CONFIG.createOrder, data, { headers: authHeader() });
const updateOrder = (data) => axios.post(DASHBOARD_CONFIG.updateOrder, data, { headers: authHeader() });
const deleteOrder = (data) => axios.post(DASHBOARD_CONFIG.deleteOrder, data, { headers: authHeader() });
const getOneOrder = (data) => axios.post(DASHBOARD_CONFIG.getOrder, data, { headers: authHeader() });
const getAllOrder = (data) => axios.post(DASHBOARD_CONFIG.getAllOrder, data, { headers: authHeader() });
const verifyOrderOtp = (data) => axios.post(DASHBOARD_CONFIG.verifyOrderOtp, data, { headers: authHeader() });

const getAllRequest = (data) => axios.post(DASHBOARD_CONFIG.getAllRequest, data, { headers: authHeader() });
const acceptDenyRequest = (data) => axios.post(DASHBOARD_CONFIG.acceptDenyRequest, data, {headers: authHeader()});
const getOneRequest = (data) => axios.post(DASHBOARD_CONFIG.getOneRequest, data, {headers: authHeader() });

const createStaff = (data) => axios.post(DASHBOARD_CONFIG.createStaff, data, { headers: authHeader() });
const updateStaff = (data) => axios.post(DASHBOARD_CONFIG.updateStaff, data, { headers: authHeader() });
const deleteStaff = (data) => axios.post(DASHBOARD_CONFIG.deleteStaff, data, { headers: authHeader() });
const getStaff = (data) => axios.post(DASHBOARD_CONFIG.getStaff, data, { headers: authHeader() });
const getAllStaff = (data) => axios.post(DASHBOARD_CONFIG.getAllStaff, data, { headers: authHeader() });


const updateDashboardSettings = (data) => axios.post(DASHBOARD_CONFIG.updateDashboardSettings, data, { headers: authHeader() });
const getAllDashboardSettings = (data) => axios.post(DASHBOARD_CONFIG.getAllDashboardSettings, data, { headers: authHeader() });

const adminDashboardStatus = (data) => axios.post(DASHBOARD_CONFIG.adminDashboardStatus, data, { headers: authHeader() });

const createUser = (data) => axios.post(DASHBOARD_CONFIG.createUser, data, { headers: authHeader() });
const updateUser = (data) => axios.post(DASHBOARD_CONFIG.updateUser, data, { headers: authHeader() });
const deleteUser = (data) => axios.post(DASHBOARD_CONFIG.deleteUser, data, { headers: authHeader() });
const getUser = (data) => axios.post(DASHBOARD_CONFIG.getUser, data, { headers: authHeader() });
const getAllUsers = (data) => axios.post(DASHBOARD_CONFIG.getAllUsers, data, { headers: authHeader() });


const getAllProposals = (data) => axios.post(DASHBOARD_CONFIG.getAllProposals, data, { headers: authHeader() });
const getProposalDetails = (data) => axios.post(DASHBOARD_CONFIG.getOneProposal, data, { headers: authHeader() });
const updateProposalDetails = (data) => axios.post(DASHBOARD_CONFIG.updateProposalDetails, data, { headers: authHeader() });
const updateProposal = (data) => axios.post(DASHBOARD_CONFIG.updateProposal, data, { headers: authHeader() });

const getAllBusiness = (data) => axios.post(DASHBOARD_CONFIG.getAllBusiness, data, { headers: authHeader() });

const getAllFreelancer = (data) => axios.post(DASHBOARD_CONFIG.getAllFreelancer, data, { headers: authHeader() });

const deleteServiceProvider = (data) => axios.post(DASHBOARD_CONFIG.deleteServiceProvider, data, { headers: authHeader() });

const getAllMarketing = (data) => axios.post(DASHBOARD_CONFIG.getAllMarketing, data, { headers: authHeader() });


const changeProposalStatus = (data) => axios.post(DASHBOARD_CONFIG.changeProposalStatus, data, { headers: authHeader() });
const addStaffs = (data) => axios.post(DASHBOARD_CONFIG.addStaffs, data, { headers: authHeader() });



const createCouponCode = (data) => axios.post(DASHBOARD_CONFIG.createCouponCode, data, { headers: authHeader() });
const updateCouponCode = (data) => axios.post(DASHBOARD_CONFIG.updateCouponCode, data, { headers: authHeader() });
const deleteCouponCode = (data) => axios.post(DASHBOARD_CONFIG.deleteCouponCode, data, { headers: authHeader() });
const getOneCouponCode = (data) => axios.post(DASHBOARD_CONFIG.getOneCouponCode, data, { headers: authHeader() });
const getAllCouponCode = (data) => axios.post(DASHBOARD_CONFIG.getAllCouponCode, data, { headers: authHeader() });


const createReferralCode = (data) => axios.post(DASHBOARD_CONFIG.createReferralCode, data, { headers: authHeader() });
const updateReferralCode = (data) => axios.post(DASHBOARD_CONFIG.updateReferralCode, data, { headers: authHeader() });
const deleteReferralCode = (data) => axios.post(DASHBOARD_CONFIG.deleteReferralCode, data, { headers: authHeader() });
const getOneReferralCode = (data) => axios.post(DASHBOARD_CONFIG.getOneReferralCode, data, { headers: authHeader() });
const getAllReferralCode = (data) => axios.post(DASHBOARD_CONFIG.getAllReferralCode, data, { headers: authHeader() });




const createResources = (data) => axios.post(DASHBOARD_CONFIG.createResources, data, { headers: authHeader() });
const updateResources = (data) => axios.post(DASHBOARD_CONFIG.updateResources, data, { headers: authHeader() });
const deleteResources = (data) => axios.post(DASHBOARD_CONFIG.deleteResources, data, { headers: authHeader() });
const findOneResource = (data) => axios.post(DASHBOARD_CONFIG.findOneResource, data, { headers: authHeader() });
const findAllresources = (data) => axios.post(DASHBOARD_CONFIG.findAllresources, data, { headers: authHeader() });



const createBusinessCouponCode = (data) => axios.post(DASHBOARD_CONFIG.createBusinessCouponCode, data, { headers: authHeader() });
const updateBusinessCouponCode = (data) => axios.post(DASHBOARD_CONFIG.updateBusinessCouponCode, data, { headers: authHeader() });
const deleteBusinessCouponCode = (data) => axios.post(DASHBOARD_CONFIG.deleteBusinessCouponCode, data, { headers: authHeader() });
const findOneBusinessCouponCode = (data) => axios.post(DASHBOARD_CONFIG.findOneBusinessCouponCode, data, { headers: authHeader() });
const findAllbusinessCouponCodes = (data) => axios.post(DASHBOARD_CONFIG.findAllbusinessCouponCodes, data, { headers: authHeader() });

const adminCreateCouponCode = (data) => axios.post(DASHBOARD_CONFIG.createAdminCouponCode, data, {headers: authHeader()});
const adminfindOneCouponCode = (data) => axios.post(DASHBOARD_CONFIG.getOneAdminCouponCode, data, {headers: authHeader()});
const adminUpdateCouponCode = (data) => axios.post(DASHBOARD_CONFIG.adminUpdateCouponCode, data, { headers: authHeader()});
const adminUpdateGetoneCouponCode = (data) => axios.post(DASHBOARD_CONFIG.adminUpdateGetoneCouponCode, data, { headers: authHeader()});
const viewBuinessCouponCodeUsers = (data ) => axios.post(DASHBOARD_CONFIG.viewBuinessCouponCodeUsers, data, { headers: authHeader()});
const adminDeleteAllCouponCode = (data) => axios.post(DASHBOARD_CONFIG.adminDeleteAllCouponCode, data, { headers: authHeader()});
const adminDeleteCouponCode = (data) => axios.post(DASHBOARD_CONFIG.adminDeleteCouponCode, data, { headers: authHeader()});
const BusinessDeleteCouponCode = (data) => axios.post(DASHBOARD_CONFIG.businessDeleteCouponCode, data, { headers: authHeader()});


const adminfindAllCouponCode = (data) => axios.post(DASHBOARD_CONFIG.adminGetAllCouponCode, data, { headers: authHeader() });
const adminfindAllServiceProvider = (data) => axios.post(DASHBOARD_CONFIG.findallServiceInCouponCode, data, { headers: authHeader() });
const adminfindAllUsers = (data) => axios.post(DASHBOARD_CONFIG.adminfindAllUsers, data, { headers: authHeader() });
const adminAddUsersCoupon = (data) => axios.post(DASHBOARD_CONFIG.adminAddUsersCoupon, data, {headers: authHeader()});
const addBusinessCouponCodeUsers = (data) => axios.post(DASHBOARD_CONFIG.addBusinessCouponCodeUsers, data, {headers: authHeader()});
const updateBusinessCouponCodeUsers = (data) => axios.post(DASHBOARD_CONFIG.updateBusinessCouponCodeUsers, data, {headers: authHeader()});
const deleteBusinessCouponCodeUsers = (data) => axios.post(DASHBOARD_CONFIG.deleteBusinessCouponCodeUsers, data, {headers: authHeader()});
const adminDeleteUsersCoupon = (data) => axios.post(DASHBOARD_CONFIG.adminDeleteUsersCoupon, data, {headers: authHeader()});
const updateAdminUserCoupons = (data) => axios.post(DASHBOARD_CONFIG.updateAdminUserCoupons, data, {headers: authHeader()});
const updateAdminGroupingCoupons = (data) => axios.post(DASHBOARD_CONFIG.updateAdminGroupingCoupons, data, {headers: authHeader()});




// const adminfindAllServiceProvider = (code) =>
//     axios.get(DASHBOARD_CONFIG.findallServiceInCouponCode, {
//         params: {
//             code: code,
//         },
//     });

const businessCreateCouponCode = (data) => axios.post(DASHBOARD_CONFIG.createBusinessCouponCodeNew, data, {headers: authHeader()});
const businessfindOneCouponCode = (data) => axios.post(DASHBOARD_CONFIG.getOneBusinessCouponCodeNew, data, {headers: authHeader()});
const businessUpdateCouponCode = (data) => axios.post(DASHBOARD_CONFIG.BusinessUpdateCouponCodeNew, data, { headers: authHeader()});
const businessDeleteCouponCode = (data) => axios.post(DASHBOARD_CONFIG.BusinessDeleteCouponCodeNew, data, { headers: authHeader()});
const businessfindAllCouponCode = (data) => axios.post(DASHBOARD_CONFIG.BusinessGetAllCouponCodeNew, data, { headers: authHeader() });
const businessGetServiceCategory = (data) => axios.post(DASHBOARD_CONFIG.BusinessGetServiceCategoryNew, data, { headers: authHeader() });
const businessDeleteAllCouponCode = (data) => axios.post(DASHBOARD_CONFIG.BusinessDeleteAllCouponCodeNew, data, { headers: authHeader()});

const createGallery = (data) => axios.post(DASHBOARD_CONFIG.createGallery, data, { headers: authHeader() });
const updateGallery = (data) => axios.post(DASHBOARD_CONFIG.updateGallery, data, { headers: authHeader() });
const deleteGallery = (data) => axios.post(DASHBOARD_CONFIG.deleteGallery, data, { headers: authHeader() });
const findOneGallery = (data) => axios.post(DASHBOARD_CONFIG.findOneGallery, data, { headers: authHeader() });
const findAllGallery = (data) => axios.post(DASHBOARD_CONFIG.findAllGallery, data, { headers: authHeader() });
const findAllUsedCoupons = (data) => axios.post(DASHBOARD_CONFIG.findAllUsedCoupon, data, { headers: authHeader() });
const findAllUsedCouponsBusiness = (data) => axios.post(DASHBOARD_CONFIG.findAllUsedCouponBusiness, data, { headers: authHeader() });
const getAllCouponsGrouping = (data) => axios.post(DASHBOARD_CONFIG.getAllCouponCodeGrouping, data, { headers: authHeader() });
const getAllCouponsGrouping_business = (data) => axios.post(DASHBOARD_CONFIG.getAllCouponCodeGrouping_business, data, { headers: authHeader() });
const transcationGetAll = (data) => axios.post(DASHBOARD_CONFIG.transcationGetAll, data, { headers: authHeader() });
const commissionGetAll = (data) => axios.post(DASHBOARD_CONFIG.commissionGetAll, data, {headers: authHeader() });
const commissionUpdate = (data) => axios.post(DASHBOARD_CONFIG.commissionUpdate, data, {headers: authHeader() });




const getVendorDetails = (data) => axios.post(VENDOR_CONFIG.getVendorApi, data, { headers: authHeader() });

const updateBusinessInformation = (data) => axios.post(DASHBOARD_CONFIG.updateBasicInformationApi, data, { headers: authHeader() });
const updateBusinessTiming = (data) => axios.post(DASHBOARD_CONFIG.updateBusinessTimingApi, data, { headers: authHeader() });


const getReferralList = (data) => axios.post(DASHBOARD_CONFIG.getReferralList, data, { headers: authHeader() });







const data = {

    adminCreateCouponCode,
    adminfindOneCouponCode,
    adminUpdateCouponCode,
    adminDeleteCouponCode,
    businessDeleteCouponCode,
    adminDeleteAllCouponCode,
    adminfindAllCouponCode,
    adminfindAllServiceProvider,
    adminfindAllUsers,

    businessCreateCouponCode,
    businessfindOneCouponCode,
    businessUpdateCouponCode,
    businessDeleteCouponCode,
    businessfindAllCouponCode,
    businessGetServiceCategory,
    businessDeleteAllCouponCode,
    updateProposalDetails,
    
    dashboardStatus,

    createService,
    updateService,
    deleteService,
    deleteManyService,
    getOneService,

    getAllService,
    acceptDenyRequest,
    getOneRequest,
    
    createAppointment,
    updateAppointment,
    deleteAppointment,
    getOneAppointment,
    getAllAppointment,


    createOrder,
    updateOrder,
    deleteOrder,
    getOneOrder,
    getAllOrder,
    verifyOrderOtp,

    getAllRequest,


    createStaff,
    updateStaff,
    deleteStaff,
    getStaff,
    getAllStaff,

    updateDashboardSettings,
    getAllDashboardSettings,

    adminDashboardStatus,
    createUser,
    updateUser,
    deleteUser,
    getUser,
    getAllUsers,

    getAllProposals,
    updateProposal,
    getProposalDetails,
    getAllBusiness,
    getAllFreelancer,
    deleteServiceProvider,

    getAllMarketing,
    changeProposalStatus,
    addStaffs,

    createCouponCode,
    getAllCouponCode,
    getOneCouponCode,
    updateCouponCode,
    deleteCouponCode,


    //Referral code apis
    createReferralCode,
    getAllReferralCode,
    getOneReferralCode,
    updateReferralCode,
    deleteReferralCode,


    createResources,
    findAllresources,
    findOneResource,
    updateResources,
    deleteResources,

    createBusinessCouponCode,
    findAllbusinessCouponCodes,
    findOneBusinessCouponCode,
    updateBusinessCouponCode,
    deleteBusinessCouponCode,

    createGallery,
    findAllGallery,
    findOneGallery,
    updateGallery,
    deleteGallery,

    getVendorDetails,

    updateBusinessInformation,
    updateBusinessTiming,
    findAllUsedCoupons,
    findAllUsedCouponsBusiness,

    transcationGetAll,
    commissionGetAll,
    commissionUpdate,
    getAllCouponsGrouping,
    getAllCouponsGrouping_business,
    adminAddUsersCoupon,
    addBusinessCouponCodeUsers,
    updateBusinessCouponCodeUsers,
    deleteBusinessCouponCodeUsers,
    adminDeleteUsersCoupon,
    adminUpdateGetoneCouponCode,
    viewBuinessCouponCodeUsers,
    updateAdminUserCoupons,
    updateAdminGroupingCoupons,

    //referral
    getReferralList

};

export default data;