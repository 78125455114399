import React, { useState } from 'react';
import Stepper from '../../../helpers/Stepper';
import { BiSupport } from "react-icons/bi"

function VerificationCard(props) {
    const [currentStep, setCurrentStep] = useState(2);

    const stepsData = [
        {
            label: "Form Submisson",
            name: "Form Submisson"
        },
        {
            label: "Payment Verification",
            name: "Payment Verification"
        },
        {
            label: "Admin Approval",
            name: "Admin Approval"
        },


    ];

    return (
        <div className="verification-status-card text-start">
            <div className="mx-2">
                {/* <span><h6> Verification Status:</h6> pending</span> */}
                <div className="d-flex flex-row">
                    <div>
                        <h6>Verification Status: </h6>
                    </div>
                    <div className="badge bg-warning p-2 ms-2">Pending</div>
                </div>
                <h4 className="mt-3">
                    Reg Id : &nbsp;<span className="text-muted">{props.regId}</span>
                </h4>
            </div>
            <div className="m-3">
                <Stepper
                    steps={stepsData}
                    currentStep={currentStep}
                />
            </div>
            <div className="d-flex flex-row justify-content-between">
                <div className="p-2">
                    <p className="text-muted">
                        *Once verification is completed you can list your services
                    </p>
                </div>
                <div className="p-2">
                    <p className="text-primary">
                        <BiSupport /> Contact Support
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VerificationCard;