import React from 'react'
import Facebookimg from "../../assets/images/fb-icon.svg";
import Whatsappimg from "../../assets/images/wa-icon.svg";
import Youtubeimg from "../../assets/images/youtube-icon.svg";
// import Directimg from "../../assets/images/dm-icon.svg";
import Twitterimg from "../../assets/images/twitter-icon.svg";
import Instagramimg from "../../assets/images/Insta-icon.svg";


function ContactUsProfile() {
  return (
    <div>
     <div className='contactUs-section'>
          <h1>Contact Us</h1>
          <h2>Get in touch with us</h2>
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p> */}
          <h2>Service Query</h2>
          <p>service@slaylewks.com</p>
          <h2>Business Query</h2>
          <p>business@slaylewks.com</p>
          <h2>Social Media</h2>
          <div className='d-flex mt-3'>
           <span className='mx-2'>
            <a href='https://m.facebook.com/people/Slaylewks/100087101419921/' target="blank">
            <img src={Facebookimg} />
            </a>
           </span>
           <span className='mx-2'>
            <a href='https://www.instagram.com/slaylewks/' target="blank">
            <img src={Instagramimg} />
            </a>
           </span> <span className='mx-2'>
            <a href='https://twitter.com/slaylewks' target="blank">
            <img src={Twitterimg} />
            </a>
           </span>
            {/* <span className='mx-2'>
            <a href='https://m.facebook.com/people/Slaylewks/100087101419921/' target="blank">
            <img src={Youtubeimg} />
            </a>
           </span> <span className='mx-2'>
           <a href="#"target="blank">
                        <img src={Whatsappimg} />
            </a>
           </span> */}
           
          </div>
          </div>
    </div>
  )
}

export default ContactUsProfile
