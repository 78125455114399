import React, { useEffect, useState, useContext } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container, Breadcrumb, Table } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import { MdOutlineNavigateNext } from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import PaymentFailed from './PaymentFailed';
import CancellationSucess from './CancellationSucess';





function PaymentSuccessBridal() {


   const navigate = useNavigate();
  

   

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    const navigateTerms = () => {
        navigate('/term-of-use')
    }



 


    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }

  



    
    return (
        <div className="payment-success">
            {/* <Header /> */}
            <section className='pb-0 mt-0 pt-0'>
               
                        <div className='container-fluid' id='thanks-note-outer'>
                            <Row>
                                <Col md={12}>
                                  
                                            <div>
                                                <Container>
                                                    <Row className='text-center mt-5'>
                                                        <img src={YesSuccess} alt="small-info" className="payment-success-icon" />
                                                        <div className='thanks-note mt-5'>
                                                            <h1>Thank you for your order! </h1>
                                                            <p className='mt-3'>The order confirmation details of your order has been sent to your register email address.</p>
                                                            <Card className='orderid-card'>
                                                                <h6>You can check your order details in my orders page</h6>
                                                            </Card>
                                                            <h5 className="mt-5" onClick={redirectOrdersPage}> My Orders<MdOutlineNavigateNext size={18} /> </h5>
                                                        </div>
                                                    </Row>
                                                </Container>
                                                <hr className='mt-5 pt-3' />
                                            </div>

                               

                                </Col>
                            </Row>
                        </div>
                   

                

            </section>
            <div className="mt-5">
            <CartFooter />       
            </div>
        </div>
    )
}

export default PaymentSuccessBridal