import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/elements/header/Header";
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import {Form,Modal,Alert,Spinner,Button,Container,Row,Col} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ImageUploder from "../../helpers/imageUploder";
import { bankInformationValidation } from "../../validations/yub_schemas/bankInformationValidation";
import BankFormImg from "../../assets/images/vendor_icons/Online-banking.svg";
import { BsFillCaretRightFill, BsFillCaretLeftFill, BsInfoCircle } from "react-icons/bs";
import MultiStepFormContext from "./MultiStepFormContext" ;


const BusinessStep4 = () => {

    const [value, setValue] = useState("");

    const navigate = useNavigate();

    const iconStyle = { color: "#FFF", fontSize: "1em" };

    const { step4Details, setStep4Details, onFormSubmit, prev, loading } = useContext(MultiStepFormContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const formik = useFormik({
        initialValues: {
            bank_username: step4Details.bank_username || "",
            bank_account_number: step4Details.bank_account_number || "",
            confirm_account_number: step4Details.confirm_account_number || "",
            bank_ifsc: step4Details.bank_ifsc || "",
            bank_account_image_url: step4Details.bank_account_image_url || ""
        },
        validationSchema: bankInformationValidation,
        onSubmit: (values) => {
            // ...values,
            // let data = {
            //     bank_username: values.bank_username,
            //     bank_account_number: values.bank_account_number,
            //     bank_ifsc: values.bank_ifsc
            // }
            setStep4Details(values);
            onFormSubmit(values)
            .then((res)=>{
                navigate("/")
            })


        },
    });

    const onBack = () => {
        prev();
    }

    return (
        <div className="businessStep4 mt-5">
            <Container>
                <Row>
                    <Col md={12} sm={12}>
                        <Form noValidate className="business-register-form text-start px-5" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                <div className="step-one-title">
                                    <h4>Enter Bank Account Details *</h4>
                                    <p>Please Enter your Active Bank details for hassle free transactions</p>
                                </div>
                                <Row>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 p-2" controlId="bankAccountNo">
                                            <Form.Label>Account Number *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Account Number"
                                                name="bank_account_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.bank_account_number}
                                                isInvalid={!!formik.touched.bank_account_number && !!formik.errors.bank_account_number}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.bank_account_number}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 p-2" controlId="reBankAccountName">
                                            <Form.Label>Re-Enter Account Number *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Confirm Account Number"
                                                name="confirm_account_number"
                                                onChange={formik.handleChange}
                                                value={formik.values.confirm_account_number}
                                                isInvalid={!!formik.touched.confirm_account_number && !!formik.errors.confirm_account_number}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.confirm_account_number}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 p-2" controlId="bankAccountName">
                                            <Form.Label>Account Name *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Account Username"
                                                name="bank_username"
                                                onChange={formik.handleChange}
                                                value={formik.values.bank_username}
                                                isInvalid={!!formik.touched.bank_username && !!formik.errors.bank_username}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.bank_username}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 p-2" controlId="ifscCode">
                                            <Form.Label>Branch name *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Branch Name"
                                                name="bank_ifsc"
                                                onChange={formik.handleChange}
                                                value={formik.values.bank_ifsc}
                                                isInvalid={!!formik.touched.bank_ifsc && !!formik.errors.bank_ifsc}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.bank_ifsc}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                             
                                {/* <div>
                                <ImageUploder 
                                onChange={formik.handleChange}
                                value={formik.values.bank_account_image_url}
                                ></ImageUploder>
                            </div> */}
                                <div className="text-center my-5">
                                    {
                                        loading && (
                                            <div className="text-muted text-start">
                                                <p><span className="me-2"><BsInfoCircle /></span>This process might take longer. Please wait sometime</p>
                                            </div>
                                        )

                                    }
                                   <Row>
                                   <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                                        <button class="back-btn-businessSteps" size="lg" onClick={onBack}>
                                                Back
                                            </button>
                                        </Col>
                                        <Col md={6} sm={6} xs={6} className="d-grid mb-2">
                                            <Button variant="" className="next-btn-businessSteps" type="submit" size="lg">
                                                Submit
                                                {
                                                    loading && (
                                                        <Spinner
                                                            className="ms-3"
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )
                                                }

                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </FormikProvider>
                        </Form>
                    </Col>
                    {/* <Col md={4} sm={12}>
                        <div className="form-image-container mt-5">
                            <img className="img-fluid" src={BankFormImg} />
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
};

export default BusinessStep4;