import React, { useEffect, useState } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import {MdOutlineNavigateNext} from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import PaymentFailed from './PaymentFailed';

function CustomToggle({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a class="btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>
            <h5>{children}</h5>
        </a>
    );
}


function ContinueButton({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button class="continue-btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>Continue</button>
    );
}


function ReschduleSuccess() {

    const [searchParams] = useSearchParams();

    const [orderDetails, setOrderDetails] = useState({})
    const [loading, setLoading] = useState(false)

    const type = searchParams.get("type");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const redirectPaymentDetails = (type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectReschedule = () => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    


    const formik = useFormik({
        initialValues: {
            refundReason: 'One',
            refundMessage: '',
            payment_id: '',
            confirmation: 'false'
        },
    });

    useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);


    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }

    const raiseRefunds = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (formik.values.confirmation !== 'true') {
            toast.warning('Please select mode of refund.');
            setLoading(false);
            return
        }
        const obj = {
            refundReason: formik.values.refundReason,
            refundMessage: formik.values.refundMessage,
            payment_id: orderDetails.payment_id,
            order_id: orderDetails._id,
            appointment_id: orderDetails.appointment._id
        }
        const result = await dispatch(raiseRefund(obj));
        setLoading(false)
        if (result.result) {
            toast.success('Cancellation successfully raised')
            navigate(`/business/payment/${id}?type=cancellation_raised`)
        } else {
            toast.error('Could not raise a cancellation request.')
        }
    }

    return (
        <div className="payment-success">
            <Header />
            <section className='pb-0 mt-0 pt-0'>
                <div className='container-fluid' id='thanks-note-outer'>
                <Row>
                        <Col md={12}>
                        
                                      <div>    
                                        <Container>
                                            <Row className='text-center mt-5'>
                                            <img src={YesSuccess} alt="small-info" className="payment-success-icon" />
                                                <div className='thanks-note'>
                                                <h1 className='mt-4'>Successfully Rescheduled!</h1>
                                                <p>The order Reschedule confirmation details of your order has been sent to your email address.</p>
                                                 <Card className='orderid-card'>
                                                    <h6>YOUR ORDER ID: {orderDetails?.order_id}</h6>
                                                 </Card>
                                                 <p>Reschedule on: {moment(new Date()).local().format("DD MMM YYYY")}</p>
                                                 <h5 onClick={redirectToExplore}>Continue Explore <MdOutlineNavigateNext size={18}/> </h5>
                                                </div>
                                            </Row>
                                        </Container>
                                        <hr />

                                        <div>
                                <Container className='mt-5'>
                                   <div className='payment-sub-cards'>
                                   <Row>
                                        <Col md={4}>
                                            <Card>
                                                <Card.Body>
                                                <Row className='text-center'>
                                                    <p>Appointment Date / Time</p>
                                                    <h6>{moment(orderDetails?.appointment?.appointment_start_dateTime).format("DD MMM YYYY hh:mm a")}
                                                    </h6>
                                                </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={4}>
                                        <Card>
                                            <Card.Body>
                                                
                                            <Row className='text-center'>
                                                    <p>Service type</p>
                                                    <h6>Shop Service</h6>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        </Col>
                                        <Col md={4}>
                                        <Card>
                                            <Card.Body>
                                                <Row className='text-center'>
                                                    <p>Verification CODE</p>
                                                    <h6>{orderDetails?.order_OTP}</h6>
                                                </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                   </div>
                                </Container>
                                        </div>

                                        <Container>
                    <Row>
                    <Col md={7}>
                                    <>
                                   <p className='payment-info-text'>You will receive updates from</p>
                                <Card className='payment-info-card'>
                                    <Row className='mx-2'>
                                        <Col md={7} className="payment-info-inner">
                                       <div>
                                       <Row>
                                       <div className='payment-suceess-service-info'>
                                                  <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                                                  <h6>{orderDetails?.service_provider?.business_email}</h6>
                                                  </div>
                                        </Row>
                                        </div>   
                                        </Col>
                                        <Col md={5} className="">
                                        <Row>
                                                  <div className='payment-suceess-service-info'>
                                                  <p> <ion-icon name="call-outline"></ion-icon></p>
                                                  <h6>{orderDetails?.service_provider?.business_contactNo}</h6>

                                                  </div>
                                        </Row>
                                        </Col>
                                    </Row>
                                    </Card>
                                    <Row className='payment-order-info'>
                                        <Col>
                                        <p className='payment-info-text'>Order Details</p>
                                        </Col>
                                        <Col>
                                        <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                        </Col>

                                    </Row>
                                    <Card>
                                        <Card.Header className=' bg-white'>
                                    <Row className='mt-2 mb-2'>
                                        <Col md={1}>
                                        <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                        </Col>
                                       <Col className='payment-orderInfo-details px-0' md={11}>
                                            <h6 className='mt-2'>{orderDetails?.service_provider?.business_name}</h6>
                                            <p className='mt-2'>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                      </Col>
                                    
                                    </Row>
                                    </Card.Header>
                                    <Card.Header className='bg-white'>
                                    <Row>
                                    {
                                        orderDetails?.services && orderDetails?.services.length > 0 && (
                                            orderDetails?.services?.map((item, index) => {

                                                return (
                                                    <div key={index}>
                                                    <Row className='mb-2'>
                                                    
                                                        <Col md={6} sm={6} className="cart-checkout-item-list">
                                                        <span>{item?.gender?.[0]?.gender_name}</span>
                                                        <p class="service-heading">{item?.service_name}</p>
                                                        <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                        </Col>
                                                        <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                              <div className='cart-check-out-amount'>
                                                                <p></p>
                                                                <p></p>
                                                                <p className='mt-5'>{ item?.service_price?.["$numberDecimal"] +" ₹ "}</p>
                                                              </div>
                                                        </Col>

                                                    </Row>
                                                    <hr />

                                                </div>

                                                );
                                            })
                                        )
                                    }
                                    </Row>
                                    </Card.Header>

                                    </Card>
                                    </>

                                
                    </Col>
                    <Col md={5}>
                      <div>
                        <p className='payment-info-text'>Payment Details</p>
                        <Card>
                            <Card.Header className='pb-0 payment-info-totalCard'>
                                <Row>
                                    <Col>
                                    <h1>Grand Total:</h1>
                                    <h1>Payment Method:</h1>
                                    </Col>
                                    <Col>
                                    <div className='float-end'>
                                        <Row className='mt-1 mb-0 pb-0'>
                                            <Col className='text-end px-2'>
                                            <h6 className='float-end'>₹{orderDetails?.final_price + ".00"}</h6>
                                            </Col>
                                            
                                        </Row> 
                                        <Row>
                                        <Col className='text-end px-2 mt-2'>
                                            <h6 className='float-end'>UPI</h6>
                                            </Col>
                                            </Row> 
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <div className='payment-total-card-inner'>
                                        <p>Price</p>
                                        <p>GST 18%</p>
                                        <p>Discount</p>
                                        <p>Coupon for you</p>
                                        <p>Grand Total</p>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className='float-end payment-total-card-inner'>
                                        <Row>
                                            <Col className='text-end px-0'>
                                             <p>{Math.round(orderDetails?.original_price +orderDetails?.discount+orderDetails?.coupon/100-orderDetails?.gst_value/100 ) + ".00"}</p>
                                             <p>{Math.round(orderDetails?.gst_value/100) + ".00"}</p>
                                             <p className='green_text'>{Math.round(orderDetails?.discount) + ".00"}</p>
                                             <p className='green_text'>{Math.round(orderDetails?.coupon/100) + ".00"}</p>
                                             <p>{Math.round(orderDetails?.final_price) + ".00"}</p>
                                            </Col>
                                            <Col className='Payment-amount-inner'>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <p className='payment-info-text'>Contact Details</p>
                        <Card>
                            <Card.Body>
                                <div className='payment-user-contact-info'>
                                <p>{orderDetails?.customer_location_details?.alternate_username}</p>
                                <p>{orderDetails?.customer_location_details?.area}{orderDetails?.customer_location_details?.city}</p>
                                <h6>{orderDetails?.customer_location_details?.alternate_phone}</h6>
                                <h6>{orderDetails?.customer_location_details?.alternate_emailID}</h6>
                                </div>
                            </Card.Body>
                        </Card>
                      </div>
                      <div className='payment-sucess-need-help'>
                        <h1>Need Help?</h1>
                        {
                            orderDetails?.order_status !== "cancelled" && orderDetails?.order_status !== "completed" && (
                                <div className='d-flex'>
                                <p className='help-icons'><ion-icon name="close-circle-outline"></ion-icon></p>
                                <p onClick={() => redirectPaymentDetails("cancel")}>Cancel Order</p>
                                </div>
                            )
                        }
                       
                        {/* <div className='d-flex'>
                        <p className='help-icons'><ion-icon name="calendar-outline"></ion-icon></p>
                        <p onClick={redirectReschedule}>Rescheduled</p>
                        </div> */}
                        <div className='d-flex'>
                        <p className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></p>
                        <p onClick={redirectSupport}>Support Center</p>
                         </div>
                      </div>
                    </Col>
                    </Row>
                    
                </Container>
                                       
                                     {/* <div>
                                        <h1>Your Payment is <span className='text-success'>Successful</span></h1>
                                        <h6 className='mt-4'>Order Id: <span>{orderDetails?.order_id}</span> </h6>
                                       
                                        <h6>Placed on: {moment(orderDetails?.created_at).local().format("YYYY-MM-DD hh:mm A")}</h6>
                                    </div> */}
                                    </div>

                              
                                
                        </Col>

                        
                
                    </Row>
                </div>
                
                
                <div class="container mt-3">
                
                
                    <div class="row mb-5">
                        <div class="col-lg-7 col-md-7 col-sm-12 myorder">
                       {
                        type === "view" &&  (
                            <div className='mx-2'>
                                        <Row>
                                        <Col md={12}>
                                        <div className='order-id-view'>
                                        <h4>Order Id:</h4>
                                        <h5 className='mx-2 mt-1'>{orderDetails?.order_id}</h5> 
                                        </div>
                                        </Col>
                                        <Row className='mt-4'>
                                            <Col> <p className='mt-2'>Placed on: {moment(orderDetails?.created_at).local().format("YYYY-MM-DD hh:mm A")}</p></Col>
                                            <Col>
                                            {
                                         orderDetails?.order_status === "pending"  && orderDetails?.payment_status === "complete" ? (
                                             <div className='order-view-two-btn mb-2'>
                                                <Button onClick={redirectReschedule} className='order-btn-Reschedule mb-2'>Reschedule</Button>
                                                <Button onClick={() => redirectPaymentDetails("cancel")} className='order-btn-Cancel mb-2'>Cancel Order</Button>
                                             </div>
                                         ):(
                                            null
                                         )
                                      }
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                                
                        )
                       }
                      
                          
                            {/* {
                                type ==="view" && (
                                    {
                                        orderDetails?.payment_status !== "complete" ? (
                                            <h5><Badge pill bg="warning">Payment pending</Badge></h5>
                                        ) : (
                                            <h5><Badge pill bg="success">Payment success</Badge></h5>
                                        )
        
                                    }
                                   
                                ) 
                            } */}

                            {/* {
                                orderDetails?.order_status !== "completed" ? (
                                    <h5><Badge pill bg="warning">Order pending</Badge></h5>
                                ) : (
                                    <h5><Badge pill bg="success">Order completed</Badge></h5>
                                )

                            } */}
                            {
                                orderDetails?.order_status === "cancelled" && (
                                    <div>
                                        <Card className='refund-order-view'>
                                            <Card.Title className='mb-0'>
                                                <Row className='mt-3 mx-2'>
                                                    <Col md={6} className="w-50">
                                                        <div>
                                                        <h5>Refund</h5>
                                                        </div>
                                                         </Col>
                                                    <Col md={6} className="w-50">
                                                        <div className='w-100'>
                                                        {/* <h6 className='mt-2 float-end'>Processing</h6> */}
                                                        </div>
                                                        </Col>
                                                </Row>
                                            </Card.Title>
                                            <hr className='line-hr' />
                                            <Card.Body>
                                                <Row className='d-flex'>
                                                    <Col md={6} sm={6} className="w-50">
                                                    <h6 className='text-muted'>Price</h6>
                                                    <h6 className='text-muted'>Discount</h6>
                                                    </Col>
                                                    <Col md={6} sm={6} className="w-50">
                                                        <div className='float-end'>
                                                        <h6>₹{orderDetails?.final_price}</h6>
                                                        <h6>−₹{orderDetails?.discount}</h6>
                                                        </div>
                                                    
                                                    </Col>   
                                                </Row>
                                            </Card.Body>
                                            <hr className='line-hr' />
                                            <Card.Body>
                                             <Row>
                                                <Col><h6 className='mt-2'>Total Amount</h6></Col>
                                                <Col><h6 className='float-end'>₹{orderDetails?.final_price}</h6></Col>
                                             </Row>
                                        </Card.Body>
                                        </Card>
                                    </div>
                                )
                            }
                          {/* commented on 13/9/2022 */}
                            {/* <div className='mt-3'>
                                <Card className='payment-success-service-details'>
                                <div class="service-details">
                                <div className='mx-3'>
                                <h4 className='m-0'>Service Details</h4>
                                <span>{orderDetails?.service_provider?.business_name}</span>
                                <p>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                <h6 className='mt-4 text-primary'>Shop Service</h6>
                                <div class="service-list-payment">
                                    <Row>
                                        <Col>
                                        <ul>
                                       <li>
                                        <h6 class="">
                                            Date: {moment(orderDetails?.appointment?.appointment_start_dateTime).format("DD MMM YYYY")}
                                        </h6>
                                    </li>
                                    <li>
                                    <h6>
                                            Start Time : {moment(orderDetails?.appointment?.appointment_start_dateTime).format("hh:mm A")}
                                        </h6>
                                    </li>
                                    </ul>
                                        </Col>
                                        <Col className='order-view-service-details'>
                                        <div className='float-end mx-5'>
                                            <ul>
                                                
                                                <li>
                                                <h6 class="total-amt float-end">Total: ₹{orderDetails?.final_price}</h6>
                                                </li>
                                            </ul>
                                        </div>
                                        </Col>
                                    </Row>
                                    </div>
                                    </div>
                            </div>
                                </Card>
                            </div> */}

                            <div class="service-list-payment mx-2">
                                <ul>
                                    {/* <li>
                                        <p class="date-month">
                                            Date: {moment(orderDetails?.appointment?.appointment_start_dateTime).format("YYYY-MM-DD hh:mm A")}

                                        </p>
                                        <span class="total-amt">Total: ₹{orderDetails?.final_price}</span>
                                    </li> */}
                                    {/* <li>
                                        <p class="service-heading">Hair Cut</p>
                                        <div class="service-item">
                                            <p><img src={MenImg} alt="men" class="img-fluid common-img" />  Men</p>
                                            <p><img src={ProfessionalImg} alt="professional" class="img-fluid common-img" /> Professional</p>
                                            <p><img src={TimerImg} alt="timer" class="img-fluid common-img" /> 30 min</p>
                                        </div>
                                        <span class="amount-text">Samira Raman</span>
                                        <span class="time-order">10: 00 AM</span>
                                    </li> */}

                                                              {/* commented on 13/9/2022 */}

                                    {/* {
                                        orderDetails?.services && orderDetails?.services.length > 0 && (
                                            orderDetails?.services?.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <p class="service-heading">{item?.service_name}</p>
                                                        <div class="service-item">
                                                            <p><img src={MenImg} alt="men" class="img-fluid common-img" /> {item?.gender?.[0]?.gender_name}</p>
                                                            <p><img src={TimerImg} alt="timer" class="img-fluid common-img" />{item?.duration?.["$numberDecimal"]} min</p>
                                                        </div>
                                                        <span class="time-order">₹ {item?.service_price?.["$numberDecimal"]}</span>
                                                    </li>
                                                );
                                            })
                                        )
                                    } */}

                                         {/* till this */}

                                         
                                    {/* <li>
                                        <p class="service-heading">Hair Cut</p>
                                        <div class="service-item">
                                            <p><img src={MenImg} alt="men" class="img-fluid common-img" />  Men</p>
                                            <p><img src={ProfessionalImg} alt="professional" class="img-fluid common-img" /> Professional</p>
                                            <p><img src={TimerImg} alt="timer" class="img-fluid common-img" /> 30 min</p>
                                        </div>
                                        <span class="amount-text">Samira Raman</span>
                                        <span class="time-order">10: 00 AM</span>
                                    </li>
                                    <li>
                                        <p class="service-heading">Hair Cut</p>
                                        <div class="service-item">
                                            <p><img src={MenImg} alt="men" class="img-fluid common-img" />  Men</p>
                                            <p><img src={ProfessionalImg} alt="professional" class="img-fluid common-img" /> Professional</p>
                                            <p><img src={TimerImg} alt="timer" class="img-fluid common-img" /> 30 min</p>
                                        </div>
                                        <span class="amount-text">Samira Raman</span>
                                        <span class="time-order">10: 00 AM</span>
                                    </li> */}
                                </ul>
                            </div>
                                                              {/* commented on 13/9/2022 */}


                            {/* <div>
                            {
                                type && type === "view" &&  (
                                    <div>
                                        
                                        <div class="common-busniess-box mt-4 mx-2">
                                    <h4>Customer Details</h4>
    
                                    <h6>
                                        Name   <span className="customer-details-order">
                                            {orderDetails?.customer_location_details?.alternate_username}
                                        </span>
                                    </h6>
    
                                    <h6>
                                        Phone Number   <span className="customer-details-order">
                                            {orderDetails?.customer_location_details?.alternate_phone}
                                        </span>
                                    </h6>
    
                                    <h6>
                                        Email Id  <span className="customer-details-order">
                                            {orderDetails?.customer_location_details?.alternate_emailID}
                                        </span>
                                    </h6>
                                    {
                                        orderDetails?.customer_location_details?.area && (
                                            <h6>
                                                Address  <span className="customer-details-order">
                                                    {orderDetails?.customer_location_details?.area}
                                                </span>
                                            </h6>
                                        )
                                    }
                                    {
                                        orderDetails?.customer_location_details?.city && (
                                            <h6>
                                                City  <span className="customer-details-order">
                                                    {orderDetails?.customer_location_details?.city}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                    {
                                        orderDetails?.customer_location_details?.state && (
                                            <h6>
                                                State  <span className="customer-details-order">
                                                    {orderDetails?.customer_location_details?.state}
                                                </span>
                                            </h6>
                                        )
                                    }
                                    {
                                        orderDetails?.customer_location_details?.country && (
                                            <h6>
                                                Country  <span className="customer-details-order">
                                                    {orderDetails?.customer_location_details?.country}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                    {
                                        orderDetails?.customer_location_details?.zipcode && (
                                            <h6>
                                                Zipcode  <span className="customer-details-order">
                                                    {orderDetails?.customer_location_details?.zipcode}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                    </div>
                                    { 
                                ((orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') || (orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled)) &&
                                <div class="common-busniess-box">
                                    <h4>Need Help?</h4>
                                    <ul className='need-help-section'>
                                    <li>{(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') && (<a href="JavaScript:void(0);" onClick={() => redirectPaymentDetails("cancel")}><img src={CancelOrderImg} alt="cancel-order" class="img-fluid info-icon" />Cancel Order</a>)} </li>
                                   <li> {(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled) && <a href="JavaScript:void(0);" onClick={redirectReschedule}><img src={RescheduleImg} alt="reschduled" class="img-fluid info-icon" />Reschedule</a>} </li>
                                   <li> <a href="JavaScript:void(0);"><img src={SupportCenterImg} alt="support-center" class="img-fluid info-icon" />Support Center</a> </li>
                                   </ul>
                                </div>
                            }
                                        
                                    </div>
                                   
                                )
                            }
                             </div> */}


                             {/* // commented on 12/10 for design change // */}
                            {/* { 
                                ((orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') || (orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled)) &&
                                <div class="common-busniess-box">
                                    <h4>Need Help?</h4>
                                    <a href="JavaScript:void(0);"><img src="public/images/order-issue.png" alt="order-issue" class="img-fluid info-icon" />Order Issue</a>
                                    {(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') && (<a href="JavaScript:void(0);" onClick={() => redirectPaymentDetails("cancel")}><img src={CancelOrderImg} alt="cancel-order" class="img-fluid info-icon" />Cancel Order</a>)} 
                                    {(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled) && <a href="JavaScript:void(0);" onClick={redirectReschedule}><img src={RescheduleImg} alt="reschduled" class="img-fluid info-icon" />Reschedule</a>}
                                    <a href="JavaScript:void(0);"><img src={SupportCenterImg} alt="support-center" class="img-fluid info-icon" />Support Center</a>
                                </div>
                            } */}
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-12 ">
                           
                            {/* {
                                type && type === "payment-success" && (
                                 
                                    
                                   <div>
                                    <Card className="payment-success-customer-details">
                                    <div class="common-busniess-box p-2 mx-3">
                                    <h4>Customer Details</h4>
    
                                    <h6>
                                        Name   <span className="text-muted">
                                            {orderDetails?.customer_location_details?.alternate_username}
                                        </span>
                                    </h6>
    
                                    <h6>
                                        Phone Number   <span className="text-muted">
                                            {orderDetails?.customer_location_details?.alternate_phone}
                                        </span>
                                    </h6>
    
                                    <h6>
                                        Email Id  <span className="text-muted">
                                            {orderDetails?.customer_location_details?.alternate_emailID}
                                        </span>
                                    </h6>
                                    {
                                        orderDetails?.customer_location_details?.area && (
                                            <h6>
                                                Address  <span className="text-muted">
                                                    {orderDetails?.customer_location_details?.area}
                                                </span>
                                            </h6>
                                        )
                                    }
                                    {
                                        orderDetails?.customer_location_details?.city && (
                                            <h6>
                                                City  <span className="text-muted">
                                                    {orderDetails?.customer_location_details?.city}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                    {
                                        orderDetails?.customer_location_details?.state && (
                                            <h6>
                                                State  <span className="text-muted">
                                                    {orderDetails?.customer_location_details?.state}
                                                </span>
                                            </h6>
                                        )
                                    }
                                    {
                                        orderDetails?.customer_location_details?.country && (
                                            <h6>
                                                Country  <span className="text-muted">
                                                    {orderDetails?.customer_location_details?.country}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                    {
                                        orderDetails?.customer_location_details?.zipcode && (
                                            <h6>
                                                Zipcode  <span className="text-muted">
                                                    {orderDetails?.customer_location_details?.zipcode}
                                                </span>
                                            </h6>
                                        )
                                    }
    
                                   </div>
                                    </Card>
                                    { 
                                ((orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') || (orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled)) &&
                                <div class="payment-success-help-center">
                                    <h4>Need Help?</h4>
                                    <ul className='need-help-section'>
                                    <li>{(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed') && (<a href="JavaScript:void(0);" onClick={() => redirectPaymentDetails("cancel")}><img src={CancelOrderImg} alt="cancel-order" class="img-fluid info-icon mx-2" />Cancel Order</a>)} </li>
                                    <li>{(orderDetails?.order_status !== 'cancelled' && orderDetails?.payment_status && orderDetails?.payment_status === "complete" && orderDetails?.order_status !== 'completed' && !orderDetails?.rescheduled) && <a href="JavaScript:void(0);" onClick={redirectReschedule}><img src={RescheduleImg} alt="reschduled" class="img-fluid info-icon mx-2" />Reschedule</a>}</li>
                                    <li><a href="JavaScript:void(0);"><img src={SupportCenterImg} alt="support-center" class="img-fluid info-icon mx-2" />Support Center</a></li>
                                  </ul>
                               </div>
                            }
                               
                                    </div>
                                )
                            } */}
                            <div className='float-end'>
                            {
                                type && type === "view" && (
                                    <div className='order-view-right-ctnt'>
                                        <Card className='card-order-view'>
                                            <Card.Title>
                                                <Row className='mt-3 mx-1'>
                                                    <Col md={6} sm={6} className="w-50">
                                                    <h5>Service Status</h5>
                                                    </Col>
                                                    <Col md={6} sm={6} className="w-50">
                                                    {orderDetails?.order_status && (
                                                                        <div className='float-end'>
                                                                            {
                                                                                orderDetails?.order_status === "completed" && (
                                                                                    <h5><Badge pill bg="success" className='badge-font'>Order {orderDetails?.order_status}</Badge></h5>
                                                                                )
                                                                            }
                                                                            {
                                                                                orderDetails?.order_status === "pending" && (
                                                                                    <h5><Badge pill bg="warning" className='badge-font'>Order {orderDetails?.order_status}</Badge></h5>
                                                                                )
                                                                            }
                                                                            {
                                                                                    orderDetails?.order_status === "cancelled" && (
                                                                                        <h5><Badge pill bg="danger" className='badge-font'>Order {orderDetails?.order_status}</Badge></h5>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    )}
                                                    </Col>
                                                </Row>
                                            </Card.Title>          
                                                {
                                                    orderDetails?.order_status === "pending" ? (
                                                        <div>
                                                        <hr className='line-hr'/>
                                                        <Card.Body>
                                                           
                                                            <Row>
                                                                <Col><h5>Verification OTP</h5></Col>
                                                                <Col> <h6 className='float-end'>{orderDetails?.order_OTP}</h6></Col>
                                                               
                                                            </Row>
                                                           </Card.Body>
                                                           </div>
                                                    ):(
                                                        null
                                                    )
                                                }
                                            
                                        </Card>

                                        <Card className='card-order-view'>
                                            <Card.Title className='mb-0'>
                                                <Row className='mt-3 mb-0 mx-1'>
                                                    <Col><h5>Total Services</h5></Col>
                                                    <Col><h6 className='float-end'>{orderDetails?.services?.length}</h6></Col>
                                                    
                                                </Row>
                                            </Card.Title>
                                            <hr className='line-hr' />
                                            <Card.Body>
                                                <Row>
                                                    <Col><h6 className='text-muted'>Price</h6>
                                                    <h6 className='text-muted'>Discount</h6></Col>
                                                    <Col >
                                                    <div className='float-end'>
                                                    <h6>₹{orderDetails?.final_price}</h6>
                                                    <h6 >−₹{orderDetails?.discount}</h6>
                                                    </div>
                                                    </Col>

                                                </Row>
                                            </Card.Body>
                                            <hr className='line-hr' />
                                            <Card.Body>
                                             <Row>
                                                <Col><h6 className='mt-2'>Total Amount</h6></Col>
                                                <Col><h6 className='float-end'>₹{orderDetails?.final_price}</h6></Col>
                                                
                                             </Row>
                                        </Card.Body>
                                        </Card>

                                        <Card className='card-order-view'>
                                            <Row className='mt-3 mx-1 mb-0'>
                                            <Card.Title>
                                                <h6>Payment Option</h6>
                                            </Card.Title>
                                            <hr  className='line-hr'/>
                                            <Card.Body>
                                            <img src={RazorpayImg} alt="small-info" class="img-fluid small-info" />
                                            </Card.Body>
                                            </Row>
                                        </Card>
                                        
                                        </div>
                                )
                            }
                            </div>
                            {
                                type && type === "cancellation_raised" && (
                                    <div class="sucessfully-cancel my-5">
                                        <img src={CancelSuccess} alt="cancel-sucess" class="img-fluid" />
                                        <p>Your Order has been <span>Successfully Cancelled</span></p>
                                        <span class="request-process">Your request is being processed. It may take up to 4 hours for your refund(if any) to be initiated.</span>
                                        <br />
                                        <button class="back-home-btn mt-5" onClick={backToHome}>Back to Home</button>
                                    </div>
                                )
                            }

                            {
                                type && type === "cancel-success" && (
                                    <div class="sucessfully-cancel my-5">
                                        <img src={CancelSuccess} alt="cancel-sucess" class="img-fluid" />
                                        <p>Your Order has been <span>Successfully Cancelled</span></p>
                                        <span class="request-process">Your request is being processed. It may take up to 4 hours for your refund(if any) to be initiated.</span>
                                        <br />
                                        <button class="back-home-btn mt-5" onClick={backToHome}>Back to Home</button>
                                    </div>
                                )
                            }

                            {
                                type && type === "cancel" && (
                                    <div className="">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="checkout-card-header">
                                                    <CustomToggle eventKey="0">Easy Cancellation</CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <Form>
                                                            <FormikProvider value={formik}>
                                                                <div class="card-body">
                                                                    <p class="reason-cancel">Reason for cancellation: Select reason for cancellation</p>
                                                                    <div class="mb-3">
                                                                        <Form.Label className="required">Reason for cancellation</Form.Label>
                                                                        <Form.Select
                                                                            name="refundReason"
                                                                            placeholder="Reason"
                                                                            onChange={formik.handleChange}
                                                                            value={formik.values.refundReason}
                                                                        >
                                                                            <option value="Concerned about service quality/hygiene">Concerned about service quality/hygiene</option>
                                                                            <option value="Found another service provider in Slaylewks">Found another service provider in Slaylewks</option>
                                                                            <option value="Found another service provider outside Slaylewks">Found another service provider outside Slaylewks</option>
                                                                            <option value="Booked by mistake">Booked by mistake</option>
                                                                            <option value="Change of plans">Change of plans</option>
                                                                            <option value="Others">Others</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <Form.Label className="required">Comment</Form.Label>
                                                                        <div class="select-content">
                                                                            <textarea class="form-control common-text" id="refundMessage" rows="3"
                                                                                placeholder="Comment.."
                                                                                onChange={formik.handleChange}
                                                                                value={formik.values.refundMessage}
                                                                            ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body">
                                                                    <p class="select-mode">Select a Mode of Refund </p>
                                                                    <div class="form-check">
                                                                        <Form.Check
                                                                            className="mb-2"
                                                                            type='radio'
                                                                            id='confirmation'
                                                                            name="confirmation"
                                                                            checked={formik.values.confirmation === "true"}
                                                                            onChange={() => formik.setFieldValue("confirmation", "true")}
                                                                        />
                                                                        <label class="form-check-label" for="flexRadioDefault1" >
                                                                            Orginal Payment Mode
                                                                        </label>
                                                                    </div>
                                                                    <p class="refund-process">Refund will be processed with in 8 days</p>
                                                                    <p class="terms-condtion">By clicking on “Request Cancellation”, I agree to <span>Tearms and
                                                                        Conditions</span> of Regund.</p>
                                                                    <button class="continue-btn mt-0" onClick={raiseRefunds} disabled={loading}>Continue</button>
                                                                </div>
                                                            </FormikProvider>
                                                        </Form>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                )
                            }
                        </div>
                
                    </div>
                </div>
            </section>
            <CartFooter />
        </div>
    )
}

export default ReschduleSuccess