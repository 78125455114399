import axios from 'axios';
import { API_CONFIG } from '../../configs';

export function authHeader() {
    let token = localStorage.getItem('access');

    if (token) {
        return { 'x-access-token': `${token}` };
    } else {
        return {};
    }
}

const signup = (data) => axios.post(API_CONFIG.signup, data);

const verifySignup = (code) =>
    axios.get(API_CONFIG.verifySignup, {
        params: {
            code: code,
        },
    });

const verifyToken = (code) =>
    axios.post(API_CONFIG.verifyToken, {}, { headers: authHeader() });

const login = (data) => axios.post(API_CONFIG.login, data);

const loginRefresh = (refreshToken) =>
    axios.post(API_CONFIG.loginRefresh, { refresh: refreshToken });

const logout = (refreshToken) => axios.post(API_CONFIG.logout, { refresh: refreshToken });

const getUser = (username) => axios.get(`${API_CONFIG.getUser}${encodeURIComponent(username)}/`);

export const forgotPassword = (data) => axios.post(API_CONFIG.forgotPassword, data);

export const changeEmail = (data) => axios.post(API_CONFIG.changeProfileEmail, data);


export const updateUser = (data) => axios.post(API_CONFIG.updateUser, data);

export const verifyForgotPassword = (code) =>
    axios.get(API_CONFIG.verifyForgotPassword, { params: { code: code } });

export const verifiedForgotPassword = (data) =>
    axios.post(API_CONFIG.verifiedForgotPassword, data);

export const changePassword = (data) => 
   axios.post(API_CONFIG.changePasswordProfile, data);

export const verifyEmail = (data) => axios.post(API_CONFIG.verifyEmail, data);

export const resendOtp = (data) => axios.post(API_CONFIG.resendOtp, data);

export const verifyRegisteredEmail = (data) => axios.post(API_CONFIG.verifyRegisteredEmail, data);
export const verifyProfileEmail = (data) => axios.post(API_CONFIG.verifyEmailProfile, data);


export const supportMessage = (data) => axios.post(API_CONFIG.supportMessage, data);
export const userSupportOrders = (data) => axios.post(API_CONFIG.supportOrders, data);
export const BusinessSupportMessage = (data) => axios.post(API_CONFIG.BusinessSupportMessage, data);



const data = {
    signup,
    verifyToken,
    verifySignup,
    login,
    loginRefresh,
    forgotPassword,
    verifyForgotPassword,
    verifyEmail,
    verifiedForgotPassword,
    changePassword,
    changeEmail,
    logout,
    getUser,
    updateUser,
    verifyRegisteredEmail,
    verifyProfileEmail,
    resendOtp,
    supportMessage,
    userSupportOrders,
    BusinessSupportMessage
};

export default data;
