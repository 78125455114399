import axios from 'axios';
import * as TYPES from './types.js';
import { toast } from "react-toastify";
import CHECKOUT_SERVICES from "./services";

export const changeServiceProvider = (data) => (dispatch) => {
    dispatch({
        type: TYPES.CHANGE_SERVICE_PROVIDER_DETAILS,
        payload: data
    });
};

export const clearServiceProvider = (data) => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_SERVICE_PROVIDER_DETAILS,
        payload: data
    });
};

export const addToCart = (service) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_SERVICE_TO_CART,
        payload: service
    });
};



export const addManyServicesToCart = (services) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_MANY_SERVICES_TO_CART,
        payload: services
    });
};




export const addServiceQty = (service) => (dispatch) => {
    dispatch({
        type: TYPES.ADD_SERVICE_QTY_TO_CART,
        payload: service
    });
};


export const removeServiceQty = (type) => (dispatch) => {
    dispatch({
        type: TYPES.REMOVE_SERVICE_QTY_FROM_CART,
        payload: type
    });
};

export const clearSlotDetails = (type) => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_SLOT_DETAILS,
        payload: type
    });
};


export const clearAllCartDetails = (type) => (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_ALL_CART_DETAILS
    });
};

export const createCartActions = (data) => (dispatch) => {
    //  console.log("====>",data)
    return CHECKOUT_SERVICES.createCartServices(data).then(
        (response) => {
            const { data } = response;
            console.log(data)
            
              let result = data;
              console.log("result==>",result)
            if (result) {
                dispatch({
                    type: TYPES.CREATE_SERVICE_TO_CART_SUCCESS,
                    payload: result.services,
                });
                toast.success(result.message)
                
            }
            else {
                dispatch({
                    type: TYPES.CREATE_SERVICE_TO_CART_FAIL,
                });
                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        // dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(result.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.CREATE_SERVICE_TO_CART_FAIL,
            });
            // return Promise.reject();
        },
    );
};

export const ReadCartActions = (data) => (dispatch) => {
    //  console.log("====>",data)
    return CHECKOUT_SERVICES.ReadCartServices(data).then(
        (response) => {
            const { data } = response;
            console.log(data)
              let result = data;
              console.log("result==>",result)
              
              
            if (result?.length > 0) {
                let carts = result.map(item => {
                    return {...item.services, cart_id:item?._id}
                  })
                dispatch({
                    type: TYPES.READ_CART_SERVICE_SUCCESS,
                    payload: carts,
                });
                // toast.success(result.message)
               
            }
            else {
                dispatch({
                    type: TYPES.READ_CART_SERVICE_SUCCESS,
                    payload: [],
                });
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                    }
                    toast.warning(result.message);
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.READ_CART_SERVICE_FAIL,
            });
        },
    );
};


export const removeFromCart = (data) => (dispatch) => {
     console.log("====>",data)
    return CHECKOUT_SERVICES.deleteCartServices(data).then(
        (response) => {
            const { data } = response;
            console.log(data)
              let result = data;
              console.log("result==>",result)
              
              
            if (result) {
               
                toast.success(result.message)
               
            }
            else {

                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                    }
                    toast.warning(result.message);
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
        },
    );
};



export const clearCartAction = (data) => (dispatch) => {
    return CHECKOUT_SERVICES.deleteCartManyServices(data).then(
        (response) => {
            const {data, status} = response;
            let result = data;
            console.log("Response =>", response)
            if(status == 200){
                return true;
            }else{
                return false;
            }

        
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                    }
                    toast.warning(result.message);
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.DELETE_CART_MANY_FAIL,
            });
            return Promise.reject();
        },
    );

};

export const getAllCouponCodeAction = (data) => (dispatch) => {
    return CHECKOUT_SERVICES.getAllCouponCodes(data).then(
        (response) => {
            if (response && response.status === 200) {
                const { data} = response
                return data;
            }
            else {
                console.log(response);

            }
        
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                    }
                    toast.warning(result.message);
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.DELETE_CART_MANY_FAIL,
            });
            return Promise.reject();
        },
    );

};
export const getApplyCouponCodeAction = (data) => (dispatch) => {
    return CHECKOUT_SERVICES.getApplyCouponCodes(data).then(
        (response) => {
            if (response && response.status === 200) {
                const { data} = response
                return data;
            }
            else {
                console.log(response);

            }
        
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                    }
                    toast.warning(result.message);
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            
            return Promise.reject();
        },
    );

};






