import React, { useEffect, useState, useContext } from "react";
import Header from "../../../components/elements/header/Header";
import { useFormik, FormikProvider, Formik, Field, ErrorMessage } from 'formik';
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Container,
    Row,
    Col
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ImageUploder from "../../../helpers/imageUploder";
import { bankInformationValidation } from "../../../validations/yub_schemas/bankInformationValidation";
import BankFormImg from "../../../assets/images/vendor_icons/Online-banking.svg";
import { BsFillCaretRightFill, BsFillCaretLeftFill, BsInfoCircle } from "react-icons/bs";
import DashboardHeader from '../DashboardHeader';
import { getVendorDetailsAction, updateBusinessInformationAction } from "../../../redux/dashboard/actions"
    // import MultiStepFormContext from "./MultiStepFormContext"
    ;
import { useDispatch, useSelector } from "react-redux";




const BankInformationSettings = () => {

    const [value, setValue] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const iconStyle = { color: "#FFF", fontSize: "1em" };

    const [loading, setLoading] = useState(false)

    // const { step4Details, setStep4Details, onFormSubmit, prev, loading } = useContext(MultiStepFormContext);

    const { vendor } = useSelector(state => state.business)

    useEffect(() => {
        const data = {
            id: vendor?._id
        }

        dispatch(getVendorDetailsAction(data))
            .then((res) => {
            })

    }, [vendor])

    const formik = useFormik({
        initialValues: {
            bank_username: "",
            bank_account_number: "",
            confirm_account_number: "",
            bank_ifsc: "",
            bank_account_image_url: ""
        },
        validationSchema: bankInformationValidation,
        onSubmit: (values) => {
            // ...values,
            // let data = {
            //     bank_username: values.bank_username,
            //     bank_account_number: values.bank_account_number,
            //     bank_ifsc: values.bank_ifsc
            // }
        },
    });

    const onBack = () => {
        navigate("/dashboard/settings")
    }

    return (
        <>
            <DashboardHeader />
            <div className="businessStep4 mt-5">
                <Container>
                    <Row>
                        <Col md={8} sm={12}>
                            <Form noValidate className="business-register-form text-start px-5" onSubmit={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <div className="my-5">
                                        <h4>Enter Bank Account Details</h4>
                                        <small className="text-muted">Please Enter your Active Bank details for hassle free transactions</small>
                                    </div>
                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="bankAccountName">
                                                <Form.Label className="required">Account Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Account Username"
                                                    name="bank_username"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.bank_username}
                                                    isInvalid={!!formik.touched.bank_username && !!formik.errors.bank_username}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bank_username}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="bankAccountNo">
                                                <Form.Label className="required">Account Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Account Number"
                                                    name="bank_account_number"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.bank_account_number}
                                                    isInvalid={!!formik.touched.bank_account_number && !!formik.errors.bank_account_number}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bank_account_number}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="reBankAccountName">
                                                <Form.Label className="required">Re-Enter Account Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Confirm Account Number"
                                                    name="confirm_account_number"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.confirm_account_number}
                                                    isInvalid={!!formik.touched.confirm_account_number && !!formik.errors.confirm_account_number}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.confirm_account_number}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3 p-2" controlId="ifscCode">
                                                <Form.Label className="required">IFSC Code</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="IFSC Code"
                                                    name="bank_ifsc"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.bank_ifsc}
                                                    isInvalid={!!formik.touched.bank_ifsc && !!formik.errors.bank_ifsc}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.bank_ifsc}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* <div>
                                <ImageUploder 
                                onChange={formik.handleChange}
                                value={formik.values.bank_account_image_url}
                                ></ImageUploder>
                            </div> */}
                                    <div className="text-center my-5">
                                        {
                                            loading && (
                                                <div className="text-muted text-start">
                                                    <p><span className="me-2"><BsInfoCircle /></span>This process might take longer. Please wait sometime</p>
                                                </div>
                                            )

                                        }
                                        <Row>
                                            <Col md={6} className="d-grid">
                                                <Button variant="primary" onClick={onBack} size="lg">
                                                    <BsFillCaretLeftFill style={iconStyle} /> Back
                                                </Button>
                                            </Col>
                                            <Col md={6} className="d-grid">
                                                <Button variant="primary" type="submit" size="lg">

                                                    Submit
                                                    {
                                                        loading && (
                                                            <Spinner
                                                                className="ms-3"
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </FormikProvider>
                            </Form>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="form-image-container mt-5">
                                <img className="img-fluid" src={BankFormImg} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BankInformationSettings;