import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Modal,
    Alert,
    Spinner,
    Button,
    Row,
    Col,
    Table
} from "react-bootstrap";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import("moment-timezone");

function AppointmentView(props) {
    const appointmentDetails = props?.order?.appointment
    const orderDetails = props?.order?.order_details
    const date = moment(appointmentDetails?.appointment_start_dateTime).format("YYYY-MM-DD");
    const time = moment(appointmentDetails?.appointment_start_dateTime).format("hh:mm A");

    console.log(orderDetails, "hbdfhhdfb")
    console.log(orderDetails?.[0]?.order_id, "hbdfhhdfb")

    const [status, setStatus] = useState("")

    const appointmentServices = [
        {
            id: 1,
            name: "Hair cut",
            category: "Hair",
            time: "11-5-2022",
            price: "50",

        },
        {
            id: 2,
            name: "Hair cut 1",
            category: "Hair",
            time: "11-5-2022",
            price: "20",

        },
        {
            id: 3,
            name: "Hair cut 2",
            category: "Hair",
            time: "11-5-2022",
            price: "50",

        },
        {
            id: 4,
            name: "Hair cut 3",
            category: "Hair",
            time: "11-5-2022",
            price: "20",

        },
    ]

    const customerDetails = [
        {
            username: "test user 1",
            mobile_no: "987654321",
            email_id: "test1@gmail",
            location_details: {
                address: "test address",
                city: "Coimbatore",
                state: "Tamilnadu",
                country: "India"
            },
            services: appointmentServices
        },
        {
            username: "test user 2",
            mobile_no: "987654321",
            email_id: "test2@gmail",
            location_details: {
                address: "test address",
                city: "Coimbatore",
                state: "Tamilnadu",
                country: "India"
            },
            services: appointmentServices
        },
        {
            username: "test user 3",
            mobile_no: "987654321",
            email_id: "test3@gmail",
            location_details: {
                address: "test address",
                city: "Coimbatore",
                state: "Tamilnadu",
                country: "India"
            },
            services: appointmentServices
        },
        {
            username: "test user 4",
            mobile_no: "987654321",
            email_id: "test4@gmail",
            location_details: {
                address: "test address",
                city: "Coimbatore",
                state: "Tamilnadu",
                country: "India"
            },
            services: appointmentServices
        },
        {
            username: "test user 5",
            mobile_no: "987654321",
            email_id: "test5@gmail",
            location_details: {
                address: "test address",
                city: "Coimbatore",
                state: "Tamilnadu",
                country: "India"
            },
            services: appointmentServices
        },
    ]

    const onChangeFilter = (e) => {
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

                <div className="appointment-view-body">
                    <h4 className="mb-4">
                        Appointment Details
                    </h4>
                    <Row>
                        <Col md={12}>
                        <Table responsive borderless className='order-info-tabel px-0 mx-0'>
                                        <tbody>
                                           
                                                  <tr>
                                                <td>
                                                    <p className='myOrder-id-info'> Order ID </p>
                                                </td>
                                                <td>
                                                <span className="text-muted">
                                                {orderDetails?.[0]?.order_id}
                                            </span>
                                                </td>
                                            </tr>
                                                
                                       
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'> 
                                                    Date
                                                     </p>
                                                </td>
                                                <td>
                                                <span className="text-muted"> {date}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'>Time</p>
                                                </td>
                                                <td>
                                                <span className="text-muted">
                                                {time}
                                            </span>
                                                </td>
                                            </tr>
                                          

                                        </tbody>
                                </Table>
                            {/* {
                                orderDetails && (<h6>Order ID:&nbsp;
                                    <span className="text-muted">
                                        {orderDetails?.order_id}
                                    </span>
                                </h6>)
                            }
                            <h6>Date:&nbsp;
                                <span className="text-muted">
                                    {date}
                                </span>
                            </h6>
                            <h6>Time:&nbsp;
                                <span className="text-muted">
                                    {time}
                                </span>
                            </h6> */}
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                    <hr />
                    <div className="appointment-customer-details">
                        <h4>
                            Customer Details
                        </h4>

                        <div>
                            <Row>
                                <Col md={10}>
                                <Table responsive borderless className='order-info-tabel px-0 mx-0'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'>  Name </p>
                                                </td>
                                                <td>
                                                <span className="text-muted">
                                                {appointmentDetails?.client_details?.[0]?.username || "Test user"}
                                            </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'> Phone No </p>
                                                </td>
                                                <td>
                                                <span className="text-muted">
                                                {appointmentDetails?.client_details?.[0]?.mobile_no}
                                            </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className='myOrder-id-info'> Email Id  </p>
                                                </td>
                                                <td>
                                                <span className="text-muted">
                                                {appointmentDetails?.client_details?.[0]?.email_id || "testuser@gmail.com"}
                                            </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                </Table>
                                 
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <hr />


                    <div className="order-customer-details">
                        <h4>
                            Services
                        </h4>

                        <div className="order-services-table-view">
                            <div class="container mt-5 px-2">
                                <div class="table-responsive slaylewks-table">
                                    <table class="table table-responsive table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="table-head">
                                                    <h6>Service name</h6>
                                                </th>
                                               
                                                <th scope="col" class="table-head">
                                                    <h6>Original Price</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                    <h6>Discount</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                    <h6>Price</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                    <h6>Duration</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                appointmentDetails && appointmentDetails?.service_details && appointmentDetails?.service_details.length > 0 ?
                                                    (
                                                        appointmentDetails?.service_details.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td class="sss">{item?.service_name}</td>
                                                                    <td>{item?.service_original_price?.$numberDecimal}</td>

                                                                    <td>{item?.service_discount?.$numberDecimal}</td>
                                                                    <td>{item?.service_price?.$numberDecimal}</td>
                                                                    <td>{item?.duration?.$numberDecimal} mins</td>
                                                                    {/* <td><BsThreeDotsVertical /></td> */}

                                                                </tr>
                                                            );
                                                        })

                                                    )
                                                    :

                                                    (
                                                        <div className="mt-5 justify-content-center">
                                                            No Orders Found
                                                        </div>
                                                    )

                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <div className="order-customer-details">
                        <h5 className="mb4">
                            Appointment Details
                        </h5>

                        <div className="order-services-table-view">
                            <div class="container mt-5 px-2">
                                <div class="table-responsive slaylewks-table">
                                    <table class="table table-responsive table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="table-head">
                                                    <h6>Username</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                    <h6>Email Id</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                    <h6>Phone No</h6>
                                                </th>
                                                <th scope="col" class="table-head">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                customerDetails && customerDetails.length > 0 ?
                                                    (
                                                        customerDetails.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr
                                                                        key={index}
                                                                        data-toggle="collapse"
                                                                        data-target=".multi-collapse1"
                                                                        aria-controls="multiCollapseExample1"
                                                                    >
                                                                        <td class="sss">{item?.username}</td>
                                                                        <td>{item?.email_id}</td>
                                                                        <td>{item?.mobile_no}</td>
                                                                        <td

                                                                        >
                                                                            <BsThreeDotsVertical />
                                                                        </td>

                                                                    </tr>
                                                                    <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                                                                        <td>Child col 1</td>
                                                                        <td>Child col 2</td>
                                                                        <td>Child col 3</td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })

                                                    )
                                                    :

                                                    (
                                                        <div className="mt-5 justify-content-center">
                                                            No Orders Found
                                                        </div>
                                                    )

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default AppointmentView;