import React, { useEffect, useState } from 'react'
import Header from "../../../components/elements/header/Header";
import Footer from "../../../components/elements/footer/Footer"
import MenImg from "../../../assets/images/men.png"
import TimerImg from "../../../assets/images/timer.png";
import CancelOrderImg from "../../../assets/images/cancel-order.png";
import RescheduleImg from "../../../assets/images/reschduled.png";
import SupportCenterImg from "../../../assets/images/support-center.png";
import CancelSuccess from "../../../assets/images/cancel-sucess.png";
import { Accordion, Card, Form, Row, Col, Button, Badge, Container } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUserOneOrderAction } from "../../../redux/user/actions";
import { raiseRefund } from '../../../redux/payment/actions';
import { useFormik, FormikProvider, Formik, Field } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import RazorpayImg from "../../../assets/images/Razorpay.svg"
import YesSuccess from "../../../assets/images/PaymentSucessNew.svg"
import {MdOutlineNavigateNext} from "react-icons/md"
import CartFooter from '../../../components/elements/footer/CartFooter';
import PaymentFailed from './PaymentFailed';

function CustomToggle({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <a class="btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>
            <h5>{children}</h5>
        </a>
    );
}


function ContinueButton({ children, eventKey }) {

    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button class="continue-btn" data-bs-toggle="collapse" onClick={decoratedOnClick}>Continue</button>
    );
}


function CancellationSucess() {

    const [searchParams] = useSearchParams();

    const [orderDetails, setOrderDetails] = useState({})
    const [loading, setLoading] = useState(false)

    const type = searchParams.get("type");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();

    const redirectPaymentDetails = (type) => {
        navigate(`/business/payment/${id}?type=${type}`)
    }

    const redirectReschedule = () => {
        navigate(`/payment/checkout?id=${id}&type=reschedule`)
    }

    const redirectToExplore = () => {
        navigate('/explore')
    }
    const redirectSupport = () => {
        navigate('/support')
    }
    


    const formik = useFormik({
        initialValues: {
            refundReason: 'One',
            refundMessage: '',
            payment_id: '',
            confirmation: 'false'
        },
    });

    useEffect(() => {
        if (id) {
            let data = {
                id: id,
                type: "",
            }
            dispatch(getUserOneOrderAction(data))
                .then((res) => {
                    const { results } = res;

                    setOrderDetails(results)
                    
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [id]);


    const redirectOrdersPage = () => {
        navigate("/orders");
    }

    const backToHome = () => {
        navigate("/")
    }

    const raiseRefunds = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (formik.values.confirmation !== 'true') {
            toast.warning('Please select mode of refund.');
            setLoading(false);
            return
        }
        const obj = {
            refundReason: formik.values.refundReason,
            refundMessage: formik.values.refundMessage,
            payment_id: orderDetails.payment_id,
            order_id: orderDetails._id,
            appointment_id: orderDetails.appointment._id
        }
        const result = await dispatch(raiseRefund(obj));
        setLoading(false)
        if (result.result) {
            toast.success('Cancellation successfully raised')
            navigate(`/business/payment/${id}?type=cancellation_raised`)
        } else {
            toast.error('Could not raise a cancellation request.')
        }
    }

    return (
        <div className="payment-success">
            <section className='pb-0 mt-0 pt-0'>
                <div className='container-fluid' id='thanks-note-outer'>
                <Row>
                        <Col md={12}>
                        
                                      <div>    
                                        <Container>
                                            <Row className='text-center mt-5'>
                                            <img src={YesSuccess} alt="small-info" className="payment-success-icon" />
                                                <div className='thanks-note'>
                                                <h1 className='mt-4'>Successfully Cancelled!</h1>
                                                <p>The order cancellation details of your order has been sent to your register email address.</p>
                                                 <Card className='orderid-card'>
                                                    <h6>Cancelled on:{orderDetails?.order_id}</h6>
                                                 </Card>
                                                 <p>Cancelled on: {moment(new Date()).local().format("DD MMM YYYY")}</p>
                                                 <h5 onClick={redirectToExplore}>Continue Explore <MdOutlineNavigateNext size={18}/> </h5>
                                                </div>
                                            </Row>
                                        </Container>
                                        <hr />

                               

                                        <Container>
                    <Row>
                    <Col md={7}>
                                    <>
                                   <p className='payment-info-text'>You will receive updates from</p>
                                <Card className='payment-info-card'>
                                    <Row className='mx-2'>
                                        <Col md={7} className="payment-info-inner">
                                       <div>
                                       <Row>
                                       <div className='payment-suceess-service-info'>
                                                  <p> <ion-icon name="mail-unread-outline"></ion-icon></p>
                                                  <h6>{orderDetails?.service_provider?.business_email}</h6>
                                                  </div>
                                        </Row>
                                        </div>   
                                        </Col>
                                        <Col md={5} className="">
                                        <Row>
                                                  <div className='payment-suceess-service-info'>
                                                  <p> <ion-icon name="call-outline"></ion-icon></p>
                                                  <h6>{orderDetails?.service_provider?.business_contactNo}</h6>

                                                  </div>
                                        </Row>
                                        </Col>
                                    </Row>
                                    </Card>
                                    <Row className='payment-order-info'>
                                        <Col>
                                        <p className='payment-info-text'>Order Details</p>
                                        </Col>
                                        <Col>
                                        <h6 className='float-end ' onClick={redirectOrdersPage}>My Order <MdOutlineNavigateNext size={18} /></h6>
                                        </Col>

                                    </Row>
                                    <Card>
                                        <Card.Header className=' bg-white'>
                                    <Row className='mt-2 mb-2'>
                                        <Col md={1}>
                                        <img className='payment-info-image' src={orderDetails?.service_provider?.businesslogoURL} />
                                        </Col>
                                       <Col className='payment-orderInfo-details px-0' md={11}>
                                            <h6 className='mt-2'>{orderDetails?.service_provider?.business_name}</h6>
                                            <p className='mt-2'>{orderDetails?.service_provider?.location_details?.area + " " + orderDetails?.service_provider?.location_details?.city + " " + orderDetails?.service_provider?.location_details?.state}</p>
                                      </Col>
                                    
                                    </Row>
                                    </Card.Header>
                                    <Card.Header className='bg-white'>
                                    <Row>
                                    {
                                        orderDetails?.services && orderDetails?.services.length > 0 && (
                                            orderDetails?.services?.map((item, index) => {

                                                return (
                                                    <div key={index}>
                                                    <Row className='mb-2'>
                                                    
                                                        <Col md={6} sm={6} className="cart-checkout-item-list">
                                                        <span>{item?.gender?.[0]?.gender_name}</span>
                                                        <p class="service-heading">{item?.service_name}</p>
                                                        <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                        </Col>
                                                        <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                              <div className='cart-check-out-amount'>
                                                                <p></p>
                                                                <p></p>
                                                                <p className='mt-5'>{ item?.service_price?.["$numberDecimal"] +" ₹ "}</p>
                                                              </div>
                                                        </Col>

                                                    </Row>
                                                    <hr />

                                                </div>

                                                );
                                            })
                                        )
                                    }
                                    </Row>
                                    </Card.Header>

                                    </Card>
                                    </>

                                
                    </Col>
                    <Col md={5}>
                      <div>
                        <p className='payment-info-text'>Payment Details</p>
                        <Card>
                            <Card.Header className='pb-0 payment-info-totalCard'>
                                <Row>
                                    <Col>
                                    <h1>Grand Total:</h1>
                                    <h1>Payment Method:</h1>
                                    </Col>
                                    <Col>
                                    <div className='float-end'>
                                        <Row className='mt-1 mb-0 pb-0'>
                                            <Col className='text-end px-2'>
                                            <h6 className='float-end'>₹{Math.round(orderDetails?.final_price)}</h6>
                                            </Col>
                                            
                                        </Row> 
                                        <Row>
                                        <Col className='text-end px-2 mt-2'>
                                            <h6 className='float-end'>UPI</h6>
                                            </Col>
                                            </Row> 
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <div className='payment-total-card-inner'>
                                        <p>Price</p>
                                        <p>GST 18%</p>
                                        <p>Discount</p>
                                        <p>Coupon for you</p>
                                        <p>Grand Total</p>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className='float-end payment-total-card-inner'>
                                        <Row>
                                            <Col className='text-end px-0'>
                                            <p>{Math.round(orderDetails?.final_price + (orderDetails?.coupon/100) - (orderDetails?.gst_value/100))  + ".00" }</p>
                                             <p>{Math.round(orderDetails?.gst_value/100) + ".00"}</p>
                                             <p className='green_text'>{orderDetails?.discount + '.00'}</p>
                                             <p className='green_text'>{(orderDetails?.coupon)/100 + '.00'}</p>
                                             <p>{orderDetails?.final_price}</p>
                                            </Col>
                                            <Col className='Payment-amount-inner'>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            <p>₹</p>
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <p className='payment-info-text'>Contact Details</p>
                        <Card>
                            <Card.Body>
                                <div className='payment-user-contact-info'>
                                <p>{orderDetails?.customer_location_details?.alternate_username}</p>
                                <p>{orderDetails?.customer_location_details?.area}{orderDetails?.customer_location_details?.city}</p>
                                <h6>{orderDetails?.customer_location_details?.alternate_phone}</h6>
                                <h6>{orderDetails?.customer_location_details?.alternate_emailID}</h6>
                                </div>
                            </Card.Body>
                        </Card>
                      </div>
                      <div className='payment-sucess-need-help'>
                        <h1>Need Help?</h1>
                        <div className='d-flex'>
                        <p className='help-icons'><ion-icon name="help-buoy-outline"></ion-icon></p>
                        <p onClick={redirectSupport}>Support Center</p>
                         </div>
                      </div>
                    </Col>
                    </Row>
                </Container>
                </div>  
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default CancellationSucess