import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ziplocation from "../../assets/images/zip-location.svg";
import searchicon from "../../assets/images/search-icon.svg";
import buttonImg from "../../assets/images/button.svg";

import servicelistimg from "../../assets/images/service-list-img.webp";
import onlinebooking from "../../assets/images/online-booking.webp";
import notificationmail from "../../assets/images/notification-mail.svg";
import clientadmin from "../../assets/images/client-admin.svg";

import acceptpayment from "../../assets/images/accept-payment.svg";
import integration from "../../assets/images/integration.webp";
import customerfeatures from "../../assets/images/customer-features.webp";

import sallonspa from "../../assets/images/sallon-spa.svg";
import onlineappointment from "../../assets/images/online-appointment.svg";
import compareprice from "../../assets/images/compare-price.svg";
import waitingtime from "../../assets/images/waiting-time.svg";
import usicon from "../../assets/images/us-icon.svg";
import bookappointment from "../../assets/images/book-appointment.webp";
import { useNavigate } from "react-router-dom";

import HairImg from "../../assets/images/home/Hair.webp";
import MakeupImg from "../../assets/images/home/Makeup.webp";
import MehndiImg from "../../assets/images/home/Mehndi.webp";
import NailsImg from "../../assets/images/home/Nails.webp";
import SpaImg from "../../assets/images/home/Spa.webp";
import SkinImg from "../../assets/images/home/Skin.webp";
import {
  changeHomeSearch,
  clearHomeSearch,
  exploreAction,
} from "../../redux/business/actions";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import noSalonImg from "../../assets/images/NoSalon.svg";
import LocationSearch from "../../components/locationSearch/locationSearch";
import Select from "react-dropdown-select";
import { locationDetailsAction } from "../../redux/location/action";

function NewUserHome() {
  const [state, setState] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closeLocation = () => {
    setLoading(false);
    setShow(false);
  };
  const { locationDetails } = useSelector((state) => state.getlocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [service, setService] = useState("");

  const words = [
    "Beauty Professionals",
    "Beauty Services",
    "Beauty Stylist",
    "Beauty Salons",
  ];
  const initialCity = "Muscat";
  useEffect(() => {
    let data = {
      user_id: "",
      page: 1,
      location_search: "",
      shopNameSearch: "",
      homelocation: locationDetails ? locationDetails : initialCity,
      serviceNameSearch: "",
      services_filter: "",
      servicetypes_filter: "",
      gender_filter: "",
      service_shop_search: "",
    };
    dispatch(exploreAction(data)).then((res) => {
      setState(res);
    });
  }, [locationDetails]);

  useEffect(() => {
    // if (state?.salonCount == 0) {
    //   setShow(true);
    // } else {
    //   setShow(false);
    // }
  });
  // useEffect(()=>{
  //  let values={initialValues: {
  //         location: "Chennai",
  //       }
  //     }
  //       dispatch(locationDetailsAction(values))
  // })

  const exploreServices = () => {
    if (service !== "" || address !== "") {
      let homeSearch = {
        address: address,
        service: service,
      };
      dispatch(changeHomeSearch(homeSearch));
      navigate("/explore");
    }
  };

  useEffect(() => {
    dispatch(clearHomeSearch());
  }, []);

  const searchAddress = (e) => {
    setAddress(e.target.value);
  };

  const searchService = (e) => {
    setService(e.target.value);
  };

  const selectServiceSearch = (type) => {
    setService(type);
    let homeSearch = {
      address: "",
      service: "",
      category: [type],
    };
    dispatch(changeHomeSearch(homeSearch));
    navigate("/explore");
  };

  const redirectExplorePage = () => {
    navigate("/explore");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      exploreServices(e);
    }
  };

  return (
    <div className="new-user-home">
      <section class="user-home-banner">
        {/* <img src={usercompressedimg} alt="user-bannerimg" class="img-fluid" /> */}
        <div class="container user-home-content">
          <div class="row">
            <div class="col-lg-12">
              <div class="user-home-text">
                <p class="banner-text">
                  Discover & Book local
                  <span class="beauty-text">
                    <Typewriter
                      words={words}
                      loop={25}
                      cursor
                      cursorStyle="_"
                      typeSpeed={300}
                      deleteSpeed={150}
                      delaySpeed={1000}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="home-search-outer mt-5">
            <div className="home-search-icon">
              <span className="fs-1 mt-4 mx-0">
                <ion-icon name="search-outline"></ion-icon>
              </span>
            </div>
            <div className="home-search-container">
              {/* <Select 
                                    className='form-control home-search-input-box location-flex-box' 
                                     placeholder="Chennai">

                                    </Select> */}
              <input
                type="button"
                value={locationDetails ? locationDetails :initialCity}
                class="form-control home-search-input-box location-flex-box"
                id=""
                placeholder="Chennai"   
                name="search"
                onClick={() => setLoading(true)}
                onInput={searchAddress}
              />
              <span class="vertical-line"></span>
              <input
                type="text"
                class="form-control home-search-input-box service-search-flex-box"
                id=""
                placeholder="Services, Stylist or Salon...."
                name="search"
                onInput={searchService}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="home-enter-icon">
              <span className="fs-2  mx-0" onClick={exploreServices}>
                <ion-icon name="arrow-forward"></ion-icon>
              </span>
            </div>
          </div>
          <div class=" city-zip-outer">
            {/* <div class="zip-inner">
                                        <div>
                                            <Row className='home-search-outer'>
                                                <Col lg={12} md={12} sm={12} className="d-flex">
                                                    <img src={ziplocation} alt="zip-location" class="img-fluid zip-location" />
                                                    <input type="text" class="form-control city-zipcode" placeholder="City or Zip code...." onInput={searchAddress} />
                                                    <img src={searchicon} alt="search-icon" class="img-fluid service-location" />
                                                    <input type="text" class="form-control service-stylist" placeholder="Services, Stylist or Salon...." onInput={searchService} />
                                                    <a href="JavaScript:void(0);" class="search-btn"><img src={buttonImg} alt="button" class="img-fluid button-location" onClick={exploreServices} /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                     */}
            <div class="service-gender-user">
              <ul>
                <li>
                  <p>3000+</p>
                  <span>Service's</span>
                </li>
                <li>
                  <p>For All</p>
                  <span>Gender</span>
                </li>
                <li>
                  <p>2500+</p>
                  <span>User's</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div class="user-section">
        <section className="explore-service-outer">
          <div class="container">
            <div class="row">
              <div class="col-md-12 title-view">
                <h3 class="user-common-title">
                  Explore<span> Services</span>
                </h3>
                <a
                  href="JavaScript:void(0);"
                  class="view-all cur-pointer"
                  onClick={redirectExplorePage}
                >
                  View all
                </a>

                <div class="explore-service-list">
                  <Row>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Hair")}
                      >
                        <img
                          src={HairImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Hair</span>
                      </div>
                    </Col>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Nails")}
                      >
                        <img
                          src={NailsImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Nails</span>
                      </div>
                    </Col>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Mehendi")}
                      >
                        <img
                          src={MehndiImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Mehendi</span>
                      </div>
                    </Col>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Spa")}
                      >
                        <img
                          src={SpaImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Spa</span>
                      </div>
                    </Col>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Makeup")}
                      >
                        <img
                          src={MakeupImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Makeup</span>
                      </div>
                    </Col>
                    <Col md={2} xs={4}>
                      <div
                        class="explore-list"
                        onClick={() => selectServiceSearch("Skin")}
                      >
                        <img
                          src={SkinImg}
                          alt="service-list-img"
                          class="mobile-img"
                        />
                        <span>Skin</span>
                      </div>
                    </Col>
                  </Row>
                  {/* <ul>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Hair")}>
                                                <img src={HairImg} alt="service-list-img" class="mobile-img" />
                                                <span>Hair</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Nails")}>
                                                <img src={NailsImg} alt="service-list-img" class="mobile-img" />
                                                <span>Nails</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Mehendi")}>
                                                <img src={MehndiImg} alt="service-list-img" class="mobile-img" />
                                                <span>Mehendi</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Spa")}>
                                                <img src={SpaImg} alt="service-list-img" class="mobile-img" />
                                                <span>Spa</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Makeup")}>
                                                <img src={MakeupImg} alt="service-list-img" class="mobile-img" />
                                                <span>Makeup</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="explore-list" onClick={() => selectServiceSearch("Skin")}>
                                                <img src={SkinImg} alt="service-list-img" class="mobile-img" />
                                                <span>Skin</span>
                                            </div>
                                        </li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="container our-features-header">
          <h3 class="user-common-title">
            Our<span> Features</span>
          </h3>
        </div>
        <section class="our-features-outer">
          <div class="container mb-5 mt-5">
            <div class="row">
              <div class="col-md-12">
                {/* <h3 class="user-common-title">Our<span> Features</span></h3> */}
                {/* <div class="client-main">
                                    <ul>
                                        <li><a href="JavaScript:void(0);">Main</a></li>
                                        <li><a href="JavaScript:void(0);">For Clients</a></li>
                                    </ul>
                                </div> */}
                <div>
                  <Row className="text-center mb-5">
                    <Row>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={onlinebooking}
                            alt="online-booking"
                            class="img-fluid"
                          />
                          <p>Accept online bookings</p>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={notificationmail}
                            alt="notification-mail"
                            class="img-fluid"
                          />
                          <p>Notifications via SMS/Email</p>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={clientadmin}
                            alt="client-admin"
                            class="img-fluid"
                          />
                          <p>Client & Admin App</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={acceptpayment}
                            alt="accept-payment"
                            class="img-fluid"
                          />
                          <p>Accept Payments</p>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={integration}
                            alt="integration"
                            class="img-fluid"
                          />
                          <p>Integration & API</p>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={4}>
                        <div class="our-features-images text-center">
                          <img
                            src={customerfeatures}
                            alt="customer-features"
                            class="img-fluid"
                          />
                          <p>Custom features</p>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="how-works-outer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h3 class="user-common-title mt-5">
                  How it<span> Works</span>
                </h3>
                <div class="how-works-list">
                  <Row>
                    <Col md={3} sm={6} xs={6}>
                      <Card className="how-it-works-card">
                        <img
                          src={sallonspa}
                          alt="sallon-spa"
                          class="img-fluid works-img"
                        />
                        <div class="card-body">
                          <h5 class="card-title">Find Salons and Spas</h5>
                          <p class="card-text">
                            Discover the widest selection of salons, parlours
                            and spas in and around your area.
                          </p>
                        </div>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} xs={6}>
                      <Card className="how-it-works-card">
                        <img
                          src={onlineappointment}
                          alt="online-appointment"
                          class="img-fluid works-img"
                        />
                        <div class="card-body">
                          <h5 class="card-title">Online Appointments</h5>
                          <p class="card-text">
                            Select your preferred time slot and book
                            appointments online with just a tap or two.
                          </p>
                        </div>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} xs={6}>
                      <Card className="how-it-works-card">
                        <img
                          src={compareprice}
                          alt="compare-price"
                          class="img-fluid works-img"
                        />
                        <div class="card-body">
                          <h5 class="card-title">Compare Prices</h5>
                          <p class="card-text">
                            Evaluate different outlets, their services and
                            prices and pick the one that fits your needs.
                          </p>
                        </div>
                      </Card>
                    </Col>
                    <Col md={3} sm={6} xs={6}>
                      <Card className="how-it-works-card">
                        <img
                          src={waitingtime}
                          alt="waiting-time"
                          class="img-fluid works-img"
                        />
                        <div class="card-body">
                          <h5 class="card-title">Zero Waiting Time</h5>
                          <p class="card-text">
                            No more waiting in the queues. Turn up to the venue
                            at your selected time and enjoy.
                          </p>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="customer-review-outer">
                    <div class="container">
                        <div class="row" id='carousel-system-view'>
                            <div class="col-md-12">
                                <h3 class="user-common-title">Customer<span> Review</span></h3>
                                <Carousel>
                                <div className='mb-4 mt-4'>
                                 <Row >
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={6} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                    <Col>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div> 
                            </Carousel>
                            </div>
                        </div>

                        <div class="row" id='carousel-mobile-view'>
                            <div class="col-md-12">
                                <h3 class="user-common-title">Customer<span> Review</span></h3>
                                <Carousel>
                                <div className='mb-4 mt-4'>
                                 <Row >
                                    <Col md={12} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                  
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={12} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div>
                                <div className='mb-4 mt-4'>
                                 <Row>
                                    <Col md={12} sm={12}>
                                        <Card className='carosel-review-card'>
                                        <Card.Img variant="top" src={usicon} alt="us-icon" class="carousel-icon" />
                                        <Card.Body> 
                                            <Card.Text>
                                            <h6 class="review-comment">Lorem ipsum dolor sit amet, vince adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute </h6>
                                            </Card.Text>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                <h6 className='float-start'>User Name</h6>
                                                 <h6 class="float-start">Coimbatore, 25 jun 2022</h6></Col>
                                                <Col></Col>
                                            </Row>
                                        </Card.Body>
                                        </Card>     
                                    </Col>
                                 </Row>
                                </div> 
                            </Carousel>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section class="book-appointment-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="book-appointment-outer">
                  <img
                    src={bookappointment}
                    alt="book-appointment"
                    class="img-fluid"
                  />
                  <div class="discount-booking-text">
                    <p>Book Appointment</p>
                    <h2 className="discount-font">
                      Get ₹200 discount
                      <br />
                      off your first booking
                    </h2>
                    <span>
                      Get your discount by book appointment from 01 Dec 2022 -
                      31 Jan 2023{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <Modal size="md" show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="no-salons-available">
              <img src={noSalonImg} className="mb-2" />
              <h1>We are coming soon</h1>
              <p>
                Thanks for reaching out, but unfortunately there are no
                providers at this location at the moment. Try a different
                location
              </p>
              <h6 onClick={() => setLoading(true)}>Change Location</h6>
            </div>
          </Modal.Body>
        </Modal>

        {loading ? (
          <LocationSearch info={loading} closeSearch={closeLocation} />
        ) : null}
      </div>
    </div>
  );
}

export default NewUserHome;
