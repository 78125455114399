import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import paymentReducer from './payment/reducer';
import businessReducer from './business/reducer';
import dashboardReducer from './dashboard/reducer';
import cartReducer from './cart/reducer';
import userReducer from './user/reducer';
import locationReducer from './location/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    payment: paymentReducer,
    business: businessReducer,
    dashboard: dashboardReducer,
    cart: cartReducer,
    user: userReducer,
    getlocation: locationReducer,
});

export default rootReducer;
