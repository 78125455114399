import React, {useState, useEffect} from 'react'
import { AdminSidebar, businessSidebarNew } from "../../../configs/sidebarConfig";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SupportImg from "../../../assets/images/support.png"
import logoutImg from "../../../assets/images/logout.png";
import { logoutFromLocalStorage } from '../../../redux/auth/actions';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { MdAdsClick } from "react-icons/md";
import chevron from '../../../assets/images/chevron-down-outline.svg'



function AdminSidebarPage() {
    const [show, setShow] = useState(false);
    // const [showdropdown, setShowDropDown] = useState(false)
    const [showdropdown, setShowDropDown] = useState(localStorage.getItem('showdropdown') === 'true' ? true : false); // set initial state from localStorage

    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(""); 
    const [activeUrl, setActiveUrl] = useState(""); 

    
    if (activeUrl !== window.location.pathname){
        setActiveUrl(window.location.pathname);
        const DashboardActive = window.location.pathname.split('/')
        if(DashboardActive[2]){
            setActiveTab(DashboardActive[2])
        } else {
            setActiveTab("dashboard")
        }
    }
    
    
    const handleLogout = () => {
        dispatch(logoutFromLocalStorage());
        navigate("/");
    };

    const dropdown_menu = (e) => {
        e.preventDefault()
        setShowDropDown(!showdropdown)
    }

    useEffect(() => {
        const handleTabClose = event => {
          event.preventDefault();
          localStorage.setItem('showdropdown', false);
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
        };
      }, []);

    useEffect(() => {
        localStorage.setItem('showdropdown', showdropdown);
    }, [showdropdown]);




    return (
        <div className='container-xxl'>
            <div id="mobile-view">
            <Button variant="" className='menu-btn-dashboard' onClick={handleShow}>
             DashBoard <span><MdAdsClick /></span>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dashboard</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div class="business-left-menu">
        <ul>
                {
                    AdminSidebar.map((tab, index) => {
                        return (
                            <Link to={tab.link} key={index}>
                                <li className={activeTab.includes(tab.heading.toLowerCase()) ? "active-menu" : ""}>
                                    <img src={tab.image} class="img-fluid" alt={tab.heading}  />
                                    {/* <a href="JavaScript:void(0);" >{tab.heading}</a> */}
                                    {tab.heading == "Marketing"?
                                        <a href="JavaScript:void(0);" onClick={(e)=>dropdown_menu(e)} >{tab.heading}</a>:
                                        <a href="JavaScript:void(0);" >{tab.heading}</a>

                                    }
                                    {tab.dropdown && (
                                        
                                        <img
                                            src={chevron}
                                            className="img-fluid"
                                            style={showdropdown?{transform: "rotate(180deg)",marginLeft: "10px"}: {marginLeft: "10px"}}
                                            alt={tab.heading}
                                            onClick={(e)=>dropdown_menu(e)}
                                        />
                                    )}
                                    {showdropdown && tab.dropdown && (

                                        tab.dropdown.map((menu, index) => {

                                            return (
                                                <Link to={menu.link} key={index} id="sub-heading">
                                                    <li className={activeTab.includes(menu.heading.toLowerCase()) ? "active-menu" : ""} style={{marginTop:"5px"}} >
                                                        <a href="JavaScript:void(0);" >{menu.heading}</a>
                                                    </li>
                                                </Link>
                                                // <li>
                                                //     <a href="JavaScript:void(0);"  >{menu.label}</a>
                                                // </li>
                                            )
                                        })


                                    )}
                                </li>
                            </Link>
                        )
                    })
                }
                <div class="support-logout">
               
                    <li><img src={logoutImg} class="img-fluid" alt="Log out" />
                        <a href="JavaScript:void(0);" class="logout" onClick={handleLogout}>Log out</a></li>
                </div>
            </ul>
            </div>
        </Offcanvas.Body>
      </Offcanvas>

         
           </div>

           <div class="business-left-menu" id='system-view'>
            <ul>
                {
                    AdminSidebar.map((tab, index) => {
                        return (
                            <Link to={tab.link} key={index}>
                                <li className={activeTab.includes(tab.heading.toLowerCase()) ? "active-menu" : ""}>
                                    <img src={tab.image} class="img-fluid" alt={tab.heading}  />
                                    {tab.heading == "Marketing"?
                                        <a href="JavaScript:void(0);" onClick={(e)=>dropdown_menu(e)} >{tab.heading}</a>:
                                        <a href="JavaScript:void(0);" >{tab.heading}</a>

                                    }
                                    {/* <a href="JavaScript:void(0);" >{tab.heading}</a> */}

                                    {tab.dropdown && (
                                        
                                        <img
                                            src={chevron}
                                            className="img-fluid"
                                            style={showdropdown?{transform: "rotate(180deg)",marginLeft: "10px"}: {marginLeft: "10px"}}
                                            alt={tab.heading}
                                            onClick={(e)=>dropdown_menu(e)}
                                        />
                                    )}
                                </li>
                                {/* {tab.heading == "Marketing"? */}
                                
                                { showdropdown && tab.dropdown && (

                                tab.dropdown.map((menu, index) => {
                                    {console.log(" the dropdown menu called ")}

                                 
                                    return (
                                        <Link to={menu.link} key={index} >
                                            <li className={activeTab.includes(menu.heading.toLowerCase()) ? "active-menu" : ""} style={{paddingLeft:"15%"}} > 
                                                <a href="JavaScript:void(0);" >{menu.heading}</a>
                                            </li>
                                        </Link>
                                    // <li>
                                    //     <a href="JavaScript:void(0);"  >{menu.label}</a>
                                    // </li>
                                    )
                                }) 
                                
                                
                                )}

                            </Link>
                        )
                    })
                }
                <div class="support-logout">
               
                    <li><img src={logoutImg} class="img-fluid" alt="Log out" />
                        <a href="JavaScript:void(0);" class="logout" onClick={handleLogout}>Log out</a></li>
                </div>
            </ul>
           </div>
        </div>
    )
}

export default AdminSidebarPage;