import React, { useEffect, useState } from 'react';
import InfoImg from "../../assets/images/info.png"
import MenImg from "../../assets/images/men.png"
// import ProfessionalImg from "../../assets/images/professional.png"
import timerImg from "../../assets/images/timer.png";
import CloseImg from "../../assets/images/close.png";
// import smallInfoImg from "../../assets/images/small-info.png";
// import secureImg from "../../assets/images/secure.png";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, ReadCartActions, clearCartAction } from '../../redux/cart/actions';
import { useNavigate } from "react-router-dom";
import OrderSummary from './OrderSummary';
import {Button, Modal, Row, Col} from "react-bootstrap"

function CartSummary() {

    // const [price, setPrice] = useState(0);
    // const [discount, setDiscount] = useState(0);
    // const [coupon, setCoupon] = useState(0);
    // const [serviceCharge, setServiceCharge] = useState(0);
    // const [gst, setGst] = useState(0);
    // const [totalAmount, setTotalAmount] = useState(0);

    const { servicesCart, serviceProviderCart } = useSelector((state) => state.cart);
    const { user, role, isLoggedIn } = useSelector((state) => state.auth);

    
    const [remove, setRemove] = useState(false);
    const handleClose = () => setRemove(false);
    const handleShow = () => setRemove(true);
    const [select, setSelect] = useState("");
    const [serviceDeleted, setServiceDeleted] = useState(false)


    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setServiceDeleted(true)
    }, []);

    const removeServiceFromCart = async (service) => {
       await dispatch(removeFromCart({...select, id: select?.cart_id}))
       await dispatch(ReadCartActions({user:user?._id}))
       setRemove(false)
        setServiceDeleted(true)
        // onServiceDeleted();
    }

    const handleServiceDeleted = () => {
        setServiceDeleted(false);
      };
      


    const removeCart  = async (service) => {
    if (isLoggedIn && role?.role_name === "user") {
        if (user?.email_verified) {
            if (serviceProviderCart?._id) {
                        let cartIds = servicesCart?.map(item => item?.cart_id)
                        if(cartIds?.length > 0){
                           let clearCartDetails =  await dispatch(clearCartAction({id : cartIds}));
                           if(clearCartDetails){
                                    await dispatch(ReadCartActions({user: user?._id}));
                                }
                        }   
                        else{
                            console.log("no cart found");
                        }                 
                    }
            }    
        } 
}

    const removeItemCart = (service) => {
    setSelect(service)
    setRemove(true);
}



    const goBack = () => {
        // navigate(-1);
        navigate(`/business/${serviceProviderCart?._id}`);
    }

    return (
        <div className="cart-summary-page">
            <section>
                <div class="container">
                        <Row>
                            <Col md={7} sm={12}>
                                <div className='cart-component'>
                                <Row className='mt-3 cart-service-header'>
                                  <Col md={6}>
                                  <h6>Services</h6>
                                  </Col>
                                  <Col md={6}>
                                  <p onClick={goBack}>
                                  Add new services
                                  </p>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className='cart-serviceprovider-details' md={12}>
                                    <h6>{serviceProviderCart?.business_name}</h6>
                                    <p>{serviceProviderCart?.location_details?.area + " " + serviceProviderCart?.location_details?.city}</p>
                                    </Col>
                                    <hr />
                                </Row>
                                
                                <Row>
                                    <div>
                                {
                                        servicesCart && servicesCart?.length > 0 && (
                                            servicesCart.map((item, index) => {
                                                let gender = ""
                                                if (item?.gender && item?.gender?.length > 0) {
                                                    gender = item?.gender?.map((i) => i.gender_name).join(" ")
                                                }
                                                let itemPrice = item?.service_price?.["$numberDecimal"];
                                                let servicePrice = Math.round(itemPrice)
                                                
                                                return (
                                                    <Row>
                                                        {item?.duration &&
                                                        <div key={index}>
                                                            <Row className='mb-2'>
                                                            
                                                                <Col md={6} sm={6} className="cart-checkout-item-list">
                                                                <span>{gender}</span>
                                                                <p class="service-heading">{item?.service_name}</p>                                       
                                                                <h6><span className='me-2'><ion-icon name="timer-outline"></ion-icon></span>{item?.duration?.["$numberDecimal"]} mins</h6>
                                                                </Col>
                                                                <Col md={6} sm={6} className="cart-checkout-amount-list">
                                                                      <div className='cart-check-out-amount'>
                                                                       <span onClick={()=>{removeItemCart(item)}}>
                                                                      <ion-icon name="trash-outline"></ion-icon>
                                                                    
                                                                        </span>
                                                                        <p className='mt-4'>{ servicePrice + ".00" + " ₹ "}</p>
                                                                       
                            
                                                                      </div>
                                                                </Col>
                                                            
                                                            </Row>
                                                        
                                                        </div>
                                                        }
                                                        {item?.duration &&
                                                        <hr />
                                                        }  
                                                    </Row>  
                                                                                              
                                                )
                                                                                           
                                            })
                                        )
                                    } 
                                    </div>
                                </Row>
                                <Modal show={remove} onHide={handleClose}
                                                size="md"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered>
                                                        <Modal.Header closeButton>
                                                        
                                                        </Modal.Header>
                                                        <Modal.Body className='mb-5'>
                                                            <h5 class="text-center">Remove Service</h5>
                                                        <p class="text-center mt-4">Are you sure you want to Remove?</p>
                                                        <Row className='mt-3'>
                                                            <Col className='text-center'>
                                                            <Button className='replace-cancel-btn' onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        
                                                            </Col>
                                                            <Col className='text-center'>
                                                            <Button className='replace-btn' onClick={()=>{removeServiceFromCart()}}>
                                                            Remove
                                                        </Button>
                                                            </Col>
                                                        </Row>
                                                        </Modal.Body>
                                    </Modal>
                                    </div>
                            </Col>
                            <Col md={5}>
                            <OrderSummary continue={true} serviceDeleted={serviceDeleted} onServiceDeleted={handleServiceDeleted} />

                            </Col>

                        </Row>
                </div>
            </section>
        </div>
    )
}

export default CartSummary