import React, { useState, useEffect } from 'react';
import { getAllOrders, deleteOrder, getAllRequest, requestAcceptDenyAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical, BsFillCalendar3WeekFill} from "react-icons/bs";
import { Pagination, Dropdown, Badge } from "react-bootstrap";
import OrderView from './OrderView';
import {Row, Col, Modal, Button, Table} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import TickImg from "../../../assets/images/Tick.svg";
import WrongImg from "../../../assets/images/Wrong.svg";

function OrderRequestList() {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [viewOrder, setViewOrder] = useState({});
  const [page, setPage] = useState(0);
  const [selectallCheck, setSelectallCheck] = useState(false)
  const [selectSort, setSelectSort] = useState("desc");
  const [selectedItems, setSelectedItems] = useState([]);
  const [successModal, setSucessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);

  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
    total: 0
  });

  const dispatch = useDispatch();

  const { orders } = useSelector((state) => state.dashboard);
  const { vendor } = useSelector((state) => state.business);

  const [show, setShow] = useState(false);
  const [stateDate, SetStateDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


  useEffect(() => {
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      search:"" ,
      request_status: filterBy,
      sortType: selectSort,
    };
    if (search) data.search = search
    dispatch(getAllRequest(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }, [filterBy, selectSort]);

  const readAgain = () => {
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      search:"" ,
      request_status: filterBy,
      sortType: selectSort,
    };
    dispatch(getAllRequest(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }

  const handleOrderDataChange = () => {
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage
    };
    dispatch(getAllRequest(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }


  const handlePageChange = (pageNumber) => {
    setState((prev) => ({ ...prev, activePage: pageNumber?.selected + 1 }));
    setPage(pageNumber.selected);
    const data = {
      serviceProvider: vendor._id,
      page: pageNumber?.selected +1,
      search:"" ,
      request_status: filterBy,
      sortType: selectSort,
    };
    if (search) data.search = search
    dispatch(getAllRequest(data))
      .then((res) => {
      
        const { results, total_count, resultsPerPage } = res
        results.map((checkItem)=>{
            checkItem.isChecked = false;
           });
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })

  };


 const [acceptModal, setAcceptModal] = useState(false);
 const [rejectModal, setRejectModal] = useState(false);
const [selectedRequest, setSelectedRequest] = useState("")

  const handleAcceptChange = (id) => {
    setSelectedRequest(id);
    setAcceptModal(true);
  }
  const acceptRequest = async() => {
    const data = {
      id:selectedRequest,
      request_status:"accept"
    };

    await dispatch(requestAcceptDenyAction(data))
      .then((res) => {
        setAcceptModal(false);
        setSucessModal(true);
        readAgain();
      })
      .catch((err) => {
        console.log("Err => ", err)
      })

  }
  const rejectRequest = async()=>{
    const data = {
      id:selectedRequest,
      request_status:"deny"
    };

    await dispatch(requestAcceptDenyAction(data))
      .then((res) => {
        setRejectModal(false);
        setFailureModal(true);
        readAgain();
      })
      .catch((err) => {
        console.log("Err => ", err)
      })
  }

  const handleRejectChange = (id) => {
    
    setSelectedRequest(id);
    setRejectModal(true);
  }

  const handleViewPage = (id) =>{
    navigate(`/open/request/${id}`)
  }

  const onCheckboxBtnClick = (item, e) => {
    item.isChecked = e.target.checked;
  
    if (item.isChecked) {
      selectedItems.push(item);
    } else {
      selectedItems.splice(item, 1);
    }
    let isAllSelect = state.data.every((item)=>{
      return item.isChecked == true;
    })
    setSelectedItems([...selectedItems]);
    if (isAllSelect){
      setSelectallCheck(true);
    } else{
      setSelectallCheck(false);
    }
  };

  const selectAllCheckBox =(event)=>{
    if (event.target.checked) {
      state.data.map((item)=>{
        selectedItems.push(item);
        return item.isChecked = true;
      });
  
      setSelectedItems([...selectedItems]);
      setSelectallCheck(true);
    }
     else {
      state.data.map((item)=>{
        selectedItems.splice(item, 1);
        return item.isChecked = false;
      });
      setSelectedItems([]);
      setSelectallCheck(false);
  
    }
    
  }
//   const multiDelete =()=>{
//     let selectedItemId = selectedItems.map((service) => {
//         return service._id;
//       })
//       const data = {
//                 id: selectedItemId,
//              }
//              dispatch(deleteAllAdminCouponCodeAction(data))
//              setDeleteConform(false);
//              getAllCouponList();
//              setSelectallCheck(false);
//              setSelectedItems([]);
//         }


  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
    const data = {
      serviceProvider: vendor._id,
      search: e.target.value,
      page: 1
    };

    dispatch(getAllRequest(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  };



  const getOrderStatus = (status) => {
    switch (status) {
      case "completed":
        return (<Badge bg="success">Completed</Badge>)
      case "pending":
        return (<Badge bg="warning">Pending</Badge>)
      case "cancelled":
        return (<Badge bg="danger">Cancelled</Badge>)
      default:
        break;
    }
  }
  
  const dateRange = () => {

    let data = {
      start : moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD"),
      end : moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD"),
    }
    
  }

  const onChangeSort = async () => {
    if(selectSort == "asc"){
      setSelectSort("desc")
     } else {
      setSelectSort("asc")
     }
    }
  const resetFilters = () =>{
    setFilterBy("");
    const data = {
      serviceProvider: vendor._id,
      page: state.activePage,
      search:"" ,
      request_status: filterBy,
      sortType: "asc",
    };
    dispatch(getAllRequest(data))
      .then((res) => {
        const { results, total_count, resultsPerPage } = res
        setState((prev) => ({
          ...prev,
          data: results,
          total: total_count / resultsPerPage
        }))
      })
  }
 

  return (

   <div className='container-xxl'>
     <div className="order-list mt-3">
      <OrderView
        show={viewOpen}
        onHide={() => setViewOpen(false)}
        order={viewOrder}
        handleOrderDataChange={handleOrderDataChange}
      />
    <div className="service-list-header-option mt-4">
      <Row>
        <Col md={6} className="mt-2">
            <h5>
            Your Orders Request            </h5>
        </Col>
        
        {/* <Col md={6}>
        <h5 class="order-calendar text-primary">
        <span className='cur-pointer' onClick={()=> setShow(true)}>
              {
                moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD") !== moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD") ? (
                  <>
                   <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                   <span className='mx-2'>to</span>
                   <span className='text-primary'>{moment(stateDate?.[0]?.endDate).format("YYYY-MM-DD")}</span>
                  </>
                ) : (
                  <>
                  <span className='text-primary'>{moment(stateDate?.[0]?.startDate).format("YYYY-MM-DD")}</span>
                  </>
                )
              }
            </span>
            <span className="text-primary mx-3">
              <BsFillCalendar3WeekFill className='cur-pointer' onClick={()=> setShow(true)} />
            </span>

            <Modal show={show}>
            <Modal.Header closeButton onClick={()=> setShow(false)} className="mt-2">
              <Button  className="date-apply" variant="" onClick={dateRange}>Apply</Button>
            </Modal.Header>
            <Modal.Body>
            <div className='calendar-view'>
            <DateRange
                editableDateInputs={true}
                onChange={item => SetStateDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={stateDate}
                minDate= {(new Date('01/01/2022'))}
              />
              </div>
              </Modal.Body>
            </Modal>
          </h5>
        </Col> */}
      </Row>
      <hr />
      <Row>
        <Col md={6} sm={12} xs={12}>
         <Col md={6} sm={12} xs={12}>
         <input type="text" class="form-control request-search-bar mb-2" id="" placeholder="Search" name="search" onInput={onChangeSearch} />
         </Col>
    
        </Col>
        <Col md={6}>
                         <Row className=''>
                         <Col md={7} sm={6} xs={6}>
                          <p className='request-headers-reset' onClick={resetFilters}>Reset Filters</p>
                          </Col>
                          <Col md={3} sm={3} xs={3}>
                          <select class="form-select mx-2"
              aria-label="Default select example"
              name="filterService"
              onChange={(e)=>setFilterBy(e.target.value)}
              value={filterBy}
            >
              <option value="">Filter</option>
              <option value="pending">Pending</option>
              <option value="accept">Accepted</option>
              <option value="deny">Denied</option>
              <option value="cancelled">Cancelled</option>
            </select>

                          </Col>
                          <Col md={2} sm={2} xs={2}>
                          <Button className='fiter-btn-coupon' onClick={onChangeSort}>
                          <ion-icon name="swap-vertical-outline"></ion-icon>
                          </Button>
                          </Col>
                          </Row>
                         
        </Col>
      </Row>
      <hr />
        
      <Row>
        <Col md={12}>
        <div>
                      <Table responsive>
                      <thead className='admin-coupon-list-head'>
                      <tr>
                        
                        <th className='admin-tabel-heading'>
                        Request Id                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Name
                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Type
                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Service                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Status                        </th>
                        <th className='admin-tabel-heading text-center'>
                        Mobile                        </th>
                        <th>

                        </th>
                      </tr>
                      </thead>
                      <tbody className='admin-tabel-data'>
                      {
                                    state.data && state.data.length > 0 ?
                                        (
                                         state.data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td class="sss">{item?.request_id}</td> 
                                                        <td class="sss text-center">{item?.alternate_name}</td> 
                                                        <td className='text-center'>{item?.service_type}</td>
                                                        <td className='text-center'>{item?.services?.length}</td>
                                                        {
                                                          item?.request_status == "pending" && (
                                                            <td className='text-center'>
                                                              <Badge className='pending-badge' bg="pending-badge">Pending</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {
                                                          item?.request_status == "accept" && (
                                                            <td className='text-center'>
                                                              <Badge className='accepted-badge' bg="accepted-badge">Accepted</Badge>
                                                            </td>
                                                          )
                                                        }
                                                          {
                                                          item?.request_status == "deny" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg="deny-badge">Rejected</Badge>
                                                            </td>
                                                          )
                                                        }
                                                        {
                                                          item?.request_status == "cancelled" && (
                                                            <td className='text-center'>
                                                              <Badge className='deny-badge' bg='deny-badge'>Cancelled</Badge>
                                                            </td>
                                                          )
                                                        }
                                                        <td className='text-center'>{item?.alternate_phone}</td>

                                                        
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <BsThreeDotsVertical />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="text-center">
                                                                <Dropdown.Item
                                                                 onClick={() => handleViewPage(item?._id)}
                                                                 >View</Dropdown.Item>
                                                                <hr className='p-0 m-0'/>
                                                                {
                                                                  item?.request_status == "pending" && (
                                                                   <>
                                                                   <Dropdown.Item 
                                                                    onClick={() => handleAcceptChange(item?._id)}
                                                                    >Accept</Dropdown.Item>
                                                                    <hr className='p-0 m-0'/>

                                                                    <Dropdown.Item 
                                                                    onClick={() => handleRejectChange(item?._id)}
                                                                    >reject</Dropdown.Item>
                                                                   </>
                                                                  )
                                                                }
                                                                    


                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </td>

                                                    </tr>
                                                );
                                            })

                                        )
                                        :

                                        (
                                            null
                                        )

                                }
                      
                      </tbody>

                      </Table>
                      {
                      state && state?.data?.length === 0 && (
                        <p class="text-center mt-5">No results found</p>

                      )
                      }
                      
                    </div>
        </Col>
      </Row>

    </div>
     
     <div class="modals-tag">
     <Modal show={acceptModal} onHide={()=>setAcceptModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title>Accept Request?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <p>Are you sure you want to accept the request?</p>

                 <Row className='mt-5 px-2'>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn-close w-100' variant="" onClick={()=>setAcceptModal(false)}>
                  Cancel                 
                   </Button>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn w-100' variant="" onClick={acceptRequest}>  
                  Accept             
                  </Button>
                  </Col>
                 </Row>
               
                
                </Modal.Body>
                
      </Modal>
      <Modal show={rejectModal} onHide={()=>setRejectModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title>Reject Request?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <p>Are you sure you want to reject the request?</p>

                 <Row className='mt-5 px-2'>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn-close w-100' variant="" onClick={()=>setRejectModal(false)}>
                  Cancel                 
                   </Button>
                  </Col>
                  <Col xs={6} md={6} sm={6}>
                  <Button className='download-btn w-100' variant="" onClick={rejectRequest}>  
                  Reject             
                  </Button>
                  </Col>
                 </Row>
               
                
                </Modal.Body>
                
      </Modal>
      <Modal show={successModal} onHide={()=>setSucessModal(false)} centered className='text-center'>
                <Modal.Header>
                  <Modal.Title className='mt-3'>
                    <img src={TickImg} className="img-fluid" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <h6>Request Successfully Accepted!</h6>
                <p>note</p>
                <h5 className="close-text" onClick={()=>setSucessModal(false)}>Close</h5> 
                </Modal.Body>
                
           </Modal>
           <Modal show={failureModal} onHide={()=>setFailureModal(false)} centered className='text-center'>
           <Modal.Header>
                  <Modal.Title className='mt-3'>
                    <img src={WrongImg} className="img-fluid" />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mb-5 px-5'>
                <h6>Request Successfully Rejected!</h6>
                <p>note</p>
                <h5 className="close-text" onClick={()=>setFailureModal(false)}>Close</h5> 
                </Modal.Body>
           </Modal>

     </div>
      <div>
     {
      state?.total > 1 && (
        <ReactPaginate 
        
            previousLabel="<<"
            nextLabel=">>"
            breakLabel="..."
            pageCount={state.total}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={'pagination justify-content-center pagination-box'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            forcePage={page}
           />
      )
     }
            
         
       
      </div>
    </div >
   </div>
  )
}

export default OrderRequestList