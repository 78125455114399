import React, { useEffect, useState } from 'react';

import InfoImg from "../../../assets/images/info.png";
import BasicDetails from "../../../assets/images/basicdetails.webp";
import BankDetails from "../../../assets/images/bankdetails.webp";
import ServiceDetails from "../../../assets/images/servicedetails.png";
import GovtProof from "../../../assets/images/govtproof.png";
import DashboardHeader from '../DashboardHeader';
import BusinessSidebar from '../../../components/elements/siderbar';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "react-bootstrap";
import { getVendorDetailsAction } from "../../../redux/dashboard/actions"

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, role } = useSelector(state => state.auth);
  const { vendor } = useSelector(state => state.business);


  const businessSettingsPage = () => {
    // navigate("/business/steps");
  }



  const redirectBusinessInformation = () => {
    if (vendor?._id) {
      const data = {
        id: vendor?._id
      }
      dispatch(getVendorDetailsAction(data))
      navigate("/settings/businessInformation");
    } else {
      console.log("Vendor not found");
    }
  }

  const redirectBusinessTiming = () => {
    if (vendor?._id) {
      const data = {
        id: vendor?._id
      }
      dispatch(getVendorDetailsAction(data))
      navigate("/settings/businessTiming");
    } else {
      console.log("Vendor not found");
    }

  }

  const redirectBankInformation = () => {
    if (vendor?._id) {
      const data = {
        id: vendor?._id
      }
      dispatch(getVendorDetailsAction(data))
      navigate("/settings/bankInformation");
    } else {
      console.log("Vendor not found");
    }

  }


  return (

    <div className="dashboard-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="">
          <Row>
            <Col className='p-0' md={2} sm={12}>
              <div className="sidebar-content">
                <BusinessSidebar />

              </div>

            </Col>
            <Col md={10} sm={12}>
              <div className="main-content">
                <div className="settings-page">
                  <section>
                    <div class="container-xxl">
                      <h3 className='marketing-header'>Settings</h3>

                      <p class="setting-suptitle">*Once any change is made admin will verification your account</p>
                      <div class="row">
                        <div class="col-md common-box-border mb-3" onClick={() => redirectBusinessInformation()}>
                          <h5>Basic Details <img src={InfoImg} class="img-fluid info-icon" alt="info" /></h5>
                          <div class="text-center mt-5 p-5 mb-3">
                            <img src={BasicDetails} class="img-fluid" alt="basicdetails" />
                          </div>
                        </div>
                        <div class="col-md common-box-border mb-3" onClick={redirectBusinessTiming}>
                      <h5>Service Details <img src={InfoImg} class="img-fluid info-icon" alt="info" /></h5>
                      <div class="text-center mt-5 p-5 mb-3">
                        <img src={ServiceDetails} class="img-fluid" alt="servicedetails" />
                      </div>
                    </div>
                        {/* <div class="col-md common-box-border" onClick={() => businessSettingsPage()}>
                      <h3>Govt Proof  <img src={InfoImg} class="img-fluid info-icon" alt="info" /></h3>
                      <div class="text-center mt-5 p-5">
                        <img src={GovtProof} class="img-fluid" alt="govtproof" />
                      </div>
                    </div> */}
                        {/* <div class="col-md common-box-border" onClick={() => redirectBankInformation()}>
                      <h3>Bank Details  <img src={InfoImg} class="img-fluid info-icon" alt="info" /></h3>
                      <div class="text-center mt-5 p-5">
                        <img src={BankDetails} class="img-fluid" alt="bankdetails" />
                      </div>
                    </div> */}
                      </div>


                    </div>
                  </section>
                </div>
              </div>

            </Col>
          </Row>

        </div>
      </div>

    </div>

  )
}

export default Settings