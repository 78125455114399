import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"
import { Accordion, Row, Col, Image, Badge, Breadcrumb, Container, Card, Button, Modal } from "react-bootstrap";
import Header from "../../components/elements/header/Header";
import Footer from "../../components/elements/footer/Footer";
import location from "../../assets/images/location.png";
import ImageBg from "../../assets/images/image.webp"
import like from "../../assets/images/like.png"
import unlike from "../../assets/images/like.png";
import rating from "../../assets/images/rating.png";
import MatchNotFound from "../../assets/images/match-not-found.svg"
import {MdOutlineArrowDropUp, MdOutlineArrowDropDown} from "react-icons/md"
import { FiHome } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { exploreAction, getSomeSalonsAction, clearHomeSearch, getSomeSalonsLocationAction } from '../../redux/business/actions';
import ReactStars from "react-rating-stars-component";
import {AiOutlineHeart} from 'react-icons/ai';
import {AiFillHeart} from 'react-icons/ai';
import { getUserFavoritesAction, locationDetailsAction } from "../../redux/user/actions";
import { SpinnerDotted } from 'spinners-react';



const ExplorePage = () => {
   
 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, role, isLoggedIn } = useSelector(state => state.auth);
    const { vendor } = useSelector(state => state.business);
    const { homeSearch } = useSelector(state => state.business) 
    const { exploreSearch } = useSelector(state => state.business) 
    const [filterView, setFilterView] = useState(false)


    const [state, setState] = useState({
        data: [],
        limit: 9,
        activePage: 1,
        total: 1,
        next: false
    });
    
    

    // useEffect(() => {
    //     console.log("Header search => ", exploreSearch?.serviceProvider?.length)
    //      if(Object.keys(exploreSearch).length > 0){
    //                 if(exploreSearch?.serviceProvider?.length > 0) {
    //                     const { serviceProvider, resultsPerPage, total_count, next} = exploreSearch
                           
    //                     setState((prev) => ({
    //                         ...prev,
    //                         data: serviceProvider,
    //                         total: total_count / resultsPerPage,
    //                         next : next
            
    //                     }))
    //                 }
                   
    //             }
    // },[exploreSearch])


    const { id } = useParams();
    const [salons, setSalons] = useState([]);
    const [salonLocation, setSalonLocation] = useState();
    const [genderCategoryArrFilter, setGenderCategoryArrFilter] = useState([]);
    const [serviceTypeArrFilter, setServiceTypeArrFilter] = useState([]);
    const [serviceCategoryArrFilter, setServiceCategoryArrFilter] = useState([]);
    const [locationSearch, setLocationSearch] = useState("")
    const [serviceNameSearch, setServiceNameSearch] = useState("")
    const [shopNameSearch, setShopNameSearch] = useState("")
    const [liked, setLiked] = useState(false);
    const [selectedLocation, SetSelectedLocation] = useState([]);
    const [noOfArea, setNoOfArea] = useState (6)
    const[areaSearch, setAreaSearch] = useState("")
    const areaSlice = salonLocation?.area.slice(0, noOfArea);
   
  
    const { locationDetails }  = useSelector((state) => state.getlocation);

    useEffect(async() => {
        await setState(() => ({
            activePage: 1,
        }))

        let data = {
            user_id: user?._id || "",
            page: 1,
            location_search: "",
            shopNameSearch: "",
            homelocation: locationDetails,
            serviceNameSearch: serviceNameSearch,
            services_filter: homeSearch?.category?.length > 0 ? homeSearch?.category : serviceCategoryArrFilter,
            servicetypes_filter: serviceTypeArrFilter,
            gender_filter: genderCategoryArrFilter,
            service_shop_search: homeSearch?.service !== "" ? homeSearch?.service : shopNameSearch != "" ? shopNameSearch : "" ,
        }
        dispatch(exploreAction(data))
            .then(async(res) => {
                await dispatch(clearHomeSearch())
                const { serviceProvider, resultsPerPage, total_count, next} = res
               
                await setState((prev) => ({
                    ...prev,
                    data: serviceProvider,
                    total: total_count / resultsPerPage,
                    next : next

                }))
               
                
            })
            .catch((err) => {
                console.log("Err =>", err)
            })
    }, [genderCategoryArrFilter, serviceTypeArrFilter, serviceCategoryArrFilter, locationSearch, serviceNameSearch, shopNameSearch, locationDetails]);
    
    const [sortType, setSortType] = useState('')
   
    const SortByType = (type)=>{
        setSortType(type)
        setState((prev) => ({
            ...prev,
            activePage: 1,
        }))
        let data = {
            user_id: user?._id || "",
            page: 1,
            location_search: selectedLocation,
            homelocation: locationDetails,
            shopNameSearch: "",
            serviceNameSearch: serviceNameSearch,
            services_filter: homeSearch?.category?.length > 0 ? homeSearch?.category : serviceCategoryArrFilter,
            servicetypes_filter: serviceTypeArrFilter || "",
            gender_filter: genderCategoryArrFilter ,
            service_shop_search: homeSearch?.service !== "" ? homeSearch?.service : shopNameSearch != "" ? shopNameSearch : "" ,
            sortby: type,
        }
        dispatch(exploreAction(data))
        .then((res) => {
            dispatch(clearHomeSearch())
            const { serviceProvider, resultsPerPage, total_count, next} = res
           
            setState((prev) => ({
                ...prev,
                data: serviceProvider,
                total: total_count / resultsPerPage,
                next : next

            }))
        })


    }

    

    const handlePageChange = () => {
        const data = {
            id: user?._id,
            page: state.activePage + 1,
            homelocation: locationDetails,
            location_search: "",
            shopNameSearch: "",
            homelocation: locationDetails,
            serviceNameSearch: serviceNameSearch,
            services_filter: homeSearch?.category?.length > 0 ? homeSearch?.category : serviceCategoryArrFilter,
            servicetypes_filter: serviceTypeArrFilter,
            gender_filter: genderCategoryArrFilter,
            service_shop_search: homeSearch?.service !== "" ? homeSearch?.service : shopNameSearch != "" ? shopNameSearch : "" ,
            sortby: sortType,
        }
        dispatch(exploreAction(data))
            .then((res) => {
                const { serviceProvider, total_count, resultsPerPage, next } = res
                setState((prev) => ({
                    ...prev,
                    data: prev.data.concat(serviceProvider),
                    total: total_count / resultsPerPage,
                    activePage: state?.activePage + 1,
                    next
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })

    }

    const handleLocationSearch = (e) => {
        setLocationSearch(e.target.value)
    }

    const handleServiceNameSearch = (e) => {
        setServiceNameSearch(e.target.value)
    }
    const handelAreaSearch = (e)=>{
        setAreaSearch(e.target.value);

    }
    const handleShopNameSearch = (e) => {
        setShopNameSearch(e.target.value)
    }
      


    
    const handleCheckboxChange = (e, item) => {
        const { checked, value} = e.target;
       if(checked){
        selectedLocation.push(value)

       } else {
        const getIndex = selectedLocation.indexOf(value)
        selectedLocation.splice(getIndex, 1)
       }

       let data = {
        user_id: user?._id || "",
        page: 1,
        location_search: selectedLocation,
        shopNameSearch: "",
        homelocation: locationDetails,
        serviceNameSearch: serviceNameSearch,
        services_filter: homeSearch?.category?.length > 0 ? homeSearch?.category : serviceCategoryArrFilter,
        servicetypes_filter: serviceTypeArrFilter,
        gender_filter: genderCategoryArrFilter,
        service_shop_search: homeSearch?.service !== "" ? homeSearch?.service : shopNameSearch != "" ? shopNameSearch : "" ,
    }
    dispatch(exploreAction(data))
        .then((res) => {
            dispatch(clearHomeSearch())
            const { serviceProvider, resultsPerPage, total_count, next} = res
           
            setState((prev) => ({
                ...prev,
                data: serviceProvider,
                total: total_count / resultsPerPage,
                next : next

            }))
           
            
        })
        .catch((err) => {
            console.log("Err =>", err)
        })

    };


    const areaLoadMOre = () =>{
        setNoOfArea(PrevValue => PrevValue + 6)
    }

    const areaShowLess =()=>{
        setNoOfArea(6)
    }

    const handleGenderCategoryArrFilter = (e) => {
        const { name, value, checked } = e.target;
        var gender_array = [...genderCategoryArrFilter];
        if (checked) {
            gender_array = [...genderCategoryArrFilter, value];
        } else {
            gender_array.splice(genderCategoryArrFilter.indexOf(value), 1);
        }
        setGenderCategoryArrFilter(gender_array);
    }
    const handleServiceTypeArrFilter = (e) => {

        const { name, value, checked } = e.target;
        var service_type_array = [...serviceTypeArrFilter];
        if (checked) {
            service_type_array = [...serviceTypeArrFilter, value];
        } else {
            service_type_array.splice(serviceTypeArrFilter.indexOf(value), 1);
        }
        setServiceTypeArrFilter(service_type_array);
    }

    const handleServiceCategoryArrFilter = (e) => {

        const { name, value, checked } = e.target;
        var service_category_array = [...serviceCategoryArrFilter];
        if (checked) {
            service_category_array = [...serviceCategoryArrFilter, value];
        } else {
            service_category_array.splice(serviceCategoryArrFilter.indexOf(value), 1);
        }
        setServiceCategoryArrFilter(service_category_array);
    }
    const resetFilters = () => {
        setGenderCategoryArrFilter([]);
        setServiceTypeArrFilter([]);
        setServiceCategoryArrFilter([]);
        setLocationSearch("");
        setServiceNameSearch("");
        setShopNameSearch("");
        SetSelectedLocation([]);
        setState(() => ({
            activePage: 1,
        }))
    }
    const redirectDetailsPage = (id) => {
       navigate(`/business/${id}`)
    }
    
    useEffect(() => {
        dispatch(getSomeSalonsAction())
            .then((res) => {

                const { results, serviceproviderCount, resultsPerPage } = res
                setSalons(results)
                
            })
            .catch((err) => {
                console.log("Err =>", err)
            })
    }, []);

    useEffect(() => {
        const data = {
            location: locationDetails,
            search: areaSearch,
        }
        dispatch(getSomeSalonsLocationAction(data))
            .then((res) => {
                setSalonLocation(res)
            })
            .catch((err) => {
                console.log("Err =>", err)
            })
    }, [areaSearch, locationDetails]);

    useEffect(()=>{
        console.log(salonLocation, areaSearch)
    },[salonLocation, areaSearch])

 
    const homeNavigation = () => {
    navigate("/")
    }
 const getAllFavorites =() => {
    let data = {
        user_id: user?._id || "",
        page: state.activePage,
        location_search: selectedLocation,
        homelocation: locationDetails,
        shopNameSearch: shopNameSearch,
        serviceNameSearch: serviceNameSearch,
        services_filter: homeSearch?.category?.length > 0 ? homeSearch?.category : serviceCategoryArrFilter,
        servicetypes_filter: serviceTypeArrFilter,
        gender_filter: genderCategoryArrFilter,
        service_shop_search: homeSearch?.service !== "" ? homeSearch?.service : "",
    }
    dispatch(exploreAction(data))
        .then((res) => {
            dispatch(clearHomeSearch())
            const { serviceProvider, resultsPerPage, total_count, next} = res
            setState((prev) => ({
                ...prev,
                data: serviceProvider,
                total: total_count / resultsPerPage,
                next : next
            }))
        })
        .catch((err) => {
            console.log("Err =>", err)
        })
 }
   const handleFavorites = async (item,index) => {
       const data = {
        type: item?.isLiked ==="true" ? "dislike" : "like",
        serviceprovider: item?._id,
        user: user?._id
    }
    await dispatch(getUserFavoritesAction(data))
    await getAllFavorites()
   }
   const handelUnFavorites = async (item) => {
    const data = {
        type: item?.isLiked ==="true" ? "dislike" : "like",
        serviceprovider: item?._id,
        user: user?._id
    }
    await dispatch(getUserFavoritesAction(data))
    await getAllFavorites()
   }


   const removeGenderCategrory = (index) => {
   let tmp = genderCategoryArrFilter;
    tmp.splice(index, 1);
    setGenderCategoryArrFilter([...tmp]);
   }  
   const removeAreaCategrory = (index) => {
    let tmp = selectedLocation;
     tmp.splice(index, 1);
     SetSelectedLocation([...tmp]);
    } 
   const removeStypeCategrory = (index) => {
    let tmp = serviceTypeArrFilter;
     tmp.splice(index, 1);
     setServiceTypeArrFilter([...tmp]);
    } 
    const removeServiceCategrory = (index) => {
        let tmp = serviceCategoryArrFilter;
         tmp.splice(index, 1);
        setServiceCategoryArrFilter([...tmp]);
    } 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true)
      setTimeout(()=> {
         setLoading(false)
      }, 500)
    }, [])

     
    return (
        <div>
        {
            loading ?
            <div style={{
              width: "10%",
              margin: "18% auto",
            }}>
                  <SpinnerDotted
                      loading={loading} 
                      size="100%"
                      thickness={180}
                      speed={180}
                      color ="#ECB390"
                      secondaryColor="rgb(255,255,255)"
             />
  
              </div>
              :
              <div  className="explorePage mb-0">
            <Header />
            <Container>
            <Breadcrumb>
                <Breadcrumb.Item onClick={homeNavigation} className="subnav-list">Home</Breadcrumb.Item>
                <Breadcrumb.Item className="subnav-list">
                Explore 
                </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
          
            <section className="explore-page-section mb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-sm-12 filter-section">
                           <div className="filter-sideBar" id="mobile-view">
                            <Card className="px-3">
                            <Row className='d-flex mt-2'>
                            <Col md={6} sm={6} xs={6}>
                                <div className='d-flex' onClick={()=> setFilterView(true)}>
                                <span className='fs-5 mx-2 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className=''>Filter</h5>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                                <h5 className='text-primary cur-pointer reset-fliter-set' onClick={resetFilters}>
                                    Reset
                                </h5>
                                </Col>
                               
                        </Row>
                        <Card.Body className="mb-0 pb-0 mt-0 pt-0">
                        <Row className="filter-items-view">
                                                        
                                                        {
                                                    genderCategoryArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeGenderCategrory(index)}>
                                                                <span className="mx-1">{item}</span>
                                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    serviceTypeArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                            <Badge className="badges-filter bg-light" variant="" onClick={()=>removeStypeCategrory(index)}>
                                                            <span className="mx-1">{item}</span>
                                                            <span><ion-icon name="close"></ion-icon></span>
                                                            </Badge>
                                                        </span>
                                                        )
                                                    })}
                                                    {
                                                    serviceCategoryArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeServiceCategrory(index)}>
                                                                    <span className="mx-1">{item}</span>
                                                                <span><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })}
                                                    {
                                                    selectedLocation.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeAreaCategrory(index)}>
                                                                <span className="mx-1">{item}</span>
                                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })
                                                } 
                                                        
                                </Row>
                        </Card.Body>
                            </Card>
                            <Modal show={filterView} onHide={() => setFilterView(false)} className="m-0 p-0">
                                <Modal.Header className='pb-0' closeButton></Modal.Header>
                                    <Modal.Body className='mt-3'>
                                        <Accordion className="m-0" defaultActiveKey={["0"]}>
                                            <Accordion.Item eventKey="0">
                                                    <Row className="filter-items-view">
                                                        
                                                        {
                                                    genderCategoryArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeGenderCategrory(index)}>
                                                                <span className="mx-1">{item}</span>
                                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    serviceTypeArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                            <Badge className="badges-filter bg-light" variant="" onClick={()=>removeStypeCategrory(index)}>
                                                            <span className="mx-1">{item}</span>
                                                            <span><ion-icon name="close"></ion-icon></span>
                                                            </Badge>
                                                        </span>
                                                        )
                                                    })}
                                                    {
                                                    serviceCategoryArrFilter.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeServiceCategrory(index)}>
                                                                    <span className="mx-1">{item}</span>
                                                                <span><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })}
                                                    {
                                                    selectedLocation.map((item, index) => {
                                                        return(
                                                            <span className="d-flex filter-badges">
                                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeAreaCategrory(index)}>
                                                                <span className="mx-1">{item}</span>
                                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                                </Badge>
                                                            </span>
                                                        )
                                                    })
                                                } 
                                                        
                                            {/* <Row>
                                                <div>
                                                    {genderCategoryArrFilter.length > 0 && (
                                                        <Row>
                                                    </Row>)}
                                                </div>
                                            </Row>
                                            <Row>
                                                <div>
                                                    {serviceTypeArrFilter.length > 0 && (
                                                        <Row>
                                            
                                                    </Row>  )}
                                                </div>
                                            </Row>
                                            <Row>
                                                <div>
                                                    {serviceCategoryArrFilter.length > 0 && (
                                                        <Row>
                                            
                                                    </Row> )}
                                                </div>
                                            </Row>
                                            <Row>
                                                <div>
                                                    {selectedLocation.length > 0 && (
                                                        <Row>
                                            
                                                </Row>)
                                                }
                                                </div>
                                            </Row> */}
                                                    </Row>
                                                    
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>
                                                        <h6>Category</h6>   
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Men" id="genderCategory" onClick={
                                                                // categoryFilter
                                                                handleGenderCategoryArrFilter
                                                            } checked={genderCategoryArrFilter.includes("Men") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Men
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Women" id="genderCategory" onClick={
                                                                // categoryFilter
                                                                handleGenderCategoryArrFilter
                                                            } checked={genderCategoryArrFilter.includes("Women") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Women
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Kids" id="genderCategory" onClick={
                                                                // categoryFilter
                                                                handleGenderCategoryArrFilter
                                                            } checked={genderCategoryArrFilter.includes("Kids") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Kids
                                                            </label>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>
                                                        <h6>
                                                            Service Type
                                                        </h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Shop only" id="serviceType" onClick={
                                                                handleServiceTypeArrFilter
                                                            } checked={serviceTypeArrFilter.includes("Shop only") ? 'checked' : ''}
                                                            />
                                                            <label class="form-check-label">
                                                                Shop only
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Home service" id="serviceType" onClick={
                                                                handleServiceTypeArrFilter
                                                            } checked={serviceTypeArrFilter.includes("Home service") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Home service
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Both Shop and Home service" id="serviceType" onClick={
                                                                handleServiceTypeArrFilter
                                                            } checked={serviceTypeArrFilter.includes("Both Shop and Home service") ? 'checked' : ''}/>
                                                            <label class="form-check-label" >
                                                                Both Shop and Home service
                                                            </label>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>
                                                        <h6>
                                                            Services
                                                        </h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <input type="text" class="form-control service-search-explore" id="" placeholder="Search for service"
                                                            name="search" value={serviceNameSearch} onInput={
                                                                // serviceFilterSearch
                                                                handleServiceNameSearch
                                                            } />
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Hair" id="hairServiceType" onClick={
                                                                // servicesFilter
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Hair") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Hair
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Makeup" id="makeupServiceType" onClick={
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Makeup") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Makeup
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Skin" id="skinServiceType" onClick={
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Skin") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Skin
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Spa" id="spaServiceType" onClick={
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Spa") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Spa
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Mehendi" id="mehendiServiceType" onClick={
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Mehendi") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Mehendi
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="Nails" id="nailsServiceType" onClick={
                                                                handleServiceCategoryArrFilter
                                                            } checked={serviceCategoryArrFilter.includes("Nails") ? 'checked' : ''}/>
                                                            <label class="form-check-label">
                                                                Nails
                                                            </label>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                {/* <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h6>
                                                            Shop
                                                        </h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <input type="text" class="form-control search-box" id="" placeholder="Type to search..."
                                                            name="search" value={shopNameSearch} onInput={
                                                                handleShopNameSearch
                                                            } />

                                                        {
                                                            salons.length > 0 ? (
                                                                <div>
                                                                    {
                                                                        salons.map((item, index) => {
                                                                            return (
                                                                                <a class="common-tag" key={index}>{item?.business_name}</a>
                                                                            )
                                                                        })
                                                                    }


                                                                </div>
                                                            )
                                                                :
                                                                (
                                                                    <div>
                                                                        <a class="common-tag"></a>
                                                                    </div>
                                                                )
                                                        }


                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                                                {/* 
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h5>
                                                            Discount
                                                        </h5>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div>
                                                            <a href="#" class="common-tag">Up to 10% Off</a>
                                                            <a href="#" class="common-tag">Up to 20% Off</a>
                                                            <a href="#" class="common-tag">Up to 50% Off</a>
                                                            <a href="#" class="common-tag">Up to 80% Off</a>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item> */}
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>
                                                        <h6> Area Location</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body className="">
                                                        <input type="text" class="form-control service-search-explore" id="" placeholder="Search for Area Location"
                                                            name="search" value={areaSearch} onInput={
                                                                // serviceFilterSearch
                                                                // handleServiceNameSearch
                                                                handelAreaSearch
                                                            } />

                                                            {
                                                                areaSlice?.map((item, index)=>{
                                                                return(
                                                                    <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value={item} id="hairServiceType"  onChange={(e)=>handleCheckboxChange(e, index, item)}  checked={selectedLocation.includes(item) ? 'checked' : ''}/>
                                                                    <label class="form-check-label">
                                                                        <Row className="location-names">
                                                                        <span>{item}</span>
                                                                        </Row>
                                                                    </label>
                                                                </div>
                                                                )
                                                
                                                                })
                                                            }
                                                            {
                                                                salonLocation?.area.length > 6 && (
                                                                    <>
                                                                    {
                                                                    salonLocation?.area?.length !== areaSlice?.length ? (
                                                                            <p className="view-all-font" onClick={areaLoadMOre}>View all</p>
                                                                        )
                                                                        :(
                                                                            <p className="view-all-font" onClick={areaShowLess}>View less</p>
                                                                        )
                        
                                                                    }
                                                                    </>
                        
                                                                )
                                                            }
                                                        
                                                    
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>
                                                    <h6>Sort by</h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="sortbyType" onClick={()=>SortByType("recentlylisted")}>Recently Listed</p>
                                                        <p className="sortbyType" onClick={()=>SortByType("lowtohigh")}>Rating: Low to High</p>
                                                        <p className="sortbyType" onClick={()=>SortByType("hightolow")}>Rating: High to Low</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                        </Accordion>
                                        <Button className="apply-service mb-3 " onClick={()=>setFilterView(false)}>Apply</Button>

                                   </Modal.Body>
                           </Modal>
                            </div>

                            <div className="filter-sideBar" id="system-view">
                            <Accordion className="m-0" defaultActiveKey={["0"]}>
                            <Accordion.Item eventKey="0">
                                    <Row className='d-flex mt-3'>
                            <Col className='d-flex'>
                            <span className='fs-5 mx-2 cur-pointer' ><ion-icon name="options-outline"></ion-icon></span> <h5 className=''>Filter</h5>
                            </Col>
                            <Col>
                                <h5 className='text-primary cur-pointer float-end mx-3 reset-fliter-set' onClick={resetFilters}>
                                    Reset
                                </h5>
                                </Col>
                        </Row>  
                                 
                        
                                    <Row className="filter-items-view">
                                        
                                        {
                                    genderCategoryArrFilter.map((item, index) => {
                                        return(
                                            <span className="d-flex filter-badges">
                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeGenderCategrory(index)}>
                                                <span className="mx-1">{item}</span>
                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                </Badge>
                                            </span>
                                        )
                                    })
                                }
                                   {
                                    serviceTypeArrFilter.map((item, index) => {
                                        return(
                                            <span className="d-flex filter-badges">
                                            <Badge className="badges-filter bg-light" variant="" onClick={()=>removeStypeCategrory(index)}>
                                            <span className="mx-1">{item}</span>
                                            <span><ion-icon name="close"></ion-icon></span>
                                            </Badge>
                                        </span>
                                        )
                                    })}
                                       {
                                    serviceCategoryArrFilter.map((item, index) => {
                                        return(
                                            <span className="d-flex filter-badges">
                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeServiceCategrory(index)}>
                                                    <span className="mx-1">{item}</span>
                                                <span><ion-icon name="close"></ion-icon></span>
                                                </Badge>
                                            </span>
                                        )
                                    })}
                                     {
                                    selectedLocation.map((item, index) => {
                                        return(
                                            <span className="d-flex filter-badges">
                                                <Badge className="badges-filter bg-light" variant="" onClick={()=>removeAreaCategrory(index)}>
                                                <span className="mx-1">{item}</span>
                                                <span className=""><ion-icon name="close"></ion-icon></span>
                                                </Badge>
                                            </span>
                                        )
                                    })
                                } 
                                        
                            {/* <Row>
                                <div>
                                    {genderCategoryArrFilter.length > 0 && (
                                         <Row>
                                    </Row>)}
                                </div>
                            </Row>
                            <Row>
                                <div>
                                    {serviceTypeArrFilter.length > 0 && (
                                        <Row>
                              
                                    </Row>  )}
                                </div>
                            </Row>
                            <Row>
                                <div>
                                    {serviceCategoryArrFilter.length > 0 && (
                                        <Row>
                             
                                    </Row> )}
                                </div>
                            </Row>
                            <Row>
                                <div>
                                    {selectedLocation.length > 0 && (
                                         <Row>
                               
                                </Row>)
                                }
                                </div>
                            </Row> */}
                        </Row>
                                    
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h6>Category</h6>   
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Men" id="genderCategory" onClick={
                                                // categoryFilter
                                                handleGenderCategoryArrFilter
                                            } checked={genderCategoryArrFilter.includes("Men") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Men
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Women" id="genderCategory" onClick={
                                                // categoryFilter
                                                handleGenderCategoryArrFilter
                                            } checked={genderCategoryArrFilter.includes("Women") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Women
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Kids" id="genderCategory" onClick={
                                                // categoryFilter
                                                handleGenderCategoryArrFilter
                                            } checked={genderCategoryArrFilter.includes("Kids") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Kids
                                            </label>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <h6>
                                            Service Type
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Shop only" id="serviceType" onClick={
                                                handleServiceTypeArrFilter
                                            } checked={serviceTypeArrFilter.includes("Shop only") ? 'checked' : ''}
                                            />
                                            <label class="form-check-label">
                                                Shop only
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Home service" id="serviceType" onClick={
                                                handleServiceTypeArrFilter
                                            } checked={serviceTypeArrFilter.includes("Home service") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Home service
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Both Shop and Home service" id="serviceType" onClick={
                                                handleServiceTypeArrFilter
                                            } checked={serviceTypeArrFilter.includes("Both Shop and Home service") ? 'checked' : ''}/>
                                            <label class="form-check-label" >
                                                Both Shop and Home service
                                            </label>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <h6>
                                            Services
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <input type="text" class="form-control service-search-explore" id="" placeholder="Search for service"
                                            name="search" value={serviceNameSearch} onInput={
                                                // serviceFilterSearch
                                                handleServiceNameSearch
                                            } />
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Hair" id="hairServiceType" onClick={
                                                // servicesFilter
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Hair") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Hair
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Makeup" id="makeupServiceType" onClick={
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Makeup") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Makeup
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Skin" id="skinServiceType" onClick={
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Skin") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Skin
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Spa" id="spaServiceType" onClick={
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Spa") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Spa
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Mehendi" id="mehendiServiceType" onClick={
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Mehendi") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Mehendi
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="Nails" id="nailsServiceType" onClick={
                                                handleServiceCategoryArrFilter
                                            } checked={serviceCategoryArrFilter.includes("Nails") ? 'checked' : ''}/>
                                            <label class="form-check-label">
                                                Nails
                                            </label>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <h6>
                                            Shop
                                        </h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <input type="text" class="form-control search-box" id="" placeholder="Type to search..."
                                            name="search" value={shopNameSearch} onInput={
                                                handleShopNameSearch
                                            } />

                                        {
                                            salons.length > 0 ? (
                                                <div>
                                                    {
                                                        salons.map((item, index) => {
                                                            return (
                                                                <a class="common-tag" key={index}>{item?.business_name}</a>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            )
                                                :
                                                (
                                                    <div>
                                                        <a class="common-tag"></a>
                                                    </div>
                                                )
                                        }


                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* 
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <h5>
                                            Discount
                                        </h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <a href="#" class="common-tag">Up to 10% Off</a>
                                            <a href="#" class="common-tag">Up to 20% Off</a>
                                            <a href="#" class="common-tag">Up to 50% Off</a>
                                            <a href="#" class="common-tag">Up to 80% Off</a>
                                        </div>

                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <h6> Area Location</h6>
                                    </Accordion.Header>
                                    <Accordion.Body className="">
                                        <input type="text" class="form-control service-search-explore" id="" placeholder="Search for Area Location"
                                            name="search" value={areaSearch} onInput={
                                                // serviceFilterSearch
                                                // handleServiceNameSearch
                                                handelAreaSearch
                                            } />

                                            {
                                                areaSlice?.map((item, index)=>{
                                                  return(
                                                    <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value={item} id="hairServiceType"  onChange={(e)=>handleCheckboxChange(e, index, item)}  checked={selectedLocation.includes(item) ? 'checked' : ''}/>
                                                    <label class="form-check-label">
                                                        <Row className="location-names">
                                                         <span>{item}</span>
                                                        </Row>
                                                    </label>
                                                </div>
                                                  )
                                
                                                })
                                            }
                                            {
                                                salonLocation?.area.length > 6 && (
                                                    <>
                                                    {
                                                       salonLocation?.area?.length !== areaSlice?.length ? (
                                                            <p className="view-all-font" onClick={areaLoadMOre}>View all</p>
                                                        )
                                                        :(
                                                            <p className="view-all-font" onClick={areaShowLess}>View less</p>
                                                        )
        
                                                    }
                                                    </>
        
                                                )
                                            }
                                          
                                       
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                       <h6>Sort by</h6>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="sortbyType" onClick={()=>SortByType("recentlylisted")}>Recently Listed</p>
                                        <p className="sortbyType" onClick={()=>SortByType("lowtohigh")}>Rating: Low to High</p>
                                        <p className="sortbyType" onClick={()=>SortByType("hightolow")}>Rating: High to Low</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            </div>




                        </div>
                        <div class="col-md-9 col-sm-12 p-0 mt-2">
                            <div class="">
                            <span className='d-flex'>
                                <span className="explore-search-icon fs-5 mt-2 mx-0">
                                <ion-icon name="search-outline" ></ion-icon>
                                </span>
                                <input type="text" class="form-control exploremain-search-box" id="" placeholder="Services, Stylist or Salon...." name="search" onInput={handleShopNameSearch} />
                                
                            </span>
                            <hr className="line-search" />
                             
                            </div>
                            <div class="explore-card-images mt-4">
                                <Row>
                                {
                                       state?.data?.length > 0 && (
                                            state?.data?.map((item, index) => {
                                                let location = item?.location_details?.area;
                                                // let address = '';
                                                let services_category = item?.service_category?.map(item => item.servicecategory_name)
                  
                                                return (
                                                    <Col md={4} key={index}> 
                                                        <div class="card card-list explore-card mb-3">
                                                            <img class="card-img-top" src={item?.businessURL || ImageBg} alt="Card image" />
                                                            
                                                            <div class="card-body explore-card-style">
                                                                <h4>{
                                                                    item?.business_name?.length > 17 ?
                                                                    item?.business_name?.slice(0,17).concat(" .....")
                                                                    :
                                                                    item?.business_name?.slice(0,17)
                                                                }
                                                                </h4>
                                                                <p class="card-text mb-0">{location}</p>
                                                               <Row>
                                                                <Col>
                                                              

                                                   <div className="d-flex">
                                                   <Button bg="" className='review-content-explore'>
                                                     <p>
                                                     <span className="icon-explore-page">
                                                     <ion-icon name="star"></ion-icon>                                                        </span>
                                                                {item?.rating_avg}
                                                                
                                                     </p>
                                                    </Button>
                                                    <p className="review-count-explore">({item?.review_count})</p>
                                                   </div>
                                                               
                                                                </Col>
                                                                <Col></Col>
                                                               </Row>

                                                            </div>
                                                            <div className="card-footer p-0 m-0 footer-view-details">
                                                            <button class="btn view-details-btn" onClick={() => redirectDetailsPage(item._id)}>View Details</button>
                                                            </div>
                                                        </div>
                                                        {  isLoggedIn && role?.role_name === "user" && (
                                                                item?.isLiked && item?.isLiked === "true" ? (
                                                                    <>
                                                                        <AiFillHeart alt="" className="fav-icon-fill" size={24} onClick={()=>handelUnFavorites(item, index)} />

                                                                        {/* </div> */}

                                                                    
                                                                    </>
                                                                ):
                                                                (
                                                                    <div id="fav-icon-fill-outer">
                                                                        <span id="fav-icon-hover">
                                                                        <p>Add to Favorites <span> <AiOutlineHeart size={14} /></span></p> 
                                                                        </span>

                                                                    <AiOutlineHeart size={24}
                                                                    className="fav-icon-unFill"
                                                                    onClick={()=>handleFavorites(item,index)}
                                                                    />
                                                                    </div>
                                                                )
                                                             )
                                                                
                                                             }
                                                       
                                                    </Col>
                                                    
                                                )
                                            })
                                        )
                                    }
                                    <div>
                                    {
                                        state?.data?.length == 0 && (
                                            <div className="result-not-found-section text-center mt-5">
                                            <h4>
                                                No result found, please try again
                                            </h4>
                                            <Image
                                                src={MatchNotFound} className="img-fluid"
                                            />

                                        </div>
                                        )
                                    }
                                    </div>
                                </Row>
                            </div>
                            {
                                state?.next ? (
                                    <div class="loadmore-btn">
                                        <button class="load-btn" onClick={handlePageChange}>Load more</button>
                                    </div>
                                ):(
                                    null
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
               </div>
          }
       </div>
    );
};

export default ExplorePage;