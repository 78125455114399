import React, { useState } from "react";
import {
    Form,
    Modal,
    Button
    
} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import { useDispatch } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { login } from '../../../redux/auth/actions';
import { loginValidationSchema } from "../../../validations/yub_schemas/loginValidationSchema";
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

const Login = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eyeState, setEyeState] = useState(false)
    const [conformEyeState, setConformEyeState] = useState(false)

    const Eyeview = () => {
        setEyeState(prevState => !prevState)
    }

    const ConformEyeview = () => {
        setConformEyeState(prevState => !prevState)
    }

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values) => {
            const data = {
                email_id: values.email,
                password: values.password,
            };
            dispatch(login(data))
                .then(() => {
                    formik.resetForm();
                    props.onHide(true);
                })
                .catch((error) => {
                    console.log(error);

                    setLoading(false);
                });
        },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const showRegisterModal = () => {
        props.onHide(true);
        props.onRegisterHide(true);
    }

    const showForgotPasswordModal = (e) => {
        props.onHide(true);
        props.setforgotpasswordmodal(true);
        e.preventDefault()
    }
   

    return (
        <div className="login">
            <Modal
                // {...props}
                show={props.show}
                onHide={props.onHide}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="">
                    <div className="text-center">
                        <img className="logo"
                            src={logo}
                            alt="Slaylewks logo" />

                        <h4 className="register-header">Log in to your account</h4>
                        <div className="m-1">
                            <h6><small className="text-muted">Welcome back ! Please enter your details</small></h6>
                        </div>
                    </div>

                    <div>
                        <Form className="register-form" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>
                                <Form.Group className="mb-4 " controlId="formBasicEmail">
                                    {/* <Form.Label>Email Id</Form.Label> */}
                                    <Form.Control
                                        type="email"
                                        placeholder="Email Id"
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        isInvalid={!!formik.touched.email && !!formik.errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* <InputGroup> */}
                                <Form.Group className="mb-2 position-relative" controlId="formBasicPassword">
                                    {/* <Form.Label>Password</Form.Label> */}
                                    <Form.Control
                                        type={eyeState ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                    />
                                    <span id='eye' onClick={Eyeview}>
                                        {eyeState ? <AiOutlineEye /> :
                                            <AiOutlineEyeInvisible />
                                        }
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.password}
                                    </Form.Control.Feedback>
                                    {/* <InputGroup.Append>
                                        <InputGroup.Text>
                                            <i onClick={clickHandler} class={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                        </InputGroup.Text>
                                    </InputGroup.Append> */}
                                </Form.Group>
                                {/* </InputGroup> */}
                                <div className="text-end mt-0 d-grid gap-2 text-primary">
                                    <a className="login-register" href="#" onClick={showForgotPasswordModal}>Forgot Password ?</a>
                                </div>
                                <div className="text-center mt-4 d-grid gap-2">
                                    <Button className="register-button" type="submit" size="lg">
                                        Login
                                    </Button>
                                </div>
                               
                                {/* <div className="text-center mt-3">
                                    <h6>
                                        <small className="text-muted">
                                            Or Sign in with
                                        </small>
                                    </h6>
                                </div> */}

                                {/* <div className="text-center mt-3">
                                    <BsGoogle width="100" height="100"/>
                                    <img src={googleIcon} width="40" height="40" alt="Google icon" />
                                </div> */}
                                <div className="text-center mt-2">
                                    <h6>
                                        <small className="text-muted">
                                            Don't have an account?
                                        </small>
                                        &nbsp;
                                        <span className="text-primary">
                                            <a className="login-register" onClick={showRegisterModal}>Register Here</a>
                                        </span>
                                    </h6>
                                </div>
                            </FormikProvider>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Login;