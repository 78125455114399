import React, { useContext, useEffect, useState, useRef } from "react";
import {
    Form,
    Modal,
    Alert,
    FloatingLabel,
    Button,
    Row,
    Col,
    Card
} from "react-bootstrap";
import { useFormik, FormikProvider, ErrorMessage} from 'formik';
import { adminGetServiceProviderAction, createAdminCouponCode, createBusinessCouponCode,businessGetServiceCategoryAction } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import moment, { min } from "moment";
import "react-datepicker/dist/react-datepicker.css"
import { couponAdminValidation } from "../../../validations/yub_schemas/couponAdminValidation";
import { couponBusinessValidation } from "../../../validations/yub_schemas/couponBusinessValidation";
import {couponBusinessValidation_admin} from "./couponBusinessValidation_admin"
import { Multiselect } from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
import Select from "react-dropdown-select";





function CouponAddEdit(props) {
    const [state, setState] = useState();
    const  vendor  = useSelector((state) => state);
    const [copyText, setCopyText] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [code, setCode] = useState("");
    const [generateCode, setGenerateCode] = useState(false)
    const [adddiscounts, setAdddiscounts] = useState(false);
    const [dateText, setDateText] = useState(false);
    const [validrange, SetValidrange] = useState(false)
    const [discountminus, Setdiscountminus] = useState(false)
    const [generateText, setGenerateText] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [options, setOptions] = useState()
    const [selectallCheck, setSelectallCheck] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);
    const [serviceProvider_id, setServiceProvider_id] = useState([])
    const [category , setCategory] = useState([])
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);



    useEffect(() => {
       
        dispatch(adminGetServiceProviderAction())
            .then((res) => {                               
                setState(res);   
                setOptions(res.data)
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
            
    }, [])

    // if(selectedProviders[0].id){
    //     const data = {
    //                 serviceProviderID:selectedProviders[0].id,
    //              }
    //             dispatch(businessGetServiceCategoryAction(data))
    //                 .then((res) => {
    //                     console.log(res.data.list,"res",res.data)
    //                     setCategory(res.data.list);
    //                 })
    //                 .catch((err) => {
    //                     console.log("Err => ", err)
    //                 })
    // }

    // useEffect(() => {
    //     const data = {
    //         serviceProviderID:serviceProvider_id,
    //      }
    //     dispatch(businessGetServiceCategoryAction(data))
    //         .then((res) => {
    //             setState(res)
    //             console.log(res.data.list,"res",res.data)
    //             setCategory(res.data.list);
    //         })
    //         .catch((err) => {
    //             console.log("Err => ", err)
    //         })
    // })



    const[discountData, setDiscountData] = useState(
        {
        min: "",
        max: "",
        amount: ""
        }
    )

    const selectStartDate = (date) => {
       setStartDate(date);
    }
    const selectEndDate = (date) => {
        setendDate(date);
     }

     const addDiscountAmount = () => {
        let numbers = /^[0-9]+$/;
        discountData.max = Number(discountData.max);
        discountData.min = Number(discountData.min);
        discountData.amount = Number(discountData.amount);
        if( numbers.test(discountData.min  || discountData.max || discountData.amount)) {
            if(discountData.max > discountData.min && discountData.amount < discountData.max){
                if(discountData.max && discountData.min && discountData.amount >= 0){
                    let temp = formik.values.discount_offer
                    temp = temp.concat({...discountData})
                    formik.setFieldValue("discount_offer", temp)
                    setAdddiscounts(false);
                    SetValidrange(false);
                    Setdiscountminus(false)

                } else {
                    Setdiscountminus(true)
                    setAdddiscounts(false);
                    SetValidrange(false);
                }
               
            }else {
                SetValidrange(true);
                setAdddiscounts(false);
                Setdiscountminus(false)
            }
        } else {
            setAdddiscounts(true);
            Setdiscountminus(false);
            SetValidrange(true);
        }
        
    }


 
    const handelGenerateCode = () => {
        

        if(formik.values.coupon_name !== "" && formik.values.serviceprovider !== ""
        && formik.values.discount_offer !== []) {
            setGenerateCode(true)
            makeid()
            setGenerateText(false)

        } else {
            setGenerateCode(false)
            setGenerateText(true)
        }
    }

    const handelGenerateCodeCustom = () => {

        if(formik.values.coupon_name !== "" && formik.values.serviceprovider !== ""
        && formik.values.discount_offer !== "" && formik.values.custom_code !== "") {
            
            setGenerateCode(true);
            customid();
            setGenerateText(false);

        } else {
            setGenerateCode(false);
            setGenerateText(true);
        }
    }
  
    // const formik = useFormik({
    //     enableReinitialize: true,
        
    //     initialValues: {
    //         coupon_type: "Auto code",
    //         coupon_name: "",
    //         custom_code: "",
    //         serviceprovider: "",
    //         usage_limit_type:"limited",
    //         usage_limit: "",
    //         time_limit_type:"limited duration",
    //         toTime: "",
    //         fromTime: "",
    //         per_user_limit_type:"one  time",
    //         per_user_limit: "",
    //         discount_offer_type:"in price",
    //         description: '',
    //         discount_offer: [
               
    //         ],
    //         description: "",
    //         coupon_code_admin: "",
            
    //     },
    //     validationSchema: couponAdminValidation,
    //     onSubmit: (values) => {
    //         const data = {
    //             coupon_type: values.coupon_type,
    //             coupon_name: values.coupon_name,
    //             custom_code: values.custom_code,
    //             serviceprovider: values.serviceprovider,
    //             usage_limit_type:values.usage_limit_type,
    //             usage_limit: values.usage_limit,
    //             time_limit_type:values.time_limit_type,
    //             toTime: values.toTime,
    //             fromTime: values.fromTime,
    //             per_user_limit_type:values.per_user_limit_type,
    //             per_user_limit: values.per_user_limit,
    //             discount_offer_type:values.discount_offer_type,
    //             discount_offer: values.discount_offer,
    //             description: values.description,
    //             coupon_code_admin: values.coupon_code_admin
                
    //         };

    //        dispatch(createAdminCouponCode(data))
    //         .then((res) => {
    //                     props.getCouponCodes();
    //                     props.onHide();
    //                     setGenerateCode(false);
    //                     formik.resetForm()

    //                 })
    //                 .catch((err) => {
    //                     console.log("Err => ", err)
    //                 })

            
    //     },
    // });

//   create business coupon for admin 

const formik = useFormik({
    enableReinitialize: true,
    
    initialValues: {
        serviceprovider: "",
        coupon_type: "Auto code",
        coupon_name: "",
        custom_code: "",
        servicecategory:[
            
        ],
        usage_limit_type:"limited",
        usage_limit: "",
        time_limit_type:"limited duration",
        toTime: "",
        fromTime: "",
        per_user_limit_type:"one time",
        per_user_limit: "",
        discount_offer_type:"in price",
        description: '',
        discount_offer: [
           
        ],
        description: "",
        coupon_code_business: "",
        generated_by:"Admin",
        
    },
    validationSchema: couponBusinessValidation_admin,
    onSubmit: (values) => {

        let categorys = values.servicecategory.map((item) => {
            return item.label
        })

        let serviceprovider_id = values.serviceprovider

        const data = {
            service_provider: serviceprovider_id,
            coupon_type: values.coupon_type,
            coupon_name: values.coupon_name,
            custom_code: values.custom_code,
            servicecategory: categorys,
            usage_limit_type:values.usage_limit_type,
            usage_limit: values.usage_limit,
            time_limit_type:values.time_limit_type,
            toTime: values.toTime,
            fromTime: values.fromTime,
            per_user_limit_type:values.per_user_limit_type,
            per_user_limit: values.per_user_limit,
            discount_offer_type:values.discount_offer_type,
            discount_offer: values.discount_offer,
            description: values.description,
            coupon_code_business: values.coupon_code_business,
            generated_by:"Admin"

            
        };
        console.log(" data : ", values.servicecategory, values.servicecategory.label)

       dispatch(createBusinessCouponCode(data))
        .then((res) => {

                    // props.onHide();
                    // formik.resetForm()
                    // setGenerateCode(false);
                    props.onHide();
                    formik.resetForm()
                    setGenerateCode(false);
                    // window.location.reload();
                    navigate(`/dashboard/salon`)



                })

                .catch((err) => {
                    console.log("Err => ", err)
                })

        
    },
});
// console.log("------> ",formik.errors)
// console.log("=====> ",formik.values)

    const makeid = () => {

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        formik.values.coupon_code_business = text;
        setCode(text)
    }

    const customid = () => {
        var text = formik.values.custom_code ;
        // var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        // for (var i = 0; i < 4; i++)
        //     text += possible.charAt(Math.floor(Math.random() * possible.length));
        formik.values.coupon_code_business = text;
        setCode(text);
    }
    const changediscountPercentage = (e) => {
        formik.setFieldValue("discount_offer_type", e)
    }

    const changeUsagelimit = (e) => {
        formik.setFieldValue("usage_limit_type", e)
    }
  
    const changeuserLimt = (e) => {
        formik.setFieldValue("time_limit_type", e)
    }

    const changeTimeLimit = (e) => {
        formik.setFieldValue("per_user_limit_type", e)
    }


    const copiedtoClip = () => {
        setCopyText(true)
        setTimeout(()=>{
            setCopyText(false)

        }, 2000)
    }

   


    const removeDiscount = (index) => {
        let discountValues = formik.values.discount_offer;
        discountValues.splice(index, 1);
        formik.setFieldValue("discount_offer", discountValues)
    };
  

const addingServiceProviders = (e) => {
    setSelectedProviders(e)
    // setServiceProvider_id(e[0].id)
            const data = {
                    serviceProviderID:e,
                 }
                dispatch(businessGetServiceCategoryAction(data))
                    .then((res) => {
                        console.log(res.data.list,"res",res.data)
                        setCategory(res.data.list);
                    })
                    .catch((err) => {
                        console.log("Err => ", err)
                    })
}

const addingServiceCategory = (e) => {
    setSelectedCategory(e)
    formik.setFieldValue("servicecategory", e)

}

// const categoryid = (e) => {
    // setServiceProvider_id(e[0].id)
    
    //     const data = {
    //        serviceProviderID:e[0].id,
    //     }
    //    dispatch(businessGetServiceCategoryAction(data))
    //        .then((res) => {
    //            setState(res)
    //            console.log(res.data.list,"res",res.data)
    //            setCategory(res.data.list);
    //        })
    //        .catch((err) => {
    //            console.log("Err => ", err)
    //        })
// }
const overrideStrings = {
    "allItemsAreSelected": "All the service providers are selected",
    "clearSearch": "Clear Search",
    "clearSelected": "Clear Selected",
    "noOptions": "No options",
    "search": "Search for service providers",
    "selectAll": "Select All",
    "selectAllFiltered": "Select All (Filtered)",
    "selectSomeItems": "Choose the service provider for this coupon",
    "create": "Create",
  }

  const overrideStringsforcategory = {
    "allItemsAreSelected": "All the service category are selected",
    "clearSearch": "Clear Search",
    "clearSelected": "Clear Selected",
    "noOptions": "No options",
    "search": "Search for service category",
    "selectAll": "Select All",
    "selectAllFiltered": "Select All (Filtered)",
    "selectSomeItems": "Choose the service category for this coupon",
    "create": "Create",
  }

   
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-50w"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="marketing-body-content">
                    <Form noValidate className="marketing-register-form text-start" onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <div className="code-modal-header">
                                <h4>Generate coupon coded</h4>
                            </div>
                            <Row className="mt-4 custom-code-container">
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3" controlId="coupon_type">
                                        <Form.Select
                                        className="form-select-category"
                                            name="coupon_type"
                                            onChange={formik.handleChange}
                                            value={formik.values.coupon_type}
                                            isInvalid={!!formik.touched.coupon_type && !!formik.errors.coupon_type}
                                        >
                                            <option  value="Custom code">Custom Code</option>
                                            <option  value="Auto code">Auto Code</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    formik.values.coupon_type != "Auto code" ? (
                                           <div className="p-0"> 
                                              <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Coupon Code</Form.Label>
                                                    <Col sm="12">
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Custom Coupon Code*"
                                                            name="custom_code"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.custom_code}
                                                            isInvalid={!!formik.touched.custom_code && !!formik.errors.custom_code}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.custom_code}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>

                                           </div>
                                    ) : (
                                        null
                                    )
                                }
                            </Row>
                            <hr />
                                      
                            <div className="code-modal-body">
                            
                                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Coupon Name</Form.Label>
                                                    <Col sm="12">
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Coupon Name*"
                                                            name="coupon_name"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.coupon_name}
                                                            isInvalid={!!formik.touched.coupon_name && !!formik.errors.coupon_name}

                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.coupon_name}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                           
                                                       
                                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Service Provider</Form.Label>
                                                    <Col sm="12">
                                                    {/* <Select
                                                    
                                                    options={options?.map((item)=>{
                                                        return(
                                                            {"label": item?.business_name, "value": item?.business_name , "id":item?._id}
                                                        )
                                                     })}
                                                    placeholder="Select serviceprovider"
                                                    onChange={(e) =>addingServiceProviders(e)} /> */}


                                        <Form.Select
                                            // className="form-select-serviceprovider"
                                            className={`form-select-serviceprovider ${formik.values.serviceprovider ? "selected" : ""}`}

                                            name="serviceprovider"
                                            value={formik.values.serviceprovider}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if (e.target.value !== "") {
                                                    addingServiceProviders(e.target.value);
                                                }else {
                                                    toast.error("Please select a service provider");
                                                }                                            
                                            }}
                                            isInvalid={!!formik.touched.serviceprovider && !!formik.errors.serviceprovider}

                                        >
                                            {/* <option value="" hidden >Select service provider</option> */}
                                            <option value="" hidden style={{ color: "gray" }}>
                                                Select a service provider
                                            </option>
                                            {options && options.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.business_name}
                                                </option>
                                            ))}
                                        </Form.Select>

                                        


                                                    {/* <MultiSelect
                                                       options={options?.map((item)=>{
                                                        return(
                                                            {"label": item?.business_name, "value": item?.business_name }
                                                        )
                                                     })}
                                                       hasSelectAll={("hasSelectAll", true)}
                                                       isLoading={("isLoading", false)}
                                                       value={selectedProviders}
                                                       disabled={("disabled", false)}
                                                       onChange={(e)=> addingServiceProviders(e)}
                                                       name="serviceprovider"
                                                       className={("className", "multi-select")}
                                                       overrideStrings={overrideStrings}
                                                    /> */}

                                                   {/* <Multiselect
                                                    showCheckbox 
                                                    hidePlaceholder
                                                     options={options}
                                                     displayValue='business_name'
                                                     placeholder="Please select"
                                                     onSelect={(event)=>{ formik.setFieldValue("serviceprovider", event) }}
                                                     onRemove={(event)=>{ formik.setFieldValue("serviceprovider", event) }}
                                                     name="serviceprovider"
                                                    /> */}
                                                                         

                                                 

                                                    {/* <Form.Select
                                        className="form-select-category"
                                            name="serviceprovider"
                                            onChange={formik.handleChange}
                                            value={formik.values.serviceprovider}
                                            isInvalid={!!formik.touched.serviceprovider && !!formik.errors.serviceprovider}
                                            >
                                            <option>Select service Provider</option>
                                            {
                                                state?.data?.map((item, index)=>{
                                                    return(

                                                        <option key={index}>{item?.business_name}</option>
                                                    )

                                                })
                                            }
                                        </Form.Select> */}
                                                        {/* <Form.Control
                                                            
                                                            type="text"
                                                            placeholder="Service provider"
                                                            name="serviceprovider"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.serviceprovider}
                                                            isInvalid={!!formik.touched.serviceprovider && !!formik.errors.serviceprovider}

                                                        /> */}
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.serviceprovider}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>

{/* new form added for service category */}
                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                    <Form.Label column sm="12" className="form-label-coupon-code">Service Category</Form.Label>
                                    <Col sm="12">
                                        <MultiSelect
                                            options={category?.map((item,index) => {
                                                return (
                                                    {"label": item?.servicecategory_name, "value": item?._id }
                                                    // { "label": item?.servicecategory_name, "value": item?.business_name }
                                                )
                                            })}
                                            // options = {
                                            //     State?.data?.list?.map((item, index) => {
                                            //         return(
                                            //             { "label":item?.servicecategory_name, "value":item?.servicecategory_name}
                                            //             // <option key={index} value={item?.servicecategory_name}>{item?.servicecategory_name}</option>
                                            //         )

                                            //     })
                                            // }
                                            hasSelectAll={("hasSelectAll", true)}
                                            isLoading={("isLoading", false)}
                                            value={selectedCategory}
                                            name="servicecategory"
                                            disabled={("disabled", false)}
                                            onChange={(e) => addingServiceCategory(e)}
                                            isInvalid={!!formik.touched.servicecategory && !!formik.errors.servicecategory}
                                            className={("className", "multi-select")}
                                            overrideStrings={overrideStringsforcategory}

                                        />
                                        {formik.touched.servicecategory && formik.errors.servicecategory ? (
                                            <div className="errorMessage" >{formik.errors.servicecategory}</div>
                                        ) : null}

                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.servicecategory}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                    
                                                
                                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code" >Discount Offer</Form.Label>
                                                    <Col sm="12">
                                                       
                                                         <Row className="mb-3 px-3">
                                                       <Col md={6} sm={6} xs={6}  className="p-0 ">
                                                        <Card onClick={()=>{
                                                            changediscountPercentage("in price")
                                                        }} className={formik.values.discount_offer_type==="in price" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                                <p>In price</p>
                                                        </Card>
                                                       </Col>
                                                       <Col md={6} sm={6} xs={6} className="p-0">
                                                       <Card onClick={()=>{
                                                        
                                                        changediscountPercentage("in percentage")
                                                        }} className={formik.values.discount_offer_type==="in percentage" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                       <p>In Percentage</p>
                                                       </Card>
                                                       </Col>
                                                       </Row>
                                                        <Row className="px-3">
                                                            <Col md={10} sm={10} xs={10}>
                                                            <Row>
                                                            <Col md={4} sm={4} xs={4}>
                                                              <Form.Control
                                                            type="text"
                                                            placeholder="Min"
                                                            name="min"
                                                            onChange={(e)=>{
                                                                setDiscountData({...discountData, min: e.target.value});
                                                                // formik.setFieldValue("min", e.target.value);
                                                            }}
                                                            value={discountData?.min}
                                                            // value={formik.values.discount_offer.min}
                                                        />
                                                     
                                                            </Col>
                                                            <Col md={4} sm={4} xs={4}>
                                                              <Form.Control
                                                            type="text"
                                                            placeholder="Max"
                                                            name="max"
                                                            onChange={(e)=>
                                                                {
                                                                    setDiscountData({...discountData, max: e.target.value});
                                                                }}
                                                            value={discountData?.max}
                                                        />
                                                            </Col>
                                                            <Col md={4} sm={4} xs={4}>
                                                              <Form.Control
                                                            type="text"
                                                            placeholder="Amount"
                                                            name="amount"
                                                            onChange={(e)=>{
                                                                setDiscountData({...discountData, amount: e.target.value});
                                                        }}
                                                            value={discountData?.amount}
                                                        />
                                                            </Col>
                                                         </Row>
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                            <h6 className="add-discount-amt-icon" onClick={addDiscountAmount}>
                                                            <ion-icon name="add-circle-outline"></ion-icon>
                                                            </h6>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            formik.values.discount_offer.length > 0 && (
                                                                <Row className="px-3">
                                                            {
                                                                formik.values.discount_offer?.map((item, index)=>{
                                                                    return(
                                                                    <Row className="d-flex px-3 mt-2 mb-0 py-0" key={index}>
                                                                       <Col md={10} sm={10} xs={10}>
                                                                        <Row>
                                                                       <Col md={4} sm={4} xs={4}>
                                                                       <p>{item?.min}</p>
                                                                       </Col>
                                                                       <Col md={4} sm={4} xs={4}>
                                                                       <p>{item?.max}</p>
                                                                       </Col>
                                                                       <Col md={4} sm={4} xs={4}>
                                                                       <p>{item?.amount}</p>
                                                                       </Col>
                                                                       </Row>
                                                                       </Col>
                                                                         <Col md={2} sm={2} xs={2}>
                                                                        <h6 className="close-discount-amt" onClick={()=>removeDiscount(index)}><ion-icon name="close-circle-outline"></ion-icon></h6>
                                                                         </Col>
                                                                    </Row>
                                                                    )
                                                                })
                                                            }
                                                            </Row>
                                                            ) 
                                                        }
                                                         <p className="manual-error-style px-3">
                                                            <ErrorMessage name="discount_offer" />
                                                         </p>
                                                        {
                                                            adddiscounts && (
                                                                <p className="manual-error-style px-3"> please enter the amount</p>
                                                            )
                                                        }
                                                         {
                                                            validrange && (
                                                                <p className="manual-error-style px-3"> please choose the min and max amount correctly</p>
                                                            )
                                                        }
                                                         {
                                                            discountminus && (
                                                                <p className="manual-error-style px-3"> Cant accept values in minus</p>
                                                            )
                                                        }
                                                    </Col>
                                                </Form.Group>    
                                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Usage Limit</Form.Label>
                                                    <Col sm="12">
                                                          <Row className="mb-3 px-3">
                                                       <Col md={6} sm={6} xs={6}  className="p-0 m-0">
                                                        <Card onClick={()=>{changeUsagelimit("limited")}} className={formik.values.usage_limit_type==="limited" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                            <p> Limited</p>
                                                        </Card>
                                                       </Col>
                                                       <Col md={6} sm={6} xs={6} className="p-0 m-0">
                                                       <Card onClick={()=>{
                                                            changeUsagelimit("unLimited")
                                                        }}  className={formik.values.usage_limit_type==="unLimited" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                       <p> Unlimited</p>
                                                       </Card>
                                                       </Col>
                                                         </Row>
                                                         {
                                                            formik.values.usage_limit_type==="limited" ? (
                                                                <Row className="px-3">
                                                                <Form.Control
                                                            type="text"
                                                            placeholder="Coupon Limit"
                                                            name="usage_limit"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.usage_limit}
                                                            isInvalid={!!formik.touched.usage_limit && !!formik.errors.usage_limit}
                                                        />
                                                         <Form.Control.Feedback type="invalid">
                                                            {formik.errors.usage_limit}
                                                        </Form.Control.Feedback>
                                                          
                                                                </Row>
                                                            ) : (
                                                                <Row className="px-3">
                                                                <Form.Control
                                                                disabled
                                                            
                                                        />
                                                                </Row>
                                                            )
                                                         }
                                                    
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Time Limit</Form.Label>
                                                    <Col sm="12"> 
                                                          <Row className="mb-3 px-3">
                                                       <Col md={6}  sm={6} xs={6}  className="p-0 m-0">
                                                        <Card onClick={()=>{changeuserLimt("life time")}} className={formik.values.time_limit_type==="life time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                            <p> Life Time</p>
                                                        </Card>
                                                       </Col>
                                                       <Col md={6}  sm={6} xs={6} className="p-0 m-0">
                                                       <Card onClick={()=>{changeuserLimt("limited duration")}} className={formik.values.time_limit_type==="limited duration" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                       <p>Limited Duration</p>
                                                       </Card>
                                                       </Col>
                                                       <Row className="calander-section-coupon mt-3">
                                                        {
                                                            formik.values.time_limit_type==="limited duration" ? (
                                                                <>
                                                     <Col md={6}  sm={6} xs={6} className="p-0">
                                                          <DatePicker
                                                           className="to-datePicker" 
                                                           name="fromTime"
                                                           minDate = {new Date()} 
                                                           selected={startDate}
                                                           onChange={(date) => {
                                                            selectStartDate(date);
                                                            formik.setFieldValue("fromTime", moment(date).format("YYYY-MM-DD"));}} 
                                                              placeholderText="From"
                                                           />
                                                         <span className="date-cal-coupon">
                                                        <ion-icon name="calendar-outline"></ion-icon>
                                                        </span>
                                                       
                                                        </Col>
                                                        <Col md={6}  sm={6} xs={6} className="p-0">
                                                          <DatePicker
                                                           className="to-datePicker" 
                                                           name="toTime" 
                                                           minDate = {new Date()} 
                                                           selected={endDate}
                                                           onChange={(date) => {
                                                            selectEndDate(date);
                                                            formik.setFieldValue("toTime", moment(date).format("YYYY-MM-DD"));
                                                             }} 
                                                           placeholderText="To"
                                                            />
                                                           <span className="date-cal-coupon">
                                                           <ion-icon name="calendar-outline"></ion-icon>
                                                           </span> 
                                                                                                               
                                                           

                                                        </Col>
                                                          
                                                                </>
                                                            ) : (
                                                               < div className="life-time-empty px-3">
                                                                <Form.Group as={Row} className="mb-3 p-0" controlId="coupon_name">
                                                                <Form.Control
                                                                 disabled
                                                                 type="text"
                                                                 value={""}
                                                        />
                                                        </Form.Group>
                                                               </div>
                                                            )
                                                        }
                                                           
                                                        </Row>
                                                         </Row>
                                                      
                                                    </Col>
                                                    <Row>
                                                        <Col>
                                                        <p className="manual-error-style px-3">
                                                            <ErrorMessage name="fromTime" />
                                                        </p>
                                                    
                                                        </Col>
                                                        <Col>
                                                        <p className="manual-error-style px-3">
                                                           <ErrorMessage name="toTime" /> 
                                                    </p>
                                                        </Col>
                                                    </Row>

                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="coupon_name">
                                                    <Form.Label column sm="12" className="form-label-coupon-code">Per User Limit</Form.Label>
                                                    <Col sm="12">
                                                
                                                          <Row className="mb-3 px-3">
                                                       <Col md={6}  sm={6} xs={6}  className="p-0 m-0">
                                                        <Card onClick={()=>{changeTimeLimit("one time")}} className={formik.values.per_user_limit_type==="one time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                            <p>One Time</p>
                                                        </Card>
                                                       </Col>
                                                       <Col md={6}  sm={6} xs={6} className="p-0 m-0">
                                                       <Card onClick={()=>{changeTimeLimit("multiple time")}} className={formik.values.per_user_limit_type==="multiple time" ? "generateCode-type-active" : "default-generateCode-type"}>
                                                       <p>Multiple Time</p>
                                                       </Card>
                                                       </Col>
                                                         </Row>
                                                         {
                                                            formik.values.per_user_limit_type==="one time" ? (
                                                                <Row className="px-3">
                                                                 <Form.Control
                                                                 disabled
                                                                 type="text"
                                                                 value={""}
                                                        />
                                                                </Row>
                                                            ) : (
                                                                <Row className="px-3">
                                                                 <Form.Control
                                                        
                                                            type="text"
                                                            placeholder="Enter user limit"
                                                            name="per_user_limit"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.per_user_limit}
                                                            isInvalid={!!formik.touched.per_user_limit && !!formik.errors.per_user_limit}
                                                        />
                                                                </Row>
                                                            )
                                                         }
                                                          <p className="manual-error-style px-3">
                                                            <ErrorMessage name="per_user_limit" />
                                                        </p>
                                                    </Col>

                                                </Form.Group>
                                                    <Form.Group className="mb-3 px-2" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label className="form-label-coupon-code">Description</Form.Label>
                                                    <Form.Control as="textarea" name='description' rows={3} placeholder="Description Eg: Get 20% on orders of 250rs, 50% on orders of 2000rs by using code SLAY50" onChange={formik.handleChange} values={formik.values.description} />
                                                </Form.Group>
                                <div className="text-center mt-5 d-grid gap-2">
                                    {
                                         formik.values.coupon_type != "Auto code" ? (
                                            <Button variant="primary" size="lg" onClick={() => handelGenerateCodeCustom()} className="generate-coupon-code">
                                            Generate Custom Code
                                        </Button>
                                         ) : (
                                            <Button variant="primary" size="lg" onClick={() => handelGenerateCode()} className="generate-coupon-code">
                                            Generate Auto Code
                                        </Button>
                                         )
                                    }
                                   
                                </div>
                            </div>
                            <hr />
                            {
                            
                            generateCode && code && code !== "" && (
                                    <div className="code-generate-sec">
                                        <h4>
                                            Copy Coupon Code
                                        </h4>
                                        <div className="random-code-sec text-center">
                                            <h1 className="mt-5">
                                                {code}
                                            </h1>
                                            <div className="marketing-code-share mt-4">
                                            <span className="mx-2">
                                            <CopyToClipboard text={code} onCopy={copiedtoClip} >
                                            <ion-icon name="copy-outline"></ion-icon>
                                            </CopyToClipboard>
                                           
                                            </span>
                                            <span className="mx-2">
                                            <ion-icon name="share-social-outline"></ion-icon>

                                            </span>
                                                    {
                                                        copyText ? (
                                                            <>
                                                            <p className="text-success">Coupon code copied</p>
                                                            </>
                                                        ) : (
                                                              null
                                                        )
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } 
                            {
                                generateText && (
                                    <p className="manual-error-style px-3">Please fill the form before generating the Coupon</p>
                                )
                            }

                        <div className="code-action-btn">
                                   <Row>
                                    <Col>
                                    <Button variant="" className="generate-coupon-code-cancel w-100" onClick={props.onHide}>Cancel</Button>
                                    </Col>
                                    <Col>
                                    <Button variant="" className="generate-coupon-code w-100" type="submit">Save</Button>
                                    </Col>
                                   </Row>
                         </div>
                        </FormikProvider>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CouponAddEdit