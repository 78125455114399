import axios from 'axios';
import * as TYPES from './types.js';
import AuthService from './services';
import { toast } from "react-toastify";
import * as BusinessTypes from "../business/types"
import * as CartTypes from "../cart/types";
import * as UserTypes from "../user/types"

// export const supportOrdersAction = (data) => (dispatch) => {
//     return AuthService.userSupportOrders(data).then(
//         (response) => {
//             const { data } = response;
//             if(data){
//                 toast.success(data.message);
//             } else {
//                 toast.error(data.message);
//             }
//         }
//     )
// }
export const supportOrdersAction = (data) => (dispatch) => {


    return AuthService.userSupportOrders(data).then(
        (response) => {
            const { data } = response;
              
              let result = data;
              console.log(result)
            if (result) {
               return result;
                
            }
            else {
                
                toast.warning(result.message)

            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(result.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                // type: TYPES.SUPPORT_MESSAGE_FAIL,
            });
            // return Promise.reject();
        },
    );
};

export const supportMessage = (data) => (dispatch) => {

    
    return AuthService.supportMessage(data).then(
        (response) => {
            const { data } = response;
              
              let result = data;
              console.log(result)
            if (result) {
                dispatch({
                    type: TYPES.SUPPORT_MESSAGE_SUCCESS,
                    payload: result.support,
                });
                toast.success(result.message)
                
            }
            else {
                dispatch({
                    type: TYPES.SUPPORT_MESSAGE_FAIL,
                });
                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(result.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.SUPPORT_MESSAGE_FAIL,
            });
            // return Promise.reject();
        },
    );
};

export const BusinessSupportMessage = (data) => (dispatch) => {
    return AuthService.BusinessSupportMessage(data).then(
        (response) => {
            const { data } = response;
            let result = data;
            if (result) {
                dispatch({
                    type: TYPES.SUPPORT_MESSAGE_SUCCESS,
                    payload: result.support
                });
                toast.success(result.message);
               
            }
            else {
                dispatch({
                    type: TYPES.SUPPORT_MESSAGE_FAIL,
                });
                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                let result = data;
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(result.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.SUPPORT_MESSAGE_FAIL,
            });
            // return Promise.reject();
        
        },
    );
};

export const signup = (data) => (dispatch) => {
    debugger
    return AuthService.signup(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                toast.success(data.message);
                return dispatch({
                    type: TYPES.SIGNUP_SUCCESS,
                    payload: { ...data.data }
                });
            } else {
                toast.error(data.message);
                return dispatch({
                    type: TYPES.SIGNUP_FAIL,
                });
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.SIGNUP_FAIL,
            });
            return Promise.reject(error);
        },
    );
};



export const verifySignup = (code) => (dispatch) =>
    AuthService.verifySignup(code).then(
        (response) => {
            return dispatch({
                type: TYPES.VERIFY_SIGNUP_SUCCESS,
            });
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.VERIFY_SIGNUP_FAIL,
            });
            return Promise.reject();
        },
    );


export const verifyToken = (code) => (dispatch) =>
    AuthService.verifyToken(code).then(
        (response) => {
            //do nothing
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return Promise.reject();
        },
    );

export const login = (data) => (dispatch) => {
    return AuthService.login(data)
        .then(
            (response) => {
                const data = response?.data;
                return data;
            },
            (error) => {
                dispatch({
                    type: TYPES.LOGIN_FAIL,
                });
                return Promise.reject(error);
            },
        )
        .then(
            (data) => {
                if (data.data.accessToken) {
                    localStorage.setItem('access', data.data.accessToken);
                    localStorage.setItem('refresh', data.data.accessToken);
                }
                dispatch({
                    type: TYPES.LOGIN_SUCCESS,
                    payload: data.data.user,
                });
                dispatch({
                    type: TYPES.LOGIN_REFRESH_SUCCESS,
                    payload: data.data.user,
                });
                if (data.data.serviceProvider) {
                    dispatch({
                        type: BusinessTypes.GET_VENDOR_DETAILS_SUCCESS,
                        payload: data.data.serviceProvider
                    });

                }
                dispatch({
                    type: BusinessTypes.CHANGE_VENDOR_TYPE,
                    payload: data.data.user?.roles[0]?.role_name
                });
                toast.success(data.message)
               
                if(data?.data?.cart){
 
                    let cart = data?.data?.cart?.map((item) =>{
                        return {...item.services, cart_id: item?._id}
                    })
                       
                    console.log("Cart actions => ",cart)
                    if(cart?.length > 0){
                        dispatch({
                            type: CartTypes.READ_CART_SERVICE_SUCCESS,
                            payload: cart
                        })
                    }
                    
                }
                if(data?.data?.cartserviceprovider){
                    dispatch({
                        type: CartTypes.CHANGE_SERVICE_PROVIDER_DETAILS,
                        payload: data?.data?.cartserviceprovider
                    });
                }


            },
            (error) => {
                if (error.response) {
                    const { status, data } = error.response
                    if (status > 200 && status < 500) {
                        toast.warning(data.message);
                    }
                    else {
                        toast.error("Something went wrong");
                    }
                }
                else {
                    toast.error("Something went wrong");
                }

                dispatch({
                    type: TYPES.LOGIN_FAIL,
                });

                return Promise.reject(error);
            },
        );
};

export const loginRefresh = (refreshToken) => (dispatch) => {
    return AuthService.loginRefresh(refreshToken).then(
        (response) => {
            if (response) {
                if (response.data.access && response.data.refresh) {
                    localStorage.setItem('access', response.data.access);
                    localStorage.setItem('refresh', response.data.refresh);
                }
                return dispatch({
                    type: TYPES.LOGIN_REFRESH_SUCCESS,
                    payload: response.data,
                });
            }
        },

        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.LOGIN_REFRESH_FAIL,
            });

            return Promise.reject();
        },
    );
};
export const forgotPassword = (data) => (dispatch) => {
    return AuthService.forgotPassword(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                dispatch({
                    type: TYPES.FORGOT_PASSWORD_SUCCESS,
                    payload: data.data
                });
                toast.success(data.message);
            }
            else {
                dispatch({
                    type: TYPES.FORGOT_PASSWORD_FAIL,
                });
                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );
};

export const ChangeEmailAction = (data) => (dispatch) => {
    return AuthService.changeEmail(data).then(
        (response) => {
            const { data } = response;
            if (data) {
                console.log("data==>",data)
                // dispatch({
                //     type: TYPES.CHANGE_EMAIL_SUCCESS,
                //     payload: data?.updatedUser

                // });
                toast.success(data.message);
            }
            else {
                dispatch({
                    type: TYPES.CHANGE_EMAIL_FAIL,
                });
                toast.error("Something went wrong");
            }

        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        // dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.CHANGE_EMAIL_FAIL,
            });
            return Promise.reject();
        },
    );
};

export const verifyForgotPassword = (code) => (dispatch) =>
    AuthService.verifyForgotPassword(code).then(
        (response) => {
            if (response) {
                return dispatch({
                    type: TYPES.VERIFY_FORGOT_PASSWORD_SUCCESS,
                    payload: {
                        code: code,
                    },
                });
            }
            return Promise.resolve();
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.VERIFY_FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );

export const verifyEmail = (data) => (dispatch) =>
    AuthService.verifyEmail(data).then(
        (response) => {
            if (response) {
                const { data } = response;
                toast.success(data.message);
                return dispatch({
                    type: TYPES.VERIFY_EMAIL_SUCCESS,
                    payload: {
                        code: data.verify_code,
                    },
                });
            }  else {

                toast.error("Something went wrong");
                return false;
            }
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                        return false;
                    }
                    toast.warning(data.message);
                    return false;
                    
                }
                else {
                    toast.warning(data.message);
                    return false;
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.VERIFY_EMAIL_FAIL,
                
            });
            // return Promise.reject();
            return false;
        },
    );

export const verifiedForgotPassword = (data) => (dispatch) => {
    return AuthService.verifiedForgotPassword(data).then(
        (response) => {
            if (response) {
                const { data } = response;
                toast.success(data.message);
                return dispatch({
                    type: TYPES.VERIFIED_FORGOT_PASSWORD_SUCCESS,
                });
            }
            return Promise.resolve();
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.VERIFIED_FORGOT_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );
};

export const ChangePasswordAction = (data) => (dispatch) => {
    return AuthService.changePassword(data).then(
        (response) => {
            if (response) {
                const { data } = response;
                toast.success(data.message);
                // dispatch(logoutFromLocalStorage());
                return dispatch({
                    type: TYPES.CHANGE_PASSWORD_SUCCESS,
                });
            }
            return Promise.resolve();
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        toast.warning(data.message);
                    }
                    
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.CHANGE_PASSWORD_FAIL,
            });
            return Promise.reject();
        },
    );
};
export const logout = (refreshToken) => (dispatch) => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    delete axios.defaults.headers.common['x-access-token'];
    return AuthService.logout(refreshToken).then(
        (response) => {
            if (response) {
                return dispatch({
                    type: TYPES.LOGOUT,
                });
            }
            return Promise.resolve();
        },
        (error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.LOGOUT,
            });
            return Promise.reject();
        },
    );
};
export const logoutFromLocalStorage = () => (dispatch) => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');

    delete axios.defaults.headers.common['x-access-token'];
    dispatch({
        type: TYPES.LOGOUT,
    });
    dispatch({
        type: BusinessTypes.CLEAR_VENDOR_DETAILS
    })
    dispatch({
        type: CartTypes.CLEAR_SERVICE_PROVIDER_DETAILS
    })
    dispatch({
        type: CartTypes.CLEAR_CART_DETAILS
    })

    dispatch({
        type: UserTypes.CLEAR_ALL_USER_ORDER_DETAILS
    })



};

export const getUser = (username) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ME_REQUEST,
    });
    return AuthService.getUser(username)
        .then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ME_SUCCESS,
                    payload: { user: response.data },
                });
                return response;
            }
            dispatch({
                type: TYPES.GET_ME_FAIL,
            });
        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ME_FAIL,
            });
        });
};

export const updateUserAction = (data) => (dispatch) => {
    return AuthService.updateUser(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ME_SUCCESS,
                    payload: { user: data.user },
                });
                toast.success(data.message)
                return response;
            }
            else {
                dispatch({
                    type: TYPES.GET_ME_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ME_FAIL,
            });
        });
};
export const updateUserActionForEmail = (data) => (dispatch) => {
    return AuthService.updateUser(data)
        .then((response) => {
            const { data } = response
            if (response && response.status === 200) {
                dispatch({
                    type: TYPES.GET_ME_SUCCESS,
                    payload: { user: data.user },
                });
                return response;
            }
            else {
                dispatch({
                    type: TYPES.GET_ME_FAIL,
                });
            }

        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({
                type: TYPES.GET_ME_FAIL,
            });
        });
};

export const changeUserAvatar = (data) => ({
    type: TYPES.CHANGE_USER_AVATAR_SUCCESS,
    payload: { data },
});


export const needToLoginAction = (payload) => ({ type: TYPES.NEED_TO_LOGIN, payload });

export const setTokens = (data) => ({
    type: TYPES.LOGIN_SUCCESS,
    payload: data,
});

export const addFollowing = () => ({ type: TYPES.ADD_FOLLOWING_COUNT_SUCCESS });

export const reduceFollowing = () => ({ type: TYPES.REDUCE_FOLLOWING_COUNT_SUCCESS });



export const verifyRegisteredEmail = (data) => (dispatch) => {
    return AuthService.verifyRegisteredEmail(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};

export const verifyProfileEmailAction = (data) => (dispatch) => {
    return AuthService.verifyProfileEmail(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            // return Promise.reject(error);
        });
};

export const resendOtp = (data) => (dispatch) => {
    return AuthService.resendOtp(data)
        .then((response) => {
            const { data } = response;
            if (response && response.status === 200) {
                toast.success(data.message)
                return response;
            }
            else {
                toast.warning(data.message);
                console.log(response);
            }

        })
        .catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            return Promise.reject(error);
        });
};


