import React from 'react';
import BusinessList from './BusinessList';
import DashboardHeader from '../DashboardHeader';
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import { Col, Row } from 'react-bootstrap';

function BusinessPage() {
  return (
    <div className="dashboard-page">
      <DashboardHeader />
      <div className="dashboard-body">
        <div className="">
        <Row>
        <Col className='p-0' md={2} sm={12}>  
          <div className="sidebar-content">
            <AdminSidebarPage />
          </div>
          </Col>
          <Col md={10} sm={12}>
          <div className="main-content">
            <BusinessList />
          </div>
          </Col>
        </Row>
        </div>
      </div>
    </div>
  )
}

export default BusinessPage;