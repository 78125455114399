import * as TYPES from './types.js';

const initialState = {
    vendorType: "",
    vendor: {},
    getVendorLoading: false,
    isVendorLoggedIn: localStorage.getItem('access') ? true : false,
    homeSearch: {
        address: "",
        service: "",
        category: []
    },

    exploreSearch: {
      
    }


};


const businessReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.VENDOR_REGISTER_SUCESS:
            return {
                ...state,
                vendor: payload,
            };
        case TYPES.VENDOR_REGISTER_FAIL:
            return {
                ...state,
                isVendorLoggedIn: false,
            };
        case TYPES.VENDOR_UPDATE_SUCESS:
            return {
                ...state,
                vendor: payload,
            };
        case TYPES.VENDOR_UPDATE_FAIL:
            return {
                ...state,
                isVendorLoggedIn: false,
            };
        case TYPES.CHANGE_VENDOR_TYPE:
            return {
                ...state,
                vendorType: payload,
            };
        case TYPES.GET_VENDOR_DETAILS_SUCCESS:
            return {
                ...state,
                vendor: payload,
                isVendorLoggedIn: true,
            };
        case TYPES.GET_VENDOR_DETAILS_FAIL:
            return {
                ...state,
                isVendorLoggedIn: false,
            };
        case TYPES.CLEAR_VENDOR_DETAILS:
            return {
                ...state,
                vendorType: "",
                vendor: null,
                isVendorLoggedIn: false,
            };
        case TYPES.CHANGE_HOME_SEARCH:
            return {
                ...state,
                homeSearch: payload,
            };
        case TYPES.CLEAR_HOME_SEARCH:
            return {
                ...state,
                homeSearch: {
                    address: "",
                    service: "",
                    category: []
                },
            };
            case TYPES.GET_ALL_VENDOR_SEARCH:
                return {
                    ...state,
                    exploreSearch: payload
                }

        default:
            return state;
    }
};

export default businessReducer;
