import React from 'react';
import Header from '../../components/elements/header/Header';
import "../../assets/css/policy.css"
import Footer from '../../components/elements/footer/Footer';

function Reschedule() {
    return (
        <div>
            <Header />
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 className="policy-header">Cancellations and Reschedule</h6>
                            <h2 class="terms-paragraph">1.  Cancellation</h2>
                            <p class="terms-paragraph">Bookings made on Slaylewks platform are subject to the following cancellation terms: </p>
                            <ul>
                                <li className='py-2'>Cancellations made more than 48 Hrs before the appointment date and time are eligible for a 100 % refund (Not applicable for Bridal Services) </li>
                                <li className='py-2'>Cancellations made within 48 hrs of the appointment date and time are eligible for 90 % refund (Not applicable for Bridal Services) </li>
                                <li className='py-2'>Bridal services are not eligible for refund. Users are encouraged to do a reschedule instead. </li>
                            </ul>
                            <p class="terms-paragraph">The types of personal data collected depend on the purpose(s) of collection. We may process your personal data by way of collecting, recording, altering, holding, storing, transferring, erasing, correcting, retrieving, destructing, aligning, combining, disclosing and/or deleting it.</p>
                            <h2 class="terms-paragraph">2.  Reschedule</h2>
                            <p class="terms-paragraph">Bookings made on Slaylewks platform are subject to the following reschedule terms:</p>
                           <ul>
                            <li className='py-2'>Appointments can be rescheduled up to 24 hrs before the appointment date and time. </li>
                            <li className='py-2'>No reschedule is allowed within 24 hrs of the appointment date and time. If the customer doesn’t show, it is treated as a cancellation and no refund will be made.</li>
                           </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Reschedule