import React from 'react';
import TimePicker from 'react-gradient-timepicker';

function TimePickerHelper(time,setTime) {
  return (
    <div>
      <TimePicker
        time="01:00"
        theme="Bourbon"
        className="timepicker"
        placeholder="Pick Time"
        onSet={(val) => {setTime(val)}}
      />
    </div>
  )
}

export default TimePickerHelper;