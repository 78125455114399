import * as TYPES from './types.js';
import PaymentService from './services';
import { toast } from "react-toastify";
import { logoutFromLocalStorage } from '../auth/actions';


export const createRPOrder = (data) => (dispatch) => {
    console.log(data, "CreateRP order COnsole")
    dispatch({ type: TYPES.CREATE_RP_ORDER });
    return PaymentService.createRPOrder(data)
        .then((response) => {
            console.log("response ==>", response)
            if (response.status === 200) {
                dispatch({
                    type: TYPES.CREATE_RP_ORDER_SUCCESS,
                    payload: { data: response.data },
                });
                console.log(response.data)
                return response.data;
            }
            dispatch({ type: TYPES.CREATE_RP_ORDER_FAIL });
        })
        .catch((error) => {
            console.log(error.response)
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({ type: TYPES.CREATE_RP_ORDER_FAIL });
        });
};

export const paymentFailed = (data) => (dispatch) => {
    console.log('paymentFailed')
    console.log(data);
    return PaymentService.paymentFailed(data)
        .then((response) => {
            if (response.status === 200) {
                console.log(response.data)
                return response.data;
            }
        })
        .catch((error) => {
            console.log(error.response)
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
        });
};

export const verifyRazorpayPayment = (data) => (dispatch) => {
    dispatch({ type: TYPES.START_PAYMNET_VERIFICATION });
    console.log(data);
    return PaymentService.verifyRazorpayPayment(data)
        .then((response) => {
            if (response.status === 200) {
                console.log('success');
                return response.data.isSignatureValid
            }
            dispatch({ type: TYPES.PAYMNET_VERIFICATION_SUCCESS });
            console.log('finish');
        })
        .catch((error) => {
            console.log(error.response)
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);

                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            dispatch({ type: TYPES.PAYMNET_VERIFICATION_FAIL });
            console.log('finish');
        });
};

export const setVendorID = (data) => (dispatch) => {
    dispatch({ 
        type: TYPES.SET_VENDOR_ID,
        payload: data
    })
};

export const setOrderAmount = (data) => (dispatch) => {
    // Razorpay requires basic currency unit. Rupee -> Paise
    data.total_amount = data.total_amount * 100;
    data.taxable_amount = data.taxable_amount * 100;
    data.tax_amount = data.tax_amount * 100;
    data.discount_amount = data.discount_amount * 100;
    data.coupon_amount = data.coupon_amount * 100;
    data.gst_amount = data.gst_amount * 100;
    data.coupon_amount_reduced = data.coupon_amount_reduced * 100;
    data.coupon_name = data.coupon_name;
    data.isAdmin = data.isAdmin;


    console.log(data,"orderampunt");
    dispatch({
        type: TYPES.SET_ORDER_AMOUNT,
        payload: data
    });
};

export const raiseRefund = (data) => (dispatch) => {
    console.log(data);
    return PaymentService.raiseRefund(data)
        .then((response) => {
            if (response.status === 200) {
                console.log('success');
                return {
                    result: true
                }
            }
        })
        .catch((error) => {
            console.log(error.response)
            if (error.response) {
                const { status, data } = error.response
                if (status > 200 && status <= 500) {
                    if (status === 401) {
                        dispatch(logoutFromLocalStorage());
                    }
                    toast.warning(data.message);
                }
                else {
                    toast.error("Something went wrong");
                }
            }
            else {
                toast.error("Something went wrong");
            }
            return {
                result: false,
                error: error.response.message
            }
        });
};