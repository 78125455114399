import React, { useState, useEffect } from 'react';
// import galleryImg from "../../../assets/images/gallery.webp";
// import galleryLikeImg from "../../../assets/images/like-gallery.png"
// import dislikeImg from "../../../assets/images/dislike.png";
import nullImage from "../../../assets/images/no-image-found.svg"
import { useDispatch, useSelector} from "react-redux";
import { getAllBusinessGallery } from "../../../redux/business/actions";
import { Row, Col, Modal, Button } from "react-bootstrap"

function GalleryPage(props) {

    const [state, setState] = useState({
        data: [],
        limit: 10,
        activePage: 1,
        total: 1
    });
    const [imgView, setImgView] = useState(false)
    const [imgSelected, setImgSelected] = useState()

    const imageView = (item) => {
        setImgSelected(item)
        setImgView(true)

    }
    const { vendor } = useSelector((state) => state.business);

    const [filterBy, setFilterBy] = useState("");

    const dispatch = useDispatch();

    const { id } = props;

    useEffect(() => {
        let data = {
            id: id,
            image_category: "",
        }
        dispatch(getAllBusinessGallery(data))
            .then((res) => {
                const { results, totalcount, resultsPerPage } = res
                setState((prev) => ({
                    ...prev,
                    data: res,
                    total: res.length
                }))
            })
            .catch((err) => {
                console.log("Err => ", err)
            })
    }, [id])

    const onChangeFilter = async (e) => {
        await setFilterBy(e.target.value);
        const data = {
            id: id,
          image_category: e.target.value,
        };
    
        dispatch(getAllBusinessGallery(data))
          .then((res) => {
            setState((prev) => ({
              ...prev,
              data: res,
              total: res.length
            }))
          })
        // onServiceDataChange();
      }



    return (
        <div className="business-gallery-page">
            <div class="row">
                <div class="col-md-12">
                    <div class="gallery-list-inner">
                        <Row>
                            <Col>
                            <div className='float-end mb-2'>
                            <select class="form-select filter-service"
                                    aria-label="Default select example"
                                    name="filterService"
                                    onChange={onChangeFilter}
                                    value={filterBy}
                                    >
                                    <option value="" selected>Filter</option>  
                                    <option value="Hair">Hair</option>
                                    <option value="Spa">Spa</option>
                                    <option value="Nails">Nails</option>
                                    <option value="Makeup">Makeup</option>
                                    <option value="Mehendi">Mehendi</option>
                                    <option value="Skin">Skin</option>
              </select>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                state.data && state.data.length > 0 ? (
                                    state.data.map((item, index) => {
                                        return (
                                            <Col md={3} key={index} className="mb-4">
                                                <img onClick={()=>imageView(item)}  src={item?.image_URL} alt="gallery" class="gallery-image-user" />
                                            </Col>
                                        )
                                    })
                                ) : (
                                    <img className='no-image-found' src={nullImage} alt="no results Found" />
                                )
                            }
                            <Modal  centered size="md" show={imgView} onHide={()=>setImgView(false)}>
                                    <img src={imgSelected?.image_URL} alt='image' className='img-fluid previewImage w-100' />
                                </Modal>
                        </Row>
                        {/* <div class="load-more-outer text-center pb-5">
                            <button class="btn load-more">Load more</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryPage