import React, { useState } from "react";
import {
    Form,
    Modal,
    Button
} from "react-bootstrap";
import logo from "../../../assets/images/logo.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { verifiedForgotPassword, logoutFromLocalStorage } from '../../../redux/auth/actions';
import { resetPasswordValidationNew } from "../../../validations/yub_schemas/resetPasswordValidationNew";
import { useNavigate } from 'react-router-dom';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const [eyeState, setEyeState] = useState(false)
    const [conformEyeState, setConformEyeState] = useState(false)

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: resetPasswordValidationNew,
        onSubmit: (values) => {
            const data = {
                id: user?.user_id || user?._id,
                password: values.password,
            };

            dispatch(verifiedForgotPassword(data))
                .then(() => {
                    formik.resetForm();
                    props.onHide(true);
                    dispatch(logoutFromLocalStorage());
                    navigate("/")
                    props.setloginmodal(true);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        },
    });

    const Eyeview = () => {
        setEyeState(prevState => !prevState)
    }

    const ConformEyeview = () => {
        setConformEyeState(prevState => !prevState)
    }

    return (
        <div className="new-password">
            <Modal
                // {...props}
                show={props.show}
                onHide={props.onHide}
                dialogClassName="register-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="">
                    <div className="text-center">
                        {/* <img className="logo"
                            src={logo}
                            width="182.46px"
                            height="40px"
                            alt="Slaylewks logo" /> */}

                        <h4 className="register-header">Reset Password</h4>
                        <div className="m-1">
                            <h6><small className="text-muted">Please Enter New Password</small></h6>
                        </div>
                    </div>

                    <div>
                        <Form className="register-form" onSubmit={formik.handleSubmit}>
                            <FormikProvider value={formik}>

                                <Form.Group className="p-2" controlId="formBasicPassword">
                                    <Form.Control
                                        type={eyeState ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                    />
                                     <span className="reset-eye-icon" onClick={Eyeview}>
                                        {eyeState ? <AiOutlineEye /> :
                                            <AiOutlineEyeInvisible />
                                        }
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="p-2" controlId="formBasicConfirmPassword">
                                    <Form.Control
                                        type={conformEyeState ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        isInvalid={!!formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                                    />
                                        <span className="reset-eye-icon-conform" onClick={ConformEyeview}>
                                        {conformEyeState ? <AiOutlineEye /> :
                                            <AiOutlineEyeInvisible />
                                        }
                                    </span>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div className="text-center d-grid gap-2 p-2">
                                    <Button className="register-button" type="submit" size="lg">
                                        Submit
                                    </Button>
                                </div>
                            </FormikProvider>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ResetPassword;