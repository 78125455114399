import React from 'react'
import {useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ReferAndEarn() {
    
    const { user } = useSelector((state) => state.auth);

    const referral_text = user?.user_referral_code;
    const [copyText, setCopyText] = useState(false)

    const copiedtoClip = () => {
        setCopyText(true)
        setTimeout(()=>{
            setCopyText(false)

        }, 2000)
    }

  return (
    <div>
      <Row>
      <div className='Refer-section'>
      <h1>Refer and Invite friends</h1>
      <p>Send a referral code to your friends via SMS/ Whatsapp/ Email</p>
      <h6>Referral Code</h6>

        </div>
      </Row>

      <Row>
        <Col>
        <div className='refer-code'>
            <p>{referral_text}</p>                
                {/* <p>XUVSGVFDY</p> */}
                <CopyToClipboard text={referral_text} onCopy={copiedtoClip}>
                <h6>Copy</h6>
                </CopyToClipboard>
               
        </div>
        <div className='mt-5'>
        {
                                                        copyText ? (
                                                            <>
                                                            <p className="text-success text-center">Referall code copied</p>
                                                            </>
                                                        ) : (
                                                              null
                                                        )
                                                    }
        </div>
        </Col>
     
      </Row>
    </div>
  )
}

export default ReferAndEarn
