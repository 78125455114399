export const CHANGE_VENDOR_TYPE = 'CHANGE_VENDOR_TYPE';

export const VENDOR_REGISTER_SUCESS = 'VENDOR_REGISTER_SUCESS';
export const VENDOR_REGISTER_FAIL = 'CHANGE_REGISTER_FAIL';
export const VENDOR_UPDATE_SUCESS = 'VENDOR_UPDATE_SUCESS';
export const VENDOR_UPDATE_FAIL = 'VENDOR_UPDATE_FAIL';


export const GET_VENDOR_DETAILS_SUCCESS = 'GET_VENDOR_DETAILS_SUCCESS';
export const GET_VENDOR_DETAILS_FAIL = 'GET_VENDOR_DETAILS_FAIL';
export const GET_ALL_VENDOR_SEARCH = 'GET_ALL_VENDOR_SEARCH';

export const CLEAR_VENDOR_DETAILS = 'CLEAR_VENDOR_DETAILS';

export const CHANGE_HOME_SEARCH = 'CHANGE_HOME_SEARCH';
export const CLEAR_HOME_SEARCH = 'CLEAR_HOME_SEARCH';

