import * as Yup from 'yup';



export const businessTimingValidation = Yup.object({
    

    business_type: Yup.string('Enter the business type').required('business type is required'),
    age_category: Yup.array().min(1).required('gendor category is required'),
    service_category: Yup.array().min(1).required('service type is required'),
    business_basic_hours: Yup.array().min(1).required('business hours is required'),
    business_open_days: Yup.array().min(1).required('business days is required'),
    // advance_percentage: Yup.string('please choose your advance percentage').required('advance percentage is required'),

});

