const apiUrl = process.env.REACT_APP_API_URL;

export const DASHBOARD_CONFIG = {
    dashboardStatus: `${apiUrl}/dashboard/status`,

    createService: `${apiUrl}/service/create`,
    updateService: `${apiUrl}/service/update`,
    deleteService: `${apiUrl}/service/delete`,
    deleteManyService: `${apiUrl}/service/deletemany`,
    getService: `${apiUrl}/service/get`,
    getAllService: `${apiUrl}/service/findall`,

    createAppointment: `${apiUrl}/appointment/create`,
    updateAppointment: `${apiUrl}/appointment/update`,
    deleteAppointment: `${apiUrl}/appointment/delete`,
    getAppointment: `${apiUrl}/appointment/getone`,
    getAllAppointment: `${apiUrl}/appointment/getall`,


    createOrder: `${apiUrl}/order/create`,
    updateOrder: `${apiUrl}/order/update`,
    deleteOrder: `${apiUrl}/order/delete`,
    getOrder: `${apiUrl}/order/get`,
    getAllOrder: `${apiUrl}/order/getall`,
    verifyOrderOtp: `${apiUrl}/orderotp/verify`,

    getAllRequest: `${apiUrl}/requests/findall`,
    getOneRequest: `${apiUrl}/order/getone`,
    acceptDenyRequest: `${apiUrl}/requests/acceptordenyorcancel`,
    
    createStaff: `${apiUrl}/staff/create`,
    updateStaff: `${apiUrl}/staff/update`,
    deleteStaff: `${apiUrl}/staff/delete`,
    getStaff: `${apiUrl}/staff/get`,
    getAllStaff: `${apiUrl}/staff/getall`,


    updateDashboardSettings: `${apiUrl}/dashboard/settings/update`,
    getAllDashboardSettings: `${apiUrl}/dashboard/settings`,

    adminDashboardStatus: `${apiUrl}/admin/dashboard/`,

    createUser: `${apiUrl}/admin/user/create`,
    updateUser: `${apiUrl}/admin/user/update`,
    deleteUser: `${apiUrl}/admin/user/delete`,
    getUser: `${apiUrl}/admin/user/get`,
    getAllUsers: `${apiUrl}/admin/userboard`,

    updateProposal: `${apiUrl}/admin/acceptdecline`,
    getOneProposal: `${apiUrl}/admin/getserviceprovider`,
    getAllProposals: `${apiUrl}/admin/proposalboard`,
    updateProposalDetails: `${apiUrl}/admin/updateserviceprovider`,

    createBusiness: `${apiUrl}/admin/business/create`,
    updateBusiness: `${apiUrl}/admin/business/update`,
    deleteBusiness: `${apiUrl}/admin/business/delete`,
    getBusiness: `${apiUrl}/admin/business/get`,
    getAllBusiness: `${apiUrl}/admin/businessfreelancerboard`,

    deleteServiceProvider: `${apiUrl}/admin/serviceprovider/delete`,


    createFreelancer: `${apiUrl}/admin/freelancer/create`,
    updateFreelancer: `${apiUrl}/admin/freelancer/update`,
    deleteFreelancer: `${apiUrl}/admin/freelancer/delete`,
    getFreelancer: `${apiUrl}/admin/freelancer/get`,
    getAllFreelancer: `${apiUrl}/admin/businessfreelancerboard`,


    getAllMarketing: `${apiUrl}/admin/marketing`,


    changeProposalStatus: `${apiUrl}/admin/acceptdecline`,
    addStaffs: `${apiUrl}/resource/onboardingcreate`,


    //Coupon code apis
    createCouponCode: `${apiUrl}/couponcode/create`,
    getAllCouponCode: `${apiUrl}/couponcode/findall`,
    getOneCouponCode: `${apiUrl}/couponcode/findone`,
    updateCouponCode: `${apiUrl}/couponcode/update`,
    deleteCouponCode: `${apiUrl}/couponcode/delete`,

    //Coupon Code admin
    createAdminCouponCode: `${apiUrl}/admincoupon/create`,
    getOneAdminCouponCode: `${apiUrl}/admincoupon/findone`,
    adminUpdateCouponCode: `${apiUrl}/admincoupon/update`,
    adminUpdateGetoneCouponCode: `${apiUrl}/admin/getusersgroup`,
    adminDeleteCouponCode: `${apiUrl}/admincoupon/delete`,
    // admin created coupon for business
    businessDeleteCouponCode: `${apiUrl}/businesscoupon/delete`,

    adminDeleteAllCouponCode: `${apiUrl}/admincoupon/deletemany`,
    adminGetAllCouponCode: `${apiUrl}/admincoupon/findall`,
    findallServiceInCouponCode: `${apiUrl}/serviceprovider/findall`,
    getAllCouponCodeGrouping: `${apiUrl}/admincoupon/findall`,
    getAllCouponCodeGrouping_business: `${apiUrl}/businesscoupons/findall`,
    // adminfindAllUsers: `${apiUrl}/admin/userboard`,
    adminfindAllUsers: `${apiUrl}/admin/users`,
    adminAddUsersCoupon: `${apiUrl}/admin/addusersgroup`,
    addBusinessCouponCodeUsers : `${apiUrl}/business/creategroup`,
    viewBuinessCouponCodeUsers : `${apiUrl}/business/getgroup`,
    updateBusinessCouponCodeUsers : `${apiUrl}/business/updategroup`,
    deleteBusinessCouponCodeUsers : `${apiUrl}/business/deletegroup`,
    adminDeleteUsersCoupon: `${apiUrl}/admin/deleteusersgroup`,
    adminDeleteUsersCoupon: `${apiUrl}/admin/deleteusersgroup`,
    updateAdminUserCoupons: `${apiUrl}/admin/getusersgroup`,
    updateAdminGroupingCoupons: `${apiUrl}/admin/updateusersgroup`,


    //Coupon code business
    createBusinessCouponCodeNew: `${apiUrl}/businesscoupon/create `,
    getOneBusinessCouponCodeNew: `${apiUrl}/businesscoupon/findone`,
    BusinessUpdateCouponCodeNew: `${apiUrl}/businesscoupon/update`,
    BusinessDeleteCouponCodeNew: `${apiUrl}/businesscoupon/delete`,
    BusinessGetAllCouponCodeNew: `${apiUrl}/businesscoupon/findall`,
    BusinessGetServiceCategoryNew: `${apiUrl}/serviceCategory/dropdown`,
    BusinessDeleteAllCouponCodeNew: `${apiUrl}/businesscoupon/deletemany`,



    //Referral code apis
    createReferralCode: `${apiUrl}/referralcode/create`,
    getAllReferralCode: `${apiUrl}/referralcode/findall`,
    getOneReferralCode: `${apiUrl}/referralcode/findone`,
    updateReferralCode: `${apiUrl}/referralcode/update`,
    deleteReferralCode: `${apiUrl}/referralcode/delete`,


    //Resources apis
    createResources: `${apiUrl}/resource/create`,
    findAllresources: `${apiUrl}/resource/getall`,
    findOneResource: `${apiUrl}/resource/getone`,
    updateResources: `${apiUrl}/resource/update`,
    deleteResources: `${apiUrl}/resource/delete`,



    createBusinessCouponCode: `${apiUrl}/businessmarketing/create`,
    findAllbusinessCouponCodes: `${apiUrl}/businessmarketing/getall`,
    findOneBusinessCouponCode: `${apiUrl}/businessmarketing/getone`,
    updateBusinessCouponCode: `${apiUrl}/businessmarketing/update`,
    deleteBusinessCouponCode: `${apiUrl}/businessmarketing/delete`,


    createGallery: `${apiUrl}/gallery/create`,
    findAllGallery: `${apiUrl}/gallery/getall`,
    findOneGallery: `${apiUrl}/gallery/getone`,
    updateGallery: `${apiUrl}/gallery/update`,
    deleteGallery: `${apiUrl}/gallery/delete`,
    findAllUsedCoupon: `${apiUrl}/admincoupon/usedcount`,
    findAllUsedCouponBusiness: `${apiUrl}/businesscoupon/usedcount`,

    transcationGetAll : `${apiUrl}/admin/commissioncount`,
    commissionGetAll : `${apiUrl}/admin/view/serviceproviders`,
    commissionUpdate:`${apiUrl}/admin/paymentcommission`,
    updateBasicInformationApi: `${apiUrl}/basicsetting`,
    updateBusinessTimingApi: `${apiUrl}/servicesetting`,


    //referral List 
    getReferralList: `${apiUrl}/admin/referrallist`

};
 