import React, { useState, useEffect } from 'react';
import ProposalsList from "./ProposalsList";
import DashboardHeader from '../DashboardHeader';
import AdminSidebarPage from "../../../components/elements/siderbar/AdminSidebarPage";
import NaturalImg from "../../../assets/images/naturalimg.png"
import NaturalImg2 from "../../../assets/images/naturalsimg2.webp"
import AcceptImg from "../../../assets/images/accept.png"
import declineImg from "../../../assets/images/decline.png";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getProposalDetails, changeProposalStatus, addStaffs } from "../../../redux/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment' 
import {Row, Col, Modal, Button, Form} from 'react-bootstrap';
import { useFormik, FormikProvider} from "formik";
import { supportValidation } from '../../../validations/yub_schemas/supportValidation';
import { commentMessageValidation } from '../../../validations/yub_schemas/commentMessagevalidation';
import {IoIosArrowBack } from "react-icons/io";


function ProposalView() {

    const [serviceProvider, setServiceProvider] = useState({})
    const [serviceType, setServiceType] = useState("")
    const [serviceCategory, setServiceCategory] = useState("")
    const { id } = useParams();
    

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [declineshow, setDeclineShow] = useState(false) ; 
    const handleClose = () => setDeclineShow(false);
     const [searchParams] = useSearchParams();

    const type = searchParams.get("type");

    const declineComment = () => {
        setDeclineShow(false);
        
   }
   const acceptService = () => {
       navigate("/dashboard/proposal"); 
  }

    useEffect(() => {
        const data = {
            id: id
        };
        dispatch(getProposalDetails(data))
            .then((res) => {
                setServiceProvider(res.serviceprovider);

            })
    }, [id]);


    const createstaffs = (value) => {


        let staff = {
            serviceProvider: id,
        }

        if(value == "business"){
            dispatch(addStaffs(staff))
        }


    }

    const onsubmitservice = (value) => {
        let data = {
            id: id,
            status: value,

        }

        if(value === 'decline'){
             data.comment = formik.values.comment
        }
        dispatch(changeProposalStatus(data))
        declineComment();
        navigate("/dashboard/proposal"); 
      
  
    }

    const formik = useFormik({
        enableReinitialize: true,
          initialValues:{
              id: id,
              comment: ""
                  
          },
          validationSchema: commentMessageValidation,
          onSubmit: (values) => {
           
            const data = {
              id: id,
              comment: values?.comment
            };
            onsubmitservice("decline");
            formik.resetForm();
        
        },
    });

    const goBack =() =>{
        navigate('/dashboard/proposal')
    }

    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <div className="dashboard-body">
                <div className="">
                    <Row>
                        <Col className="p-0" md={2}>
                        <div className="sidebar-content">
                        {/* <AdminSidebarPage /> */}
                        <h6 className='text-primary mt-4 px-3 back-text' onClick={goBack}>
                                <span >
                                    <IoIosArrowBack size={16} />
                                </span>
                                    Back</h6>

                    </div>
                        </Col>
                        <Col md={10}>
                        <div className="main-content">
                        <section>
                            <div class="container">
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <h3>Reg: {serviceProvider?.reg_id} </h3>
                                        <h5 className='mt-4'>{serviceProvider?.vendor_type} Account</h5>
                                    </div>
                                    {
                                        serviceProvider?.created_at && (
                                            <div class="col-md-6">
                                                <h3 class="float-end">Date: {new Date(serviceProvider?.created_at).toLocaleDateString()} </h3>
                                            </div>
                                        )
                                    }
                                </div>
                                <hr />
                                <div class="row mb-2">
                                    <div class="col-md-7">
                                        <h5>Business details</h5>
                                        <div class="proposal-view-left">
                                            <Row className='mb-3'>
                                                <Col> <h6>
                                                Business Name
                                            </h6></Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.business_name}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'> 
                                                <Col>
                                                <h6>
                                                Business Address
                                            </h6>
                                                </Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.location_details?.area}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                <h6>
                                                City
                                            </h6></Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.location_details?.city}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                <h6>
                                                State
                                            </h6></Col>
                                                <Col>
                                                
                                                <span className="text-muted">
                                                    {serviceProvider?.location_details?.state}
                                                </span></Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                
                                                <h6>
                                                Country
                                            </h6></Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.location_details?.country}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                <h6>
                                                Zipcode
                                            </h6>
                                                </Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.location_details?.zipcode}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col>
                                                <h6>
                                                Address Url
                                            </h6>
                                                </Col>
                                                <Col>
                                                <span className="text-muted">
                                                    {serviceProvider?.location_url}
                                                </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <h5>Contact number at Business</h5>
                                        <div class="proposal-view-right ">
                                            <Row className='mb-3'>
                                                <Col md={5}>
                                                <h6>
                                                Email Id
                                            </h6>
                                                </Col>
                                                <Col md={7}>
                                                <span className="text-muted">
                                                    {serviceProvider?.business_email}
                                                </span>
                                                </Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col md={5}>
                                                <h6>
                                                Phone no
                                            </h6></Col>
                                                <Col md={7}>
                                                <span className="text-muted">
                                                    {serviceProvider?.business_contactNo}
                                                </span></Col>
                                            </Row>
                                            <Row className='mb-3'>
                                                <Col md={5}>
                                                <h6>
                                                Landline no
                                            </h6>
                                                </Col>
                                                <Col md={7}>
                                                <span className="text-muted">
                                                    {serviceProvider?.business_landline}
                                                </span>
                                                </Col>
                                            </Row>
                                           
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row mb-2 mt-5">
                                    <div class="col-md-6">
                                        <h5 className='mb-3'>Establishment type</h5>
                                        {
                                            serviceProvider.serviceTypes && serviceProvider.serviceTypes && (
                                                serviceProvider.serviceTypes.map((item) => {
                                                    return (
                                                        <p>
                                                            {item.servicetype_name} &nbsp;
                                                        </p>
                                                    )
                                                })
                                            )
                                        }

                                        <div className="mt-5">
                                            <h5>Age category</h5>
                                            {
                                                serviceProvider.gender && serviceProvider.gender && (
                                                    serviceProvider.gender.map((item) => {
                                                        return (
                                                            <p>
                                                                {item.gender_name} &nbsp;
                                                            </p>
                                                        )
                                                    })
                                                )
                                            }
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <h5>Business operational hours</h5>
                                        <div class="mb-5 mt-3">

                                            {
                                                serviceProvider?.slot_details && Object.keys(serviceProvider?.slot_details).length > 0 && (


                                                    Object.keys(serviceProvider?.slot_details).map((key) => {

                                                        let slot_time = Object.keys(serviceProvider?.slot_details[key]).map((slot) => {
                                                            let open_at = moment(serviceProvider?.slot_details[key][slot]['open_at'], "HH:mm").format("HH:mm A");
                                                            let close_at = moment(serviceProvider?.slot_details[key][slot]['close_at'], "HH:mm").format("HH:mm A");
                                                            let slotStr = open_at + " to " + close_at
                                                            return slotStr
                                                        });
                                                        return (
                                                            <Row className='mb-3'>
                                                                <Col md={3}>
                                                                <h6>
                                                                {key}  
                                                               </h6>
                                                                </Col >
                                                               
                                                                <Col md={6}>
                                                                <p className="text-muted">
                                                                    {slot_time.join(" , ")}
                                                                </p>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-12">
                                        <h5 class="mb-2">Business Services</h5>
                                        <div class="common-busniess-box-proposal business-service mt-3 mb-5">
                                            <ul>
                                                {
                                                    serviceProvider.service_category && serviceProvider.service_category && (
                                                        serviceProvider.service_category.map((item, index) => {
                                                            return (
                                                                <>
                                                                {/* <li>{index + 1} <span className='mx-1'>{item.servicecategory_name}</span></li> */}
                                                                <li><span>{index + 1 + " " + ". " + item.servicecategory_name}</span></li>
                                                                </>
                                                            )
                                                        })
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                {
                                    serviceProvider?.vendor_type && serviceProvider?.vendor_type === "business"
                                    &&
                                    (
                                        <>
                                            <div class="row mb-2">
                                                <Row className='mt-4'>
                                                    <Col>
                                                    <div class="">
                                                    <h5 class="mb-2">Business Logo</h5>
                                                    <div class="">
                                                        <img class="rounded-3 mt-2 mb-2" src={serviceProvider?.businesslogoURL} width="250px" height="250px"  alt="naturalimg" />
                                                    </div>
                                                </div>
                                                    </Col>
                                                    <Col>
                                                    <div class="">
                                                    <h5 class="mb-2">Business Image</h5>
                                                    <div class="">
                                                        <img class="rounded-3 mt-2 mb-2" src={serviceProvider?.businessURL} alt="naturalsimg2" width="250px" height="250px" />
                                                    </div>
                                                </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Row className='mt-5'>
                                                <div class="col-md-4 col-sm-6">
                                                    <h5 class="mb-2">PAN Card Details</h5>
                                                    <Row>
                                                        <Col>
                                                        <p>PAN Card Number</p>
                                                        </Col>
                                                        <Col>
                                                         <p>{serviceProvider?.pan_no}</p>
                                                        </Col>
                                                    </Row>
                                                    <div class="">
                                                        <img class="rounded-3 mt-2 mb-2" src={serviceProvider.panURL} alt="naturalimg" width="250px" height="250px" />
                                                        {/* <span class="view-text">view</span> */}
                                                  </div>
                                                </div>
                                                </Row>
                                                
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    <h5 class="mt-3">GST Details</h5>
                                                    <Row>
                                                        <Col>
                                                        <p>GST Number</p>
                                                        </Col>
                                                        <Col>
                                                        <p>{serviceProvider?.gst_number}</p>
                                                        </Col>
                                                    </Row>
                                                   
                                                </div>
                                                <div class="col-md-6">

                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <hr />
                                {
                                    serviceProvider?.vendor_type && serviceProvider?.vendor_type === "freelancer" &&
                                    (
                                        <div class="row mb-2">
                                            <div class="col-md-6">
                                                <h3 class="mb-4">PAN Card Details</h3>
                                                <Row>
                                                    <Col>
                                                    <p>PAN Card Number</p>
                                                    </Col>
                                                    <Col>
                                                    <p>{serviceProvider?.pan_no}</p>
                                                    </Col>
                                                </Row>
                                                
                                                <div class="">
                                                    <img class="rounded-3 mt-2 mb-2" src={serviceProvider.panURL} alt="naturalimg" width="250px" height="250px" />
                                                    {/* <span class="view-text">view</span> */}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="mb-4">Upload Aadhar Card Details</h3>
                                                <Row>
                                                    <Col>
                                                    <p>Aadhar number</p>
                                                    </Col>
                                                    <Col>
                                                    <p>{serviceProvider?.aadhaar_no}</p>
                                                    </Col>
                                                </Row>
                                                <div class="">
                                                    <img class="rounded-3 mt-2 mb-2" src={serviceProvider.aadharURL} alt="naturalimg" width="250px" height="250px" />
                                                    <span class="view-text">view</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                                <div class="row mb-5">
                                    <div class="col-md-6">
                                        <h3 class="">Enter Bank Account Details</h3>
                                        <Row>
                                            <Col><p>Fund Account</p></Col>
                                            <Col><p>{serviceProvider?.fundAccount_id}</p></Col>
                                        </Row>
                                    </div>
                                    <div class="col-md-6"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="decline-accept">
                                          
                                          {
                                             type && type === "edit" && (
                                                <>
                                                  {
                                            serviceProvider?.business_verified
                                            ?(
                                                 <a href="JavaScript:void(0);" class="decline-btn float-start" onClick={()=>{setDeclineShow(true)}}>
                                                Decline</a>
                                            ):(
                                                <>
                                                 <a href="JavaScript:void(0);" class="decline-btn" onClick={()=>{setDeclineShow(true)}}>
                                                Decline</a>
                                               <a href="JavaScript:void(0);" class="accept-btn" onClick={() => {acceptService();onsubmitservice("accept");createstaffs(serviceProvider?.vendor_type)}}>
                                                Accept</a>
                                                </>
                                            )
                                          }
                                                </>

                                             )
                                          }
                                                  
                                                  <Modal show={declineshow} onHide={handleClose}
                                                    size="md"
                                                     aria-labelledby="contained-modal-title-vcenter"
                                                   centered>
                                                    <Modal.Header className='modal-width mb-4 mt-5'>
                                                    <h1>Decline</h1>
                                                    </Modal.Header>
                                                    <Modal.Body className='mb-5 modal-width'>
                                                        <h5>Comment</h5>
                                                        <Form onSubmit={formik.handleSubmit}>
                                                        <FormikProvider value={formik}>
                                                    <Row className='mt-3'>
                                                        
                                                    <Form.Control
                                                                className='decline-comment'
                                                                as="textarea"
                                                                name="comment"
                                                                placeholder="Leave a comment here"
                                                                style={{ height: '172px' }}
                                                                value={formik.values.comment}
                                                                onChange={formik.handleChange}
                                                                isInvalid={!!formik.touched.comment && !!formik.errors.comment}
                                                                />
                                                                 <Form.Control.Feedback type="invalid">
                                                                     {formik.errors.comment}
                                                                  </Form.Control.Feedback>
                                                                
                                                       
                                                    </Row>
                                                    <Row className='mt-3'>
                                                     <Col lg={6}>
                                                     
                                                     </Col>
                                                     <Col lg={6}>
                                                     <Button type='submit' className='comment-decline'>
                                                        Submit
                                                    </Button>
                                                    
                                                     </Col>
                                                     </Row>
                                                     </FormikProvider>
                                                        </Form>
                                                    </Modal.Body>
                                                  
                                                </Modal> 
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                        </Col>
                   </Row>
                </div>
            </div>
        </div>
    )
}

export default ProposalView