import axios from 'axios';
import { VENDOR_CONFIG, FILE_UPLOAD_CONFIG } from '../../configs';

export function fileHeader() {

    return { 'Content-Type': 'multipart/form-data' };
}


export function authHeader() {
    let token = localStorage.getItem('access');

    if (token) {
        return { 'x-access-token': `${token}` };
    } else {
        console.log('not returning');
        return {};
    }
}

const vendorRegister = (data) => axios.post(VENDOR_CONFIG.vendorRegister, data, { headers: authHeader() });

const vendorUpdate = (data) => axios.post(VENDOR_CONFIG.vendorUpdate, data, { headers: authHeader() });


const fileUpload = (data) => axios.post(FILE_UPLOAD_CONFIG.fileUpload, data, { headers: fileHeader() });

const fileUploadLocalHost = (data) => axios.post(FILE_UPLOAD_CONFIG.fileUploadLocalHost, data, { headers: fileHeader() });


const downloadServicesTemplateService = () => axios.get(FILE_UPLOAD_CONFIG.downloadServicesTemplateApi, {
    responseType: 'blob'
});

const downloadServicesInfoTemplateService = () => axios.get(FILE_UPLOAD_CONFIG.downloadServicesInfoTemplateApi, {
    responseType: 'blob'
});


const exploreService = (data) => axios.post(VENDOR_CONFIG.exploreApi, data);

const businessDetailsService = (data) => axios.post(VENDOR_CONFIG.businessDetailsApi, data);

const businessDetailsByGenderService = (data) => axios.post(VENDOR_CONFIG.businessDetailsByGenderApi, data);


const getAllBusinessReviewService = (data) => axios.post(VENDOR_CONFIG.getAllBusinessReviewsApi, data);
const getAllBusinessGalleryService = (data) => axios.post(VENDOR_CONFIG.getAllBusinessGalleryApi, data);
const createBusinessReviewService = (data) => axios.post(VENDOR_CONFIG.createBusinessReviewApi, data);

const getSomeSalonsService = (data) => axios.post(VENDOR_CONFIG.getSomeSalonsApi, data);
const getSalonsLocationService = (data) => axios.post(VENDOR_CONFIG.getSalonsLocation, data);



const getAvgReviewsService = (data) => axios.post(VENDOR_CONFIG.getAvgReviewsApi, data);





const data = {
    vendorRegister,
    vendorUpdate,
    fileUpload,
    fileUploadLocalHost,
    exploreService,
    downloadServicesTemplateService,
    downloadServicesInfoTemplateService,
    businessDetailsService,
    businessDetailsByGenderService,
    getAllBusinessReviewService,
    getAllBusinessGalleryService,
    createBusinessReviewService,
    getSomeSalonsService,
    getAvgReviewsService,
    getSalonsLocationService,

};

export default data;